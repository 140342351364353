// Generated by psc version 0.9.1
"use strict";
var Control_Monad_Aff = require("../Control.Monad.Aff");
var Control_Monad_Eff = require("../Control.Monad.Eff");
var Control_Monad_Eff_Class = require("../Control.Monad.Eff.Class");
var Control_Monad_Eff_Console = require("../Control.Monad.Eff.Console");
var Data_Firebase_References = require("../Data.Firebase.References");
var Data_Firebase_Subscribe = require("../Data.Firebase.Subscribe");
var Data_Firebase_Subscription = require("../Data.Firebase.Subscription");
var Data_Maybe = require("../Data.Maybe");
var Data_Tuple = require("../Data.Tuple");
var Debug_Trace = require("../Debug.Trace");
var Model_GlobalRole = require("../Model.GlobalRole");
var Model_UserProfile = require("../Model.UserProfile");
var Network_RemoteData = require("../Network.RemoteData");
var Ports_Firebase_Aff = require("../Ports.Firebase.Aff");
var Ports_Firebase_Eff = require("../Ports.Firebase.Eff");
var Ports_Firebase_Types = require("../Ports.Firebase.Types");
var Prelude = require("../Prelude");
var Pux_Router = require("../Pux.Router");
var Signal_Channel = require("../Signal.Channel");
var State_DataDependencyInterpreter = require("../State.DataDependencyInterpreter");
var State_Types = require("../State.Types");
var State_UserProfiles = require("../State.UserProfiles");
var UReview_Prelude = require("../UReview.Prelude");
var UReview_UserNotifications = require("../UReview.UserNotifications");
var WeReview_Data_Schema = require("../WeReview.Data.Schema");
var Web_Firebase = require("../Web.Firebase");
var Control_Bind = require("../Control.Bind");
var Web_Firebase_Aff_Read = require("../Web.Firebase.Aff.Read");
var Control_Applicative = require("../Control.Applicative");
var Data_Function = require("../Data.Function");
var Data_Unit = require("../Data.Unit");
var updateGlobalRole = function (snap) {
    return Control_Bind.bind(Control_Monad_Aff.bindAff)(Web_Firebase_Aff_Read.readSnapshotWithDefault(Model_GlobalRole.isForeignGlobalRole)(Model_GlobalRole.nullGlobalRole)(snap))(function (v) {
        return Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(new State_Types.RolesChanged(v));
    });
};
var unAuthenticated = function (state) {
    var onSuccessImpl = UReview_UserNotifications.sendStatusMessage(state.channel)("You have logged out sucessfully. We look forward to seeing you again");
    var onError = UReview_UserNotifications.sendErrorMessage(state.channel)("Logging out failed.");
    return {
        state: (function () {
            var $11 = {};
            for (var $12 in State_Types.initialState(state.rootRef)(state.channel)) {
                if ((State_Types.initialState(state.rootRef)(state.channel)).hasOwnProperty($12)) {
                    $11[$12] = (State_Types.initialState(state.rootRef)(state.channel))[$12];
                };
            };
            $11.authHandlerPresent = true;
            return $11;
        })(), 
        effects: [ Data_Function.apply(Control_Monad_Eff_Class.liftEff(Control_Monad_Aff.monadEffAff))(function __do() {
            Control_Monad_Eff_Console.log("Unregistering from /users")();
            var v = Data_Firebase_References.refToUsers(state.rootRef)();
            Web_Firebase.offSimple(v)();
            Data_Firebase_Subscription.unsubscribeAll(state.rootRef)(state.firebaseSubscriptions)();
            Ports_Firebase_Eff.logOut(onSuccessImpl)(onError)();
            Pux_Router.navigateTo("/")();
            return State_Types.Noop.value;
        }) ]
    };
};
var traceMsg = function (dictApplicative) {
    return function (msg) {
        return Debug_Trace.trace(msg)(function (v) {
            return Control_Applicative.pure(dictApplicative)(Data_Unit.unit);
        });
    };
};
var synchronizeCallback = function (v) {
    return function (v1) {
        if (v1 instanceof Data_Maybe.Just) {
            return Debug_Trace.traceAny(v1.value0)(function (v2) {
                return UReview_UserNotifications.sendErrorMessage(v)("User profile synchronization failed.");
            });
        };
        if (v1 instanceof Data_Maybe.Nothing) {
            return Control_Applicative.pure(Control_Monad_Eff.applicativeEff)(Data_Unit.unit);
        };
        throw new Error("Failed pattern match at State.Authentication line 83, column 1 - line 84, column 1: " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var subscribeToUsers = function (roles) {
    return function (state) {
        var $19 = Model_GlobalRole.canSeeOtherUsers(roles);
        if ($19) {
            return State_DataDependencyInterpreter.subscribeLeafAdded(WeReview_Data_Schema.pathToWeReviewSchema)(WeReview_Data_Schema.AllUsers.value)(State_UserProfiles.applyUserAdded)(state);
        };
        if (!$19) {
            return State_Types.noop(state);
        };
        throw new Error("Failed pattern match at State.Authentication line 88, column 3 - line 91, column 15: " + [ $19.constructor.name ]);
    };
};
var subscribeToRolesFor = function (uid) {
    return function (state) {
        return function (channel) {
            var p = Data_Firebase_References.myRolesPath(uid);
            return Data_Firebase_Subscribe.subscribeOnValue(p)(updateGlobalRole)(state.firebaseSubscriptions)(state.rootRef)(channel);
        };
    };
};
var authenticated = function (userProfile) {
    return function (state) {
        return function (channel) {
            var uid = (Model_UserProfile.runUserProfile(userProfile)).uid;
            var saveProfile = function __do() {
                var v = Data_Firebase_References.refToProfile(userProfile)(state.rootRef)();
                return Web_Firebase.setE(UReview_Prelude.writeForeign(Model_UserProfile.genericUserProfile)(userProfile))(synchronizeCallback(channel))(v)();
            };
            var newState = (function () {
                var $21 = {};
                for (var $22 in state) {
                    if (state.hasOwnProperty($22)) {
                        $21[$22] = state[$22];
                    };
                };
                $21.userProfile = new Network_RemoteData.Success(userProfile);
                $21.allUsers = [ userProfile ];
                $21.notification = UReview_UserNotifications.mkStatusNotification("");
                return $21;
            })();
            return {
                state: newState, 
                effects: [ Control_Bind.bind(Control_Monad_Aff.bindAff)(Data_Function.apply(Control_Monad_Eff_Class.liftEff(Control_Monad_Aff.monadEffAff))(saveProfile))(function () {
                    return subscribeToRolesFor(uid)(state)(channel);
                }) ]
            };
        };
    };
};
var addUser = function (v) {
    return Control_Bind.bind(Control_Monad_Aff.bindAff)(Web_Firebase_Aff_Read.readSnapshot(Model_UserProfile.isForeignUserProfile)(v.value1))(function (v1) {
        return Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(new State_Types.ChangeState(State_UserProfiles.userAdded(v1)));
    });
};
module.exports = {
    authenticated: authenticated, 
    subscribeToUsers: subscribeToUsers, 
    unAuthenticated: unAuthenticated
};
