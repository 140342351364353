// Generated by psc version 0.9.1
"use strict";
var Data_Array = require("../Data.Array");
var Data_Firebase_Foreign = require("../Data.Firebase.Foreign");
var Data_Foldable = require("../Data.Foldable");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var Data_Foreign_Class_Default = require("../Data.Foreign.Class.Default");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_Generic = require("../Data.Generic");
var Model_Event_Key = require("../Model.Event.Key");
var Model_GlobalRole_Tokens = require("../Model.GlobalRole.Tokens");
var Prelude = require("../Prelude");
var Test_QuickCheck_Arbitrary = require("../Test.QuickCheck.Arbitrary");
var UReview_Prelude = require("../UReview.Prelude");
var Control_Apply = require("../Control.Apply");
var Data_Maybe = require("../Data.Maybe");
var Data_Unit = require("../Data.Unit");
var Data_Show = require("../Data.Show");
var Data_Eq = require("../Data.Eq");
var Control_Bind = require("../Control.Bind");
var Data_Function = require("../Data.Function");
var Control_Applicative = require("../Control.Applicative");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra");
var Data_Either = require("../Data.Either");
var Data_Foreign_Index = require("../Data.Foreign.Index");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans");
var Data_Identity = require("../Data.Identity");
var GlobalRole = function (x) {
    return x;
};
var PermissionDenied = (function () {
    function PermissionDenied() {

    };
    PermissionDenied.value = new PermissionDenied();
    return PermissionDenied;
})();
var CanView = (function () {
    function CanView() {

    };
    CanView.value = new CanView();
    return CanView;
})();
var CanEdit = (function () {
    function CanEdit() {

    };
    CanEdit.value = new CanEdit();
    return CanEdit;
})();
var unGlobalRole = function (v) {
    return v;
};
var reviewerForEventIds = function (v) {
    return v.reviewerFor;
};
var nullGlobalRole = {
    administrator: false, 
    reviewerFor: [  ], 
    panelChairFor: [  ]
};
var makeReviewerFor = function (k) {
    return function (v) {
        var reviewerFor$prime = Data_Function.apply(Data_Array.nub(Data_ForeignKey.eqForeignKey))(Data_Array.cons(k)(v.reviewerFor));
        return Data_Function.apply(GlobalRole)((function () {
            var $22 = {};
            for (var $23 in v) {
                if (v.hasOwnProperty($23)) {
                    $22[$23] = v[$23];
                };
            };
            $22.reviewerFor = reviewerFor$prime;
            return $22;
        })());
    };
};
var isPanelChairFor = function (k) {
    return function (v) {
        return v.administrator || Data_Foldable.elem(Data_Foldable.foldableArray)(Data_ForeignKey.eqForeignKey)(k)(v.panelChairFor);
    };
};
var reviewersPermission = function (event) {
    return function (v) {
        if (v.administrator) {
            return CanEdit.value;
        };
        if (!v.administrator) {
            var $29 = isPanelChairFor(event)(v);
            if ($29) {
                return CanView.value;
            };
            if (!$29) {
                return PermissionDenied.value;
            };
            throw new Error("Failed pattern match at Model.GlobalRole line 47, column 11 - line 49, column 31: " + [ $29.constructor.name ]);
        };
        throw new Error("Failed pattern match at Model.GlobalRole line 45, column 3 - line 49, column 32: " + [ v.administrator.constructor.name ]);
    };
};
var isPanelChair = function (v) {
    return v.administrator || Data_Function.apply(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Array["null"](v.panelChairFor));
};
var isForeignGlobalRole = new Data_Foreign_Class.IsForeign(function (value) {
    return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Class_Default.readPropWithDefault(Data_Foreign_Class.booleanIsForeign)(Data_Foreign_Index.indexString)(false)(Model_GlobalRole_Tokens.administratorToken)(value))(function (v) {
        return Control_Bind.bind(Data_Either.bindEither)(Data_Firebase_Foreign.readTrueKeys(Model_GlobalRole_Tokens.reviewerForToken)(value))(function (v1) {
            return Control_Bind.bind(Data_Either.bindEither)(Data_Firebase_Foreign.readTrueKeys(Model_GlobalRole_Tokens.panelChairForToken)(value))(function (v2) {
                return Data_Function.apply(Control_Applicative.pure(Data_Either.applicativeEither))({
                    administrator: v, 
                    reviewerFor: v1, 
                    panelChairFor: v2
                });
            });
        });
    });
});
var isAdmin = function (v) {
    return v.administrator;
};
var getPanelChairFor = function (v) {
    return v.panelChairFor;
};
var genericGlobalRole = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.GlobalRole.GlobalRole" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(GlobalRole))((function (r) {
            if (r instanceof Data_Generic.SRecord && r.value0.length === 3) {
                return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(function (administrator1) {
                    return function (panelChairFor1) {
                        return function (reviewerFor1) {
                            return {
                                administrator: administrator1, 
                                panelChairFor: panelChairFor1, 
                                reviewerFor: reviewerFor1
                            };
                        };
                    };
                }))(Data_Generic.fromSpine(Data_Generic.genericBool)((r.value0[0]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Data_Generic.genericArray(Data_ForeignKey.genericForeignKey))((r.value0[1]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Data_Generic.genericArray(Data_ForeignKey.genericForeignKey))((r.value0[2]).recValue(Data_Unit.unit)));
            };
            return Data_Maybe.Nothing.value;
        })(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Model.GlobalRole.GlobalRole", [ {
        sigConstructor: "Model.GlobalRole.GlobalRole", 
        sigValues: [ function ($dollarq1) {
            return new Data_Generic.SigRecord([ {
                recLabel: "administrator", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_Generic.genericBool)(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "panelChairFor", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_Generic.genericArray(Data_ForeignKey.genericForeignKey))(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "reviewerFor", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_Generic.genericArray(Data_ForeignKey.genericForeignKey))(Data_Generic.anyProxy);
                }
            } ]);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("Model.GlobalRole.GlobalRole", [ function ($dollarq) {
        return new Data_Generic.SRecord([ {
            recLabel: "administrator", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_Generic.genericBool)(v.administrator);
            }
        }, {
            recLabel: "panelChairFor", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_Generic.genericArray(Data_ForeignKey.genericForeignKey))(v.panelChairFor);
            }
        }, {
            recLabel: "reviewerFor", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_Generic.genericArray(Data_ForeignKey.genericForeignKey))(v.reviewerFor);
            }
        } ]);
    } ]);
});
var showGlobalRole = new Data_Show.Show(Data_Generic.gShow(genericGlobalRole));
var toFirebaseForeignGlobalRole = new Data_Firebase_Foreign.ToFirebaseForeign(UReview_Prelude.writeForeign(genericGlobalRole));
var eqGlobalRole = new Data_Eq.Eq(Data_Generic.gEq(genericGlobalRole));
var canSeeOtherUsers = function (gr) {
    return isPanelChair(gr) || isAdmin(gr);
};
var arbitraryGlobalRole = new Test_QuickCheck_Arbitrary.Arbitrary(Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Test_QuickCheck_Arbitrary.arbBoolean))(function (v) {
    return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Data_ForeignKey.uniqueKeys)(function (v1) {
        return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Data_ForeignKey.uniqueKeys)(function (v2) {
            return Data_Function.apply(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity)))({
                administrator: v, 
                reviewerFor: v1, 
                panelChairFor: v2
            });
        });
    });
}));
var adminGlobalRole = {
    administrator: true, 
    reviewerFor: [  ], 
    panelChairFor: [  ]
};
module.exports = {
    GlobalRole: GlobalRole, 
    adminGlobalRole: adminGlobalRole, 
    canSeeOtherUsers: canSeeOtherUsers, 
    getPanelChairFor: getPanelChairFor, 
    isAdmin: isAdmin, 
    isPanelChair: isPanelChair, 
    isPanelChairFor: isPanelChairFor, 
    makeReviewerFor: makeReviewerFor, 
    nullGlobalRole: nullGlobalRole, 
    reviewerForEventIds: reviewerForEventIds, 
    unGlobalRole: unGlobalRole, 
    genericGlobalRole: genericGlobalRole, 
    showGlobalRole: showGlobalRole, 
    eqGlobalRole: eqGlobalRole, 
    isForeignGlobalRole: isForeignGlobalRole, 
    arbitraryGlobalRole: arbitraryGlobalRole, 
    toFirebaseForeignGlobalRole: toFirebaseForeignGlobalRole
};
