// Generated by psc version 0.9.1
"use strict";
var Control_Monad_Aff = require("../Control.Monad.Aff");
var Data_Activation = require("../Data.Activation");
var Data_Firebase_WriteParentChild = require("../Data.Firebase.WriteParentChild");
var Data_Unit = require("../Data.Unit");
var Debug_Trace = require("../Debug.Trace");
var Model_Event_Key = require("../Model.Event.Key");
var Model_UserProfile = require("../Model.UserProfile");
var PanelChair = require("../PanelChair");
var Prelude = require("../Prelude");
var WeReview_Data_Schema = require("../WeReview.Data.Schema");
var Ports_Firebase_Types = require("../Ports.Firebase.Types");
var Data_Function = require("../Data.Function");
var Control_Bind = require("../Control.Bind");
var Data_Firebase_Foreign = require("../Data.Firebase.Foreign");
var _setPanelChair = function (eventKey) {
    return function (userKey) {
        return function (activation) {
            return function (rootRef) {
                var panelChairInEvent = Data_Function.apply(WeReview_Data_Schema.Events.create(eventKey))(new WeReview_Data_Schema.PanelChair(userKey));
                var active = Data_Function.apply(Debug_Trace.spy)(Data_Function.apply(Data_Activation.asBoolean)(Debug_Trace.spy(activation)));
                return Control_Bind.bind(Control_Monad_Aff.bindAff)(Data_Firebase_WriteParentChild.set(WeReview_Data_Schema.pathToWeReviewSchema)(Data_Firebase_Foreign.toFirebaseForeignBoolean)(Data_Function.apply(WeReview_Data_Schema.Roles.create(userKey))(new WeReview_Data_Schema.PanelChairFor(eventKey)))(active)(rootRef))(function () {
                    return Data_Firebase_WriteParentChild.set(WeReview_Data_Schema.pathToWeReviewSchema)(Data_Firebase_Foreign.toFirebaseForeignBoolean)(panelChairInEvent)(active)(rootRef);
                });
            };
        };
    };
};
var createPanelChairProcedure = function (eventKey) {
    return function (userKey) {
        return function (rootRef) {
            return _setPanelChair(eventKey)(userKey)(Data_Activation.activate)(rootRef);
        };
    };
};
var togglePanelChair = function (eventKey) {
    return function (panelchair) {
        return function (rootRef) {
            var setPanelChair = function (eventKey1) {
                return function (v) {
                    return function (rootRef1) {
                        return _setPanelChair(eventKey1)(Model_UserProfile.getUserKey(v.user))(v.activation)(rootRef1);
                    };
                };
            };
            return setPanelChair(eventKey)(PanelChair.toggleActivation(panelchair))(rootRef);
        };
    };
};
module.exports = {
    _setPanelChair: _setPanelChair, 
    createPanelChairProcedure: createPanelChairProcedure, 
    togglePanelChair: togglePanelChair
};
