// Generated by psc version 0.9.1
"use strict";
var WeReview_Data_Schema = require("../WeReview.Data.Schema");
var Data_ForeignLocation = require("../Data.ForeignLocation");
var Data_Monoid = require("../Data.Monoid");
var Model_SessionDescription = require("../Model.SessionDescription");
var Prelude = require("../Prelude");
var State_DataDependency = require("../State.DataDependency");
var State_EventDependencies = require("../State.EventDependencies");
var State_SessionDescription = require("../State.SessionDescription");
var Data_Function = require("../Data.Function");
var Data_PathTo = require("../Data.PathTo");
var State_Types = require("../State.Types");
var fixedPath = function (p1) {
    return function (v) {
        return function (v1) {
            return p1;
        };
    };
};
var sessionDescriptionPath = function (k) {
    return Data_Function.apply(fixedPath)(Data_Function.apply(Data_PathTo.pathTo(WeReview_Data_Schema.pathToWeReviewSchema))(new WeReview_Data_Schema.SessionDescriptions(k, WeReview_Data_Schema.SessionDescriptionObject.value)));
};
var subscribeSessionDescriptionItself = function (k) {
    return new State_Types.Changed("SessionDescription", sessionDescriptionPath(k), State_SessionDescription.applyForeignSessionDescription, Data_Monoid.mempty(Data_Monoid.monoidArray));
};
module.exports = {
    subscribeSessionDescriptionItself: subscribeSessionDescriptionItself
};
