// Generated by psc version 0.9.1
"use strict";
var Data_Array = require("../Data.Array");
var Data_ForeignLocation = require("../Data.ForeignLocation");
var Data_Maybe = require("../Data.Maybe");
var Debug_Trace = require("../Debug.Trace");
var Model_UserProfile = require("../Model.UserProfile");
var Network_RemoteData = require("../Network.RemoteData");
var Prelude = require("../Prelude");
var State_ApplyFound = require("../State.ApplyFound");
var State_Types = require("../State.Types");
var Data_Boolean = require("../Data.Boolean");
var Data_Functor = require("../Data.Functor");
var Data_Function = require("../Data.Function");
var Control_Bind = require("../Control.Bind");
var Data_Either = require("../Data.Either");
var Control_Applicative = require("../Control.Applicative");
var Model_GlobalRole = require("../Model.GlobalRole");
var usersSortedByName = function (v) {
    return Data_Array.sort(Model_UserProfile.ordUserProfile)(v.allUsers);
};
var userAdded = function (userProfile) {
    return function (state) {
        var $6 = {};
        for (var $7 in state) {
            if (state.hasOwnProperty($7)) {
                $6[$7] = state[$7];
            };
        };
        $6.allUsers = Data_Array.cons(userProfile)(state.allUsers);
        return $6;
    };
};
var updateUser = function (f) {
    return function (key) {
        return function (state) {
            var updateWhenFound = function (user) {
                if (Model_UserProfile.isIdentifiedBy(key)(user)) {
                    return f(user);
                };
                if (Data_Boolean.otherwise) {
                    return user;
                };
                throw new Error("Failed pattern match at State.UserProfiles line 37, column 5 - line 37, column 64: " + [ user.constructor.name ]);
            };
            var $9 = {};
            for (var $10 in state) {
                if (state.hasOwnProperty($10)) {
                    $9[$10] = state[$10];
                };
            };
            $9.allUsers = Data_Functor.map(Data_Functor.functorArray)(updateWhenFound)(state.allUsers);
            return $9;
        };
    };
};
var findUser = function (k) {
    return function (v) {
        return Model_UserProfile.findUser(k)(v.allUsers);
    };
};
var applyUserAdded = function (found) {
    return function (state) {
        var k = Data_Function.apply(Model_UserProfile.mkUserKey)(Data_Function.apply(Debug_Trace.spy)(Data_ForeignLocation.key(found)));
        return Control_Bind.bind(Data_Either.bindEither)(Data_ForeignLocation.readForeignPart(Model_UserProfile.isForeignUserProfile)(found))(function (v) {
            return Data_Function.apply(Control_Applicative.pure(Data_Either.applicativeEither))(userAdded(v)(state));
        });
    };
};
var applyForeignRole = function (found) {
    return function (state) {
        var k = Data_Function.apply(Debug_Trace.spy)(Data_Function.apply(Model_UserProfile.mkUserKey)(Data_ForeignLocation.key(found)));
        return Control_Bind.bind(Data_Either.bindEither)(Data_Function.apply(Debug_Trace.spy)(Data_ForeignLocation.readForeignPart(Model_GlobalRole.isForeignGlobalRole)(found)))(function (v) {
            return Data_Function.apply(Control_Applicative.pure(Data_Either.applicativeEither))(updateUser(Model_UserProfile.setGlobalRole(v))(k)(state));
        });
    };
};
module.exports = {
    applyForeignRole: applyForeignRole, 
    applyUserAdded: applyUserAdded, 
    findUser: findUser, 
    userAdded: userAdded, 
    usersSortedByName: usersSortedByName
};
