// Generated by psc version 0.9.1
"use strict";
var Components_Elements = require("../Components.Elements");
var Components_ViewAllowed = require("../Components.ViewAllowed");
var Data_Maybe = require("../Data.Maybe");
var Data_Tuple = require("../Data.Tuple");
var Model_UserProfile = require("../Model.UserProfile");
var Network_RemoteData = require("../Network.RemoteData");
var Prelude = require("../Prelude");
var Pux_CSS = require("../Pux.CSS");
var Pux_Html = require("../Pux.Html");
var Pux_Html_Attributes = require("../Pux.Html.Attributes");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var Pux_Router = require("../Pux.Router");
var Route_HtmlElements = require("../Route.HtmlElements");
var State_SessionDescription = require("../State.SessionDescription");
var State_Types = require("../State.Types");
var UReview_Route_Types = require("../UReview.Route.Types");
var View_CodeOfConduct = require("../View.CodeOfConduct");
var View_EditCfS = require("../View.EditCfS");
var View_EventView = require("../View.EventView");
var View_EventsHome = require("../View.EventsHome");
var View_ExpenseRequest = require("../View.ExpenseRequest");
var View_FullSessionsView = require("../View.FullSessionsView");
var View_HiddenSessionsView = require("../View.HiddenSessionsView");
var View_Home = require("../View.Home");
var View_Loading = require("../View.Loading");
var View_ManageReviewersView = require("../View.ManageReviewersView");
var View_NotFound = require("../View.NotFound");
var View_Organise = require("../View.Organise");
var View_PanelChairs = require("../View.PanelChairs");
var View_RandomReviewView = require("../View.RandomReviewView");
var View_ReviewSummary = require("../View.ReviewSummary");
var View_ReviewSummaryWithEmergencyCSV = require("../View.ReviewSummaryWithEmergencyCSV");
var View_ReviewView = require("../View.ReviewView");
var View_ReviewerView = require("../View.ReviewerView");
var View_SignupView = require("../View.SignupView");
var View_TermsAndConditions = require("../View.TermsAndConditions");
var View_UserView = require("../View.UserView");
var View_UsersView = require("../View.UsersView");
var View_VisibleSessionsView = require("../View.VisibleSessionsView");
var Data_Functor = require("../Data.Functor");
var Data_Function = require("../Data.Function");
var Components_Elements_Basic_Types = require("../Components.Elements.Basic.Types");
var Data_Show = require("../Data.Show");
var Model_Connection = require("../Model.Connection");
var statusBar = function (v) {
    return Pux_Html_Elements.div([ Pux_Html_Attributes.className("row notification") ])([ Pux_Html_Elements.div([ Pux_Html_Attributes.className("column") ])([ Pux_Html_Elements.p([ Pux_Html_Attributes.className("notification-text") ])([ Pux_Html_Elements.text(v.message) ]) ]) ]);
};
var propose = Pux_Html_Elements.text("Propose");
var viewLogin = Pux_Html_Elements.div([ Pux_Html_Attributes.className("row align-justify align-middle") ])([ Pux_Html_Elements.div([ Pux_Html_Attributes.className("column") ])([ Pux_Html_Elements.ul([ Pux_Html_Attributes.className("menu") ])([ Pux_Html_Elements.li([  ])([ Pux_Router.link("/")([  ])([ propose ]) ]) ]) ]), Pux_Html_Elements.div([ Pux_Html_Attributes.className("column shrink") ])([ Pux_Html_Elements.ul([ Pux_Html_Attributes.className("menu align-right") ])([ Pux_Html_Elements.li([  ])([ Pux_Router.link("/signup")([  ])([ Pux_Html_Elements.text("Log in or sign up ") ]) ]) ]) ]) ]);
var pageTitle = function (state) {
    return Pux_Html_Elements.header([  ])([ Pux_Html_Elements.div([ Pux_Html_Attributes.className("row") ])([ Pux_Html_Elements.div([ Pux_Html_Attributes.className("column") ])([ Pux_Html_Elements.h1([  ])([ Pux_Html_Elements.text("WeReview") ]) ]) ]) ]);
};
var organise = "Organise";
var nameAndProfilePicture = function (u) {
    return Pux_Html_Elements.div([ Pux_Html_Attributes.className("column shrink") ])([ Pux_Html_Elements.ul([ Pux_Html_Attributes.className("menu") ])([ Pux_Html_Elements.li([ Pux_Html_Attributes.className("profile-info") ])([ Pux_Html_Elements.img([ Pux_Html_Attributes.src(u.profileImageURL), Pux_Html_Attributes.width("22"), Pux_Html_Attributes.height("22"), Pux_Html_Attributes.alt(u.uid) ])([  ]), Pux_Html_Elements.text(u.displayName) ]), Pux_Html_Elements.li([  ])([ Pux_Router.link("/signout")([  ])([ Pux_Html_Elements.text("Log out") ]) ]) ]) ]);
};
var menu = function (state) {
    var menuLinks = function (state1) {
        var listItems = Data_Functor.map(Data_Functor.functorArray)(function (html) {
            return Pux_Html.withChild(Pux_Html_Elements.li)(html);
        });
        var everyone = function (v) {
            return true;
        };
        return Data_Function.apply(listItems)(Components_ViewAllowed.viewAllowed([ Data_Function.apply(Data_Tuple.Tuple.create(Components_ViewAllowed.administrator))(Route_HtmlElements.routeLinkWithId(UReview_Route_Types.OrganiseRoute.value)(Components_Elements_Basic_Types.mkHtmlId("goto-organise"))(organise)), Data_Function.apply(Data_Tuple.Tuple.create(everyone))(Pux_Router.link("/")([  ])([ propose ])), Data_Function.apply(Data_Tuple.Tuple.create(Components_ViewAllowed.reviewer))(Pux_Router.link("/events")([  ])([ Pux_Html_Elements.text("Review") ])) ])(state1));
    };
    return Pux_Html_Elements.div([ Pux_Html_Attributes.className("column") ])([ Data_Function.apply(Pux_Html_Elements.ul([ Pux_Html_Attributes.className("menu") ]))(menuLinks(state)) ]);
};
var viewCurrentProfile = function (state) {
    return function (v) {
        return Pux_Html_Elements.div([ Pux_Html_Attributes.className("row align-justify align-middle") ])([ menu(state), nameAndProfilePicture(v) ]);
    };
};
var profileBar = function (state) {
    return Pux_Html.withChild(Pux_Html_Elements.nav)((function () {
        if (state.userProfile instanceof Network_RemoteData.Success) {
            return viewCurrentProfile(state)(state.userProfile.value0);
        };
        return viewLogin;
    })());
};
var footerBar = function (state) {
    return Pux_Html_Elements.footer([  ])([ Pux_Html_Elements.div([ Pux_Html_Attributes.className("row medium-unstack") ])([ Pux_Html_Elements.div([ Pux_Html_Attributes.className("column shrink") ])([ Pux_Html_Elements.p([ Pux_Html_Attributes.className("small uppercase bold margin-bottom-0") ])([ Pux_Html_Elements.text("WeReview public beta.") ]) ]) ]) ]);
};
var currentMockup = function (s) {
    return Data_Functor.map(Pux_Html_Elements.functorHtml)(State_Types.UpdateMockup.create)(View_ExpenseRequest.expenseRequestView(s.mockup));
};
var layout = function (state) {
    return Pux_Html_Elements.div([ Pux_Html_Attributes.id_("top") ])([ profileBar(state), pageTitle(state), statusBar(state.notification), (function () {
        if (state.route instanceof UReview_Route_Types.Home) {
            return View_Home.home(state);
        };
        if (state.route instanceof UReview_Route_Types.Reviewer) {
            return View_ReviewerView.reviewerView(state.route.value0)(state.route.value1)(state);
        };
        if (state.route instanceof UReview_Route_Types.RandomReview) {
            return View_RandomReviewView.randomReviewView(state.route.value0)(state);
        };
        if (state.route instanceof UReview_Route_Types.ReviewRoute) {
            return View_ReviewView.reviewView(state)(state.route.value0);
        };
        if (state.route instanceof UReview_Route_Types.PanelChairsRoute) {
            return View_PanelChairs.panelChairsView(state)(state.route.value0);
        };
        if (state.route instanceof UReview_Route_Types.EventRoute) {
            return View_EventView.eventView(state)(state.route.value0);
        };
        if (state.route instanceof UReview_Route_Types.EditCallForSessions) {
            return View_EditCfS.editCfS(state)(state.route.value0);
        };
        if (state.route instanceof UReview_Route_Types.HiddenSessions) {
            return View_HiddenSessionsView.viewHiddenSessions(state.route.value0)(state);
        };
        if (state.route instanceof UReview_Route_Types.VisibleSessions) {
            return View_VisibleSessionsView.viewVisibleSessions(state.route.value0)(state);
        };
        if (state.route instanceof UReview_Route_Types.ManageReviewers) {
            return View_ManageReviewersView.manageReviewers(state.route.value0)(state);
        };
        if (state.route instanceof UReview_Route_Types.ProposeSessionRoute) {
            return View_Home.home(state);
        };
        if (state.route instanceof UReview_Route_Types.ReviewSummaryRoute) {
            return View_ReviewSummary.reviewSummaryView(state)(state.route.value0);
        };
        if (state.route instanceof UReview_Route_Types.DownloadFullCSVRoute) {
            return View_ReviewSummaryWithEmergencyCSV.reviewSummaryViewWithEmergencyCSV(state)(state.route.value0);
        };
        if (state.route instanceof UReview_Route_Types.FullSessionDescriptionRoute) {
            var $23 = Components_ViewAllowed.canSeeFullSessionDescription(state);
            if ($23) {
                return Data_Maybe.maybe(View_Loading.loading("session description"))(View_FullSessionsView.fullSessionsView(state)(state.route.value0))(State_SessionDescription.findSessionDescription(state.route.value0)(state));
            };
            if (!$23) {
                return Components_ViewAllowed.accessDenied("session description");
            };
            throw new Error("Failed pattern match at View.Layout line 64, column 7 - line 69, column 47: " + [ $23.constructor.name ]);
        };
        if (state.route instanceof UReview_Route_Types.OrganiseRoute) {
            return View_Organise.organiseView(state);
        };
        if (state.route instanceof UReview_Route_Types.Events) {
            return View_EventsHome.eventsHome(state);
        };
        if (state.route instanceof UReview_Route_Types.UsersRoute) {
            return View_UsersView.usersView(state);
        };
        if (state.route instanceof UReview_Route_Types.TermsAndConditions) {
            return View_TermsAndConditions.termsAndConditionsView(state);
        };
        if (state.route instanceof UReview_Route_Types.CodeOfConduct) {
            return View_CodeOfConduct.codeOfConductView(state);
        };
        if (state.route instanceof UReview_Route_Types.UserRoute) {
            return View_UserView.userView(state.route.value0)(state);
        };
        if (state.route instanceof UReview_Route_Types.SignupRoute) {
            return View_SignupView.signupView(state);
        };
        if (state.route instanceof UReview_Route_Types.SignOutRoute) {
            return View_Home.home(state);
        };
        if (state.route instanceof UReview_Route_Types.NotFound) {
            return View_NotFound.notFound(state);
        };
        if (state.route instanceof UReview_Route_Types.CurrentMockup) {
            return currentMockup(state);
        };
        throw new Error("Failed pattern match at View.Layout line 49, column 5 - line 80, column 41: " + [ state.route.constructor.name ]);
    })(), footerBar(state) ]);
};
var connected = function (v) {
    return Data_Show.show(Model_Connection.showConnection)(v.connection);
};
module.exports = {
    connected: connected, 
    currentMockup: currentMockup, 
    footerBar: footerBar, 
    layout: layout, 
    menu: menu, 
    nameAndProfilePicture: nameAndProfilePicture, 
    organise: organise, 
    pageTitle: pageTitle, 
    profileBar: profileBar, 
    propose: propose, 
    statusBar: statusBar, 
    viewCurrentProfile: viewCurrentProfile, 
    viewLogin: viewLogin
};
