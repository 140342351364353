// Generated by psc version 0.9.1
"use strict";
var Components_Elements = require("../Components.Elements");
var Components_Elements_Types = require("../Components.Elements.Types");
var Data_Function = require("../Data.Function");
var Model_SessionDescription = require("../Model.SessionDescription");
var Pux_Html = require("../Pux.Html");
var Pux_Html_Attributes = require("../Pux.Html.Attributes");
var Route_HtmlElements = require("../Route.HtmlElements");
var State_Types = require("../State.Types");
var UReview_Route_Types = require("../UReview.Route.Types");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var Components_Elements_Basic_Types = require("../Components.Elements.Basic.Types");
var bod = (function () {
    var proposePath = function (v) {
        return new UReview_Route_Types.ProposeSessionRoute(v.eventId);
    };
    var h3t = function (txt) {
        return Pux_Html_Elements.h3([ Pux_Html_Attributes.className("mt2 mb1") ])([ Pux_Html_Elements.text(txt) ]);
    };
    var h2t = function (txt) {
        return Pux_Html_Elements.h2([ Pux_Html_Attributes.className("mv3") ])([ Pux_Html_Elements.text(txt) ]);
    };
    return Pux_Html.withChild(Pux_Html_Elements.div)(Pux_Html.bind(h2t("Terms and conditions for WeReview"))(function () {
        return Pux_Html.bind(Components_Elements.ptext("By submitting a session proposal to WeReview you agree to the following Terms and Conditions."))(function () {
            return Pux_Html.bind(h3t("Session reviews"))(function () {
                return Pux_Html.bind(Components_Elements.ptext("After the deadline closes, our selection panel will review all submissions anonymously."))(function () {
                    return Pux_Html.bind(Components_Elements.ptext("The review process usually takes between 3 and 6 weeks depending on the volume of submissions. We\u2019ll contact both successful and unsuccessful candidates within the review period."))(function () {
                        return Pux_Html.bind(h3t("Programming"))(function () {
                            return Pux_Html.bind(Components_Elements.ptext("Inclusion of a session in the conference programme will be at the sole discretion of the conference organisers. By agreeing to have your session included in the programme you agree to participate in the conference and in all pre-conference quality control activities as deemed necessary by the conference organisers. You also agree to promote your session and involvement in the build up to the event. We will provide a range of assets to support any promotional efforts."))(function () {
                                return Pux_Html.bind(Components_Elements.ptext("The organisers reserve the right to remove a session from the programme at any time. Speakers will no longer be eligible for free entry to the conference and so will have to pay the current non-speaker rate if they wish to participate in the conference."))(function () {
                                    return Pux_Html.bind(Components_Elements.ptext("The organisers will not be responsible for payment of any kind on behalf of the speaker in this event."))(function () {
                                        return Pux_Html.bind(h3t("Data policy"))(function () {
                                            return Pux_Html.bind(Components_Elements.ptext("We may process information that you provide to us for the purpose of submitting a session proposal. The data we process includes your name, organisation affiliation, email address, Twitter handle, LinkedIn details (where provided) and details of the session being proposed (including speaker bio). The legal basis for processing this data is our legitimate interest, namely the administration required to produce a programme for the event to which you have submitted. All the data you provide will be shared with our Panel Chair. Just the session title and description will be shared with the programme panel for the purpose of compiling the programme. Note that we do not redact details in the session description. Your name, organisation, session details and Twitter handle will be published as part of the event programme."))(function () {
                                                return Components_Elements.ptext("If your submission is successful you will need to register to attend the event. Your data will then be processed as per section 4 of our privacy notice, which can be found on the event website. Please also refer to this privacy notice for information about your rights and how to contact us about the data we hold on you.");
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });
    }));
})();
var termsAndConditionsView = function (v) {
    var row = Pux_Html.withAttr(Pux_Html_Elements.div)(Pux_Html_Attributes.className("row"));
    var routeDiv = Pux_Html.withAttr(Pux_Html_Elements.div)(Pux_Html_Attributes.className("column medium-9 large-7 mt2 bg-black5 pa1"));
    var proposePath = function (v1) {
        return new UReview_Route_Types.ProposeSessionRoute(v1.eventId);
    };
    return Pux_Html.withChild(Pux_Html_Elements.section)(Pux_Html.withChild(row)(Pux_Html.bind(Pux_Html.withChild(routeDiv)(Route_HtmlElements.routeLinkWithId(proposePath(v.newSessionDescription))(Components_Elements_Basic_Types.mkHtmlId("back-to-proposal"))("Back to my Session Proposal.")))(function () {
        return Pux_Html.bind(bod)(function () {
            return Pux_Html.withChild(routeDiv)(Route_HtmlElements.routeLinkWithId(proposePath(v.newSessionDescription))(Components_Elements_Basic_Types.mkHtmlId("back-to-proposal"))("Back to my Session Proposal."));
        });
    })));
};
module.exports = {
    termsAndConditionsView: termsAndConditionsView
};
