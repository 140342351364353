// Generated by psc version 0.9.1
"use strict";
var Data_Activation = require("../Data.Activation");
var Data_Either = require("../Data.Either");
var Data_Firebase_Foreign = require("../Data.Firebase.Foreign");
var Data_Foreign = require("../Data.Foreign");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_Generic = require("../Data.Generic");
var Data_Tuple = require("../Data.Tuple");
var Model_UserProfile = require("../Model.UserProfile");
var Prelude = require("../Prelude");
var UReview_Prelude = require("../UReview.Prelude");
var Control_Apply = require("../Control.Apply");
var Data_Maybe = require("../Data.Maybe");
var Data_Unit = require("../Data.Unit");
var Data_Show = require("../Data.Show");
var Data_Eq = require("../Data.Eq");
var Data_Function = require("../Data.Function");
var Data_Functor = require("../Data.Functor");
var PanelChairLoader = function (x) {
    return x;
};
var PanelChair = function (x) {
    return x;
};
var toggleActivation = function (v) {
    if (v.activation instanceof Data_Activation.Active) {
        return {
            user: v.user, 
            activation: Data_Activation.InActive.value
        };
    };
    if (v.activation instanceof Data_Activation.InActive) {
        return {
            user: v.user, 
            activation: Data_Activation.Active.value
        };
    };
    throw new Error("Failed pattern match at PanelChair line 60, column 51 - line 62, column 54: " + [ v.activation.constructor.name ]);
};
var mkPanelChairLoader = function (key) {
    return function (activation) {
        return {
            key: key, 
            activation: activation
        };
    };
};
var readPanelChairLoadersWithDefault = function (aDefault) {
    return function (token) {
        return function (value) {
            var convert = function (v) {
                return mkPanelChairLoader(Model_UserProfile.mkUserKey(v.value0))(Data_Activation.fromBoolean(v.value1));
            };
            var $19 = Data_Firebase_Foreign.readKeyValues(Data_Foreign_Class.booleanIsForeign)(token)(value);
            if ($19 instanceof Data_Either.Left) {
                return new Data_Either.Right(aDefault);
            };
            if ($19 instanceof Data_Either.Right) {
                return Data_Function.apply(Data_Either.Right.create)(Data_Functor.map(Data_Functor.functorArray)(convert)($19.value0));
            };
            throw new Error("Failed pattern match at PanelChair line 43, column 3 - line 45, column 37: " + [ $19.constructor.name ]);
        };
    };
};
var mkPanelChair = function (user) {
    return function (v) {
        return {
            user: user, 
            activation: v.activation
        };
    };
};
var isActive = function (v) {
    return Data_Activation.asBoolean(v.activation);
};
var getUserKey = function (v) {
    return v.key;
};
var getUser = function (v) {
    return v.user;
};
var getName = function (v) {
    return Model_UserProfile.getDisplayName(v.user);
};
var getKeyString = function (v) {
    return Model_UserProfile.keyString(v.user);
};
var genericPanelChairLoader = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "PanelChair.PanelChairLoader" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(PanelChairLoader))((function (r) {
            if (r instanceof Data_Generic.SRecord && r.value0.length === 2) {
                return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(function (activation1) {
                    return function (key1) {
                        return {
                            activation: activation1, 
                            key: key1
                        };
                    };
                }))(Data_Generic.fromSpine(Data_Activation.genericActivation)((r.value0[0]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Model_UserProfile.genericUserKey)((r.value0[1]).recValue(Data_Unit.unit)));
            };
            return Data_Maybe.Nothing.value;
        })(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("PanelChair.PanelChairLoader", [ {
        sigConstructor: "PanelChair.PanelChairLoader", 
        sigValues: [ function ($dollarq1) {
            return new Data_Generic.SigRecord([ {
                recLabel: "activation", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_Activation.genericActivation)(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "key", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Model_UserProfile.genericUserKey)(Data_Generic.anyProxy);
                }
            } ]);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("PanelChair.PanelChairLoader", [ function ($dollarq) {
        return new Data_Generic.SRecord([ {
            recLabel: "activation", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_Activation.genericActivation)(v.activation);
            }
        }, {
            recLabel: "key", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Model_UserProfile.genericUserKey)(v.key);
            }
        } ]);
    } ]);
});
var isForeignPanelChairLoader = new Data_Foreign_Class.IsForeign(function (value) {
    return UReview_Prelude.readForeign(isForeignPanelChairLoader)(genericPanelChairLoader)(value);
});
var showPanelChairLoader = new Data_Show.Show(Data_Generic.gShow(genericPanelChairLoader));
var eqPanelChairLoader = new Data_Eq.Eq(Data_Generic.gEq(genericPanelChairLoader));
module.exports = {
    PanelChair: PanelChair, 
    getKeyString: getKeyString, 
    getName: getName, 
    getUser: getUser, 
    getUserKey: getUserKey, 
    isActive: isActive, 
    mkPanelChair: mkPanelChair, 
    mkPanelChairLoader: mkPanelChairLoader, 
    readPanelChairLoadersWithDefault: readPanelChairLoadersWithDefault, 
    toggleActivation: toggleActivation, 
    genericPanelChairLoader: genericPanelChairLoader, 
    showPanelChairLoader: showPanelChairLoader, 
    eqPanelChairLoader: eqPanelChairLoader, 
    isForeignPanelChairLoader: isForeignPanelChairLoader
};
