// Generated by psc version 0.9.1
"use strict";
var $foreign = require("./foreign");
var Data_Foreign = require("../Data.Foreign");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var Data_Foreign_Generic = require("../Data.Foreign.Generic");
var Data_Generic = require("../Data.Generic");
var Data_Maybe = require("../Data.Maybe");
var Prelude = require("../Prelude");
var Web_Firebase = require("../Web.Firebase");
var Web_Firebase_Aff = require("../Web.Firebase.Aff");
var Web_Firebase_Types = require("../Web.Firebase.Types");
var Control_Apply = require("../Control.Apply");
var Data_Unit = require("../Data.Unit");
var Data_Show = require("../Data.Show");
var Data_Eq = require("../Data.Eq");
var GoogleProfile = function (x) {
    return x;
};
var LoggedOut = (function () {
    function LoggedOut() {

    };
    LoggedOut.value = new LoggedOut();
    return LoggedOut;
})();
var LoggedIn = (function () {
    function LoggedIn(value0) {
        this.value0 = value0;
    };
    LoggedIn.create = function (value0) {
        return new LoggedIn(value0);
    };
    return LoggedIn;
})();
var jsonOptions = (function () {
    var $4 = {};
    for (var $5 in Data_Foreign_Generic.defaultOptions) {
        if (Data_Foreign_Generic.defaultOptions.hasOwnProperty($5)) {
            $4[$5] = Data_Foreign_Generic.defaultOptions[$5];
        };
    };
    $4.unwrapNewtypes = true;
    return $4;
})();
var genericGoogleProfile = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Ports.Firebase.Types.GoogleProfile" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(GoogleProfile))((function (r) {
            if (r instanceof Data_Generic.SRecord && r.value0.length === 4) {
                return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(function (displayName1) {
                    return function (email1) {
                        return function (photoURL1) {
                            return function (uid1) {
                                return {
                                    displayName: displayName1, 
                                    email: email1, 
                                    photoURL: photoURL1, 
                                    uid: uid1
                                };
                            };
                        };
                    };
                }))(Data_Generic.fromSpine(Data_Generic.genericString)((r.value0[0]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Data_Generic.genericMaybe(Data_Generic.genericString))((r.value0[1]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Data_Generic.genericString)((r.value0[2]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Data_Generic.genericString)((r.value0[3]).recValue(Data_Unit.unit)));
            };
            return Data_Maybe.Nothing.value;
        })(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Ports.Firebase.Types.GoogleProfile", [ {
        sigConstructor: "Ports.Firebase.Types.GoogleProfile", 
        sigValues: [ function ($dollarq1) {
            return new Data_Generic.SigRecord([ {
                recLabel: "displayName", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "email", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_Generic.genericMaybe(Data_Generic.genericString))(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "photoURL", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "uid", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
                }
            } ]);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("Ports.Firebase.Types.GoogleProfile", [ function ($dollarq) {
        return new Data_Generic.SRecord([ {
            recLabel: "displayName", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_Generic.genericString)(v.displayName);
            }
        }, {
            recLabel: "email", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_Generic.genericMaybe(Data_Generic.genericString))(v.email);
            }
        }, {
            recLabel: "photoURL", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_Generic.genericString)(v.photoURL);
            }
        }, {
            recLabel: "uid", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_Generic.genericString)(v.uid);
            }
        } ]);
    } ]);
});
var isForeignGoogleProfile = new Data_Foreign_Class.IsForeign(Data_Foreign_Generic.readGeneric(genericGoogleProfile)(jsonOptions));
var showGoogleProfile = new Data_Show.Show(Data_Generic.gShow(genericGoogleProfile));
var eqGoogleProfile = new Data_Eq.Eq(Data_Generic.gEq(genericGoogleProfile));
module.exports = {
    GoogleProfile: GoogleProfile, 
    genericGoogleProfile: genericGoogleProfile, 
    showGoogleProfile: showGoogleProfile, 
    eqGoogleProfile: eqGoogleProfile, 
    isForeignGoogleProfile: isForeignGoogleProfile, 
    firebaseServerTimestamp: $foreign.firebaseServerTimestamp
};
