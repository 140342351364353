// Generated by psc version 0.9.1
"use strict";
var $foreign = require("./foreign");
var Prelude = require("../Prelude");
var Control_Monad_Eff = require("../Control.Monad.Eff");
var Data_Foldable = require("../Data.Foldable");
var Data_Maybe = require("../Data.Maybe");
var Data_Functor = require("../Data.Functor");
var Control_Apply = require("../Control.Apply");
var Control_Applicative = require("../Control.Applicative");
var Data_Semigroup = require("../Data.Semigroup");
var Data_Function = require("../Data.Function");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var Data_Monoid = require("../Data.Monoid");
var squigglyMap = function (dictFunctor) {
    return Data_Functor.map(dictFunctor);
};
var squigglyApply = function (dictApply) {
    return Control_Apply.apply(dictApply);
};
var semigroupSignal = new Data_Semigroup.Semigroup($foreign.merge);
var mergeMany = function (dictFunctor) {
    return function (dictFoldable) {
        return function (sigs) {
            var mergeMaybe = function (v) {
                return function (v1) {
                    if (v1 instanceof Data_Maybe.Nothing) {
                        return v;
                    };
                    if (v instanceof Data_Maybe.Nothing) {
                        return v1;
                    };
                    if (v instanceof Data_Maybe.Just && v1 instanceof Data_Maybe.Just) {
                        return new Data_Maybe.Just($foreign.merge(v.value0)(v1.value0));
                    };
                    throw new Error("Failed pattern match at Signal line 52, column 9 - line 52, column 33: " + [ v.constructor.name, v1.constructor.name ]);
                };
            };
            return Data_Foldable.foldl(dictFoldable)(mergeMaybe)(Data_Maybe.Nothing.value)(Data_Functor.map(dictFunctor)(Data_Maybe.Just.create)(sigs));
        };
    };
};
var functorSignal = new Data_Functor.Functor($foreign.mapSig);
var flippedMap = function (dictFunctor) {
    return Data_Function.flip(Data_Functor.map(dictFunctor));
};
var flatten = function (dictFunctor) {
    return function (dictFoldable) {
        return function (sig) {
            return $foreign.flattenArray(flippedMap(functorSignal)(sig)(function ($13) {
                return Data_Foldable.fold(dictFoldable)(Data_Monoid.monoidArray)(Data_Functor.map(dictFunctor)(function (i) {
                    return [ i ];
                })($13));
            }));
        };
    };
};
var filterMap = function (f) {
    return function (def) {
        return function (sig) {
            return Data_Functor.map(functorSignal)(Data_Maybe.fromMaybe(def))($foreign.filter(Data_Maybe.isJust)(new Data_Maybe.Just(def))(Data_Functor.map(functorSignal)(f)(sig)));
        };
    };
};
var applySignal = new Control_Apply.Apply(function () {
    return functorSignal;
}, $foreign.applySig);
var map2 = function (f) {
    return function (a) {
        return function (b) {
            return squigglyApply(applySignal)(squigglyMap(functorSignal)(f)(a))(b);
        };
    };
};
var map3 = function (f) {
    return function (a) {
        return function (b) {
            return function (c) {
                return squigglyApply(applySignal)(squigglyApply(applySignal)(squigglyMap(functorSignal)(f)(a))(b))(c);
            };
        };
    };
};
var map4 = function (f) {
    return function (a) {
        return function (b) {
            return function (c) {
                return function (d) {
                    return squigglyApply(applySignal)(squigglyApply(applySignal)(squigglyApply(applySignal)(squigglyMap(functorSignal)(f)(a))(b))(c))(d);
                };
            };
        };
    };
};
var map5 = function (f) {
    return function (a) {
        return function (b) {
            return function (c) {
                return function (d) {
                    return function (e) {
                        return squigglyApply(applySignal)(squigglyApply(applySignal)(squigglyApply(applySignal)(squigglyApply(applySignal)(squigglyMap(functorSignal)(f)(a))(b))(c))(d))(e);
                    };
                };
            };
        };
    };
};
var applicativeSignal = new Control_Applicative.Applicative(function () {
    return applySignal;
}, $foreign.constant);
module.exports = {
    filterMap: filterMap, 
    flatten: flatten, 
    flippedMap: flippedMap, 
    map2: map2, 
    map3: map3, 
    map4: map4, 
    map5: map5, 
    mergeMany: mergeMany, 
    squigglyApply: squigglyApply, 
    squigglyMap: squigglyMap, 
    functorSignal: functorSignal, 
    applySignal: applySignal, 
    applicativeSignal: applicativeSignal, 
    semigroupSignal: semigroupSignal, 
    constant: $foreign.constant, 
    dropRepeats: $foreign.dropRepeats, 
    "dropRepeats'": $foreign["dropRepeats'"], 
    filter: $foreign.filter, 
    flattenArray: $foreign.flattenArray, 
    foldp: $foreign.foldp, 
    merge: $foreign.merge, 
    runSignal: $foreign.runSignal, 
    sampleOn: $foreign.sampleOn, 
    unwrap: $foreign.unwrap
};
