// Generated by psc version 0.9.1
"use strict";
var Components_Elements = require("../Components.Elements");
var Components_Elements_Types = require("../Components.Elements.Types");
var Components_ManageReviewPanel_Types = require("../Components.ManageReviewPanel.Types");
var Data_ForeignKey = require("../Data.ForeignKey");
var Model_UserProfile = require("../Model.UserProfile");
var Prelude = require("../Prelude");
var Pux_Html = require("../Pux.Html");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var Data_Function = require("../Data.Function");
var Components_Elements_Basic_Types = require("../Components.Elements.Basic.Types");
var Data_Semigroup = require("../Data.Semigroup");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var addRoleToUserBuilder = function (v) {
    return function (sortedUsers) {
        return function (state) {
            return {
                listBuilder: {
                    htmlId: Data_Function.apply(Components_Elements_Basic_Types.mkHtmlId)("add-" + (v.htmlIdFragment + "-select")), 
                    label: v.labelFragment, 
                    action: Components_ManageReviewPanel_Types.Selected.create, 
                    length: 6, 
                    items: sortedUsers, 
                    selected: state.user, 
                    renderKey: Model_UserProfile.keyString, 
                    renderValue: function ($6) {
                        return Pux_Html_Elements.text(Model_UserProfile.getDisplayName($6));
                    }, 
                    stringToActionKey: Data_ForeignKey.mkForeignKey
                }, 
                confirmBuilder: {
                    confirmationMessage: function (u) {
                        return Pux_Html_Elements.p([  ])([ Data_Function.apply(Pux_Html_Elements.text)("Do you want to add " + (Model_UserProfile.getDisplayName(u) + (" as " + (v.labelFragment + "?")))) ]);
                    }, 
                    htmlId: Data_Function.apply(Components_Elements_Basic_Types.mkHtmlId)("add-" + (v.htmlIdFragment + "-button")), 
                    action: Components_ManageReviewPanel_Types.Committed.value, 
                    label: function ($7) {
                        return v.labelFragment + Model_UserProfile.getDisplayName($7);
                    }
                }
            };
        };
    };
};
var addReviewerBuilder = function (sortedUsers) {
    return function (state) {
        return {
            listBuilder: {
                htmlId: Components_Elements_Basic_Types.mkHtmlId("add-reviewer-select"), 
                label: "Add Reviewer", 
                action: Components_ManageReviewPanel_Types.Selected.create, 
                length: 6, 
                items: sortedUsers, 
                selected: state.user, 
                renderKey: Model_UserProfile.keyString, 
                renderValue: function ($8) {
                    return Pux_Html_Elements.text(Model_UserProfile.getDisplayName($8));
                }, 
                stringToActionKey: Data_ForeignKey.mkForeignKey
            }, 
            confirmBuilder: {
                confirmationMessage: function (u) {
                    return Pux_Html_Elements.p([  ])([ Data_Function.apply(Pux_Html_Elements.text)("Do you want to add " + (Model_UserProfile.getDisplayName(u) + " as reviewer?")) ]);
                }, 
                htmlId: Components_Elements_Basic_Types.mkHtmlId("add-reviewer-button"), 
                action: Components_ManageReviewPanel_Types.Committed.value, 
                label: function ($9) {
                    return "Add Reviewer" + Model_UserProfile.getDisplayName($9);
                }
            }
        };
    };
};
var addReviewerComponent = function (us) {
    return function (s) {
        return Data_Function.apply(Components_Elements.buildMultiSelectConfirmer([  ]))(addReviewerBuilder(us)(s));
    };
};
module.exports = {
    addReviewerBuilder: addReviewerBuilder, 
    addReviewerComponent: addReviewerComponent, 
    addRoleToUserBuilder: addRoleToUserBuilder
};
