// Generated by psc version 0.9.1
"use strict";
var Control_Monad_Aff = require("../Control.Monad.Aff");
var Data_Array = require("../Data.Array");
var Data_Maybe = require("../Data.Maybe");
var Model_Review = require("../Model.Review");
var Prelude = require("../Prelude");
var Pux = require("../Pux");
var ReviewDependencies = require("../ReviewDependencies");
var State_CallForSessions = require("../State.CallForSessions");
var State_Connection = require("../State.Connection");
var State_DataDependencyInterpreter = require("../State.DataDependencyInterpreter");
var State_EventDependencies = require("../State.EventDependencies");
var State_SessionDescriptionDependencies = require("../State.SessionDescriptionDependencies");
var State_Types = require("../State.Types");
var State_UserDependencies = require("../State.UserDependencies");
var UReview_Route_Types = require("../UReview.Route.Types");
var Data_Foldable = require("../Data.Foldable");
var Control_Bind = require("../Control.Bind");
var Control_Applicative = require("../Control.Applicative");
var Data_Function = require("../Data.Function");
var subscribePartsForUnauthenticatedRoute = function (s) {
    var subscriptions = Data_Array.singleton(State_Connection.dependOnConnectionState);
    return State_DataDependencyInterpreter.subscribeAll(Data_Foldable.foldableArray)(subscriptions)(s);
};
var forkAllEffects = function (v) {
    var effects$prime = [ Control_Bind.bind(Control_Monad_Aff.bindAff)(Control_Monad_Aff.forkAll(Data_Foldable.foldableArray)(v.effects))(function (v1) {
        return Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(State_Types.Noop.value);
    }) ];
    return Pux.onlyEffects(v.state)(effects$prime);
};
var dataDependenciesForCurrentRoute = function (s) {
    if (s.route instanceof UReview_Route_Types.Home) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)([ State_CallForSessions.dependOnAllOpenCallsForSessions ]);
    };
    if (s.route instanceof UReview_Route_Types.ProposeSessionRoute) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)([ State_CallForSessions.dependOnAnOpenCallForSessions(s.route.value0) ]);
    };
    if (s.route instanceof UReview_Route_Types.EventRoute) {
        return State_EventDependencies.subscribeAllEventKey(s.route.value0)(s);
    };
    if (s.route instanceof UReview_Route_Types.HiddenSessions) {
        return State_EventDependencies.subscribeAllEventKey(s.route.value0)(s);
    };
    if (s.route instanceof UReview_Route_Types.VisibleSessions) {
        return State_EventDependencies.subscribeAllEventKey(s.route.value0)(s);
    };
    if (s.route instanceof UReview_Route_Types.ManageReviewers) {
        return State_EventDependencies.subscribeAllEventKey(s.route.value0)(s);
    };
    if (s.route instanceof UReview_Route_Types.EditCallForSessions) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)([ State_CallForSessions.dependOnAnOpenCallForSessions(s.route.value0) ]);
    };
    if (s.route instanceof UReview_Route_Types.ReviewRoute) {
        return ReviewDependencies.subscribeAllReviewKey(s.route.value0)(s);
    };
    if (s.route instanceof UReview_Route_Types.FullSessionDescriptionRoute) {
        return Data_Function.apply(Control_Applicative.pure(Data_Maybe.applicativeMaybe))(Data_Function.apply(Data_Array.singleton)(State_SessionDescriptionDependencies.subscribeSessionDescriptionItself(s.route.value0)));
    };
    if (s.route instanceof UReview_Route_Types.ReviewSummaryRoute) {
        return State_EventDependencies.subscribeAllEventKey(s.route.value0)(s);
    };
    if (s.route instanceof UReview_Route_Types.DownloadFullCSVRoute) {
        return State_EventDependencies.subscribeAllEventKey(s.route.value0)(s);
    };
    if (s.route instanceof UReview_Route_Types.Reviewer) {
        return State_EventDependencies.subscribeAllEventKey(s.route.value0)(s);
    };
    if (s.route instanceof UReview_Route_Types.RandomReview) {
        return State_EventDependencies.subscribeAllEventKey(s.route.value0)(s);
    };
    if (s.route instanceof UReview_Route_Types.OrganiseRoute) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)([ State_EventDependencies.dependOnEventsOnly, State_CallForSessions.dependOnAllOpenCallsForSessions ]);
    };
    if (s.route instanceof UReview_Route_Types.UserRoute) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)([ State_UserDependencies.dependOnRole(s.route.value0), State_CallForSessions.dependOnAllOpenCallsForSessions ]);
    };
    return Data_Maybe.Nothing.value;
};
var subscribePartsForRoute = function (s) {
    var subscriptions = (function () {
        var $21 = dataDependenciesForCurrentRoute(s);
        if ($21 instanceof Data_Maybe.Nothing) {
            return Data_Array.singleton(State_Connection.dependOnConnectionState);
        };
        if ($21 instanceof Data_Maybe.Just) {
            return Data_Array.cons(State_Connection.dependOnConnectionState)($21.value0);
        };
        throw new Error("Failed pattern match at State.SubscribeReadModelForRoute line 41, column 7 - line 43, column 51: " + [ $21.constructor.name ]);
    })();
    return State_DataDependencyInterpreter.subscribeAll(Data_Foldable.foldableArray)(subscriptions)(s);
};
module.exports = {
    subscribePartsForRoute: subscribePartsForRoute, 
    subscribePartsForUnauthenticatedRoute: subscribePartsForUnauthenticatedRoute
};
