// Generated by psc version 0.9.1
"use strict";
var publicationStatusToken = "published";
var proposalFormToken = "proposalForm";
var invitationToken = "invitation";
var eventNameToken = "eventName";
var eventIdToken = "eventId";
var adaptedFieldsToken = "adaptedFields";
module.exports = {
    adaptedFieldsToken: adaptedFieldsToken, 
    eventIdToken: eventIdToken, 
    eventNameToken: eventNameToken, 
    invitationToken: invitationToken, 
    proposalFormToken: proposalFormToken, 
    publicationStatusToken: publicationStatusToken
};
