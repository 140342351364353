// Generated by psc version 0.9.1
"use strict";
var $foreign = require("./foreign");
var Data_Array = require("../Data.Array");
var Data_FieldsAndValues = require("../Data.FieldsAndValues");
var Prelude = require("../Prelude");
var Data_Maybe = require("../Data.Maybe");
var Data_Function = require("../Data.Function");
var Data_Functor = require("../Data.Functor");
var firstFieldNames = function (xs) {
    return Data_Maybe.maybe([  ])(Data_FieldsAndValues.fieldNames)(Data_Array.head(xs));
};
var csvFromRecords = function (xs) {
    return Data_Function.apply($foreign.commaSeparated)(Data_Array.cons(firstFieldNames(xs))(Data_Functor.map(Data_Functor.functorArray)(Data_FieldsAndValues.fieldStringValues)(xs)));
};
module.exports = {
    csvFromRecords: csvFromRecords, 
    commaSeparated: $foreign.commaSeparated
};
