// Generated by psc version 0.9.1
"use strict";
var $foreign = require("./foreign");
var Data_Tuple = require("../Data.Tuple");
var Prelude = require("../Prelude");
var Pux_Html = require("../Pux.Html");
var Data_Semigroup = require("../Data.Semigroup");
var zoomAndPan = $foreign.attr("zoomAndPan");
var z = $foreign.attr("z");
var yChannelSelector = $foreign.attr("yChannelSelector");
var y2 = $foreign.attr("y2");
var y1 = $foreign.attr("y1");
var y = $foreign.attr("y");
var xmlSpace = $foreign.attr("xmlSpace");
var xmlLang = $foreign.attr("xmlLang");
var xmlBase = $foreign.attr("xmlBase");
var xlinkType = $foreign.attr("xlinkType");
var xlinkTitle = $foreign.attr("xlinkTitle");
var xlinkShow = $foreign.attr("xlinkShow");
var xlinkRole = $foreign.attr("xlinkRole");
var xlinkHref = $foreign.attr("xlinkHref");
var xlinkArcrole = $foreign.attr("xlinkArcrole");
var xlinkActuate = $foreign.attr("xlinkActuate");
var xHeight = $foreign.attr("xHeight");
var xChannelSelector = $foreign.attr("xChannelSelector");
var x2 = $foreign.attr("x2");
var x1 = $foreign.attr("x1");
var x = $foreign.attr("x");
var writingMode = $foreign.attr("writingMode");
var wordSpacing = $foreign.attr("wordSpacing");
var wmode = $foreign.attr("wmode");
var widths = $foreign.attr("widths");
var width = $foreign.attr("width");
var visibility = $foreign.attr("visibility");
var viewTarget = $foreign.attr("viewTarget");
var viewBox = $foreign.attr("viewBox");
var vertOriginY = $foreign.attr("vertOriginY");
var vertOriginX = $foreign.attr("vertOriginX");
var vertAdvY = $foreign.attr("vertAdvY");
var version = $foreign.attr("version");
var vectorEffect = $foreign.attr("vectorEffect");
var values = $foreign.attr("values");
var value = $foreign.attr("value");
var vMathematical = $foreign.attr("vMathematical");
var vIdeographic = $foreign.attr("vIdeographic");
var vHanging = $foreign.attr("vHanging");
var vAlphabetic = $foreign.attr("vAlphabetic");
var useMap = $foreign.attr("useMap");
var unselectable = $foreign.attr("unselectable");
var unitsPerEm = $foreign.attr("unitsPerEm");
var unicodeRange = $foreign.attr("unicodeRange");
var unicodeBidi = $foreign.attr("unicodeBidi");
var unicode = $foreign.attr("unicode");
var underlineThickness = $foreign.attr("underlineThickness");
var underlinePosition = $foreign.attr("underlinePosition");
var u2 = $foreign.attr("u2");
var u1 = $foreign.attr("u1");
var type_ = $foreign.attr("type");
var transform = $foreign.attr("transform");
var to = $foreign.attr("to");
var title = $foreign.attr("title");
var textRendering = $foreign.attr("textRendering");
var textLength = $foreign.attr("textLength");
var textDecoration = $foreign.attr("textDecoration");
var textAnchor = $foreign.attr("textAnchor");
var targetY = $foreign.attr("targetY");
var targetX = $foreign.attr("targetX");
var target = $foreign.attr("target");
var tableValues = $foreign.attr("tableValues");
var tabIndex = $foreign.attr("tabIndex");
var systemLanguage = $foreign.attr("systemLanguage");
var surfaceScale = $foreign.attr("surfaceScale");
var style = $foreign.attr("style");
var strokeWidth = $foreign.attr("strokeWidth");
var strokeOpacity = $foreign.attr("strokeOpacity");
var strokeMiterlimit = $foreign.attr("strokeMiterlimit");
var strokeLinejoin = $foreign.attr("strokeLinejoin");
var strokeLinecap = $foreign.attr("strokeLinecap");
var strokeDashoffset = $foreign.attr("strokeDashoffset");
var strokeDasharray = $foreign.attr("strokeDasharray");
var stroke = $foreign.attr("stroke");
var string = $foreign.attr("string");
var strikethroughThickness = $foreign.attr("strikethroughThickness");
var strikethroughPosition = $foreign.attr("strikethroughPosition");
var stopOpacity = $foreign.attr("stopOpacity");
var stopColor = $foreign.attr("stopColor");
var stitchTiles = $foreign.attr("stitchTiles");
var step = $foreign.attr("step");
var stemv = $foreign.attr("stemv");
var stemh = $foreign.attr("stemh");
var stdDeviation = $foreign.attr("stdDeviation");
var startOffset = $foreign.attr("startOffset");
var start = $foreign.attr("start");
var srcSet = $foreign.attr("srcSet");
var srcDoc = $foreign.attr("srcDoc");
var src = $foreign.attr("src");
var spreadMethod = $foreign.attr("spreadMethod");
var spellCheck = $foreign.attr("spellCheck");
var speed = $foreign.attr("speed");
var specularExponent = $foreign.attr("specularExponent");
var specularConstant = $foreign.attr("specularConstant");
var span_ = $foreign.attr("span");
var spacing = $foreign.attr("spacing");
var slope = $foreign.attr("slope");
var sizes = $foreign.attr("sizes");
var size = $foreign.attr("size");
var shapeRendering = $foreign.attr("shapeRendering");
var shape = $foreign.attr("shape");
var selected = $foreign.attr("selected");
var seed = $foreign.attr("seed");
var security = $foreign.attr("security");
var seamless = $foreign.attr("seamless");
var scrolling = $foreign.attr("scrolling");
var scoped = $foreign.attr("scope");
var scope = $foreign.attr("scope");
var scale = $foreign.attr("scale");
var sandbox = $foreign.attr("sandbox");
var ry = $foreign.attr("ry");
var rx = $foreign.attr("rx");
var rows = $foreign.attr("rows");
var rowSpan = $foreign.attr("rowSpan");
var rotate = $foreign.attr("rotate");
var role = $foreign.attr("role");
var results = $foreign.attr("results");
var result = $foreign.attr("result");
var restart = $foreign.attr("restart");
var requiredFeatures = $foreign.attr("requiredFeatures");
var requiredExtensions = $foreign.attr("requiredExtensions");
var required = $foreign.attr("required");
var repeatDur = $foreign.attr("repeatDur");
var repeatCount = $foreign.attr("repeatCount");
var renderingIntent = $foreign.attr("renderingIntent");
var rel = $foreign.attr("rel");
var refY = $foreign.attr("refY");
var refX = $foreign.attr("refX");
var readOnly = $foreign.attr("readOnly");
var radius = $foreign.attr("radius");
var radioGroup = $foreign.attr("radioGroup");
var r = $foreign.attr("r");
var primitiveUnits = $foreign.attr("primitiveUnits");
var preserveAspectRatio = $foreign.attr("preserveAspectRatio");
var preserveAlpha = $foreign.attr("preserveAlpha");
var preload = $foreign.attr("preload");
var poster = $foreign.attr("poster");
var pointsAtZ = $foreign.attr("pointsAtZ");
var pointsAtY = $foreign.attr("pointsAtY");
var pointsAtX = $foreign.attr("pointsAtX");
var points = $foreign.attr("points");
var pointerEvents = $foreign.attr("pointerEvents");
var placeholder = $foreign.attr("placeholder");
var patternUnits = $foreign.attr("patternUnits");
var patternTransform = $foreign.attr("patternTransform");
var patternContentUnits = $foreign.attr("patternContentUnits");
var pattern = $foreign.attr("pattern");
var pathLength = $foreign.attr("pathLength");
var panose1 = $foreign.attr("panose1");
var paintOrder = $foreign.attr("paintOrder");
var overlineThickness = $foreign.attr("overlineThickness");
var overlinePosition = $foreign.attr("overlinePosition");
var overflow = $foreign.attr("overflow");
var origin = $foreign.attr("origin");
var orientation = $foreign.attr("orientation");
var orient = $foreign.attr("orient");
var order = $foreign.attr("order");
var operator = $foreign.attr("operator");
var open = $foreign.attr("open");
var opacity = $foreign.attr("opacity");
var offset = $foreign.attr("offset");
var numOctaves = $foreign.attr("numOctaves");
var noValidate = $foreign.attr("noValidate");
var name = $foreign.attr("name");
var muted = $foreign.attr("muted");
var multiple = $foreign.attr("multiple");
var mode = $foreign.attr("mode");
var min = $foreign.attr("min");
var method = $foreign.attr("method");
var mediaGroup = $foreign.attr("mediaGroup");
var media = $foreign.attr("media");
var maxLength = $foreign.attr("maxLength");
var max = $foreign.attr("max");
var mathematical = $foreign.attr("mathematical");
var maskUnits = $foreign.attr("maskUnits");
var maskContentUnits = $foreign.attr("maskContentUnits");
var mask = $foreign.attr("mask");
var markerWidth = $foreign.attr("markerWidth");
var markerUnits = $foreign.attr("markerUnits");
var markerStart = $foreign.attr("markerStart");
var markerMid = $foreign.attr("markerMid");
var markerHeight = $foreign.attr("markerHeight");
var markerEnd = $foreign.attr("markerEnd");
var marginWidth = $foreign.attr("marginWidth");
var marginHeight = $foreign.attr("marginHeight");
var manifest = $foreign.attr("manifest");
var loop = $foreign.attr("loop");
var local = $foreign.attr("local");
var list = $foreign.attr("list");
var limitingConeAngle = $foreign.attr("limitingConeAngle");
var lightingColor = $foreign.attr("lightingColor");
var letterSpacing = $foreign.attr("letterSpacing");
var lengthAdjust = $foreign.attr("lengthAdjust");
var lang = $foreign.attr("lang");
var label = $foreign.attr("label");
var keyTimes = $foreign.attr("keyTimes");
var keySplines = $foreign.attr("keySplines");
var keyPoints = $foreign.attr("keyPoints");
var key = $foreign.attr("key");
var kerning = $foreign.attr("kerning");
var kernelUnitLength = $foreign.attr("kernelUnitLength");
var kernelMatrix = $foreign.attr("kernelMatrix");
var k4 = $foreign.attr("k4");
var k3 = $foreign.attr("k3");
var k2 = $foreign.attr("k2");
var k1 = $foreign.attr("k1");
var k = $foreign.attr("k");
var itemType = $foreign.attr("itemType");
var itemScope = $foreign.attr("itemScope");
var itemRef = $foreign.attr("itemRef");
var itemProp = $foreign.attr("itemProp");
var itemID = $foreign.attr("itemID");
var intercept = $foreign.attr("intercept");
var in_ = $foreign.attr("in");
var in2 = $foreign.attr("in2");
var imageRendering = $foreign.attr("imageRendering");
var ideographic = $foreign.attr("ideographic");
var id_ = $foreign.attr("id");
var icon = $foreign.attr("icon");
var httpEquiv = $foreign.attr("httpEquiv");
var htmlFor = $foreign.attr("htmlFor");
var hrefLang = $foreign.attr("hrefLang");
var href = $foreign.attr("href");
var horizOriginX = $foreign.attr("horizOriginX");
var horizAdvX = $foreign.attr("horizAdvX");
var hidden = $foreign.attr("hidden");
var height = $foreign.attr("height");
var hanging = $foreign.attr("hanging");
var gradientUnits = $foreign.attr("gradientUnits");
var gradientTransform = $foreign.attr("gradientTransform");
var glyphRef = $foreign.attr("glyphRef");
var glyphOrientationVertical = $foreign.attr("glyphOrientationVertical");
var glyphOrientationHorizontal = $foreign.attr("glyphOrientationHorizontal");
var glyphName = $foreign.attr("glyphName");
var g2 = $foreign.attr("g2");
var g1 = $foreign.attr("g1");
var fy = $foreign.attr("fy");
var fx = $foreign.attr("effects");
var from = $foreign.attr("from");
var frameBorder = $foreign.attr("frameBorder");
var format = $foreign.attr("format");
var formTarget = $foreign.attr("formTarget");
var formNoValidate = $foreign.attr("formNoValidate");
var formMethod = $foreign.attr("formMethod");
var formEncType = $foreign.attr("formEncType");
var formAction = $foreign.attr("formAction");
var form = $foreign.attr("form");
var fontWeight = $foreign.attr("fontWeight");
var fontVariant = $foreign.attr("fontVariant");
var fontStyle = $foreign.attr("fontStyle");
var fontStretch = $foreign.attr("fontStretch");
var fontSizeAdjust = $foreign.attr("fontSizeAdjust");
var fontSize = $foreign.attr("fontSize");
var fontFamily = $foreign.attr("fontFamily");
var focusable = $foreign.attr("focusable");
var floodOpacity = $foreign.attr("floodOpacity");
var floodColor = $foreign.attr("floodColor");
var filterUnits = $foreign.attr("filterUnits");
var filterRes = $foreign.attr("filterRes");
var filter = $foreign.attr("filter");
var fillRule = $foreign.attr("fillRule");
var fillOpacity = $foreign.attr("fillOpacity");
var fill = $foreign.attr("fill");
var externalResourcesRequired = $foreign.attr("externalResourcesRequired");
var exponent = $foreign.attr("exponent");
var end = $foreign.attr("end");
var encType = $foreign.attr("encType");
var enableBackground = $foreign.attr("enableBackground");
var elevation = $foreign.attr("elevation");
var edgeMode = $foreign.attr("edgeMode");
var dy = $foreign.attr("dy");
var dx = $foreign.attr("dx");
var dur = $foreign.attr("dur");
var draggable = $foreign.attr("draggable");
var download = $foreign.attr("download");
var dominantBaseline = $foreign.attr("dominantBaseline");
var divisor = $foreign.attr("divisor");
var display = $foreign.attr("display");
var disabled = function (v) {
    if (v) {
        return $foreign.attr("disabled")("disabled");
    };
    if (!v) {
        return $foreign.attr("disabled")("");
    };
    throw new Error("Failed pattern match at Pux.Html.Attributes line 122, column 1 - line 123, column 1: " + [ v.constructor.name ]);
};
var direction = $foreign.attr("direction");
var dir = $foreign.attr("dir");
var diffuseConstant = $foreign.attr("diffuseConstant");
var descent = $foreign.attr("descent");
var defer = $foreign.attr("defer");
var defaultValue = $foreign.attr("defaultValue");
var defaultChecked = $foreign.attr("defaultChecked");
var decelerate = $foreign.attr("decelerate");
var dateTime = $foreign.attr("dateTime");
var data_ = function (dataAttr) {
    return $foreign.attr("data-" + dataAttr);
};
var dangerouslySetInnerHTML = $foreign.attr("dangerouslySetInnerHTML");
var d = $foreign.attr("d");
var cy = $foreign.attr("cy");
var cx = $foreign.attr("cx");
var cursor = $foreign.attr("cursor");
var crossOrigin = $foreign.attr("crossOrigin");
var coords = $foreign.attr("coords");
var controls = $foreign.attr("controls");
var contextMenu = $foreign.attr("contextMenu");
var contentStyleType = $foreign.attr("contentStyleType");
var contentScriptType = $foreign.attr("contentScriptType");
var contentEditable = $foreign.attr("contentEditable");
var content = $foreign.attr("content");
var cols = $foreign.attr("cols");
var colorRendering = $foreign.attr("colorRendering");
var colorProfile = $foreign.attr("colorProfile");
var colorInterpolationFilters = $foreign.attr("colorInterpolationFilters");
var colorInterpolation = $foreign.attr("colorInterpolation");
var color = $foreign.attr("color");
var colSpan = $foreign.attr("colSpan");
var clipRule = $foreign.attr("clipRule");
var clipPathUnits = $foreign.attr("clipPathUnits");
var clipPath = $foreign.attr("clipPath");
var clip = $foreign.attr("clip");
var className = $foreign.attr("className");
var classID = $foreign.attr("classID");
var checked = $foreign.attr("checked");
var charSet = $foreign.attr("charSet");
var cellSpacing = $foreign.attr("cellSpacing");
var cellPadding = $foreign.attr("cellPadding");
var capture = $foreign.attr("capture");
var capHeight = $foreign.attr("capHeight");
var calcMode = $foreign.attr("calcMode");
var by = $foreign.attr("by");
var bias = $foreign.attr("bias");
var begin = $foreign.attr("begin");
var bbox = $foreign.attr("bbox");
var baselineShift = $foreign.attr("baselineShift");
var baseProfile = $foreign.attr("baseProfile");
var baseFrequency = $foreign.attr("baseFrequency");
var azimuth = $foreign.attr("azimuth");
var autoSave = $foreign.attr("autoSave");
var autoReverse = $foreign.attr("autoReverse");
var autoPlay = $foreign.attr("autoPlay");
var autoFocus = $foreign.attr("autoFocus");
var autoCorrect = $foreign.attr("autoCorrect");
var autoComplete = $foreign.attr("autoComplete");
var autoCapitalize = $foreign.attr("autoCapitalize");
var attributeType = $foreign.attr("attributeType");
var attributeName = $foreign.attr("attributeName");
var async = $foreign.attr("async");
var ascent = $foreign.attr("ascent");
var aria = function (ariaAttr) {
    return $foreign.attr("aria-" + ariaAttr);
};
var arabicForm = $foreign.attr("arabicForm");
var amplitude = $foreign.attr("amplitude");
var alt = $foreign.attr("alt");
var alphabetic = $foreign.attr("alphabetic");
var allowTransparency = $foreign.attr("allowTransparency");
var allowReorder = $foreign.attr("allowReorder");
var allowFullScreen = $foreign.attr("allowFullScreen");
var alignmentBaseline = $foreign.attr("alignmentBaseline");
var additive = $foreign.attr("additive");
var action = $foreign.attr("action");
var accumulate = $foreign.attr("accumulate");
var accessKey = $foreign.attr("accessKey");
var acceptCharset = $foreign.attr("acceptCharset");
var accept = $foreign.attr("accept");
var accentHeight = $foreign.attr("accentHeight");
module.exports = {
    accentHeight: accentHeight, 
    accept: accept, 
    acceptCharset: acceptCharset, 
    accessKey: accessKey, 
    accumulate: accumulate, 
    action: action, 
    additive: additive, 
    alignmentBaseline: alignmentBaseline, 
    allowFullScreen: allowFullScreen, 
    allowReorder: allowReorder, 
    allowTransparency: allowTransparency, 
    alphabetic: alphabetic, 
    alt: alt, 
    amplitude: amplitude, 
    arabicForm: arabicForm, 
    aria: aria, 
    ascent: ascent, 
    async: async, 
    attributeName: attributeName, 
    attributeType: attributeType, 
    autoCapitalize: autoCapitalize, 
    autoComplete: autoComplete, 
    autoCorrect: autoCorrect, 
    autoFocus: autoFocus, 
    autoPlay: autoPlay, 
    autoReverse: autoReverse, 
    autoSave: autoSave, 
    azimuth: azimuth, 
    baseFrequency: baseFrequency, 
    baseProfile: baseProfile, 
    baselineShift: baselineShift, 
    bbox: bbox, 
    begin: begin, 
    bias: bias, 
    by: by, 
    calcMode: calcMode, 
    capHeight: capHeight, 
    capture: capture, 
    cellPadding: cellPadding, 
    cellSpacing: cellSpacing, 
    charSet: charSet, 
    checked: checked, 
    classID: classID, 
    className: className, 
    clip: clip, 
    clipPath: clipPath, 
    clipPathUnits: clipPathUnits, 
    clipRule: clipRule, 
    colSpan: colSpan, 
    color: color, 
    colorInterpolation: colorInterpolation, 
    colorInterpolationFilters: colorInterpolationFilters, 
    colorProfile: colorProfile, 
    colorRendering: colorRendering, 
    cols: cols, 
    content: content, 
    contentEditable: contentEditable, 
    contentScriptType: contentScriptType, 
    contentStyleType: contentStyleType, 
    contextMenu: contextMenu, 
    controls: controls, 
    coords: coords, 
    crossOrigin: crossOrigin, 
    cursor: cursor, 
    cx: cx, 
    cy: cy, 
    d: d, 
    dangerouslySetInnerHTML: dangerouslySetInnerHTML, 
    data_: data_, 
    dateTime: dateTime, 
    decelerate: decelerate, 
    defaultChecked: defaultChecked, 
    defaultValue: defaultValue, 
    defer: defer, 
    descent: descent, 
    diffuseConstant: diffuseConstant, 
    dir: dir, 
    direction: direction, 
    disabled: disabled, 
    display: display, 
    divisor: divisor, 
    dominantBaseline: dominantBaseline, 
    download: download, 
    draggable: draggable, 
    dur: dur, 
    dx: dx, 
    dy: dy, 
    edgeMode: edgeMode, 
    elevation: elevation, 
    enableBackground: enableBackground, 
    encType: encType, 
    end: end, 
    exponent: exponent, 
    externalResourcesRequired: externalResourcesRequired, 
    fill: fill, 
    fillOpacity: fillOpacity, 
    fillRule: fillRule, 
    filter: filter, 
    filterRes: filterRes, 
    filterUnits: filterUnits, 
    floodColor: floodColor, 
    floodOpacity: floodOpacity, 
    focusable: focusable, 
    fontFamily: fontFamily, 
    fontSize: fontSize, 
    fontSizeAdjust: fontSizeAdjust, 
    fontStretch: fontStretch, 
    fontStyle: fontStyle, 
    fontVariant: fontVariant, 
    fontWeight: fontWeight, 
    form: form, 
    formAction: formAction, 
    formEncType: formEncType, 
    formMethod: formMethod, 
    formNoValidate: formNoValidate, 
    formTarget: formTarget, 
    format: format, 
    frameBorder: frameBorder, 
    from: from, 
    fx: fx, 
    fy: fy, 
    g1: g1, 
    g2: g2, 
    glyphName: glyphName, 
    glyphOrientationHorizontal: glyphOrientationHorizontal, 
    glyphOrientationVertical: glyphOrientationVertical, 
    glyphRef: glyphRef, 
    gradientTransform: gradientTransform, 
    gradientUnits: gradientUnits, 
    hanging: hanging, 
    height: height, 
    hidden: hidden, 
    horizAdvX: horizAdvX, 
    horizOriginX: horizOriginX, 
    href: href, 
    hrefLang: hrefLang, 
    htmlFor: htmlFor, 
    httpEquiv: httpEquiv, 
    icon: icon, 
    id_: id_, 
    ideographic: ideographic, 
    imageRendering: imageRendering, 
    in2: in2, 
    in_: in_, 
    intercept: intercept, 
    itemID: itemID, 
    itemProp: itemProp, 
    itemRef: itemRef, 
    itemScope: itemScope, 
    itemType: itemType, 
    k: k, 
    k1: k1, 
    k2: k2, 
    k3: k3, 
    k4: k4, 
    kernelMatrix: kernelMatrix, 
    kernelUnitLength: kernelUnitLength, 
    kerning: kerning, 
    key: key, 
    keyPoints: keyPoints, 
    keySplines: keySplines, 
    keyTimes: keyTimes, 
    label: label, 
    lang: lang, 
    lengthAdjust: lengthAdjust, 
    letterSpacing: letterSpacing, 
    lightingColor: lightingColor, 
    limitingConeAngle: limitingConeAngle, 
    list: list, 
    local: local, 
    loop: loop, 
    manifest: manifest, 
    marginHeight: marginHeight, 
    marginWidth: marginWidth, 
    markerEnd: markerEnd, 
    markerHeight: markerHeight, 
    markerMid: markerMid, 
    markerStart: markerStart, 
    markerUnits: markerUnits, 
    markerWidth: markerWidth, 
    mask: mask, 
    maskContentUnits: maskContentUnits, 
    maskUnits: maskUnits, 
    mathematical: mathematical, 
    max: max, 
    maxLength: maxLength, 
    media: media, 
    mediaGroup: mediaGroup, 
    method: method, 
    min: min, 
    mode: mode, 
    multiple: multiple, 
    muted: muted, 
    name: name, 
    noValidate: noValidate, 
    numOctaves: numOctaves, 
    offset: offset, 
    opacity: opacity, 
    open: open, 
    operator: operator, 
    order: order, 
    orient: orient, 
    orientation: orientation, 
    origin: origin, 
    overflow: overflow, 
    overlinePosition: overlinePosition, 
    overlineThickness: overlineThickness, 
    paintOrder: paintOrder, 
    panose1: panose1, 
    pathLength: pathLength, 
    pattern: pattern, 
    patternContentUnits: patternContentUnits, 
    patternTransform: patternTransform, 
    patternUnits: patternUnits, 
    placeholder: placeholder, 
    pointerEvents: pointerEvents, 
    points: points, 
    pointsAtX: pointsAtX, 
    pointsAtY: pointsAtY, 
    pointsAtZ: pointsAtZ, 
    poster: poster, 
    preload: preload, 
    preserveAlpha: preserveAlpha, 
    preserveAspectRatio: preserveAspectRatio, 
    primitiveUnits: primitiveUnits, 
    r: r, 
    radioGroup: radioGroup, 
    radius: radius, 
    readOnly: readOnly, 
    refX: refX, 
    refY: refY, 
    rel: rel, 
    renderingIntent: renderingIntent, 
    repeatCount: repeatCount, 
    repeatDur: repeatDur, 
    required: required, 
    requiredExtensions: requiredExtensions, 
    requiredFeatures: requiredFeatures, 
    restart: restart, 
    result: result, 
    results: results, 
    role: role, 
    rotate: rotate, 
    rowSpan: rowSpan, 
    rows: rows, 
    rx: rx, 
    ry: ry, 
    sandbox: sandbox, 
    scale: scale, 
    scope: scope, 
    scoped: scoped, 
    scrolling: scrolling, 
    seamless: seamless, 
    security: security, 
    seed: seed, 
    selected: selected, 
    shape: shape, 
    shapeRendering: shapeRendering, 
    size: size, 
    sizes: sizes, 
    slope: slope, 
    spacing: spacing, 
    span_: span_, 
    specularConstant: specularConstant, 
    specularExponent: specularExponent, 
    speed: speed, 
    spellCheck: spellCheck, 
    spreadMethod: spreadMethod, 
    src: src, 
    srcDoc: srcDoc, 
    srcSet: srcSet, 
    start: start, 
    startOffset: startOffset, 
    stdDeviation: stdDeviation, 
    stemh: stemh, 
    stemv: stemv, 
    step: step, 
    stitchTiles: stitchTiles, 
    stopColor: stopColor, 
    stopOpacity: stopOpacity, 
    strikethroughPosition: strikethroughPosition, 
    strikethroughThickness: strikethroughThickness, 
    string: string, 
    stroke: stroke, 
    strokeDasharray: strokeDasharray, 
    strokeDashoffset: strokeDashoffset, 
    strokeLinecap: strokeLinecap, 
    strokeLinejoin: strokeLinejoin, 
    strokeMiterlimit: strokeMiterlimit, 
    strokeOpacity: strokeOpacity, 
    strokeWidth: strokeWidth, 
    style: style, 
    surfaceScale: surfaceScale, 
    systemLanguage: systemLanguage, 
    tabIndex: tabIndex, 
    tableValues: tableValues, 
    target: target, 
    targetX: targetX, 
    targetY: targetY, 
    textAnchor: textAnchor, 
    textDecoration: textDecoration, 
    textLength: textLength, 
    textRendering: textRendering, 
    title: title, 
    to: to, 
    transform: transform, 
    type_: type_, 
    u1: u1, 
    u2: u2, 
    underlinePosition: underlinePosition, 
    underlineThickness: underlineThickness, 
    unicode: unicode, 
    unicodeBidi: unicodeBidi, 
    unicodeRange: unicodeRange, 
    unitsPerEm: unitsPerEm, 
    unselectable: unselectable, 
    useMap: useMap, 
    vAlphabetic: vAlphabetic, 
    vHanging: vHanging, 
    vIdeographic: vIdeographic, 
    vMathematical: vMathematical, 
    value: value, 
    values: values, 
    vectorEffect: vectorEffect, 
    version: version, 
    vertAdvY: vertAdvY, 
    vertOriginX: vertOriginX, 
    vertOriginY: vertOriginY, 
    viewBox: viewBox, 
    viewTarget: viewTarget, 
    visibility: visibility, 
    width: width, 
    widths: widths, 
    wmode: wmode, 
    wordSpacing: wordSpacing, 
    writingMode: writingMode, 
    x: x, 
    x1: x1, 
    x2: x2, 
    xChannelSelector: xChannelSelector, 
    xHeight: xHeight, 
    xlinkActuate: xlinkActuate, 
    xlinkArcrole: xlinkArcrole, 
    xlinkHref: xlinkHref, 
    xlinkRole: xlinkRole, 
    xlinkShow: xlinkShow, 
    xlinkTitle: xlinkTitle, 
    xlinkType: xlinkType, 
    xmlBase: xmlBase, 
    xmlLang: xmlLang, 
    xmlSpace: xmlSpace, 
    y: y, 
    y1: y1, 
    y2: y2, 
    yChannelSelector: yChannelSelector, 
    z: z, 
    zoomAndPan: zoomAndPan, 
    attr: $foreign.attr
};
