// Generated by psc version 0.9.1
"use strict";
var Control_Monad_Aff = require("../Control.Monad.Aff");
var Control_Monad_Eff = require("../Control.Monad.Eff");
var Control_Monad_Eff_Class = require("../Control.Monad.Eff.Class");
var Data_Either = require("../Data.Either");
var Data_Firebase_SnapshotToForeignLocation = require("../Data.Firebase.SnapshotToForeignLocation");
var Data_Firebase_Subscription = require("../Data.Firebase.Subscription");
var Data_Foldable = require("../Data.Foldable");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_ForeignLocation = require("../Data.ForeignLocation");
var Data_PathTo = require("../Data.PathTo");
var Data_Tuple = require("../Data.Tuple");
var Ports_Firebase_Eff = require("../Ports.Firebase.Eff");
var Ports_Firebase_Types = require("../Ports.Firebase.Types");
var Prelude = require("../Prelude");
var Signal_Channel = require("../Signal.Channel");
var State_ApplyFound = require("../State.ApplyFound");
var State_EffModelHelpers = require("../State.EffModelHelpers");
var State_Types = require("../State.Types");
var UReview_UserNotifications = require("../UReview.UserNotifications");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var Data_Semigroup = require("../Data.Semigroup");
var Data_Show = require("../Data.Show");
var Control_Bind = require("../Control.Bind");
var Data_Function = require("../Data.Function");
var Web_Firebase = require("../Web.Firebase");
var Web_Firebase_Types = require("../Web.Firebase.Types");
var Control_Applicative = require("../Control.Applicative");
var nextLevelPath = function (subPathBuilder) {
    return function ($69) {
        return Data_Tuple.uncurry(subPathBuilder)(Data_ForeignLocation.pathKey($69));
    };
};
var mkRealSender = function (channel) {
    return function (applyFound) {
        return {
            sendError: function (m) {
                return UReview_UserNotifications.sendErrorMessage(channel)(m);
            }, 
            sendSuccess: function (childAddedSuccess) {
                return Signal_Channel.send(channel)(new State_Types.DoChildAdded(applyFound, childAddedSuccess));
            }
        };
    };
};
var errorCb = function (dictShow) {
    return function (sender) {
        return function (path) {
            return function (err) {
                var errorText = "Subscribing failed " + Data_Show.show(dictShow)(err);
                return sender.sendError("Subscribing to " + (path + (" failed. Underlying error: " + errorText)));
            };
        };
    };
};
var childAddedCb = function (sender) {
    return function (path) {
        return function (recurse) {
            return function (snapshot) {
                var $11 = Data_Firebase_SnapshotToForeignLocation.snapshotToForeignLocation(path)(snapshot);
                if ($11 instanceof Data_Either.Left) {
                    return sender.sendError($11.value0);
                };
                if ($11 instanceof Data_Either.Right) {
                    return sender.sendSuccess({
                        fdo: $11.value0, 
                        nextLevel: recurse
                    });
                };
                throw new Error("Failed pattern match at State.DataDependencyInterpreter line 128, column 45 - line 130, column 88: " + [ $11.constructor.name ]);
            };
        };
    };
};
var doSubscribe = function (v) {
    var sender = mkRealSender(v.state.channel)(v.stateChanger);
    return Control_Bind.bind(Control_Monad_Aff.bindAff)(Data_Function.apply(Control_Monad_Eff_Class.liftEff(Control_Monad_Aff.monadEffAff))(Web_Firebase.child(v.path)(v.state.rootRef)))(function (v1) {
        return Data_Function.apply(Control_Monad_Eff_Class.liftEff(Control_Monad_Aff.monadEffAff))(Web_Firebase.on(v.dbEvent)(childAddedCb(sender)(v.path)(v.recurse))(errorCb(Web_Firebase_Types.showFirebaseErr)(sender)(v.path))(v1));
    });
};
var subscribeInFirebase = function (v) {
    return Data_Function.apply(Control_Applicative.unless(Control_Monad_Aff.applicativeAff)(Data_Firebase_Subscription.isSubscribed(v.path)(v.dbEvent)(v.state.firebaseSubscriptions)))(doSubscribe(v));
};
var subscribeCreateOrUpdate = function (v) {
    return {
        state: (function () {
            var $28 = {};
            for (var $29 in v.state) {
                if (v.state.hasOwnProperty($29)) {
                    $28[$29] = v.state[$29];
                };
            };
            $28.firebaseSubscriptions = Data_Firebase_Subscription.subscribed(v.path)(v.dbEvent)(v.state.firebaseSubscriptions);
            return $28;
        })(), 
        effects: Data_Function.apply(State_EffModelHelpers.pureNoop(Control_Monad_Aff.applyAff)(Control_Monad_Aff.applicativeAff))(subscribeInFirebase(v))
    };
};
var subscribeLeafAdded = function (dictPathTo) {
    return function (p) {
        return function (stateChanger) {
            return function (state) {
                var recurse = [  ];
                var path = Data_PathTo.pathTo(dictPathTo)(p);
                var cu = {
                    stateChanger: stateChanger, 
                    dbEvent: Web_Firebase.ChildAdded.value, 
                    path: path, 
                    recurse: recurse, 
                    state: state
                };
                return subscribeCreateOrUpdate(cu);
            };
        };
    };
};
var subscribeWithPath = function (v) {
    return function (path) {
        return function (state) {
            if (v instanceof State_Types.Added) {
                return subscribeCreateOrUpdate({
                    stateChanger: v.value2, 
                    dbEvent: Web_Firebase.ChildAdded.value, 
                    path: path, 
                    recurse: v.value3, 
                    state: state
                });
            };
            if (v instanceof State_Types.Changed) {
                return subscribeCreateOrUpdate({
                    stateChanger: v.value2, 
                    dbEvent: Web_Firebase.Value.value, 
                    path: path, 
                    recurse: v.value3, 
                    state: state
                });
            };
            throw new Error("Failed pattern match at State.DataDependencyInterpreter line 55, column 1 - line 58, column 25: " + [ v.constructor.name, path.constructor.name, state.constructor.name ]);
        };
    };
};
var inner = function (fdo) {
    return function (v) {
        return function (state) {
            if (v instanceof State_Types.Added) {
                var path = nextLevelPath(v.value1)(fdo);
                return subscribeWithPath(v)(path)(state);
            };
            if (v instanceof State_Types.Changed) {
                var path = nextLevelPath(v.value1)(fdo);
                return subscribeWithPath(v)(path)(state);
            };
            throw new Error("Failed pattern match at State.DataDependencyInterpreter line 163, column 1 - line 165, column 40: " + [ fdo.constructor.name, v.constructor.name, state.constructor.name ]);
        };
    };
};
var registerANextLevelSubscription = function (fdo) {
    return function (effModel) {
        return function (dep) {
            return State_EffModelHelpers.applyEffModel(inner(fdo)(dep))(effModel);
        };
    };
};
var subscribeNextLevel = function (v) {
    return function (before) {
        return Data_Foldable.foldl(Data_Foldable.foldableArray)(registerANextLevelSubscription(v.fdo))(State_EffModelHelpers.noop(before))(v.nextLevel);
    };
};
var subscribeTopLevel = function (v) {
    return function (state) {
        if (v instanceof State_Types.Added) {
            return subscribeWithPath(v)(v.value1("")(Data_ForeignKey.mkForeignKey("")))(state);
        };
        if (v instanceof State_Types.Changed) {
            return subscribeWithPath(v)(v.value1("")(Data_ForeignKey.mkForeignKey("")))(state);
        };
        throw new Error("Failed pattern match at State.DataDependencyInterpreter line 49, column 1 - line 50, column 64: " + [ v.constructor.name, state.constructor.name ]);
    };
};
var subscribeAll = function (dictFoldable) {
    return function (deps) {
        return function (s) {
            var subscribeAnother = function (effModel) {
                return function (dep) {
                    return State_EffModelHelpers.applyEffModel(subscribeTopLevel(dep))(effModel);
                };
            };
            return Data_Foldable.foldl(dictFoldable)(subscribeAnother)(State_EffModelHelpers.noop(s))(deps);
        };
    };
};
module.exports = {
    childAddedCb: childAddedCb, 
    nextLevelPath: nextLevelPath, 
    registerANextLevelSubscription: registerANextLevelSubscription, 
    subscribeAll: subscribeAll, 
    subscribeLeafAdded: subscribeLeafAdded, 
    subscribeNextLevel: subscribeNextLevel, 
    subscribeTopLevel: subscribeTopLevel, 
    subscribeWithPath: subscribeWithPath
};
