// Generated by psc version 0.9.1
"use strict";
var Web_Firebase = require("../Web.Firebase");
var Control_Monad_Aff = require("../Control.Monad.Aff");
var Control_Monad_Eff = require("../Control.Monad.Eff");
var Data_Firebase_WriteParentChild = require("../Data.Firebase.WriteParentChild");
var Data_ForeignKey = require("../Data.ForeignKey");
var Model_SessionDescription = require("../Model.SessionDescription");
var Prelude = require("../Prelude");
var Ports_Firebase_Aff = require("../Ports.Firebase.Aff");
var Ports_Firebase_Types = require("../Ports.Firebase.Types");
var Web_Firebase_Aff = require("../Web.Firebase.Aff");
var Data_Semigroup = require("../Data.Semigroup");
var Control_Bind = require("../Control.Bind");
var sessionDescriptionsPath = "/sessiondescriptions/";
var refToSessionDescriptions = Web_Firebase_Aff.child(sessionDescriptionsPath);
var refToSessionDescription = function (key) {
    return function (ref) {
        return Web_Firebase.child("/sessiondescriptions/" + key)(ref);
    };
};
var createSessionDescription = function (v) {
    return function (rootRef) {
        return Control_Bind.bind(Control_Monad_Aff.bindAff)(refToSessionDescriptions(rootRef))(function (v1) {
            return Control_Bind.bind(Control_Monad_Aff.bindAff)(Web_Firebase_Aff.child("/events/" + (Data_ForeignKey.fkToString(v.eventId) + "/sessiondescriptions"))(rootRef))(function (v2) {
                return Data_Firebase_WriteParentChild.pushChild(Model_SessionDescription.toFirebaseForeignSessionDescription)(v)(v1)(v2);
            });
        });
    };
};
module.exports = {
    createSessionDescription: createSessionDescription, 
    refToSessionDescriptions: refToSessionDescriptions
};
