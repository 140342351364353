// Generated by psc version 0.9.1
"use strict";
var Components_ManageReviewPanel_AddReviewerView = require("../Components.ManageReviewPanel.AddReviewerView");
var Components_ManageReviewPanel_ReviewerListView = require("../Components.ManageReviewPanel.ReviewerListView");
var Components_ManageReviewPanel_Types = require("../Components.ManageReviewPanel.Types");
var Control_Alt = require("../Control.Alt");
var Data_Array = require("../Data.Array");
var Model_Event = require("../Model.Event");
var Model_UserProfile = require("../Model.UserProfile");
var Prelude = require("../Prelude");
var Pux_Html = require("../Pux.Html");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var State_Event = require("../State.Event");
var State_Types = require("../State.Types");
var State_UserProfiles = require("../State.UserProfiles");
var Data_Functor = require("../Data.Functor");
var Data_Function = require("../Data.Function");
var renderReviewPanel = function (sortedUsers) {
    return function (potentialReviewers) {
        return function (v) {
            return function (componentState) {
                return [ Pux_Html_Elements.h3([  ])([ Pux_Html_Elements.text("Reviewers") ]), Components_ManageReviewPanel_ReviewerListView.listActivatedReviewers(sortedUsers)(Model_Event.activatedReviewerKeys(v))(v.reviewers), Components_ManageReviewPanel_ReviewerListView.listDeactivatedReviewers(sortedUsers)(Model_Event.deActivatedReviewerKeys(v))(v.reviewers), Data_Functor.map(Pux_Html_Elements.functorHtml)(State_Types.ChooseReviewerToAdd.create)(Components_ManageReviewPanel_AddReviewerView.addReviewerComponent(potentialReviewers)(componentState)) ];
            };
        };
    };
};
var manageReviewPanel = function (v) {
    return function (state) {
        var sortedUsers = State_UserProfiles.usersSortedByName(state);
        var potentialReviewers = Data_Function.apply(Data_Array.sort(Model_UserProfile.ordUserProfile))(State_Event.nonReviewingUsers(v)(state));
        return renderReviewPanel(sortedUsers)(potentialReviewers)(v)((state["new"]).reviewerFor);
    };
};
module.exports = {
    manageReviewPanel: manageReviewPanel
};
