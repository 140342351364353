// Generated by psc version 0.9.1
"use strict";
var $foreign = require("./foreign");
var Prelude = require("../Prelude");
var Data_Either = require("../Data.Either");
var Data_Function_Uncurried = require("../Data.Function.Uncurried");
var Data_Int = require("../Data.Int");
var Data_Maybe = require("../Data.Maybe");
var Data_String = require("../Data.String");
var Data_Show = require("../Data.Show");
var Data_Semigroup = require("../Data.Semigroup");
var Data_Eq = require("../Data.Eq");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra");
var Control_Applicative = require("../Control.Applicative");
var Data_Function = require("../Data.Function");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var TypeMismatch = (function () {
    function TypeMismatch(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    TypeMismatch.create = function (value0) {
        return function (value1) {
            return new TypeMismatch(value0, value1);
        };
    };
    return TypeMismatch;
})();
var ErrorAtIndex = (function () {
    function ErrorAtIndex(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ErrorAtIndex.create = function (value0) {
        return function (value1) {
            return new ErrorAtIndex(value0, value1);
        };
    };
    return ErrorAtIndex;
})();
var ErrorAtProperty = (function () {
    function ErrorAtProperty(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ErrorAtProperty.create = function (value0) {
        return function (value1) {
            return new ErrorAtProperty(value0, value1);
        };
    };
    return ErrorAtProperty;
})();
var JSONError = (function () {
    function JSONError(value0) {
        this.value0 = value0;
    };
    JSONError.create = function (value0) {
        return new JSONError(value0);
    };
    return JSONError;
})();
var unsafeReadTagged = function (tag) {
    return function (value) {
        if ($foreign.tagOf(value) === tag) {
            return Control_Applicative.pure(Data_Either.applicativeEither)($foreign.unsafeFromForeign(value));
        };
        return new Data_Either.Left(new TypeMismatch(tag, $foreign.tagOf(value)));
    };
};
var showForeignError = new Data_Show.Show(function (v) {
    if (v instanceof TypeMismatch) {
        return "Type mismatch: expected " + (v.value0 + (", found " + v.value1));
    };
    if (v instanceof ErrorAtIndex) {
        return "Error at array index " + (Data_Show.show(Data_Show.showInt)(v.value0) + (": " + Data_Show.show(showForeignError)(v.value1)));
    };
    if (v instanceof ErrorAtProperty) {
        return "Error at property " + (Data_Show.show(Data_Show.showString)(v.value0) + (": " + Data_Show.show(showForeignError)(v.value1)));
    };
    if (v instanceof JSONError) {
        return "JSON error: " + v.value0;
    };
    throw new Error("Failed pattern match at Data.Foreign line 53, column 3 - line 53, column 87: " + [ v.constructor.name ]);
});
var readString = unsafeReadTagged("String");
var readNumber = unsafeReadTagged("Number");
var readInt = function (value) {
    var error = Data_Function.apply(Data_Either.Left.create)(new TypeMismatch("Int", $foreign.tagOf(value)));
    var fromNumber = function ($30) {
        return Data_Maybe.maybe(error)(Control_Applicative.pure(Data_Either.applicativeEither))(Data_Int.fromNumber($30));
    };
    return Data_Either.either(Data_Function["const"](error))(fromNumber)(readNumber(value));
};
var readChar = function (value) {
    var error = Data_Function.apply(Data_Either.Left.create)(new TypeMismatch("Char", $foreign.tagOf(value)));
    var fromString = function ($31) {
        return Data_Maybe.maybe(error)(Control_Applicative.pure(Data_Either.applicativeEither))(Data_String.toChar($31));
    };
    return Data_Either.either(Data_Function["const"](error))(fromString)(readString(value));
};
var readBoolean = unsafeReadTagged("Boolean");
var readArray = function (value) {
    if ($foreign.isArray(value)) {
        return Data_Function.apply(Control_Applicative.pure(Data_Either.applicativeEither))($foreign.unsafeFromForeign(value));
    };
    return new Data_Either.Left(new TypeMismatch("array", $foreign.tagOf(value)));
};
var parseJSON = function (json) {
    return Data_Function_Uncurried.runFn3($foreign.parseJSONImpl)(function ($32) {
        return Data_Either.Left.create(JSONError.create($32));
    })(Data_Either.Right.create)(json);
};
var eqForeignError = new Data_Eq.Eq(function (v) {
    return function (v1) {
        if (v instanceof TypeMismatch && v1 instanceof TypeMismatch) {
            return v.value0 === v1.value0 && v.value1 === v1.value1;
        };
        if (v instanceof ErrorAtIndex && v1 instanceof ErrorAtIndex) {
            return v.value0 === v1.value0 && Data_Eq.eq(eqForeignError)(v.value1)(v1.value1);
        };
        if (v instanceof ErrorAtProperty && v1 instanceof ErrorAtProperty) {
            return v.value0 === v1.value0 && Data_Eq.eq(eqForeignError)(v.value1)(v1.value1);
        };
        if (v instanceof JSONError && v1 instanceof JSONError) {
            return v.value0 === v1.value0;
        };
        return false;
    };
});
module.exports = {
    TypeMismatch: TypeMismatch, 
    ErrorAtIndex: ErrorAtIndex, 
    ErrorAtProperty: ErrorAtProperty, 
    JSONError: JSONError, 
    parseJSON: parseJSON, 
    readArray: readArray, 
    readBoolean: readBoolean, 
    readChar: readChar, 
    readInt: readInt, 
    readNumber: readNumber, 
    readString: readString, 
    unsafeReadTagged: unsafeReadTagged, 
    showForeignError: showForeignError, 
    eqForeignError: eqForeignError, 
    isArray: $foreign.isArray, 
    isNull: $foreign.isNull, 
    isUndefined: $foreign.isUndefined, 
    tagOf: $foreign.tagOf, 
    toForeign: $foreign.toForeign, 
    typeOf: $foreign.typeOf, 
    unsafeFromForeign: $foreign.unsafeFromForeign
};
