// Generated by psc version 0.9.1
"use strict";
var Prelude = require("../Prelude");
var Data_Generic = require("../Data.Generic");
var CSS_String = require("../CSS.String");
var CSS_Property = require("../CSS.Property");
var Data_Eq = require("../Data.Eq");
var Data_Ord = require("../Data.Ord");
var Control_Apply = require("../Control.Apply");
var Data_Maybe = require("../Data.Maybe");
var Data_Unit = require("../Data.Unit");
var Data_Function = require("../Data.Function");
var TimingFunction = function (x) {
    return x;
};
var valTimingFunction = new CSS_Property.Val(function (v) {
    return v;
});
var linear = Data_Function.apply(TimingFunction)(CSS_String.fromString(CSS_Property.isStringValue)("linear"));
var genericTimingFunction = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "CSS.Transition.TimingFunction" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(TimingFunction))(Data_Generic.fromSpine(CSS_Property.genericValue)(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("CSS.Transition.TimingFunction", [ {
        sigConstructor: "CSS.Transition.TimingFunction", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(CSS_Property.genericValue)(Data_Generic.anyProxy);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("CSS.Transition.TimingFunction", [ function ($dollarq) {
        return Data_Generic.toSpine(CSS_Property.genericValue)(v);
    } ]);
});
var eqTimingFunction = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(CSS_Property.eqValue)(x)(y);
    };
});
var ordTimingFunction = new Data_Ord.Ord(function () {
    return eqTimingFunction;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(CSS_Property.ordValue)(x)(y);
    };
});
var easeOut = Data_Function.apply(TimingFunction)(CSS_String.fromString(CSS_Property.isStringValue)("ease-out"));
module.exports = {
    TimingFunction: TimingFunction, 
    easeOut: easeOut, 
    linear: linear, 
    eqTimingFunction: eqTimingFunction, 
    ordTimingFunction: ordTimingFunction, 
    genericTimingFunction: genericTimingFunction, 
    valTimingFunction: valTimingFunction
};
