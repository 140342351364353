// Generated by psc version 0.9.1
"use strict";
var Components_DB_Action = require("../Components.DB.Action");
var Control_Monad_Aff = require("../Control.Monad.Aff");
var Data_Firebase_Foreign = require("../Data.Firebase.Foreign");
var Data_Firebase_Subscribe = require("../Data.Firebase.Subscribe");
var Data_List = require("../Data.List");
var Data_Maybe = require("../Data.Maybe");
var Data_StrMap = require("../Data.StrMap");
var Data_Tuple = require("../Data.Tuple");
var Model_CallForSessions = require("../Model.CallForSessions");
var Model_OpenCallForSessions_EventName = require("../Model.OpenCallForSessions.EventName");
var Model_Event = require("../Model.Event");
var Prelude = require("../Prelude");
var State_Types = require("../State.Types");
var WeReview_Data_Schema = require("../WeReview.Data.Schema");
var Ports_Firebase_Aff = require("../Ports.Firebase.Aff");
var Web_Firebase_DataSnapshot = require("../Web.Firebase.DataSnapshot");
var Ports_Firebase_Types = require("../Ports.Firebase.Types");
var Data_Semigroup = require("../Data.Semigroup");
var Data_Function = require("../Data.Function");
var Data_Foldable = require("../Data.Foldable");
var Control_Bind = require("../Control.Bind");
var Web_Firebase_Aff_Read = require("../Web.Firebase.Aff.Read");
var Control_Applicative = require("../Control.Applicative");
var Events = function (x) {
    return x;
};
var unWrap = function (v) {
    return v;
};
var saveEvent$prime = function (v) {
    var shortKey = Model_Event.getShortCode(v);
    var dbEvent = new Data_Tuple.Tuple(new WeReview_Data_Schema.Events(shortKey, WeReview_Data_Schema.EventObject.value), Data_Firebase_Foreign.toFire(Model_Event.toFirebaseForeignEvent)(v));
    var aboutConferenceBlurb = v.name + " is a [TBD conference description] conference that [TBD conference goal]\nThe call for speakers closes at [TBD Date].";
    var invitation = aboutConferenceBlurb + ("\n" + "All session proposals will be reviewed by our conference panel. The names, organisations, and other fields capturing personal data will NOT be shared with session reviewers. Anonymising reviews like this protects your personal data and means sessions are assessed entirely on their own merit.\nYour data will be held in the strictest confidence throughout the review process. If your session is accepted, we'll use the relevant information you have provided in the event programme and to promote the event.");
    var cfs = Model_CallForSessions.mkUnAdvertisedCallForSessions(shortKey)(Model_OpenCallForSessions_EventName.mkEventName(v.name))(invitation);
    var dbCfs = new Data_Tuple.Tuple(new WeReview_Data_Schema.OpenCallForSessions(shortKey, WeReview_Data_Schema.OpenCfSObject.value), Data_Firebase_Foreign.toFire(Model_CallForSessions.toFirebaseForeignOpenCallForSessions)(cfs));
    return Data_Function.apply(Components_DB_Action.SetValues.create)(Data_List.fromFoldable(Data_Foldable.foldableArray)([ dbEvent, dbCfs ]));
};
var applyEvent = function (aKey) {
    return function (snap) {
        return Control_Bind.bind(Control_Monad_Aff.bindAff)(Web_Firebase_Aff_Read.readSnapshot(Model_Event.isForeignEvent)(snap))(function (v) {
            return Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(new State_Types.ChangeState(function (state) {
                var $7 = {};
                for (var $8 in state) {
                    if (state.hasOwnProperty($8)) {
                        $7[$8] = state[$8];
                    };
                };
                $7.events = Data_StrMap.insert(aKey)(v)(state.events);
                return $7;
            }));
        });
    };
};
var eventAdded = function (v) {
    var k = Web_Firebase_DataSnapshot.key(v.value1);
    if (k instanceof Data_Maybe.Nothing) {
        return Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(State_Types.Noop.value);
    };
    if (k instanceof Data_Maybe.Just) {
        return applyEvent(k.value0)(v.value1);
    };
    throw new Error("Failed pattern match at Data.Firebase.Event line 48, column 3 - line 50, column 38: " + [ k.constructor.name ]);
};
module.exports = {
    "saveEvent'": saveEvent$prime
};
