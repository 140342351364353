// Generated by psc version 0.9.1
"use strict";
var Prelude = require("../Prelude");
var Data_Array = require("../Data.Array");
var Data_Either = require("../Data.Either");
var Data_Foldable = require("../Data.Foldable");
var Data_Int = require("../Data.Int");
var Data_Int_Bits = require("../Data.Int.Bits");
var Data_Maybe = require("../Data.Maybe");
var Data_String = require("../Data.String");
var Data_String_Regex = require("../Data.String.Regex");
var $$Math = require("../Math");
var Partial_Unsafe = require("../Partial.Unsafe");
var Data_Show = require("../Data.Show");
var Data_Semigroup = require("../Data.Semigroup");
var Data_Eq = require("../Data.Eq");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra");
var Data_EuclideanRing = require("../Data.EuclideanRing");
var Data_Semiring = require("../Data.Semiring");
var Data_Ring = require("../Data.Ring");
var Data_Ord = require("../Data.Ord");
var Data_Boolean = require("../Data.Boolean");
var Data_Function = require("../Data.Function");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var Control_Bind = require("../Control.Bind");
var Data_Functor = require("../Data.Functor");
var Control_Applicative = require("../Control.Applicative");
var RGB = (function () {
    function RGB() {

    };
    RGB.value = new RGB();
    return RGB;
})();
var HSL = (function () {
    function HSL() {

    };
    HSL.value = new HSL();
    return HSL;
})();
var LCh = (function () {
    function LCh() {

    };
    LCh.value = new LCh();
    return LCh;
})();
var Lab = (function () {
    function Lab() {

    };
    Lab.value = new Lab();
    return Lab;
})();
var HSLA = (function () {
    function HSLA(value0, value1, value2, value3) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
        this.value3 = value3;
    };
    HSLA.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return function (value3) {
                    return new HSLA(value0, value1, value2, value3);
                };
            };
        };
    };
    return HSLA;
})();
var toRGBA$prime = function (v) {
    var h$prime = v.value0 / 60.0;
    var chr = (1.0 - $$Math.abs(2.0 * v.value2 - 1.0)) * v.value1;
    var m = v.value2 - chr / 2.0;
    var x = chr * (1.0 - $$Math.abs($$Math.remainder(h$prime)(2.0) - 1.0));
    var col = (function () {
        if (h$prime < 1.0) {
            return {
                r: chr, 
                g: x, 
                b: 0.0
            };
        };
        if (1.0 <= h$prime && h$prime < 2.0) {
            return {
                r: x, 
                g: chr, 
                b: 0.0
            };
        };
        if (2.0 <= h$prime && h$prime < 3.0) {
            return {
                r: 0.0, 
                g: chr, 
                b: x
            };
        };
        if (3.0 <= h$prime && h$prime < 4.0) {
            return {
                r: 0.0, 
                g: x, 
                b: chr
            };
        };
        if (4.0 <= h$prime && h$prime < 5.0) {
            return {
                r: x, 
                g: 0.0, 
                b: chr
            };
        };
        if (Data_Boolean.otherwise) {
            return {
                r: chr, 
                g: 0.0, 
                b: x
            };
        };
        throw new Error("Failed pattern match at Color line 295, column 1 - line 306, column 61: " + [  ]);
    })();
    return {
        r: col.r + m, 
        g: col.g + m, 
        b: col.b + m, 
        a: v.value3
    };
};
var toXYZ = function (c) {
    var rec = toRGBA$prime(c);
    var finv = function (c1) {
        if (c1 <= 4.045e-2) {
            return c1 / 12.92;
        };
        if (Data_Boolean.otherwise) {
            return $$Math.pow((c1 + 5.5e-2) / 1.055)(2.4);
        };
        throw new Error("Failed pattern match at Color line 314, column 1 - line 328, column 1: " + [ c1.constructor.name ]);
    };
    var g = finv(rec.g);
    var r = finv(rec.r);
    var b = finv(rec.b);
    var x = 0.4124 * r + 0.3576 * g + 0.1805 * b;
    var y = 0.2126 * r + 0.7152 * g + 7.22e-2 * b;
    var z = 1.93e-2 * r + 0.1192 * g + 0.9505 * b;
    return {
        x: x, 
        y: y, 
        z: z
    };
};
var toRGBA = function (v) {
    var c = toRGBA$prime(v);
    var g = Data_Int.round(255.0 * c.g);
    var r = Data_Int.round(255.0 * c.r);
    var b = Data_Int.round(255.0 * c.b);
    return {
        r: r, 
        g: g, 
        b: b, 
        a: v.value3
    };
};
var toHexString = function (color) {
    var toHex = Data_Int.toStringAs(Data_Int.hexadecimal);
    var c = toRGBA(color);
    return "#" + (toHex(c.r) + (toHex(c.g) + toHex(c.b)));
};
var toHSLA = function (v) {
    return {
        h: v.value0, 
        s: v.value1, 
        l: v.value2, 
        a: v.value3
    };
};
var showColor = new Data_Show.Show(function (c) {
    var col = toRGBA(c);
    return "rgba " + (Data_Show.show(Data_Show.showInt)(col.r) + (" " + (Data_Show.show(Data_Show.showInt)(col.g) + (" " + (Data_Show.show(Data_Show.showInt)(col.b) + (" " + Data_Show.show(Data_Show.showNumber)(col.a)))))));
});
var modPos = function (x) {
    return function (y) {
        return $$Math.remainder($$Math.remainder(x)(y) + y)(y);
    };
};
var rgba = function (red$prime) {
    return function (green$prime) {
        return function (blue$prime) {
            return function (alpha) {
                var red = Data_Ord.clamp(Data_Ord.ordInt)(0)(255)(red$prime);
                var r = Data_Int.toNumber(red) / 255.0;
                var green = Data_Ord.clamp(Data_Ord.ordInt)(0)(255)(green$prime);
                var g = Data_Int.toNumber(green) / 255.0;
                var blue = Data_Ord.clamp(Data_Ord.ordInt)(0)(255)(blue$prime);
                var maxChroma = Data_Ord.max(Data_Ord.ordInt)(Data_Ord.max(Data_Ord.ordInt)(red)(green))(blue);
                var minChroma = Data_Ord.min(Data_Ord.ordInt)(Data_Ord.min(Data_Ord.ordInt)(red)(green))(blue);
                var chroma = maxChroma - minChroma;
                var chroma$prime = Data_Int.toNumber(chroma) / 255.0;
                var lightness = Data_Int.toNumber(maxChroma + minChroma | 0) / (255.0 * 2.0);
                var saturation = (function () {
                    if (chroma === 0) {
                        return 0.0;
                    };
                    if (Data_Boolean.otherwise) {
                        return chroma$prime / (1.0 - $$Math.abs(2.0 * lightness - 1.0));
                    };
                    throw new Error("Failed pattern match at Color line 118, column 1 - line 146, column 75: " + [  ]);
                })();
                var b = Data_Int.toNumber(blue) / 255.0;
                var hue$prime = function (v) {
                    if (v === 0) {
                        return 0.0;
                    };
                    if (maxChroma === red) {
                        return modPos((g - b) / chroma$prime)(6.0);
                    };
                    if (maxChroma === green) {
                        return (b - r) / chroma$prime + 2.0;
                    };
                    if (Data_Boolean.otherwise) {
                        return (r - g) / chroma$prime + 4.0;
                    };
                    throw new Error("Failed pattern match at Color line 118, column 1 - line 146, column 75: " + [ v.constructor.name ]);
                };
                var hue = 60.0 * hue$prime(chroma);
                return new HSLA(hue, saturation, lightness, alpha);
            };
        };
    };
};
var rgb = function (r) {
    return function (g) {
        return function (b) {
            return rgba(r)(g)(b)(1.0);
        };
    };
};
var rgba$prime = function (r) {
    return function (g) {
        return function (b) {
            return function (a) {
                return rgba(Data_Function.apply(Data_Int.round)(r * 255.0))(Data_Function.apply(Data_Int.round)(g * 255.0))(Data_Function.apply(Data_Int.round)(b * 255.0))(a);
            };
        };
    };
};
var rgb$prime = function (r) {
    return function (g) {
        return function (b) {
            return rgba$prime(r)(g)(b)(1.0);
        };
    };
};
var xyz = function (x) {
    return function (y) {
        return function (z) {
            var f = function (c) {
                if (c <= 3.1308e-3) {
                    return 12.92 * c;
                };
                if (Data_Boolean.otherwise) {
                    return 1.055 * $$Math.pow(c)(1.0 / 2.4) - 5.5e-2;
                };
                throw new Error("Failed pattern match at Color line 193, column 5 - line 193, column 50: " + [ c.constructor.name ]);
            };
            var g = f(-0.9689 * x + 1.8758 * y + 4.15e-2 * z);
            var r = f(3.2406 * x - 1.5372 * y - 0.4986 * z);
            var b = f((5.57e-2 * x - 0.204 * y) + 1.057 * z);
            return rgb$prime(r)(g)(b);
        };
    };
};
var luminance = function (col) {
    var val = toRGBA$prime(col);
    var f = function (c) {
        if (c <= 3.928e-2) {
            return c / 12.92;
        };
        if (Data_Boolean.otherwise) {
            return $$Math.pow((c + 5.5e-2) / 1.055)(2.4);
        };
        throw new Error("Failed pattern match at Color line 518, column 1 - line 526, column 26: " + [ c.constructor.name ]);
    };
    var g = f(val.g);
    var r = f(val.r);
    var b = f(val.b);
    return 0.2126 * r + 0.7152 * g + 7.22e-2 * b;
};
var interpolate = function (fraction) {
    return function (a) {
        return function (b) {
            return a + fraction * (b - a);
        };
    };
};
var interpolateAngle = function (fraction) {
    return function (a) {
        return function (b) {
            var paths = [ {
                from: a, 
                to: b
            }, {
                from: a, 
                to: b + 360.0
            }, {
                from: a + 360.0, 
                to: b
            } ];
            var dist = function (v) {
                return $$Math.abs(v.to - v.from);
            };
            var shortest = Partial_Unsafe.unsafePartial(function (dictPartial) {
                return Data_Maybe.fromJust(dictPartial)(Data_Foldable.minimumBy(Data_Foldable.foldableArray)(Data_Ord.comparing(Data_Ord.ordNumber)(dist))(paths));
            });
            return interpolate(fraction)(shortest.from)(shortest.to);
        };
    };
};
var hsla = function (h) {
    return function (s) {
        return function (l) {
            return function (a) {
                var s$prime = Data_Ord.clamp(Data_Ord.ordNumber)(0.0)(1.0)(s);
                var l$prime = Data_Ord.clamp(Data_Ord.ordNumber)(0.0)(1.0)(l);
                var h$prime = modPos(h)(360.0);
                var a$prime = Data_Ord.clamp(Data_Ord.ordNumber)(0.0)(1.0)(a);
                return new HSLA(h$prime, s$prime, l$prime, a$prime);
            };
        };
    };
};
var lighten = function (f) {
    return function (v) {
        return hsla(v.value0)(v.value1)(v.value2 + f)(v.value3);
    };
};
var rotateHue = function (angle) {
    return function (v) {
        return hsla(v.value0 + angle)(v.value1)(v.value2)(v.value3);
    };
};
var saturate = function (f) {
    return function (v) {
        return hsla(v.value0)(v.value1 + f)(v.value2)(v.value3);
    };
};
var hsl = function (h) {
    return function (s) {
        return function (l) {
            return hsla(h)(s)(l)(1.0);
        };
    };
};
var white = hsl(0.0)(0.0)(1.0);
var graytone = function (l) {
    return hsl(0.0)(0.0)(l);
};
var fromInt = function (m) {
    var n = Data_Ord.clamp(Data_Ord.ordInt)(0)(16777215)(m);
    var r = Data_Int_Bits.and(n >> 16)(255);
    var g = Data_Int_Bits.and(n >> 8)(255);
    var b = Data_Int_Bits.and(n)(255);
    return rgb(r)(g)(b);
};
var fromHexString = function (str) {
    var parseHex = function ($78) {
        return Data_Maybe.fromMaybe(0)(Data_Int.fromStringAs(Data_Int.hexadecimal)($78));
    };
    var isShort = Data_String.length(str) === 4;
    var hush = Data_Either.either(Data_Function["const"](Data_Maybe.Nothing.value))(Data_Maybe.Just.create);
    var pair = "(" + ("[0-9a-f]" + ("[0-9a-f]" + ")"));
    var single = "(" + ("[0-9a-f]" + ")");
    var variant = (function () {
        if (isShort) {
            return single + (single + single);
        };
        if (!isShort) {
            return pair + (pair + pair);
        };
        throw new Error("Failed pattern match at Color line 255, column 15 - line 257, column 42: " + [ isShort.constructor.name ]);
    })();
    var mPattern = Data_String_Regex.regex("^#(?:" + (variant + ")$"))(Data_String_Regex.parseFlags("i"));
    return Control_Bind.bind(Data_Maybe.bindMaybe)(hush(mPattern))(function (v) {
        return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_String_Regex.match(v)(str))(function (v1) {
            return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(parseHex)(Control_Bind.join(Data_Maybe.bindMaybe)(Data_Array.index(v1)(1))))(function (v2) {
                return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(parseHex)(Control_Bind.join(Data_Maybe.bindMaybe)(Data_Array.index(v1)(2))))(function (v3) {
                    return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(parseHex)(Control_Bind.join(Data_Maybe.bindMaybe)(Data_Array.index(v1)(3))))(function (v4) {
                        if (isShort) {
                            return Data_Function.apply(Control_Applicative.pure(Data_Maybe.applicativeMaybe))(rgb((16 * v2 | 0) + v2 | 0)((16 * v3 | 0) + v3 | 0)((16 * v4 | 0) + v4 | 0));
                        };
                        if (!isShort) {
                            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(rgb(v2)(v3)(v4));
                        };
                        throw new Error("Failed pattern match at Color line 245, column 3 - line 249, column 23: " + [ isShort.constructor.name ]);
                    });
                });
            });
        });
    });
};
var eqColor = new Data_Eq.Eq(function (c1) {
    return function (c2) {
        var rgb2 = toRGBA(c2);
        var rgb1 = toRGBA(c1);
        return rgb1.r === rgb2.r && (rgb1.g === rgb2.g && (rgb1.b === rgb2.b && rgb1.a === rgb2.a));
    };
});
var desaturate = function (f) {
    return saturate(-f);
};
var darken = function (f) {
    return lighten(-f);
};
var d65 = {
    xn: 0.95047, 
    yn: 1.0, 
    zn: 1.08883
};
var lab = function (l) {
    return function (a) {
        return function (b) {
            var l$prime = (l + 16.0) / 116.0;
            var delta = 6.0 / 29.0;
            var finv = function (t) {
                if (t > delta) {
                    return $$Math.pow(t)(3.0);
                };
                if (Data_Boolean.otherwise) {
                    return 3.0 * delta * delta * (t - 4.0 / 29.0);
                };
                throw new Error("Failed pattern match at Color line 212, column 1 - line 221, column 64: " + [ t.constructor.name ]);
            };
            var x = d65.xn * finv(l$prime + a / 500.0);
            var y = d65.yn * finv(l$prime);
            var z = d65.zn * finv(l$prime - b / 200.0);
            return xyz(x)(y)(z);
        };
    };
};
var lch = function (l) {
    return function (c) {
        return function (h) {
            var deg2rad = $$Math.pi / 180.0;
            var b = c * $$Math.sin(h * deg2rad);
            var a = c * $$Math.cos(h * deg2rad);
            return lab(l)(a)(b);
        };
    };
};
var toLab = function (col) {
    var rec = toXYZ(col);
    var cut = $$Math.pow(6.0 / 29.0)(3.0);
    var f = function (t) {
        if (t > cut) {
            return $$Math.pow(t)(1.0 / 3.0);
        };
        if (Data_Boolean.otherwise) {
            return (1.0 / 3.0) * $$Math.pow(29.0 / 6.0)(2.0) * t + 4.0 / 29.0;
        };
        throw new Error("Failed pattern match at Color line 332, column 1 - line 346, column 1: " + [ t.constructor.name ]);
    };
    var fy = f(rec.y / d65.yn);
    var l = 116.0 * fy - 16.0;
    var b = 200.0 * (fy - f(rec.z / d65.zn));
    var a = 500.0 * (f(rec.x / d65.xn) - fy);
    return {
        l: l, 
        a: a, 
        b: b
    };
};
var distance = function (col1) {
    return function (col2) {
        var sq = function (x) {
            return $$Math.pow(x)(2.0);
        };
        var c2 = toLab(col2);
        var c1 = toLab(col1);
        return $$Math.sqrt(sq(c1.l - c2.l) + sq(c1.a - c2.a) + sq(c1.b - c2.b));
    };
};
var toLCh = function (col) {
    var rec = toLab(col);
    var rad2deg = 180.0 / $$Math.pi;
    var c = $$Math.sqrt(rec.a * rec.a + rec.b * rec.b);
    var h = modPos($$Math.atan2(rec.b)(rec.a) * rad2deg)(360.0);
    return {
        l: rec.l, 
        c: c, 
        h: h
    };
};
var mix = function (v) {
    return function (c1) {
        return function (c2) {
            return function (frac) {
                if (v instanceof HSL) {
                    var t = toHSLA(c2);
                    var f = toHSLA(c1);
                    return hsla(interpolateAngle(frac)(f.h)(t.h))(interpolate(frac)(f.s)(t.s))(interpolate(frac)(f.l)(t.l))(interpolate(frac)(f.a)(t.a));
                };
                if (v instanceof RGB) {
                    var t = toRGBA$prime(c2);
                    var f = toRGBA$prime(c1);
                    return rgba$prime(interpolate(frac)(f.r)(t.r))(interpolate(frac)(f.g)(t.g))(interpolate(frac)(f.b)(t.b))(interpolate(frac)(f.a)(t.a));
                };
                if (v instanceof LCh) {
                    var t = toLCh(c2);
                    var f = toLCh(c1);
                    return lch(interpolate(frac)(f.l)(t.l))(interpolate(frac)(f.c)(t.c))(interpolateAngle(frac)(f.h)(t.h));
                };
                if (v instanceof Lab) {
                    var t = toLab(c2);
                    var f = toLab(c1);
                    return lab(interpolate(frac)(f.l)(t.l))(interpolate(frac)(f.a)(t.a))(interpolate(frac)(f.b)(t.b));
                };
                throw new Error("Failed pattern match at Color line 472, column 1 - line 479, column 18: " + [ v.constructor.name, c1.constructor.name, c2.constructor.name, frac.constructor.name ]);
            };
        };
    };
};
var toGray = function (col) {
    var res = toLCh(col);
    return desaturate(1.0)(lch(res.l)(0.0)(0.0));
};
var cssStringRGBA = function (col) {
    var c = toRGBA(col);
    var green = Data_Show.show(Data_Show.showInt)(c.g);
    var red = Data_Show.show(Data_Show.showInt)(c.r);
    var blue = Data_Show.show(Data_Show.showInt)(c.b);
    var alpha = Data_Show.show(Data_Show.showNumber)(c.a);
    var $69 = c.a === 1.0;
    if ($69) {
        return "rgb(" + (red + (", " + (green + (", " + (blue + ")")))));
    };
    if (!$69) {
        return "rgba(" + (red + (", " + (green + (", " + (blue + (", " + (alpha + ")")))))));
    };
    throw new Error("Failed pattern match at Color line 389, column 3 - line 393, column 3: " + [ $69.constructor.name ]);
};
var cssStringHSLA = function (v) {
    var toString = function (n) {
        return Data_Function.apply(Data_Show.show(Data_Show.showNumber))(Data_Int.toNumber(Data_Int.round(100.0 * n)) / 100.0);
    };
    var saturation = toString(v.value1 * 100.0) + "%";
    var lightness = toString(v.value2 * 100.0) + "%";
    var hue = toString(v.value0);
    var alpha = Data_Show.show(Data_Show.showNumber)(v.value3);
    var $71 = v.value3 === 1.0;
    if ($71) {
        return "hsl(" + (hue + (", " + (saturation + (", " + (lightness + ")")))));
    };
    if (!$71) {
        return "hsla(" + (hue + (", " + (saturation + (", " + (lightness + (", " + (alpha + ")")))))));
    };
    throw new Error("Failed pattern match at Color line 375, column 3 - line 379, column 3: " + [ $71.constructor.name ]);
};
var contrast = function (c1) {
    return function (c2) {
        var l2 = luminance(c2);
        var l1 = luminance(c1);
        var $76 = l1 > l2;
        if ($76) {
            return (l1 + 5.0e-2) / (l2 + 5.0e-2);
        };
        if (!$76) {
            return (l2 + 5.0e-2) / (l1 + 5.0e-2);
        };
        throw new Error("Failed pattern match at Color line 535, column 18 - line 537, column 44: " + [ $76.constructor.name ]);
    };
};
var isReadable = function (c1) {
    return function (c2) {
        return contrast(c1)(c2) > 4.5;
    };
};
var complementary = rotateHue(180.0);
var brightness = function (col) {
    var c = toRGBA$prime(col);
    return (299.0 * c.r + 587.0 * c.g + 114.0 * c.b) / 1000.0;
};
var isLight = function (c) {
    return brightness(c) > 0.5;
};
var black = hsl(0.0)(0.0)(0.0);
var textColor = function (c) {
    if (isLight(c)) {
        return black;
    };
    if (Data_Boolean.otherwise) {
        return white;
    };
    throw new Error("Failed pattern match at Color line 563, column 1 - line 564, column 32: " + [ c.constructor.name ]);
};
module.exports = {
    RGB: RGB, 
    HSL: HSL, 
    LCh: LCh, 
    Lab: Lab, 
    black: black, 
    brightness: brightness, 
    complementary: complementary, 
    contrast: contrast, 
    cssStringHSLA: cssStringHSLA, 
    cssStringRGBA: cssStringRGBA, 
    darken: darken, 
    desaturate: desaturate, 
    distance: distance, 
    fromHexString: fromHexString, 
    fromInt: fromInt, 
    graytone: graytone, 
    hsl: hsl, 
    hsla: hsla, 
    isLight: isLight, 
    isReadable: isReadable, 
    lab: lab, 
    lch: lch, 
    lighten: lighten, 
    luminance: luminance, 
    mix: mix, 
    rgb: rgb, 
    "rgb'": rgb$prime, 
    rgba: rgba, 
    "rgba'": rgba$prime, 
    rotateHue: rotateHue, 
    saturate: saturate, 
    textColor: textColor, 
    toGray: toGray, 
    toHSLA: toHSLA, 
    toHexString: toHexString, 
    toLCh: toLCh, 
    toLab: toLab, 
    toRGBA: toRGBA, 
    "toRGBA'": toRGBA$prime, 
    toXYZ: toXYZ, 
    white: white, 
    xyz: xyz, 
    showColor: showColor, 
    eqColor: eqColor
};
