// Generated by psc version 0.9.1
"use strict";
var Data_Firebase_Foreign = require("../Data.Firebase.Foreign");
var Data_Foldable = require("../Data.Foldable");
var Data_Foreign = require("../Data.Foreign");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var Data_Foreign_Class_Default = require("../Data.Foreign.Class.Default");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_Generators = require("../Data.Generators");
var Data_Generic = require("../Data.Generic");
var Data_List = require("../Data.List");
var Data_Maybe = require("../Data.Maybe");
var Model_Event_Key = require("../Model.Event.Key");
var Model_OpenCallForSessions_Tokens = require("../Model.OpenCallForSessions.Tokens");
var Model_OpenCallForSessions_ProposalForm = require("../Model.OpenCallForSessions.ProposalForm");
var Model_OpenCallForSessions_PublicationStatus = require("../Model.OpenCallForSessions.PublicationStatus");
var Model_OpenCallForSessions_EventName = require("../Model.OpenCallForSessions.EventName");
var Model_OpenCallForSessions_UserDefinedFields = require("../Model.OpenCallForSessions.UserDefinedFields");
var Prelude = require("../Prelude");
var Test_QuickCheck_Arbitrary = require("../Test.QuickCheck.Arbitrary");
var Control_Apply = require("../Control.Apply");
var Data_Unit = require("../Data.Unit");
var Data_Eq = require("../Data.Eq");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra");
var Data_Show = require("../Data.Show");
var Control_Bind = require("../Control.Bind");
var Data_Function = require("../Data.Function");
var Control_Applicative = require("../Control.Applicative");
var Data_Functor = require("../Data.Functor");
var Data_Monoid = require("../Data.Monoid");
var Data_Either = require("../Data.Either");
var Data_Foreign_Index = require("../Data.Foreign.Index");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans");
var Data_Identity = require("../Data.Identity");
var OpenCallForSessions = function (x) {
    return x;
};
var toForeignOpenCallForSessions = function (v) {
    var cfs = {
        eventId: v.eventId, 
        eventName: v.eventName, 
        invitation: v.invitation, 
        published: Data_Firebase_Foreign.toFire(Model_OpenCallForSessions_PublicationStatus.toFirebaseForeignPublicationStatus)(v.published), 
        proposalForm: Data_Firebase_Foreign.toFire(Model_OpenCallForSessions_ProposalForm.toFirebaseForeignProposalForm)(v.proposalForm), 
        adaptedFields: Data_Firebase_Foreign.toFire(Model_OpenCallForSessions_UserDefinedFields.toFirebaseForeignUserDefinedFields)(v.adaptedFields)
    };
    return Data_Foreign.toForeign(cfs);
};
var toFirebaseForeignOpenCallForSessions = new Data_Firebase_Foreign.ToFirebaseForeign(toForeignOpenCallForSessions);
var showOpenCallForSessions = new Data_Show.Show(function (v) {
    return Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)(" | ")([ Data_ForeignKey.fkToString(v.eventId), Data_Show.show(Model_OpenCallForSessions_EventName.showEventName)(v.eventName), Data_Show.show(Data_Show.showString)(v.invitation), Data_Show.show(Model_OpenCallForSessions_PublicationStatus.showPublicationStatus)(v.published), Data_Show.show(Model_OpenCallForSessions_UserDefinedFields.showUserDefinedFields)(v.adaptedFields) ]);
});
var mkUnAdvertisedCallForSessions = function (eventId) {
    return function (eventName) {
        return function (invitation) {
            return {
                eventId: eventId, 
                eventName: eventName, 
                invitation: invitation, 
                published: Model_OpenCallForSessions_PublicationStatus.Hidden.value, 
                proposalForm: Model_OpenCallForSessions_ProposalForm.InPerson.value, 
                adaptedFields: Model_OpenCallForSessions_UserDefinedFields.aDummy
            };
        };
    };
};
var keyStr = function (v) {
    return Data_ForeignKey.fkToString(v.eventId);
};
var isOpen = function (v) {
    return Data_Function.apply(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Eq.eq(Model_OpenCallForSessions_PublicationStatus.eqPublicationStatus)(v.published)(Model_OpenCallForSessions_PublicationStatus.Closed.value));
};
var isForeignOpenCallForSessions = new Data_Foreign_Class.IsForeign(function (value) {
    return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Class.readProp(Data_ForeignKey.isForeignForeignKey)(Data_Foreign_Index.indexString)(Model_OpenCallForSessions_Tokens.eventIdToken)(value))(function (v) {
        return Control_Bind.bind(Data_Either.bindEither)(Data_Functor.map(Data_Either.functorEither)(Model_OpenCallForSessions_EventName.mkEventName)(Data_Foreign_Class.readProp(Data_Foreign_Class.stringIsForeign)(Data_Foreign_Index.indexString)(Model_OpenCallForSessions_Tokens.eventNameToken)(value)))(function (v1) {
            return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Class.readProp(Data_Foreign_Class.stringIsForeign)(Data_Foreign_Index.indexString)(Model_OpenCallForSessions_Tokens.invitationToken)(value))(function (v2) {
                return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Class.readProp(Model_OpenCallForSessions_PublicationStatus.isForeignPublicationStatus)(Data_Foreign_Index.indexString)(Model_OpenCallForSessions_Tokens.publicationStatusToken)(value))(function (v3) {
                    return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Class.readProp(Model_OpenCallForSessions_ProposalForm.isForeignProposalForm)(Data_Foreign_Index.indexString)(Model_OpenCallForSessions_Tokens.proposalFormToken)(value))(function (v4) {
                        return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Class_Default.readPropWithDefault(Model_OpenCallForSessions_UserDefinedFields.isForeignUserDefinedFields)(Data_Foreign_Index.indexString)(Model_OpenCallForSessions_UserDefinedFields.aDummy)(Model_OpenCallForSessions_Tokens.adaptedFieldsToken)(value))(function (v5) {
                            return Data_Function.apply(Control_Applicative.pure(Data_Either.applicativeEither))({
                                eventId: v, 
                                eventName: v1, 
                                invitation: v2, 
                                published: v3, 
                                proposalForm: v4, 
                                adaptedFields: v5
                            });
                        });
                    });
                });
            });
        });
    });
});
var invitationText = function (v) {
    return v.invitation;
};
var getPublicationStatus = function (v) {
    return v.published;
};
var getProposalForm = function (v) {
    return v.proposalForm;
};
var genericOpenCallForSessions = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.CallForSessions.OpenCallForSessions" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(OpenCallForSessions))((function (r) {
            if (r instanceof Data_Generic.SRecord && r.value0.length === 6) {
                return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(function (adaptedFields1) {
                    return function (eventId1) {
                        return function (eventName1) {
                            return function (invitation1) {
                                return function (proposalForm1) {
                                    return function (published1) {
                                        return {
                                            adaptedFields: adaptedFields1, 
                                            eventId: eventId1, 
                                            eventName: eventName1, 
                                            invitation: invitation1, 
                                            proposalForm: proposalForm1, 
                                            published: published1
                                        };
                                    };
                                };
                            };
                        };
                    };
                }))(Data_Generic.fromSpine(Model_OpenCallForSessions_UserDefinedFields.genericUserDefinedFields)((r.value0[0]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)((r.value0[1]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Model_OpenCallForSessions_EventName.genericEventName)((r.value0[2]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Data_Generic.genericString)((r.value0[3]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Model_OpenCallForSessions_ProposalForm.genericProposalForm)((r.value0[4]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Model_OpenCallForSessions_PublicationStatus.genericPublicationStatus)((r.value0[5]).recValue(Data_Unit.unit)));
            };
            return Data_Maybe.Nothing.value;
        })(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Model.CallForSessions.OpenCallForSessions", [ {
        sigConstructor: "Model.CallForSessions.OpenCallForSessions", 
        sigValues: [ function ($dollarq1) {
            return new Data_Generic.SigRecord([ {
                recLabel: "adaptedFields", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Model_OpenCallForSessions_UserDefinedFields.genericUserDefinedFields)(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "eventId", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "eventName", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Model_OpenCallForSessions_EventName.genericEventName)(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "invitation", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "proposalForm", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Model_OpenCallForSessions_ProposalForm.genericProposalForm)(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "published", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Model_OpenCallForSessions_PublicationStatus.genericPublicationStatus)(Data_Generic.anyProxy);
                }
            } ]);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("Model.CallForSessions.OpenCallForSessions", [ function ($dollarq) {
        return new Data_Generic.SRecord([ {
            recLabel: "adaptedFields", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Model_OpenCallForSessions_UserDefinedFields.genericUserDefinedFields)(v.adaptedFields);
            }
        }, {
            recLabel: "eventId", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v.eventId);
            }
        }, {
            recLabel: "eventName", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Model_OpenCallForSessions_EventName.genericEventName)(v.eventName);
            }
        }, {
            recLabel: "invitation", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_Generic.genericString)(v.invitation);
            }
        }, {
            recLabel: "proposalForm", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Model_OpenCallForSessions_ProposalForm.genericProposalForm)(v.proposalForm);
            }
        }, {
            recLabel: "published", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Model_OpenCallForSessions_PublicationStatus.genericPublicationStatus)(v.published);
            }
        } ]);
    } ]);
});
var eventNameStr = function (v) {
    return Model_OpenCallForSessions_EventName.runEventName(v.eventName);
};
var eqOpenCallForSessions = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(Model_OpenCallForSessions_UserDefinedFields.eqUserDefinedFields)(x.adaptedFields)(y.adaptedFields) && Data_Eq.eq(Data_ForeignKey.eqForeignKey)(x.eventId)(y.eventId) && Data_Eq.eq(Model_OpenCallForSessions_EventName.eqEventName)(x.eventName)(y.eventName) && x.invitation === y.invitation && Data_Eq.eq(Model_OpenCallForSessions_ProposalForm.eqProposalForm)(x.proposalForm)(y.proposalForm) && Data_Eq.eq(Model_OpenCallForSessions_PublicationStatus.eqPublicationStatus)(x.published)(y.published);
    };
});
var cfsKey = function (v) {
    return v.eventId;
};
var arbOpenCallForSessions = new Test_QuickCheck_Arbitrary.Arbitrary(Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Data_ForeignKey.arbForeignKey))(function (v) {
    return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Model_OpenCallForSessions_EventName.arbitraryEventname))(function (v1) {
        return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Data_Generators.alphaNumString)(function (v2) {
            return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Model_OpenCallForSessions_PublicationStatus.arbitraryPublicationStatus))(function (v3) {
                return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Model_OpenCallForSessions_ProposalForm.arbitraryProposalForm))(function (v4) {
                    return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Model_OpenCallForSessions_UserDefinedFields.arbUserDefinedFields))(function (v5) {
                        return Data_Function.apply(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity)))({
                            eventId: v, 
                            eventName: v1, 
                            invitation: v2, 
                            published: v3, 
                            proposalForm: v4, 
                            adaptedFields: v5
                        });
                    });
                });
            });
        });
    });
}));
module.exports = {
    OpenCallForSessions: OpenCallForSessions, 
    cfsKey: cfsKey, 
    eventNameStr: eventNameStr, 
    getProposalForm: getProposalForm, 
    getPublicationStatus: getPublicationStatus, 
    invitationText: invitationText, 
    isOpen: isOpen, 
    keyStr: keyStr, 
    mkUnAdvertisedCallForSessions: mkUnAdvertisedCallForSessions, 
    genericOpenCallForSessions: genericOpenCallForSessions, 
    eqOpenCallForSessions: eqOpenCallForSessions, 
    showOpenCallForSessions: showOpenCallForSessions, 
    arbOpenCallForSessions: arbOpenCallForSessions, 
    isForeignOpenCallForSessions: isForeignOpenCallForSessions, 
    toFirebaseForeignOpenCallForSessions: toFirebaseForeignOpenCallForSessions
};
