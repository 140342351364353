// Generated by psc version 0.9.1
"use strict";
var Prelude = require("../Prelude");
var Data_Generic = require("../Data.Generic");
var CSS_Property = require("../CSS.Property");
var CSS_String = require("../CSS.String");
var Data_Eq = require("../Data.Eq");
var Data_Ord = require("../Data.Ord");
var Control_Apply = require("../Control.Apply");
var Data_Maybe = require("../Data.Maybe");
var Data_Unit = require("../Data.Unit");
var Data_Function = require("../Data.Function");
var Data_Semigroup = require("../Data.Semigroup");
var Time = function (x) {
    return x;
};
var valTime = new CSS_Property.Val(function (v) {
    return v;
});
var sec = function (i) {
    return Data_Function.apply(Time)(Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_Property.value(CSS_Property.valNumber)(i))(CSS_String.fromString(CSS_Property.isStringValue)("s")));
};
var ms = function (i) {
    return Data_Function.apply(Time)(Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_Property.value(CSS_Property.valNumber)(i))(CSS_String.fromString(CSS_Property.isStringValue)("ms")));
};
var genericTime = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "CSS.Time.Time" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Time))(Data_Generic.fromSpine(CSS_Property.genericValue)(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("CSS.Time.Time", [ {
        sigConstructor: "CSS.Time.Time", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(CSS_Property.genericValue)(Data_Generic.anyProxy);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("CSS.Time.Time", [ function ($dollarq) {
        return Data_Generic.toSpine(CSS_Property.genericValue)(v);
    } ]);
});
var eqTime = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(CSS_Property.eqValue)(x)(y);
    };
});
var ordTime = new Data_Ord.Ord(function () {
    return eqTime;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(CSS_Property.ordValue)(x)(y);
    };
});
module.exports = {
    Time: Time, 
    ms: ms, 
    sec: sec, 
    eqTime: eqTime, 
    ordTime: ordTime, 
    genericTime: genericTime, 
    valTime: valTime
};
