// Generated by psc version 0.9.1
"use strict";
var Model_SessionDescription = require("../Model.SessionDescription");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_ForeignKey_StrMap = require("../Data.ForeignKey.StrMap");
var Data_Maybe = require("../Data.Maybe");
var Data_String = require("../Data.String");
var Data_Themes = require("../Data.Themes");
var Prelude = require("../Prelude");
var Pux_Html = require("../Pux.Html");
var Pux_Html_Attributes = require("../Pux.Html.Attributes");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var State_Types = require("../State.Types");
var Data_Function = require("../Data.Function");
var Data_Functor = require("../Data.Functor");
var Data_Show = require("../Data.Show");
var showLabel = function (l) {
    return Pux_Html_Elements.h3([ Pux_Html_Attributes.className("mt3") ])([ Pux_Html_Elements.text(l) ]);
};
var para = function (s) {
    return Pux_Html.withChild(Pux_Html_Elements.p)(Pux_Html_Elements.text(s));
};
var asParagraphs = function (s) {
    var paragraphs = Data_String.split("\n")(s);
    return Data_Function.apply(Pux_Html_Elements.div([  ]))(Data_Functor.map(Data_Functor.functorArray)(para)(paragraphs));
};
var asList = function (s) {
    return Data_Function.apply(Pux_Html_Elements.ul([  ]))(Data_Function.apply(Data_Functor.map(Data_Functor.functorArray)(function (i) {
        return Pux_Html_Elements.li([  ])([ Pux_Html_Elements.text(i) ]);
    }))(Data_Themes.toArray(s)));
};
var showAnonymousSessionDescription = function (v) {
    return Pux_Html_Elements.span([  ])([ Pux_Html_Elements.h2([ Pux_Html_Attributes.className("mb3") ])([ Pux_Html_Elements.text(v.title) ]), showLabel("Session type"), Pux_Html.withChild(Pux_Html_Elements.p)(Pux_Html_Elements.text(v.sessiontype)), showLabel("Duration"), Pux_Html.withChild(Pux_Html_Elements.p)(Pux_Html_Elements.text(v.duration)), showLabel("Abstract"), asParagraphs(v["abstract"]), showLabel("Session description"), asParagraphs(v.sessiondescription), showLabel("Themes"), asList(v.themes), showLabel("Reference number"), Data_Function.apply(asParagraphs)(Data_Show.show(Data_Show.showInt)(v.sessionid)) ]);
};
var sessionDescriptionView = function (state) {
    return function (sessionId) {
        var session = Data_ForeignKey_StrMap.lookupKey(sessionId)(state.sessionDescriptions);
        return Pux_Html_Elements.div([  ])([ (function () {
            if (session instanceof Data_Maybe.Nothing) {
                return Pux_Html.withChild(Pux_Html_Elements.p)(Pux_Html_Elements.text("loading session description, please wait..."));
            };
            if (session instanceof Data_Maybe.Just) {
                return showAnonymousSessionDescription(session.value0);
            };
            throw new Error("Failed pattern match at View.SessionDescriptionView line 17, column 3 - line 19, column 51: " + [ session.constructor.name ]);
        })() ]);
    };
};
module.exports = {
    sessionDescriptionView: sessionDescriptionView
};
