// Generated by psc version 0.9.1
"use strict";
var Prelude = require("../Prelude");
var Data_Generic = require("../Data.Generic");
var Data_Eq = require("../Data.Eq");
var Data_Ord = require("../Data.Ord");
var Control_Apply = require("../Control.Apply");
var Data_Maybe = require("../Data.Maybe");
var Data_Unit = require("../Data.Unit");
var Data_Show = require("../Data.Show");
var Data_Semigroup = require("../Data.Semigroup");
var MediaType = function (x) {
    return x;
};
var unMediaType = function (v) {
    return v;
};
var showMediaType = new Data_Show.Show(function (v) {
    return "(MediaType " + (Data_Show.show(Data_Show.showString)(v) + ")");
});
var genericMediaType = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Data.MediaType.MediaType" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(MediaType))(Data_Generic.fromSpine(Data_Generic.genericString)(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Data.MediaType.MediaType", [ {
        sigConstructor: "Data.MediaType.MediaType", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("Data.MediaType.MediaType", [ function ($dollarq) {
        return Data_Generic.toSpine(Data_Generic.genericString)(v);
    } ]);
});
var eqMediaType = new Data_Eq.Eq(function (x) {
    return function (y) {
        return x === y;
    };
});
var ordMediaType = new Data_Ord.Ord(function () {
    return eqMediaType;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(Data_Ord.ordString)(x)(y);
    };
});
module.exports = {
    MediaType: MediaType, 
    unMediaType: unMediaType, 
    eqMediaType: eqMediaType, 
    ordMediaType: ordMediaType, 
    genericMediaType: genericMediaType, 
    showMediaType: showMediaType
};
