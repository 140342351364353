// Generated by psc version 0.9.1
"use strict";
var Control_Bind = require("../Control.Bind");
var Data_Default = require("../Data.Default");
var Data_Either = require("../Data.Either");
var Data_Eq = require("../Data.Eq");
var Data_Firebase_Foreign = require("../Data.Firebase.Foreign");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var Data_Generators = require("../Data.Generators");
var Data_Generic = require("../Data.Generic");
var Data_Show = require("../Data.Show");
var Prelude = require("../Prelude");
var Test_QuickCheck_Arbitrary = require("../Test.QuickCheck.Arbitrary");
var Test_QuickCheck_Gen = require("../Test.QuickCheck.Gen");
var Control_Apply = require("../Control.Apply");
var Data_Maybe = require("../Data.Maybe");
var Data_Unit = require("../Data.Unit");
var Data_Function = require("../Data.Function");
var Control_Applicative = require("../Control.Applicative");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans");
var Data_Identity = require("../Data.Identity");
var Themes = function (x) {
    return x;
};
var toArray = function (v) {
    return v;
};
var toFirebaseForeignThemes = new Data_Firebase_Foreign.ToFirebaseForeign(function (t) {
    return Data_Function.apply(Data_Firebase_Foreign.arrayOfStringsToFirebase)(toArray(t));
});
var mkThemes = Themes;
var isForeignThemes = new Data_Foreign_Class.IsForeign(function (value) {
    var r = Data_Firebase_Foreign.readStringArrayFromObject(value);
    if (r instanceof Data_Either.Right) {
        return Data_Function.apply(Data_Either.Right.create)(r.value0);
    };
    if (r instanceof Data_Either.Left) {
        var arr = Data_Foreign_Class.read(Data_Foreign_Class.arrayIsForeign(Data_Foreign_Class.stringIsForeign))(value);
        if (arr instanceof Data_Either.Right) {
            return Data_Function.apply(Data_Either.Right.create)(arr.value0);
        };
        if (arr instanceof Data_Either.Left) {
            return Data_Function.apply(Data_Either.Right.create)([  ]);
        };
        throw new Error("Failed pattern match at Data.Themes line 38, column 9 - line 40, column 38: " + [ arr.constructor.name ]);
    };
    throw new Error("Failed pattern match at Data.Themes line 34, column 5 - line 40, column 38: " + [ r.constructor.name ]);
});
var genericTheme = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Data.Themes.Themes" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Themes))(Data_Generic.fromSpine(Data_Generic.genericArray(Data_Generic.genericString))(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Data.Themes.Themes", [ {
        sigConstructor: "Data.Themes.Themes", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_Generic.genericArray(Data_Generic.genericString))(Data_Generic.anyProxy);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("Data.Themes.Themes", [ function ($dollarq) {
        return Data_Generic.toSpine(Data_Generic.genericArray(Data_Generic.genericString))(v);
    } ]);
});
var showThemes = new Data_Show.Show(Data_Generic.gShow(genericTheme));
var eqThemes = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x)(y);
    };
});
var defaultTheme = new Data_Default.Default([  ]);
var arbitraryThemes = new Test_QuickCheck_Arbitrary.Arbitrary(Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Gen.arrayOf(Data_Generators.alphaNumString))(function (v) {
    return Data_Function.apply(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity)))(mkThemes(v));
}));
module.exports = {
    mkThemes: mkThemes, 
    toArray: toArray, 
    genericTheme: genericTheme, 
    eqThemes: eqThemes, 
    showThemes: showThemes, 
    toFirebaseForeignThemes: toFirebaseForeignThemes, 
    isForeignThemes: isForeignThemes, 
    defaultTheme: defaultTheme, 
    arbitraryThemes: arbitraryThemes
};
