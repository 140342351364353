// Generated by psc version 0.9.1
"use strict";
var Default = function ($$default) {
    this["default"] = $$default;
};
var defaultString = new Default("");
var defaultInt = new Default(0);
var $$default = function (dict) {
    return dict["default"];
};
module.exports = {
    Default: Default, 
    "default": $$default, 
    defaultString: defaultString, 
    defaultInt: defaultInt
};
