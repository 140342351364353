// Generated by psc version 0.9.1
"use strict";
var Pux_Html = require("../Pux.Html");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var State_Types = require("../State.Types");
var notFound = function (state) {
    return Pux_Html.withChild(Pux_Html_Elements.div)(Pux_Html.withChild(Pux_Html_Elements.h2)(Pux_Html_Elements.text("404 Not Found")));
};
module.exports = {
    notFound: notFound
};
