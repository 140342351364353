// Generated by psc version 0.9.1
"use strict";
var sessionDescriptionsToken = "sessiondescriptions";
var isVisibleToken = "isVisible";
var isValidToken = "isValid";
module.exports = {
    isValidToken: isValidToken, 
    isVisibleToken: isVisibleToken, 
    sessionDescriptionsToken: sessionDescriptionsToken
};
