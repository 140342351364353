// Generated by psc version 0.9.1
"use strict";
var $foreign = require("./foreign");
var Web_Firebase_Types = require("../Web.Firebase.Types");
var Data_Foreign = require("../Data.Foreign");
var Data_Nullable = require("../Data.Nullable");
var Data_Maybe = require("../Data.Maybe");
var Data_Function_Uncurried = require("../Data.Function.Uncurried");
var val = Data_Function_Uncurried.runFn1($foreign.valImpl);
var numChildren = Data_Function_Uncurried.runFn1($foreign._numChildren);
var key = function (ds) {
    return Data_Nullable.toMaybe(Data_Function_Uncurried.runFn1($foreign._key)(ds));
};
var hasChildren = Data_Function_Uncurried.runFn1($foreign._hasChildren);
var hasChild = Data_Function_Uncurried.runFn2($foreign._hasChild);
var exists = Data_Function_Uncurried.runFn1($foreign._exists);
var child = Data_Function_Uncurried.runFn2($foreign._child);
module.exports = {
    child: child, 
    exists: exists, 
    hasChild: hasChild, 
    hasChildren: hasChildren, 
    key: key, 
    numChildren: numChildren, 
    val: val
};
