// Generated by psc version 0.9.1
"use strict";
var Data_Maybe = require("../Data.Maybe");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_StrMap = require("../Data.StrMap");
var lookupKey = function (fk) {
    return function (m) {
        return Data_StrMap.lookup(Data_ForeignKey.fkToString(fk))(m);
    };
};
var lookup = lookupKey;
var insertKey = function (fk) {
    return function (v) {
        return function (m) {
            return Data_StrMap.insert(Data_ForeignKey.fkToString(fk))(v)(m);
        };
    };
};
var alterKey = function (f) {
    return function (fk) {
        return function (m) {
            return Data_StrMap.alter(f)(Data_ForeignKey.fkToString(fk))(m);
        };
    };
};
module.exports = {
    alterKey: alterKey, 
    insertKey: insertKey, 
    lookup: lookup, 
    lookupKey: lookupKey
};
