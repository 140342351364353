// Generated by psc version 0.9.1
"use strict";
var Color = require("../Color");
var yellowgreen = Color.rgb(154)(205)(50);
var yellow = Color.rgb(255)(255)(0);
var whitesmoke = Color.rgb(245)(245)(245);
var wheat = Color.rgb(245)(222)(179);
var violet = Color.rgb(238)(130)(238);
var turquoise = Color.rgb(64)(224)(208);
var tomato = Color.rgb(255)(99)(71);
var thistle = Color.rgb(216)(191)(216);
var teal = Color.rgb(0)(128)(128);
var tan = Color.rgb(210)(180)(140);
var steelblue = Color.rgb(70)(130)(180);
var springgreen = Color.rgb(0)(255)(127);
var snow = Color.rgb(255)(250)(250);
var slategrey = Color.rgb(112)(128)(144);
var slategray = Color.rgb(112)(128)(144);
var slateblue = Color.rgb(106)(90)(205);
var skyblue = Color.rgb(135)(206)(235);
var silver = Color.rgb(192)(192)(192);
var sienna = Color.rgb(160)(82)(45);
var seashell = Color.rgb(255)(245)(238);
var seagreen = Color.rgb(46)(139)(87);
var sandybrown = Color.rgb(244)(164)(96);
var salmon = Color.rgb(250)(128)(114);
var saddlebrown = Color.rgb(139)(69)(19);
var royalblue = Color.rgb(65)(105)(225);
var rosybrown = Color.rgb(188)(143)(143);
var red = Color.rgb(255)(0)(0);
var purple = Color.rgb(128)(0)(128);
var powderblue = Color.rgb(176)(224)(230);
var plum = Color.rgb(221)(160)(221);
var pink = Color.rgb(255)(192)(203);
var peru = Color.rgb(205)(133)(63);
var peachpuff = Color.rgb(255)(218)(185);
var papayawhip = Color.rgb(255)(239)(213);
var palevioletred = Color.rgb(219)(112)(147);
var paleturquoise = Color.rgb(175)(238)(238);
var palegreen = Color.rgb(152)(251)(152);
var palegoldenrod = Color.rgb(238)(232)(170);
var orchid = Color.rgb(218)(112)(214);
var orangered = Color.rgb(255)(69)(0);
var orange = Color.rgb(255)(165)(0);
var olivedrab = Color.rgb(107)(142)(35);
var olive = Color.rgb(128)(128)(0);
var oldlace = Color.rgb(253)(245)(230);
var navy = Color.rgb(0)(0)(128);
var navajowhite = Color.rgb(255)(222)(173);
var moccasin = Color.rgb(255)(228)(181);
var mistyrose = Color.rgb(255)(228)(225);
var mintcream = Color.rgb(245)(255)(250);
var midnightblue = Color.rgb(25)(25)(112);
var mediumvioletred = Color.rgb(199)(21)(133);
var mediumturquoise = Color.rgb(72)(209)(204);
var mediumspringgreen = Color.rgb(0)(250)(154);
var mediumslateblue = Color.rgb(123)(104)(238);
var mediumseagreen = Color.rgb(60)(179)(113);
var mediumpurple = Color.rgb(147)(112)(219);
var mediumorchid = Color.rgb(186)(85)(211);
var mediumblue = Color.rgb(0)(0)(205);
var mediumaquamarine = Color.rgb(102)(205)(170);
var maroon = Color.rgb(128)(0)(0);
var magenta = Color.rgb(255)(0)(255);
var linen = Color.rgb(250)(240)(230);
var limegreen = Color.rgb(50)(205)(50);
var lime = Color.rgb(0)(255)(0);
var lightyellow = Color.rgb(255)(255)(224);
var lightsteelblue = Color.rgb(176)(196)(222);
var lightslategrey = Color.rgb(119)(136)(153);
var lightslategray = Color.rgb(119)(136)(153);
var lightskyblue = Color.rgb(135)(206)(250);
var lightseagreen = Color.rgb(32)(178)(170);
var lightsalmon = Color.rgb(255)(160)(122);
var lightpink = Color.rgb(255)(182)(193);
var lightgrey = Color.rgb(211)(211)(211);
var lightgreen = Color.rgb(144)(238)(144);
var lightgray = Color.rgb(211)(211)(211);
var lightgoldenrodyellow = Color.rgb(250)(250)(210);
var lightcyan = Color.rgb(224)(255)(255);
var lightcoral = Color.rgb(240)(128)(128);
var lightblue = Color.rgb(173)(216)(230);
var lemonchiffon = Color.rgb(255)(250)(205);
var lawngreen = Color.rgb(124)(252)(0);
var lavenderblush = Color.rgb(255)(240)(245);
var lavender = Color.rgb(230)(230)(250);
var khaki = Color.rgb(240)(230)(140);
var ivory = Color.rgb(255)(255)(240);
var indigo = Color.rgb(75)(0)(130);
var indianred = Color.rgb(205)(92)(92);
var hotpink = Color.rgb(255)(105)(180);
var honeydew = Color.rgb(240)(255)(240);
var grey = Color.rgb(128)(128)(128);
var greenyellow = Color.rgb(173)(255)(47);
var green = Color.rgb(0)(128)(0);
var gray = Color.rgb(128)(128)(128);
var goldenrod = Color.rgb(218)(165)(32);
var gold = Color.rgb(255)(215)(0);
var ghostwhite = Color.rgb(248)(248)(255);
var gainsboro = Color.rgb(220)(220)(220);
var fuchsia = Color.rgb(255)(0)(255);
var forestgreen = Color.rgb(34)(139)(34);
var floralwhite = Color.rgb(255)(250)(240);
var firebrick = Color.rgb(178)(34)(34);
var dodgerblue = Color.rgb(30)(144)(255);
var dimgrey = Color.rgb(105)(105)(105);
var dimgray = Color.rgb(105)(105)(105);
var deepskyblue = Color.rgb(0)(191)(255);
var deeppink = Color.rgb(255)(20)(147);
var darkviolet = Color.rgb(148)(0)(211);
var darkturquoise = Color.rgb(0)(206)(209);
var darkslategrey = Color.rgb(47)(79)(79);
var darkslategray = Color.rgb(47)(79)(79);
var darkslateblue = Color.rgb(72)(61)(139);
var darkseagreen = Color.rgb(143)(188)(143);
var darksalmon = Color.rgb(233)(150)(122);
var darkred = Color.rgb(139)(0)(0);
var darkorchid = Color.rgb(153)(50)(204);
var darkorange = Color.rgb(255)(140)(0);
var darkolivegreen = Color.rgb(85)(107)(47);
var darkmagenta = Color.rgb(139)(0)(139);
var darkkhaki = Color.rgb(189)(183)(107);
var darkgrey = Color.rgb(169)(169)(169);
var darkgreen = Color.rgb(0)(100)(0);
var darkgray = Color.rgb(169)(169)(169);
var darkgoldenrod = Color.rgb(184)(134)(11);
var darkcyan = Color.rgb(0)(139)(139);
var darkblue = Color.rgb(0)(0)(139);
var cyan = Color.rgb(0)(255)(255);
var crimson = Color.rgb(220)(20)(60);
var cornsilk = Color.rgb(255)(248)(220);
var cornflowerblue = Color.rgb(100)(149)(237);
var coral = Color.rgb(255)(127)(80);
var chocolate = Color.rgb(210)(105)(30);
var chartreuse = Color.rgb(127)(255)(0);
var cadetblue = Color.rgb(95)(158)(160);
var burlywood = Color.rgb(222)(184)(135);
var brown = Color.rgb(165)(42)(42);
var blueviolet = Color.rgb(138)(43)(226);
var blue = Color.rgb(0)(0)(255);
var blanchedalmond = Color.rgb(255)(235)(205);
var bisque = Color.rgb(255)(228)(196);
var beige = Color.rgb(245)(245)(220);
var azure = Color.rgb(240)(255)(255);
var aquamarine = Color.rgb(127)(255)(212);
var aqua = Color.rgb(0)(255)(255);
var antiquewhite = Color.rgb(250)(235)(215);
var aliceblue = Color.rgb(240)(248)(255);
module.exports = {
    aliceblue: aliceblue, 
    antiquewhite: antiquewhite, 
    aqua: aqua, 
    aquamarine: aquamarine, 
    azure: azure, 
    beige: beige, 
    bisque: bisque, 
    blanchedalmond: blanchedalmond, 
    blue: blue, 
    blueviolet: blueviolet, 
    brown: brown, 
    burlywood: burlywood, 
    cadetblue: cadetblue, 
    chartreuse: chartreuse, 
    chocolate: chocolate, 
    coral: coral, 
    cornflowerblue: cornflowerblue, 
    cornsilk: cornsilk, 
    crimson: crimson, 
    cyan: cyan, 
    darkblue: darkblue, 
    darkcyan: darkcyan, 
    darkgoldenrod: darkgoldenrod, 
    darkgray: darkgray, 
    darkgreen: darkgreen, 
    darkgrey: darkgrey, 
    darkkhaki: darkkhaki, 
    darkmagenta: darkmagenta, 
    darkolivegreen: darkolivegreen, 
    darkorange: darkorange, 
    darkorchid: darkorchid, 
    darkred: darkred, 
    darksalmon: darksalmon, 
    darkseagreen: darkseagreen, 
    darkslateblue: darkslateblue, 
    darkslategray: darkslategray, 
    darkslategrey: darkslategrey, 
    darkturquoise: darkturquoise, 
    darkviolet: darkviolet, 
    deeppink: deeppink, 
    deepskyblue: deepskyblue, 
    dimgray: dimgray, 
    dimgrey: dimgrey, 
    dodgerblue: dodgerblue, 
    firebrick: firebrick, 
    floralwhite: floralwhite, 
    forestgreen: forestgreen, 
    fuchsia: fuchsia, 
    gainsboro: gainsboro, 
    ghostwhite: ghostwhite, 
    gold: gold, 
    goldenrod: goldenrod, 
    gray: gray, 
    green: green, 
    greenyellow: greenyellow, 
    grey: grey, 
    honeydew: honeydew, 
    hotpink: hotpink, 
    indianred: indianred, 
    indigo: indigo, 
    ivory: ivory, 
    khaki: khaki, 
    lavender: lavender, 
    lavenderblush: lavenderblush, 
    lawngreen: lawngreen, 
    lemonchiffon: lemonchiffon, 
    lightblue: lightblue, 
    lightcoral: lightcoral, 
    lightcyan: lightcyan, 
    lightgoldenrodyellow: lightgoldenrodyellow, 
    lightgray: lightgray, 
    lightgreen: lightgreen, 
    lightgrey: lightgrey, 
    lightpink: lightpink, 
    lightsalmon: lightsalmon, 
    lightseagreen: lightseagreen, 
    lightskyblue: lightskyblue, 
    lightslategray: lightslategray, 
    lightslategrey: lightslategrey, 
    lightsteelblue: lightsteelblue, 
    lightyellow: lightyellow, 
    lime: lime, 
    limegreen: limegreen, 
    linen: linen, 
    magenta: magenta, 
    maroon: maroon, 
    mediumaquamarine: mediumaquamarine, 
    mediumblue: mediumblue, 
    mediumorchid: mediumorchid, 
    mediumpurple: mediumpurple, 
    mediumseagreen: mediumseagreen, 
    mediumslateblue: mediumslateblue, 
    mediumspringgreen: mediumspringgreen, 
    mediumturquoise: mediumturquoise, 
    mediumvioletred: mediumvioletred, 
    midnightblue: midnightblue, 
    mintcream: mintcream, 
    mistyrose: mistyrose, 
    moccasin: moccasin, 
    navajowhite: navajowhite, 
    navy: navy, 
    oldlace: oldlace, 
    olive: olive, 
    olivedrab: olivedrab, 
    orange: orange, 
    orangered: orangered, 
    orchid: orchid, 
    palegoldenrod: palegoldenrod, 
    palegreen: palegreen, 
    paleturquoise: paleturquoise, 
    palevioletred: palevioletred, 
    papayawhip: papayawhip, 
    peachpuff: peachpuff, 
    peru: peru, 
    pink: pink, 
    plum: plum, 
    powderblue: powderblue, 
    purple: purple, 
    red: red, 
    rosybrown: rosybrown, 
    royalblue: royalblue, 
    saddlebrown: saddlebrown, 
    salmon: salmon, 
    sandybrown: sandybrown, 
    seagreen: seagreen, 
    seashell: seashell, 
    sienna: sienna, 
    silver: silver, 
    skyblue: skyblue, 
    slateblue: slateblue, 
    slategray: slategray, 
    slategrey: slategrey, 
    snow: snow, 
    springgreen: springgreen, 
    steelblue: steelblue, 
    tan: tan, 
    teal: teal, 
    thistle: thistle, 
    tomato: tomato, 
    turquoise: turquoise, 
    violet: violet, 
    wheat: wheat, 
    whitesmoke: whitesmoke, 
    yellow: yellow, 
    yellowgreen: yellowgreen
};
