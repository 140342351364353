// Generated by psc version 0.9.1
"use strict";
var Control_Applicative = require("../Control.Applicative");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_Generic = require("../Data.Generic");
var Model_Event_Key = require("../Model.Event.Key");
var Model_UserProfile = require("../Model.UserProfile");
var Prelude = require("../Prelude");
var Test_QuickCheck_Arbitrary = require("../Test.QuickCheck.Arbitrary");
var Test_QuickCheck_Gen = require("../Test.QuickCheck.Gen");
var Control_Bind = require("../Control.Bind");
var Data_Functor = require("../Data.Functor");
var Data_Semigroup = require("../Data.Semigroup");
var Data_Maybe = require("../Data.Maybe");
var Control_Apply = require("../Control.Apply");
var Data_Unit = require("../Data.Unit");
var Data_Show = require("../Data.Show");
var Data_Eq = require("../Data.Eq");
var Data_Function = require("../Data.Function");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans");
var Data_Identity = require("../Data.Identity");
var Home = (function () {
    function Home() {

    };
    Home.value = new Home();
    return Home;
})();
var Events = (function () {
    function Events() {

    };
    Events.value = new Events();
    return Events;
})();
var EventRoute = (function () {
    function EventRoute(value0) {
        this.value0 = value0;
    };
    EventRoute.create = function (value0) {
        return new EventRoute(value0);
    };
    return EventRoute;
})();
var EditCallForSessions = (function () {
    function EditCallForSessions(value0) {
        this.value0 = value0;
    };
    EditCallForSessions.create = function (value0) {
        return new EditCallForSessions(value0);
    };
    return EditCallForSessions;
})();
var HiddenSessions = (function () {
    function HiddenSessions(value0) {
        this.value0 = value0;
    };
    HiddenSessions.create = function (value0) {
        return new HiddenSessions(value0);
    };
    return HiddenSessions;
})();
var VisibleSessions = (function () {
    function VisibleSessions(value0) {
        this.value0 = value0;
    };
    VisibleSessions.create = function (value0) {
        return new VisibleSessions(value0);
    };
    return VisibleSessions;
})();
var ManageReviewers = (function () {
    function ManageReviewers(value0) {
        this.value0 = value0;
    };
    ManageReviewers.create = function (value0) {
        return new ManageReviewers(value0);
    };
    return ManageReviewers;
})();
var PanelChairsRoute = (function () {
    function PanelChairsRoute(value0) {
        this.value0 = value0;
    };
    PanelChairsRoute.create = function (value0) {
        return new PanelChairsRoute(value0);
    };
    return PanelChairsRoute;
})();
var OrganiseRoute = (function () {
    function OrganiseRoute() {

    };
    OrganiseRoute.value = new OrganiseRoute();
    return OrganiseRoute;
})();
var ProposeSessionRoute = (function () {
    function ProposeSessionRoute(value0) {
        this.value0 = value0;
    };
    ProposeSessionRoute.create = function (value0) {
        return new ProposeSessionRoute(value0);
    };
    return ProposeSessionRoute;
})();
var ReviewSummaryRoute = (function () {
    function ReviewSummaryRoute(value0) {
        this.value0 = value0;
    };
    ReviewSummaryRoute.create = function (value0) {
        return new ReviewSummaryRoute(value0);
    };
    return ReviewSummaryRoute;
})();
var DownloadFullCSVRoute = (function () {
    function DownloadFullCSVRoute(value0) {
        this.value0 = value0;
    };
    DownloadFullCSVRoute.create = function (value0) {
        return new DownloadFullCSVRoute(value0);
    };
    return DownloadFullCSVRoute;
})();
var Reviewer = (function () {
    function Reviewer(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Reviewer.create = function (value0) {
        return function (value1) {
            return new Reviewer(value0, value1);
        };
    };
    return Reviewer;
})();
var RandomReview = (function () {
    function RandomReview(value0) {
        this.value0 = value0;
    };
    RandomReview.create = function (value0) {
        return new RandomReview(value0);
    };
    return RandomReview;
})();
var ReviewRoute = (function () {
    function ReviewRoute(value0) {
        this.value0 = value0;
    };
    ReviewRoute.create = function (value0) {
        return new ReviewRoute(value0);
    };
    return ReviewRoute;
})();
var FullSessionDescriptionRoute = (function () {
    function FullSessionDescriptionRoute(value0) {
        this.value0 = value0;
    };
    FullSessionDescriptionRoute.create = function (value0) {
        return new FullSessionDescriptionRoute(value0);
    };
    return FullSessionDescriptionRoute;
})();
var UsersRoute = (function () {
    function UsersRoute() {

    };
    UsersRoute.value = new UsersRoute();
    return UsersRoute;
})();
var UserRoute = (function () {
    function UserRoute(value0) {
        this.value0 = value0;
    };
    UserRoute.create = function (value0) {
        return new UserRoute(value0);
    };
    return UserRoute;
})();
var SignupRoute = (function () {
    function SignupRoute() {

    };
    SignupRoute.value = new SignupRoute();
    return SignupRoute;
})();
var SignOutRoute = (function () {
    function SignOutRoute() {

    };
    SignOutRoute.value = new SignOutRoute();
    return SignOutRoute;
})();
var TermsAndConditions = (function () {
    function TermsAndConditions() {

    };
    TermsAndConditions.value = new TermsAndConditions();
    return TermsAndConditions;
})();
var CodeOfConduct = (function () {
    function CodeOfConduct() {

    };
    CodeOfConduct.value = new CodeOfConduct();
    return CodeOfConduct;
})();
var NotFound = (function () {
    function NotFound() {

    };
    NotFound.value = new NotFound();
    return NotFound;
})();
var CurrentMockup = (function () {
    function CurrentMockup() {

    };
    CurrentMockup.value = new CurrentMockup();
    return CurrentMockup;
})();
var mkUserRoute = function (fk) {
    return Data_Function.apply(UserRoute.create)(Model_UserProfile.mkUserKey(fk));
};
var genericRoute = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "UReview.Route.Types.Home" && v.value1.length === 0)) {
        return new Data_Maybe.Just(Home.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "UReview.Route.Types.Events" && v.value1.length === 0)) {
        return new Data_Maybe.Just(Events.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "UReview.Route.Types.EventRoute" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(EventRoute.create))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)(v.value1[0](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "UReview.Route.Types.EditCallForSessions" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(EditCallForSessions.create))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)(v.value1[0](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "UReview.Route.Types.HiddenSessions" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(HiddenSessions.create))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)(v.value1[0](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "UReview.Route.Types.VisibleSessions" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(VisibleSessions.create))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)(v.value1[0](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "UReview.Route.Types.ManageReviewers" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(ManageReviewers.create))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)(v.value1[0](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "UReview.Route.Types.PanelChairsRoute" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(PanelChairsRoute.create))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)(v.value1[0](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "UReview.Route.Types.OrganiseRoute" && v.value1.length === 0)) {
        return new Data_Maybe.Just(OrganiseRoute.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "UReview.Route.Types.ProposeSessionRoute" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(ProposeSessionRoute.create))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)(v.value1[0](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "UReview.Route.Types.ReviewSummaryRoute" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(ReviewSummaryRoute.create))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)(v.value1[0](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "UReview.Route.Types.DownloadFullCSVRoute" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(DownloadFullCSVRoute.create))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)(v.value1[0](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "UReview.Route.Types.Reviewer" && v.value1.length === 2)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Reviewer.create))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)(v.value1[0](Data_Unit.unit))))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)(v.value1[1](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "UReview.Route.Types.RandomReview" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(RandomReview.create))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)(v.value1[0](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "UReview.Route.Types.ReviewRoute" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(ReviewRoute.create))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)(v.value1[0](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "UReview.Route.Types.FullSessionDescriptionRoute" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(FullSessionDescriptionRoute.create))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)(v.value1[0](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "UReview.Route.Types.UsersRoute" && v.value1.length === 0)) {
        return new Data_Maybe.Just(UsersRoute.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "UReview.Route.Types.UserRoute" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(UserRoute.create))(Data_Generic.fromSpine(Model_UserProfile.genericUserKey)(v.value1[0](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "UReview.Route.Types.SignupRoute" && v.value1.length === 0)) {
        return new Data_Maybe.Just(SignupRoute.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "UReview.Route.Types.SignOutRoute" && v.value1.length === 0)) {
        return new Data_Maybe.Just(SignOutRoute.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "UReview.Route.Types.TermsAndConditions" && v.value1.length === 0)) {
        return new Data_Maybe.Just(TermsAndConditions.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "UReview.Route.Types.CodeOfConduct" && v.value1.length === 0)) {
        return new Data_Maybe.Just(CodeOfConduct.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "UReview.Route.Types.NotFound" && v.value1.length === 0)) {
        return new Data_Maybe.Just(NotFound.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "UReview.Route.Types.CurrentMockup" && v.value1.length === 0)) {
        return new Data_Maybe.Just(CurrentMockup.value);
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("UReview.Route.Types.Route", [ {
        sigConstructor: "UReview.Route.Types.Home", 
        sigValues: [  ]
    }, {
        sigConstructor: "UReview.Route.Types.Events", 
        sigValues: [  ]
    }, {
        sigConstructor: "UReview.Route.Types.EventRoute", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "UReview.Route.Types.EditCallForSessions", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "UReview.Route.Types.HiddenSessions", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "UReview.Route.Types.VisibleSessions", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "UReview.Route.Types.ManageReviewers", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "UReview.Route.Types.PanelChairsRoute", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "UReview.Route.Types.OrganiseRoute", 
        sigValues: [  ]
    }, {
        sigConstructor: "UReview.Route.Types.ProposeSessionRoute", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "UReview.Route.Types.ReviewSummaryRoute", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "UReview.Route.Types.DownloadFullCSVRoute", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "UReview.Route.Types.Reviewer", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
        }, function ($dollarq1) {
            return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "UReview.Route.Types.RandomReview", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "UReview.Route.Types.ReviewRoute", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "UReview.Route.Types.FullSessionDescriptionRoute", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "UReview.Route.Types.UsersRoute", 
        sigValues: [  ]
    }, {
        sigConstructor: "UReview.Route.Types.UserRoute", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Model_UserProfile.genericUserKey)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "UReview.Route.Types.SignupRoute", 
        sigValues: [  ]
    }, {
        sigConstructor: "UReview.Route.Types.SignOutRoute", 
        sigValues: [  ]
    }, {
        sigConstructor: "UReview.Route.Types.TermsAndConditions", 
        sigValues: [  ]
    }, {
        sigConstructor: "UReview.Route.Types.CodeOfConduct", 
        sigValues: [  ]
    }, {
        sigConstructor: "UReview.Route.Types.NotFound", 
        sigValues: [  ]
    }, {
        sigConstructor: "UReview.Route.Types.CurrentMockup", 
        sigValues: [  ]
    } ]);
}, function (v) {
    if (v instanceof Home) {
        return new Data_Generic.SProd("UReview.Route.Types.Home", [  ]);
    };
    if (v instanceof Events) {
        return new Data_Generic.SProd("UReview.Route.Types.Events", [  ]);
    };
    if (v instanceof EventRoute) {
        return new Data_Generic.SProd("UReview.Route.Types.EventRoute", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v.value0);
        } ]);
    };
    if (v instanceof EditCallForSessions) {
        return new Data_Generic.SProd("UReview.Route.Types.EditCallForSessions", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v.value0);
        } ]);
    };
    if (v instanceof HiddenSessions) {
        return new Data_Generic.SProd("UReview.Route.Types.HiddenSessions", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v.value0);
        } ]);
    };
    if (v instanceof VisibleSessions) {
        return new Data_Generic.SProd("UReview.Route.Types.VisibleSessions", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v.value0);
        } ]);
    };
    if (v instanceof ManageReviewers) {
        return new Data_Generic.SProd("UReview.Route.Types.ManageReviewers", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v.value0);
        } ]);
    };
    if (v instanceof PanelChairsRoute) {
        return new Data_Generic.SProd("UReview.Route.Types.PanelChairsRoute", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v.value0);
        } ]);
    };
    if (v instanceof OrganiseRoute) {
        return new Data_Generic.SProd("UReview.Route.Types.OrganiseRoute", [  ]);
    };
    if (v instanceof ProposeSessionRoute) {
        return new Data_Generic.SProd("UReview.Route.Types.ProposeSessionRoute", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v.value0);
        } ]);
    };
    if (v instanceof ReviewSummaryRoute) {
        return new Data_Generic.SProd("UReview.Route.Types.ReviewSummaryRoute", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v.value0);
        } ]);
    };
    if (v instanceof DownloadFullCSVRoute) {
        return new Data_Generic.SProd("UReview.Route.Types.DownloadFullCSVRoute", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v.value0);
        } ]);
    };
    if (v instanceof Reviewer) {
        return new Data_Generic.SProd("UReview.Route.Types.Reviewer", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v.value0);
        }, function ($dollarq) {
            return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v.value1);
        } ]);
    };
    if (v instanceof RandomReview) {
        return new Data_Generic.SProd("UReview.Route.Types.RandomReview", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v.value0);
        } ]);
    };
    if (v instanceof ReviewRoute) {
        return new Data_Generic.SProd("UReview.Route.Types.ReviewRoute", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v.value0);
        } ]);
    };
    if (v instanceof FullSessionDescriptionRoute) {
        return new Data_Generic.SProd("UReview.Route.Types.FullSessionDescriptionRoute", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v.value0);
        } ]);
    };
    if (v instanceof UsersRoute) {
        return new Data_Generic.SProd("UReview.Route.Types.UsersRoute", [  ]);
    };
    if (v instanceof UserRoute) {
        return new Data_Generic.SProd("UReview.Route.Types.UserRoute", [ function ($dollarq) {
            return Data_Generic.toSpine(Model_UserProfile.genericUserKey)(v.value0);
        } ]);
    };
    if (v instanceof SignupRoute) {
        return new Data_Generic.SProd("UReview.Route.Types.SignupRoute", [  ]);
    };
    if (v instanceof SignOutRoute) {
        return new Data_Generic.SProd("UReview.Route.Types.SignOutRoute", [  ]);
    };
    if (v instanceof TermsAndConditions) {
        return new Data_Generic.SProd("UReview.Route.Types.TermsAndConditions", [  ]);
    };
    if (v instanceof CodeOfConduct) {
        return new Data_Generic.SProd("UReview.Route.Types.CodeOfConduct", [  ]);
    };
    if (v instanceof NotFound) {
        return new Data_Generic.SProd("UReview.Route.Types.NotFound", [  ]);
    };
    if (v instanceof CurrentMockup) {
        return new Data_Generic.SProd("UReview.Route.Types.CurrentMockup", [  ]);
    };
    throw new Error("Failed pattern match at UReview.Route.Types line 45, column 1 - line 45, column 46: " + [ v.constructor.name ]);
});
var showRoute = new Data_Show.Show(Data_Generic.gShow(genericRoute));
var eqRoute = new Data_Eq.Eq(Data_Generic.gEq(genericRoute));
var arbitraryRoute = new Test_QuickCheck_Arbitrary.Arbitrary(Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Data_ForeignKey.arbForeignKey))(function (v) {
    return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Data_ForeignKey.arbForeignKey))(function (v1) {
        var withOneKey = Data_Functor.map(Data_Functor.functorArray)(function (e) {
            return e(v);
        })([ EditCallForSessions.create, EventRoute.create, PanelChairsRoute.create, ProposeSessionRoute.create, ReviewRoute.create, RandomReview.create, mkUserRoute, FullSessionDescriptionRoute.create, ReviewSummaryRoute.create ]);
        var withoutKey = [ Home.value, TermsAndConditions.value, CodeOfConduct.value, NotFound.value, Events.value, UsersRoute.value, SignOutRoute.value, SignupRoute.value, CurrentMockup.value ];
        return Test_QuickCheck_Gen.elements(new Reviewer(v, v1))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(withOneKey)(withoutKey));
    });
}));
module.exports = {
    Home: Home, 
    Events: Events, 
    EventRoute: EventRoute, 
    EditCallForSessions: EditCallForSessions, 
    HiddenSessions: HiddenSessions, 
    VisibleSessions: VisibleSessions, 
    ManageReviewers: ManageReviewers, 
    PanelChairsRoute: PanelChairsRoute, 
    OrganiseRoute: OrganiseRoute, 
    ProposeSessionRoute: ProposeSessionRoute, 
    ReviewSummaryRoute: ReviewSummaryRoute, 
    DownloadFullCSVRoute: DownloadFullCSVRoute, 
    Reviewer: Reviewer, 
    RandomReview: RandomReview, 
    ReviewRoute: ReviewRoute, 
    FullSessionDescriptionRoute: FullSessionDescriptionRoute, 
    UsersRoute: UsersRoute, 
    UserRoute: UserRoute, 
    SignupRoute: SignupRoute, 
    SignOutRoute: SignOutRoute, 
    TermsAndConditions: TermsAndConditions, 
    CodeOfConduct: CodeOfConduct, 
    NotFound: NotFound, 
    CurrentMockup: CurrentMockup, 
    arbitraryRoute: arbitraryRoute, 
    genericRoute: genericRoute, 
    showRoute: showRoute, 
    eqRoute: eqRoute
};
