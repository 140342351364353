// Generated by psc version 0.9.1
"use strict";
var Prelude = require("../Prelude");
var Data_Foreign = require("../Data.Foreign");
var Control_Monad_Aff = require("../Control.Monad.Aff");
var Control_Monad_Eff_Exception = require("../Control.Monad.Eff.Exception");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class");
var Data_Either = require("../Data.Either");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var Web_Firebase = require("../Web.Firebase");
var Web_Firebase_Types = require("../Web.Firebase.Types");
var Web_Firebase_DataSnapshot = require("../Web.Firebase.DataSnapshot");
var Web_Firebase_Aff = require("../Web.Firebase.Aff");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var Data_Show = require("../Data.Show");
var Control_Applicative = require("../Control.Applicative");
var Control_Bind = require("../Control.Bind");
var Data_Function = require("../Data.Function");
var $$throw = function ($18) {
    return Control_Monad_Error_Class.throwError(Control_Monad_Aff.monadErrorAff)(Control_Monad_Eff_Exception.error($18));
};
var readSnapshot = function (dictIsForeign) {
    return function (snapshot) {
        var value = Web_Firebase_DataSnapshot.val(snapshot);
        var $7 = Data_Foreign_Class.readWith(dictIsForeign)(Data_Show.show(Data_Foreign.showForeignError))(value);
        if ($7 instanceof Data_Either.Left) {
            return $$throw($7.value0);
        };
        if ($7 instanceof Data_Either.Right) {
            return Control_Applicative.pure(Control_Monad_Aff.applicativeAff)($7.value0);
        };
        throw new Error("Failed pattern match at Web.Firebase.Aff.Read line 63, column 3 - line 65, column 24: " + [ $7.constructor.name ]);
    };
};
var readSnapshotWithDefault = function (dictIsForeign) {
    return function ($$default) {
        return function (snapshot) {
            var $10 = Web_Firebase_DataSnapshot.exists(snapshot);
            if ($10) {
                return readSnapshot(dictIsForeign)(snapshot);
            };
            if (!$10) {
                return Control_Applicative.pure(Control_Monad_Aff.applicativeAff)($$default);
            };
            throw new Error("Failed pattern match at Web.Firebase.Aff.Read line 71, column 3 - line 71, column 71: " + [ $10.constructor.name ]);
        };
    };
};
var onceValue = function (root) {
    return Web_Firebase_Aff.once(Web_Firebase.Value.value)(root);
};
var readOnceWithDefault = function (dictIsForeign) {
    return function ($$default) {
        return function (ref) {
            return Control_Bind.bind(Control_Monad_Aff.bindAff)(onceValue(ref))(function (v) {
                var $12 = Web_Firebase_DataSnapshot.exists(v);
                if ($12) {
                    return readSnapshot(dictIsForeign)(v);
                };
                if (!$12) {
                    return Control_Applicative.pure(Control_Monad_Aff.applicativeAff)($$default);
                };
                throw new Error("Failed pattern match at Web.Firebase.Aff.Read line 56, column 3 - line 56, column 64: " + [ $12.constructor.name ]);
            });
        };
    };
};
var valueAt = function (ref) {
    return Control_Bind.bind(Control_Monad_Aff.bindAff)(onceValue(ref))(function (v) {
        return Data_Function.apply(Control_Applicative.pure(Control_Monad_Aff.applicativeAff))(Web_Firebase_DataSnapshot.val(v));
    });
};
var readRecord = function (dictIsForeign) {
    return function (ref) {
        return Control_Bind.bind(Control_Monad_Aff.bindAff)(valueAt(ref))(function (v) {
            var $15 = Data_Foreign_Class.readWith(dictIsForeign)(Data_Show.show(Data_Foreign.showForeignError))(v);
            if ($15 instanceof Data_Either.Left) {
                return $$throw($15.value0);
            };
            if ($15 instanceof Data_Either.Right) {
                return Control_Applicative.pure(Control_Monad_Aff.applicativeAff)($15.value0);
            };
            throw new Error("Failed pattern match at Web.Firebase.Aff.Read line 46, column 3 - line 48, column 24: " + [ $15.constructor.name ]);
        });
    };
};
module.exports = {
    onceValue: onceValue, 
    readOnceWithDefault: readOnceWithDefault, 
    readRecord: readRecord, 
    readSnapshot: readSnapshot, 
    readSnapshotWithDefault: readSnapshotWithDefault, 
    valueAt: valueAt
};
