// Generated by psc version 0.9.1
"use strict";
var Prelude = require("../Prelude");
var Control_Monad_State_Class = require("../Control.Monad.State.Class");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans");
var Data_Identity = require("../Data.Identity");
var Data_Tuple = require("../Data.Tuple");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var withState = Control_Monad_State_Trans.withStateT;
var runState = function (v) {
    return function ($14) {
        return Data_Identity.runIdentity(v($14));
    };
};
var mapState = function (f) {
    return Control_Monad_State_Trans.mapStateT(function ($15) {
        return Data_Identity.Identity(f(Data_Identity.runIdentity($15)));
    });
};
var execState = function (v) {
    return function (s) {
        var $6 = v(s);
        return $6.value1;
    };
};
var evalState = function (v) {
    return function (s) {
        var $11 = v(s);
        return $11.value0;
    };
};
module.exports = {
    evalState: evalState, 
    execState: execState, 
    mapState: mapState, 
    runState: runState, 
    withState: withState
};
