// Generated by psc version 0.9.1
"use strict";
var NotAsked = (function () {
    function NotAsked() {

    };
    NotAsked.value = new NotAsked();
    return NotAsked;
})();
var Loading = (function () {
    function Loading(value0) {
        this.value0 = value0;
    };
    Loading.create = function (value0) {
        return new Loading(value0);
    };
    return Loading;
})();
var Failure = (function () {
    function Failure(value0) {
        this.value0 = value0;
    };
    Failure.create = function (value0) {
        return new Failure(value0);
    };
    return Failure;
})();
var Success = (function () {
    function Success(value0) {
        this.value0 = value0;
    };
    Success.create = function (value0) {
        return new Success(value0);
    };
    return Success;
})();
module.exports = {
    NotAsked: NotAsked, 
    Loading: Loading, 
    Failure: Failure, 
    Success: Success
};
