// Generated by psc version 0.9.1
"use strict";
var $foreign = require("./foreign");
var Prelude = require("../Prelude");
var Pux = require("../Pux");
var Control_Bind = require("../Control.Bind");
var Control_Monad_Eff = require("../Control.Monad.Eff");
var Control_Monad_Eff_Console = require("../Control.Monad.Eff.Console");
var DOM = require("../DOM");
var Pux_Router = require("../Pux.Router");
var Signal = require("../Signal");
var Signal_Channel = require("../Signal.Channel");
var State = require("../State");
var State_Types = require("../State.Types");
var UReview_Route_Parser = require("../UReview.Route.Parser");
var View_Layout = require("../View.Layout");
var Ports_Firebase_Types = require("../Ports.Firebase.Types");
var Control_Applicative = require("../Control.Applicative");
var config = function (state) {
    return function __do() {
        var v = Pux_Router.sampleUrl();
        var routeSignal = Signal.flippedMap(Signal.functorSignal)(v)(function (r) {
            return new State_Types.PageView(UReview_Route_Parser.match(r));
        });
        var firebaseSignal = Signal_Channel.subscribe(state.channel);
        return {
            initialState: state, 
            update: State.update, 
            view: View_Layout.layout, 
            inputs: [ firebaseSignal, routeSignal ]
        };
    };
};
var main = function (state) {
    return function __do() {
        $foreign.requireCss();
        var v = Control_Bind.bindFlipped(Control_Monad_Eff.bindEff)(Pux.start)(config(state))();
        Pux.renderToDOM("#app")(v.html)();
        return v;
    };
};
module.exports = {
    config: config, 
    main: main, 
    requireCss: $foreign.requireCss
};
