// Generated by psc version 0.9.1
"use strict";
var Control_Monad_Reader_Class = require("../Control.Monad.Reader.Class");
var Control_Monad_State_Class = require("../Control.Monad.State.Class");
var Control_Monad_Trans = require("../Control.Monad.Trans");
var Control_Monad_Writer_Class = require("../Control.Monad.Writer.Class");
var MonadRWS = function (__superclass_Control$dotMonad$dotReader$dotClass$dotMonadReader_0, __superclass_Control$dotMonad$dotState$dotClass$dotMonadState_2, __superclass_Control$dotMonad$dotWriter$dotClass$dotMonadWriter_1) {
    this["__superclass_Control.Monad.Reader.Class.MonadReader_0"] = __superclass_Control$dotMonad$dotReader$dotClass$dotMonadReader_0;
    this["__superclass_Control.Monad.State.Class.MonadState_2"] = __superclass_Control$dotMonad$dotState$dotClass$dotMonadState_2;
    this["__superclass_Control.Monad.Writer.Class.MonadWriter_1"] = __superclass_Control$dotMonad$dotWriter$dotClass$dotMonadWriter_1;
};
module.exports = {
    MonadRWS: MonadRWS
};
