// Generated by psc version 0.9.1
"use strict";
var Data_Foreign = require("../Data.Foreign");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var Data_Generic = require("../Data.Generic");
var Prelude = require("../Prelude");
var Control_Bind = require("../Control.Bind");
var Data_Function = require("../Data.Function");
var Control_Applicative = require("../Control.Applicative");
var Data_Maybe = require("../Data.Maybe");
var Data_Show = require("../Data.Show");
var Data_Eq = require("../Data.Eq");
var Data_Ord = require("../Data.Ord");
var Data_Either = require("../Data.Either");
var Connected = (function () {
    function Connected() {

    };
    Connected.value = new Connected();
    return Connected;
})();
var Disconnected = (function () {
    function Disconnected() {

    };
    Disconnected.value = new Disconnected();
    return Disconnected;
})();
var showConnection = new Data_Show.Show(function (c) {
    if (c instanceof Connected) {
        return "online";
    };
    if (c instanceof Disconnected) {
        return "offline";
    };
    throw new Error("Failed pattern match at Model.Connection line 25, column 12 - line 29, column 1: " + [ c.constructor.name ]);
});
var mkConnected = Connected.value;
var isForeignConnection = new Data_Foreign_Class.IsForeign(function (fo) {
    return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign.readBoolean(fo))(function (v) {
        return Data_Function.apply(Control_Applicative.pure(Data_Either.applicativeEither))((function () {
            if (v) {
                return Connected.value;
            };
            if (!v) {
                return Disconnected.value;
            };
            throw new Error("Failed pattern match at Model.Connection line 19, column 5 - line 19, column 53: " + [ v.constructor.name ]);
        })());
    });
});
var genericConnection = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.Connection.Connected" && v.value1.length === 0)) {
        return new Data_Maybe.Just(Connected.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.Connection.Disconnected" && v.value1.length === 0)) {
        return new Data_Maybe.Just(Disconnected.value);
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Model.Connection.Connection", [ {
        sigConstructor: "Model.Connection.Connected", 
        sigValues: [  ]
    }, {
        sigConstructor: "Model.Connection.Disconnected", 
        sigValues: [  ]
    } ]);
}, function (v) {
    if (v instanceof Connected) {
        return new Data_Generic.SProd("Model.Connection.Connected", [  ]);
    };
    if (v instanceof Disconnected) {
        return new Data_Generic.SProd("Model.Connection.Disconnected", [  ]);
    };
    throw new Error("Failed pattern match at Model.Connection line 22, column 1 - line 22, column 56: " + [ v.constructor.name ]);
});
var eqConnection = new Data_Eq.Eq(Data_Generic.gEq(genericConnection));
var ordConnection = new Data_Ord.Ord(function () {
    return eqConnection;
}, Data_Generic.gCompare(genericConnection));
module.exports = {
    mkConnected: mkConnected, 
    isForeignConnection: isForeignConnection, 
    genericConnection: genericConnection, 
    showConnection: showConnection, 
    eqConnection: eqConnection, 
    ordConnection: ordConnection
};
