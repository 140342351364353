// This can not be in src, otherwise pulp test will not work
// pulp thinks it is an FFI module

var Main = require('../output/Client/index.js');
window.EventModule = require('../output/Model.Event/index.js');
var hostName = document.location.hostname;
var initMe = require('../output/InitialState/index.js').initHost;
require('../scss/app.scss');

const appDiv = document.createElement("div");
appDiv.id = "app";
document.body.appendChild(appDiv);
window.document.title = "WeReview";
window.React = require("react");

if(!window.React) {
  throw new Error("React loading failed");
} else {
  console.log("Loaded React.");
}
var debug = process.env.NODE_ENV === 'development';

if(module.hot) {
  console.log("Hot reload enabled");
} else  {
  console.log("Hot loading disabled");
}
load(initMe(hostName)());

function load(initialState) {
  console.log ("Starting load");
  if (module.hot) {
    console.log("Hot loading");
    var app = Main.main(window.puxLastState || initialState)();
    app.state.subscribe(function (state) {
     window.puxLastState = state;
    });
    console.log("Will accept hot loading");
    module.hot.accept();
    console.log("Done accept hot loading");
  } else {
    console.log("Not hot loading");
    Main.main(initialState)();
    console.log("Done after not hot loading");
  }
}
