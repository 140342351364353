// Generated by psc version 0.9.1
"use strict";
var CSS_Selector = require("../CSS.Selector");
var CSS_String = require("../CSS.String");
var $$var = CSS_String.fromString(CSS_Selector.isStringSelector)("var");
var ul = CSS_String.fromString(CSS_Selector.isStringSelector)("ul");
var u = CSS_String.fromString(CSS_Selector.isStringSelector)("u");
var tr = CSS_String.fromString(CSS_Selector.isStringSelector)("tr");
var thead = CSS_String.fromString(CSS_Selector.isStringSelector)("thead");
var th = CSS_String.fromString(CSS_Selector.isStringSelector)("th");
var tfoot = CSS_String.fromString(CSS_Selector.isStringSelector)("tfoot");
var textarea = CSS_String.fromString(CSS_Selector.isStringSelector)("textarea");
var td = CSS_String.fromString(CSS_Selector.isStringSelector)("td");
var tbody = CSS_String.fromString(CSS_Selector.isStringSelector)("tbody");
var sup = CSS_String.fromString(CSS_Selector.isStringSelector)("sup");
var summary = CSS_String.fromString(CSS_Selector.isStringSelector)("summary");
var sub = CSS_String.fromString(CSS_Selector.isStringSelector)("sub");
var strong = CSS_String.fromString(CSS_Selector.isStringSelector)("strong");
var span = CSS_String.fromString(CSS_Selector.isStringSelector)("span");
var small = CSS_String.fromString(CSS_Selector.isStringSelector)("small");
var section = CSS_String.fromString(CSS_Selector.isStringSelector)("section");
var samp = CSS_String.fromString(CSS_Selector.isStringSelector)("samp");
var s = CSS_String.fromString(CSS_Selector.isStringSelector)("s");
var q = CSS_String.fromString(CSS_Selector.isStringSelector)("q");
var progress = CSS_String.fromString(CSS_Selector.isStringSelector)("progress");
var pre = CSS_String.fromString(CSS_Selector.isStringSelector)("pre");
var p = CSS_String.fromString(CSS_Selector.isStringSelector)("p");
var output = CSS_String.fromString(CSS_Selector.isStringSelector)("output");
var optgroup = CSS_String.fromString(CSS_Selector.isStringSelector)("optgroup");
var ol = CSS_String.fromString(CSS_Selector.isStringSelector)("ol");
var object = CSS_String.fromString(CSS_Selector.isStringSelector)("object");
var nav = CSS_String.fromString(CSS_Selector.isStringSelector)("nav");
var meter = CSS_String.fromString(CSS_Selector.isStringSelector)("meter");
var mark = CSS_String.fromString(CSS_Selector.isStringSelector)("mark");
var map = CSS_String.fromString(CSS_Selector.isStringSelector)("map");
var main = CSS_String.fromString(CSS_Selector.isStringSelector)("main");
var li = CSS_String.fromString(CSS_Selector.isStringSelector)("li");
var legend = CSS_String.fromString(CSS_Selector.isStringSelector)("legend");
var label = CSS_String.fromString(CSS_Selector.isStringSelector)("label");
var kbd = CSS_String.fromString(CSS_Selector.isStringSelector)("kbd");
var ins = CSS_String.fromString(CSS_Selector.isStringSelector)("ins");
var input = CSS_String.fromString(CSS_Selector.isStringSelector)("input");
var img = CSS_String.fromString(CSS_Selector.isStringSelector)("img");
var iframe = CSS_String.fromString(CSS_Selector.isStringSelector)("iframe");
var i = CSS_String.fromString(CSS_Selector.isStringSelector)("i");
var html = CSS_String.fromString(CSS_Selector.isStringSelector)("html");
var hr = CSS_String.fromString(CSS_Selector.isStringSelector)("hr");
var header = CSS_String.fromString(CSS_Selector.isStringSelector)("header");
var h6 = CSS_String.fromString(CSS_Selector.isStringSelector)("h6");
var h5 = CSS_String.fromString(CSS_Selector.isStringSelector)("h5");
var h4 = CSS_String.fromString(CSS_Selector.isStringSelector)("h4");
var h3 = CSS_String.fromString(CSS_Selector.isStringSelector)("h3");
var h2 = CSS_String.fromString(CSS_Selector.isStringSelector)("h2");
var h1 = CSS_String.fromString(CSS_Selector.isStringSelector)("h1");
var form = CSS_String.fromString(CSS_Selector.isStringSelector)("form");
var footer = CSS_String.fromString(CSS_Selector.isStringSelector)("footer");
var figure = CSS_String.fromString(CSS_Selector.isStringSelector)("figure");
var figcaption = CSS_String.fromString(CSS_Selector.isStringSelector)("figcaption");
var fieldset = CSS_String.fromString(CSS_Selector.isStringSelector)("fieldset");
var embed = CSS_String.fromString(CSS_Selector.isStringSelector)("embed");
var dt = CSS_String.fromString(CSS_Selector.isStringSelector)("dt");
var dl = CSS_String.fromString(CSS_Selector.isStringSelector)("dl");
var div = CSS_String.fromString(CSS_Selector.isStringSelector)("div");
var dfn = CSS_String.fromString(CSS_Selector.isStringSelector)("dfn");
var details = CSS_String.fromString(CSS_Selector.isStringSelector)("details");
var del = CSS_String.fromString(CSS_Selector.isStringSelector)("del");
var dd = CSS_String.fromString(CSS_Selector.isStringSelector)("dd");
var datalist = CSS_String.fromString(CSS_Selector.isStringSelector)("datalist");
var colgroup = CSS_String.fromString(CSS_Selector.isStringSelector)("colgroup");
var col = CSS_String.fromString(CSS_Selector.isStringSelector)("col");
var code = CSS_String.fromString(CSS_Selector.isStringSelector)("code");
var cite = CSS_String.fromString(CSS_Selector.isStringSelector)("cite");
var caption = CSS_String.fromString(CSS_Selector.isStringSelector)("caption");
var canvas = CSS_String.fromString(CSS_Selector.isStringSelector)("canvas");
var button = CSS_String.fromString(CSS_Selector.isStringSelector)("button");
var br = CSS_String.fromString(CSS_Selector.isStringSelector)("br");
var body = CSS_String.fromString(CSS_Selector.isStringSelector)("body");
var blockquote = CSS_String.fromString(CSS_Selector.isStringSelector)("blockquote");
var bdo = CSS_String.fromString(CSS_Selector.isStringSelector)("bdo");
var bdi = CSS_String.fromString(CSS_Selector.isStringSelector)("bdi");
var b = CSS_String.fromString(CSS_Selector.isStringSelector)("b");
var audio = CSS_String.fromString(CSS_Selector.isStringSelector)("audio");
var aside = CSS_String.fromString(CSS_Selector.isStringSelector)("aside");
var article = CSS_String.fromString(CSS_Selector.isStringSelector)("article");
var area = CSS_String.fromString(CSS_Selector.isStringSelector)("area");
var address = CSS_String.fromString(CSS_Selector.isStringSelector)("address");
var abbr = CSS_String.fromString(CSS_Selector.isStringSelector)("abbr");
var a = CSS_String.fromString(CSS_Selector.isStringSelector)("a");
module.exports = {
    a: a, 
    abbr: abbr, 
    address: address, 
    area: area, 
    article: article, 
    aside: aside, 
    audio: audio, 
    b: b, 
    bdi: bdi, 
    bdo: bdo, 
    blockquote: blockquote, 
    body: body, 
    br: br, 
    button: button, 
    canvas: canvas, 
    caption: caption, 
    cite: cite, 
    code: code, 
    col: col, 
    colgroup: colgroup, 
    datalist: datalist, 
    dd: dd, 
    del: del, 
    details: details, 
    dfn: dfn, 
    div: div, 
    dl: dl, 
    dt: dt, 
    embed: embed, 
    fieldset: fieldset, 
    figcaption: figcaption, 
    figure: figure, 
    footer: footer, 
    form: form, 
    h1: h1, 
    h2: h2, 
    h3: h3, 
    h4: h4, 
    h5: h5, 
    h6: h6, 
    header: header, 
    hr: hr, 
    html: html, 
    i: i, 
    iframe: iframe, 
    img: img, 
    input: input, 
    ins: ins, 
    kbd: kbd, 
    label: label, 
    legend: legend, 
    li: li, 
    main: main, 
    map: map, 
    mark: mark, 
    meter: meter, 
    nav: nav, 
    object: object, 
    ol: ol, 
    optgroup: optgroup, 
    output: output, 
    p: p, 
    pre: pre, 
    progress: progress, 
    q: q, 
    s: s, 
    samp: samp, 
    section: section, 
    small: small, 
    span: span, 
    strong: strong, 
    sub: sub, 
    summary: summary, 
    sup: sup, 
    tbody: tbody, 
    td: td, 
    textarea: textarea, 
    tfoot: tfoot, 
    th: th, 
    thead: thead, 
    tr: tr, 
    u: u, 
    ul: ul, 
    "var": $$var
};
