// Generated by psc version 0.9.1
"use strict";
var Control_Alt = require("../Control.Alt");
var Data_Default = require("../Data.Default");
var Data_Firebase_Foreign = require("../Data.Firebase.Foreign");
var Data_Foldable = require("../Data.Foldable");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var Data_Foreign_Generic = require("../Data.Foreign.Generic");
var Data_Generic = require("../Data.Generic");
var Data_String = require("../Data.String");
var Data_Ord = require("../Data.Ord");
var Data_HumanReadable = require("../Data.HumanReadable");
var Data_Valid = require("../Data.Valid");
var Data_VersionNumber = require("../Data.VersionNumber");
var Prelude = require("../Prelude");
var Test_QuickCheck_Arbitrary = require("../Test.QuickCheck.Arbitrary");
var UReview_Prelude = require("../UReview.Prelude");
var Control_Apply = require("../Control.Apply");
var Data_Maybe = require("../Data.Maybe");
var Data_Unit = require("../Data.Unit");
var Data_Show = require("../Data.Show");
var Data_Eq = require("../Data.Eq");
var Data_Function = require("../Data.Function");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra");
var Control_Bind = require("../Control.Bind");
var Control_Applicative = require("../Control.Applicative");
var Data_Semigroup = require("../Data.Semigroup");
var Data_BooleanAlgebra = require("../Data.BooleanAlgebra");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans");
var Data_Identity = require("../Data.Identity");
var Place = function (x) {
    return x;
};
var Country = function (x) {
    return x;
};
var City = function (x) {
    return x;
};
var validPlace = new Data_Valid.Valid(function (v) {
    return Data_Function.apply(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Foldable.any(Data_Foldable.foldableArray)(Data_BooleanAlgebra.booleanAlgebraBoolean)(Data_String["null"])([ v.city, v.country ]));
});
var mkPlace = function (v) {
    return function (v1) {
        return {
            city: v, 
            country: v1
        };
    };
};
var humanReadablePlace = new Data_HumanReadable.HumanReadable(function (v) {
    return [ "City: " + v.city, "Country: " + v.country ];
});
var genericPlace = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.SessionDescription.Expenses.Place.Place" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Place))((function (r) {
            if (r instanceof Data_Generic.SRecord && r.value0.length === 2) {
                return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(function (city1) {
                    return function (country1) {
                        return {
                            city: city1, 
                            country: country1
                        };
                    };
                }))(Data_Generic.fromSpine(Data_Generic.genericString)((r.value0[0]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Data_Generic.genericString)((r.value0[1]).recValue(Data_Unit.unit)));
            };
            return Data_Maybe.Nothing.value;
        })(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Model.SessionDescription.Expenses.Place.Place", [ {
        sigConstructor: "Model.SessionDescription.Expenses.Place.Place", 
        sigValues: [ function ($dollarq1) {
            return new Data_Generic.SigRecord([ {
                recLabel: "city", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "country", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
                }
            } ]);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("Model.SessionDescription.Expenses.Place.Place", [ function ($dollarq) {
        return new Data_Generic.SRecord([ {
            recLabel: "city", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_Generic.genericString)(v.city);
            }
        }, {
            recLabel: "country", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_Generic.genericString)(v.country);
            }
        } ]);
    } ]);
});
var isForeignPlace = new Data_Foreign_Class.IsForeign(function (fo) {
    return Data_Foreign_Generic.readGeneric(genericPlace)(Data_VersionNumber.jsonOptions)(fo);
});
var showPlace = new Data_Show.Show(Data_Generic.gShow(genericPlace));
var toFirebaseForeignPlace = new Data_Firebase_Foreign.ToFirebaseForeign(UReview_Prelude.writeForeign(genericPlace));
var eqPlace = new Data_Eq.Eq(Data_Generic.gEq(genericPlace));
var ordPlace = new Data_Ord.Ord(function () {
    return eqPlace;
}, Data_Generic.gCompare(genericPlace));
var defaultPlace = new Data_Default.Default({
    city: Data_Default["default"](Data_Default.defaultString), 
    country: Data_Default["default"](Data_Default.defaultString)
});
var arbPlace = new Test_QuickCheck_Arbitrary.Arbitrary(Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Test_QuickCheck_Arbitrary.arbString))(function (v) {
    return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Test_QuickCheck_Arbitrary.arbString))(function (v1) {
        return Data_Function.apply(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity)))({
            city: v, 
            country: v1
        });
    });
}));
module.exports = {
    City: City, 
    Country: Country, 
    Place: Place, 
    mkPlace: mkPlace, 
    genericPlace: genericPlace, 
    showPlace: showPlace, 
    eqPlace: eqPlace, 
    ordPlace: ordPlace, 
    isForeignPlace: isForeignPlace, 
    toFirebaseForeignPlace: toFirebaseForeignPlace, 
    validPlace: validPlace, 
    defaultPlace: defaultPlace, 
    arbPlace: arbPlace, 
    humanReadablePlace: humanReadablePlace
};
