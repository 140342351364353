// Generated by psc version 0.9.1
"use strict";
var Components_Elements = require("../Components.Elements");
var Components_ViewAllowed = require("../Components.ViewAllowed");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_Semigroup = require("../Data.Semigroup");
var Data_StrMap = require("../Data.StrMap");
var Data_Tuple = require("../Data.Tuple");
var Model_CallForSessions = require("../Model.CallForSessions");
var Model_OpenCallsForSessions = require("../Model.OpenCallsForSessions");
var Model_OpenCallForSessions_PublicationStatus = require("../Model.OpenCallForSessions.PublicationStatus");
var Model_Event = require("../Model.Event");
var Prelude = require("../Prelude");
var Pux_Html = require("../Pux.Html");
var Pux_Html_Attributes = require("../Pux.Html.Attributes");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var Pux_Html_Events = require("../Pux.Html.Events");
var Route_HtmlElements = require("../Route.HtmlElements");
var State_Types = require("../State.Types");
var UReview_Route_Types = require("../UReview.Route.Types");
var Components_Elements_Basic_Types = require("../Components.Elements.Basic.Types");
var Data_Function = require("../Data.Function");
var Data_Functor = require("../Data.Functor");
var Data_Monoid = require("../Data.Monoid");
var showUsers = function (state) {
    var $4 = Components_ViewAllowed.administrator(state);
    if ($4) {
        return Pux_Html_Elements.div([  ])([ Pux_Html_Elements.h2([  ])([ Pux_Html_Elements.text("View users") ]), Components_Elements.ptext("View a list of the current users."), Route_HtmlElements.routeButton(UReview_Route_Types.UsersRoute.value)(Components_Elements_Basic_Types.mkHtmlId("goto-user"))("View Users") ]);
    };
    if (!$4) {
        return Pux_Html_Elements.div([  ])([  ]);
    };
    throw new Error("Failed pattern match at View.Organise line 106, column 3 - line 113, column 13: " + [ $4.constructor.name ]);
};
var showAllCalls = function (cfss) {
    var editCFS = function (aCfs) {
        var key = Model_CallForSessions.cfsKey(aCfs);
        var proposeRoute = new UReview_Route_Types.ProposeSessionRoute(key);
        var editRoute = new UReview_Route_Types.EditCallForSessions(key);
        var cell = function (html) {
            return Pux_Html_Elements.td([  ])([ html ]);
        };
        return Data_Function.apply(Pux_Html_Elements.tr([  ]))(Data_Functor.map(Data_Functor.functorArray)(cell)([ Route_HtmlElements.routeButton(editRoute)(Data_Function.apply(Components_Elements_Basic_Types.mkHtmlId)("cfs-edit-" + Model_CallForSessions.keyStr(aCfs)))("Edit"), Data_Function.apply(Pux_Html_Elements.text)(" " + (Data_Function.apply(Model_OpenCallForSessions_PublicationStatus.verbosePublicationStatus)(Model_CallForSessions.getPublicationStatus(aCfs)) + " ")), Data_Function.apply(Route_HtmlElements.htmlLink(proposeRoute))(Model_CallForSessions.eventNameStr(aCfs)) ]));
    };
    var calls = Model_OpenCallsForSessions.toArray(cfss);
    var showListOfCalls = function (headingText) {
        return function (explanation) {
            return Pux_Html_Elements.div([ Pux_Html_Attributes.className("column") ])([ Pux_Html_Elements.h2([  ])([ Pux_Html_Elements.text(headingText) ]), Components_Elements.ptext(explanation), Pux_Html_Elements.table([  ])(Data_Functor.map(Data_Functor.functorArray)(editCFS)(calls)) ]);
        };
    };
    return showListOfCalls("All open calls for Sessions")("Only visible to administrators");
};
var shortTextField = function (v) {
    return function (setter) {
        return function (questionText) {
            return function (currentValue) {
                return Pux_Html.withChildren(Pux_Html_Elements.div)([ Pux_Html.withChild(Pux_Html.withAttr(Pux_Html_Elements.label)(Pux_Html_Attributes.htmlFor(v)))(Pux_Html_Elements.text(questionText)), Pux_Html_Elements.input([ Pux_Html_Attributes.id_(v), Pux_Html_Attributes.type_("text"), Pux_Html_Attributes.value(currentValue), Pux_Html_Events.onChange(State_Types.ChangedAddEventText.create(setter)) ])([  ]) ]);
            };
        };
    };
};
var linkToEvent = function (v) {
    return Pux_Html_Elements.li([  ])([ Route_HtmlElements.routeLink(new UReview_Route_Types.EventRoute(v.value0))(v.value1.name) ]);
};
var showListOfEvents = function (headingText) {
    return function (explanation) {
        return function (events) {
            return Pux_Html_Elements.div([ Pux_Html_Attributes.className("column") ])([ Pux_Html_Elements.h2([  ])([ Pux_Html_Elements.text(headingText) ]), Components_Elements.ptext(explanation), Pux_Html_Elements.ul([ Pux_Html_Attributes.className("no-bullet") ])(Data_Functor.map(Data_Functor.functorArray)(linkToEvent)(events)) ]);
        };
    };
};
var showAllEvents = showListOfEvents("All events")("Only visible to administrators");
var createButton = function (v) {
    return function (buttonText) {
        return Pux_Html.withChild(Pux_Html.withAttr(Pux_Html.withAttr(Pux_Html_Elements.button)(Pux_Html_Attributes.id_(v)))(Pux_Html_Events.onClick(Data_Function["const"](State_Types.AddEvent.value))))(Pux_Html_Elements.text(buttonText));
    };
};
var addEvent = function (v) {
    return Pux_Html_Elements.div([ Pux_Html_Attributes.className("column") ])([ Pux_Html_Elements.fieldset([  ])([ Pux_Html_Elements.legend([  ])([ Pux_Html_Elements.text("Add an Event. Set shortcode for the last part of the events' url, and a descriptive event name for e.g. page headings.") ]), Pux_Html_Elements.h2([  ])([ Pux_Html_Elements.text("Add event") ]), Components_Elements.ptext("To create a new event and open session submissions to potential speakers."), shortTextField(Components_Elements_Basic_Types.mkHtmlId("shortCode"))(Model_Event.setShortCode)("Shortcode (e.g. test3035)")(v.shortCode), shortTextField(Components_Elements_Basic_Types.mkHtmlId("displayName"))(Model_Event.setName)("Name (e.g. UX Scotland 2016)")(v.name), createButton(Components_Elements_Basic_Types.mkHtmlId("addEvent"))("Add") ]) ]);
};
var actions = function (state) {
    var mkEventLink = function (s) {
        return function (e) {
            return [ new Data_Tuple.Tuple(Data_ForeignKey.mkForeignKey(s), e) ];
        };
    };
    var eventsArray = Data_StrMap.foldMap(Data_Monoid.monoidArray)(mkEventLink);
    return [ Data_Function.apply(showAllCalls)(Model_OpenCallsForSessions.notClosed(state.openCalls)), Data_Function.apply(showAllEvents)(eventsArray(state.events)), addEvent(state.newEvent), showUsers(state) ];
};
var organiseView = function (state) {
    return Data_Function.apply(Pux_Html_Elements.section([ Pux_Html_Attributes.className("events") ]))(Components_ViewAllowed.viewAllowed([ Data_Function.apply(Data_Tuple.Tuple.create(Components_ViewAllowed.administrator))(Pux_Html_Elements.div([ Pux_Html_Attributes.className("row medium-unstack") ])(actions(state))) ])(state));
};
module.exports = {
    actions: actions, 
    addEvent: addEvent, 
    createButton: createButton, 
    linkToEvent: linkToEvent, 
    organiseView: organiseView, 
    shortTextField: shortTextField, 
    showAllCalls: showAllCalls, 
    showAllEvents: showAllEvents, 
    showListOfEvents: showListOfEvents, 
    showUsers: showUsers
};
