// Generated by psc version 0.9.1
"use strict";
var Components_DB_Action = require("../Components.DB.Action");
var Control_Alternative = require("../Control.Alternative");
var Control_Monad_Aff = require("../Control.Monad.Aff");
var Control_Monad_Eff_Class = require("../Control.Monad.Eff.Class");
var Data_Firebase_References = require("../Data.Firebase.References");
var Data_Firebase_Review = require("../Data.Firebase.Review");
var Data_Firebase_WriteParentChild = require("../Data.Firebase.WriteParentChild");
var Data_Foreign = require("../Data.Foreign");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_Maybe = require("../Data.Maybe");
var Data_Monoid = require("../Data.Monoid");
var Data_Tuple = require("../Data.Tuple");
var Model_Reviewer = require("../Model.Reviewer");
var Model_UserProfile = require("../Model.UserProfile");
var Ports_Firebase_Aff = require("../Ports.Firebase.Aff");
var Ports_Firebase_Types = require("../Ports.Firebase.Types");
var Prelude = require("../Prelude");
var Signal_Channel = require("../Signal.Channel");
var State_Reviewer = require("../State.Reviewer");
var State_Types = require("../State.Types");
var UReview_UserNotifications = require("../UReview.UserNotifications");
var WeReview_Data_Schema_1 = require("../WeReview.Data.Schema");
var WeReview_Data_Schema_1 = require("../WeReview.Data.Schema");
var Data_Function = require("../Data.Function");
var Control_Bind = require("../Control.Bind");
var Control_Applicative = require("../Control.Applicative");
var Data_Unit = require("../Data.Unit");
var Data_Firebase_Foreign = require("../Data.Firebase.Foreign");
var Web_Firebase_Aff = require("../Web.Firebase.Aff");
var Data_Set = require("../Data.Set");
var Data_Functor = require("../Data.Functor");
var writeNextReviewFor = function (v) {
    return function (channel) {
        return function (state) {
            return function (root) {
                var review = State_Reviewer.generateReviewFor(v.value0)(v.value1)(state);
                if (review instanceof Data_Maybe.Nothing) {
                    return Data_Function.apply(Control_Monad_Eff_Class.liftEff(Control_Monad_Aff.monadEffAff))(UReview_UserNotifications.sendErrorMessage(channel)("No more sessions available to review for now."));
                };
                if (review instanceof Data_Maybe.Just) {
                    return Control_Bind.bind(Control_Monad_Aff.bindAff)(Data_Firebase_Review.createReview(review.value0)(root))(function () {
                        return Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(Data_Unit.unit);
                    });
                };
                throw new Error("Failed pattern match at Data.Firebase.Reviewer line 83, column 3 - line 86, column 31: " + [ review.constructor.name ]);
            };
        };
    };
};
var setReviewerRole = function (enabled) {
    return function (v) {
        return function (v1) {
            return function (v2) {
                return function (rootRef) {
                    var userKey = Model_UserProfile.mkUserKey(v.uidKey);
                    return Control_Bind.bind(Control_Monad_Aff.bindAff)(Data_Firebase_WriteParentChild.set(WeReview_Data_Schema_1.pathToWeReviewSchema)(Data_Firebase_Foreign.toFirebaseForeignBoolean)(Data_Function.apply(WeReview_Data_Schema_1.Roles.create(userKey))(new WeReview_Data_Schema_1.ReviewerFor(v.eventKey)))(enabled)(rootRef))(function () {
                        return Data_Firebase_WriteParentChild.set(WeReview_Data_Schema_1.pathToWeReviewSchema)(Data_Firebase_Foreign.toFirebaseForeignBoolean)(Data_Function.apply(WeReview_Data_Schema_1.Events.create(v.eventKey))(new WeReview_Data_Schema_1.Reviewer(v.reviewerKey)))(enabled)(rootRef);
                    });
                };
            };
        };
    };
};
var refToReviewers = Web_Firebase_Aff.child("/reviewers/");
var createReviewer = function (eventKey) {
    return function (uidKey) {
        return function (rootRef) {
            var childRecord = Data_Function.apply(Model_Reviewer.Reviewer)((function () {
                var $23 = {};
                for (var $24 in Model_Reviewer.runReviewer(Model_Reviewer.nullReviewer)) {
                    if ((Model_Reviewer.runReviewer(Model_Reviewer.nullReviewer)).hasOwnProperty($24)) {
                        $23[$24] = (Model_Reviewer.runReviewer(Model_Reviewer.nullReviewer))[$24];
                    };
                };
                $23.eventId = eventKey;
                $23.uid = uidKey;
                $23.reviews = Data_Monoid.mempty(Data_Set.monoidSet(Data_ForeignKey.ordForeignKey));
                return $23;
            })());
            var userKey = Model_UserProfile.mkUserKey(uidKey);
            return Control_Bind.bind(Control_Monad_Aff.bindAff)(Data_Firebase_WriteParentChild.set(WeReview_Data_Schema_1.pathToWeReviewSchema)(Data_Firebase_Foreign.toFirebaseForeignBoolean)(Data_Function.apply(WeReview_Data_Schema_1.Roles.create(userKey))(new WeReview_Data_Schema_1.ReviewerFor(eventKey)))(true)(rootRef))(function () {
                return Control_Bind.bind(Control_Monad_Aff.bindAff)(refToReviewers(rootRef))(function (v) {
                    return Control_Bind.bind(Control_Monad_Aff.bindAff)(Data_Firebase_References.refToEventReviewers(eventKey)(rootRef))(function (v1) {
                        return Data_Functor.map(Control_Monad_Aff.functorAff)(Model_Reviewer.fromForeignKey)(Data_Firebase_WriteParentChild.pushChild(Model_Reviewer.toFirebaseReviewer)(childRecord)(v)(v1));
                    });
                });
            });
        };
    };
};
var createReviewer$prime = function (eventKey) {
    return function (uidKey) {
        return function (rootRef) {
            return createReviewer(eventKey)(Model_UserProfile.asForeignKey(uidKey))(rootRef);
        };
    };
};
var createReviewerProcedure = function (e) {
    return function (u) {
        return function (r) {
            return Control_Bind.bind(Control_Monad_Aff.bindAff)(createReviewer(e)(u)(r))(function () {
                return Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(State_Types.Noop.value);
            });
        };
    };
};
var createReviewCommand = function (v) {
    return new Components_DB_Action.Push(new WeReview_Data_Schema_1.Command(WeReview_Data_Schema_1.CreateReview.value), Data_Foreign.toForeign({
        eventId: Data_Foreign.toForeign(v.value1.eventId), 
        reviewerId: Data_Foreign.toForeign(v.value0), 
        created: Ports_Firebase_Types.firebaseServerTimestamp
    }));
};
module.exports = {
    createReviewCommand: createReviewCommand, 
    createReviewer: createReviewer, 
    "createReviewer'": createReviewer$prime, 
    createReviewerProcedure: createReviewerProcedure, 
    refToReviewers: refToReviewers, 
    setReviewerRole: setReviewerRole, 
    writeNextReviewFor: writeNextReviewFor
};
