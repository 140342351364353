// Generated by psc version 0.9.1
"use strict";
var Prelude = require("../Prelude");
var Data_Generic = require("../Data.Generic");
var Test_QuickCheck_Arbitrary = require("../Test.QuickCheck.Arbitrary");
var Test_QuickCheck_Gen = require("../Test.QuickCheck.Gen");
var Data_Functor = require("../Data.Functor");
var Control_Apply = require("../Control.Apply");
var Data_Maybe = require("../Data.Maybe");
var Data_Unit = require("../Data.Unit");
var Data_Show = require("../Data.Show");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans");
var Data_Identity = require("../Data.Identity");
var SmallPositiveInt = function (x) {
    return x;
};
var runSmallPositiveInt = function (v) {
    return v;
};
var mkSmallPositiveInt = function (n) {
    return n;
};
var genericSmallPositiveInt = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Data.SmallPositiveInt.SmallPositiveInt" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(SmallPositiveInt))(Data_Generic.fromSpine(Data_Generic.genericInt)(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Data.SmallPositiveInt.SmallPositiveInt", [ {
        sigConstructor: "Data.SmallPositiveInt.SmallPositiveInt", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_Generic.genericInt)(Data_Generic.anyProxy);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("Data.SmallPositiveInt.SmallPositiveInt", [ function ($dollarq) {
        return Data_Generic.toSpine(Data_Generic.genericInt)(v);
    } ]);
});
var showSmallPositiveInt = new Data_Show.Show(Data_Generic.gShow(genericSmallPositiveInt));
var arbSmallPositiveInt = new Test_QuickCheck_Arbitrary.Arbitrary(Data_Functor.map(Control_Monad_State_Trans.functorStateT(Data_Identity.functorIdentity))(SmallPositiveInt)(Test_QuickCheck_Gen.chooseInt(1)(4)));
module.exports = {
    SmallPositiveInt: SmallPositiveInt, 
    mkSmallPositiveInt: mkSmallPositiveInt, 
    runSmallPositiveInt: runSmallPositiveInt, 
    arbSmallPositiveInt: arbSmallPositiveInt, 
    genericSmallPositiveInt: genericSmallPositiveInt, 
    showSmallPositiveInt: showSmallPositiveInt
};
