// Generated by psc version 0.9.1
"use strict";
var Data_Either = require("../Data.Either");
var Data_Firebase_Foreign = require("../Data.Firebase.Foreign");
var Data_Foreign = require("../Data.Foreign");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_Monoid = require("../Data.Monoid");
var Data_Set = require("../Data.Set");
var Data_VersionNumber = require("../Data.VersionNumber");
var Model_Event_Key = require("../Model.Event.Key");
var Model_UserProfile = require("../Model.UserProfile");
var Prelude = require("../Prelude");
var Test_QuickCheck_Arbitrary = require("../Test.QuickCheck.Arbitrary");
var Test_QuickCheck_Gen = require("../Test.QuickCheck.Gen");
var Data_Show = require("../Data.Show");
var Data_Eq = require("../Data.Eq");
var Control_Bind = require("../Control.Bind");
var Data_Functor = require("../Data.Functor");
var Data_Function = require("../Data.Function");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var Data_Foreign_Index = require("../Data.Foreign.Index");
var Data_Foldable = require("../Data.Foldable");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans");
var Data_Identity = require("../Data.Identity");
var Control_Applicative = require("../Control.Applicative");
var ReviewerKey = function (x) {
    return x;
};
var Reviewer = function (x) {
    return x;
};
var toFirebaseReviewer = new Data_Firebase_Foreign.ToFirebaseForeign(function (v) {
    var foreignReviews = Data_Firebase_Foreign.setToFirebaseMap(v.reviews);
    var fr = {
        modelVersion: v.modelVersion, 
        recordVersion: v.recordVersion, 
        eventId: v.eventId, 
        reviews: foreignReviews, 
        uid: v.uid
    };
    return Data_Foreign.toForeign(fr);
});
var showReviewer = new Data_Show.Show(function (v) {
    return Data_Show.show(Data_ForeignKey.showForeignKey)(v.uid);
});
var setUid = function (value) {
    return function (v) {
        return Data_Function.apply(Reviewer)((function () {
            var $23 = {};
            for (var $24 in v) {
                if (v.hasOwnProperty($24)) {
                    $23[$24] = v[$24];
                };
            };
            $23.uid = Data_ForeignKey.mkForeignKey(value);
            return $23;
        })());
    };
};
var runReviewer = function (v) {
    return v;
};
var reviewCount = function (v) {
    return Data_Set.size(v.reviews);
};
var nullReviewer = {
    modelVersion: Data_VersionNumber.mkVersionNumber, 
    recordVersion: Data_VersionNumber.mkVersionNumber, 
    eventId: Data_ForeignKey.mkForeignKey("test3035"), 
    reviews: Data_Monoid.mempty(Data_Set.monoidSet(Data_ForeignKey.ordForeignKey)), 
    uid: Data_ForeignKey.mkForeignKey("nullUid")
};
var mkReviewerKey$prime = function ($47) {
    return ReviewerKey(Data_ForeignKey.mkForeignKey($47));
};
var mkReviewerKey = Data_ForeignKey.mkForeignKey;
var mkReviewer = function (ek) {
    return function (uk) {
        return Data_Function.apply(Reviewer)((function () {
            var $27 = {};
            for (var $28 in runReviewer(nullReviewer)) {
                if ((runReviewer(nullReviewer)).hasOwnProperty($28)) {
                    $27[$28] = (runReviewer(nullReviewer))[$28];
                };
            };
            $27.eventId = ek;
            $27.uid = Model_UserProfile.asForeignKey(uk);
            return $27;
        })());
    };
};
var isForeignReviewer = new Data_Foreign_Class.IsForeign(function (value) {
    return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Class.readProp(Data_VersionNumber.isForeignVersionNumber)(Data_Foreign_Index.indexString)("modelVersion")(value))(function (v) {
        return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Class.readProp(Data_VersionNumber.isForeignVersionNumber)(Data_Foreign_Index.indexString)("recordVersion")(value))(function (v1) {
            return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Class.readProp(Data_ForeignKey.isForeignForeignKey)(Data_Foreign_Index.indexString)("uid")(value))(function (v2) {
                return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Class.readProp(Data_ForeignKey.isForeignForeignKey)(Data_Foreign_Index.indexString)("eventId")(value))(function (v3) {
                    return Control_Bind.bind(Data_Either.bindEither)(Data_Functor.map(Data_Either.functorEither)(Data_Set.fromFoldable(Data_Foldable.foldableArray)(Data_ForeignKey.ordForeignKey))(Data_Firebase_Foreign.readPropKeysWithDefault(Data_Foreign_Index.indexString)([  ])("reviews")(value)))(function (v4) {
                        return Data_Function.apply(Data_Either.Right.create)(Data_Function.apply(Reviewer)({
                            modelVersion: v, 
                            recordVersion: v1, 
                            uid: v2, 
                            eventId: v3, 
                            reviews: v4
                        }));
                    });
                });
            });
        });
    });
});
var genReviewerForEvent = function (eventId) {
    return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Data_VersionNumber.arbVersionNumber))(function (v) {
        return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Data_VersionNumber.arbVersionNumber))(function (v1) {
            return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Data_ForeignKey.arbForeignKey))(function (v2) {
                return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Data_Functor.map(Control_Monad_State_Trans.functorStateT(Data_Identity.functorIdentity))(Data_Set.fromFoldable(Data_Foldable.foldableArray)(Data_ForeignKey.ordForeignKey))(Test_QuickCheck_Arbitrary.arbitrary(Test_QuickCheck_Arbitrary.arbArray(Data_ForeignKey.arbForeignKey))))(function (v3) {
                    return Data_Function.apply(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity)))(Data_Function.apply(Reviewer)({
                        modelVersion: v, 
                        recordVersion: v1, 
                        uid: v2, 
                        eventId: eventId, 
                        reviews: v3
                    }));
                });
            });
        });
    });
};
var genReviewer = Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Data_ForeignKey.arbForeignKey))(genReviewerForEvent);
var fromForeignKey = function (k) {
    return k;
};
var eventKey = function (v) {
    return v.eventId;
};
var eqReviewer = new Data_Eq.Eq(function (v) {
    return function (v1) {
        return Data_Eq.eq(Data_ForeignKey.eqForeignKey)(v.uid)(v.uid);
    };
});
var asForeignKey = function (v) {
    return v;
};
var arbitraryReviewer = new Test_QuickCheck_Arbitrary.Arbitrary(genReviewer);
var addedReview = function (k) {
    return function (v) {
        return Data_Function.apply(Reviewer)((function () {
            var $45 = {};
            for (var $46 in v) {
                if (v.hasOwnProperty($46)) {
                    $45[$46] = v[$46];
                };
            };
            $45.reviews = Data_Set.insert(Data_ForeignKey.ordForeignKey)(k)(v.reviews);
            return $45;
        })());
    };
};
module.exports = {
    Reviewer: Reviewer, 
    addedReview: addedReview, 
    asForeignKey: asForeignKey, 
    eventKey: eventKey, 
    fromForeignKey: fromForeignKey, 
    genReviewerForEvent: genReviewerForEvent, 
    mkReviewer: mkReviewer, 
    mkReviewerKey: mkReviewerKey, 
    "mkReviewerKey'": mkReviewerKey$prime, 
    nullReviewer: nullReviewer, 
    reviewCount: reviewCount, 
    runReviewer: runReviewer, 
    showReviewer: showReviewer, 
    eqReviewer: eqReviewer, 
    arbitraryReviewer: arbitraryReviewer, 
    isForeignReviewer: isForeignReviewer, 
    toFirebaseReviewer: toFirebaseReviewer
};
