// Generated by psc version 0.9.1
"use strict";
var Components_DB_AdministratorAction = require("../Components.DB.AdministratorAction");
var Components_DB_PanelChairAction = require("../Components.DB.PanelChairAction");
var Components_Elements = require("../Components.Elements");
var Components_ViewAllowed = require("../Components.ViewAllowed");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_Function = require("../Data.Function");
var Data_Maybe = require("../Data.Maybe");
var Data_Tuple = require("../Data.Tuple");
var Model_CallForSessions = require("../Model.CallForSessions");
var Model_OpenCallsForSessions = require("../Model.OpenCallsForSessions");
var Model_GlobalRole = require("../Model.GlobalRole");
var Model_UserProfile = require("../Model.UserProfile");
var Network_RemoteData = require("../Network.RemoteData");
var Prelude = require("../Prelude");
var Pux_Html = require("../Pux.Html");
var Pux_Html_Attributes = require("../Pux.Html.Attributes");
var Route_HtmlElements = require("../Route.HtmlElements");
var State_Types = require("../State.Types");
var UReview_Route_Types = require("../UReview.Route.Types");
var Data_Semigroup = require("../Data.Semigroup");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var Data_Functor = require("../Data.Functor");
var Components_Elements_Basic_Types = require("../Components.Elements.Basic.Types");
var Data_Show = require("../Data.Show");
var showLoader = function (loading) {
    return function (renderLoadedRoles) {
        if (loading instanceof Network_RemoteData.NotAsked) {
            return Components_Elements.ptext("Not asked");
        };
        if (loading instanceof Network_RemoteData.Loading) {
            return Components_Elements.ptext("Loading");
        };
        if (loading instanceof Network_RemoteData.Failure) {
            return Data_Function.apply(Components_Elements.ptext)("Failed " + loading.value0);
        };
        if (loading instanceof Network_RemoteData.Success) {
            return renderLoadedRoles(loading.value0);
        };
        throw new Error("Failed pattern match at View.UserView line 95, column 40 - line 99, column 47: " + [ loading.constructor.name ]);
    };
};
var showListOfOne = function (headingText) {
    return function (explanation) {
        return function (x) {
            return function (renderX) {
                return Pux_Html_Elements.div([ Pux_Html_Attributes.className("column") ])([ Pux_Html_Elements.h2([  ])([ Pux_Html_Elements.text(headingText) ]), Components_Elements.ptext(explanation), Pux_Html_Elements.ul([ Pux_Html_Attributes.className("no-bullet") ])([ renderX(x) ]) ]);
            };
        };
    };
};
var showListOf = function (headingText) {
    return function (explanation) {
        return function (xs) {
            return function (renderX) {
                return Pux_Html_Elements.div([ Pux_Html_Attributes.className("column") ])([ Pux_Html_Elements.h2([  ])([ Pux_Html_Elements.text(headingText) ]), Components_Elements.ptext(explanation), Data_Function.apply(Pux_Html_Elements.ul([ Pux_Html_Attributes.className("no-bullet") ]))(Data_Functor.map(Data_Functor.functorArray)(function (x) {
                    return Data_Function.apply(Pux_Html_Elements.li([  ]))(renderX(x));
                })(xs)) ]);
            };
        };
    };
};
var renderMaybe = function (mb) {
    return function (renderer) {
        if (mb instanceof Data_Maybe.Nothing) {
            return Components_Elements.ptext("Not found or not yet loaded.");
        };
        if (mb instanceof Data_Maybe.Just) {
            return renderer(mb.value0);
        };
        throw new Error("Failed pattern match at View.UserView line 120, column 27 - line 122, column 40: " + [ mb.constructor.name ]);
    };
};
var profileThumbnail = function (v) {
    return Pux_Html_Elements.img([ Pux_Html_Attributes.src(v.profileImageURL), Pux_Html_Attributes.width("26"), Pux_Html_Attributes.height("26"), Pux_Html_Attributes.alt("profile image of user " + Model_UserProfile.getDisplayName(v)) ])([  ]);
};
var thumbNailAndName = function (v) {
    return [ profileThumbnail(v), Pux_Html_Elements.text(v.displayName) ];
};
var profileImage = function (v) {
    return Pux_Html_Elements.img([ Pux_Html_Attributes.src(v.profileImageURL), Pux_Html_Attributes.width("64"), Pux_Html_Attributes.height("64"), Pux_Html_Attributes.alt("profile image of user " + Model_UserProfile.getDisplayName(v)) ])([  ]);
};
var profileView = function (userKey) {
    return function (v) {
        return function (v1) {
            var renderPanelChairFor = function (r) {
                var renderPanelChairRole = function (eventKey) {
                    var shortCode = Data_ForeignKey.fkToString(eventKey);
                    var htmlId = Data_Function.apply(Components_Elements_Basic_Types.mkHtmlId)("deactivate-pc-for-" + shortCode);
                    var action = Data_Function.apply(State_Types.DB.create)(Components_DB_PanelChairAction.deactivatePanelChairAction(eventKey)(userKey));
                    return [ Route_HtmlElements.routeLink(new UReview_Route_Types.EventRoute(eventKey))(shortCode), Components_Elements.createSmallButton(action)(htmlId)("Deactivate Panelchair Role") ];
                };
                return showListOf("Panel Chair for")("Conferences the user is panel chair for")(Model_GlobalRole.getPanelChairFor(r))(renderPanelChairRole);
            };
            var renderConferencesToChair = function (r) {
                var renderMakePanelChair = function (openCall) {
                    var eventName = Model_CallForSessions.eventNameStr(openCall);
                    var eventKey = Model_CallForSessions.cfsKey(openCall);
                    var shortCode = Data_ForeignKey.fkToString(eventKey);
                    var htmlId = Data_Function.apply(Components_Elements_Basic_Types.mkHtmlId)("activate-pc-for-" + shortCode);
                    var action = Data_Function.apply(State_Types.DB.create)(Components_DB_PanelChairAction.activatePanelChairAction(eventKey)(userKey));
                    return [ Route_HtmlElements.routeLink(new UReview_Route_Types.EventRoute(eventKey))(shortCode), Components_Elements.createSmallButton(action)(htmlId)("Activate Panelchair Role") ];
                };
                return showListOf("Not Panel Chair for")("Conferences the user could become panel chair for")(Model_OpenCallsForSessions.toArray(v1.openCalls))(renderMakePanelChair);
            };
            var renderAdministratorRole = function (r) {
                var renderMakeAdministrator = function (gr) {
                    var $17 = Model_GlobalRole.isAdmin(gr);
                    if ($17) {
                        return Components_Elements.createSmallButton(Data_Function.apply(State_Types.DB.create)(Components_DB_AdministratorAction.deactivateAdministratorAction(userKey)))(Components_Elements_Basic_Types.mkHtmlId("de-admin"))("Deactivate adminstrator");
                    };
                    if (!$17) {
                        return Components_Elements.createSmallButton(Data_Function.apply(State_Types.DB.create)(Components_DB_AdministratorAction.activateAdministratorAction(userKey)))(Components_Elements_Basic_Types.mkHtmlId("mkadmin"))("Promote to administrator");
                    };
                    throw new Error("Failed pattern match at View.UserView line 60, column 38 - line 64, column 5: " + [ $17.constructor.name ]);
                };
                return showListOfOne("Administrator status")("An administrator is almost all powerful - handle with care.")(r)(renderMakeAdministrator);
            };
            var profile = Pux_Html_Elements.div([  ])([ profileImage(v), Data_Function.apply(Components_Elements.ptext)(Model_UserProfile.getDisplayName(v)) ]);
            var panelChairing = Data_Functor.map(Network_RemoteData.functorRemoteData)(Model_GlobalRole.getPanelChairFor)(v.roles);
            var debugInfo = Data_Function.apply(Components_Elements.ptext)(Data_Show.show(Model_UserProfile.showUserProfile)(v));
            var actions = [ profile, showLoader(v.roles)(renderAdministratorRole), showLoader(v.roles)(renderPanelChairFor), showLoader(v.roles)(renderConferencesToChair) ];
            return Data_Function.apply(Pux_Html_Elements.section([ Pux_Html_Attributes.className("events") ]))(Components_ViewAllowed.viewAllowed([ Data_Function.apply(Data_Tuple.Tuple.create(Components_ViewAllowed.administrator))(Pux_Html_Elements.div([ Pux_Html_Attributes.className("row medium-unstack") ])(actions)) ])(v1));
        };
    };
};
var userView = function (userId) {
    return function (v) {
        var renderer = function (u) {
            return profileView(userId)(u)(v);
        };
        var foundUser = Model_UserProfile.findUser(userId)(v.allUsers);
        var showWhenFound = renderMaybe(foundUser)(renderer);
        return showWhenFound;
    };
};
module.exports = {
    profileImage: profileImage, 
    profileThumbnail: profileThumbnail, 
    thumbNailAndName: thumbNailAndName, 
    userView: userView
};
