// Generated by psc version 0.9.1
"use strict";
var $foreign = require("./foreign");
var Prelude = require("../Prelude");
var Data_Show = require("../Data.Show");
var Data_Eq = require("../Data.Eq");
var showFirebaseErr = new Data_Show.Show(function (err) {
    return $foreign.firebaseErrToString(err);
});
var eqFirebaseErr = new Data_Eq.Eq(function (e1) {
    return function (e2) {
        return $foreign.firebaseErrToString(e1) === $foreign.firebaseErrToString(e2);
    };
});
module.exports = {
    showFirebaseErr: showFirebaseErr, 
    eqFirebaseErr: eqFirebaseErr
};
