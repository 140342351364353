// Generated by psc version 0.9.1
"use strict";
var WeReview_Data_Schema = require("../WeReview.Data.Schema");
var Components_DB_Action = require("../Components.DB.Action");
var Data_Activation = require("../Data.Activation");
var Data_Firebase_Foreign = require("../Data.Firebase.Foreign");
var Data_List = require("../Data.List");
var Data_Tuple = require("../Data.Tuple");
var Model_Event_Key = require("../Model.Event.Key");
var Model_UserProfile = require("../Model.UserProfile");
var Prelude = require("../Prelude");
var Data_Function = require("../Data.Function");
var Data_Foldable = require("../Data.Foldable");
var Data_Functor = require("../Data.Functor");
var setPanelChairActivation = function (eventKey) {
    return function (userKey) {
        return function (activation) {
            var paths = [ Data_Function.apply(WeReview_Data_Schema.Events.create(eventKey))(new WeReview_Data_Schema.PanelChair(userKey)), Data_Function.apply(WeReview_Data_Schema.Roles.create(userKey))(new WeReview_Data_Schema.PanelChairFor(eventKey)) ];
            var active = Data_Firebase_Foreign.toFire(Data_Activation.toFireActivation)(activation);
            return Data_Function.apply(Components_DB_Action.SetValues.create)(Data_Function.apply(Data_List.fromFoldable(Data_Foldable.foldableArray))(Data_Functor.map(Data_Functor.functorArray)(function (p) {
                return new Data_Tuple.Tuple(p, active);
            })(paths)));
        };
    };
};
var deactivatePanelChairAction = function (eventKey) {
    return function (userKey) {
        return setPanelChairActivation(eventKey)(userKey)(Data_Activation.InActive.value);
    };
};
var activatePanelChairAction = function (eventKey) {
    return function (userKey) {
        return setPanelChairActivation(eventKey)(userKey)(Data_Activation.Active.value);
    };
};
module.exports = {
    activatePanelChairAction: activatePanelChairAction, 
    deactivatePanelChairAction: deactivatePanelChairAction
};
