// Generated by psc version 0.9.1
"use strict";
var Prelude = require("../Prelude");
var Data_ForeignKey = require("../Data.ForeignKey");
var Pux_Html = require("../Pux.Html");
var Pux_Html_Events = require("../Pux.Html.Events");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var eventValue = function (ev) {
    return ev.target.value;
};
var selectKey = function (action) {
    return Pux_Html_Events.onChange(function ($0) {
        return action(Data_ForeignKey.mkForeignKey(eventValue($0)));
    });
};
module.exports = {
    eventValue: eventValue, 
    selectKey: selectKey
};
