// Generated by psc version 0.9.1
"use strict";
var Pux_Html_Events = require("../Pux.Html.Events");
var View_ExpenseRequest = require("../View.ExpenseRequest");
var Submit = (function () {
    function Submit() {

    };
    Submit.value = new Submit();
    return Submit;
})();
var Cancel = (function () {
    function Cancel() {

    };
    Cancel.value = new Cancel();
    return Cancel;
})();
var EditTitle = (function () {
    function EditTitle(value0) {
        this.value0 = value0;
    };
    EditTitle.create = function (value0) {
        return new EditTitle(value0);
    };
    return EditTitle;
})();
var AbstractChanged = (function () {
    function AbstractChanged(value0) {
        this.value0 = value0;
    };
    AbstractChanged.create = function (value0) {
        return new AbstractChanged(value0);
    };
    return AbstractChanged;
})();
var DescriptionChanged = (function () {
    function DescriptionChanged(value0) {
        this.value0 = value0;
    };
    DescriptionChanged.create = function (value0) {
        return new DescriptionChanged(value0);
    };
    return DescriptionChanged;
})();
var TypeChanged = (function () {
    function TypeChanged(value0) {
        this.value0 = value0;
    };
    TypeChanged.create = function (value0) {
        return new TypeChanged(value0);
    };
    return TypeChanged;
})();
var DurationChanged = (function () {
    function DurationChanged(value0) {
        this.value0 = value0;
    };
    DurationChanged.create = function (value0) {
        return new DurationChanged(value0);
    };
    return DurationChanged;
})();
var NameChanged = (function () {
    function NameChanged(value0) {
        this.value0 = value0;
    };
    NameChanged.create = function (value0) {
        return new NameChanged(value0);
    };
    return NameChanged;
})();
var HowToAddressChanged = (function () {
    function HowToAddressChanged(value0) {
        this.value0 = value0;
    };
    HowToAddressChanged.create = function (value0) {
        return new HowToAddressChanged(value0);
    };
    return HowToAddressChanged;
})();
var OrgChanged = (function () {
    function OrgChanged(value0) {
        this.value0 = value0;
    };
    OrgChanged.create = function (value0) {
        return new OrgChanged(value0);
    };
    return OrgChanged;
})();
var EmailChanged = (function () {
    function EmailChanged(value0) {
        this.value0 = value0;
    };
    EmailChanged.create = function (value0) {
        return new EmailChanged(value0);
    };
    return EmailChanged;
})();
var TwitterChanged = (function () {
    function TwitterChanged(value0) {
        this.value0 = value0;
    };
    TwitterChanged.create = function (value0) {
        return new TwitterChanged(value0);
    };
    return TwitterChanged;
})();
var BioChanged = (function () {
    function BioChanged(value0) {
        this.value0 = value0;
    };
    BioChanged.create = function (value0) {
        return new BioChanged(value0);
    };
    return BioChanged;
})();
var OtherSpeakersChanged = (function () {
    function OtherSpeakersChanged(value0) {
        this.value0 = value0;
    };
    OtherSpeakersChanged.create = function (value0) {
        return new OtherSpeakersChanged(value0);
    };
    return OtherSpeakersChanged;
})();
var Speaker2NameChanged = (function () {
    function Speaker2NameChanged(value0) {
        this.value0 = value0;
    };
    Speaker2NameChanged.create = function (value0) {
        return new Speaker2NameChanged(value0);
    };
    return Speaker2NameChanged;
})();
var Speaker2HowToAddressChanged = (function () {
    function Speaker2HowToAddressChanged(value0) {
        this.value0 = value0;
    };
    Speaker2HowToAddressChanged.create = function (value0) {
        return new Speaker2HowToAddressChanged(value0);
    };
    return Speaker2HowToAddressChanged;
})();
var Speaker2OrgChanged = (function () {
    function Speaker2OrgChanged(value0) {
        this.value0 = value0;
    };
    Speaker2OrgChanged.create = function (value0) {
        return new Speaker2OrgChanged(value0);
    };
    return Speaker2OrgChanged;
})();
var Speaker2TwitterChanged = (function () {
    function Speaker2TwitterChanged(value0) {
        this.value0 = value0;
    };
    Speaker2TwitterChanged.create = function (value0) {
        return new Speaker2TwitterChanged(value0);
    };
    return Speaker2TwitterChanged;
})();
var Speaker2BioChanged = (function () {
    function Speaker2BioChanged(value0) {
        this.value0 = value0;
    };
    Speaker2BioChanged.create = function (value0) {
        return new Speaker2BioChanged(value0);
    };
    return Speaker2BioChanged;
})();
var Speaker3NameChanged = (function () {
    function Speaker3NameChanged(value0) {
        this.value0 = value0;
    };
    Speaker3NameChanged.create = function (value0) {
        return new Speaker3NameChanged(value0);
    };
    return Speaker3NameChanged;
})();
var Speaker3HowToAddressChanged = (function () {
    function Speaker3HowToAddressChanged(value0) {
        this.value0 = value0;
    };
    Speaker3HowToAddressChanged.create = function (value0) {
        return new Speaker3HowToAddressChanged(value0);
    };
    return Speaker3HowToAddressChanged;
})();
var Speaker3OrgChanged = (function () {
    function Speaker3OrgChanged(value0) {
        this.value0 = value0;
    };
    Speaker3OrgChanged.create = function (value0) {
        return new Speaker3OrgChanged(value0);
    };
    return Speaker3OrgChanged;
})();
var Speaker3TwitterChanged = (function () {
    function Speaker3TwitterChanged(value0) {
        this.value0 = value0;
    };
    Speaker3TwitterChanged.create = function (value0) {
        return new Speaker3TwitterChanged(value0);
    };
    return Speaker3TwitterChanged;
})();
var Speaker3BioChanged = (function () {
    function Speaker3BioChanged(value0) {
        this.value0 = value0;
    };
    Speaker3BioChanged.create = function (value0) {
        return new Speaker3BioChanged(value0);
    };
    return Speaker3BioChanged;
})();
var TechRequirementsChanged = (function () {
    function TechRequirementsChanged(value0) {
        this.value0 = value0;
    };
    TechRequirementsChanged.create = function (value0) {
        return new TechRequirementsChanged(value0);
    };
    return TechRequirementsChanged;
})();
var AcceptTermsAndConditions = (function () {
    function AcceptTermsAndConditions(value0) {
        this.value0 = value0;
    };
    AcceptTermsAndConditions.create = function (value0) {
        return new AcceptTermsAndConditions(value0);
    };
    return AcceptTermsAndConditions;
})();
var AcceptCodeOfConduct = (function () {
    function AcceptCodeOfConduct(value0) {
        this.value0 = value0;
    };
    AcceptCodeOfConduct.create = function (value0) {
        return new AcceptCodeOfConduct(value0);
    };
    return AcceptCodeOfConduct;
})();
var ExpensesChanged = (function () {
    function ExpensesChanged(value0) {
        this.value0 = value0;
    };
    ExpensesChanged.create = function (value0) {
        return new ExpensesChanged(value0);
    };
    return ExpensesChanged;
})();
module.exports = {
    Submit: Submit, 
    Cancel: Cancel, 
    EditTitle: EditTitle, 
    AbstractChanged: AbstractChanged, 
    DescriptionChanged: DescriptionChanged, 
    TypeChanged: TypeChanged, 
    DurationChanged: DurationChanged, 
    NameChanged: NameChanged, 
    HowToAddressChanged: HowToAddressChanged, 
    OrgChanged: OrgChanged, 
    EmailChanged: EmailChanged, 
    TwitterChanged: TwitterChanged, 
    BioChanged: BioChanged, 
    OtherSpeakersChanged: OtherSpeakersChanged, 
    Speaker2NameChanged: Speaker2NameChanged, 
    Speaker2HowToAddressChanged: Speaker2HowToAddressChanged, 
    Speaker2OrgChanged: Speaker2OrgChanged, 
    Speaker2TwitterChanged: Speaker2TwitterChanged, 
    Speaker2BioChanged: Speaker2BioChanged, 
    Speaker3NameChanged: Speaker3NameChanged, 
    Speaker3HowToAddressChanged: Speaker3HowToAddressChanged, 
    Speaker3OrgChanged: Speaker3OrgChanged, 
    Speaker3TwitterChanged: Speaker3TwitterChanged, 
    Speaker3BioChanged: Speaker3BioChanged, 
    TechRequirementsChanged: TechRequirementsChanged, 
    AcceptTermsAndConditions: AcceptTermsAndConditions, 
    AcceptCodeOfConduct: AcceptCodeOfConduct, 
    ExpensesChanged: ExpensesChanged
};
