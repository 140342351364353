// Generated by psc version 0.9.1
"use strict";
var Components_Elements = require("../Components.Elements");
var Control_Alt = require("../Control.Alt");
var CssClasses = require("../CssClasses");
var Data_Array = require("../Data.Array");
var Data_Firebase_Reviewer = require("../Data.Firebase.Reviewer");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_Map = require("../Data.Map");
var Data_Maybe = require("../Data.Maybe");
var Data_Tuple = require("../Data.Tuple");
var Model_Event = require("../Model.Event");
var Model_Reviewer = require("../Model.Reviewer");
var Model_UserProfile = require("../Model.UserProfile");
var Prelude = require("../Prelude");
var Pux_Html = require("../Pux.Html");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var Route_HtmlElements = require("../Route.HtmlElements");
var State_Types = require("../State.Types");
var UReview_Route_Types = require("../UReview.Route.Types");
var Data_Semigroup = require("../Data.Semigroup");
var Components_Elements_Basic_Types = require("../Components.Elements.Basic.Types");
var Control_Bind = require("../Control.Bind");
var Data_Function = require("../Data.Function");
var renderReviewer = function (buttonIdPart) {
    return function (buttonLabel) {
        return function (activate) {
            return function (reviewerKey) {
                return function (v) {
                    return function (v1) {
                        var routeToReviewer = new UReview_Route_Types.Reviewer(v.eventId, reviewerKey);
                        var roleSetter = {
                            uidKey: v.uid, 
                            eventKey: v.eventId, 
                            reviewerKey: reviewerKey
                        };
                        var keyString = Data_ForeignKey.fkToString(reviewerKey);
                        var changeActivation = new State_Types.PerformAff(Data_Firebase_Reviewer.setReviewerRole(activate)(roleSetter));
                        var cell = Pux_Html_Elements.td([  ]);
                        var buttonId = Data_Semigroup.append(Components_Elements_Basic_Types.semigroupHTMLId)(buttonIdPart)(Components_Elements_Basic_Types.mkHtmlId(keyString));
                        return [ cell([ Components_Elements.createSmallButton(changeActivation)(buttonId)(buttonLabel) ]), cell([ Route_HtmlElements.routeToTop(routeToReviewer)(v1.displayName) ]) ];
                    };
                };
            };
        };
    };
};
var showActiveReviewer = function (v) {
    return function (user) {
        return renderReviewer(Components_Elements_Basic_Types.mkHtmlId("deactivate-reviewer-"))("Deactivate")(false)(v.value0)(v.value1)(user);
    };
};
var showActivatedReviewer = function (allUsers) {
    return function (reviewers) {
        return function (key) {
            return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Map.lookup(Data_ForeignKey.ordForeignKey)(key)(reviewers))(function (v) {
                return Control_Bind.bind(Data_Maybe.bindMaybe)(Model_UserProfile.findUserByUid(v.uid)(allUsers))(function (v1) {
                    return Data_Function.apply(Data_Maybe.Just.create)(Data_Function.apply(Pux_Html_Elements.tr([  ]))(showActiveReviewer(new Data_Tuple.Tuple(key, v))(v1)));
                });
            });
        };
    };
};
var showInactiveReviewer = function (v) {
    return function (user) {
        return renderReviewer(Components_Elements_Basic_Types.mkHtmlId("activate-reviewer-"))("Activate")(true)(v.value0)(v.value1)(user);
    };
};
var showDeactivatedReviewer = function (allUsers) {
    return function (reviewers) {
        return function (key) {
            return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Map.lookup(Data_ForeignKey.ordForeignKey)(key)(reviewers))(function (v) {
                return Control_Bind.bind(Data_Maybe.bindMaybe)(Model_UserProfile.findUserByUid(v.uid)(allUsers))(function (v1) {
                    return Data_Function.apply(Data_Maybe.Just.create)(Data_Function.apply(Pux_Html_Elements.tr([  ]))(showInactiveReviewer(new Data_Tuple.Tuple(key, v))(v1)));
                });
            });
        };
    };
};
var listDeactivatedReviewers = function (allUsers) {
    return function (reviewerKeys) {
        return function (reviewers) {
            return Pux_Html_Elements.div([  ])([ Pux_Html_Elements.h4([  ])([ Pux_Html_Elements.text("Deactivated reviewers") ]), Pux_Html_Elements.table([  ])([ Data_Function.apply(Pux_Html_Elements.tbody([  ]))(Data_Array.mapMaybe(function (rId) {
                return showDeactivatedReviewer(allUsers)(reviewers)(rId);
            })(reviewerKeys)) ]) ]);
        };
    };
};
var listActivatedReviewers = function (allUsers) {
    return function (reviewerKeys) {
        return function (reviewers) {
            return Pux_Html_Elements.div([  ])([ Pux_Html_Elements.h4([  ])([ Pux_Html_Elements.text("Activated reviewers") ]), Pux_Html_Elements.table([  ])([ Data_Function.apply(Pux_Html_Elements.tbody([  ]))(Data_Array.mapMaybe(function (rId) {
                return showActivatedReviewer(allUsers)(reviewers)(rId);
            })(reviewerKeys)) ]) ]);
        };
    };
};
module.exports = {
    listActivatedReviewers: listActivatedReviewers, 
    listDeactivatedReviewers: listDeactivatedReviewers
};
