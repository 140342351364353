// Generated by psc version 0.9.1
"use strict";
var Web_Firebase = require("../Web.Firebase");
var Ports_Firebase_Aff = require("../Ports.Firebase.Aff");
var Components_DB_Action = require("../Components.DB.Action");
var Control_Monad_Aff = require("../Control.Monad.Aff");
var Control_Monad_Eff_Class = require("../Control.Monad.Eff.Class");
var Data_Firebase_Foreign = require("../Data.Firebase.Foreign");
var Data_Firebase_References = require("../Data.Firebase.References");
var Data_Firebase_WriteParentChild = require("../Data.Firebase.WriteParentChild");
var Data_ForeignKey = require("../Data.ForeignKey");
var Model_Review = require("../Model.Review");
var Prelude = require("../Prelude");
var Signal_Channel = require("../Signal.Channel");
var State_Types = require("../State.Types");
var UReview_UserNotifications = require("../UReview.UserNotifications");
var WeReview_Data_Schema = require("../WeReview.Data.Schema");
var Ports_Firebase_Types = require("../Ports.Firebase.Types");
var Control_Bind = require("../Control.Bind");
var Web_Firebase_Aff = require("../Web.Firebase.Aff");
var Data_Function = require("../Data.Function");
var Data_Semigroup = require("../Data.Semigroup");
var deleteReview = function (reviewKey) {
    return function (v) {
        return function (channel) {
            return function (v1) {
                return function (rootRef) {
                    return Control_Bind.bind(Control_Monad_Aff.bindAff)(Data_Firebase_References.refToReviewKeysInReviewer(v.reviewerId)(rootRef))(function (v2) {
                        return Control_Bind.bind(Control_Monad_Aff.bindAff)(Web_Firebase_Aff.child(Data_ForeignKey.fkToString(reviewKey))(v2))(function (v3) {
                            return Control_Bind.bind(Control_Monad_Aff.bindAff)(Data_Function.apply(Control_Monad_Eff_Class.liftEff(Control_Monad_Aff.monadEffAff))(Web_Firebase.toString(v3)))(function (v4) {
                                return Control_Bind.bind(Control_Monad_Aff.bindAff)(Data_Function.apply(Control_Monad_Eff_Class.liftEff(Control_Monad_Aff.monadEffAff))(UReview_UserNotifications.sendErrorMessage(channel)("deleting review at" + v4)))(function () {
                                    return Web_Firebase_Aff.remove(v3);
                                });
                            });
                        });
                    });
                };
            };
        };
    };
};
var createReview2 = function (v) {
    return new Components_DB_Action.PushChild(WeReview_Data_Schema.AllReviews.value, function (childKey) {
        return new WeReview_Data_Schema.ReviewersForReviews(v.reviewerId, new WeReview_Data_Schema.ReviewKeys(childKey));
    }, Data_Firebase_Foreign.toFire(Model_Review.toFirebaseForeignReview)(v));
};
var createReview = function (v) {
    return function (rootRef) {
        return Control_Bind.bind(Control_Monad_Aff.bindAff)(Data_Firebase_References.refToReviews(rootRef))(function (v1) {
            return Control_Bind.bind(Control_Monad_Aff.bindAff)(Data_Firebase_References.refToReviewKeysInReviewer(v.reviewerId)(rootRef))(function (v2) {
                return Data_Firebase_WriteParentChild.pushChild(Model_Review.toFirebaseForeignReview)(v)(v1)(v2);
            });
        });
    };
};
module.exports = {
    createReview: createReview, 
    createReview2: createReview2, 
    deleteReview: deleteReview
};
