// Generated by psc version 0.9.1
"use strict";
var Data_PathTo = require("../Data.PathTo");
var Prelude = require("../Prelude");
var UReview_Route_Types = require("../UReview.Route.Types");
var Data_Semigroup = require("../Data.Semigroup");
var Data_ForeignKey = require("../Data.ForeignKey");
var Model_UserProfile = require("../Model.UserProfile");
var AsUrlString = function (asUrlString) {
    this.asUrlString = asUrlString;
};
var forwardSlash = function (dictPathTo) {
    return function (path) {
        return function (key) {
            return path + ("/" + Data_PathTo.pathTo(dictPathTo)(key));
        };
    };
};
var routeString = function (r) {
    if (r instanceof UReview_Route_Types.Reviewer) {
        return forwardSlash(Data_ForeignKey.pathToForeignKey)("event")(r.value0) + forwardSlash(Data_ForeignKey.pathToForeignKey)("/reviewer")(r.value1);
    };
    if (r instanceof UReview_Route_Types.PanelChairsRoute) {
        return forwardSlash(Data_ForeignKey.pathToForeignKey)("event/panelchairs")(r.value0);
    };
    if (r instanceof UReview_Route_Types.EventRoute) {
        return forwardSlash(Data_ForeignKey.pathToForeignKey)("event")(r.value0);
    };
    if (r instanceof UReview_Route_Types.EditCallForSessions) {
        return forwardSlash(Data_ForeignKey.pathToForeignKey)("editCfS")(r.value0);
    };
    if (r instanceof UReview_Route_Types.HiddenSessions) {
        return forwardSlash(Data_ForeignKey.pathToForeignKey)("event/hiddensessions")(r.value0);
    };
    if (r instanceof UReview_Route_Types.VisibleSessions) {
        return forwardSlash(Data_ForeignKey.pathToForeignKey)("event/visiblesessions")(r.value0);
    };
    if (r instanceof UReview_Route_Types.ManageReviewers) {
        return forwardSlash(Data_ForeignKey.pathToForeignKey)("event/managereviewers")(r.value0);
    };
    if (r instanceof UReview_Route_Types.ProposeSessionRoute) {
        return forwardSlash(Data_ForeignKey.pathToForeignKey)("v1/propose")(r.value0);
    };
    if (r instanceof UReview_Route_Types.ReviewRoute) {
        return forwardSlash(Data_ForeignKey.pathToForeignKey)("review")(r.value0);
    };
    if (r instanceof UReview_Route_Types.RandomReview) {
        return forwardSlash(Data_ForeignKey.pathToForeignKey)("event/randomreview")(r.value0);
    };
    if (r instanceof UReview_Route_Types.FullSessionDescriptionRoute) {
        return forwardSlash(Data_ForeignKey.pathToForeignKey)("sessiondescription")(r.value0);
    };
    if (r instanceof UReview_Route_Types.ReviewSummaryRoute) {
        return forwardSlash(Data_ForeignKey.pathToForeignKey)("event/reviewsummary")(r.value0);
    };
    if (r instanceof UReview_Route_Types.DownloadFullCSVRoute) {
        return forwardSlash(Data_ForeignKey.pathToForeignKey)("event/downloadfullcsv")(r.value0);
    };
    if (r instanceof UReview_Route_Types.OrganiseRoute) {
        return "organise";
    };
    if (r instanceof UReview_Route_Types.Events) {
        return "events";
    };
    if (r instanceof UReview_Route_Types.UsersRoute) {
        return "users";
    };
    if (r instanceof UReview_Route_Types.UserRoute) {
        return forwardSlash(Model_UserProfile.pathToUserKey)("user")(r.value0);
    };
    if (r instanceof UReview_Route_Types.SignupRoute) {
        return "signup";
    };
    if (r instanceof UReview_Route_Types.SignOutRoute) {
        return "signout";
    };
    if (r instanceof UReview_Route_Types.TermsAndConditions) {
        return "terms-and-conditions";
    };
    if (r instanceof UReview_Route_Types.CodeOfConduct) {
        return "code-of-conduct";
    };
    if (r instanceof UReview_Route_Types.NotFound) {
        return "notfound";
    };
    if (r instanceof UReview_Route_Types.CurrentMockup) {
        return "mockup/current";
    };
    if (r instanceof UReview_Route_Types.Home) {
        return "";
    };
    throw new Error("Failed pattern match at UReview.Route.AsUrl line 15, column 17 - line 41, column 1: " + [ r.constructor.name ]);
};
var routeAsUrlstring = new AsUrlString(function (r) {
    return "/" + routeString(r);
});
var asUrlString = function (dict) {
    return dict.asUrlString;
};
module.exports = {
    AsUrlString: AsUrlString, 
    asUrlString: asUrlString, 
    routeString: routeString, 
    routeAsUrlstring: routeAsUrlstring
};
