// Generated by psc version 0.9.1
"use strict";
var Data_Maybe = require("../Data.Maybe");
var Model_Event = require("../Model.Event");
var Model_Event_Key = require("../Model.Event.Key");
var Model_Reviewer = require("../Model.Reviewer");
var Prelude = require("../Prelude");
var State_Event = require("../State.Event");
var State_Types = require("../State.Types");
var Control_Bind = require("../Control.Bind");
var findReviewer = function (eventKey) {
    return function (key) {
        return function (state) {
            return Control_Bind.bind(Data_Maybe.bindMaybe)(State_Event.findEvent(eventKey)(state))(function (v) {
                return Model_Event.findReviewer(key)(v);
            });
        };
    };
};
module.exports = {
    findReviewer: findReviewer
};
