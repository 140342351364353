// module Components.GeneratedFileDownload

/* This one does not use the Blob api
*/
exports._downloadGeneratedFileOld  = function(spec) {
 return function() {
    console.log(spec);
    var hiddenElement = document.createElement('a');
    // Firefox requires appending the element to the body
    document.body.appendChild(hiddenElement); // https://github.com/notablemind/downloadbutton/blob/master/save-as.js
    hiddenElement.href = 'data:' + spec.mimeType + ';charset=utf-8,' + encodeURI(spec.value);
    hiddenElement.target = '_blank';
    hiddenElement.download = spec.fileName;
    hiddenElement.click();
    // http://stackoverflow.com/questions/3387427/remove-element-by-id
    document.body.removeChild(hiddenElement);
 };
};

// https://developers.google.com/web/updates/2012/06/Don-t-Build-Blobs-Construct-Them?hl=en
exports._downloadGeneratedFile  = function(spec) {
  return function() {
    console.log(spec);
    const downloadData = new Blob([spec.value], {type: (spec.mimeType + ';charset=utf-8;')});
    var hiddenLink = document.createElement('a');

    hiddenLink.href = window.URL.createObjectURL(downloadData);
    hiddenLink.setAttribute('download', spec.fileName);
    // Firefox requires appending the element to the body for it to be clickable
    document.body.appendChild(hiddenLink);
    hiddenLink.click();
    document.body.removeChild(hiddenLink);
  };
};
