// Generated by psc version 0.9.1
"use strict";
var Prelude = require("../Prelude");
var Control_Monad_Aff = require("../Control.Monad.Aff");
var Control_Monad_Eff = require("../Control.Monad.Eff");
var Control_Monad_Eff_Class = require("../Control.Monad.Eff.Class");
var Web_Firebase = require("../Web.Firebase");
var Web_Firebase_Types = require("../Web.Firebase.Types");
var Data_Function = require("../Data.Function");
var unsafeRef = function (s) {
    return Data_Function.apply(Web_Firebase.newFirebase)(s);
};
var refFor = function (s) {
    return Control_Monad_Eff_Class.liftEff(Control_Monad_Aff.monadEffAff)(unsafeRef(s));
};
module.exports = {
    refFor: refFor, 
    unsafeRef: unsafeRef
};
