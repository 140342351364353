// Generated by psc version 0.9.1
"use strict";
var Prelude = require("../Prelude");
var Data_Either = require("../Data.Either");
var Data_Maybe = require("../Data.Maybe");
var Control_Monad_Aff = require("../Control.Monad.Aff");
var Control_Monad_Eff_Exception = require("../Control.Monad.Eff.Exception");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var Data_Show = require("../Data.Show");
var Control_Applicative = require("../Control.Applicative");
var $$throw = function ($8) {
    return Control_Monad_Error_Class.throwError(Control_Monad_Aff.monadErrorAff)(Control_Monad_Eff_Exception.error($8));
};
var fromRight = function (dictShow) {
    return function (v) {
        if (v instanceof Data_Either.Left) {
            return $$throw(Data_Show.show(dictShow)(v.value0));
        };
        if (v instanceof Data_Either.Right) {
            return Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(v.value0);
        };
        throw new Error("Failed pattern match at Control.Monad.Aff.Exception line 29, column 1 - line 29, column 38: " + [ v.constructor.name ]);
    };
};
var fromJust = function (v) {
    if (v instanceof Data_Maybe.Nothing) {
        return $$throw("Expected a value but there was none");
    };
    if (v instanceof Data_Maybe.Just) {
        return Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(v.value0);
    };
    throw new Error("Failed pattern match at Control.Monad.Aff.Exception line 22, column 1 - line 23, column 1: " + [ v.constructor.name ]);
};
module.exports = {
    fromJust: fromJust, 
    fromRight: fromRight, 
    "throw": $$throw
};
