// Generated by psc version 0.9.1
"use strict";
var Control_Applicative = require("../Control.Applicative");
var Data_Array = require("../Data.Array");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_ForeignLocation = require("../Data.ForeignLocation");
var Data_List = require("../Data.List");
var Data_Loaded = require("../Data.Loaded");
var Data_Map = require("../Data.Map");
var Data_Maybe = require("../Data.Maybe");
var Data_Monoid = require("../Data.Monoid");
var Data_StrMap = require("../Data.StrMap");
var Data_String = require("../Data.String");
var Data_Tuple = require("../Data.Tuple");
var Model_Event = require("../Model.Event");
var Model_Event_Key = require("../Model.Event.Key");
var Model_Reviewer = require("../Model.Reviewer");
var Model_SessionDescription = require("../Model.SessionDescription");
var Model_UserProfile = require("../Model.UserProfile");
var Network_RemoteData = require("../Network.RemoteData");
var PanelChair = require("../PanelChair");
var Prelude = require("../Prelude");
var State_ApplyFound = require("../State.ApplyFound");
var State_SessionDescription = require("../State.SessionDescription");
var State_Types = require("../State.Types");
var State_UserProfiles = require("../State.UserProfiles");
var Data_Function = require("../Data.Function");
var Control_Bind = require("../Control.Bind");
var Control_Apply = require("../Control.Apply");
var Data_Functor = require("../Data.Functor");
var Data_Unfoldable = require("../Data.Unfoldable");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra");
var Data_Eq = require("../Data.Eq");
var Data_Either = require("../Data.Either");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var ReviewingUser = function (x) {
    return x;
};
var Reviewers = function (x) {
    return x;
};
var PanelChairSelection = function (x) {
    return x;
};
var setReviewerEnabled = function (eventKey) {
    return function (reviewerKey) {
        return function (value) {
            return function (state) {
                var $11 = {};
                for (var $12 in state) {
                    if (state.hasOwnProperty($12)) {
                        $11[$12] = state[$12];
                    };
                };
                $11.events = Data_StrMap.update(function (event) {
                    return Data_Function.apply(Data_Maybe.Just.create)(Model_Event.setReviewerActivation(reviewerKey)(value)(event));
                })(Data_ForeignKey.fkToString(eventKey))(state.events);
                return $11;
            };
        };
    };
};
var reviewingUser = function (s) {
    return function (v) {
        return Control_Bind.bind(Data_Maybe.bindMaybe)(Model_UserProfile.findUserByUid(v.value1.uid)(s.allUsers))(function (v1) {
            return Data_Function.apply(Control_Applicative.pure(Data_Maybe.applicativeMaybe))({
                user: v1, 
                reviewerKey: v.value0, 
                reviewer: v.value1
            });
        });
    };
};
var resetNewEvent = function (state) {
    var $18 = {};
    for (var $19 in state) {
        if (state.hasOwnProperty($19)) {
            $18[$19] = state[$19];
        };
    };
    $18.newEvent = Model_Event.nullEvent;
    return $18;
};
var panelChairs = function (event) {
    return function (v) {
        var toLoaded = function (loader) {
            return Control_Apply.apply(Data_Maybe.applyMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(PanelChair.mkPanelChair)(Model_UserProfile.findUser(PanelChair.getUserKey(loader))(v.allUsers)))(Control_Applicative.pure(Data_Maybe.applicativeMaybe)(loader));
        };
        return Data_Array.mapMaybe(toLoaded)(Model_Event.getPanelChairs(event));
    };
};
var panelChairUsers = Data_Functor.map(Data_Functor.functorArray)(PanelChair.getUser);
var panelChairSelection = function (event) {
    return function (v) {
        var pcs = panelChairs(event)(v);
        return {
            potential: Data_Array.difference(Model_UserProfile.eqUserProfile)(v.allUsers)(panelChairUsers(pcs)), 
            active: Data_Array.partition(PanelChair.isActive)(pcs), 
            eventKey: Model_Event.getShortCode(event)
        };
    };
};
var keysOfVisibleSessionDescriptions = function (v) {
    return function (state) {
        var l = State_SessionDescription.loadedSessionDescriptions(v.sessionDescriptionKeys)(state);
        return Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.fst)(Data_Array.filter(function (v1) {
            return (Model_SessionDescription.runSessionDescription(v1.value1)).isVisible;
        })((Data_Loaded.runLoaded(l)).presentOrEmpty));
    };
};
var isReviewer = function (ev) {
    return function (state) {
        return Data_Function.apply(Data_Maybe.isJust)(Control_Bind.bind(Data_Maybe.bindMaybe)(Network_RemoteData.toMaybe(state.userProfile))(function (u) {
            return Model_Event.findReviewerWithUid(Model_UserProfile.key(u))(ev);
        }));
    };
};
var isPotentialReviewer = function (event) {
    return function (u) {
        return Data_Function.apply(Data_Maybe.isNothing)(Model_Event.findReviewerWithUid(Model_UserProfile.key(u))(event));
    };
};
var nonReviewingUsers = function (e) {
    return function (v) {
        return Data_Array.filter(isPotentialReviewer(e))(v.allUsers);
    };
};
var reviewers = function (v) {
    return function (state) {
        var mangleReviewers = function (f) {
            return Data_Function.apply(Data_Array.catMaybes)(Data_Functor.map(Data_Functor.functorArray)(reviewingUser(state))(Data_List.toUnfoldable(Data_Unfoldable.unfoldableArray)(Data_Function.apply(Data_Map.toList)(f(v)))));
        };
        return {
            potential: nonReviewingUsers(v)(state), 
            active: mangleReviewers(Model_Event.activatedReviewers), 
            deactivated: mangleReviewers(Model_Event.deActivatedReviewers)
        };
    };
};
var getActive = function (v) {
    return v.active;
};
var findReviewerKey = function (ev) {
    return function (state) {
        return Control_Bind.bind(Data_Maybe.bindMaybe)(Network_RemoteData.toMaybe(state.userProfile))(function (u) {
            return Model_Event.findReviewerWithUid(Data_ForeignKey.mkForeignKey((Model_UserProfile.runUserProfile(u)).uid))(ev);
        });
    };
};
var findEvent = function (key) {
    return function (state) {
        return Data_StrMap.lookup(Data_ForeignKey.fkToString(key))(state.events);
    };
};
var eventKeyFromPath = function (path) {
    return Data_Functor.map(Data_Maybe.functorMaybe)(Data_ForeignKey.mkForeignKey)(Data_Array.index(Data_String.split("/")(path))(2));
};
var eventKeyFromForeignLocation = function (location) {
    return Data_Function.apply(eventKeyFromPath)(Data_Function.apply(Data_Tuple.fst)(Data_ForeignLocation.pathKey(location)));
};
var areAllSessionsLoadedEk = function (key) {
    return function (state) {
        var findSessions = (function () {
            var event = findEvent(key)(state);
            return Data_Functor.map(Data_Maybe.functorMaybe)(function (e) {
                return keysOfVisibleSessionDescriptions(e)(state);
            })(event);
        })();
        if (findSessions instanceof Data_Maybe.Just) {
            return Data_Function.apply(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Eq.eq(Data_Eq.eqArray(Data_ForeignKey.eqForeignKey))(findSessions.value0)([  ]));
        };
        return false;
    };
};
var applyForeignReviewerActivation = function (fl) {
    return function (state) {
        var pathAndKey = Data_ForeignLocation.pathKey(fl);
        var reviewerKey = Data_Tuple.snd(pathAndKey);
        var maybeEventKey = Data_Function.apply(eventKeyFromPath)(Data_Tuple.fst(pathAndKey));
        return Control_Bind.bind(Data_Either.bindEither)(Data_ForeignLocation.readForeignPart(Data_Foreign_Class.booleanIsForeign)(fl))(function (v) {
            return Data_Function.apply(Control_Applicative.pure(Data_Either.applicativeEither))((function () {
                if (maybeEventKey instanceof Data_Maybe.Nothing) {
                    return state;
                };
                if (maybeEventKey instanceof Data_Maybe.Just) {
                    return setReviewerEnabled(maybeEventKey.value0)(reviewerKey)(v)(state);
                };
                throw new Error("Failed pattern match at State.Event line 77, column 3 - line 79, column 73: " + [ maybeEventKey.constructor.name ]);
            })());
        });
    };
};
var addEvent = function (state) {
    return function (v) {
        var setLoadedEvent = function (currentEvent) {
            return function (incomingEvent) {
                if (currentEvent.value0 instanceof Data_Maybe.Just && currentEvent.value1 instanceof Network_RemoteData.Loading) {
                    return new Data_Tuple.Tuple(new Data_Maybe.Just(currentEvent.value0.value0), new Network_RemoteData.Success(incomingEvent));
                };
                return new Data_Tuple.Tuple(Data_Maybe.Nothing.value, Network_RemoteData.NotAsked.value);
            };
        };
        var $49 = {};
        for (var $50 in state) {
            if (state.hasOwnProperty($50)) {
                $49[$50] = state[$50];
            };
        };
        $49.events = Data_StrMap.insert(v.shortCode)(v)(state.events);
        $49.current = {
            event: setLoadedEvent(state.current.event)(v)
        };
        return $49;
    };
};
var applyForeignEvent = function (fl) {
    return function (state) {
        return Data_Functor.map(Data_Either.functorEither)(addEvent(state))(Data_ForeignLocation.readForeignPart(Model_Event.isForeignEvent)(fl));
    };
};
var activeReviewers = function (key) {
    return function (state) {
        var event = findEvent(key)(state);
        if (event instanceof Data_Maybe.Nothing) {
            return Data_Monoid.mempty(Data_Monoid.monoidArray);
        };
        if (event instanceof Data_Maybe.Just) {
            return getActive(reviewers(event.value0)(state));
        };
        throw new Error("Failed pattern match at State.Event line 195, column 3 - line 197, column 57: " + [ event.constructor.name ]);
    };
};
module.exports = {
    PanelChairSelection: PanelChairSelection, 
    Reviewers: Reviewers, 
    ReviewingUser: ReviewingUser, 
    activeReviewers: activeReviewers, 
    addEvent: addEvent, 
    applyForeignEvent: applyForeignEvent, 
    applyForeignReviewerActivation: applyForeignReviewerActivation, 
    areAllSessionsLoadedEk: areAllSessionsLoadedEk, 
    eventKeyFromForeignLocation: eventKeyFromForeignLocation, 
    findEvent: findEvent, 
    findReviewerKey: findReviewerKey, 
    isReviewer: isReviewer, 
    keysOfVisibleSessionDescriptions: keysOfVisibleSessionDescriptions, 
    nonReviewingUsers: nonReviewingUsers, 
    panelChairSelection: panelChairSelection, 
    resetNewEvent: resetNewEvent, 
    reviewers: reviewers, 
    reviewingUser: reviewingUser
};
