// Generated by psc version 0.9.1
"use strict";
var WeReview_Data_Schema = require("../WeReview.Data.Schema");
var Data_Array = require("../Data.Array");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_ForeignLocation = require("../Data.ForeignLocation");
var Data_Maybe = require("../Data.Maybe");
var Model_Event_Key = require("../Model.Event.Key");
var Prelude = require("../Prelude");
var State_ApplyFound = require("../State.ApplyFound");
var State_Event = require("../State.Event");
var State_Review = require("../State.Review");
var State_Reviewer = require("../State.Reviewer");
var State_SessionDescription = require("../State.SessionDescription");
var State_Types = require("../State.Types");
var Data_Function = require("../Data.Function");
var Data_PathTo = require("../Data.PathTo");
var Data_Semigroup = require("../Data.Semigroup");
var Control_Applicative = require("../Control.Applicative");
var sessionsEventPath = function (v) {
    return function (k) {
        return Data_Function.apply(Data_PathTo.pathTo(WeReview_Data_Schema.pathToWeReviewSchema))(new WeReview_Data_Schema.Events(k, WeReview_Data_Schema.SessionDescriptionKeys.value));
    };
};
var sessionDescriptionPath = function (v) {
    return function (k) {
        return Data_Function.apply(Data_PathTo.pathTo(WeReview_Data_Schema.pathToWeReviewSchema))(new WeReview_Data_Schema.SessionDescriptions(k, WeReview_Data_Schema.SessionDescriptionObject.value));
    };
};
var reviewersEventPath = function (v) {
    return function (k) {
        return Data_Function.apply(Data_PathTo.pathTo(WeReview_Data_Schema.pathToWeReviewSchema))(new WeReview_Data_Schema.Events(k, WeReview_Data_Schema.Reviewers.value));
    };
};
var reviewerPath = function (v) {
    return function (k) {
        return "/reviewers/" + Data_ForeignKey.fkToString(k);
    };
};
var reviewerEventPath = function (p) {
    return function (k) {
        return p + ("/" + Data_ForeignKey.fkToString(k));
    };
};
var reviewPath = function (v) {
    return function (k) {
        return "/reviews/" + Data_ForeignKey.fkToString(k);
    };
};
var reviewKeysPath = function (p) {
    return function (v) {
        return p + "/reviews";
    };
};
var fixedPath = function (p1) {
    return function (v) {
        return function (v1) {
            return p1;
        };
    };
};
var dependOnSingleEventOnly = function (eventKey) {
    return new State_Types.Changed("Event", fixedPath("/events/" + Data_ForeignKey.fkToString(eventKey)), State_Event.applyForeignEvent, [  ]);
};
var dependOnEventsOnly = new State_Types.Added("Event", fixedPath("/events"), State_Event.applyForeignEvent, [  ]);
var afterAddingSessionDescriptionKey = [ new State_Types.Changed("Session Description", sessionDescriptionPath, State_SessionDescription.applyForeignSessionDescription, [  ]) ];
var dependOnSessionDescriptionKeysAddedToEvent = new State_Types.Added("Session Description key", sessionsEventPath, State_ApplyFound.noopApply, afterAddingSessionDescriptionKey);
var afterAddingReviewerToEvent = [ new State_Types.Changed("Reviewer visibility", reviewerEventPath, State_Event.applyForeignReviewerActivation, [  ]), new State_Types.Changed("Reviewer", reviewerPath, State_Reviewer.applyForeignReviewer, [ new State_Types.Added("Review key", reviewKeysPath, State_ApplyFound.noopApply, [ new State_Types.Changed("Review", reviewPath, State_Review.applyForeignReview, [  ]) ]) ]) ];
var dependOnReviewersAddedToEvent = new State_Types.Added("Reviewer key", reviewersEventPath, State_Event.applyForeignReviewerActivation, afterAddingReviewerToEvent);
var eventDependencies = [ dependOnReviewersAddedToEvent, dependOnSessionDescriptionKeysAddedToEvent ];
var dependOnEvent = new State_Types.Added("Event", fixedPath("/events"), State_Event.applyForeignEvent, eventDependencies);
var subscribeAllEventKey = function (eventKey) {
    return function (state) {
        var reviewersPath = Data_Function.apply(fixedPath)(reviewersEventPath("/events/")(eventKey));
        var dontCare = Data_Function.apply(fixedPath)(sessionsEventPath("unused")(eventKey));
        var dependOnSessionsForThisEvent = new State_Types.Added("Session Description key", dontCare, State_ApplyFound.noopApply, afterAddingSessionDescriptionKey);
        var dependOnReviewerKeysForThisEvent = new State_Types.Added("Reviewer key", reviewersPath, State_Event.applyForeignReviewerActivation, afterAddingReviewerToEvent);
        var $8 = State_Event.findEvent(eventKey)(state);
        if ($8 instanceof Data_Maybe.Nothing) {
            return Data_Function.apply(Control_Applicative.pure(Data_Maybe.applicativeMaybe))(Data_Function.apply(Data_Array.singleton)(dependOnSingleEventOnly(eventKey)));
        };
        if ($8 instanceof Data_Maybe.Just) {
            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)([ dependOnSessionsForThisEvent, dependOnReviewerKeysForThisEvent ]);
        };
        throw new Error("Failed pattern match at State.EventDependencies line 56, column 39 - line 58, column 83: " + [ $8.constructor.name ]);
    };
};
module.exports = {
    afterAddingReviewerToEvent: afterAddingReviewerToEvent, 
    afterAddingSessionDescriptionKey: afterAddingSessionDescriptionKey, 
    dependOnEvent: dependOnEvent, 
    dependOnEventsOnly: dependOnEventsOnly, 
    dependOnReviewersAddedToEvent: dependOnReviewersAddedToEvent, 
    dependOnSessionDescriptionKeysAddedToEvent: dependOnSessionDescriptionKeysAddedToEvent, 
    dependOnSingleEventOnly: dependOnSingleEventOnly, 
    eventDependencies: eventDependencies, 
    fixedPath: fixedPath, 
    reviewKeysPath: reviewKeysPath, 
    reviewPath: reviewPath, 
    reviewerEventPath: reviewerEventPath, 
    reviewerPath: reviewerPath, 
    reviewersEventPath: reviewersEventPath, 
    sessionDescriptionPath: sessionDescriptionPath, 
    sessionsEventPath: sessionsEventPath, 
    subscribeAllEventKey: subscribeAllEventKey
};
