// Generated by psc version 0.9.1
"use strict";
var $foreign = require("./foreign");
var Data_Function_Uncurried = require("../Data.Function.Uncurried");
var Prelude = require("../Prelude");
var Data_Functor = require("../Data.Functor");
var wbr = Data_Function_Uncurried.runFn3($foreign.element)("body");
var vkern = Data_Function_Uncurried.runFn3($foreign.element)("vkern");
var view = Data_Function_Uncurried.runFn3($foreign.element)("view");
var video = Data_Function_Uncurried.runFn3($foreign.element)("video");
var $$var = Data_Function_Uncurried.runFn3($foreign.element)("var");
var use = Data_Function_Uncurried.runFn3($foreign.element)("use");
var ul = Data_Function_Uncurried.runFn3($foreign.element)("ul");
var u = Data_Function_Uncurried.runFn3($foreign.element)("u");
var tspan = Data_Function_Uncurried.runFn3($foreign.element)("tspan");
var tref = Data_Function_Uncurried.runFn3($foreign.element)("tref");
var track = Data_Function_Uncurried.runFn3($foreign.element)("track");
var tr = Data_Function_Uncurried.runFn3($foreign.element)("tr");
var title = Data_Function_Uncurried.runFn3($foreign.element)("title");
var time = Data_Function_Uncurried.runFn3($foreign.element)("time");
var thead = Data_Function_Uncurried.runFn3($foreign.element)("thead");
var th = Data_Function_Uncurried.runFn3($foreign.element)("th");
var tfoot = Data_Function_Uncurried.runFn3($foreign.element)("tfoot");
var textarea = Data_Function_Uncurried.runFn3($foreign.element)("textarea");
var textPath = Data_Function_Uncurried.runFn3($foreign.element)("textPath");
var td = Data_Function_Uncurried.runFn3($foreign.element)("td");
var tbody = Data_Function_Uncurried.runFn3($foreign.element)("tbody");
var table = Data_Function_Uncurried.runFn3($foreign.element)("table");
var symbol = Data_Function_Uncurried.runFn3($foreign.element)("symbol");
var $$switch = Data_Function_Uncurried.runFn3($foreign.element)("switch");
var svg = Data_Function_Uncurried.runFn3($foreign.element)("svg");
var sup = Data_Function_Uncurried.runFn3($foreign.element)("sup");
var summary = Data_Function_Uncurried.runFn3($foreign.element)("summary");
var sub = Data_Function_Uncurried.runFn3($foreign.element)("sub");
var style = Data_Function_Uncurried.runFn3($foreign.element)("style");
var strong = Data_Function_Uncurried.runFn3($foreign.element)("strong");
var stop = Data_Function_Uncurried.runFn3($foreign.element)("stop");
var span = Data_Function_Uncurried.runFn3($foreign.element)("span");
var source = Data_Function_Uncurried.runFn3($foreign.element)("source");
var small = Data_Function_Uncurried.runFn3($foreign.element)("small");
var set = Data_Function_Uncurried.runFn3($foreign.element)("set");
var select = Data_Function_Uncurried.runFn3($foreign.element)("select");
var section = Data_Function_Uncurried.runFn3($foreign.element)("section");
var script = Data_Function_Uncurried.runFn3($foreign.element)("script");
var samp = Data_Function_Uncurried.runFn3($foreign.element)("samp");
var s = Data_Function_Uncurried.runFn3($foreign.element)("s");
var ruby = Data_Function_Uncurried.runFn3($foreign.element)("ruby");
var rt = Data_Function_Uncurried.runFn3($foreign.element)("rt");
var rp = Data_Function_Uncurried.runFn3($foreign.element)("rp");
var rect = Data_Function_Uncurried.runFn3($foreign.element)("rect");
var radialGradient = Data_Function_Uncurried.runFn3($foreign.element)("radialGradient");
var q = Data_Function_Uncurried.runFn3($foreign.element)("q");
var progress = Data_Function_Uncurried.runFn3($foreign.element)("progress");
var pre = Data_Function_Uncurried.runFn3($foreign.element)("pre");
var polyline = Data_Function_Uncurried.runFn3($foreign.element)("polyline");
var polygon = Data_Function_Uncurried.runFn3($foreign.element)("polygon");
var picture = Data_Function_Uncurried.runFn3($foreign.element)("picture");
var pattern = Data_Function_Uncurried.runFn3($foreign.element)("pattern");
var path = Data_Function_Uncurried.runFn3($foreign.element)("path");
var param = Data_Function_Uncurried.runFn3($foreign.element)("param");
var p = Data_Function_Uncurried.runFn3($foreign.element)("p");
var output = Data_Function_Uncurried.runFn3($foreign.element)("output");
var option = Data_Function_Uncurried.runFn3($foreign.element)("option");
var optgroup = Data_Function_Uncurried.runFn3($foreign.element)("optgroup");
var ol = Data_Function_Uncurried.runFn3($foreign.element)("ol");
var object = Data_Function_Uncurried.runFn3($foreign.element)("object");
var noscript = Data_Function_Uncurried.runFn3($foreign.element)("noscript");
var nav = Data_Function_Uncurried.runFn3($foreign.element)("nav");
var mpath = Data_Function_Uncurried.runFn3($foreign.element)("mpath");
var missingGlyph = Data_Function_Uncurried.runFn3($foreign.element)("missing-glyph");
var meter = Data_Function_Uncurried.runFn3($foreign.element)("meter");
var metadata = Data_Function_Uncurried.runFn3($foreign.element)("metadata");
var meta = Data_Function_Uncurried.runFn3($foreign.element)("meta");
var menuitem = Data_Function_Uncurried.runFn3($foreign.element)("menuitem");
var menu = Data_Function_Uncurried.runFn3($foreign.element)("menu");
var mask = Data_Function_Uncurried.runFn3($foreign.element)("mask");
var marker = Data_Function_Uncurried.runFn3($foreign.element)("marker");
var mark = Data_Function_Uncurried.runFn3($foreign.element)("mark");
var map = Data_Function_Uncurried.runFn3($foreign.element)("map");
var main = Data_Function_Uncurried.runFn3($foreign.element)("main");
var link = Data_Function_Uncurried.runFn3($foreign.element)("link");
var linearGradient = Data_Function_Uncurried.runFn3($foreign.element)("linearGradient");
var line = Data_Function_Uncurried.runFn3($foreign.element)("line");
var li = Data_Function_Uncurried.runFn3($foreign.element)("li");
var legend = Data_Function_Uncurried.runFn3($foreign.element)("legend");
var label = Data_Function_Uncurried.runFn3($foreign.element)("label");
var keygen = Data_Function_Uncurried.runFn3($foreign.element)("keygen");
var kbd = Data_Function_Uncurried.runFn3($foreign.element)("kbd");
var ins = Data_Function_Uncurried.runFn3($foreign.element)("ins");
var input = Data_Function_Uncurried.runFn3($foreign.element)("input");
var img = Data_Function_Uncurried.runFn3($foreign.element)("img");
var image = Data_Function_Uncurried.runFn3($foreign.element)("image");
var iframe = Data_Function_Uncurried.runFn3($foreign.element)("iframe");
var i = Data_Function_Uncurried.runFn3($foreign.element)("i");
var html = Data_Function_Uncurried.runFn3($foreign.element)("html");
var hr = Data_Function_Uncurried.runFn3($foreign.element)("hr");
var hkern = Data_Function_Uncurried.runFn3($foreign.element)("hkern");
var header = Data_Function_Uncurried.runFn3($foreign.element)("header");
var head = Data_Function_Uncurried.runFn3($foreign.element)("head");
var h6 = Data_Function_Uncurried.runFn3($foreign.element)("h6");
var h5 = Data_Function_Uncurried.runFn3($foreign.element)("h5");
var h4 = Data_Function_Uncurried.runFn3($foreign.element)("h4");
var h3 = Data_Function_Uncurried.runFn3($foreign.element)("h3");
var h2 = Data_Function_Uncurried.runFn3($foreign.element)("h2");
var h1 = Data_Function_Uncurried.runFn3($foreign.element)("h1");
var glyphRef = Data_Function_Uncurried.runFn3($foreign.element)("glyphRef");
var glyph = Data_Function_Uncurried.runFn3($foreign.element)("glyph");
var g = Data_Function_Uncurried.runFn3($foreign.element)("g");
var functorHtml = new Data_Functor.Functor(function (f) {
    return function (x) {
        return $foreign.forwardTo(f)(x);
    };
});
var form = Data_Function_Uncurried.runFn3($foreign.element)("form");
var foreignObject = Data_Function_Uncurried.runFn3($foreign.element)("foreignObject");
var footer = Data_Function_Uncurried.runFn3($foreign.element)("footer");
var fontFaceUri = Data_Function_Uncurried.runFn3($foreign.element)("font-face-uri");
var fontFaceSrc = Data_Function_Uncurried.runFn3($foreign.element)("font-face-src");
var fontFaceName = Data_Function_Uncurried.runFn3($foreign.element)("font-face-name");
var fontFaceFormat = Data_Function_Uncurried.runFn3($foreign.element)("font-face-format");
var fontFace = Data_Function_Uncurried.runFn3($foreign.element)("font-face");
var filter = Data_Function_Uncurried.runFn3($foreign.element)("filter");
var figure = Data_Function_Uncurried.runFn3($foreign.element)("figure");
var figcaption = Data_Function_Uncurried.runFn3($foreign.element)("figcaption");
var fieldset = Data_Function_Uncurried.runFn3($foreign.element)("fieldset");
var feTurbulence = Data_Function_Uncurried.runFn3($foreign.element)("feTurbulence");
var feTile = Data_Function_Uncurried.runFn3($foreign.element)("feTile");
var feSpotLight = Data_Function_Uncurried.runFn3($foreign.element)("feSpotLight");
var feSpecularLighting = Data_Function_Uncurried.runFn3($foreign.element)("feSpecularLighting");
var fePointLight = Data_Function_Uncurried.runFn3($foreign.element)("fePointLight");
var feOffset = Data_Function_Uncurried.runFn3($foreign.element)("feOffset");
var feMorphology = Data_Function_Uncurried.runFn3($foreign.element)("feMorphology");
var feMergeNode = Data_Function_Uncurried.runFn3($foreign.element)("feMergeNode");
var feMerge = Data_Function_Uncurried.runFn3($foreign.element)("feMerge");
var feImage = Data_Function_Uncurried.runFn3($foreign.element)("feImage");
var feGaussianBlur = Data_Function_Uncurried.runFn3($foreign.element)("feGaussianBlur");
var feFuncR = Data_Function_Uncurried.runFn3($foreign.element)("feFuncR");
var feFuncG = Data_Function_Uncurried.runFn3($foreign.element)("feFuncG");
var feFuncB = Data_Function_Uncurried.runFn3($foreign.element)("feFuncB");
var feFuncA = Data_Function_Uncurried.runFn3($foreign.element)("feFuncA");
var feFlood = Data_Function_Uncurried.runFn3($foreign.element)("feFlood");
var feDistantLight = Data_Function_Uncurried.runFn3($foreign.element)("feDistantLight");
var feDisplacementMap = Data_Function_Uncurried.runFn3($foreign.element)("feDisplacementMap");
var feDiffuseLighting = Data_Function_Uncurried.runFn3($foreign.element)("feDiffuseLighting");
var feConvolveMatrix = Data_Function_Uncurried.runFn3($foreign.element)("feConvolveMatrix");
var feComposite = Data_Function_Uncurried.runFn3($foreign.element)("feComposite");
var feComponentTransfer = Data_Function_Uncurried.runFn3($foreign.element)("feComponentTransfer");
var feColorMatrix = Data_Function_Uncurried.runFn3($foreign.element)("feColorMatrix");
var feBlend = Data_Function_Uncurried.runFn3($foreign.element)("feBlend");
var embed = Data_Function_Uncurried.runFn3($foreign.element)("embed");
var em = Data_Function_Uncurried.runFn3($foreign.element)("em");
var ellipse = Data_Function_Uncurried.runFn3($foreign.element)("ellipse");
var dt = Data_Function_Uncurried.runFn3($foreign.element)("dt");
var dl = Data_Function_Uncurried.runFn3($foreign.element)("dl");
var div = Data_Function_Uncurried.runFn3($foreign.element)("div");
var dialog = Data_Function_Uncurried.runFn3($foreign.element)("dialog");
var dfn = Data_Function_Uncurried.runFn3($foreign.element)("dfn");
var details = Data_Function_Uncurried.runFn3($foreign.element)("details");
var desc = Data_Function_Uncurried.runFn3($foreign.element)("desc");
var del = Data_Function_Uncurried.runFn3($foreign.element)("del");
var defs = Data_Function_Uncurried.runFn3($foreign.element)("defs");
var dd = Data_Function_Uncurried.runFn3($foreign.element)("dd");
var datalist = Data_Function_Uncurried.runFn3($foreign.element)("datalist");
var data_ = Data_Function_Uncurried.runFn3($foreign.element)("data");
var cursor = Data_Function_Uncurried.runFn3($foreign.element)("cursor");
var colorProfile = Data_Function_Uncurried.runFn3($foreign.element)("color-profile");
var colgroup = Data_Function_Uncurried.runFn3($foreign.element)("colgroup");
var col = Data_Function_Uncurried.runFn3($foreign.element)("col");
var code = Data_Function_Uncurried.runFn3($foreign.element)("code");
var clipPath = Data_Function_Uncurried.runFn3($foreign.element)("clipPath");
var cite = Data_Function_Uncurried.runFn3($foreign.element)("cite");
var circle = Data_Function_Uncurried.runFn3($foreign.element)("circle");
var caption = Data_Function_Uncurried.runFn3($foreign.element)("caption");
var canvas = Data_Function_Uncurried.runFn3($foreign.element)("canvas");
var button = Data_Function_Uncurried.runFn3($foreign.element)("button");
var br = Data_Function_Uncurried.runFn3($foreign.element)("br");
var body = Data_Function_Uncurried.runFn3($foreign.element)("body");
var blockquote = Data_Function_Uncurried.runFn3($foreign.element)("blockquote");
var big = Data_Function_Uncurried.runFn3($foreign.element)("big");
var bdo = Data_Function_Uncurried.runFn3($foreign.element)("bdo");
var bdi = Data_Function_Uncurried.runFn3($foreign.element)("bdi");
var base = Data_Function_Uncurried.runFn3($foreign.element)("base");
var b = Data_Function_Uncurried.runFn3($foreign.element)("b");
var audio = Data_Function_Uncurried.runFn3($foreign.element)("audio");
var aside = Data_Function_Uncurried.runFn3($foreign.element)("aside");
var article = Data_Function_Uncurried.runFn3($foreign.element)("article");
var area = Data_Function_Uncurried.runFn3($foreign.element)("area");
var animateTransform = Data_Function_Uncurried.runFn3($foreign.element)("animateTransform");
var animateMotion = Data_Function_Uncurried.runFn3($foreign.element)("animateMotion");
var animateColor = Data_Function_Uncurried.runFn3($foreign.element)("animateColor");
var animate = Data_Function_Uncurried.runFn3($foreign.element)("animate");
var altGlyphItem = Data_Function_Uncurried.runFn3($foreign.element)("altGlyphItem");
var altGlyphDef = Data_Function_Uncurried.runFn3($foreign.element)("altGlyphDef");
var altGlyph = Data_Function_Uncurried.runFn3($foreign.element)("altGlyph");
var address = Data_Function_Uncurried.runFn3($foreign.element)("address");
var abbr = Data_Function_Uncurried.runFn3($foreign.element)("abbr");
var a = Data_Function_Uncurried.runFn3($foreign.element)("a");
module.exports = {
    a: a, 
    abbr: abbr, 
    address: address, 
    altGlyph: altGlyph, 
    altGlyphDef: altGlyphDef, 
    altGlyphItem: altGlyphItem, 
    animate: animate, 
    animateColor: animateColor, 
    animateMotion: animateMotion, 
    animateTransform: animateTransform, 
    area: area, 
    article: article, 
    aside: aside, 
    audio: audio, 
    b: b, 
    base: base, 
    bdi: bdi, 
    bdo: bdo, 
    big: big, 
    blockquote: blockquote, 
    body: body, 
    br: br, 
    button: button, 
    canvas: canvas, 
    caption: caption, 
    circle: circle, 
    cite: cite, 
    clipPath: clipPath, 
    code: code, 
    col: col, 
    colgroup: colgroup, 
    colorProfile: colorProfile, 
    cursor: cursor, 
    data_: data_, 
    datalist: datalist, 
    dd: dd, 
    defs: defs, 
    del: del, 
    desc: desc, 
    details: details, 
    dfn: dfn, 
    dialog: dialog, 
    div: div, 
    dl: dl, 
    dt: dt, 
    ellipse: ellipse, 
    em: em, 
    embed: embed, 
    feBlend: feBlend, 
    feColorMatrix: feColorMatrix, 
    feComponentTransfer: feComponentTransfer, 
    feComposite: feComposite, 
    feConvolveMatrix: feConvolveMatrix, 
    feDiffuseLighting: feDiffuseLighting, 
    feDisplacementMap: feDisplacementMap, 
    feDistantLight: feDistantLight, 
    feFlood: feFlood, 
    feFuncA: feFuncA, 
    feFuncB: feFuncB, 
    feFuncG: feFuncG, 
    feFuncR: feFuncR, 
    feGaussianBlur: feGaussianBlur, 
    feImage: feImage, 
    feMerge: feMerge, 
    feMergeNode: feMergeNode, 
    feMorphology: feMorphology, 
    feOffset: feOffset, 
    fePointLight: fePointLight, 
    feSpecularLighting: feSpecularLighting, 
    feSpotLight: feSpotLight, 
    feTile: feTile, 
    feTurbulence: feTurbulence, 
    fieldset: fieldset, 
    figcaption: figcaption, 
    figure: figure, 
    filter: filter, 
    fontFace: fontFace, 
    fontFaceFormat: fontFaceFormat, 
    fontFaceName: fontFaceName, 
    fontFaceSrc: fontFaceSrc, 
    fontFaceUri: fontFaceUri, 
    footer: footer, 
    foreignObject: foreignObject, 
    form: form, 
    g: g, 
    glyph: glyph, 
    glyphRef: glyphRef, 
    h1: h1, 
    h2: h2, 
    h3: h3, 
    h4: h4, 
    h5: h5, 
    h6: h6, 
    head: head, 
    header: header, 
    hkern: hkern, 
    hr: hr, 
    html: html, 
    i: i, 
    iframe: iframe, 
    image: image, 
    img: img, 
    input: input, 
    ins: ins, 
    kbd: kbd, 
    keygen: keygen, 
    label: label, 
    legend: legend, 
    li: li, 
    line: line, 
    linearGradient: linearGradient, 
    link: link, 
    main: main, 
    map: map, 
    mark: mark, 
    marker: marker, 
    mask: mask, 
    menu: menu, 
    menuitem: menuitem, 
    meta: meta, 
    metadata: metadata, 
    meter: meter, 
    missingGlyph: missingGlyph, 
    mpath: mpath, 
    nav: nav, 
    noscript: noscript, 
    object: object, 
    ol: ol, 
    optgroup: optgroup, 
    option: option, 
    output: output, 
    p: p, 
    param: param, 
    path: path, 
    pattern: pattern, 
    picture: picture, 
    polygon: polygon, 
    polyline: polyline, 
    pre: pre, 
    progress: progress, 
    q: q, 
    radialGradient: radialGradient, 
    rect: rect, 
    rp: rp, 
    rt: rt, 
    ruby: ruby, 
    s: s, 
    samp: samp, 
    script: script, 
    section: section, 
    select: select, 
    set: set, 
    small: small, 
    source: source, 
    span: span, 
    stop: stop, 
    strong: strong, 
    style: style, 
    sub: sub, 
    summary: summary, 
    sup: sup, 
    svg: svg, 
    "switch": $$switch, 
    symbol: symbol, 
    table: table, 
    tbody: tbody, 
    td: td, 
    textPath: textPath, 
    textarea: textarea, 
    tfoot: tfoot, 
    th: th, 
    thead: thead, 
    time: time, 
    title: title, 
    tr: tr, 
    track: track, 
    tref: tref, 
    tspan: tspan, 
    u: u, 
    ul: ul, 
    use: use, 
    "var": $$var, 
    video: video, 
    view: view, 
    vkern: vkern, 
    wbr: wbr, 
    functorHtml: functorHtml, 
    element: $foreign.element, 
    forwardTo: $foreign.forwardTo, 
    text: $foreign.text
};
