// Generated by psc version 0.9.1
"use strict";
var $foreign = require("./foreign");
var Components_Elements = require("../Components.Elements");
var Control_Monad_Aff = require("../Control.Monad.Aff");
var Control_Monad_Eff = require("../Control.Monad.Eff");
var Control_Monad_Eff_Class = require("../Control.Monad.Eff.Class");
var DOM = require("../DOM");
var Data_Function_Uncurried = require("../Data.Function.Uncurried");
var Data_MediaType = require("../Data.MediaType");
var Prelude = require("../Prelude");
var Pux_Html = require("../Pux.Html");
var Pux_Html_Events = require("../Pux.Html.Events");
var Components_Elements_Basic_Types = require("../Components.Elements.Basic.Types");
var Control_Applicative = require("../Control.Applicative");
var Data_Unit = require("../Data.Unit");
var Data_Function = require("../Data.Function");
var StateLess = (function () {
    function StateLess() {

    };
    StateLess.value = new StateLess();
    return StateLess;
})();
var DownloadSpec = function (x) {
    return x;
};
var DownloadButton = function (x) {
    return x;
};
var Download = (function () {
    function Download(value0) {
        this.value0 = value0;
    };
    Download.create = function (value0) {
        return new Download(value0);
    };
    return Download;
})();
var DownloadFinished = (function () {
    function DownloadFinished() {

    };
    DownloadFinished.value = new DownloadFinished();
    return DownloadFinished;
})();
var view = function (v) {
    return Components_Elements.createButton(new Download(v.spec))(Components_Elements_Basic_Types.mkHtmlId("download"))(v.label);
};
var realizeDownloadSpec = function (v) {
    return {
        mimeType: Data_MediaType.unMediaType(v.mimeType), 
        fileName: v.fileName, 
        value: v.toString(v.value)
    };
};
var mkDownloadSpec = function (mimeType) {
    return function (fileName) {
        return function (toString) {
            return function (value) {
                return {
                    mimeType: mimeType, 
                    fileName: fileName, 
                    toString: toString, 
                    value: value
                };
            };
        };
    };
};
var mkDownloadButton = function (label) {
    return function (spec) {
        return {
            label: label, 
            spec: spec
        };
    };
};
var eventValue = function (ev) {
    return ev.target.value;
};
var downloadGeneratedFile = function (spec) {
    return Data_Function_Uncurried.runFn1($foreign._downloadGeneratedFile)(realizeDownloadSpec(spec));
};
var update = function (action) {
    if (action instanceof DownloadFinished) {
        return Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(Data_Unit.unit);
    };
    if (action instanceof Download) {
        return Data_Function.apply(Control_Monad_Eff_Class.liftEff(Control_Monad_Aff.monadEffAff))(downloadGeneratedFile(action.value0));
    };
    throw new Error("Failed pattern match at Components.GeneratedFileDownload line 43, column 17 - line 45, column 56: " + [ action.constructor.name ]);
};
module.exports = {
    Download: Download, 
    DownloadFinished: DownloadFinished, 
    DownloadButton: DownloadButton, 
    DownloadSpec: DownloadSpec, 
    StateLess: StateLess, 
    downloadGeneratedFile: downloadGeneratedFile, 
    eventValue: eventValue, 
    mkDownloadButton: mkDownloadButton, 
    mkDownloadSpec: mkDownloadSpec, 
    realizeDownloadSpec: realizeDownloadSpec, 
    update: update, 
    view: view, 
    _downloadGeneratedFile: $foreign._downloadGeneratedFile
};
