// Generated by psc version 0.9.1
"use strict";
var Model_UserProfile = require("../Model.UserProfile");
var Control_Monad_Aff = require("../Control.Monad.Aff");
var Control_Monad_Eff = require("../Control.Monad.Eff");
var Data_Firebase_Subscription = require("../Data.Firebase.Subscription");
var Data_ForeignKey = require("../Data.ForeignKey");
var Prelude = require("../Prelude");
var Web_Firebase = require("../Web.Firebase");
var Ports_Firebase_Aff = require("../Ports.Firebase.Aff");
var Ports_Firebase_Types = require("../Ports.Firebase.Types");
var Web_Firebase_Aff = require("../Web.Firebase.Aff");
var Data_Semigroup = require("../Data.Semigroup");
var Control_Category = require("../Control.Category");
var sessionDescriptionsRef = function (root) {
    return Web_Firebase_Aff.child("sessiondescriptions")(root);
};
var roleRef = function (uid) {
    return function (roleName) {
        return function (root) {
            return Web_Firebase_Aff.child("roles/" + (uid + ("/" + roleName)))(root);
        };
    };
};
var refToUsers = function (root) {
    return Web_Firebase.child("/users/")(root);
};
var refToReviews = Web_Firebase_Aff.child("/reviews/");
var refToReviewer = function (reviewerRoleId) {
    return Web_Firebase_Aff.child("/reviewers/" + Data_ForeignKey.fkToString(reviewerRoleId));
};
var refToReviewKeysInReviewer = function (reviewerId) {
    return function (rootRef) {
        return Web_Firebase_Aff.child("reviewers/" + (Data_ForeignKey.fkToString(reviewerId) + "/reviews"))(rootRef);
    };
};
var refToProfile = function (v) {
    return function (root) {
        return Web_Firebase.child("/users/" + v.uid)(root);
    };
};
var refToEventReviewers = function (eventId) {
    return function (rootRef) {
        return Web_Firebase_Aff.child("/events/" + (Data_ForeignKey.fkToString(eventId) + "/reviewers/"))(rootRef);
    };
};
var refToEventReviewer = function (eventId) {
    return function (reviewerId) {
        return function (rootRef) {
            return Web_Firebase_Aff.child("/events/" + (Data_ForeignKey.fkToString(eventId) + ("/reviewers/" + Data_ForeignKey.fkToString(reviewerId))))(rootRef);
        };
    };
};
var myRolesPath = function (uid) {
    return "roles/" + uid;
};
var myRolesRef = function (uid) {
    return function (root) {
        return Web_Firebase_Aff.child(myRolesPath(uid))(root);
    };
};
var eventSessionDescriptionsPath = function (eventId) {
    return "/events/" + (Data_ForeignKey.fkToString(eventId) + "/sessiondescriptions");
};
var allUsersRef = function (root) {
    return Web_Firebase_Aff.child("/users/")(root);
};
var allUsersPath = Data_Firebase_Subscription.mkFirebasePath(Control_Category.categoryFn)("/users/");
var allRolesRef = function (root) {
    return Web_Firebase_Aff.child("roles")(root);
};
var allEventsPath = Data_Firebase_Subscription.mkFirebasePath(Control_Category.categoryFn)("/events/");
module.exports = {
    allEventsPath: allEventsPath, 
    allRolesRef: allRolesRef, 
    allUsersPath: allUsersPath, 
    allUsersRef: allUsersRef, 
    eventSessionDescriptionsPath: eventSessionDescriptionsPath, 
    myRolesPath: myRolesPath, 
    myRolesRef: myRolesRef, 
    refToEventReviewer: refToEventReviewer, 
    refToEventReviewers: refToEventReviewers, 
    refToProfile: refToProfile, 
    refToReviewKeysInReviewer: refToReviewKeysInReviewer, 
    refToReviewer: refToReviewer, 
    refToReviews: refToReviews, 
    refToUsers: refToUsers, 
    roleRef: roleRef, 
    sessionDescriptionsRef: sessionDescriptionsRef
};
