// Generated by psc version 0.9.1
"use strict";
var Data_Foldable = require("../Data.Foldable");
var Prelude = require("../Prelude");
var State_Types = require("../State.Types");
var Control_Apply = require("../Control.Apply");
var Control_Applicative = require("../Control.Applicative");
var Data_Semigroup = require("../Data.Semigroup");
var Data_Function = require("../Data.Function");
var startChain = function (state) {
    return function (fn) {
        return fn(state);
    };
};
var pureNoop = function (dictApply) {
    return function (dictApplicative) {
        return function (f) {
            return [ Control_Apply.applySecond(dictApply)(f)(Control_Applicative.pure(dictApplicative)(State_Types.Noop.value)) ];
        };
    };
};
var noop = function (s) {
    return {
        state: s, 
        effects: [  ]
    };
};
var applyEffModel = function (fn) {
    return function (v) {
        var effModel$prime = fn(v.state);
        return {
            state: effModel$prime.state, 
            effects: Data_Semigroup.append(Data_Semigroup.semigroupArray)(v.effects)(effModel$prime.effects)
        };
    };
};
var chain = Data_Function.flip(applyEffModel);
var applyAll = function (fns) {
    return function (s) {
        return Data_Foldable.foldl(Data_Foldable.foldableArray)(chain)(noop(s))(fns);
    };
};
module.exports = {
    applyAll: applyAll, 
    applyEffModel: applyEffModel, 
    noop: noop, 
    pureNoop: pureNoop
};
