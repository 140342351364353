// Generated by psc version 0.9.1
"use strict";
var $foreign = require("./foreign");
var Data_Boolean = require("../Data.Boolean");
var Data_BooleanAlgebra = require("../Data.BooleanAlgebra");
var Data_Bounded = require("../Data.Bounded");
var Data_Eq = require("../Data.Eq");
var Data_Function = require("../Data.Function");
var Data_Int_Bits = require("../Data.Int.Bits");
var Data_Maybe = require("../Data.Maybe");
var Data_Ord = require("../Data.Ord");
var $$Math = require("../Math");
var Partial_Unsafe = require("../Partial.Unsafe");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var Radix = function (x) {
    return x;
};
var radix = function (n) {
    if (n >= 2 && n <= 36) {
        return new Data_Maybe.Just(n);
    };
    if (Data_Boolean.otherwise) {
        return Data_Maybe.Nothing.value;
    };
    throw new Error("Failed pattern match at Data.Int line 119, column 1 - line 120, column 38: " + [ n.constructor.name ]);
};
var odd = function (x) {
    return Data_Int_Bits.and(x)(1) !== 0;
};
var octal = 8;
var hexadecimal = 16;
var fromStringAs = $foreign.fromStringAsImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
var fromString = fromStringAs(10);
var fromNumber = $foreign.fromNumberImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
var unsafeClamp = function (x) {
    if (x >= $foreign.toNumber(Data_Bounded.top(Data_Bounded.boundedInt))) {
        return Data_Bounded.top(Data_Bounded.boundedInt);
    };
    if (x <= $foreign.toNumber(Data_Bounded.bottom(Data_Bounded.boundedInt))) {
        return Data_Bounded.bottom(Data_Bounded.boundedInt);
    };
    if (Data_Boolean.otherwise) {
        return Partial_Unsafe.unsafePartial(function (dictPartial) {
            return Data_Maybe.fromJust(dictPartial)(fromNumber(x));
        });
    };
    throw new Error("Failed pattern match at Data.Int line 64, column 1 - line 67, column 56: " + [ x.constructor.name ]);
};
var round = function ($3) {
    return unsafeClamp($$Math.round($3));
};
var floor = function ($4) {
    return unsafeClamp($$Math.floor($4));
};
var even = function (x) {
    return Data_Int_Bits.and(x)(1) === 0;
};
var decimal = 10;
var ceil = function ($5) {
    return unsafeClamp($$Math.ceil($5));
};
var binary = 2;
module.exports = {
    binary: binary, 
    ceil: ceil, 
    decimal: decimal, 
    even: even, 
    floor: floor, 
    fromNumber: fromNumber, 
    fromString: fromString, 
    fromStringAs: fromStringAs, 
    hexadecimal: hexadecimal, 
    octal: octal, 
    odd: odd, 
    radix: radix, 
    round: round, 
    toNumber: $foreign.toNumber, 
    toStringAs: $foreign.toStringAs
};
