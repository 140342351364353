// Generated by psc version 0.9.1
"use strict";
var Components_ViewAllowed = require("../Components.ViewAllowed");
var Data_Array = require("../Data.Array");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_Tuple = require("../Data.Tuple");
var Model_Event = require("../Model.Event");
var Prelude = require("../Prelude");
var Pux_Html = require("../Pux.Html");
var Pux_Html_Attributes = require("../Pux.Html.Attributes");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var Route_HtmlElements = require("../Route.HtmlElements");
var State_My = require("../State.My");
var State_Types = require("../State.Types");
var UReview_Route_Types = require("../UReview.Route.Types");
var Data_Functor = require("../Data.Functor");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra");
var Data_Function = require("../Data.Function");
var HTMLId = function (x) {
    return x;
};
var linkToEvent = function (v) {
    return Pux_Html_Elements.li([  ])([ Route_HtmlElements.routeToTop(new UReview_Route_Types.EventRoute(v.value0))(v.value1.name) ]);
};
var showListOfEvents = function (headingText) {
    return function (explanation) {
        return function (events) {
            return Pux_Html_Elements.div([ Pux_Html_Attributes.className("column") ])([ Pux_Html_Elements.h2([  ])([ Pux_Html_Elements.text(headingText) ]), Pux_Html_Elements.p([  ])([ Pux_Html_Elements.text(explanation) ]), Pux_Html_Elements.ul([ Pux_Html_Attributes.className("no-bullet") ])(Data_Functor.map(Data_Functor.functorArray)(linkToEvent)(events)) ]);
        };
    };
};
var showMyEventsWithReviews = showListOfEvents("Submissions for review")("You are a reviewer for the following events:");
var showPanelChairing = showListOfEvents("Events I am panel chair for")("Events below are currently open for review.");
var eventsActions = function (state) {
    var pcEvents = State_My.myPanelChairEvents(state);
    var panelChairing = function (st) {
        return Components_ViewAllowed.panelChair(st) && Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Array["null"])(pcEvents);
    };
    return Components_ViewAllowed.viewAllowed([ Data_Function.apply(Data_Tuple.Tuple.create(Components_ViewAllowed.reviewer))(Data_Function.apply(showMyEventsWithReviews)(State_My.myEventsWithReviews(state))), Data_Function.apply(Data_Tuple.Tuple.create(panelChairing))(showPanelChairing(pcEvents)) ])(state);
};
var eventsHome = function (state) {
    return Pux_Html_Elements.section([ Pux_Html_Attributes.className("events") ])([ Pux_Html_Elements.div([ Pux_Html_Attributes.className("row medium-unstack") ])(eventsActions(state)) ]);
};
module.exports = {
    eventsHome: eventsHome
};
