// Generated by psc version 0.9.1
"use strict";
var Data_Array = require("../Data.Array");
var Data_Firebase_Foreign = require("../Data.Firebase.Foreign");
var Data_Foreign = require("../Data.Foreign");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_Generators = require("../Data.Generators");
var Data_Generic = require("../Data.Generic");
var Data_Maybe = require("../Data.Maybe");
var Data_PathTo = require("../Data.PathTo");
var Model_GlobalRole = require("../Model.GlobalRole");
var Network_RemoteData = require("../Network.RemoteData");
var Prelude = require("../Prelude");
var Test_QuickCheck_Arbitrary = require("../Test.QuickCheck.Arbitrary");
var Test_QuickCheck_Gen = require("../Test.QuickCheck.Gen");
var UReview_Prelude = require("../UReview.Prelude");
var Control_Apply = require("../Control.Apply");
var Data_Unit = require("../Data.Unit");
var Data_Show = require("../Data.Show");
var Data_Eq = require("../Data.Eq");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra");
var Control_Bind = require("../Control.Bind");
var Data_Function = require("../Data.Function");
var Control_Applicative = require("../Control.Applicative");
var Data_Ord = require("../Data.Ord");
var Data_Functor = require("../Data.Functor");
var Data_Either = require("../Data.Either");
var Data_Foreign_Index = require("../Data.Foreign.Index");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans");
var Data_Identity = require("../Data.Identity");
var UserProfile = function (x) {
    return x;
};
var UserKey = function (x) {
    return x;
};
var toFirebaseForeignUserProfile = new Data_Firebase_Foreign.ToFirebaseForeign(function (v) {
    return Data_Foreign.toForeign({
        uid: Data_Firebase_Foreign.toFire(Data_Firebase_Foreign.isFirebaseString)(v.uid), 
        displayName: Data_Firebase_Foreign.toFire(Data_Firebase_Foreign.isFirebaseString)(v.displayName), 
        profileImageUrl: Data_Firebase_Foreign.toFire(Data_Firebase_Foreign.isFirebaseString)(v.profileImageURL)
    });
});
var setGlobalRole = function (value) {
    return function (v) {
        return Data_Function.apply(UserProfile)((function () {
            var $39 = {};
            for (var $40 in v) {
                if (v.hasOwnProperty($40)) {
                    $39[$40] = v[$40];
                };
            };
            $39.roles = new Network_RemoteData.Success(value);
            return $39;
        })());
    };
};
var runUserProfile = function (v) {
    return v;
};
var pathToUserKey = new Data_PathTo.PathTo(function (v) {
    return Data_ForeignKey.fkToString(v);
});
var mkUserKey = function (fk) {
    return fk;
};
var keyString = function (v) {
    return v.uid;
};
var key = function (v) {
    return Data_ForeignKey.mkForeignKey(v.uid);
};
var isIdentifiedBy = function (k) {
    return function (v) {
        return Data_PathTo.pathTo(pathToUserKey)(k) === v.uid;
    };
};
var isForeignUserProfile = new Data_Foreign_Class.IsForeign(function (value) {
    return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Class.readProp(Data_Foreign_Class.stringIsForeign)(Data_Foreign_Index.indexString)("uid")(value))(function (v) {
        return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Class.readProp(Data_Foreign_Class.stringIsForeign)(Data_Foreign_Index.indexString)("displayName")(value))(function (v1) {
            return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Class.readProp(Data_Foreign_Class.stringIsForeign)(Data_Foreign_Index.indexString)("profileImageURL")(value))(function (v2) {
                return Data_Function.apply(Control_Applicative.pure(Data_Either.applicativeEither))({
                    uid: v, 
                    displayName: v1, 
                    profileImageURL: v2, 
                    roles: Network_RemoteData.NotAsked.value
                });
            });
        });
    });
});
var getUserKey = function (v) {
    return Data_Function.apply(mkUserKey)(Data_ForeignKey.mkForeignKey(v.uid));
};
var getDisplayName = function (v) {
    return v.displayName;
};
var genericUserProfile = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.UserProfile.UserProfile" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(UserProfile))((function (r) {
            if (r instanceof Data_Generic.SRecord && r.value0.length === 4) {
                return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(function (displayName1) {
                    return function (profileImageURL1) {
                        return function (roles1) {
                            return function (uid1) {
                                return {
                                    displayName: displayName1, 
                                    profileImageURL: profileImageURL1, 
                                    roles: roles1, 
                                    uid: uid1
                                };
                            };
                        };
                    };
                }))(Data_Generic.fromSpine(Data_Generic.genericString)((r.value0[0]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Data_Generic.genericString)((r.value0[1]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Network_RemoteData.genericRemoteData(Data_Generic.genericString)(Model_GlobalRole.genericGlobalRole))((r.value0[2]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Data_Generic.genericString)((r.value0[3]).recValue(Data_Unit.unit)));
            };
            return Data_Maybe.Nothing.value;
        })(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Model.UserProfile.UserProfile", [ {
        sigConstructor: "Model.UserProfile.UserProfile", 
        sigValues: [ function ($dollarq1) {
            return new Data_Generic.SigRecord([ {
                recLabel: "displayName", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "profileImageURL", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "roles", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Network_RemoteData.genericRemoteData(Data_Generic.genericString)(Model_GlobalRole.genericGlobalRole))(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "uid", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
                }
            } ]);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("Model.UserProfile.UserProfile", [ function ($dollarq) {
        return new Data_Generic.SRecord([ {
            recLabel: "displayName", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_Generic.genericString)(v.displayName);
            }
        }, {
            recLabel: "profileImageURL", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_Generic.genericString)(v.profileImageURL);
            }
        }, {
            recLabel: "roles", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Network_RemoteData.genericRemoteData(Data_Generic.genericString)(Model_GlobalRole.genericGlobalRole))(v.roles);
            }
        }, {
            recLabel: "uid", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_Generic.genericString)(v.uid);
            }
        } ]);
    } ]);
});
var showUserProfile = new Data_Show.Show(Data_Generic.gShow(genericUserProfile));
var genericUserKey = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.UserProfile.UserKey" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(UserKey))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Model.UserProfile.UserKey", [ {
        sigConstructor: "Model.UserProfile.UserKey", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("Model.UserProfile.UserKey", [ function ($dollarq) {
        return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v);
    } ]);
});
var isForeignUserKey = new Data_Foreign_Class.IsForeign(function (value) {
    return UReview_Prelude.readForeign(isForeignUserKey)(genericUserKey)(value);
});
var showUserKey = new Data_Show.Show(Data_Generic.gShow(genericUserKey));
var genUserProfile = Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Data_Generators.alphaNumString)(function (v) {
    return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Data_Generators.alphaNumString)(function (v1) {
        return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Data_Generators.alphaNumString)(function (v2) {
            return Data_Function.apply(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity)))({
                uid: v, 
                displayName: v1, 
                profileImageURL: v2, 
                roles: Network_RemoteData.NotAsked.value
            });
        });
    });
});
var findUserByUid = function (v) {
    return function (users) {
        return Data_Function.apply(Data_Array.head)(Data_Array.filter(function (v1) {
            return v1.uid === v;
        })(users));
    };
};
var findUser = function (v) {
    return function (users) {
        var k = Data_ForeignKey.fkToString(v);
        return Data_Function.apply(Data_Array.head)(Data_Array.filter(function (v1) {
            return v1.uid === k;
        })(users));
    };
};
var eqUserProfile = new Data_Eq.Eq(function (x) {
    return function (y) {
        return x.displayName === y.displayName && x.profileImageURL === y.profileImageURL && Data_Eq.eq(Network_RemoteData.eqRemoteData(Data_Eq.eqString)(Model_GlobalRole.eqGlobalRole))(x.roles)(y.roles) && x.uid === y.uid;
    };
});
var ordUserProfile = new Data_Ord.Ord(function () {
    return eqUserProfile;
}, function (v) {
    return function (v1) {
        return Data_Ord.compare(Data_Ord.ordString)(v.displayName)(v1.displayName);
    };
});
var eqUserKey = new Data_Eq.Eq(Data_Generic.gEq(genericUserKey));
var ordUserKey = new Data_Ord.Ord(function () {
    return eqUserKey;
}, function (v) {
    return function (v1) {
        return Data_Ord.compare(Data_ForeignKey.ordForeignKey)(v)(v1);
    };
});
var asForeignKey = function (v) {
    return v;
};
var arbitraryUserProfile = new Test_QuickCheck_Arbitrary.Arbitrary(genUserProfile);
var arbitraryUserKey = new Test_QuickCheck_Arbitrary.Arbitrary(Data_Functor.map(Control_Monad_State_Trans.functorStateT(Data_Identity.functorIdentity))(mkUserKey)(Test_QuickCheck_Arbitrary.arbitrary(Data_ForeignKey.arbForeignKey)));
module.exports = {
    UserProfile: UserProfile, 
    asForeignKey: asForeignKey, 
    findUser: findUser, 
    findUserByUid: findUserByUid, 
    getDisplayName: getDisplayName, 
    getUserKey: getUserKey, 
    isIdentifiedBy: isIdentifiedBy, 
    key: key, 
    keyString: keyString, 
    mkUserKey: mkUserKey, 
    runUserProfile: runUserProfile, 
    setGlobalRole: setGlobalRole, 
    pathToUserKey: pathToUserKey, 
    genericUserProfile: genericUserProfile, 
    showUserProfile: showUserProfile, 
    eqUserProfile: eqUserProfile, 
    isForeignUserProfile: isForeignUserProfile, 
    ordUserProfile: ordUserProfile, 
    arbitraryUserProfile: arbitraryUserProfile, 
    toFirebaseForeignUserProfile: toFirebaseForeignUserProfile, 
    genericUserKey: genericUserKey, 
    showUserKey: showUserKey, 
    eqUserKey: eqUserKey, 
    isForeignUserKey: isForeignUserKey, 
    ordUserKey: ordUserKey, 
    arbitraryUserKey: arbitraryUserKey
};
