// Generated by psc version 0.9.1
"use strict";
var Components_DB_Action = require("../Components.DB.Action");
var Components_Elements = require("../Components.Elements");
var Data_Array = require("../Data.Array");
var Data_Foreign = require("../Data.Foreign");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_Loaded = require("../Data.Loaded");
var Data_Maybe = require("../Data.Maybe");
var Data_Tuple = require("../Data.Tuple");
var Model_Event = require("../Model.Event");
var Model_Event_Key = require("../Model.Event.Key");
var Model_SessionDescription = require("../Model.SessionDescription");
var Prelude = require("../Prelude");
var Pux_Html_Attributes = require("../Pux.Html.Attributes");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var Route_HtmlElements = require("../Route.HtmlElements");
var State_Event = require("../State.Event");
var State_My = require("../State.My");
var State_SessionDescription = require("../State.SessionDescription");
var State_Types = require("../State.Types");
var UReview_Route_Types = require("../UReview.Route.Types");
var View_Loading = require("../View.Loading");
var WeReview_Data_Schema = require("../WeReview.Data.Schema");
var Components_Elements_Basic_Types = require("../Components.Elements.Basic.Types");
var Data_Semigroup = require("../Data.Semigroup");
var Data_Function = require("../Data.Function");
var Data_Show = require("../Data.Show");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var Data_Functor = require("../Data.Functor");
var rowColumnH2 = function (heading) {
    return function (innerElement) {
        return Pux_Html_Elements.div([ Pux_Html_Attributes.className("row") ])([ Pux_Html_Elements.div([ Pux_Html_Attributes.className("column") ])([ Pux_Html_Elements.h2([  ])([ Pux_Html_Elements.text(heading) ]), Pux_Html_Elements.div([  ])([ innerElement ]) ]) ]);
    };
};
var linkToSessionDescription = function (sdKey) {
    return function (title) {
        return Route_HtmlElements.routeToTop(new UReview_Route_Types.FullSessionDescriptionRoute(sdKey))(title);
    };
};
var sessionDescriptionSummary = function (fk) {
    return function (sd) {
        var s = Model_SessionDescription.runSessionDescription(sd);
        var hideOrShow = function (visible) {
            if (visible) {
                return "Hide";
            };
            if (!visible) {
                return "Show";
            };
            throw new Error("Failed pattern match at View.VisibleSessionsView line 93, column 30 - line 94, column 9: " + [ visible.constructor.name ]);
        };
        var hideAction = (function () {
            var path = new WeReview_Data_Schema.SessionDescriptions(fk, WeReview_Data_Schema.IsVisible.value);
            return new State_Types.DB(new Components_DB_Action.SetValue(path, Data_Foreign.toForeign(false)));
        })();
        var cell = Pux_Html_Elements.td([  ]);
        return Pux_Html_Elements.tr([  ])([ cell([ Components_Elements.createSmallButton(hideAction)(Components_Elements_Basic_Types.mkHtmlId("show-" + Data_ForeignKey.fkToString(fk)))(hideOrShow(s.isVisible)) ]), cell([ Data_Function.apply(Pux_Html_Elements.text)(Data_Show.show(Data_Show.showInt)(s.sessionid) + " - "), linkToSessionDescription(fk)(s.title) ]), cell([ Pux_Html_Elements.text(s.speakername) ]) ]);
    };
};
var listOfSessionDescriptions = function (v) {
    return function (sessionDescriptionKeys) {
        var l = State_SessionDescription.loadedSessionDescriptions(sessionDescriptionKeys)(v);
        var r = Data_Loaded.runLoaded(l);
        var descriptions = Data_Array.filter(function ($18) {
            return Model_SessionDescription.isVisibleSessionDescription(Data_Tuple.snd($18));
        })(r.presentOrEmpty);
        if (r.allLoaded) {
            return Pux_Html_Elements.div([  ])([ Components_Elements.ptext(Data_Function.apply(Data_Show.show(Data_Show.showInt))(Data_Array.length(descriptions)) + " visible session descriptions."), Pux_Html_Elements.table([  ])([ Data_Function.apply(Pux_Html_Elements.tbody([  ]))(Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.uncurry(sessionDescriptionSummary))(descriptions)) ]) ]);
        };
        if (!r.allLoaded) {
            return Pux_Html_Elements.div([  ])([  ]);
        };
        throw new Error("Failed pattern match at View.VisibleSessionsView line 68, column 3 - line 73, column 14: " + [ r.allLoaded.constructor.name ]);
    };
};
var showSessionDescriptions = function (state) {
    return function (shortCode) {
        return function (sessionDescriptionKeys) {
            return Pux_Html_Elements.div([  ])([ Pux_Html_Elements.h3([  ])([ Pux_Html_Elements.text("Visible sessions descriptions") ]), Components_Elements.ptext("Total number of " + (Data_Function.apply(Data_Show.show(Data_Show.showInt))(Data_Array.length(sessionDescriptionKeys)) + " Session descriptions.")), listOfSessionDescriptions(state)(sessionDescriptionKeys) ]);
        };
    };
};
var pcMemberOnly = function (key) {
    return function (v) {
        return function (state) {
            return showSessionDescriptions(state)(v.shortCode)(v.sessionDescriptionKeys);
        };
    };
};
var viewFoundEvent = function (v) {
    return function (key) {
        return function (v1) {
            var authorizedView = (function () {
                var $15 = State_My.isPanelChairFor(key)(v);
                if ($15) {
                    return pcMemberOnly(key)(v1)(v);
                };
                if (!$15) {
                    return Components_Elements.ptext("Checking your permissions, please wait.");
                };
                throw new Error("Failed pattern match at View.VisibleSessionsView line 44, column 22 - line 49, column 1: " + [ $15.constructor.name ]);
            })();
            return rowColumnH2(v1.name)(authorizedView);
        };
    };
};
var eventNotFound = View_Loading.loading("Event");
var viewVisibleSessions = function (key) {
    return function (v) {
        var mEvent = State_Event.findEvent(key)(v);
        return Pux_Html_Elements.section([  ])([ Data_Maybe.maybe(eventNotFound)(viewFoundEvent(v)(key))(mEvent) ]);
    };
};
module.exports = {
    viewVisibleSessions: viewVisibleSessions
};
