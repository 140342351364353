// Generated by psc version 0.9.1
"use strict";
var Control_Alternative = require("../Control.Alternative");
var Data_Array = require("../Data.Array");
var Data_Bifunctor = require("../Data.Bifunctor");
var Data_Either = require("../Data.Either");
var Data_Foldable = require("../Data.Foldable");
var Data_Foreign = require("../Data.Foreign");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var Data_Foreign_Index = require("../Data.Foreign.Index");
var Data_Foreign_Keys = require("../Data.Foreign.Keys");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_List = require("../Data.List");
var Data_Map = require("../Data.Map");
var Data_PathTo = require("../Data.PathTo");
var Data_Set = require("../Data.Set");
var Data_Show = require("../Data.Show");
var Data_StrMap = require("../Data.StrMap");
var Data_Traversable = require("../Data.Traversable");
var Data_Tuple = require("../Data.Tuple");
var Prelude = require("../Prelude");
var Control_Category = require("../Control.Category");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var Data_Function = require("../Data.Function");
var Data_Functor = require("../Data.Functor");
var Data_Unfoldable = require("../Data.Unfoldable");
var Control_Bind = require("../Control.Bind");
var Control_Applicative = require("../Control.Applicative");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra");
var ToFirebaseForeign = function (toFire) {
    this.toFire = toFire;
};
var toFirebaseForeignInt = new ToFirebaseForeign(Data_Foreign.toForeign);
var toFirebaseForeignBoolean = new ToFirebaseForeign(Data_Foreign.toForeign);
var toFire = function (dict) {
    return dict.toFire;
};
var setToFirebaseMap = function (strings) {
    return Data_Function.apply(Data_StrMap.fromFoldable(Data_Foldable.foldableArray))(Data_Function.apply(Data_Functor.map(Data_Functor.functorArray)(function (v) {
        return new Data_Tuple.Tuple(v, true);
    }))(Data_Set.toUnfoldable(Data_Unfoldable.unfoldableArray)(strings)));
};
var readTrueStrings = Data_Foreign_Keys.keys;
var readStringListFromObject = function (f) {
    var strMap = Data_Foreign.unsafeReadTagged("Object")(f);
    return Data_Functor.map(Data_Either.functorEither)(Data_StrMap.values)(strMap);
};
var readStringArrayFromObject = function (f) {
    return Control_Bind.bind(Data_Either.bindEither)(readStringListFromObject(f))(function (v) {
        return Data_Function.apply(Control_Applicative.pure(Data_Either.applicativeEither))(Data_Array.fromFoldable(Data_List.foldableList)(v));
    });
};
var readStringArray = function (dictIndex) {
    return function (prop) {
        return function (value) {
            return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Index.ix(dictIndex)(value)(prop))(readStringArrayFromObject);
        };
    };
};
var readPropStringsWithDefault = function (dictIndex) {
    return function ($$default) {
        return function (p) {
            return function (value) {
                var $26 = Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Index.ix(dictIndex)(value)(p))(Data_Foreign_Keys.keys);
                if ($26 instanceof Data_Either.Left) {
                    return new Data_Either.Right($$default);
                };
                if ($26 instanceof Data_Either.Right) {
                    return new Data_Either.Right($26.value0);
                };
                throw new Error("Failed pattern match at Data.Firebase.Foreign line 51, column 46 - line 53, column 21: " + [ $26.constructor.name ]);
            };
        };
    };
};
var readPropKeysWithDefault = function (dictIndex) {
    return function ($$default) {
        return function (p) {
            return function (value) {
                var $29 = Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Index.ix(dictIndex)(value)(p))(Data_Foreign_Keys.keys);
                if ($29 instanceof Data_Either.Left) {
                    return new Data_Either.Right($$default);
                };
                if ($29 instanceof Data_Either.Right) {
                    return new Data_Either.Right(Data_Functor.map(Data_Functor.functorArray)(Data_ForeignKey.mkForeignKey)($29.value0));
                };
                throw new Error("Failed pattern match at Data.Firebase.Foreign line 57, column 43 - line 59, column 40: " + [ $29.constructor.name ]);
            };
        };
    };
};
var readPropKeys = function (dictIndex) {
    return function (prop) {
        return function (value) {
            return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Index.ix(dictIndex)(value)(prop))(Data_Foreign_Keys.keys);
        };
    };
};
var nestedValue = function (dictIsForeign) {
    return function (value) {
        return function (parent) {
            return function (child) {
                return Data_Function.applyFlipped(value)(Control_Bind.composeKleisli(Data_Either.bindEither)(Data_Foreign_Index.prop(parent))(Data_Foreign_Class.readProp(dictIsForeign)(Data_Foreign_Index.indexString)(child)));
            };
        };
    };
};
var readKeyValues = function (dictIsForeign) {
    return function (p) {
        return function (value) {
            return Control_Bind.bind(Data_Either.bindEither)(Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Index.prop(p)(value))(Data_Foreign_Keys.keys))(function (ks) {
                return Data_Function.apply(Data_Traversable.sequence(Data_Traversable.traversableArray)(Data_Either.applicativeEither))(Data_Functor.map(Data_Functor.functorArray)(function (key) {
                    return Data_Functor.map(Data_Either.functorEither)(Data_Tuple.Tuple.create(Data_ForeignKey.mkForeignKey(key)))(nestedValue(dictIsForeign)(value)(p)(key));
                })(ks));
            });
        };
    };
};
var mapToFirebaseMap = function (dictPathTo) {
    return function (dictToFirebaseForeign) {
        return function (mx) {
            return Data_Function.apply(Data_StrMap.fromFoldable(Data_List.foldableList))(Data_Function.apply(Data_Functor.map(Data_List.functorList)(function (v) {
                return new Data_Tuple.Tuple(Data_PathTo.pathTo(dictPathTo)(v.value0), Data_Foreign.toForeign(v.value1));
            }))(Data_Map.toList(mx)));
        };
    };
};
var toFirebaseMap = function (dictPathTo) {
    return function (dictToFirebaseForeign) {
        return new ToFirebaseForeign(function ($48) {
            return Data_Foreign.toForeign(mapToFirebaseMap(dictPathTo)(dictToFirebaseForeign)($48));
        });
    };
};
var listOfTuplesToExportableMap = function (dictPathTo) {
    return function (dictToFirebaseForeign) {
        return function ($49) {
            return Data_StrMap.fromFoldable(Data_List.foldableList)(Data_Functor.map(Data_List.functorList)(function (v) {
                return new Data_Tuple.Tuple(Data_PathTo.pathTo(dictPathTo)(v.value0), toFire(dictToFirebaseForeign)(v.value1));
            })($49));
        };
    };
};
var toFirebaseListOfTuples = function (dictPathTo) {
    return function (dictToFirebaseForeign) {
        return new ToFirebaseForeign(function ($50) {
            return Data_Foreign.toForeign(listOfTuplesToExportableMap(dictPathTo)(dictToFirebaseForeign)($50));
        });
    };
};
var keysToFirebaseMap = function (dictFunctor) {
    return function (dictFoldable) {
        return function (dictPathTo) {
            return function (strings) {
                return Data_Function.apply(Data_StrMap.fromFoldable(dictFoldable))(Data_Functor.map(dictFunctor)(function (s) {
                    return new Data_Tuple.Tuple(Data_PathTo.pathTo(dictPathTo)(s), true);
                })(strings));
            };
        };
    };
};
var toFirebaseArray = function (dictPathTo) {
    return new ToFirebaseForeign(function ($51) {
        return Data_Foreign.toForeign(keysToFirebaseMap(Data_Functor.functorArray)(Data_Foldable.foldableArray)(dictPathTo)($51));
    });
};
var isFirebaseString = new ToFirebaseForeign(Data_Foreign.toForeign);
var isFirebaseForeignKey = new ToFirebaseForeign(function (v) {
    return Data_Foreign.toForeign(v);
});
var isFirebaseForeignForeign = new ToFirebaseForeign(Control_Category.id(Control_Category.categoryFn));
var falseLeft = function (x) {
    if (x instanceof Data_Either.Left) {
        return false;
    };
    if (x instanceof Data_Either.Right) {
        return x.value0;
    };
    throw new Error("Failed pattern match at Data.Firebase.Foreign line 114, column 15 - line 116, column 16: " + [ x.constructor.name ]);
};
var falseLefts = function (xs) {
    return Data_Functor.map(Data_Functor.functorArray)(falseLeft)(xs);
};
var readBooleanKeysWithDefault = function ($$default) {
    return function (p) {
        return function (value) {
            var $42 = Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Index.prop(p)(value))(Data_Foreign_Keys.keys);
            if ($42 instanceof Data_Either.Left) {
                return new Data_Either.Right($$default);
            };
            if ($42 instanceof Data_Either.Right) {
                return new Data_Either.Right(Data_Functor.map(Data_Functor.functorArray)(function (key) {
                    return new Data_Tuple.Tuple(Data_ForeignKey.mkForeignKey(key), falseLeft(nestedValue(Data_Foreign_Class.booleanIsForeign)(value)(p)(key)));
                })($42.value0));
            };
            throw new Error("Failed pattern match at Data.Firebase.Foreign line 93, column 46 - line 95, column 104: " + [ $42.constructor.name ]);
        };
    };
};
var readFalseKeys = function (p) {
    return function (value) {
        var falseValues = function (xs) {
            return Data_Function.apply(Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.fst))(Data_Array.filter(function (v) {
                return !v.value1;
            })(xs));
        };
        return Data_Function.apply(Data_Bifunctor.rmap(Data_Either.bifunctorEither)(falseValues))(readBooleanKeysWithDefault([  ])(p)(value));
    };
};
var readTrueKeys = function (p) {
    return function (value) {
        var trueValues = function (xs) {
            return Data_Function.apply(Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.fst))(Data_Array.filter(Data_Tuple.snd)(xs));
        };
        return Data_Function.apply(Data_Bifunctor.rmap(Data_Either.bifunctorEither)(trueValues))(readBooleanKeysWithDefault([  ])(p)(value));
    };
};
var arrayOfStringsToFirebase = function (xs) {
    var t = function (a) {
        return function (i) {
            return new Data_Tuple.Tuple(Data_Show.show(Data_Show.showInt)(i), a);
        };
    };
    return Data_Function.apply(Data_Foreign.toForeign)(Data_Function.apply(Data_StrMap.fromFoldable(Data_List.foldableList))(Data_Function.apply(Data_List.mapWithIndex(t))(Data_List.fromFoldable(Data_Foldable.foldableArray)(xs))));
};
module.exports = {
    ToFirebaseForeign: ToFirebaseForeign, 
    arrayOfStringsToFirebase: arrayOfStringsToFirebase, 
    keysToFirebaseMap: keysToFirebaseMap, 
    mapToFirebaseMap: mapToFirebaseMap, 
    readBooleanKeysWithDefault: readBooleanKeysWithDefault, 
    readFalseKeys: readFalseKeys, 
    readKeyValues: readKeyValues, 
    readPropKeys: readPropKeys, 
    readPropKeysWithDefault: readPropKeysWithDefault, 
    readPropStringsWithDefault: readPropStringsWithDefault, 
    readStringArray: readStringArray, 
    readStringArrayFromObject: readStringArrayFromObject, 
    readTrueKeys: readTrueKeys, 
    readTrueStrings: readTrueStrings, 
    setToFirebaseMap: setToFirebaseMap, 
    toFire: toFire, 
    isFirebaseForeignForeign: isFirebaseForeignForeign, 
    isFirebaseForeignKey: isFirebaseForeignKey, 
    isFirebaseString: isFirebaseString, 
    toFirebaseForeignBoolean: toFirebaseForeignBoolean, 
    toFirebaseForeignInt: toFirebaseForeignInt, 
    toFirebaseMap: toFirebaseMap, 
    toFirebaseArray: toFirebaseArray, 
    toFirebaseListOfTuples: toFirebaseListOfTuples
};
