// Generated by psc version 0.9.1
"use strict";
var Control_Monad_Aff = require("../Control.Monad.Aff");
var Control_Monad_Eff = require("../Control.Monad.Eff");
var Prelude = require("../Prelude");
var Signal_Channel = require("../Signal.Channel");
var State_Types = require("../State.Types");
var Data_Function = require("../Data.Function");
var Control_Applicative = require("../Control.Applicative");
var Data_Semiring = require("../Data.Semiring");
var sendSuccessMessage = function (channel) {
    return function (message) {
        return Signal_Channel.send(channel)(Data_Function.apply(State_Types.NotifyUser.create)({
            level: State_Types.SuccessNotification.value, 
            message: message
        }));
    };
};
var sendStatusMessage = function (channel) {
    return function (message) {
        return Signal_Channel.send(channel)(Data_Function.apply(State_Types.NotifyUser.create)({
            level: State_Types.StatusNotification.value, 
            message: message
        }));
    };
};
var sendErrorMessage = function (channel) {
    return function (message) {
        return Signal_Channel.send(channel)(Data_Function.apply(State_Types.NotifyUser.create)({
            level: State_Types.ErrorNotification.value, 
            message: message
        }));
    };
};
var nullNotification = {
    level: State_Types.StatusNotification.value, 
    message: ""
};
var mkSuccessNotification = function (msg) {
    return Data_Function.apply(State_Types.UserNotification)({
        level: State_Types.SuccessNotification.value, 
        message: msg
    });
};
var setSuccessMessage = function (state) {
    return function (message) {
        var $2 = {};
        for (var $3 in state) {
            if (state.hasOwnProperty($3)) {
                $2[$3] = state[$3];
            };
        };
        $2.notification = mkSuccessNotification(message);
        return $2;
    };
};
var mkSuccessAction = function (msg) {
    return Data_Function.apply(State_Types.NotifyUser.create)({
        level: State_Types.SuccessNotification.value, 
        message: msg
    });
};
var mkStatusNotification = function (msg) {
    return Data_Function.apply(State_Types.UserNotification)({
        level: State_Types.StatusNotification.value, 
        message: msg
    });
};
var mkErrorNotification = function (msg) {
    return Data_Function.apply(State_Types.UserNotification)({
        level: State_Types.ErrorNotification.value, 
        message: msg
    });
};
var setErrorMessage = function (message) {
    return function (state) {
        var $4 = {};
        for (var $5 in state) {
            if (state.hasOwnProperty($5)) {
                $4[$5] = state[$5];
            };
        };
        $4.notification = mkErrorNotification(message);
        return $4;
    };
};
var setStatusMessage = function (message) {
    return function (state) {
        var $6 = {};
        for (var $7 in state) {
            if (state.hasOwnProperty($7)) {
                $6[$7] = state[$7];
            };
        };
        $6.notification = mkErrorNotification(message);
        return $6;
    };
};
var mkErrorAction = function (msg) {
    return Data_Function.apply(State_Types.NotifyUser.create)({
        level: State_Types.ErrorNotification.value, 
        message: msg
    });
};
var isHidden = function (v) {
    if (v.message === "") {
        return true;
    };
    return false;
};
var notifyUser = function (notification) {
    return function (state) {
        var newState = (function () {
            var $10 = {};
            for (var $11 in state) {
                if (state.hasOwnProperty($11)) {
                    $10[$11] = state[$11];
                };
            };
            $10.notification = notification;
            return $10;
        })();
        var hideNotification = Data_Function.apply(Control_Applicative.pure(Control_Monad_Aff.applicativeAff))(new State_Types.NotifyUser(nullNotification));
        var hideDelay = 10 * 1000 | 0;
        var hideNotificationLater = (function () {
            var $12 = isHidden(notification);
            if ($12) {
                return Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(State_Types.Noop.value);
            };
            if (!$12) {
                return Control_Monad_Aff["later'"](hideDelay)(hideNotification);
            };
            throw new Error("Failed pattern match at UReview.UserNotifications line 74, column 33 - line 74, column 112: " + [ $12.constructor.name ]);
        })();
        return {
            state: newState, 
            effects: [ hideNotificationLater ]
        };
    };
};
var getMessageText = function (v) {
    return v.message;
};
module.exports = {
    getMessageText: getMessageText, 
    mkErrorAction: mkErrorAction, 
    mkErrorNotification: mkErrorNotification, 
    mkStatusNotification: mkStatusNotification, 
    mkSuccessAction: mkSuccessAction, 
    mkSuccessNotification: mkSuccessNotification, 
    notifyUser: notifyUser, 
    nullNotification: nullNotification, 
    sendErrorMessage: sendErrorMessage, 
    sendStatusMessage: sendStatusMessage, 
    sendSuccessMessage: sendSuccessMessage, 
    setErrorMessage: setErrorMessage
};
