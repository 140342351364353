// Generated by psc version 0.9.1
"use strict";
var Model_Event = require("../Model.Event");
var Network_RemoteData = require("../Network.RemoteData");
var Prelude = require("../Prelude");
var Pux_Html = require("../Pux.Html");
var Pux_Html_Attributes = require("../Pux.Html.Attributes");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var Pux_Html_Events = require("../Pux.Html.Events");
var Route_HtmlElements = require("../Route.HtmlElements");
var State_Types = require("../State.Types");
var Data_Function = require("../Data.Function");
var UReview_Route_Types = require("../UReview.Route.Types");
var HTMLId = function (x) {
    return x;
};
var shortTextField = function (v) {
    return function (setter) {
        return function (questionText) {
            return function (currentValue) {
                return Pux_Html_Elements.div([  ])([ Pux_Html.withChild(Pux_Html.withAttr(Pux_Html_Elements.label)(Pux_Html_Attributes.htmlFor(v)))(Pux_Html_Elements.text(questionText)), Pux_Html.withChild(Pux_Html.withAttr(Pux_Html.withAttr(Pux_Html.withAttr(Pux_Html.withAttr(Pux_Html_Elements.input)(Pux_Html_Attributes.id_(v)))(Pux_Html_Attributes.type_("text")))(Pux_Html_Attributes.value(currentValue)))(Pux_Html_Events.onChange(State_Types.ChangedAddEventText.create(setter))))(Pux_Html_Elements.text(currentValue)) ]);
            };
        };
    };
};
var createButton = function (v) {
    return function (buttonText) {
        return Pux_Html.withChild(Pux_Html.withAttr(Pux_Html.withAttr(Pux_Html.withAttr(Pux_Html_Elements.button)(Pux_Html_Attributes.id_(v)))(Pux_Html_Attributes.className("mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent")))(Pux_Html_Events.onClick(Data_Function["const"](State_Types.AuthenticateGoogle.value))))(Pux_Html_Elements.text(buttonText));
    };
};
var signUp = Pux_Html_Elements.div([ Pux_Html_Attributes.className("column") ])([ Pux_Html_Elements.fieldset([  ])([ Pux_Html_Elements.legend([  ])([ Pux_Html_Elements.text("Identify yourself (not logged in yet)") ]), createButton("signUp")("Authenticate with Google") ]) ]);
var signupView = function (state) {
    if (state.userProfile instanceof Network_RemoteData.Success) {
        return Pux_Html_Elements.section([  ])([ Pux_Html_Elements.div([ Pux_Html_Attributes.className("row small-up-1") ])([ Pux_Html_Elements.div([ Pux_Html_Attributes.className("column") ])([ Pux_Html_Elements.p([  ])([ Pux_Html_Elements.text("You are authenticated.") ]) ]) ]) ]);
    };
    return Pux_Html_Elements.section([  ])([ Pux_Html_Elements.div([ Pux_Html_Attributes.className("row small-up-1") ])([ Pux_Html_Elements.div([ Pux_Html_Attributes.className("column") ])([ Pux_Html_Elements.p([  ])([ Pux_Html_Elements.text("Authenticate to WeReview using your google account") ]) ]), signUp ]), Pux_Html_Elements.div([ Pux_Html_Attributes.className("row small-up-1") ])([ Pux_Html_Elements.div([ Pux_Html_Attributes.className("column") ])([ Pux_Html_Elements.text("Or "), Route_HtmlElements.routeLink(UReview_Route_Types.Home.value)("propose a session without logging in") ]) ]) ]);
};
module.exports = {
    signupView: signupView
};
