// Generated by psc version 0.9.1
"use strict";
var Data_Firebase_Foreign = require("../Data.Firebase.Foreign");
var Data_Foreign = require("../Data.Foreign");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var Data_Generic = require("../Data.Generic");
var Test_QuickCheck_Arbitrary = require("../Test.QuickCheck.Arbitrary");
var Test_QuickCheck_Gen = require("../Test.QuickCheck.Gen");
var Prelude = require("../Prelude");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var Data_Functor = require("../Data.Functor");
var Data_Maybe = require("../Data.Maybe");
var Data_Eq = require("../Data.Eq");
var Control_Applicative = require("../Control.Applicative");
var Data_Show = require("../Data.Show");
var Data_Either = require("../Data.Either");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans");
var Data_Identity = require("../Data.Identity");
var InPerson = (function () {
    function InPerson() {

    };
    InPerson.value = new InPerson();
    return InPerson;
})();
var Online = (function () {
    function Online() {

    };
    Online.value = new Online();
    return Online;
})();
var verboseProposalForm = function (p) {
    if (p instanceof InPerson) {
        return "InPerson";
    };
    if (p instanceof Online) {
        return "Online";
    };
    throw new Error("Failed pattern match at Model.OpenCallForSessions.ProposalForm line 17, column 25 - line 21, column 1: " + [ p.constructor.name ]);
};
var toFirebaseForeignProposalForm = new Data_Firebase_Foreign.ToFirebaseForeign(function ($14) {
    return Data_Foreign.toForeign(verboseProposalForm($14));
});
var showProposalForm = new Data_Show.Show(verboseProposalForm);
var parseProposalForm = function (s) {
    if (s === "InPerson") {
        return InPerson.value;
    };
    if (s === "Online") {
        return Online.value;
    };
    return Online.value;
};
var isForeignProposalForm = new Data_Foreign_Class.IsForeign(function (value) {
    return Data_Functor.map(Data_Either.functorEither)(parseProposalForm)(Data_Foreign.readString(value));
});
var genericProposalForm = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.OpenCallForSessions.ProposalForm.InPerson" && v.value1.length === 0)) {
        return new Data_Maybe.Just(InPerson.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.OpenCallForSessions.ProposalForm.Online" && v.value1.length === 0)) {
        return new Data_Maybe.Just(Online.value);
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Model.OpenCallForSessions.ProposalForm.ProposalForm", [ {
        sigConstructor: "Model.OpenCallForSessions.ProposalForm.InPerson", 
        sigValues: [  ]
    }, {
        sigConstructor: "Model.OpenCallForSessions.ProposalForm.Online", 
        sigValues: [  ]
    } ]);
}, function (v) {
    if (v instanceof InPerson) {
        return new Data_Generic.SProd("Model.OpenCallForSessions.ProposalForm.InPerson", [  ]);
    };
    if (v instanceof Online) {
        return new Data_Generic.SProd("Model.OpenCallForSessions.ProposalForm.Online", [  ]);
    };
    throw new Error("Failed pattern match at Model.OpenCallForSessions.ProposalForm line 24, column 1 - line 24, column 60: " + [ v.constructor.name ]);
});
var eqProposalForm = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof InPerson && y instanceof InPerson) {
            return true;
        };
        if (x instanceof Online && y instanceof Online) {
            return true;
        };
        return false;
    };
});
var arbitraryProposalForm = new Test_QuickCheck_Arbitrary.Arbitrary(Test_QuickCheck_Gen.oneOf(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity))(InPerson.value))([ Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity))(Online.value) ]));
module.exports = {
    InPerson: InPerson, 
    Online: Online, 
    parseProposalForm: parseProposalForm, 
    verboseProposalForm: verboseProposalForm, 
    toFirebaseForeignProposalForm: toFirebaseForeignProposalForm, 
    isForeignProposalForm: isForeignProposalForm, 
    genericProposalForm: genericProposalForm, 
    eqProposalForm: eqProposalForm, 
    arbitraryProposalForm: arbitraryProposalForm, 
    showProposalForm: showProposalForm
};
