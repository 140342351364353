// Generated by psc version 0.9.1
"use strict";
var Data_Foldable = require("../Data.Foldable");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_HumanReadable = require("../Data.HumanReadable");
var Data_List = require("../Data.List");
var Data_Monoid = require("../Data.Monoid");
var Data_PleaseChooseYesNo = require("../Data.PleaseChooseYesNo");
var Data_String = require("../Data.String");
var Data_Themes = require("../Data.Themes");
var Model_Review = require("../Model.Review");
var Model_Expenses = require("../Model.Expenses");
var Model_SessionDescription = require("../Model.SessionDescription");
var Model_SpaLikeReviewVersion = require("../Model.SpaLikeReviewVersion");
var Prelude = require("../Prelude");
var State_Review = require("../State.Review");
var Data_Show = require("../Data.Show");
var Data_Function = require("../Data.Function");
var Data_Semigroup = require("../Data.Semigroup");
var Data_Semiring = require("../Data.Semiring");
var ReviewSummaryPostIt = function (x) {
    return x;
};
var ReviewSummaryFull = function (x) {
    return x;
};
var OnlineForm = (function () {
    function OnlineForm() {

    };
    OnlineForm.value = new OnlineForm();
    return OnlineForm;
})();
var OfflineForm = (function () {
    function OfflineForm() {

    };
    OfflineForm.value = new OfflineForm();
    return OfflineForm;
})();
var selectFormKind = function (v) {
    if (v.onlineFormat === "") {
        return OfflineForm.value;
    };
    return OnlineForm.value;
};
var reviewSummaryFullHeadings = [ "sessionid", "title", "abstract", "sessiondescription", "duration", "sessiontype", "cap", "live", "themes", "speakername", "speakerHowToAdress", "speaker1Pronouns", "speakerorg", "speakeremail", "twitter", "bio", "otherSpeakersCount", "otherspeakers", "speaker2name", "speaker2Pronouns", "speaker2org", "speaker2bio", "speaker2twitter", "speaker3name", "speaker3Pronouns", "speaker3org", "speaker3bio", "speaker3twitter", "timezone", "anythingElse", "recordingPermission", "expenses", "overallEvaluations", "ownConfidences", "comments", "isValid", "isVisible", "speaker2HowToAdress", "speaker3HowToAdress", "eventId" ];
var reviewSummaryFullAsArray = function (v) {
    return [ Data_Show.show(Data_Show.showInt)(v.sessionid), v.title, v["abstract"], v.sessiondescription, v.duration, v.sessiontype, v.capParticipants, v.onlineFormat, v.themes, v.speakername, v.speakerHowToAdress, v.speaker1Pronouns, v.speakerorg, v.speakeremail, v.twitter, v.bio, Data_Show.show(Data_Show.showInt)(v.otherSpeakersCount), v.otherspeakers, v.speaker2name, v.speaker2Pronouns, v.speaker2org, v.speaker2bio, v.speaker2twitter, v.speaker3name, v.speaker3Pronouns, v.speaker3org, v.speaker3bio, v.speaker3twitter, v.techrequirements, v.anythingElse, v.recordingPermission, v.expenses, v.overallEvaluations, v.ownConfidences, v.comments, Data_Show.show(Data_Show.showBoolean)(v.isValid), Data_Show.show(Data_Show.showBoolean)(v.isVisible), v.speaker2HowToAdress, v.speaker3HowToAdress, Data_ForeignKey.fkToString(v.eventId) ];
};
var ownConfidenceLensLike = function (v) {
    return (Model_SpaLikeReviewVersion.runSpaLikeReviewQuestion(v.reviewQuestions)).ownConfidence;
};
var overallEvaluationLensLike = function (v) {
    return (Model_SpaLikeReviewVersion.runSpaLikeReviewQuestion(v.reviewQuestions)).overallEvaluation;
};
var flattenExpenses = function (formKind) {
    return function (expenses) {
        var details = (function () {
            if (expenses instanceof Model_Expenses.ElaborateExpenses && expenses.value0 instanceof Model_Expenses.Yes) {
                return Data_HumanReadable.humanReadable(Model_Expenses.humanReadableDetail)(expenses.value0.value0);
            };
            return [  ];
        })();
        var optionalDetails = (function () {
            if (formKind instanceof OnlineForm) {
                return [  ];
            };
            if (formKind instanceof OfflineForm) {
                return details;
            };
            throw new Error("Failed pattern match at Model.ReviewSummary line 82, column 7 - line 84, column 31: " + [ formKind.constructor.name ]);
        })();
        return Data_Function.apply(Data_String.joinWith(" "))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_HumanReadable.humanReadable(Model_Expenses.humanReadableExpenses)(expenses))(optionalDetails));
    };
};
var countOtherSpeakers = function (v) {
    var countNonEmpty = function (name) {
        var $17 = Data_String.trim(name);
        if ($17 === "") {
            return 0;
        };
        return 1;
    };
    return countNonEmpty(v.speaker2name) + countNonEmpty(v.speaker3name) | 0;
};
var commentsLensLike = function (v) {
    return (Model_SpaLikeReviewVersion.runSpaLikeReviewQuestion(v.reviewQuestions)).comments;
};
var collateOneToFives = function (reviews) {
    return function (accessor) {
        return Data_Foldable.foldl(Data_List.foldableList)(function (acc) {
            return function (r) {
                return acc + Model_SpaLikeReviewVersion.editOneToFive(accessor(r));
            };
        })("")(reviews);
    };
};
var collateComments = function (reviews) {
    return Data_Foldable.foldl(Data_List.foldableList)(function (acc) {
        return function (r) {
            return acc + commentsLensLike(r);
        };
    })(" || ")(reviews);
};
var mkReviewSummaryFull = function (v) {
    return {
        title: v.sessionDescription.title, 
        speakername: v.sessionDescription.speakername, 
        otherSpeakersCount: countOtherSpeakers(v.sessionDescription), 
        speakerorg: v.sessionDescription.speakerorg, 
        duration: v.sessionDescription.duration, 
        sessiontype: v.sessionDescription.sessiontype, 
        capParticipants: v.sessionDescription.capParticipants, 
        onlineFormat: v.sessionDescription.onlineFormat, 
        themes: Data_Function.apply(Data_String.joinWith(" , "))(Data_Themes.toArray(v.sessionDescription.themes)), 
        expenses: flattenExpenses(selectFormKind(v.sessionDescription))(v.sessionDescription.expenses), 
        overallEvaluations: collateOneToFives(v.reviews)(overallEvaluationLensLike), 
        ownConfidences: collateOneToFives(v.reviews)(ownConfidenceLensLike), 
        comments: collateComments(v.reviews), 
        sessionid: v.sessionDescription.sessionid, 
        eventId: v.sessionDescription.eventId, 
        "abstract": v.sessionDescription["abstract"], 
        sessiondescription: v.sessionDescription.sessiondescription, 
        speakeremail: v.sessionDescription.speakeremail, 
        speakerHowToAdress: v.sessionDescription.speakerHowToAdress, 
        speaker1Pronouns: v.sessionDescription.speaker1Pronouns, 
        bio: v.sessionDescription.bio, 
        twitter: v.sessionDescription.twitter, 
        otherspeakers: v.sessionDescription.otherspeakers, 
        speaker2name: v.sessionDescription.speaker2name, 
        speaker2HowToAdress: v.sessionDescription.speaker2HowToAdress, 
        speaker2Pronouns: v.sessionDescription.speaker2Pronouns, 
        speaker2org: v.sessionDescription.speaker2org, 
        speaker2bio: v.sessionDescription.speaker2bio, 
        speaker2twitter: v.sessionDescription.speaker2twitter, 
        speaker3name: v.sessionDescription.speaker3name, 
        speaker3Pronouns: v.sessionDescription.speaker3Pronouns, 
        speaker3HowToAdress: v.sessionDescription.speaker3HowToAdress, 
        speaker3org: v.sessionDescription.speaker3org, 
        speaker3bio: v.sessionDescription.speaker3bio, 
        speaker3twitter: v.sessionDescription.speaker3twitter, 
        recordingPermission: Data_PleaseChooseYesNo.asString(v.sessionDescription.recordingPermission), 
        techrequirements: v.sessionDescription.techrequirements, 
        anythingElse: v.sessionDescription.anythingElse, 
        isValid: v.sessionDescription.isValid, 
        isVisible: v.sessionDescription.isVisible
    };
};
module.exports = {
    ReviewSummaryFull: ReviewSummaryFull, 
    mkReviewSummaryFull: mkReviewSummaryFull, 
    reviewSummaryFullAsArray: reviewSummaryFullAsArray, 
    reviewSummaryFullHeadings: reviewSummaryFullHeadings
};
