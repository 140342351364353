// Generated by psc version 0.9.1
"use strict";
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_Generic = require("../Data.Generic");
var Data_Monoid = require("../Data.Monoid");
var Data_PathTo = require("../Data.PathTo");
var Model_Event_Key = require("../Model.Event.Key");
var Model_Event_Tokens = require("../Model.Event.Tokens");
var Model_GlobalRole_Tokens = require("../Model.GlobalRole.Tokens");
var Model_OpenCallForSessions_Tokens = require("../Model.OpenCallForSessions.Tokens");
var Model_Review = require("../Model.Review");
var Model_Reviewer = require("../Model.Reviewer");
var Model_Reviewer_Tokens = require("../Model.Reviewer.Tokens");
var Model_SessionDescription_Tokens = require("../Model.SessionDescription.Tokens");
var Model_UserProfile = require("../Model.UserProfile");
var Prelude = require("../Prelude");
var Control_Apply = require("../Control.Apply");
var Data_Maybe = require("../Data.Maybe");
var Data_Unit = require("../Data.Unit");
var Data_Show = require("../Data.Show");
var Data_Eq = require("../Data.Eq");
var SessionDescriptionObject = (function () {
    function SessionDescriptionObject() {

    };
    SessionDescriptionObject.value = new SessionDescriptionObject();
    return SessionDescriptionObject;
})();
var IsValid = (function () {
    function IsValid() {

    };
    IsValid.value = new IsValid();
    return IsValid;
})();
var IsVisible = (function () {
    function IsVisible() {

    };
    IsVisible.value = new IsVisible();
    return IsVisible;
})();
var IsReviewer = (function () {
    function IsReviewer() {

    };
    IsReviewer.value = new IsReviewer();
    return IsReviewer;
})();
var ReviewerFor = (function () {
    function ReviewerFor(value0) {
        this.value0 = value0;
    };
    ReviewerFor.create = function (value0) {
        return new ReviewerFor(value0);
    };
    return ReviewerFor;
})();
var PanelChairFor = (function () {
    function PanelChairFor(value0) {
        this.value0 = value0;
    };
    PanelChairFor.create = function (value0) {
        return new PanelChairFor(value0);
    };
    return PanelChairFor;
})();
var RoleObject = (function () {
    function RoleObject() {

    };
    RoleObject.value = new RoleObject();
    return RoleObject;
})();
var IsAdministrator = (function () {
    function IsAdministrator() {

    };
    IsAdministrator.value = new IsAdministrator();
    return IsAdministrator;
})();
var ReviewKeys = (function () {
    function ReviewKeys(value0) {
        this.value0 = value0;
    };
    ReviewKeys.create = function (value0) {
        return new ReviewKeys(value0);
    };
    return ReviewKeys;
})();
var OpenCfSObject = (function () {
    function OpenCfSObject() {

    };
    OpenCfSObject.value = new OpenCfSObject();
    return OpenCfSObject;
})();
var CfSPublicationStatus = (function () {
    function CfSPublicationStatus() {

    };
    CfSPublicationStatus.value = new CfSPublicationStatus();
    return CfSPublicationStatus;
})();
var CfSProposalForm = (function () {
    function CfSProposalForm() {

    };
    CfSProposalForm.value = new CfSProposalForm();
    return CfSProposalForm;
})();
var CfSInvitation = (function () {
    function CfSInvitation() {

    };
    CfSInvitation.value = new CfSInvitation();
    return CfSInvitation;
})();
var CfSExampleThemes = (function () {
    function CfSExampleThemes() {

    };
    CfSExampleThemes.value = new CfSExampleThemes();
    return CfSExampleThemes;
})();
var Reviewers = (function () {
    function Reviewers() {

    };
    Reviewers.value = new Reviewers();
    return Reviewers;
})();
var Reviewer = (function () {
    function Reviewer(value0) {
        this.value0 = value0;
    };
    Reviewer.create = function (value0) {
        return new Reviewer(value0);
    };
    return Reviewer;
})();
var PanelChairs = (function () {
    function PanelChairs() {

    };
    PanelChairs.value = new PanelChairs();
    return PanelChairs;
})();
var PanelChair = (function () {
    function PanelChair(value0) {
        this.value0 = value0;
    };
    PanelChair.create = function (value0) {
        return new PanelChair(value0);
    };
    return PanelChair;
})();
var SessionDescriptionKeys = (function () {
    function SessionDescriptionKeys() {

    };
    SessionDescriptionKeys.value = new SessionDescriptionKeys();
    return SessionDescriptionKeys;
})();
var EventObject = (function () {
    function EventObject() {

    };
    EventObject.value = new EventObject();
    return EventObject;
})();
var CreateReview = (function () {
    function CreateReview() {

    };
    CreateReview.value = new CreateReview();
    return CreateReview;
})();
var SessionDescriptions = (function () {
    function SessionDescriptions(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    SessionDescriptions.create = function (value0) {
        return function (value1) {
            return new SessionDescriptions(value0, value1);
        };
    };
    return SessionDescriptions;
})();
var OpenCallForSessions = (function () {
    function OpenCallForSessions(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    OpenCallForSessions.create = function (value0) {
        return function (value1) {
            return new OpenCallForSessions(value0, value1);
        };
    };
    return OpenCallForSessions;
})();
var AllOpenCalls = (function () {
    function AllOpenCalls() {

    };
    AllOpenCalls.value = new AllOpenCalls();
    return AllOpenCalls;
})();
var Roles = (function () {
    function Roles(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Roles.create = function (value0) {
        return function (value1) {
            return new Roles(value0, value1);
        };
    };
    return Roles;
})();
var Events = (function () {
    function Events(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Events.create = function (value0) {
        return function (value1) {
            return new Events(value0, value1);
        };
    };
    return Events;
})();
var AllEvents = (function () {
    function AllEvents() {

    };
    AllEvents.value = new AllEvents();
    return AllEvents;
})();
var AllReviewers = (function () {
    function AllReviewers() {

    };
    AllReviewers.value = new AllReviewers();
    return AllReviewers;
})();
var AllUsers = (function () {
    function AllUsers() {

    };
    AllUsers.value = new AllUsers();
    return AllUsers;
})();
var AllReviews = (function () {
    function AllReviews() {

    };
    AllReviews.value = new AllReviews();
    return AllReviews;
})();
var Users = (function () {
    function Users(value0) {
        this.value0 = value0;
    };
    Users.create = function (value0) {
        return new Users(value0);
    };
    return Users;
})();
var ForbiddenBorkedForTest = (function () {
    function ForbiddenBorkedForTest() {

    };
    ForbiddenBorkedForTest.value = new ForbiddenBorkedForTest();
    return ForbiddenBorkedForTest;
})();
var ReviewersObjects = (function () {
    function ReviewersObjects(value0) {
        this.value0 = value0;
    };
    ReviewersObjects.create = function (value0) {
        return new ReviewersObjects(value0);
    };
    return ReviewersObjects;
})();
var ReviewersForReviews = (function () {
    function ReviewersForReviews(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ReviewersForReviews.create = function (value0) {
        return function (value1) {
            return new ReviewersForReviews(value0, value1);
        };
    };
    return ReviewersForReviews;
})();
var Reviews = (function () {
    function Reviews(value0) {
        this.value0 = value0;
    };
    Reviews.create = function (value0) {
        return new Reviews(value0);
    };
    return Reviews;
})();
var Command = (function () {
    function Command(value0) {
        this.value0 = value0;
    };
    Command.create = function (value0) {
        return new Command(value0);
    };
    return Command;
})();
var usersToken = "users";
var reviewsToken = "/reviews";
var pathToSessionDescriptionObject = new Data_PathTo.PathTo(function (s) {
    if (s instanceof IsValid) {
        return Model_SessionDescription_Tokens.isValidToken;
    };
    if (s instanceof IsVisible) {
        return Model_SessionDescription_Tokens.isVisibleToken;
    };
    if (s instanceof SessionDescriptionObject) {
        return Data_Monoid.mempty(Data_Monoid.monoidString);
    };
    throw new Error("Failed pattern match at WeReview.Data.Schema line 123, column 14 - line 126, column 39: " + [ s.constructor.name ]);
});
var pathToRoles = new Data_PathTo.PathTo(function (s) {
    if (s instanceof IsReviewer) {
        return Model_GlobalRole_Tokens.reviewerToken;
    };
    if (s instanceof ReviewerFor) {
        return Data_PathTo.forwardSlash(Model_GlobalRole_Tokens.reviewerForToken)(s.value0);
    };
    if (s instanceof PanelChairFor) {
        return Data_PathTo.forwardSlash(Model_GlobalRole_Tokens.panelChairForToken)(Data_PathTo.pathTo(Data_ForeignKey.pathToForeignKey)(s.value0));
    };
    if (s instanceof RoleObject) {
        return Data_Monoid.mempty(Data_Monoid.monoidString);
    };
    if (s instanceof IsAdministrator) {
        return Model_GlobalRole_Tokens.administratorToken;
    };
    throw new Error("Failed pattern match at WeReview.Data.Schema line 134, column 14 - line 139, column 42: " + [ s.constructor.name ]);
});
var pathToReviews = new Data_PathTo.PathTo(function (s) {
    return Data_PathTo.forwardSlash(reviewsToken)(s.value0);
});
var pathToEvents = new Data_PathTo.PathTo(function (s) {
    if (s instanceof PanelChairs) {
        return Model_Event_Tokens.panelChairsToken;
    };
    if (s instanceof PanelChair) {
        return Data_PathTo.forwardSlash(Model_Event_Tokens.panelChairsToken)(Data_PathTo.pathTo(Model_UserProfile.pathToUserKey)(s.value0));
    };
    if (s instanceof Reviewers) {
        return Model_Reviewer_Tokens.reviewersToken;
    };
    if (s instanceof Reviewer) {
        return Data_PathTo.forwardSlash(Model_Reviewer_Tokens.reviewersToken)(s.value0);
    };
    if (s instanceof SessionDescriptionKeys) {
        return Model_SessionDescription_Tokens.sessionDescriptionsToken;
    };
    if (s instanceof EventObject) {
        return Data_Monoid.mempty(Data_Monoid.monoidString);
    };
    throw new Error("Failed pattern match at WeReview.Data.Schema line 147, column 14 - line 153, column 26: " + [ s.constructor.name ]);
});
var pathToCfsRule = new Data_PathTo.PathTo(function (v) {
    if (v instanceof OpenCfSObject) {
        return Data_Monoid.mempty(Data_Monoid.monoidString);
    };
    if (v instanceof CfSPublicationStatus) {
        return Model_OpenCallForSessions_Tokens.publicationStatusToken;
    };
    if (v instanceof CfSProposalForm) {
        return Model_OpenCallForSessions_Tokens.proposalFormToken;
    };
    if (v instanceof CfSInvitation) {
        return Model_OpenCallForSessions_Tokens.invitationToken;
    };
    if (v instanceof CfSExampleThemes) {
        return Data_PathTo.forwardSlash(Model_OpenCallForSessions_Tokens.adaptedFieldsToken)("coreFields/themes/examples");
    };
    throw new Error("Failed pattern match at WeReview.Data.Schema line 108, column 3 - line 108, column 32: " + [ v.constructor.name ]);
});
var genericSessionDescriptionObject = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.SessionDescriptionObject" && v.value1.length === 0)) {
        return new Data_Maybe.Just(SessionDescriptionObject.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.IsValid" && v.value1.length === 0)) {
        return new Data_Maybe.Just(IsValid.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.IsVisible" && v.value1.length === 0)) {
        return new Data_Maybe.Just(IsVisible.value);
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("WeReview.Data.Schema.SessionDescriptionObject", [ {
        sigConstructor: "WeReview.Data.Schema.SessionDescriptionObject", 
        sigValues: [  ]
    }, {
        sigConstructor: "WeReview.Data.Schema.IsValid", 
        sigValues: [  ]
    }, {
        sigConstructor: "WeReview.Data.Schema.IsVisible", 
        sigValues: [  ]
    } ]);
}, function (v) {
    if (v instanceof SessionDescriptionObject) {
        return new Data_Generic.SProd("WeReview.Data.Schema.SessionDescriptionObject", [  ]);
    };
    if (v instanceof IsValid) {
        return new Data_Generic.SProd("WeReview.Data.Schema.IsValid", [  ]);
    };
    if (v instanceof IsVisible) {
        return new Data_Generic.SProd("WeReview.Data.Schema.IsVisible", [  ]);
    };
    throw new Error("Failed pattern match at WeReview.Data.Schema line 114, column 1 - line 114, column 84: " + [ v.constructor.name ]);
});
var showSessionDescriptionObject = new Data_Show.Show(Data_Generic.gShow(genericSessionDescriptionObject));
var genericRolesRule = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.IsReviewer" && v.value1.length === 0)) {
        return new Data_Maybe.Just(IsReviewer.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.ReviewerFor" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(ReviewerFor.create))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)(v.value1[0](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.PanelChairFor" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(PanelChairFor.create))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)(v.value1[0](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.RoleObject" && v.value1.length === 0)) {
        return new Data_Maybe.Just(RoleObject.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.IsAdministrator" && v.value1.length === 0)) {
        return new Data_Maybe.Just(IsAdministrator.value);
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("WeReview.Data.Schema.RolesRule", [ {
        sigConstructor: "WeReview.Data.Schema.IsReviewer", 
        sigValues: [  ]
    }, {
        sigConstructor: "WeReview.Data.Schema.ReviewerFor", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "WeReview.Data.Schema.PanelChairFor", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "WeReview.Data.Schema.RoleObject", 
        sigValues: [  ]
    }, {
        sigConstructor: "WeReview.Data.Schema.IsAdministrator", 
        sigValues: [  ]
    } ]);
}, function (v) {
    if (v instanceof IsReviewer) {
        return new Data_Generic.SProd("WeReview.Data.Schema.IsReviewer", [  ]);
    };
    if (v instanceof ReviewerFor) {
        return new Data_Generic.SProd("WeReview.Data.Schema.ReviewerFor", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v.value0);
        } ]);
    };
    if (v instanceof PanelChairFor) {
        return new Data_Generic.SProd("WeReview.Data.Schema.PanelChairFor", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v.value0);
        } ]);
    };
    if (v instanceof RoleObject) {
        return new Data_Generic.SProd("WeReview.Data.Schema.RoleObject", [  ]);
    };
    if (v instanceof IsAdministrator) {
        return new Data_Generic.SProd("WeReview.Data.Schema.IsAdministrator", [  ]);
    };
    throw new Error("Failed pattern match at WeReview.Data.Schema line 128, column 1 - line 128, column 54: " + [ v.constructor.name ]);
});
var showRolesRule = new Data_Show.Show(Data_Generic.gShow(genericRolesRule));
var genericReviews = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.ReviewKeys" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(ReviewKeys.create))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("WeReview.Data.Schema.ReviewKeys", [ {
        sigConstructor: "WeReview.Data.Schema.ReviewKeys", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("WeReview.Data.Schema.ReviewKeys", [ function ($dollarq) {
        return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v.value0);
    } ]);
});
var showReviews = new Data_Show.Show(Data_Generic.gShow(genericReviews));
var genericEventsRule = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.Reviewers" && v.value1.length === 0)) {
        return new Data_Maybe.Just(Reviewers.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.Reviewer" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Reviewer.create))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)(v.value1[0](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.PanelChairs" && v.value1.length === 0)) {
        return new Data_Maybe.Just(PanelChairs.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.PanelChair" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(PanelChair.create))(Data_Generic.fromSpine(Model_UserProfile.genericUserKey)(v.value1[0](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.SessionDescriptionKeys" && v.value1.length === 0)) {
        return new Data_Maybe.Just(SessionDescriptionKeys.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.EventObject" && v.value1.length === 0)) {
        return new Data_Maybe.Just(EventObject.value);
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("WeReview.Data.Schema.EventsRule", [ {
        sigConstructor: "WeReview.Data.Schema.Reviewers", 
        sigValues: [  ]
    }, {
        sigConstructor: "WeReview.Data.Schema.Reviewer", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "WeReview.Data.Schema.PanelChairs", 
        sigValues: [  ]
    }, {
        sigConstructor: "WeReview.Data.Schema.PanelChair", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Model_UserProfile.genericUserKey)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "WeReview.Data.Schema.SessionDescriptionKeys", 
        sigValues: [  ]
    }, {
        sigConstructor: "WeReview.Data.Schema.EventObject", 
        sigValues: [  ]
    } ]);
}, function (v) {
    if (v instanceof Reviewers) {
        return new Data_Generic.SProd("WeReview.Data.Schema.Reviewers", [  ]);
    };
    if (v instanceof Reviewer) {
        return new Data_Generic.SProd("WeReview.Data.Schema.Reviewer", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v.value0);
        } ]);
    };
    if (v instanceof PanelChairs) {
        return new Data_Generic.SProd("WeReview.Data.Schema.PanelChairs", [  ]);
    };
    if (v instanceof PanelChair) {
        return new Data_Generic.SProd("WeReview.Data.Schema.PanelChair", [ function ($dollarq) {
            return Data_Generic.toSpine(Model_UserProfile.genericUserKey)(v.value0);
        } ]);
    };
    if (v instanceof SessionDescriptionKeys) {
        return new Data_Generic.SProd("WeReview.Data.Schema.SessionDescriptionKeys", [  ]);
    };
    if (v instanceof EventObject) {
        return new Data_Generic.SProd("WeReview.Data.Schema.EventObject", [  ]);
    };
    throw new Error("Failed pattern match at WeReview.Data.Schema line 141, column 1 - line 141, column 56: " + [ v.constructor.name ]);
});
var showEventsRule = new Data_Show.Show(Data_Generic.gShow(genericEventsRule));
var genericCommand = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.CreateReview" && v.value1.length === 0)) {
        return new Data_Maybe.Just(CreateReview.value);
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("WeReview.Data.Schema.CommandRule", [ {
        sigConstructor: "WeReview.Data.Schema.CreateReview", 
        sigValues: [  ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("WeReview.Data.Schema.CreateReview", [  ]);
});
var showCommand = new Data_Show.Show(Data_Generic.gShow(genericCommand));
var genericCfsRule = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.OpenCfSObject" && v.value1.length === 0)) {
        return new Data_Maybe.Just(OpenCfSObject.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.CfSPublicationStatus" && v.value1.length === 0)) {
        return new Data_Maybe.Just(CfSPublicationStatus.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.CfSProposalForm" && v.value1.length === 0)) {
        return new Data_Maybe.Just(CfSProposalForm.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.CfSInvitation" && v.value1.length === 0)) {
        return new Data_Maybe.Just(CfSInvitation.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.CfSExampleThemes" && v.value1.length === 0)) {
        return new Data_Maybe.Just(CfSExampleThemes.value);
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("WeReview.Data.Schema.OpenCfSRule", [ {
        sigConstructor: "WeReview.Data.Schema.OpenCfSObject", 
        sigValues: [  ]
    }, {
        sigConstructor: "WeReview.Data.Schema.CfSPublicationStatus", 
        sigValues: [  ]
    }, {
        sigConstructor: "WeReview.Data.Schema.CfSProposalForm", 
        sigValues: [  ]
    }, {
        sigConstructor: "WeReview.Data.Schema.CfSInvitation", 
        sigValues: [  ]
    }, {
        sigConstructor: "WeReview.Data.Schema.CfSExampleThemes", 
        sigValues: [  ]
    } ]);
}, function (v) {
    if (v instanceof OpenCfSObject) {
        return new Data_Generic.SProd("WeReview.Data.Schema.OpenCfSObject", [  ]);
    };
    if (v instanceof CfSPublicationStatus) {
        return new Data_Generic.SProd("WeReview.Data.Schema.CfSPublicationStatus", [  ]);
    };
    if (v instanceof CfSProposalForm) {
        return new Data_Generic.SProd("WeReview.Data.Schema.CfSProposalForm", [  ]);
    };
    if (v instanceof CfSInvitation) {
        return new Data_Generic.SProd("WeReview.Data.Schema.CfSInvitation", [  ]);
    };
    if (v instanceof CfSExampleThemes) {
        return new Data_Generic.SProd("WeReview.Data.Schema.CfSExampleThemes", [  ]);
    };
    throw new Error("Failed pattern match at WeReview.Data.Schema line 102, column 1 - line 102, column 54: " + [ v.constructor.name ]);
});
var genericWeReviewSchema = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.SessionDescriptions" && v.value1.length === 2)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(SessionDescriptions.create))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)(v.value1[0](Data_Unit.unit))))(Data_Generic.fromSpine(genericSessionDescriptionObject)(v.value1[1](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.OpenCallForSessions" && v.value1.length === 2)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(OpenCallForSessions.create))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)(v.value1[0](Data_Unit.unit))))(Data_Generic.fromSpine(genericCfsRule)(v.value1[1](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.AllOpenCalls" && v.value1.length === 0)) {
        return new Data_Maybe.Just(AllOpenCalls.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.Roles" && v.value1.length === 2)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Roles.create))(Data_Generic.fromSpine(Model_UserProfile.genericUserKey)(v.value1[0](Data_Unit.unit))))(Data_Generic.fromSpine(genericRolesRule)(v.value1[1](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.Events" && v.value1.length === 2)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Events.create))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)(v.value1[0](Data_Unit.unit))))(Data_Generic.fromSpine(genericEventsRule)(v.value1[1](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.AllEvents" && v.value1.length === 0)) {
        return new Data_Maybe.Just(AllEvents.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.AllReviewers" && v.value1.length === 0)) {
        return new Data_Maybe.Just(AllReviewers.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.AllUsers" && v.value1.length === 0)) {
        return new Data_Maybe.Just(AllUsers.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.AllReviews" && v.value1.length === 0)) {
        return new Data_Maybe.Just(AllReviews.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.Users" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Users.create))(Data_Generic.fromSpine(Model_UserProfile.genericUserKey)(v.value1[0](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.ForbiddenBorkedForTest" && v.value1.length === 0)) {
        return new Data_Maybe.Just(ForbiddenBorkedForTest.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.ReviewersObjects" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(ReviewersObjects.create))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)(v.value1[0](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.ReviewersForReviews" && v.value1.length === 2)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(ReviewersForReviews.create))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)(v.value1[0](Data_Unit.unit))))(Data_Generic.fromSpine(genericReviews)(v.value1[1](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.Reviews" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Reviews.create))(Data_Generic.fromSpine(Model_Review.genericReviewKey)(v.value1[0](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "WeReview.Data.Schema.Command" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Command.create))(Data_Generic.fromSpine(genericCommand)(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("WeReview.Data.Schema.WeReviewSchema", [ {
        sigConstructor: "WeReview.Data.Schema.SessionDescriptions", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
        }, function ($dollarq1) {
            return Data_Generic.toSignature(genericSessionDescriptionObject)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "WeReview.Data.Schema.OpenCallForSessions", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
        }, function ($dollarq1) {
            return Data_Generic.toSignature(genericCfsRule)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "WeReview.Data.Schema.AllOpenCalls", 
        sigValues: [  ]
    }, {
        sigConstructor: "WeReview.Data.Schema.Roles", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Model_UserProfile.genericUserKey)(Data_Generic.anyProxy);
        }, function ($dollarq1) {
            return Data_Generic.toSignature(genericRolesRule)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "WeReview.Data.Schema.Events", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
        }, function ($dollarq1) {
            return Data_Generic.toSignature(genericEventsRule)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "WeReview.Data.Schema.AllEvents", 
        sigValues: [  ]
    }, {
        sigConstructor: "WeReview.Data.Schema.AllReviewers", 
        sigValues: [  ]
    }, {
        sigConstructor: "WeReview.Data.Schema.AllUsers", 
        sigValues: [  ]
    }, {
        sigConstructor: "WeReview.Data.Schema.AllReviews", 
        sigValues: [  ]
    }, {
        sigConstructor: "WeReview.Data.Schema.Users", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Model_UserProfile.genericUserKey)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "WeReview.Data.Schema.ForbiddenBorkedForTest", 
        sigValues: [  ]
    }, {
        sigConstructor: "WeReview.Data.Schema.ReviewersObjects", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "WeReview.Data.Schema.ReviewersForReviews", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
        }, function ($dollarq1) {
            return Data_Generic.toSignature(genericReviews)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "WeReview.Data.Schema.Reviews", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Model_Review.genericReviewKey)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "WeReview.Data.Schema.Command", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(genericCommand)(Data_Generic.anyProxy);
        } ]
    } ]);
}, function (v) {
    if (v instanceof SessionDescriptions) {
        return new Data_Generic.SProd("WeReview.Data.Schema.SessionDescriptions", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v.value0);
        }, function ($dollarq) {
            return Data_Generic.toSpine(genericSessionDescriptionObject)(v.value1);
        } ]);
    };
    if (v instanceof OpenCallForSessions) {
        return new Data_Generic.SProd("WeReview.Data.Schema.OpenCallForSessions", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v.value0);
        }, function ($dollarq) {
            return Data_Generic.toSpine(genericCfsRule)(v.value1);
        } ]);
    };
    if (v instanceof AllOpenCalls) {
        return new Data_Generic.SProd("WeReview.Data.Schema.AllOpenCalls", [  ]);
    };
    if (v instanceof Roles) {
        return new Data_Generic.SProd("WeReview.Data.Schema.Roles", [ function ($dollarq) {
            return Data_Generic.toSpine(Model_UserProfile.genericUserKey)(v.value0);
        }, function ($dollarq) {
            return Data_Generic.toSpine(genericRolesRule)(v.value1);
        } ]);
    };
    if (v instanceof Events) {
        return new Data_Generic.SProd("WeReview.Data.Schema.Events", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v.value0);
        }, function ($dollarq) {
            return Data_Generic.toSpine(genericEventsRule)(v.value1);
        } ]);
    };
    if (v instanceof AllEvents) {
        return new Data_Generic.SProd("WeReview.Data.Schema.AllEvents", [  ]);
    };
    if (v instanceof AllReviewers) {
        return new Data_Generic.SProd("WeReview.Data.Schema.AllReviewers", [  ]);
    };
    if (v instanceof AllUsers) {
        return new Data_Generic.SProd("WeReview.Data.Schema.AllUsers", [  ]);
    };
    if (v instanceof AllReviews) {
        return new Data_Generic.SProd("WeReview.Data.Schema.AllReviews", [  ]);
    };
    if (v instanceof Users) {
        return new Data_Generic.SProd("WeReview.Data.Schema.Users", [ function ($dollarq) {
            return Data_Generic.toSpine(Model_UserProfile.genericUserKey)(v.value0);
        } ]);
    };
    if (v instanceof ForbiddenBorkedForTest) {
        return new Data_Generic.SProd("WeReview.Data.Schema.ForbiddenBorkedForTest", [  ]);
    };
    if (v instanceof ReviewersObjects) {
        return new Data_Generic.SProd("WeReview.Data.Schema.ReviewersObjects", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v.value0);
        } ]);
    };
    if (v instanceof ReviewersForReviews) {
        return new Data_Generic.SProd("WeReview.Data.Schema.ReviewersForReviews", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v.value0);
        }, function ($dollarq) {
            return Data_Generic.toSpine(genericReviews)(v.value1);
        } ]);
    };
    if (v instanceof Reviews) {
        return new Data_Generic.SProd("WeReview.Data.Schema.Reviews", [ function ($dollarq) {
            return Data_Generic.toSpine(Model_Review.genericReviewKey)(v.value0);
        } ]);
    };
    if (v instanceof Command) {
        return new Data_Generic.SProd("WeReview.Data.Schema.Command", [ function ($dollarq) {
            return Data_Generic.toSpine(genericCommand)(v.value0);
        } ]);
    };
    throw new Error("Failed pattern match at WeReview.Data.Schema line 75, column 1 - line 75, column 64: " + [ v.constructor.name ]);
});
var showWeReviewSchema = new Data_Show.Show(Data_Generic.gShow(genericWeReviewSchema));
var showCfsRule = new Data_Show.Show(Data_Generic.gShow(genericCfsRule));
var currentCfSToken = "currentCfS";
var createReviewToken = "CreateReview";
var pathToCommand = new Data_PathTo.PathTo(function (s) {
    return createReviewToken;
});
var commandPath = "/eventsource/command/";
var pathToWeReviewSchema = new Data_PathTo.PathTo(function (s) {
    return Data_PathTo.forwardSlash(Data_Monoid.mempty(Data_Monoid.monoidString))((function () {
        if (s instanceof OpenCallForSessions) {
            return Data_PathTo.forwardSlash(currentCfSToken)(Data_PathTo.forwardSlash(s.value0)(Data_PathTo.pathTo(pathToCfsRule)(s.value1)));
        };
        if (s instanceof SessionDescriptions) {
            return Data_PathTo.forwardSlash(Model_SessionDescription_Tokens.sessionDescriptionsToken)(Data_PathTo.forwardSlash(s.value0)(Data_PathTo.pathTo(pathToSessionDescriptionObject)(s.value1)));
        };
        if (s instanceof Roles) {
            return Data_PathTo.forwardSlash(Model_GlobalRole_Tokens.rolesToken)(Data_PathTo.forwardSlash(Data_PathTo.pathTo(Model_UserProfile.pathToUserKey)(s.value0))(Data_PathTo.pathTo(pathToRoles)(s.value1)));
        };
        if (s instanceof Events && s.value1 instanceof EventObject) {
            return Data_PathTo.forwardSlash(Model_Event_Tokens.eventsToken)(s.value0);
        };
        if (s instanceof Events) {
            return Data_PathTo.forwardSlash(Model_Event_Tokens.eventsToken)(Data_PathTo.forwardSlash(s.value0)(Data_PathTo.pathTo(pathToEvents)(s.value1)));
        };
        if (s instanceof AllEvents) {
            return Model_Event_Tokens.eventsToken;
        };
        if (s instanceof AllOpenCalls) {
            return currentCfSToken;
        };
        if (s instanceof AllReviewers) {
            return Model_Reviewer_Tokens.reviewersToken;
        };
        if (s instanceof AllReviews) {
            return reviewsToken;
        };
        if (s instanceof AllUsers) {
            return usersToken;
        };
        if (s instanceof ForbiddenBorkedForTest) {
            return "forbidden";
        };
        if (s instanceof Users) {
            return Data_PathTo.forwardSlash(usersToken)(Data_PathTo.pathTo(Model_UserProfile.pathToUserKey)(s.value0));
        };
        if (s instanceof ReviewersObjects) {
            return Data_PathTo.forwardSlash(Model_Reviewer_Tokens.reviewersToken)(s.value0);
        };
        if (s instanceof ReviewersForReviews) {
            return Data_PathTo.forwardSlash(Model_Reviewer_Tokens.reviewersToken)(Data_PathTo.forwardSlash(Data_PathTo.pathTo(Data_ForeignKey.pathToForeignKey)(s.value0))(Data_PathTo.pathTo(pathToReviews)(s.value1)));
        };
        if (s instanceof Reviews) {
            return Data_PathTo.forwardSlash(reviewsToken)(Data_PathTo.pathTo(Model_Review.pathToReviewKey)(s.value0));
        };
        if (s instanceof Command) {
            return Data_PathTo.forwardSlash(commandPath)(Data_PathTo.pathTo(pathToCommand)(s.value0));
        };
        throw new Error("Failed pattern match at WeReview.Data.Schema line 84, column 14 - line 100, column 44: " + [ s.constructor.name ]);
    })());
});
var eqWeReviewSchema = new Data_Eq.Eq(function (a) {
    return function (b) {
        return Data_PathTo.pathTo(pathToWeReviewSchema)(a) === Data_PathTo.pathTo(pathToWeReviewSchema)(b);
    };
});
var anOpenCfSSchema = function (k) {
    return new OpenCallForSessions(k, OpenCfSObject.value);
};
var aPublicationStatusSchema = function (k) {
    return new OpenCallForSessions(k, CfSPublicationStatus.value);
};
var aProposalFormSchema = function (k) {
    return new OpenCallForSessions(k, CfSProposalForm.value);
};
var aCfSThemesSchema = function (k) {
    return new OpenCallForSessions(k, CfSExampleThemes.value);
};
var aCfSInvitationSchema = function (k) {
    return new OpenCallForSessions(k, CfSInvitation.value);
};
module.exports = {
    CreateReview: CreateReview, 
    Reviewers: Reviewers, 
    Reviewer: Reviewer, 
    PanelChairs: PanelChairs, 
    PanelChair: PanelChair, 
    SessionDescriptionKeys: SessionDescriptionKeys, 
    EventObject: EventObject, 
    OpenCfSObject: OpenCfSObject, 
    CfSPublicationStatus: CfSPublicationStatus, 
    CfSProposalForm: CfSProposalForm, 
    CfSInvitation: CfSInvitation, 
    CfSExampleThemes: CfSExampleThemes, 
    ReviewKeys: ReviewKeys, 
    IsReviewer: IsReviewer, 
    ReviewerFor: ReviewerFor, 
    PanelChairFor: PanelChairFor, 
    RoleObject: RoleObject, 
    IsAdministrator: IsAdministrator, 
    SessionDescriptionObject: SessionDescriptionObject, 
    IsValid: IsValid, 
    IsVisible: IsVisible, 
    SessionDescriptions: SessionDescriptions, 
    OpenCallForSessions: OpenCallForSessions, 
    AllOpenCalls: AllOpenCalls, 
    Roles: Roles, 
    Events: Events, 
    AllEvents: AllEvents, 
    AllReviewers: AllReviewers, 
    AllUsers: AllUsers, 
    AllReviews: AllReviews, 
    Users: Users, 
    ForbiddenBorkedForTest: ForbiddenBorkedForTest, 
    ReviewersObjects: ReviewersObjects, 
    ReviewersForReviews: ReviewersForReviews, 
    Reviews: Reviews, 
    Command: Command, 
    aCfSInvitationSchema: aCfSInvitationSchema, 
    aCfSThemesSchema: aCfSThemesSchema, 
    aProposalFormSchema: aProposalFormSchema, 
    aPublicationStatusSchema: aPublicationStatusSchema, 
    anOpenCfSSchema: anOpenCfSSchema, 
    genericWeReviewSchema: genericWeReviewSchema, 
    showWeReviewSchema: showWeReviewSchema, 
    eqWeReviewSchema: eqWeReviewSchema, 
    pathToWeReviewSchema: pathToWeReviewSchema, 
    genericCfsRule: genericCfsRule, 
    showCfsRule: showCfsRule, 
    pathToCfsRule: pathToCfsRule, 
    genericSessionDescriptionObject: genericSessionDescriptionObject, 
    showSessionDescriptionObject: showSessionDescriptionObject, 
    pathToSessionDescriptionObject: pathToSessionDescriptionObject, 
    genericRolesRule: genericRolesRule, 
    showRolesRule: showRolesRule, 
    pathToRoles: pathToRoles, 
    genericEventsRule: genericEventsRule, 
    showEventsRule: showEventsRule, 
    pathToEvents: pathToEvents, 
    genericReviews: genericReviews, 
    showReviews: showReviews, 
    pathToReviews: pathToReviews, 
    genericCommand: genericCommand, 
    showCommand: showCommand, 
    pathToCommand: pathToCommand
};
