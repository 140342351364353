// Generated by psc version 0.9.1
"use strict";
var Data_Array = require("../Data.Array");
var Data_Tuple = require("../Data.Tuple");
var Prelude = require("../Prelude");
var Pux_Html = require("../Pux.Html");
var State_GlobalRole = require("../State.GlobalRole");
var State_Types = require("../State.Types");
var Data_Function = require("../Data.Function");
var Data_Functor = require("../Data.Functor");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var Data_Semigroup = require("../Data.Semigroup");
var viewAllowed = function (permissions) {
    return function (state) {
        var check = function (el) {
            return Data_Function.apply(Data_Tuple.fst(el))(state);
        };
        return Data_Function.apply(Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.snd))(Data_Array.filter(check)(permissions));
    };
};
var reviewer = State_GlobalRole.hasReviewerRole;
var panelChair = State_GlobalRole.hasPanelChairRole;
var anyone = function (v) {
    return true;
};
var administrator = State_GlobalRole.hasAdministratorRole;
var canSeeFullSessionDescription = function (state) {
    return administrator(state) || panelChair(state);
};
var accessDenied = function (topic) {
    return Pux_Html_Elements.p([  ])([ Data_Function.apply(Pux_Html_Elements.text)("You have insufficient permission to access a " + (topic + ".")) ]);
};
module.exports = {
    accessDenied: accessDenied, 
    administrator: administrator, 
    anyone: anyone, 
    canSeeFullSessionDescription: canSeeFullSessionDescription, 
    panelChair: panelChair, 
    reviewer: reviewer, 
    viewAllowed: viewAllowed
};
