// Generated by psc version 0.9.1
"use strict";
var Components_Elements = require("../Components.Elements");
var Model_CallForSessions = require("../Model.CallForSessions");
var Model_OpenCallsForSessions = require("../Model.OpenCallsForSessions");
var State_CallForSessions = require("../State.CallForSessions");
var Prelude = require("../Prelude");
var Pux_Html = require("../Pux.Html");
var Pux_Html_Attributes = require("../Pux.Html.Attributes");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var Route_HtmlElements = require("../Route.HtmlElements");
var State_Types = require("../State.Types");
var Data_Function = require("../Data.Function");
var UReview_Route_Types = require("../UReview.Route.Types");
var Data_Functor = require("../Data.Functor");
var showAllCallsNew = function (cfss) {
    var propose = function (aCfs) {
        var space = Pux_Html_Elements.text(" ");
        var proposeRoute = Data_Function.apply(UReview_Route_Types.ProposeSessionRoute.create)(Model_CallForSessions.cfsKey(aCfs));
        return Pux_Html_Elements.li([  ])([ Data_Function.apply(Route_HtmlElements.htmlLink(proposeRoute))(Model_CallForSessions.eventNameStr(aCfs)) ]);
    };
    var calls = Model_OpenCallsForSessions.toArray(cfss);
    var showListOfCalls = function (explanation) {
        return Pux_Html_Elements.div([  ])([ Components_Elements.ptext(explanation), Pux_Html_Elements.ul([ Pux_Html_Attributes.className("no-bullet") ])(Data_Functor.map(Data_Functor.functorArray)(propose)(calls)) ]);
    };
    return showListOfCalls("Propose a session for one of these conferences.");
};
var conferencesWithOpenSubmissions = function (state) {
    return Pux_Html_Elements.div([  ])([ Pux_Html_Elements.h2([  ])([ Pux_Html_Elements.text("Submit a session") ]), Data_Function.apply(showAllCallsNew)(State_CallForSessions.publishedCallsForSessions(state)) ]);
};
var home = function (state) {
    return Pux_Html_Elements.section([  ])([ Pux_Html_Elements.div([ Pux_Html_Attributes.className("row medium-unstack") ])([ Components_Elements.column([ conferencesWithOpenSubmissions(state) ]) ]) ]);
};
module.exports = {
    home: home
};
