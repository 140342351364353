// Generated by psc version 0.9.1
"use strict";
var $foreign = require("./foreign");
var Prelude = require("../Prelude");
var Data_Foreign = require("../Data.Foreign");
var Data_Nullable = require("../Data.Nullable");
var Data_Maybe = require("../Data.Maybe");
var Control_Monad_Eff = require("../Control.Monad.Eff");
var Control_Monad_Aff = require("../Control.Monad.Aff");
var Control_Monad_Eff_Class = require("../Control.Monad.Eff.Class");
var Control_Monad_Eff_Exception = require("../Control.Monad.Eff.Exception");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class");
var Web_Firebase = require("../Web.Firebase");
var Web_Firebase_Types = require("../Web.Firebase.Types");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var Control_Bind = require("../Control.Bind");
var Data_Function = require("../Data.Function");
var Control_Applicative = require("../Control.Applicative");
var toString = function ($4) {
    return Control_Monad_Eff_Class.liftEff(Control_Monad_Aff.monadEffAff)(Web_Firebase.toString($4));
};
var $$throw = function ($5) {
    return Control_Monad_Error_Class.throwError(Control_Monad_Aff.monadErrorAff)(Control_Monad_Eff_Exception.error($5));
};
var offLocation = function ($6) {
    return Control_Monad_Eff_Class.liftEff(Control_Monad_Aff.monadEffAff)(Web_Firebase.offSimple($6));
};
var key = function (fb) {
    return Control_Bind.bind(Control_Monad_Aff.bindAff)(Data_Function.apply(Control_Monad_Eff_Class.liftEff(Control_Monad_Aff.monadEffAff))(Web_Firebase.key(fb)))(function (v) {
        if (v instanceof Data_Maybe.Nothing) {
            return $$throw("Key was null. Did you ask key of root reference?");
        };
        if (v instanceof Data_Maybe.Just) {
            return Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(v.value0);
        };
        throw new Error("Failed pattern match at Web.Firebase.Aff line 64, column 3 - line 66, column 24: " + [ v.constructor.name ]);
    });
};
var convertError = function (errorCallback) {
    return function (firebaseError) {
        return errorCallback($foreign.fb2error(firebaseError));
    };
};
var on = function (etype) {
    return function (fb) {
        return Control_Monad_Aff.makeAff(function (eb) {
            return function (cb) {
                return Web_Firebase.on(etype)(cb)(convertError(eb))(fb);
            };
        });
    };
};
var once = function (eventType) {
    return function (root) {
        return Control_Monad_Aff.makeAff(function (errorCb) {
            return function (successCb) {
                return Web_Firebase.once(eventType)(successCb)(convertError(errorCb))(root);
            };
        });
    };
};
var onceValue = function (root) {
    return once(Web_Firebase.Value.value)(root);
};
var push = function (value) {
    return function (ref) {
        return Control_Monad_Aff.makeAff(function (onError) {
            return function (onSuccess) {
                return Web_Firebase.pushA(value)(onSuccess)(convertError(onError))(ref);
            };
        });
    };
};
var set = function (value) {
    return function (ref) {
        return Control_Monad_Aff.makeAff(function (onError) {
            return function (onSuccess) {
                return Web_Firebase.setA(value)(onSuccess)(convertError(onError))(ref);
            };
        });
    };
};
var remove = function (ref) {
    var foreignNull = Data_Function.apply(Data_Foreign.toForeign)(Data_Function.apply(Data_Nullable.toNullable)(Data_Maybe.Nothing.value));
    return set(foreignNull)(ref);
};
var child = function (aKey) {
    return function (ref) {
        return Data_Function.apply(Control_Monad_Eff_Class.liftEff(Control_Monad_Aff.monadEffAff))(Web_Firebase.child(aKey)(ref));
    };
};
module.exports = {
    child: child, 
    convertError: convertError, 
    key: key, 
    offLocation: offLocation, 
    on: on, 
    once: once, 
    onceValue: onceValue, 
    push: push, 
    remove: remove, 
    set: set, 
    toString: toString, 
    fb2error: $foreign.fb2error, 
    firebaseErrToString: $foreign.firebaseErrToString
};
