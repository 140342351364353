// Generated by psc version 0.9.1
"use strict";
var Prelude = require("../Prelude");
var Data_Maybe = require("../Data.Maybe");
var Data_Foreign = require("../Data.Foreign");
var Control_Applicative = require("../Control.Applicative");
var Data_Either = require("../Data.Either");
var Data_Functor = require("../Data.Functor");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var Null = function (x) {
    return x;
};
var unNull = function (v) {
    return v;
};
var readNull = function (v) {
    return function (value) {
        if (Data_Foreign.isNull(value)) {
            return Control_Applicative.pure(Data_Either.applicativeEither)(Data_Maybe.Nothing.value);
        };
        return Data_Functor.map(Data_Either.functorEither)(function ($5) {
            return Null(Data_Maybe.Just.create($5));
        })(v(value));
    };
};
module.exports = {
    Null: Null, 
    readNull: readNull, 
    unNull: unNull
};
