// Generated by psc version 0.9.1
"use strict";
var Data_Semigroup = require("../Data.Semigroup");
var PathTo = function (pathTo) {
    this.pathTo = pathTo;
};
var pathTo = function (dict) {
    return dict.pathTo;
};
var forwardSlash = function (a) {
    return function (b) {
        return a + ("/" + b);
    };
};
module.exports = {
    PathTo: PathTo, 
    forwardSlash: forwardSlash, 
    pathTo: pathTo
};
