// Generated by psc version 0.9.1
"use strict";
var Model_Expenses = require("../Model.Expenses");
var Pux_Html_Events = require("../Pux.Html.Events");
var View_Place = require("../View.Place");
var ChooseExpenseKind = (function () {
    function ChooseExpenseKind(value0) {
        this.value0 = value0;
    };
    ChooseExpenseKind.create = function (value0) {
        return new ChooseExpenseKind(value0);
    };
    return ChooseExpenseKind;
})();
var ExpenseRemarksChanged = (function () {
    function ExpenseRemarksChanged(value0) {
        this.value0 = value0;
    };
    ExpenseRemarksChanged.create = function (value0) {
        return new ExpenseRemarksChanged(value0);
    };
    return ExpenseRemarksChanged;
})();
var TravellingFromChanged = (function () {
    function TravellingFromChanged(value0) {
        this.value0 = value0;
    };
    TravellingFromChanged.create = function (value0) {
        return new TravellingFromChanged(value0);
    };
    return TravellingFromChanged;
})();
var TravellingToChanged = (function () {
    function TravellingToChanged(value0) {
        this.value0 = value0;
    };
    TravellingToChanged.create = function (value0) {
        return new TravellingToChanged(value0);
    };
    return TravellingToChanged;
})();
var pleaseChooseToken = "Please Choose";
var $$null = Model_Expenses.nullExpenses;
var noExpensesToken = "No";
var expensesToken = "Yes";
var mainLabels = [ pleaseChooseToken, noExpensesToken, expensesToken ];
var eventValue = function (ev) {
    return ev.target.value;
};
var currentChoice = function (e) {
    if (e instanceof Model_Expenses.No) {
        return noExpensesToken;
    };
    if (e instanceof Model_Expenses.Yes) {
        return expensesToken;
    };
    if (e instanceof Model_Expenses.PleaseChoose) {
        return pleaseChooseToken;
    };
    throw new Error("Failed pattern match at State.CurrentMockup line 44, column 3 - line 47, column 41: " + [ e.constructor.name ]);
};
var chooseExpenseKind = function (v) {
    return function (s) {
        if (v === "No") {
            return Model_Expenses.chooseNo(s);
        };
        if (v === "Yes") {
            return Model_Expenses.chooseYes(s);
        };
        return Model_Expenses.nullExpenses;
    };
};
var update = function (a) {
    return function (expenses) {
        if (a instanceof ChooseExpenseKind) {
            return chooseExpenseKind(eventValue(a.value0))(expenses);
        };
        if (a instanceof ExpenseRemarksChanged) {
            return Model_Expenses.setExpenseRemark(eventValue(a.value0))(expenses);
        };
        if (a instanceof TravellingFromChanged) {
            return Model_Expenses.updateTravellingFrom(View_Place.update(a.value0))(expenses);
        };
        if (a instanceof TravellingToChanged) {
            return Model_Expenses.updateTravellingTo(View_Place.update(a.value0))(expenses);
        };
        throw new Error("Failed pattern match at State.CurrentMockup line 29, column 22 - line 33, column 70: " + [ a.constructor.name ]);
    };
};
module.exports = {
    ChooseExpenseKind: ChooseExpenseKind, 
    ExpenseRemarksChanged: ExpenseRemarksChanged, 
    TravellingFromChanged: TravellingFromChanged, 
    TravellingToChanged: TravellingToChanged, 
    currentChoice: currentChoice, 
    mainLabels: mainLabels, 
    "null": $$null, 
    update: update
};
