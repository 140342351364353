// Generated by psc version 0.9.1
"use strict";
var $foreign = require("./foreign");
var Prelude = require("../Prelude");
var Control_Monad_Eff = require("../Control.Monad.Eff");
var Unsafe_Coerce = require("../Unsafe.Coerce");
var Control_Applicative = require("../Control.Applicative");
var Data_Unit = require("../Data.Unit");
var spec$prime = function (getInitialState) {
    return function (renderFn) {
        return {
            render: renderFn, 
            displayName: "", 
            getInitialState: getInitialState, 
            componentWillMount: function (v) {
                return Control_Applicative.pure(Control_Monad_Eff.applicativeEff)(Data_Unit.unit);
            }, 
            componentDidMount: function (v) {
                return Control_Applicative.pure(Control_Monad_Eff.applicativeEff)(Data_Unit.unit);
            }, 
            componentWillReceiveProps: function (v) {
                return function (v1) {
                    return Control_Applicative.pure(Control_Monad_Eff.applicativeEff)(Data_Unit.unit);
                };
            }, 
            shouldComponentUpdate: function (v) {
                return function (v1) {
                    return function (v2) {
                        return Control_Applicative.pure(Control_Monad_Eff.applicativeEff)(true);
                    };
                };
            }, 
            componentWillUpdate: function (v) {
                return function (v1) {
                    return function (v2) {
                        return Control_Applicative.pure(Control_Monad_Eff.applicativeEff)(Data_Unit.unit);
                    };
                };
            }, 
            componentDidUpdate: function (v) {
                return function (v1) {
                    return function (v2) {
                        return Control_Applicative.pure(Control_Monad_Eff.applicativeEff)(Data_Unit.unit);
                    };
                };
            }, 
            componentWillUnmount: function (v) {
                return Control_Applicative.pure(Control_Monad_Eff.applicativeEff)(Data_Unit.unit);
            }
        };
    };
};
var spec = function (state) {
    return spec$prime(function (v) {
        return Control_Applicative.pure(Control_Monad_Eff.applicativeEff)(state);
    });
};
var createClassStateless = Unsafe_Coerce.unsafeCoerce;
var createClassStateless$prime = function (k) {
    return createClassStateless(function (props) {
        return k(props)($foreign.childrenToArray((Unsafe_Coerce.unsafeCoerce(props)).children));
    });
};
module.exports = {
    createClassStateless: createClassStateless, 
    "createClassStateless'": createClassStateless$prime, 
    spec: spec, 
    "spec'": spec$prime, 
    createClass: $foreign.createClass, 
    createElement: $foreign.createElement, 
    createElementDynamic: $foreign.createElementDynamic, 
    createElementTagName: $foreign.createElementTagName, 
    createElementTagNameDynamic: $foreign.createElementTagNameDynamic, 
    createFactory: $foreign.createFactory, 
    getChildren: $foreign.getChildren, 
    getProps: $foreign.getProps, 
    getRefs: $foreign.getRefs, 
    handle: $foreign.handle, 
    readState: $foreign.readState, 
    transformState: $foreign.transformState, 
    writeState: $foreign.writeState, 
    writeStateWithCallback: $foreign.writeStateWithCallback
};
