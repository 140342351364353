// Generated by psc version 0.9.1
"use strict";
var $foreign = require("./foreign");
var Prelude = require("../Prelude");
var Data_Either = require("../Data.Either");
var Data_Foreign = require("../Data.Foreign");
var Data_Function = require("../Data.Function");
var Data_Eq = require("../Data.Eq");
var keys = function (value) {
    if (Data_Foreign.isNull(value)) {
        return Data_Function.apply(Data_Either.Left.create)(new Data_Foreign.TypeMismatch("object", "null"));
    };
    if (Data_Foreign.isUndefined(value)) {
        return Data_Function.apply(Data_Either.Left.create)(new Data_Foreign.TypeMismatch("object", "undefined"));
    };
    if (Data_Foreign.typeOf(value) === "object") {
        return Data_Function.apply(Data_Either.Right.create)($foreign.unsafeKeys(value));
    };
    return Data_Function.apply(Data_Either.Left.create)(new Data_Foreign.TypeMismatch("object", Data_Foreign.typeOf(value)));
};
module.exports = {
    keys: keys
};
