// Generated by psc version 0.9.1
"use strict";
var Pux_Html = require("../Pux.Html");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var Pux_Html_Attributes = require("../Pux.Html.Attributes");
var Pux_Html_Events = require("../Pux.Html.Events");
var Components_Elements = require("../Components.Elements");
var Components_Elements_Basic_Types = require("../Components.Elements.Basic.Types");
var CurrentValue = function (x) {
    return x;
};
var Changed = (function () {
    function Changed(value0) {
        this.value0 = value0;
    };
    Changed.create = function (value0) {
        return new Changed(value0);
    };
    return Changed;
})();
var update = function (v) {
    var eventValue = function (ev) {
        return ev.target.value;
    };
    return eventValue(v.value0);
};
var editTextField = function (v) {
    return function (v1) {
        return function (action) {
            var idString = Components_Elements_Basic_Types.fromHtmlId(v.htmlId);
            return Pux_Html_Elements.div([  ])([ Pux_Html_Elements.label([ Pux_Html_Attributes.htmlFor(idString), Pux_Html_Attributes.className(v.inputClass) ])([ Pux_Html_Elements.text(v.fieldLabel) ]), Pux_Html_Elements.p([ Pux_Html_Attributes.className("help-text") ])([ Pux_Html_Elements.text(v.helpText) ]), Pux_Html_Elements.input([ Pux_Html_Attributes.id_(idString), Pux_Html_Attributes.type_("text"), Pux_Html_Attributes.value(v1), Pux_Html_Events.onChange(action) ])([  ]) ]);
        };
    };
};
var view = function (t) {
    return function (cv) {
        return editTextField(t)(cv)(Changed.create);
    };
};
module.exports = {
    update: update, 
    view: view
};
