// Generated by psc version 0.9.1
"use strict";
var Control_Alternative = require("../Control.Alternative");
var Control_Monad_Eff = require("../Control.Monad.Eff");
var Control_Monad_Eff_Class = require("../Control.Monad.Eff.Class");
var Control_Monad_Eff_Console = require("../Control.Monad.Eff.Console");
var Data_Array = require("../Data.Array");
var Data_Bifunctor = require("../Data.Bifunctor");
var Data_Either = require("../Data.Either");
var Data_Foreign = require("../Data.Foreign");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var Data_Maybe = require("../Data.Maybe");
var Debug_Trace = require("../Debug.Trace");
var Model_UserProfile = require("../Model.UserProfile");
var Network_RemoteData = require("../Network.RemoteData");
var Ports_Firebase_Eff = require("../Ports.Firebase.Eff");
var Ports_Firebase_Types = require("../Ports.Firebase.Types");
var Prelude = require("../Prelude");
var Signal_Channel = require("../Signal.Channel");
var State_Types = require("../State.Types");
var UReview_SendActions = require("../UReview.SendActions");
var UReview_UserNotifications = require("../UReview.UserNotifications");
var Control_Bind = require("../Control.Bind");
var Data_Foreign_Index = require("../Data.Foreign.Index");
var Data_Functor = require("../Data.Functor");
var Data_Function = require("../Data.Function");
var Control_Applicative = require("../Control.Applicative");
var Control_Alt = require("../Control.Alt");
var Data_Semigroup = require("../Data.Semigroup");
var Data_Show = require("../Data.Show");
var Control_Monad_Aff = require("../Control.Monad.Aff");
var Web_Firebase_Authentication_Eff = require("../Web.Firebase.Authentication.Eff");
var parseGoogleAuth = function (value) {
    return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Class.readProp(Data_Foreign_Class.stringIsForeign)(Data_Foreign_Index.indexString)("uid")(value))(function (v) {
        return Control_Bind.bind(Data_Either.bindEither)(Data_Functor.map(Data_Either.functorEither)(Data_Foreign.readArray)(Data_Foreign_Class.readProp(Data_Foreign_Class.foreignIsForeign)(Data_Foreign_Index.indexString)("providerData")(value)))(function (v1) {
            return Control_Bind.bind(Data_Either.bindEither)(Data_Functor.map(Data_Either.functorEither)(Data_Array.head)(Data_Bifunctor.lmap(Data_Either.bifunctorEither)(function (e) {
                return new Data_Foreign.ErrorAtProperty("profileData", e);
            })(v1)))(function (v2) {
                return Control_Bind.bind(Data_Either.bindEither)(Data_Maybe.maybe(new Data_Either.Left(new Data_Foreign.ErrorAtProperty("profileData", new Data_Foreign.TypeMismatch("array with one user profile", "empty array"))))(function (a) {
                    return new Data_Either.Right(a);
                })(v2))(function (v3) {
                    return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Class.readProp(Data_Foreign_Class.stringIsForeign)(Data_Foreign_Index.indexString)("displayName")(v3))(function (v4) {
                        return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Class.readProp(Data_Foreign_Class.stringIsForeign)(Data_Foreign_Index.indexString)("photoURL")(v3))(function (v5) {
                            return Data_Function.apply(Control_Applicative.pure(Data_Either.applicativeEither))({
                                uid: v, 
                                displayName: v4, 
                                profileImageURL: v5, 
                                roles: Network_RemoteData.NotAsked.value
                            });
                        });
                    });
                });
            });
        });
    });
};
var parseAnonAuth = function (value) {
    return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Class.readProp(Data_Foreign_Class.stringIsForeign)(Data_Foreign_Index.indexString)("uid")(value))(function (v) {
        return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Class.readProp(Data_Foreign_Class.booleanIsForeign)(Data_Foreign_Index.indexString)("isAnonymous")(value))(function (v1) {
            if (v1) {
                return Data_Function.apply(Data_Either.Right.create)({
                    uid: v, 
                    displayName: "Anonymous", 
                    profileImageURL: "http://www.gravatar.com/avatar/?d=identicon", 
                    roles: Network_RemoteData.NotAsked.value
                });
            };
            if (!v1) {
                return Data_Function.apply(Data_Either.Left.create)(new Data_Foreign.ErrorAtProperty("isAnonymous", new Data_Foreign.JSONError("Try another kind of profile, the user is not anonymous.")));
            };
            throw new Error("Failed pattern match at State.Authenticate line 67, column 5 - line 73, column 119: " + [ v1.constructor.name ]);
        });
    });
};
var parseAuth = function (v) {
    return Control_Alt.alt(Data_Either.altEither)(parseAnonAuth(v))(parseGoogleAuth(v));
};
var parseCredentials$prime = function (channel) {
    return function (authResult) {
        var $17 = Data_Function.apply(Debug_Trace.spy)(parseAuth(authResult));
        if ($17 instanceof Data_Either.Left) {
            return UReview_UserNotifications.sendErrorMessage(channel)("Error reading google profile: " + Data_Function.apply(Data_Show.show(Data_Foreign.showForeignError))(Debug_Trace.spy($17.value0)));
        };
        if ($17 instanceof Data_Either.Right) {
            return UReview_SendActions.sendAction(channel)(Data_Function.apply(State_Types.Authenticated.create)($17.value0));
        };
        throw new Error("Failed pattern match at State.Authenticate line 48, column 40 - line 50, column 73: " + [ $17.constructor.name ]);
    };
};
var onAuthCallback = function (channel) {
    return function (authResult) {
        var unused2 = Debug_Trace.spy("auth called here, now try to find out what state we are in. Null means not logged in.");
        var unused = Debug_Trace.spy(authResult);
        var $20 = Data_Foreign.isNull(authResult);
        if ($20) {
            return UReview_SendActions.sendUnAuthenticated(channel);
        };
        if (!$20) {
            return parseCredentials$prime(channel)(authResult);
        };
        throw new Error("Failed pattern match at State.Authenticate line 43, column 3 - line 45, column 46: " + [ $20.constructor.name ]);
    };
};
var authenticate = function (state) {
    return function (channel) {
        return {
            state: (function () {
                var $21 = {};
                for (var $22 in state) {
                    if (state.hasOwnProperty($22)) {
                        $21[$22] = state[$22];
                    };
                };
                $21.userProfile = Network_RemoteData.Loading.value;
                return $21;
            })(), 
            effects: [ Control_Bind.bind(Control_Monad_Aff.bindAff)(Data_Function.apply(Control_Monad_Eff_Class.liftEff(Control_Monad_Aff.monadEffAff))(Control_Monad_Eff_Console.log("re-register auth handler (need three valued logic, logged out state as well)")))(function () {
                return Control_Bind.bind(Control_Monad_Aff.bindAff)(Data_Function.apply(Control_Monad_Eff_Class.liftEff(Control_Monad_Aff.monadEffAff))(Web_Firebase_Authentication_Eff.onAuth(onAuthCallback(channel))(state.rootRef)))(function () {
                    return Control_Bind.bind(Control_Monad_Aff.bindAff)(Data_Function.apply(Control_Monad_Eff_Class.liftEff(Control_Monad_Aff.monadEffAff))(Ports_Firebase_Eff.authenticateGoogleWithRedirect))(function () {
                        return Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(State_Types.Noop.value);
                    });
                });
            }) ]
        };
    };
};
module.exports = {
    authenticate: authenticate, 
    onAuthCallback: onAuthCallback, 
    parseAnonAuth: parseAnonAuth, 
    parseAuth: parseAuth, 
    parseGoogleAuth: parseGoogleAuth
};
