// Generated by psc version 0.9.1
"use strict";
var Control_Monad_Aff = require("../Control.Monad.Aff");
var Control_Monad_Eff = require("../Control.Monad.Eff");
var Control_Monad_Eff_Class = require("../Control.Monad.Eff.Class");
var Control_Monad_Eff_Random = require("../Control.Monad.Eff.Random");
var Data_Array = require("../Data.Array");
var Data_String = require("../Data.String");
var Prelude = require("../Prelude");
var Test_QuickCheck_Arbitrary = require("../Test.QuickCheck.Arbitrary");
var Test_QuickCheck_Gen = require("../Test.QuickCheck.Gen");
var Test_QuickCheck_LCG = require("../Test.QuickCheck.LCG");
var Data_Functor = require("../Data.Functor");
var Data_Show = require("../Data.Show");
var Data_Eq = require("../Data.Eq");
var Data_Ord = require("../Data.Ord");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans");
var Data_Identity = require("../Data.Identity");
var Control_Bind = require("../Control.Bind");
var Data_Function = require("../Data.Function");
var Control_Applicative = require("../Control.Applicative");
var AlphaNumString = function (x) {
    return x;
};
var uniqueStrings = Data_Functor.map(Control_Monad_State_Trans.functorStateT(Data_Identity.functorIdentity))(Data_Array.nub(Data_Eq.eqString))(Test_QuickCheck_Arbitrary.arbitrary(Test_QuickCheck_Arbitrary.arbArray(Test_QuickCheck_Arbitrary.arbString)));
var showAlphaNumString = new Data_Show.Show(function (v) {
    return v;
});
var randomSample1 = function (gen) {
    return function __do() {
        var v = Test_QuickCheck_LCG.randomSeed();
        return Data_Function.apply(Control_Applicative.pure(Control_Monad_Eff.applicativeEff))(Test_QuickCheck_Gen.evalGen(gen)({
            newSeed: v, 
            size: 1
        }))();
    };
};
var takeSample = function (g) {
    return Control_Monad_Eff_Class.liftEff(Control_Monad_Aff.monadEffAff)(randomSample1(g));
};
var nonEmptyArrayOf = function (g) {
    return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Gen.chooseInt(1)(20))(function (v) {
        return Test_QuickCheck_Gen.vectorOf(v)(g);
    });
};
var integerBetween = Test_QuickCheck_Gen.chooseInt;
var eqAlphaNumString = new Data_Eq.Eq(function (v) {
    return function (v1) {
        return v === v1;
    };
});
var ordAlphaNumString = new Data_Ord.Ord(function () {
    return eqAlphaNumString;
}, function (v) {
    return function (v1) {
        return Data_Ord.compare(Data_Ord.ordString)(v)(v1);
    };
});
var bigArrayOf = function (gen) {
    return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Gen.chooseInt(30)(50))(function (arraySize) {
        return Test_QuickCheck_Gen.vectorOf(arraySize)(gen);
    });
};
var anyChar = (function () {
    var rest = Data_String.toCharArray("bcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789");
    return Test_QuickCheck_Gen.oneOf(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity))("a"))(Data_Functor.map(Data_Functor.functorArray)(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity)))(rest));
})();
var bigAlphaNumString = Data_Functor.map(Control_Monad_State_Trans.functorStateT(Data_Identity.functorIdentity))(Data_String.fromCharArray)(bigArrayOf(anyChar));
var alphaNumString = Data_Functor.map(Control_Monad_State_Trans.functorStateT(Data_Identity.functorIdentity))(Data_String.fromCharArray)(nonEmptyArrayOf(anyChar));
var arbitraryAlphaNumString = new Test_QuickCheck_Arbitrary.Arbitrary(Data_Functor.map(Control_Monad_State_Trans.functorStateT(Data_Identity.functorIdentity))(AlphaNumString)(alphaNumString));
module.exports = {
    AlphaNumString: AlphaNumString, 
    alphaNumString: alphaNumString, 
    bigAlphaNumString: bigAlphaNumString, 
    integerBetween: integerBetween, 
    nonEmptyArrayOf: nonEmptyArrayOf, 
    randomSample1: randomSample1, 
    takeSample: takeSample, 
    uniqueStrings: uniqueStrings, 
    arbitraryAlphaNumString: arbitraryAlphaNumString, 
    showAlphaNumString: showAlphaNumString, 
    eqAlphaNumString: eqAlphaNumString, 
    ordAlphaNumString: ordAlphaNumString
};
