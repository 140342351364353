// Generated by psc version 0.9.1
"use strict";
var Components_Elements = require("../Components.Elements");
var Components_Elements_Types = require("../Components.Elements.Types");
var Components_ManageReviewPanel_AddReviewerView = require("../Components.ManageReviewPanel.AddReviewerView");
var Components_ManageReviewPanel_Types = require("../Components.ManageReviewPanel.Types");
var Data_Array = require("../Data.Array");
var Data_Maybe = require("../Data.Maybe");
var Data_Tuple = require("../Data.Tuple");
var Model_Event = require("../Model.Event");
var Model_Event_Key = require("../Model.Event.Key");
var Network_RemoteData = require("../Network.RemoteData");
var Prelude = require("../Prelude");
var Pux_Html = require("../Pux.Html");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var State_Event = require("../State.Event");
var State_Types = require("../State.Types");
var Data_Function = require("../Data.Function");
var Data_Show = require("../Data.Show");
var Data_Semigroup = require("../Data.Semigroup");
var Data_Functor = require("../Data.Functor");
var Model_UserProfile = require("../Model.UserProfile");
var Data_ForeignKey = require("../Data.ForeignKey");
var renderEvent = function (state) {
    return function (key) {
        return Data_Function.apply(Components_Elements.ptext)((function () {
            var $2 = State_Event.findEvent(key)(state);
            if ($2 instanceof Data_Maybe.Nothing) {
                return "Not found";
            };
            if ($2 instanceof Data_Maybe.Just) {
                return Data_Show.show(Model_Event.showEvent)($2.value0);
            };
            throw new Error("Failed pattern match at View.PanelChairs line 36, column 25 - line 39, column 29: " + [ $2.constructor.name ]);
        })());
    };
};
var addRoleToPanelChair = function (users) {
    return function (state) {
        return Data_Function.apply(Components_Elements.buildMultiSelectConfirmer([  ]))(Components_ManageReviewPanel_AddReviewerView.addRoleToUserBuilder(Components_Elements_Types.mkIdAndLabelFragment("panelchair")("Panel Chair"))(users)(state));
    };
};
var renderPanelChairSelection = function (state) {
    return function (v) {
        var inactiveCount = Data_Function.apply(Data_Show.show(Data_Show.showInt))(Data_Function.apply(Data_Array.length)(v.active.no));
        var activeCount = Data_Function.apply(Data_Show.show(Data_Show.showInt))(Data_Function.apply(Data_Array.length)(v.active.yes));
        return Pux_Html_Elements.div([  ])([ Data_Function.apply(Components_Elements.ptext)(activeCount + " active panel chairs"), Data_Function.apply(Components_Elements.ptext)(inactiveCount + " deactivated panel chairs"), Data_Functor.map(Pux_Html_Elements.functorHtml)(State_Types.ChoosePanelChairToAdd.create)(addRoleToPanelChair(v.potential)((state["new"]).reviewerFor)) ]);
    };
};
var renderLoadedEvent = function (event) {
    return function (state) {
        return Pux_Html_Elements.div([  ])([ Data_Function.apply(renderPanelChairSelection(state))(State_Event.panelChairSelection(event)(state)), Data_Function.apply(Components_Elements.ptext)(Data_Show.show(Data_Maybe.showMaybe(Model_UserProfile.showUserProfile))((state["new"]).reviewerFor.user)), Data_Function.apply(Components_Elements.ptext)(Data_Show.show(Model_Event.showEvent)(event)) ]);
    };
};
var showLoading = function (state) {
    return function (v) {
        if (v.value1 instanceof Network_RemoteData.NotAsked) {
            return Components_Elements.ptext("Not asked");
        };
        if (v.value1 instanceof Network_RemoteData.Loading) {
            return Components_Elements.ptext("Loading");
        };
        if (v.value1 instanceof Network_RemoteData.Failure) {
            return Data_Function.apply(Components_Elements.ptext)("Failed " + v.value1.value0);
        };
        if (v.value1 instanceof Network_RemoteData.Success) {
            return renderLoadedEvent(v.value1.value0)(state);
        };
        throw new Error("Failed pattern match at View.PanelChairs line 29, column 39 - line 33, column 49: " + [ v.value1.constructor.name ]);
    };
};
var panelChairsView = function (state) {
    return function (eventKey) {
        return Pux_Html_Elements.div([  ])([ Pux_Html_Elements.h3([  ])([ Pux_Html_Elements.text("panel chair page") ]), showLoading(state)(state.current.event), renderEvent(state)(eventKey), Data_Function.apply(Components_Elements.ptext)(Data_Show.show(Data_ForeignKey.showForeignKey)(eventKey)) ]);
    };
};
module.exports = {
    panelChairsView: panelChairsView
};
