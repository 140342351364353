// Generated by psc version 0.9.1
"use strict";
var Data_Foreign = require("../Data.Foreign");
var Data_ForeignLocation = require("../Data.ForeignLocation");
var Data_Monoid = require("../Data.Monoid");
var Debug_Trace = require("../Debug.Trace");
var Model_Connection = require("../Model.Connection");
var Prelude = require("../Prelude");
var State_EventDependencies = require("../State.EventDependencies");
var State_Types = require("../State.Types");
var Data_Function = require("../Data.Function");
var Data_Functor = require("../Data.Functor");
var Data_Either = require("../Data.Either");
var connectionEventPath = function (v) {
    return function (v1) {
        return ".info/connected";
    };
};
var applyConnectionState = function (foreignLocation) {
    return function (state) {
        var setConnectionState = function (aConnection) {
            var $2 = {};
            for (var $3 in state) {
                if (state.hasOwnProperty($3)) {
                    $2[$3] = state[$3];
                };
            };
            $2.connection = aConnection;
            return $2;
        };
        var parsedConnection = Data_ForeignLocation.readForeignPart(Model_Connection.isForeignConnection)(foreignLocation);
        return Data_Function.apply(Debug_Trace.spy)(Data_Functor.map(Data_Either.functorEither)(setConnectionState)(parsedConnection));
    };
};
var dependOnConnectionState = new State_Types.Changed("Connection State", connectionEventPath, applyConnectionState, Data_Monoid.mempty(Data_Monoid.monoidArray));
module.exports = {
    applyConnectionState: applyConnectionState, 
    connectionEventPath: connectionEventPath, 
    dependOnConnectionState: dependOnConnectionState
};
