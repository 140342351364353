// Generated by psc version 0.9.1
"use strict";
var Data_Array = require("../Data.Array");
var Data_Maybe = require("../Data.Maybe");
var Data_Monoid = require("../Data.Monoid");
var Data_PathTo = require("../Data.PathTo");
var Model_Review = require("../Model.Review");
var Model_SessionDescription = require("../Model.SessionDescription");
var Prelude = require("../Prelude");
var State_DataDependency = require("../State.DataDependency");
var State_EventDependencies = require("../State.EventDependencies");
var State_Review = require("../State.Review");
var State_SessionDescription = require("../State.SessionDescription");
var State_Types = require("../State.Types");
var WeReview_Data_Schema = require("../WeReview.Data.Schema");
var Data_Function = require("../Data.Function");
var Control_Applicative = require("../Control.Applicative");
var sessionDescriptionPath = function (k) {
    return Data_Function.apply(State_EventDependencies.fixedPath)(Data_Function.apply(Data_PathTo.pathTo(WeReview_Data_Schema.pathToWeReviewSchema))(new WeReview_Data_Schema.SessionDescriptions(k, WeReview_Data_Schema.SessionDescriptionObject.value)));
};
var subscribeAllReviewParts = function (v) {
    return Data_Function.apply(Data_Array.singleton)(new State_Types.Changed("SessionDescription", sessionDescriptionPath(v.sessionDescriptionId), State_SessionDescription.applyForeignSessionDescription, Data_Monoid.mempty(Data_Monoid.monoidArray)));
};
var aReviewPath = function (k) {
    return Data_Function.apply(State_EventDependencies.fixedPath)(Data_Function.apply(Data_PathTo.pathTo(WeReview_Data_Schema.pathToWeReviewSchema))(new WeReview_Data_Schema.Reviews(k)));
};
var subscribeReviewItself = function (rk) {
    return new State_Types.Changed("Review", aReviewPath(rk), State_Review.applyForeignReview, Data_Monoid.mempty(Data_Monoid.monoidArray));
};
var subscribeAllReviewKey = function (v) {
    return function (s) {
        var $6 = State_Review.findReview(v)(s);
        if ($6 instanceof Data_Maybe.Nothing) {
            return Data_Function.apply(Control_Applicative.pure(Data_Maybe.applicativeMaybe))(Data_Function.apply(Data_Array.singleton)(subscribeReviewItself(v)));
        };
        if ($6 instanceof Data_Maybe.Just) {
            return Data_Function.apply(Control_Applicative.pure(Data_Maybe.applicativeMaybe))(subscribeAllReviewParts($6.value0));
        };
        throw new Error("Failed pattern match at ReviewDependencies line 25, column 52 - line 27, column 45: " + [ $6.constructor.name ]);
    };
};
module.exports = {
    subscribeAllReviewKey: subscribeAllReviewKey, 
    subscribeAllReviewParts: subscribeAllReviewParts
};
