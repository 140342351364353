// Generated by psc version 0.9.1
"use strict";
var Data_Generic = require("../Data.Generic");
var Test_QuickCheck_Arbitrary = require("../Test.QuickCheck.Arbitrary");
var Test_QuickCheck_Gen_1 = require("../Test.QuickCheck.Gen");
var Test_QuickCheck_Gen_1 = require("../Test.QuickCheck.Gen");
var Data_Foldable = require("../Data.Foldable");
var Prelude = require("../Prelude");
var Control_Apply = require("../Control.Apply");
var Data_Maybe = require("../Data.Maybe");
var Data_Unit = require("../Data.Unit");
var Data_Eq = require("../Data.Eq");
var Data_Ord = require("../Data.Ord");
var Data_Show = require("../Data.Show");
var Control_Applicative = require("../Control.Applicative");
var Data_Functor = require("../Data.Functor");
var Control_Bind = require("../Control.Bind");
var Data_Semigroup = require("../Data.Semigroup");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans");
var Data_Identity = require("../Data.Identity");
var EventName = function (x) {
    return x;
};
var runEventName = function (v) {
    return v;
};
var showEventName = new Data_Show.Show(runEventName);
var mkEventName = function (s) {
    return s;
};
var genericEventName = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.OpenCallForSessions.EventName.EventName" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(EventName))(Data_Generic.fromSpine(Data_Generic.genericString)(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Model.OpenCallForSessions.EventName.EventName", [ {
        sigConstructor: "Model.OpenCallForSessions.EventName.EventName", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("Model.OpenCallForSessions.EventName.EventName", [ function ($dollarq) {
        return Data_Generic.toSpine(Data_Generic.genericString)(v);
    } ]);
});
var genConcat = function (dictBind) {
    return function (dictApplicative) {
        return function (l) {
            return function (r) {
                return Control_Bind.bind(dictBind)(l)(function (v) {
                    return Control_Bind.bind(dictBind)(r)(function (v1) {
                        return Control_Applicative.pure(dictApplicative)(v + (" " + v1));
                    });
                });
            };
        };
    };
};
var eqEventName = new Data_Eq.Eq(function (x) {
    return function (y) {
        return x === y;
    };
});
var ordEventName = new Data_Ord.Ord(function () {
    return eqEventName;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(Data_Ord.ordString)(x)(y);
    };
});
var city$prime = (function () {
    var prefix = Test_QuickCheck_Gen_1.oneOf(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity))("Maas"))(Data_Functor.map(Data_Functor.functorArray)(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity)))([ "Goidschalk", "Greu", "Old", "New", "West", "East", "South", "Nor", "North", "Califor", "Duc", "Brow", "Glous", "Man", "Brasi", "Melb", "Brus" ]));
    var postfix = Test_QuickCheck_Gen_1.oneOf(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity))("oord"))(Data_Functor.map(Data_Functor.functorArray)(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity)))([ "ville", "villa", "e", "p", "which", "chester", "x", "ch", "nix", "city", "lia", "ourne,", "sels" ]));
    var combine = function (l) {
        return function (r) {
            return Control_Apply.apply(Control_Monad_State_Trans.applyStateT(Data_Identity.monadIdentity))(Data_Functor.map(Control_Monad_State_Trans.functorStateT(Data_Identity.functorIdentity))(Data_Semigroup.append(Data_Semigroup.semigroupString))(l))(r);
        };
    };
    return combine(prefix)(postfix);
})();
var arbitraryEventname = new Test_QuickCheck_Arbitrary.Arbitrary((function () {
    var year = Test_QuickCheck_Gen_1.oneOf(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity))("2020"))(Data_Functor.map(Data_Functor.functorArray)(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity)))([ "2019", "2025", "2021", "2022", "2023", "2017", "2018" ]));
    var rest = [ "Agile", "Lean Agile", "Service design", "UX", "UX in the city", "XP", "XP Days", "DevOps Nights", "C++ Months", "Haskell Weeks", "Scrum", "Kanban" ];
    var topic = Test_QuickCheck_Gen_1.oneOf(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity))("Lean"))(Data_Functor.map(Data_Functor.functorArray)(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity)))(rest));
    return Data_Functor.map(Control_Monad_State_Trans.functorStateT(Data_Identity.functorIdentity))(EventName)(Data_Foldable.foldl(Data_Foldable.foldableArray)(genConcat(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity)))(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity))(""))([ topic, city$prime, year ]));
})());
module.exports = {
    mkEventName: mkEventName, 
    runEventName: runEventName, 
    genericEventName: genericEventName, 
    eqEventName: eqEventName, 
    ordEventName: ordEventName, 
    showEventName: showEventName, 
    arbitraryEventname: arbitraryEventname
};
