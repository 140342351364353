// Generated by psc version 0.9.1
"use strict";
var Data_Firebase_Foreign = require("../Data.Firebase.Foreign");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var Data_Foreign_Generic = require("../Data.Foreign.Generic");
var Data_Generic = require("../Data.Generic");
var Prelude = require("../Prelude");
var UReview_Prelude = require("../UReview.Prelude");
var Data_Maybe = require("../Data.Maybe");
var Data_Show = require("../Data.Show");
var Data_Eq = require("../Data.Eq");
var ReviewNotStarted = (function () {
    function ReviewNotStarted() {

    };
    ReviewNotStarted.value = new ReviewNotStarted();
    return ReviewNotStarted;
})();
var ReviewStarted = (function () {
    function ReviewStarted() {

    };
    ReviewStarted.value = new ReviewStarted();
    return ReviewStarted;
})();
var ReviewCompleted = (function () {
    function ReviewCompleted() {

    };
    ReviewCompleted.value = new ReviewCompleted();
    return ReviewCompleted;
})();
var ReviewDeclined = (function () {
    function ReviewDeclined() {

    };
    ReviewDeclined.value = new ReviewDeclined();
    return ReviewDeclined;
})();
var genericReviewCompletion = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.ReviewCompletion.ReviewNotStarted" && v.value1.length === 0)) {
        return new Data_Maybe.Just(ReviewNotStarted.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.ReviewCompletion.ReviewStarted" && v.value1.length === 0)) {
        return new Data_Maybe.Just(ReviewStarted.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.ReviewCompletion.ReviewCompleted" && v.value1.length === 0)) {
        return new Data_Maybe.Just(ReviewCompleted.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.ReviewCompletion.ReviewDeclined" && v.value1.length === 0)) {
        return new Data_Maybe.Just(ReviewDeclined.value);
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Model.ReviewCompletion.ReviewCompletion", [ {
        sigConstructor: "Model.ReviewCompletion.ReviewNotStarted", 
        sigValues: [  ]
    }, {
        sigConstructor: "Model.ReviewCompletion.ReviewStarted", 
        sigValues: [  ]
    }, {
        sigConstructor: "Model.ReviewCompletion.ReviewCompleted", 
        sigValues: [  ]
    }, {
        sigConstructor: "Model.ReviewCompletion.ReviewDeclined", 
        sigValues: [  ]
    } ]);
}, function (v) {
    if (v instanceof ReviewNotStarted) {
        return new Data_Generic.SProd("Model.ReviewCompletion.ReviewNotStarted", [  ]);
    };
    if (v instanceof ReviewStarted) {
        return new Data_Generic.SProd("Model.ReviewCompletion.ReviewStarted", [  ]);
    };
    if (v instanceof ReviewCompleted) {
        return new Data_Generic.SProd("Model.ReviewCompletion.ReviewCompleted", [  ]);
    };
    if (v instanceof ReviewDeclined) {
        return new Data_Generic.SProd("Model.ReviewCompletion.ReviewDeclined", [  ]);
    };
    throw new Error("Failed pattern match at Model.ReviewCompletion line 14, column 1 - line 14, column 68: " + [ v.constructor.name ]);
});
var isForeignReviewCompletion = new Data_Foreign_Class.IsForeign(Data_Foreign_Generic.readGeneric(genericReviewCompletion)(UReview_Prelude.jsonOptions));
var showReviewCompletion = new Data_Show.Show(Data_Generic.gShow(genericReviewCompletion));
var toFirebaseForeignOneTofive = new Data_Firebase_Foreign.ToFirebaseForeign(UReview_Prelude.writeForeign(genericReviewCompletion));
var eqReviewCompletion = new Data_Eq.Eq(Data_Generic.gEq(genericReviewCompletion));
module.exports = {
    ReviewNotStarted: ReviewNotStarted, 
    ReviewStarted: ReviewStarted, 
    ReviewCompleted: ReviewCompleted, 
    ReviewDeclined: ReviewDeclined, 
    genericReviewCompletion: genericReviewCompletion, 
    showReviewCompletion: showReviewCompletion, 
    eqReviewCompletion: eqReviewCompletion, 
    isForeignReviewCompletion: isForeignReviewCompletion, 
    toFirebaseForeignOneTofive: toFirebaseForeignOneTofive
};
