// Generated by psc version 0.9.1
"use strict";
var Data_Default = require("../Data.Default");
var Data_Either = require("../Data.Either");
var Data_Firebase_Foreign = require("../Data.Firebase.Foreign");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var Data_Generic = require("../Data.Generic");
var Data_Ord = require("../Data.Ord");
var Data_Valid = require("../Data.Valid");
var Prelude = require("../Prelude");
var Test_QuickCheck_Arbitrary = require("../Test.QuickCheck.Arbitrary");
var Test_QuickCheck_Gen = require("../Test.QuickCheck.Gen");
var UReview_Prelude = require("../UReview.Prelude");
var Data_Maybe = require("../Data.Maybe");
var Data_Show = require("../Data.Show");
var Data_Eq = require("../Data.Eq");
var Data_Function = require("../Data.Function");
var PleaseChoose = (function () {
    function PleaseChoose() {

    };
    PleaseChoose.value = new PleaseChoose();
    return PleaseChoose;
})();
var Yes = (function () {
    function Yes() {

    };
    Yes.value = new Yes();
    return Yes;
})();
var No = (function () {
    function No() {

    };
    No.value = new No();
    return No;
})();
var validPleaseChooseYesNo = new Data_Valid.Valid(function (v) {
    if (v instanceof PleaseChoose) {
        return false;
    };
    return true;
});
var pleaseChooseYesNoDefault = new Data_Default.Default(PleaseChoose.value);
var parsePleaseChooseYesNo = function (s) {
    if (s === "Yes") {
        return Yes.value;
    };
    if (s === "No") {
        return No.value;
    };
    return PleaseChoose.value;
};
var isYes = function (v) {
    if (v instanceof Yes) {
        return true;
    };
    return false;
};
var genericPleaseChooseYesNo = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Data.PleaseChooseYesNo.PleaseChoose" && v.value1.length === 0)) {
        return new Data_Maybe.Just(PleaseChoose.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "Data.PleaseChooseYesNo.Yes" && v.value1.length === 0)) {
        return new Data_Maybe.Just(Yes.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "Data.PleaseChooseYesNo.No" && v.value1.length === 0)) {
        return new Data_Maybe.Just(No.value);
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Data.PleaseChooseYesNo.PleaseChooseYesNo", [ {
        sigConstructor: "Data.PleaseChooseYesNo.PleaseChoose", 
        sigValues: [  ]
    }, {
        sigConstructor: "Data.PleaseChooseYesNo.Yes", 
        sigValues: [  ]
    }, {
        sigConstructor: "Data.PleaseChooseYesNo.No", 
        sigValues: [  ]
    } ]);
}, function (v) {
    if (v instanceof PleaseChoose) {
        return new Data_Generic.SProd("Data.PleaseChooseYesNo.PleaseChoose", [  ]);
    };
    if (v instanceof Yes) {
        return new Data_Generic.SProd("Data.PleaseChooseYesNo.Yes", [  ]);
    };
    if (v instanceof No) {
        return new Data_Generic.SProd("Data.PleaseChooseYesNo.No", [  ]);
    };
    throw new Error("Failed pattern match at Data.PleaseChooseYesNo line 17, column 1 - line 17, column 70: " + [ v.constructor.name ]);
});
var isForeignPleaseChooseYesNo = new Data_Foreign_Class.IsForeign(function (value) {
    var v = Data_Function.apply(UReview_Prelude.readForeign(isForeignPleaseChooseYesNo)(genericPleaseChooseYesNo))(value);
    if (v instanceof Data_Either.Left) {
        return new Data_Either.Right(PleaseChoose.value);
    };
    if (v instanceof Data_Either.Right) {
        return v;
    };
    throw new Error("Failed pattern match at Data.PleaseChooseYesNo line 31, column 5 - line 33, column 19: " + [ v.constructor.name ]);
});
var showPleaseChooseYesNo = new Data_Show.Show(Data_Generic.gShow(genericPleaseChooseYesNo));
var toFirebaseForeignPleaseChooseYesNo = new Data_Firebase_Foreign.ToFirebaseForeign(UReview_Prelude.writeForeign(genericPleaseChooseYesNo));
var genPleaseChooseYesNo = Test_QuickCheck_Gen.elements(Yes.value)([ No.value, PleaseChoose.value ]);
var eqPleaseChooseYesNo = new Data_Eq.Eq(Data_Generic.gEq(genericPleaseChooseYesNo));
var ordPleaseChooseYesNo = new Data_Ord.Ord(function () {
    return eqPleaseChooseYesNo;
}, Data_Generic.gCompare(genericPleaseChooseYesNo));
var asString = function (yn) {
    if (yn instanceof Yes) {
        return "Yes";
    };
    if (yn instanceof No) {
        return "No";
    };
    if (yn instanceof PleaseChoose) {
        return "Please Choose";
    };
    throw new Error("Failed pattern match at Data.PleaseChooseYesNo line 53, column 3 - line 58, column 1: " + [ yn.constructor.name ]);
};
var arbitraryPleaseChooseYesNo = new Test_QuickCheck_Arbitrary.Arbitrary(genPleaseChooseYesNo);
module.exports = {
    PleaseChoose: PleaseChoose, 
    Yes: Yes, 
    No: No, 
    asString: asString, 
    genPleaseChooseYesNo: genPleaseChooseYesNo, 
    isYes: isYes, 
    parsePleaseChooseYesNo: parsePleaseChooseYesNo, 
    genericPleaseChooseYesNo: genericPleaseChooseYesNo, 
    showPleaseChooseYesNo: showPleaseChooseYesNo, 
    eqPleaseChooseYesNo: eqPleaseChooseYesNo, 
    ordPleaseChooseYesNo: ordPleaseChooseYesNo, 
    isForeignPleaseChooseYesNo: isForeignPleaseChooseYesNo, 
    arbitraryPleaseChooseYesNo: arbitraryPleaseChooseYesNo, 
    pleaseChooseYesNoDefault: pleaseChooseYesNoDefault, 
    validPleaseChooseYesNo: validPleaseChooseYesNo, 
    toFirebaseForeignPleaseChooseYesNo: toFirebaseForeignPleaseChooseYesNo
};
