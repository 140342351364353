// Generated by psc version 0.9.1
"use strict";
var Control_Alternative = require("../Control.Alternative");
var Data_Array = require("../Data.Array");
var Data_Foldable = require("../Data.Foldable");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_Map = require("../Data.Map");
var Data_Maybe = require("../Data.Maybe");
var Data_Monoid = require("../Data.Monoid");
var Data_StrMap = require("../Data.StrMap");
var Data_Tuple = require("../Data.Tuple");
var Prelude = require("../Prelude");
var Data_Function = require("../Data.Function");
var Data_Functor = require("../Data.Functor");
var Control_Bind = require("../Control.Bind");
var Data_Semiring = require("../Data.Semiring");
var Data_Eq = require("../Data.Eq");
var Data_Ord = require("../Data.Ord");
var Loaded = function (x) {
    return x;
};
var unionKeys = function (dictOrd) {
    return function (ks) {
        return function (m) {
            return Data_Function.apply(Data_Map.fromFoldable(dictOrd)(Data_Foldable.foldableArray))(Data_Function.apply(Data_Array.catMaybes)(Data_Functor.map(Data_Functor.functorArray)(function (k) {
                return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Map.lookup(dictOrd)(k)(m))(function (v) {
                    return Data_Function.apply(Data_Maybe.Just.create)(new Data_Tuple.Tuple(k, v));
                });
            })(ks)));
        };
    };
};
var runLoaded = function (v) {
    return v;
};
var partiallyLoaded = function (v) {
    return v.present;
};
var onlyLoaded = function (v) {
    return v.presentOrEmpty;
};
var length = function (dictFoldable) {
    return function (as) {
        return Data_Foldable.foldl(dictFoldable)(function (acc) {
            return function (v) {
                return acc + 1 | 0;
            };
        })(0)(as);
    };
};
var loadedOrDefault = function (dictFoldable) {
    return function (dictFoldable1) {
        return function (keys) {
            return function (values) {
                return function ($$default) {
                    var $26 = length(dictFoldable1)(values) === length(dictFoldable)(keys);
                    if ($26) {
                        return values;
                    };
                    if (!$26) {
                        return $$default;
                    };
                    throw new Error("Failed pattern match at Data.Loaded line 132, column 3 - line 135, column 12: " + [ $26.constructor.name ]);
                };
            };
        };
    };
};
var loaded = function (dictFoldable) {
    return function (dictFoldable1) {
        return function (dictMonoid) {
            return function (keys) {
                return function (values) {
                    return loadedOrDefault(dictFoldable)(dictFoldable1)(keys)(values)(Data_Monoid.mempty(dictMonoid));
                };
            };
        };
    };
};
var loadedStrMap = function (dictFoldable) {
    return function (keys) {
        return function (values) {
            return loadedOrDefault(dictFoldable)(Data_StrMap.foldableStrMap)(keys)(values)(Data_StrMap.empty);
        };
    };
};
var mkLoaded = function (dictFoldable) {
    return function (dictFoldable1) {
        return function (dictMonoid) {
            return function (keys) {
                return function (present) {
                    var tc = length(dictFoldable)(keys);
                    var pc = length(dictFoldable1)(present);
                    var al = pc === tc;
                    var pe = (function () {
                        if (al) {
                            return present;
                        };
                        if (!al) {
                            return Data_Monoid.mempty(dictMonoid);
                        };
                        throw new Error("Failed pattern match at Data.Loaded line 43, column 10 - line 43, column 40: " + [ al.constructor.name ]);
                    })();
                    return {
                        present: present, 
                        presentOrEmpty: pe, 
                        presentCount: pc, 
                        totalCount: tc, 
                        allLoaded: al
                    };
                };
            };
        };
    };
};
var joinStrMap = function (l) {
    return function (m) {
        var compareKeys = function (v) {
            return function (v1) {
                return Data_Ord.compare(Data_Ord.ordString)(v.value0)(v1.value0);
            };
        };
        return Data_Function.apply(Data_Array.sortBy(compareKeys))(Data_Array.catMaybes(Data_Functor.map(Data_Functor.functorArray)(function (key) {
            return Data_Maybe.maybe(Data_Maybe.Nothing.value)(function (v) {
                return new Data_Maybe.Just(new Data_Tuple.Tuple(key, v));
            })(Data_StrMap.lookup(key)(m));
        })(l)));
    };
};
var loadedStrMapAsArray = function (ks) {
    return function (aMap) {
        var present = Data_Function.apply(Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return new Data_Tuple.Tuple(Data_ForeignKey.mkForeignKey(v.value0), v.value1);
        }))(joinStrMap(Data_Functor.map(Data_Functor.functorArray)(Data_ForeignKey.fkToString)(ks))(aMap));
        return mkLoaded(Data_Foldable.foldableArray)(Data_Foldable.foldableArray)(Data_Monoid.monoidArray)(ks)(present);
    };
};
var joinKeyMap = function (dictOrd) {
    return function (l) {
        return function (m) {
            var compareKeys = function (v) {
                return function (v1) {
                    return Data_Ord.compare(dictOrd)(v.value0)(v1.value0);
                };
            };
            return Data_Function.apply(Data_Array.sortBy(compareKeys))(Data_Array.catMaybes(Data_Functor.map(Data_Functor.functorArray)(function (key) {
                return Data_Maybe.maybe(Data_Maybe.Nothing.value)(function (v) {
                    return new Data_Maybe.Just(new Data_Tuple.Tuple(key, v));
                })(Data_Map.lookup(dictOrd)(key)(m));
            })(l)));
        };
    };
};
var loadedMap = function (dictOrd) {
    return function (ks) {
        return function (aMap) {
            return Data_Function.apply(mkLoaded(Data_Foldable.foldableArray)(Data_Foldable.foldableArray)(Data_Monoid.monoidArray)(ks))(joinKeyMap(dictOrd)(ks)(aMap));
        };
    };
};
module.exports = {
    joinStrMap: joinStrMap, 
    length: length, 
    loaded: loaded, 
    loadedOrDefault: loadedOrDefault, 
    loadedStrMap: loadedStrMap, 
    loadedStrMapAsArray: loadedStrMapAsArray, 
    mkLoaded: mkLoaded, 
    onlyLoaded: onlyLoaded, 
    partiallyLoaded: partiallyLoaded, 
    runLoaded: runLoaded, 
    unionKeys: unionKeys
};
