// Generated by psc version 0.9.1
"use strict";
var Prelude = require("../Prelude");
var Data_Foreign = require("../Data.Foreign");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_Tuple = require("../Data.Tuple");
var Data_Show = require("../Data.Show");
var Data_Semigroup = require("../Data.Semigroup");
var Data_Eq = require("../Data.Eq");
var ForeignLocation = function (x) {
    return x;
};
var showForeignLocation = new Data_Show.Show(function (v) {
    return "ForeignLocation {\npath: " + (v.location.path + ("\nkey: " + (Data_Show.show(Data_ForeignKey.showForeignKey)(v.location.key) + "\n(foreign: can not show)")));
});
var readForeignPart = function (dictIsForeign) {
    return function (v) {
        return Data_Foreign_Class.read(dictIsForeign)(v.foreign);
    };
};
var pathKey = function (v) {
    return new Data_Tuple.Tuple(v.location.path, v.location.key);
};
var key = function (v) {
    return v.location.key;
};
var eqForeignLocation = new Data_Eq.Eq(function (l) {
    return function (r) {
        return Data_Eq.eq(Data_Tuple.eqTuple(Data_Eq.eqString)(Data_ForeignKey.eqForeignKey))(pathKey(l))(pathKey(r));
    };
});
module.exports = {
    ForeignLocation: ForeignLocation, 
    key: key, 
    pathKey: pathKey, 
    readForeignPart: readForeignPart, 
    showForeignLocation: showForeignLocation, 
    eqForeignLocation: eqForeignLocation
};
