// Generated by psc version 0.9.1
"use strict";
var Prelude = require("../Prelude");
var Data_Generic = require("../Data.Generic");
var CSS_Property = require("../CSS.Property");
var CSS_Size = require("../CSS.Size");
var CSS_String = require("../CSS.String");
var CSS_Stylesheet = require("../CSS.Stylesheet");
var Data_Eq = require("../Data.Eq");
var Data_Ord = require("../Data.Ord");
var Control_Apply = require("../Control.Apply");
var Data_Maybe = require("../Data.Maybe");
var Data_Unit = require("../Data.Unit");
var Data_Function = require("../Data.Function");
var Data_Semigroup = require("../Data.Semigroup");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var Transformation = function (x) {
    return x;
};
var valTransformation = new CSS_Property.Val(function (v) {
    return v;
});
var translate = function (x) {
    return function (y) {
        return Data_Function.apply(Transformation)(Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_String.fromString(CSS_Property.isStringValue)("translate("))(Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_Property.value(CSS_Property.valList(CSS_Size.valSize))([ x, y ]))(CSS_String.fromString(CSS_Property.isStringValue)(")"))));
    };
};
var transforms = function ($23) {
    return CSS_Stylesheet.key(CSS_Property.valValue)(CSS_String.fromString(CSS_Property.isStringKey)("transform"))(CSS_Property.noCommas(valTransformation)($23));
};
var transform = Data_Function.apply(CSS_Stylesheet.key(valTransformation))(CSS_String.fromString(CSS_Property.isStringKey)("transform"));
var rotate = function (a) {
    return Data_Function.apply(Transformation)(Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_String.fromString(CSS_Property.isStringValue)("rotate("))(Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_Property.value(CSS_Size.valAngle)(a))(CSS_String.fromString(CSS_Property.isStringValue)(")"))));
};
var genericTransformation = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "CSS.Transform.Transformation" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Transformation))(Data_Generic.fromSpine(CSS_Property.genericValue)(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("CSS.Transform.Transformation", [ {
        sigConstructor: "CSS.Transform.Transformation", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(CSS_Property.genericValue)(Data_Generic.anyProxy);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("CSS.Transform.Transformation", [ function ($dollarq) {
        return Data_Generic.toSpine(CSS_Property.genericValue)(v);
    } ]);
});
var eqTransformation = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(CSS_Property.eqValue)(x)(y);
    };
});
var ordTransformation = new Data_Ord.Ord(function () {
    return eqTransformation;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(CSS_Property.ordValue)(x)(y);
    };
});
module.exports = {
    Transformation: Transformation, 
    rotate: rotate, 
    transform: transform, 
    transforms: transforms, 
    translate: translate, 
    eqTransformation: eqTransformation, 
    ordTransformation: ordTransformation, 
    genericTransformation: genericTransformation, 
    valTransformation: valTransformation
};
