// Generated by psc version 0.9.1
"use strict";
var Data_Generic = require("../Data.Generic");
var Test_QuickCheck_Arbitrary = require("../Test.QuickCheck.Arbitrary");
var Prelude = require("../Prelude");
var Control_Apply = require("../Control.Apply");
var Data_Maybe = require("../Data.Maybe");
var Data_Unit = require("../Data.Unit");
var Data_Show = require("../Data.Show");
var Data_Eq = require("../Data.Eq");
var Data_Functor = require("../Data.Functor");
var Data_Semigroup = require("../Data.Semigroup");
var Data_Function = require("../Data.Function");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans");
var Data_Identity = require("../Data.Identity");
var HTMLId = function (x) {
    return x;
};
var semigroupHTMLId = new Data_Semigroup.Semigroup(function (v) {
    return function (v1) {
        return Data_Function.apply(HTMLId)(v + v1);
    };
});
var mkHtmlId = function (s) {
    return s;
};
var genericHTMLId = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Components.Elements.Basic.Types.HTMLId" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(HTMLId))(Data_Generic.fromSpine(Data_Generic.genericString)(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Components.Elements.Basic.Types.HTMLId", [ {
        sigConstructor: "Components.Elements.Basic.Types.HTMLId", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("Components.Elements.Basic.Types.HTMLId", [ function ($dollarq) {
        return Data_Generic.toSpine(Data_Generic.genericString)(v);
    } ]);
});
var showHTMLId = new Data_Show.Show(Data_Generic.gShow(genericHTMLId));
var fromHtmlId = function (v) {
    return v;
};
var eqHTMLId = new Data_Eq.Eq(Data_Generic.gEq(genericHTMLId));
var arbHTMLId = new Test_QuickCheck_Arbitrary.Arbitrary(Data_Functor.map(Control_Monad_State_Trans.functorStateT(Data_Identity.functorIdentity))(HTMLId)(Test_QuickCheck_Arbitrary.arbitrary(Test_QuickCheck_Arbitrary.arbString)));
module.exports = {
    HTMLId: HTMLId, 
    fromHtmlId: fromHtmlId, 
    mkHtmlId: mkHtmlId, 
    genericHTMLId: genericHTMLId, 
    showHTMLId: showHTMLId, 
    eqHTMLId: eqHTMLId, 
    arbHTMLId: arbHTMLId, 
    semigroupHTMLId: semigroupHTMLId
};
