// Generated by psc version 0.9.1
"use strict";
var Data_Firebase_Foreign = require("../Data.Firebase.Foreign");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var Data_Foreign_Generic = require("../Data.Foreign.Generic");
var Data_Generic = require("../Data.Generic");
var Data_Ord = require("../Data.Ord");
var Data_VersionNumber = require("../Data.VersionNumber");
var Prelude = require("../Prelude");
var Test_QuickCheck_Arbitrary = require("../Test.QuickCheck.Arbitrary");
var Test_QuickCheck_Gen = require("../Test.QuickCheck.Gen");
var UReview_Prelude = require("../UReview.Prelude");
var Data_Maybe = require("../Data.Maybe");
var Data_Show = require("../Data.Show");
var Data_Eq = require("../Data.Eq");
var PleaseChoose = (function () {
    function PleaseChoose() {

    };
    PleaseChoose.value = new PleaseChoose();
    return PleaseChoose;
})();
var NotApplicable = (function () {
    function NotApplicable() {

    };
    NotApplicable.value = new NotApplicable();
    return NotApplicable;
})();
var DontKnow = (function () {
    function DontKnow() {

    };
    DontKnow.value = new DontKnow();
    return DontKnow;
})();
var Yes = (function () {
    function Yes() {

    };
    Yes.value = new Yes();
    return Yes;
})();
var No = (function () {
    function No() {

    };
    No.value = new No();
    return No;
})();
var verboseYesNo = function (yn) {
    if (yn instanceof NotApplicable) {
        return "Not Applicable";
    };
    if (yn instanceof Yes) {
        return "Yes";
    };
    if (yn instanceof No) {
        return "No";
    };
    if (yn instanceof DontKnow) {
        return "Don't Know";
    };
    if (yn instanceof PleaseChoose) {
        return "Please Choose";
    };
    throw new Error("Failed pattern match at Data.YesNo line 41, column 3 - line 48, column 1: " + [ yn.constructor.name ]);
};
var parseYesNo = function (s) {
    if (s === "Not Applicable") {
        return NotApplicable.value;
    };
    if (s === "Yes") {
        return Yes.value;
    };
    if (s === "No") {
        return No.value;
    };
    if (s === "Don't Know") {
        return DontKnow.value;
    };
    return PleaseChoose.value;
};
var genericYesNo = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Data.YesNo.PleaseChoose" && v.value1.length === 0)) {
        return new Data_Maybe.Just(PleaseChoose.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "Data.YesNo.NotApplicable" && v.value1.length === 0)) {
        return new Data_Maybe.Just(NotApplicable.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "Data.YesNo.DontKnow" && v.value1.length === 0)) {
        return new Data_Maybe.Just(DontKnow.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "Data.YesNo.Yes" && v.value1.length === 0)) {
        return new Data_Maybe.Just(Yes.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "Data.YesNo.No" && v.value1.length === 0)) {
        return new Data_Maybe.Just(No.value);
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Data.YesNo.YesNo", [ {
        sigConstructor: "Data.YesNo.PleaseChoose", 
        sigValues: [  ]
    }, {
        sigConstructor: "Data.YesNo.NotApplicable", 
        sigValues: [  ]
    }, {
        sigConstructor: "Data.YesNo.DontKnow", 
        sigValues: [  ]
    }, {
        sigConstructor: "Data.YesNo.Yes", 
        sigValues: [  ]
    }, {
        sigConstructor: "Data.YesNo.No", 
        sigValues: [  ]
    } ]);
}, function (v) {
    if (v instanceof PleaseChoose) {
        return new Data_Generic.SProd("Data.YesNo.PleaseChoose", [  ]);
    };
    if (v instanceof NotApplicable) {
        return new Data_Generic.SProd("Data.YesNo.NotApplicable", [  ]);
    };
    if (v instanceof DontKnow) {
        return new Data_Generic.SProd("Data.YesNo.DontKnow", [  ]);
    };
    if (v instanceof Yes) {
        return new Data_Generic.SProd("Data.YesNo.Yes", [  ]);
    };
    if (v instanceof No) {
        return new Data_Generic.SProd("Data.YesNo.No", [  ]);
    };
    throw new Error("Failed pattern match at Data.YesNo line 17, column 1 - line 17, column 46: " + [ v.constructor.name ]);
});
var isForeignYesNo = new Data_Foreign_Class.IsForeign(Data_Foreign_Generic.readGeneric(genericYesNo)(Data_VersionNumber.jsonOptions));
var showYesNo = new Data_Show.Show(Data_Generic.gShow(genericYesNo));
var toFirebaseForeignYesNo = new Data_Firebase_Foreign.ToFirebaseForeign(UReview_Prelude.writeForeign(genericYesNo));
var eqYesNo = new Data_Eq.Eq(Data_Generic.gEq(genericYesNo));
var ordYesNo = new Data_Ord.Ord(function () {
    return eqYesNo;
}, Data_Generic.gCompare(genericYesNo));
var arbitraryYesNo = new Test_QuickCheck_Arbitrary.Arbitrary(Test_QuickCheck_Gen.elements(Yes.value)([ No.value, DontKnow.value, PleaseChoose.value, NotApplicable.value ]));
module.exports = {
    PleaseChoose: PleaseChoose, 
    NotApplicable: NotApplicable, 
    DontKnow: DontKnow, 
    Yes: Yes, 
    No: No, 
    parseYesNo: parseYesNo, 
    verboseYesNo: verboseYesNo, 
    genericYesNo: genericYesNo, 
    showYesNo: showYesNo, 
    eqYesNo: eqYesNo, 
    ordYesNo: ordYesNo, 
    isForeignYesNo: isForeignYesNo, 
    arbitraryYesNo: arbitraryYesNo, 
    toFirebaseForeignYesNo: toFirebaseForeignYesNo
};
