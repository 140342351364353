// Generated by psc version 0.9.1
"use strict";
var Data_StrMap = require("../Data.StrMap");
var Components_Elements = require("../Components.Elements");
var Data_Firebase_Foreign = require("../Data.Firebase.Foreign");
var Data_Foreign = require("../Data.Foreign");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_Int = require("../Data.Int");
var Data_Maybe = require("../Data.Maybe");
var Data_Tuple = require("../Data.Tuple");
var Data_YesNo = require("../Data.YesNo");
var Model_Review = require("../Model.Review");
var Model_SpaLikeReviewVersion = require("../Model.SpaLikeReviewVersion");
var Prelude = require("../Prelude");
var Pux_Html = require("../Pux.Html");
var Pux_Html_Attributes = require("../Pux.Html.Attributes");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var Pux_Html_Events = require("../Pux.Html.Events");
var Pux_Router = require("../Pux.Router");
var Route_HtmlElements = require("../Route.HtmlElements");
var State_Review = require("../State.Review");
var State_Types = require("../State.Types");
var View_SessionDescriptionView = require("../View.SessionDescriptionView");
var Components_Elements_Basic_Types = require("../Components.Elements.Basic.Types");
var Data_Semigroup = require("../Data.Semigroup");
var Data_Function = require("../Data.Function");
var Data_Functor = require("../Data.Functor");
var Data_Show = require("../Data.Show");
var reviewQuestionFieldPath = function (v) {
    return function (reviewPath) {
        return reviewPath + ("/reviewQuestions/" + v);
    };
};
var yesNoQuestion = function (v) {
    return function (questionText) {
        return function (currentValue) {
            return function (reviewPath) {
                var fieldPath = reviewQuestionFieldPath(v)(reviewPath);
                var selecter = {
                    htmlId: v, 
                    label: questionText, 
                    selected: new Data_Maybe.Just(currentValue), 
                    items: [ Data_YesNo.PleaseChoose.value, Data_YesNo.Yes.value, Data_YesNo.No.value, Data_YesNo.DontKnow.value, Data_YesNo.NotApplicable.value ], 
                    action: function (yn) {
                        return new State_Types.SetPathValue(fieldPath, Data_Firebase_Foreign.toFire(Data_YesNo.toFirebaseForeignYesNo)(yn));
                    }, 
                    renderKey: Data_YesNo.verboseYesNo, 
                    renderValue: function (v1) {
                        return Data_Function.apply(Pux_Html_Elements.text)(Data_YesNo.verboseYesNo(v1));
                    }, 
                    stringToActionKey: Data_YesNo.parseYesNo
                };
                return Pux_Html_Elements.div([  ])([ Components_Elements.buildSelect([  ])(selecter) ]);
            };
        };
    };
};
var oneToFiveActionKey = function (v) {
    return Data_Function.apply(Model_SpaLikeReviewVersion.OneToFive)((function () {
        if (v === "1") {
            return 1;
        };
        if (v === "2") {
            return 2;
        };
        if (v === "3") {
            return 3;
        };
        if (v === "4") {
            return 4;
        };
        if (v === "5") {
            return 5;
        };
        return 0;
    })());
};
var overallEvaluationQuestion = function (v) {
    return function (questionText) {
        return function (currentValue) {
            return function (reviewPath) {
                var s = function (v1) {
                    if (v1 === 1) {
                        return "Strong reject";
                    };
                    if (v1 === 2) {
                        return "Weak reject";
                    };
                    if (v1 === 3) {
                        return "Neither reject nor accept";
                    };
                    if (v1 === 4) {
                        return "Weak accept";
                    };
                    if (v1 === 5) {
                        return "Strong accept";
                    };
                    return "Do you accept or reject this session?";
                };
                var fieldPath = reviewQuestionFieldPath(v)(reviewPath);
                var selecter = {
                    htmlId: v, 
                    label: questionText, 
                    selected: new Data_Maybe.Just(currentValue), 
                    items: Data_Functor.map(Data_Functor.functorArray)(Model_SpaLikeReviewVersion.OneToFive)([ 0, 1, 2, 3, 4, 5 ]), 
                    action: function (answer) {
                        return new State_Types.SetPathValue(fieldPath, Data_Firebase_Foreign.toFire(Model_SpaLikeReviewVersion.toFirebaseForeignOneTofive)(answer));
                    }, 
                    renderKey: function (v1) {
                        return Data_Show.show(Data_Show.showInt)(v1);
                    }, 
                    renderValue: function (v1) {
                        return Data_Function.apply(Pux_Html_Elements.text)(s(v1));
                    }, 
                    stringToActionKey: oneToFiveActionKey
                };
                return Pux_Html_Elements.div([  ])([ Components_Elements.buildSelect([  ])(selecter) ]);
            };
        };
    };
};
var ownConfidenceQuestion = function (v) {
    return function (questionText) {
        return function (currentValue) {
            return function (reviewPath) {
                var s = function (v1) {
                    if (v1 === 1) {
                        return "Not at all confident";
                    };
                    if (v1 === 2) {
                        return "Not confident";
                    };
                    if (v1 === 3) {
                        return "Somewhat confident";
                    };
                    if (v1 === 4) {
                        return "Confident";
                    };
                    if (v1 === 5) {
                        return "Extremely confident";
                    };
                    return "How confident are you in your evaluation?";
                };
                var fieldPath = reviewQuestionFieldPath(v)(reviewPath);
                var selecter = {
                    htmlId: v, 
                    label: questionText, 
                    selected: new Data_Maybe.Just(currentValue), 
                    items: Data_Functor.map(Data_Functor.functorArray)(Model_SpaLikeReviewVersion.OneToFive)([ 0, 1, 2, 3, 4, 5 ]), 
                    action: function (answer) {
                        return new State_Types.SetPathValue(fieldPath, Data_Firebase_Foreign.toFire(Model_SpaLikeReviewVersion.toFirebaseForeignOneTofive)(answer));
                    }, 
                    renderKey: function (v1) {
                        return Data_Show.show(Data_Show.showInt)(v1);
                    }, 
                    renderValue: function (v1) {
                        return Data_Function.apply(Pux_Html_Elements.text)(s(v1));
                    }, 
                    stringToActionKey: oneToFiveActionKey
                };
                return Pux_Html_Elements.div([  ])([ Components_Elements.buildSelect([  ])(selecter) ]);
            };
        };
    };
};
var event2ForeignString = function (ev) {
    return Data_Function.apply(Data_Maybe.Just.create)(Data_Foreign.toForeign(ev.target.value));
};
var longTextQuestion = function (v) {
    return function (questionText) {
        return function (currentValue) {
            return function (reviewPath) {
                var fieldPath = reviewQuestionFieldPath(v)(reviewPath);
                return Pux_Html_Elements.div([  ])([ Pux_Html.withChild(Pux_Html.withAttr(Pux_Html_Elements.label)(Pux_Html_Attributes.htmlFor(v)))(Pux_Html_Elements.text(questionText)), Pux_Html_Elements.br([  ])([  ]), Pux_Html_Elements.textarea([ Pux_Html_Attributes.id_(v), Pux_Html_Attributes.cols(80), Pux_Html_Attributes.rows(6), Pux_Html_Events.onInput(State_Types.SetPathEvent.create(fieldPath)(event2ForeignString)), Pux_Html_Attributes.defaultValue(currentValue) ])([  ]) ]);
            };
        };
    };
};
var showFullQuestions = function (path) {
    return function (v) {
        return Pux_Html_Elements.div([ Pux_Html_Attributes.className("small-12 medium-10 large-9 column bg-black5 pa2 mt2") ])([ Pux_Html_Elements.h3([  ])([ Pux_Html_Elements.text("Your review of this session") ]), Pux_Html_Elements.form([  ])([ Pux_Html_Elements.fieldset([  ])([ yesNoQuestion("subjectClearlyDefined")("Is the subject to be covered clearly defined?")(v.subjectClearlyDefined)(path), yesNoQuestion("sessionOfInterest")("Do you think the session will be of interest to the event audience?")(v.sessionOfInterest)(path), yesNoQuestion("technicallySound")("Will the session content be sound?")(v.technicallySound)(path), yesNoQuestion("wellOrganised")("Do you think the session will be well organised and well delivered?")(v.wellOrganised)(path), yesNoQuestion("wouldYouGo")("Would you, or someone you know, go to this session?")(v.wouldYouGo)(path), overallEvaluationQuestion("overallEvaluation")("Overall evaluation")(v.overallEvaluation)(path), ownConfidenceQuestion("ownConfidence")("Your confidence in your own evaluation")(v.ownConfidence)(path), longTextQuestion("comments")("Comments")(v.comments)(path) ]) ]), Pux_Html_Elements.p([ Pux_Html_Attributes.className("mt2 bold") ])([ Pux_Html_Elements.text("Your review will be saved automatically") ]) ]);
    };
};
var event2ForeignInt = function (ev) {
    return Data_Function.apply(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Foreign.toForeign))(Data_Int.fromString(ev.target.value));
};
var oneToFiveQuestion = function (v) {
    return function (questionText) {
        return function (currentValue) {
            return function (reviewPath) {
                var fieldPath = reviewQuestionFieldPath(v)(reviewPath);
                return Pux_Html_Elements.div([  ])([ Pux_Html.withChild(Pux_Html.withAttr(Pux_Html_Elements.label)(Pux_Html_Attributes.htmlFor(v)))(Pux_Html_Elements.text(questionText)), Pux_Html_Elements.br([  ])([  ]), Pux_Html_Elements.input([ Pux_Html_Attributes.type_("range"), Pux_Html_Attributes.min("1"), Pux_Html_Attributes.max("5"), Pux_Html_Attributes.value(Model_SpaLikeReviewVersion.editOneToFive(currentValue)), Pux_Html_Attributes.id_(v), Pux_Html_Events.onChange(State_Types.SetPathEvent.create(fieldPath)(event2ForeignInt)) ])([  ]), Pux_Html_Elements.span([  ])([ Pux_Html_Elements.text(Model_SpaLikeReviewVersion.editOneToFive(currentValue) + "/5") ]), Pux_Html_Elements.br([  ])([  ]) ]);
            };
        };
    };
};
var bottomNav = function (v) {
    var shortCode = Data_ForeignKey.fkToString(v.gatheringId);
    return Route_HtmlElements.navBox([ Pux_Html_Elements.ul([  ])([ Pux_Html_Elements.li([  ])([ Route_HtmlElements.backToTop ]), Pux_Html_Elements.li([  ])([ Pux_Router.link("/event/" + (Data_ForeignKey.fkToString(v.gatheringId) + ("/reviewer/" + Data_ForeignKey.fkToString(v.reviewerId))))([  ])([ Data_Function.apply(Pux_Html_Elements.text)("Back to my reviews for " + shortCode) ]) ]), Pux_Html_Elements.li([  ])([ Pux_Router.link("/event/" + Data_ForeignKey.fkToString(v.gatheringId))([  ])([ Data_Function.apply(Pux_Html_Elements.text)("Back to " + Data_ForeignKey.fkToString(v.gatheringId)) ]) ]) ]) ]);
};
var showFullReview = function (state) {
    return function (path) {
        return function (v) {
            var shortCode = Data_ForeignKey.fkToString(v.gatheringId);
            return Pux_Html_Elements.div([ Pux_Html_Attributes.className("row") ])([ Pux_Html_Elements.div([ Pux_Html_Attributes.className("small-12 medium-10 large-9 column mt2") ])([ Pux_Html_Elements.h2([ Pux_Html_Attributes.className("mb4") ])([ Pux_Html_Elements.text(" Session Review") ]) ]), Pux_Html_Elements.div([ Pux_Html_Attributes.className("small-12 medium-10 large-9 column ") ])([ View_SessionDescriptionView.sessionDescriptionView(state)(v.sessionDescriptionId) ]), showFullQuestions(path)(v.reviewQuestions), bottomNav(v) ]);
        };
    };
};
var reviewView = function (state) {
    return function (reviewKey) {
        var reviewSession = State_Review.findReviewSession(reviewKey)(state);
        var reviewId = Data_ForeignKey.fkToString(reviewKey);
        var review = Data_StrMap.lookup(reviewId)(state.reviews);
        var path = "/reviews/" + reviewId;
        return Pux_Html_Elements.section([  ])([ (function () {
            if (reviewSession instanceof Data_Maybe.Nothing) {
                return Pux_Html.withChild(Pux_Html_Elements.p)(Pux_Html_Elements.text("loading review, please wait..."));
            };
            if (reviewSession instanceof Data_Maybe.Just) {
                return showFullReview(state)(path)(reviewSession.value0.value0);
            };
            throw new Error("Failed pattern match at View.ReviewView line 38, column 3 - line 40, column 53: " + [ reviewSession.constructor.name ]);
        })() ]);
    };
};
module.exports = {
    reviewView: reviewView
};
