// Generated by psc version 0.9.1
"use strict";
var Data_Array_1 = require("../Data.Array");
var Data_Map_1 = require("../Data.Map");
var Data_Set = require("../Data.Set");
var Data_Array_1 = require("../Data.Array");
var Data_Firebase_Foreign = require("../Data.Firebase.Foreign");
var Data_Foldable = require("../Data.Foldable");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var Data_Foreign_Generic = require("../Data.Foreign.Generic");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_Generic = require("../Data.Generic");
var Data_Map_1 = require("../Data.Map");
var Data_Maybe = require("../Data.Maybe");
var Data_PathTo = require("../Data.PathTo");
var Data_SmallPositiveInt = require("../Data.SmallPositiveInt");
var Data_Tuple = require("../Data.Tuple");
var Data_VersionNumber = require("../Data.VersionNumber");
var Model_Event_Key = require("../Model.Event.Key");
var Model_ReviewCompletion = require("../Model.ReviewCompletion");
var Model_Reviewer = require("../Model.Reviewer");
var Model_SessionDescription = require("../Model.SessionDescription");
var Model_SpaLikeReviewVersion_1 = require("../Model.SpaLikeReviewVersion");
var Model_SpaLikeReviewVersion_1 = require("../Model.SpaLikeReviewVersion");
var Prelude = require("../Prelude");
var Test_QuickCheck_Arbitrary = require("../Test.QuickCheck.Arbitrary");
var Test_QuickCheck_Gen = require("../Test.QuickCheck.Gen");
var UReview_Prelude = require("../UReview.Prelude");
var Control_Apply = require("../Control.Apply");
var Data_Unit = require("../Data.Unit");
var Data_Show = require("../Data.Show");
var Data_Eq = require("../Data.Eq");
var Data_Functor = require("../Data.Functor");
var Control_Bind = require("../Control.Bind");
var Data_Function = require("../Data.Function");
var Control_Applicative = require("../Control.Applicative");
var Data_Ord = require("../Data.Ord");
var Data_List = require("../Data.List");
var Data_Semigroup = require("../Data.Semigroup");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra");
var Data_BooleanAlgebra = require("../Data.BooleanAlgebra");
var Data_Semiring = require("../Data.Semiring");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var Data_Unfoldable = require("../Data.Unfoldable");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans");
var Data_Identity = require("../Data.Identity");
var ReviewKey = function (x) {
    return x;
};
var Review = function (x) {
    return x;
};
var thisEvent = function (ek) {
    return function (v) {
        return Data_Eq.eq(Data_ForeignKey.eqForeignKey)(ek)(v.gatheringId);
    };
};
var sessionsWithoutReviews = function (keys) {
    return function (reviews) {
        var reviewedKeys = Data_Function.apply(Data_Array_1.nub(Data_ForeignKey.eqForeignKey))(Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.sessionDescriptionId;
        })(reviews));
        return Data_Array_1.difference(Data_ForeignKey.eqForeignKey)(Data_Array_1.nub(Data_ForeignKey.eqForeignKey)(keys))(Data_Array_1.nub(Data_ForeignKey.eqForeignKey)(reviewedKeys));
    };
};
var sessionKeysByNumberOfReviews = function (m) {
    return Data_Function.apply(Data_Map_1.fromFoldableWith(Data_Ord.ordInt)(Data_List.foldableList)(Data_Semigroup.append(Data_Semigroup.semigroupArray)))(Data_Function.apply(Data_Functor.map(Data_List.functorList)(function (v) {
        return new Data_Tuple.Tuple(v.value1, [ v.value0 ]);
    }))(Data_Map_1.toList(m)));
};
var sameSession = function (v) {
    return function (v1) {
        return Data_Eq.eq(Data_ForeignKey.eqForeignKey)(v.sessionDescriptionId)(v1.sessionDescriptionId);
    };
};
var runReview = function (v) {
    return v;
};
var reviewByMe = function (reviewerKey) {
    return function (v) {
        return Data_Eq.eq(Data_ForeignKey.eqForeignKey)(reviewerKey)(v.reviewerId);
    };
};
var pathToReviewKey = new Data_PathTo.PathTo(function (v) {
    return Data_PathTo.pathTo(Data_ForeignKey.pathToForeignKey)(v);
});
var patchReview = function (eK) {
    return function (rK) {
        return function (sdK) {
            return function (v) {
                return Data_Function.apply(Review)((function () {
                    var $59 = {};
                    for (var $60 in v) {
                        if (v.hasOwnProperty($60)) {
                            $59[$60] = v[$60];
                        };
                    };
                    $59.gatheringId = eK;
                    $59.reviewerId = rK;
                    $59.sessionDescriptionId = sdK;
                    return $59;
                })());
            };
        };
    };
};
var notEnoughReviews = function (required) {
    return function (reviews) {
        return Data_Array_1.length(reviews) < Data_SmallPositiveInt.runSmallPositiveInt(required);
    };
};
var noReviewByMe = function (dictFoldable) {
    return function (reviewerKey) {
        return function (reviews) {
            return Data_Function.apply(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Foldable.any(dictFoldable)(Data_BooleanAlgebra.booleanAlgebraBoolean)(reviewByMe(reviewerKey))(reviews));
        };
    };
};
var isComplete = function (v) {
    return Model_SpaLikeReviewVersion_1.isComplete(v.reviewQuestions);
};
var getReviewerKey = function (v) {
    return v.reviewerId;
};
var genericReviewKey = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.Review.ReviewKey" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(ReviewKey))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Model.Review.ReviewKey", [ {
        sigConstructor: "Model.Review.ReviewKey", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("Model.Review.ReviewKey", [ function ($dollarq) {
        return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v);
    } ]);
});
var isForeignReviewKey = new Data_Foreign_Class.IsForeign(Data_Foreign_Generic.readGeneric(genericReviewKey)(UReview_Prelude.jsonOptions));
var showReviewKey = new Data_Show.Show(Data_Generic.gShow(genericReviewKey));
var toFirebaseForeignReviewKey = new Data_Firebase_Foreign.ToFirebaseForeign(UReview_Prelude.writeForeign(genericReviewKey));
var genericReview = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.Review.Review" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Review))((function (r) {
            if (r instanceof Data_Generic.SRecord && r.value0.length === 5) {
                return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(function (gatheringId1) {
                    return function (modelVersion1) {
                        return function (reviewQuestions1) {
                            return function (reviewerId1) {
                                return function (sessionDescriptionId1) {
                                    return {
                                        gatheringId: gatheringId1, 
                                        modelVersion: modelVersion1, 
                                        reviewQuestions: reviewQuestions1, 
                                        reviewerId: reviewerId1, 
                                        sessionDescriptionId: sessionDescriptionId1
                                    };
                                };
                            };
                        };
                    };
                }))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)((r.value0[0]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Data_VersionNumber.genericVersionNumber)((r.value0[1]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Model_SpaLikeReviewVersion_1.genericSpaLikeReviewVersion)((r.value0[2]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)((r.value0[3]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Data_ForeignKey.genericForeignKey)((r.value0[4]).recValue(Data_Unit.unit)));
            };
            return Data_Maybe.Nothing.value;
        })(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Model.Review.Review", [ {
        sigConstructor: "Model.Review.Review", 
        sigValues: [ function ($dollarq1) {
            return new Data_Generic.SigRecord([ {
                recLabel: "gatheringId", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "modelVersion", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_VersionNumber.genericVersionNumber)(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "reviewQuestions", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Model_SpaLikeReviewVersion_1.genericSpaLikeReviewVersion)(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "reviewerId", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "sessionDescriptionId", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_ForeignKey.genericForeignKey)(Data_Generic.anyProxy);
                }
            } ]);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("Model.Review.Review", [ function ($dollarq) {
        return new Data_Generic.SRecord([ {
            recLabel: "gatheringId", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v.gatheringId);
            }
        }, {
            recLabel: "modelVersion", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_VersionNumber.genericVersionNumber)(v.modelVersion);
            }
        }, {
            recLabel: "reviewQuestions", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Model_SpaLikeReviewVersion_1.genericSpaLikeReviewVersion)(v.reviewQuestions);
            }
        }, {
            recLabel: "reviewerId", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v.reviewerId);
            }
        }, {
            recLabel: "sessionDescriptionId", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_ForeignKey.genericForeignKey)(v.sessionDescriptionId);
            }
        } ]);
    } ]);
});
var isForeignReview = new Data_Foreign_Class.IsForeign(Data_Foreign_Generic.readGeneric(genericReview)(UReview_Prelude.jsonOptions));
var showReview = new Data_Show.Show(Data_Generic.gShow(genericReview));
var toFirebaseForeignReview = new Data_Firebase_Foreign.ToFirebaseForeign(UReview_Prelude.writeForeign(genericReview));
var eventKey = function (v) {
    return v.gatheringId;
};
var eqReviewKey = new Data_Eq.Eq(Data_Generic.gEq(genericReviewKey));
var eqReview = new Data_Eq.Eq(Data_Generic.gEq(genericReview));
var countReviewsPerSessionId = function (dictFoldable) {
    return function (dictFunctor) {
        return function (sks) {
            return function (reviews) {
                var sessionCount = Data_Function.apply(Data_Map_1.fromFoldable(Data_ForeignKey.ordForeignKey)(dictFoldable))(Data_Functor.map(dictFunctor)(function (sessionDescriptionId) {
                    return new Data_Tuple.Tuple(sessionDescriptionId, 0);
                })(sks));
                var reviewCount = Data_Function.apply(Data_Map_1.fromFoldableWith(Data_ForeignKey.ordForeignKey)(dictFoldable)(Data_Semiring.add(Data_Semiring.semiringInt)))(Data_Functor.map(dictFunctor)(function (v) {
                    return new Data_Tuple.Tuple(v.sessionDescriptionId, 1);
                })(reviews));
                return Data_Map_1.unionWith(Data_ForeignKey.ordForeignKey)(Data_Semiring.add(Data_Semiring.semiringInt))(sessionCount)(reviewCount);
            };
        };
    };
};
var countReviews = function (dictFoldable) {
    return function (dictFunctor) {
        return function (sks) {
            return function (reviews) {
                return Data_Function.apply(sessionKeysByNumberOfReviews)(countReviewsPerSessionId(dictFoldable)(dictFunctor)(sks)(reviews));
            };
        };
    };
};
var reviewedSessionICouldAddTo = function (reviewerKey) {
    return function (sessionKeys) {
        return function (reviews) {
            var sessionsReviewedByMe = Data_Function.apply(Data_Set.fromFoldable(Data_Foldable.foldableArray)(Data_ForeignKey.ordForeignKey))(Data_Functor.map(Data_Functor.functorArray)(function ($105) {
                return (function (v) {
                    return v.sessionDescriptionId;
                })(runReview($105));
            })(Data_Array_1.filter(reviewByMe(reviewerKey))(reviews)));
            var allReviewedSessionKeys = Data_Function.apply(Data_Set.fromFoldable(Data_Foldable.foldableArray)(Data_ForeignKey.ordForeignKey))(Data_Functor.map(Data_Functor.functorArray)(function ($106) {
                return (function (v) {
                    return v.sessionDescriptionId;
                })(runReview($106));
            })(reviews));
            var sessionsNotReviewedByMe = Data_Set.difference(Data_ForeignKey.ordForeignKey)(allReviewedSessionKeys)(sessionsReviewedByMe);
            var reviewsNotByMe = Data_Array_1.filter(function (v) {
                return Data_Set.member(Data_ForeignKey.ordForeignKey)(v.sessionDescriptionId)(sessionsNotReviewedByMe);
            })(reviews);
            var numberOfReviewsPerSessionId = countReviews(Data_Foldable.foldableArray)(Data_Functor.functorArray)(Data_Set.toUnfoldable(Data_Unfoldable.unfoldableArray)(sessionsNotReviewedByMe))(reviewsNotByMe);
            var m = Data_Map_1.findMin(numberOfReviewsPerSessionId);
            var mk = Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
                return v.value;
            })(m);
            if (mk instanceof Data_Maybe.Nothing) {
                return Data_Maybe.Nothing.value;
            };
            if (mk instanceof Data_Maybe.Just) {
                return Data_Array_1.head(mk.value0);
            };
            throw new Error("Failed pattern match at Model.Review line 204, column 2 - line 206, column 49: " + [ mk.constructor.name ]);
        };
    };
};
var completeReview = function (sessionId) {
    return function (incomplete) {
        return Data_Function.apply(Review)({
            gatheringId: incomplete.gatheringId, 
            reviewerId: incomplete.reviewerId, 
            modelVersion: Data_VersionNumber.mkVersionNumber, 
            sessionDescriptionId: sessionId, 
            reviewQuestions: Model_SpaLikeReviewVersion_1.nullSpaLikeReviewVersion
        });
    };
};
var mkReview = function (eK) {
    return function (sdKey) {
        return function (reviewerKey) {
            return Data_Function.apply(completeReview(sdKey))({
                gatheringId: eK, 
                reviewerId: reviewerKey
            });
        };
    };
};
var nextReview = function (ek) {
    return function (rk) {
        return function (v) {
            return function (v1) {
                return function (v2) {
                    if (v.length === 0) {
                        return Data_Maybe.Nothing.value;
                    };
                    var eventReviews = Data_Array_1.filter(thisEvent(ek))(v2);
                    var aSessionWithoutReview = Data_Function.apply(Data_Array_1.head)(sessionsWithoutReviews(v)(v2));
                    var nextKey = (function () {
                        if (aSessionWithoutReview instanceof Data_Maybe.Just) {
                            return new Data_Maybe.Just(aSessionWithoutReview.value0);
                        };
                        if (aSessionWithoutReview instanceof Data_Maybe.Nothing) {
                            return reviewedSessionICouldAddTo(rk)(v)(v2);
                        };
                        throw new Error("Failed pattern match at Model.Review line 193, column 15 - line 195, column 69: " + [ aSessionWithoutReview.constructor.name ]);
                    })();
                    return Data_Functor.map(Data_Maybe.functorMaybe)(function (sk) {
                        return completeReview(sk)({
                            gatheringId: ek, 
                            reviewerId: rk
                        });
                    })(nextKey);
                };
            };
        };
    };
};
var bySessionId = function (v) {
    return function (v1) {
        return Data_Ord.compare(Data_ForeignKey.ordForeignKey)(v.sessionDescriptionId)(v1.sessionDescriptionId);
    };
};
var sessionsReviewedButNotYetByMe = function (myKey) {
    return function (required) {
        return function (reviews) {
            var firstSessionId = function (reviews1) {
                return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array_1.head(reviews1))(function (v) {
                    return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(v.sessionDescriptionId);
                });
            };
            return Data_Function.apply(Data_Array_1.mapMaybe(firstSessionId))(Data_Function.apply(Data_Array_1.filter(noReviewByMe(Data_Foldable.foldableArray)(myKey)))(Data_Function.apply(Data_Array_1.filter(notEnoughReviews(required)))(Data_Function.apply(Data_Array_1.groupBy(sameSession))(Data_Array_1.sortBy(bySessionId)(reviews)))));
        };
    };
};
var sessionsWithoutRequiredAmountOfReviews = function (myKey) {
    return function (required) {
        return function (reviews) {
            var firstSessionId = function (reviews1) {
                return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array_1.head(reviews1))(function (v) {
                    return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(v.sessionDescriptionId);
                });
            };
            return Data_Function.apply(Data_Array_1.mapMaybe(firstSessionId))(Data_Function.apply(Data_Array_1.filter(noReviewByMe(Data_Foldable.foldableArray)(myKey)))(Data_Function.apply(Data_Array_1.filter(notEnoughReviews(required)))(Data_Function.apply(Data_Array_1.groupBy(sameSession))(Data_Array_1.sortBy(bySessionId)(reviews)))));
        };
    };
};
var asForeignKey = function (v) {
    return v;
};
var arbitraryReviewKey = new Test_QuickCheck_Arbitrary.Arbitrary(Data_Functor.map(Control_Monad_State_Trans.functorStateT(Data_Identity.functorIdentity))(ReviewKey)(Test_QuickCheck_Arbitrary.arbitrary(Data_ForeignKey.arbForeignKey)));
var genReviewKey = Test_QuickCheck_Arbitrary.arbitrary(arbitraryReviewKey);
var arbitraryReview = new Test_QuickCheck_Arbitrary.Arbitrary(Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Data_ForeignKey.arbForeignKey))(function (v) {
    return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Data_ForeignKey.arbForeignKey))(function (v1) {
        return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Data_VersionNumber.arbVersionNumber))(function (v2) {
            return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Data_ForeignKey.arbForeignKey))(function (v3) {
                return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Model_SpaLikeReviewVersion_1.arbitrarySpaLikeReviewViewVersion))(function (v4) {
                    return Data_Function.apply(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity)))({
                        gatheringId: v, 
                        reviewerId: v1, 
                        modelVersion: v2, 
                        sessionDescriptionId: v3, 
                        reviewQuestions: v4
                    });
                });
            });
        });
    });
}));
module.exports = {
    Review: Review, 
    ReviewKey: ReviewKey, 
    asForeignKey: asForeignKey, 
    bySessionId: bySessionId, 
    completeReview: completeReview, 
    countReviews: countReviews, 
    eventKey: eventKey, 
    genReviewKey: genReviewKey, 
    getReviewerKey: getReviewerKey, 
    mkReview: mkReview, 
    nextReview: nextReview, 
    patchReview: patchReview, 
    runReview: runReview, 
    sameSession: sameSession, 
    genericReviewKey: genericReviewKey, 
    showReviewKey: showReviewKey, 
    eqReviewKey: eqReviewKey, 
    toFirebaseForeignReviewKey: toFirebaseForeignReviewKey, 
    arbitraryReviewKey: arbitraryReviewKey, 
    isForeignReviewKey: isForeignReviewKey, 
    pathToReviewKey: pathToReviewKey, 
    genericReview: genericReview, 
    showReview: showReview, 
    eqReview: eqReview, 
    isForeignReview: isForeignReview, 
    toFirebaseForeignReview: toFirebaseForeignReview, 
    arbitraryReview: arbitraryReview
};
