// Generated by psc version 0.9.1
"use strict";
var Data_Either = require("../Data.Either");
var Data_Maybe = require("../Data.Maybe");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_ForeignLocation = require("../Data.ForeignLocation");
var Prelude = require("../Prelude");
var Ports_Firebase_Types = require("../Ports.Firebase.Types");
var Web_Firebase_DataSnapshot = require("../Web.Firebase.DataSnapshot");
var Data_Function = require("../Data.Function");
var Data_Semigroup = require("../Data.Semigroup");
var snapshotToForeignLocation = function (path) {
    return function (snap) {
        var $0 = Web_Firebase_DataSnapshot.key(snap);
        if ($0 instanceof Data_Maybe.Nothing) {
            return Data_Function.apply(Data_Either.Left.create)("Could not read key for added child of " + path);
        };
        if ($0 instanceof Data_Maybe.Just) {
            return Data_Function.apply(Data_Either.Right.create)({
                location: {
                    key: Data_ForeignKey.mkForeignKey($0.value0), 
                    path: path
                }, 
                foreign: Web_Firebase_DataSnapshot.val(snap)
            });
        };
        throw new Error("Failed pattern match at Data.Firebase.SnapshotToForeignLocation line 22, column 39 - line 24, column 108: " + [ $0.constructor.name ]);
    };
};
module.exports = {
    snapshotToForeignLocation: snapshotToForeignLocation
};
