// Generated by psc version 0.9.1
"use strict";
var $foreign = require("./foreign");
var Components_GeneratedFileDownload = require("../Components.GeneratedFileDownload");
var Components_Elements = require("../Components.Elements");
var Data_Array_1 = require("../Data.Array");
var Data_Array_1 = require("../Data.Array");
var Data_CommaSeparated = require("../Data.CommaSeparated");
var Data_Foreign = require("../Data.Foreign");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_List = require("../Data.List");
var Data_MediaType_Common = require("../Data.MediaType.Common");
var Data_String = require("../Data.String");
var Model_Event = require("../Model.Event");
var Model_ReviewSummary = require("../Model.ReviewSummary");
var Prelude = require("../Prelude");
var Pux_Html = require("../Pux.Html");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var State_Event = require("../State.Event");
var State_Review = require("../State.Review");
var State_Types = require("../State.Types");
var Data_Unfoldable = require("../Data.Unfoldable");
var Data_Function = require("../Data.Function");
var Data_Show = require("../Data.Show");
var Data_Semigroup = require("../Data.Semigroup");
var Control_Category = require("../Control.Category");
var Data_Functor = require("../Data.Functor");
var Data_Ord = require("../Data.Ord");
var fullJSON = function (shortCode) {
    return function (sessionReviews) {
        return function (state) {
            var theArray = Data_List.toUnfoldable(Data_Unfoldable.unfoldableArray)(sessionReviews);
            var theJSON = Data_Function.apply($foreign.stringify)(Data_Foreign.toForeign(theArray));
            var theLength = Data_Function.apply(Data_Show.show(Data_Show.showInt))(Data_String.length(theJSON));
            var fileName = Data_ForeignKey.fkToString(shortCode) + "-reviewsummary.json";
            var downloadSpec = Components_GeneratedFileDownload.mkDownloadSpec(Data_MediaType_Common.applicationJSON)(fileName)(Control_Category.id(Control_Category.categoryFn))(theJSON);
            var downloadButton = Components_GeneratedFileDownload.mkDownloadButton("Export JSON")(downloadSpec);
            return Pux_Html_Elements.div([  ])([ Data_Function.apply(Components_Elements.ptext)("JSON size: " + theLength), Data_Functor.map(Pux_Html_Elements.functorHtml)(State_Types.DownloadEventCSV.create)(Components_GeneratedFileDownload.view(downloadButton)) ]);
        };
    };
};
var fullCsv = function (shortCode) {
    return function (sessionReviews) {
        return function (state) {
            var fullSessions = Data_Functor.map(Data_Functor.functorArray)(Model_ReviewSummary.mkReviewSummaryFull)(Data_List.toUnfoldable(Data_Unfoldable.unfoldableArray)(sessionReviews));
            var fileName = Data_ForeignKey.fkToString(shortCode) + "-reviewsummary.csv";
            var csvFromSessions = function (s) {
                return Data_Function.apply(Data_CommaSeparated.commaSeparated)(Data_Array_1.cons(Model_ReviewSummary.reviewSummaryFullHeadings)(Data_Functor.map(Data_Functor.functorArray)(Model_ReviewSummary.reviewSummaryFullAsArray)(s)));
            };
            var downloadSpec = Components_GeneratedFileDownload.mkDownloadSpec(Data_MediaType_Common.textCSV)(fileName)(Control_Category.id(Control_Category.categoryFn))(csvFromSessions(fullSessions));
            var downloadButton = Components_GeneratedFileDownload.mkDownloadButton("Export CSV")(downloadSpec);
            return Data_Functor.map(Pux_Html_Elements.functorHtml)(State_Types.DownloadEventCSV.create)(Components_GeneratedFileDownload.view(downloadButton));
        };
    };
};
var csvTextArea = function (shortCode) {
    return function (sessionReviews) {
        return function (state) {
            var fullSessions = Data_Functor.map(Data_Functor.functorArray)(Model_ReviewSummary.mkReviewSummaryFull)(Data_List.toUnfoldable(Data_Unfoldable.unfoldableArray)(sessionReviews));
            var reviewSummaries = Data_Functor.map(Data_Functor.functorArray)(Model_ReviewSummary.reviewSummaryFullAsArray)(fullSessions);
            var csvFromSessions = Data_Function.apply(Data_CommaSeparated.commaSeparated)(Data_Array_1.cons(Model_ReviewSummary.reviewSummaryFullHeadings)(reviewSummaries));
            return Pux_Html_Elements.div([  ])([ Components_Elements.ptext("Session summary count: " + Data_Show.show(Data_Show.showInt)(Data_List.length(sessionReviews))), Pux_Html_Elements.textarea([  ])([ Pux_Html_Elements.text(csvFromSessions) ]) ]);
        };
    };
};
var csvOfSessionDescriptionReviews = function (eventKey) {
    return function (event) {
        return function (state) {
            var sessionCount = Data_Function.apply(Data_Array_1.length)(State_Event.keysOfVisibleSessionDescriptions(event)(state));
            var reviewSummaries = State_Review.reviewsBySession(eventKey)(state);
            var reviewSummaryCount = Data_List.length(reviewSummaries);
            var $0 = reviewSummaryCount >= sessionCount;
            if ($0) {
                return Pux_Html_Elements.div([  ])([ fullCsv(eventKey)(reviewSummaries)(state), csvTextArea(eventKey)(reviewSummaries)(state) ]);
            };
            if (!$0) {
                return Components_Elements.ptext("Loaded " + (Data_Show.show(Data_Show.showInt)(reviewSummaryCount) + (" review summaries of " + (Data_Show.show(Data_Show.showInt)(sessionCount) + " visible session descriptions. CSV download only available once all sessions have at least one review."))));
            };
            throw new Error("Failed pattern match at View.ReviewSummaryCSV line 24, column 3 - line 30, column 210: " + [ $0.constructor.name ]);
        };
    };
};
module.exports = {
    csvOfSessionDescriptionReviews: csvOfSessionDescriptionReviews
};
