// Generated by psc version 0.9.1
"use strict";
var $foreign = require("./foreign");
var Prelude = require("../Prelude");
var Control_Monad_Eff = require("../Control.Monad.Eff");
var Control_Alt = require("../Control.Alt");
var Control_Plus = require("../Control.Plus");
var Control_MonadPlus = require("../Control.MonadPlus");
var Data_Function_Uncurried = require("../Data.Function.Uncurried");
var Data_Maybe = require("../Data.Maybe");
var Data_String = require("../Data.String");
var Data_Traversable = require("../Data.Traversable");
var Data_Int = require("../Data.Int");
var Data_Array = require("../Data.Array");
var Data_List = require("../Data.List");
var Data_Tuple = require("../Data.Tuple");
var Data_Map = require("../Data.Map");
var DOM = require("../DOM");
var Pux_Html = require("../Pux.Html");
var Pux_Html_Attributes = require("../Pux.Html.Attributes");
var Global = require("../Global");
var Signal = require("../Signal");
var Data_Functor = require("../Data.Functor");
var Data_Function = require("../Data.Function");
var Control_Apply = require("../Control.Apply");
var Control_Applicative = require("../Control.Applicative");
var Data_Foldable = require("../Data.Foldable");
var Control_Bind = require("../Control.Bind");
var Control_MonadZero = require("../Control.MonadZero");
var Data_Eq = require("../Data.Eq");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var Data_Ord = require("../Data.Ord");
var Data_Boolean = require("../Data.Boolean");
var Data_Unit = require("../Data.Unit");
var Data_Semigroup = require("../Data.Semigroup");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var Path = (function () {
    function Path(value0) {
        this.value0 = value0;
    };
    Path.create = function (value0) {
        return new Path(value0);
    };
    return Path;
})();
var Query = (function () {
    function Query(value0) {
        this.value0 = value0;
    };
    Query.create = function (value0) {
        return new Query(value0);
    };
    return Query;
})();
var Match = function (x) {
    return x;
};
var str = Data_Function.apply(Match)(function (r) {
    if (r instanceof Data_List.Cons && r.value0 instanceof Path) {
        return Data_Function.apply(Data_Maybe.Just.create)(new Data_Tuple.Tuple(r.value1, r.value0.value0));
    };
    return Data_Maybe.Nothing.value;
});
var sampleUrl = $foreign.createUrlSignal(Signal.constant);
var parsePart = function (s) {
    var parts = Data_Function.apply(Data_List.fromFoldable(Data_Foldable.foldableArray))(Data_Function.apply(Data_String.split("&"))(Data_String.drop(1)(s)));
    var part2tuple = function (part) {
        var param$prime = Data_String.split("=")(part);
        return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Function.apply(Control_MonadZero.guard(Data_Maybe.monadZeroMaybe))(Data_Array.length(param$prime) === 2))(function () {
            return Control_Apply.apply(Data_Maybe.applyMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Tuple.Tuple.create)(Data_Array.head(param$prime)))(Data_Array.index(param$prime)(1));
        });
    };
    return Data_Maybe.fromMaybe(new Path(s))(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Function.apply(Control_MonadZero.guard(Data_Maybe.monadZeroMaybe))(Data_String.take(1)(s) === "?"))(function () {
        return Data_Function.apply(Data_Functor.map(Data_Maybe.functorMaybe)(function ($64) {
            return Query.create(Data_Map.fromList(Data_Ord.ordString)($64));
        }))(Data_Traversable.traverse(Data_List.traversableList)(Data_Maybe.applicativeMaybe)(part2tuple)(parts));
    }));
};
var routeFromUrl = function (url) {
    if (url === "/") {
        return Data_List.Nil.value;
    };
    if (Data_Boolean.otherwise) {
        return Data_Function.apply(Data_Functor.map(Data_List.functorList)(parsePart))(Data_Function.apply(Data_List.drop(1))(Data_List.fromFoldable(Data_Foldable.foldableArray)(Data_String.split("/")(url))));
    };
    throw new Error("Failed pattern match at Pux.Router line 157, column 1 - line 158, column 87: " + [ url.constructor.name ]);
};
var router = function (url) {
    return function (v) {
        var result = Data_Function.apply(v)(routeFromUrl(url));
        return Data_Maybe.maybe(Data_Maybe.Nothing.value)(function ($65) {
            return Data_Maybe.Just.create(Data_Tuple.snd($65));
        })(result);
    };
};
var params = Data_Function.apply(Match)(function (r) {
    if (r instanceof Data_List.Cons && r.value0 instanceof Query) {
        return Data_Function.apply(Data_Maybe.Just.create)(new Data_Tuple.Tuple(r.value1, r.value0.value0));
    };
    return Data_Maybe.Nothing.value;
});
var param = function (key) {
    return Data_Function.apply(Match)(function (r) {
        if (r instanceof Data_List.Cons && r.value0 instanceof Query) {
            var $18 = Data_Map.lookup(Data_Ord.ordString)(key)(r.value0.value0);
            if ($18 instanceof Data_Maybe.Nothing) {
                return Data_Maybe.Nothing.value;
            };
            if ($18 instanceof Data_Maybe.Just) {
                return Data_Function.apply(Data_Maybe.Just.create)(new Data_Tuple.Tuple(new Data_List.Cons(Data_Function.apply(function ($66) {
                    return Query.create(Data_Map["delete"](Data_Ord.ordString)(key)($66));
                })(r.value0.value0), r.value1), $18.value0));
            };
            throw new Error("Failed pattern match at Pux.Router line 117, column 7 - line 119, column 74: " + [ $18.constructor.name ]);
        };
        return Data_Maybe.Nothing.value;
    });
};
var num = Data_Function.apply(Match)(function (r) {
    if (r instanceof Data_List.Cons && r.value0 instanceof Path) {
        var res = Global.readFloat(r.value0.value0);
        var $24 = Global["isNaN"](res);
        if ($24) {
            return Data_Maybe.Nothing.value;
        };
        if (!$24) {
            return Data_Function.apply(Data_Maybe.Just.create)(new Data_Tuple.Tuple(r.value1, res));
        };
        throw new Error("Failed pattern match at Pux.Router line 88, column 7 - line 91, column 28: " + [ $24.constructor.name ]);
    };
    return Data_Maybe.Nothing.value;
});
var matchFunctor = new Data_Functor.Functor(function (f) {
    return function (v) {
        return Data_Function.apply(Match)(function (r) {
            return Data_Function.apply(Data_Maybe.maybe(Data_Maybe.Nothing.value)(function (t) {
                return Data_Function.apply(Data_Maybe.Just.create)(new Data_Tuple.Tuple(Data_Tuple.fst(t), f(Data_Tuple.snd(t))));
            }))(v(r));
        });
    };
});
var matchApply = new Control_Apply.Apply(function () {
    return matchFunctor;
}, function (v) {
    return function (v1) {
        return Data_Function.apply(Match)(function (r1) {
            var $32 = v(r1);
            if ($32 instanceof Data_Maybe.Nothing) {
                return Data_Maybe.Nothing.value;
            };
            if ($32 instanceof Data_Maybe.Just) {
                var $33 = v1($32.value0.value0);
                if ($33 instanceof Data_Maybe.Nothing) {
                    return Data_Maybe.Nothing.value;
                };
                if ($33 instanceof Data_Maybe.Just) {
                    return Data_Function.apply(Data_Maybe.Just.create)(new Data_Tuple.Tuple($33.value0.value0, $32.value0.value1($33.value0.value1)));
                };
                throw new Error("Failed pattern match at Pux.Router line 146, column 28 - line 148, column 51: " + [ $33.constructor.name ]);
            };
            throw new Error("Failed pattern match at Pux.Router line 144, column 5 - line 148, column 51: " + [ $32.constructor.name ]);
        });
    };
});
var matchApplicative = new Control_Applicative.Applicative(function () {
    return matchApply;
}, function (a) {
    return function (r) {
        return Data_Function.apply(Control_Applicative.pure(Data_Maybe.applicativeMaybe))(new Data_Tuple.Tuple(r, a));
    };
});
var matchAlt = new Control_Alt.Alt(function () {
    return matchFunctor;
}, function (v) {
    return function (v1) {
        return Data_Function.apply(Match)(function (r) {
            return Control_Alt.alt(Data_Maybe.altMaybe)(v(r))(v1(r));
        });
    };
});
var matchPlus = new Control_Plus.Plus(function () {
    return matchAlt;
}, function (r) {
    return Data_Maybe.Nothing.value;
});
var lit = function (part) {
    return Data_Function.apply(Match)(function (r) {
        if (r instanceof Data_List.Cons && (r.value0 instanceof Path && r.value0.value0 === part)) {
            return Data_Function.apply(Data_Maybe.Just.create)(new Data_Tuple.Tuple(r.value1, Data_Unit.unit));
        };
        return Data_Maybe.Nothing.value;
    });
};
var link = function (url) {
    return function (attrs) {
        return function (children) {
            var newAttrs = Data_Semigroup.append(Data_Semigroup.semigroupArray)(attrs)([ $foreign.linkHandler(url), Pux_Html_Attributes.attr("href")(url) ]);
            return Data_Function_Uncurried.runFn3(Pux_Html_Elements.element)("a")(newAttrs)(children);
        };
    };
};
var $$int = Data_Function.apply(Match)(function (r) {
    if (r instanceof Data_List.Cons && r.value0 instanceof Path) {
        return Data_Function.apply(Data_Maybe.maybe(Data_Maybe.Nothing.value)(function ($67) {
            return Data_Maybe.Just.create(Data_Tuple.Tuple.create(r.value1)($67));
        }))(Data_Int.fromString(r.value0.value0));
    };
    return Data_Maybe.Nothing.value;
});
var end = Data_Function.apply(Match)(function (r) {
    if (r instanceof Data_List.Cons && (r.value0 instanceof Query && r.value1 instanceof Data_List.Nil)) {
        return Data_Function.apply(Data_Maybe.Just.create)(new Data_Tuple.Tuple(Data_List.Nil.value, Data_Unit.unit));
    };
    if (r instanceof Data_List.Nil) {
        return Data_Function.apply(Data_Maybe.Just.create)(new Data_Tuple.Tuple(Data_List.Nil.value, Data_Unit.unit));
    };
    return Data_Maybe.Nothing.value;
});
var bool = Data_Function.apply(Match)(function (r) {
    if (r instanceof Data_List.Cons && (r.value0 instanceof Path && r.value0.value0 === "true")) {
        return Data_Function.apply(Data_Maybe.Just.create)(new Data_Tuple.Tuple(r.value1, true));
    };
    if (r instanceof Data_List.Cons && (r.value0 instanceof Path && r.value0.value0 === "false")) {
        return Data_Function.apply(Data_Maybe.Just.create)(new Data_Tuple.Tuple(r.value1, false));
    };
    return Data_Maybe.Nothing.value;
});
var any = Data_Function.apply(Match)(function (r) {
    if (r instanceof Data_List.Cons) {
        return Data_Function.apply(Data_Maybe.Just.create)(new Data_Tuple.Tuple(r.value1, Data_Unit.unit));
    };
    return Data_Maybe.Nothing.value;
});
module.exports = {
    Path: Path, 
    Query: Query, 
    any: any, 
    bool: bool, 
    end: end, 
    "int": $$int, 
    link: link, 
    lit: lit, 
    num: num, 
    param: param, 
    params: params, 
    router: router, 
    sampleUrl: sampleUrl, 
    str: str, 
    matchFunctor: matchFunctor, 
    matchAlt: matchAlt, 
    matchApply: matchApply, 
    matchPlus: matchPlus, 
    matchApplicative: matchApplicative, 
    navigateTo: $foreign.navigateTo
};
