// Generated by psc version 0.9.1
"use strict";
var Control_Apply = require("../Control.Apply");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_ForeignKey_StrMap = require("../Data.ForeignKey.StrMap");
var Data_ForeignLocation = require("../Data.ForeignLocation");
var Data_Loaded = require("../Data.Loaded");
var Data_Maybe = require("../Data.Maybe");
var Data_StrMap = require("../Data.StrMap");
var Data_Tuple = require("../Data.Tuple");
var Model_Event = require("../Model.Event");
var Model_SessionDescription = require("../Model.SessionDescription");
var Prelude = require("../Prelude");
var State_ApplyFound = require("../State.ApplyFound");
var State_Types = require("../State.Types");
var UReview_UserNotifications = require("../UReview.UserNotifications");
var Data_Eq = require("../Data.Eq");
var Control_Bind = require("../Control.Bind");
var Data_Function = require("../Data.Function");
var Data_Ring = require("../Data.Ring");
var Control_Category = require("../Control.Category");
var Data_Either = require("../Data.Either");
var Data_Functor = require("../Data.Functor");
var Control_Applicative = require("../Control.Applicative");
var validateNewSessionDescription = function (state) {
    var newSd = Model_SessionDescription.validateSessionDescription(state.newSessionDescription);
    var success = Model_SessionDescription.isValidSessionDescription(newSd);
    var anError = UReview_UserNotifications.mkErrorNotification("Please fill in all the required fields. They are marked with a \u2022 .");
    var aSucess = UReview_UserNotifications.mkSuccessNotification("Submitting session, please contact us or try again if you don't see the thank you page shortly.");
    var newNotification = (function () {
        if (success) {
            return aSucess;
        };
        if (!success) {
            return anError;
        };
        throw new Error("Failed pattern match at State.SessionDescription line 92, column 23 - line 92, column 59: " + [ success.constructor.name ]);
    })();
    var $3 = {};
    for (var $4 in state) {
        if (state.hasOwnProperty($4)) {
            $3[$4] = state[$4];
        };
    };
    $3.newSessionDescription = Model_SessionDescription.validateSessionDescription(state.newSessionDescription);
    $3.notification = newNotification;
    return $3;
};
var proposalHasBeenSubmitted = function (s) {
    return Data_Eq.eq(Model_SessionDescription.eqSubmissionState)(Model_SessionDescription.Submitting.value)((Model_SessionDescription.runSessionDescription(s.newSessionDescription)).isSubmitting);
};
var loadedSessionDescriptions = function (ks) {
    return function (state) {
        return Data_Loaded.loadedStrMapAsArray(ks)(state.sessionDescriptions);
    };
};
var humanIdForDescription = function (state) {
    return function (eventId) {
        return function (sessionId) {
            var findEvent = function (key) {
                return function (state1) {
                    return Data_StrMap.lookup(Data_ForeignKey.fkToString(key))(state1.events);
                };
            };
            return Control_Bind.bind(Data_Maybe.bindMaybe)(findEvent(eventId)(state))(function (v) {
                return Model_Event.indexOfSessionId(sessionId)(v);
            });
        };
    };
};
var setSessionDescription = function (sessionId) {
    return function (v) {
        return function (state) {
            var humanId = Data_Function.apply(Data_Maybe.maybe(-1)(Control_Category.id(Control_Category.categoryFn)))(humanIdForDescription(state)(v.eventId)(sessionId));
            var newSd = Data_Function.apply(Model_SessionDescription.SessionDescription)((function () {
                var $9 = {};
                for (var $10 in v) {
                    if (v.hasOwnProperty($10)) {
                        $9[$10] = v[$10];
                    };
                };
                $9.sessionid = humanId;
                return $9;
            })());
            var $11 = {};
            for (var $12 in state) {
                if (state.hasOwnProperty($12)) {
                    $11[$12] = state[$12];
                };
            };
            $11.sessionDescriptions = Data_ForeignKey_StrMap.insertKey(sessionId)(newSd)(state.sessionDescriptions);
            return $11;
        };
    };
};
var findSessionDescription = function (key) {
    return function (state) {
        return Data_ForeignKey_StrMap.lookup(key)(state.sessionDescriptions);
    };
};
var editSession = function (eventId) {
    return function (state) {
        var $13 = proposalHasBeenSubmitted(state);
        if ($13) {
            var $14 = {};
            for (var $15 in state) {
                if (state.hasOwnProperty($15)) {
                    $14[$15] = state[$15];
                };
            };
            $14.newSessionDescription = Model_SessionDescription.newSessionDescription(eventId)(Model_SessionDescription.sessionTypes);
            return $14;
        };
        if (!$13) {
            var $16 = {};
            for (var $17 in state) {
                if (state.hasOwnProperty($17)) {
                    $16[$17] = state[$17];
                };
            };
            $16.newSessionDescription = Model_SessionDescription.toEditingState(eventId)(state.newSessionDescription);
            return $16;
        };
        throw new Error("Failed pattern match at State.SessionDescription line 57, column 3 - line 60, column 87: " + [ $13.constructor.name ]);
    };
};
var applyForeignSessionDescription = function (foreignLocation) {
    return function (state) {
        return Control_Apply.apply(Data_Either.applyEither)(Control_Apply.apply(Data_Either.applyEither)(Data_Functor.map(Data_Either.functorEither)(setSessionDescription)(Data_Function.apply(Control_Applicative.pure(Data_Either.applicativeEither))(Data_ForeignLocation.key(foreignLocation))))(Data_ForeignLocation.readForeignPart(Model_SessionDescription.isForeignSessionDescription)(foreignLocation)))(Control_Applicative.pure(Data_Either.applicativeEither)(state));
    };
};
module.exports = {
    applyForeignSessionDescription: applyForeignSessionDescription, 
    editSession: editSession, 
    findSessionDescription: findSessionDescription, 
    loadedSessionDescriptions: loadedSessionDescriptions, 
    proposalHasBeenSubmitted: proposalHasBeenSubmitted, 
    setSessionDescription: setSessionDescription, 
    validateNewSessionDescription: validateNewSessionDescription
};
