// Generated by psc version 0.9.1
"use strict";
var Prelude = require("../Prelude");
var Data_Generic = require("../Data.Generic");
var CSS_Property = require("../CSS.Property");
var CSS_String = require("../CSS.String");
var CSS_Stylesheet = require("../CSS.Stylesheet");
var Data_Eq = require("../Data.Eq");
var Data_Ord = require("../Data.Ord");
var Control_Apply = require("../Control.Apply");
var Data_Maybe = require("../Data.Maybe");
var Data_Unit = require("../Data.Unit");
var Data_Function = require("../Data.Function");
var Overflow = function (x) {
    return x;
};
var visible = Data_Function.apply(Overflow)(CSS_String.fromString(CSS_Property.isStringValue)("visible"));
var valOverflow = new CSS_Property.Val(function (v) {
    return v;
});
var scroll = Data_Function.apply(Overflow)(CSS_String.fromString(CSS_Property.isStringValue)("scroll"));
var overflowY = Data_Function.apply(CSS_Stylesheet.key(valOverflow))(CSS_String.fromString(CSS_Property.isStringKey)("overflow-y"));
var overflowX = Data_Function.apply(CSS_Stylesheet.key(valOverflow))(CSS_String.fromString(CSS_Property.isStringKey)("overflow-x"));
var overflowInherit = Data_Function.apply(Overflow)(CSS_String.fromString(CSS_Property.isStringValue)("inherit"));
var overflowAuto = Data_Function.apply(Overflow)(CSS_String.fromString(CSS_Property.isStringValue)("auto"));
var overflow = Data_Function.apply(CSS_Stylesheet.key(valOverflow))(CSS_String.fromString(CSS_Property.isStringKey)("overflow"));
var hidden = Data_Function.apply(Overflow)(CSS_String.fromString(CSS_Property.isStringValue)("hidden"));
var genericOverflow = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "CSS.Overflow.Overflow" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Overflow))(Data_Generic.fromSpine(CSS_Property.genericValue)(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("CSS.Overflow.Overflow", [ {
        sigConstructor: "CSS.Overflow.Overflow", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(CSS_Property.genericValue)(Data_Generic.anyProxy);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("CSS.Overflow.Overflow", [ function ($dollarq) {
        return Data_Generic.toSpine(CSS_Property.genericValue)(v);
    } ]);
});
var eqOverflow = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(CSS_Property.eqValue)(x)(y);
    };
});
var ordOverflow = new Data_Ord.Ord(function () {
    return eqOverflow;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(CSS_Property.ordValue)(x)(y);
    };
});
module.exports = {
    Overflow: Overflow, 
    hidden: hidden, 
    overflow: overflow, 
    overflowAuto: overflowAuto, 
    overflowInherit: overflowInherit, 
    overflowX: overflowX, 
    overflowY: overflowY, 
    scroll: scroll, 
    visible: visible, 
    eqOverflow: eqOverflow, 
    ordOverflow: ordOverflow, 
    genericOverflow: genericOverflow, 
    valOverflow: valOverflow
};
