// Generated by psc version 0.9.1
"use strict";
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_Maybe = require("../Data.Maybe");
var Model_Event_Key = require("../Model.Event.Key");
var Model_UserProfile = require("../Model.UserProfile");
var Prelude = require("../Prelude");
var Selected = (function () {
    function Selected(value0) {
        this.value0 = value0;
    };
    Selected.create = function (value0) {
        return new Selected(value0);
    };
    return Selected;
})();
var Committed = (function () {
    function Committed() {

    };
    Committed.value = new Committed();
    return Committed;
})();
var update = function (users) {
    return function (action) {
        return function (state) {
            if (action instanceof Selected) {
                return Data_Maybe.maybe(state)(function (e) {
                    return {
                        eventId: new Data_Maybe.Just(e), 
                        user: Model_UserProfile.findUserByUid(action.value0)(users)
                    };
                })(state.eventId);
            };
            if (action instanceof Committed) {
                return state;
            };
            throw new Error("Failed pattern match at Components.ManageReviewPanel.Types line 42, column 3 - line 44, column 18: " + [ action.constructor.name ]);
        };
    };
};
var init = function (e) {
    return {
        eventId: e, 
        user: Data_Maybe.Nothing.value
    };
};
var nothing = init(Data_Maybe.Nothing.value);
var asCertainState = function (v) {
    if (v.eventId instanceof Data_Maybe.Just && v.user instanceof Data_Maybe.Just) {
        return new Data_Maybe.Just({
            eventId: v.eventId.value0, 
            uid: Data_ForeignKey.mkForeignKey(v.user.value0.uid)
        });
    };
    return Data_Maybe.Nothing.value;
};
module.exports = {
    Selected: Selected, 
    Committed: Committed, 
    asCertainState: asCertainState, 
    init: init, 
    nothing: nothing, 
    update: update
};
