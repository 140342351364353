// Generated by psc version 0.9.1
"use strict";
var Components_DB = require("../Components.DB");
var Components_GeneratedFileDownload = require("../Components.GeneratedFileDownload");
var Components_ManageReviewPanel_Types = require("../Components.ManageReviewPanel.Types");
var Ports_Firebase_Types = require("../Ports.Firebase.Types");
var Signal_Channel_1 = require("../Signal.Channel");
var Components_NewSessionDescription_Action = require("../Components.NewSessionDescription.Action");
var Components_NewSessionDescription_State = require("../Components.NewSessionDescription.State");
var Control_Apply = require("../Control.Apply");
var Control_Bind = require("../Control.Bind");
var Control_Monad_Aff = require("../Control.Monad.Aff");
var Control_Monad_Aff_Unsafe = require("../Control.Monad.Aff.Unsafe");
var Control_Monad_Eff = require("../Control.Monad.Eff");
var Control_Monad_Eff_Class = require("../Control.Monad.Eff.Class");
var Control_Monad_Eff_Console = require("../Control.Monad.Eff.Console");
var Control_Monad_Eff_Exception = require("../Control.Monad.Eff.Exception");
var DOM = require("../DOM");
var Data_Either = require("../Data.Either");
var Data_Firebase_Event = require("../Data.Firebase.Event");
var Data_Firebase_PanelChair = require("../Data.Firebase.PanelChair");
var Data_Firebase_Review = require("../Data.Firebase.Review");
var Data_Firebase_Reviewer = require("../Data.Firebase.Reviewer");
var Data_Firebase_SessionDescription = require("../Data.Firebase.SessionDescription");
var Data_Firebase_Subscription = require("../Data.Firebase.Subscription");
var Data_Firebase_WriteParentChild = require("../Data.Firebase.WriteParentChild");
var Data_Foreign = require("../Data.Foreign");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_Maybe = require("../Data.Maybe");
var Data_Tuple = require("../Data.Tuple");
var Model_My = require("../Model.My");
var Model_SessionDescription = require("../Model.SessionDescription");
var Model_UserProfile = require("../Model.UserProfile");
var Network_RemoteData = require("../Network.RemoteData");
var Prelude = require("../Prelude");
var Pux = require("../Pux");
var Route_Action = require("../Route.Action");
var Signal_Channel_1 = require("../Signal.Channel");
var State_Authenticate = require("../State.Authenticate");
var State_Authentication = require("../State.Authentication");
var State_CurrentMockup = require("../State.CurrentMockup");
var State_DataDependencyInterpreter = require("../State.DataDependencyInterpreter");
var State_DoChildAdded = require("../State.DoChildAdded");
var State_EffModelHelpers = require("../State.EffModelHelpers");
var State_Event = require("../State.Event");
var State_EventDependencies = require("../State.EventDependencies");
var State_GlobalRole = require("../State.GlobalRole");
var State_SessionDescription = require("../State.SessionDescription");
var State_SubscribeReadModelForRoute = require("../State.SubscribeReadModelForRoute");
var State_Types = require("../State.Types");
var UReview_UserNotifications = require("../UReview.UserNotifications");
var Web_Firebase_Authentication_Eff = require("../Web.Firebase.Authentication.Eff");
var Web_Firebase_Channel = require("../Web.Firebase.Channel");
var Data_Function = require("../Data.Function");
var Control_Applicative = require("../Control.Applicative");
var Data_Boolean = require("../Data.Boolean");
var Data_Functor = require("../Data.Functor");
var Data_Foldable = require("../Data.Foldable");
var UReview_Route_Types = require("../UReview.Route.Types");
var UReview_Route_AsUrl = require("../UReview.Route.AsUrl");
var Control_Category = require("../Control.Category");
var signalError = function (input) {
    return function (error) {
        return UReview_UserNotifications.sendErrorMessage(input)(Control_Monad_Eff_Exception.message(error));
    };
};
var setRoute = function (route) {
    return function (state) {
        var $5 = {};
        for (var $6 in state) {
            if (state.hasOwnProperty($6)) {
                $5[$6] = state[$6];
            };
        };
        $5.route = route;
        return $5;
    };
};
var registerAuthHandler = function (state) {
    return function (channel) {
        var onPresent = Control_Monad_Eff_Console.log("auth handler already registered");
        var onNotPresent = function __do() {
            Control_Monad_Eff_Console.log("registering auth handler")();
            return Web_Firebase_Authentication_Eff.onAuth(State_Authenticate.onAuthCallback(channel))(state.rootRef)();
        };
        return Data_Function.apply(Control_Monad_Eff_Class.liftEff(Control_Monad_Aff.monadEffAff))((function () {
            if (state.authHandlerPresent) {
                return onPresent;
            };
            if (!state.authHandlerPresent) {
                return onNotPresent;
            };
            throw new Error("Failed pattern match at State line 236, column 7 - line 238, column 37: " + [ state.authHandlerPresent.constructor.name ]);
        })());
    };
};
var withAuthHandler = function (route) {
    return function (channel) {
        return function (state) {
            return {
                state: (function () {
                    var $8 = {};
                    for (var $9 in state) {
                        if (state.hasOwnProperty($9)) {
                            $8[$9] = state[$9];
                        };
                    };
                    $8.authHandlerPresent = true;
                    return $8;
                })(), 
                effects: [ Control_Apply.applySecond(Control_Monad_Aff.applyAff)(registerAuthHandler(state)(channel))(Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(State_Types.Noop.value)) ]
            };
        };
    };
};
var panelChairsRoute = function (route) {
    return function (state) {
        return function (channel) {
            return function (eventKey) {
                var key = new Data_Maybe.Just(eventKey);
                var loadingEvent = (function () {
                    var $10 = {};
                    for (var $11 in state) {
                        if (state.hasOwnProperty($11)) {
                            $10[$11] = state[$11];
                        };
                    };
                    $10.current = {
                        event: new Data_Tuple.Tuple(key, Network_RemoteData.Loading.value)
                    };
                    $10.new = {
                        reviewerFor: Components_ManageReviewPanel_Types.init(key)
                    };
                    return $10;
                })();
                return State_DataDependencyInterpreter.subscribeTopLevel(State_EventDependencies.dependOnSingleEventOnly(eventKey))(loadingEvent);
            };
        };
    };
};
var onlyAff = function (state) {
    return function (f) {
        return Pux.onlyEffects(state)([ Control_Apply.applySecond(Control_Monad_Aff.applyAff)(f)(Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(State_Types.Noop.value)) ]);
    };
};
var notifyE = function (s) {
    return function (v) {
        return Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(Data_Function.apply(State_Types.NotifyUser.create)(UReview_UserNotifications.mkErrorNotification(s)));
    };
};
var eventRoute = function (state) {
    return function (channel) {
        return function (eventKey) {
            var initAddReviewerComponent = function (s) {
                var $12 = {};
                for (var $13 in s) {
                    if (s.hasOwnProperty($13)) {
                        $12[$13] = s[$13];
                    };
                };
                $12.new = {
                    reviewerFor: Components_ManageReviewPanel_Types.init(new Data_Maybe.Just(eventKey))
                };
                return $12;
            };
            return Data_Function.apply(State_EffModelHelpers.applyAll([ State_SubscribeReadModelForRoute.subscribePartsForRoute ]))(initAddReviewerComponent(state));
        };
    };
};
var pageViewAfterAuthentication = function (route) {
    return function (input) {
        return function (state) {
            var subscribeEvents = function (state1) {
                if (Model_My.isAdmin(state1.my)) {
                    return State_DataDependencyInterpreter.subscribeTopLevel(State_EventDependencies.dependOnEventsOnly)(state1);
                };
                if (Data_Boolean.otherwise) {
                    var dependencies = Data_Functor.map(Data_Functor.functorArray)(function (shortCode) {
                        return State_EventDependencies.dependOnSingleEventOnly(shortCode);
                    })(Model_My.playsRoleInEvents(state1.my));
                    return State_DataDependencyInterpreter.subscribeAll(Data_Foldable.foldableArray)(dependencies)(state1);
                };
                throw new Error("Failed pattern match at State line 184, column 5 - line 193, column 41: " + [ state1.constructor.name ]);
            };
            if (route instanceof UReview_Route_Types.SignOutRoute) {
                return State_Authentication.unAuthenticated(state);
            };
            if (route instanceof UReview_Route_Types.Events) {
                return subscribeEvents(state);
            };
            if (route instanceof UReview_Route_Types.PanelChairsRoute) {
                return panelChairsRoute(route)(state)(input)(route.value0);
            };
            if (route instanceof UReview_Route_Types.EventRoute) {
                return eventRoute(state)(input)(route.value0);
            };
            if (route instanceof UReview_Route_Types.ProposeSessionRoute) {
                return Pux.onlyEffects(state)([ Control_Apply.applySecond(Control_Monad_Aff.applyAff)(Route_Action.navigateTo(UReview_Route_AsUrl.routeAsUrlstring)(new UReview_Route_Types.ProposeSessionRoute(route.value0)))(Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(State_Types.Noop.value)) ]);
            };
            return State_SubscribeReadModelForRoute.subscribePartsForRoute(state);
        };
    };
};
var pageView = function (route) {
    return function (state) {
        return function (input) {
            return State_EffModelHelpers.applyEffModel(State_SubscribeReadModelForRoute.subscribePartsForUnauthenticatedRoute)(State_EffModelHelpers.applyEffModel(withAuthHandler(route)(input))(Data_Function.apply(pageViewAfterAuthentication(route)(input))(setRoute(route)(state))));
        };
    };
};
var catchException$prime = function (handler) {
    return function (f) {
        return Control_Bind.bind(Control_Monad_Aff.bindAff)(Control_Monad_Aff.attempt(Control_Monad_Aff_Unsafe.unsafeInterleaveAff(f)))(function (v) {
            return Data_Function.apply(Control_Applicative.pure(Control_Monad_Aff.applicativeAff))(Data_Either.either(handler)(Control_Category.id(Control_Category.categoryFn))(v));
        });
    };
};
var notifyUserOnFailure = function (state) {
    return function (f) {
        var handler = function (error) {
            var $20 = State_GlobalRole.hasAdministratorRole(state);
            if ($20) {
                return Data_Function.apply(State_Types.NotifyUser.create)(UReview_UserNotifications.mkErrorNotification(Control_Monad_Eff_Exception.message(error)));
            };
            if (!$20) {
                return State_Types.Noop.value;
            };
            throw new Error("Failed pattern match at State line 71, column 20 - line 74, column 27: " + [ $20.constructor.name ]);
        };
        return catchException$prime(handler)(f);
    };
};
var breakDown = function (state) {
    return function (firebasePath) {
        return function (value) {
            return Control_Bind.bind(Control_Monad_Aff.bindAff)(Data_Firebase_WriteParentChild.setAtPath(firebasePath)(value)(state.rootRef))(function () {
                return Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(State_Types.Noop.value);
            });
        };
    };
};
var setValueAt = function (state) {
    return function (v) {
        return function (v1) {
            if (v1 instanceof Data_Maybe.Nothing) {
                return State_EffModelHelpers.noop(state);
            };
            if (v1 instanceof Data_Maybe.Just) {
                return {
                    state: state, 
                    effects: [ breakDown(state)(v)(v1.value0) ]
                };
            };
            throw new Error("Failed pattern match at State line 250, column 1 - line 250, column 56: " + [ state.constructor.name, v.constructor.name, v1.constructor.name ]);
        };
    };
};
var updateWithException = function (action) {
    return function (state) {
        if (action instanceof State_Types.Noop) {
            return State_EffModelHelpers.noop(state);
        };
        if (action instanceof State_Types.NavigateTo) {
            return Pux.onlyEffects(state)([ Control_Apply.applySecond(Control_Monad_Aff.applyAff)(Route_Action.navigateTo(UReview_Route_AsUrl.routeAsUrlstring)(action.value0))(Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(State_Types.Noop.value)) ]);
        };
        if (action instanceof State_Types.PageView) {
            return pageView(action.value0)(state)(state.channel);
        };
        if (action instanceof State_Types.AuthenticateGoogle) {
            return State_Authenticate.authenticate(state)(state.channel);
        };
        if (action instanceof State_Types.Authenticated) {
            return State_Authentication.authenticated(action.value0)(state)(state.channel);
        };
        if (action instanceof State_Types.UnAuthenticated) {
            if (state.userProfile instanceof Network_RemoteData.Success) {
                return State_Authentication.unAuthenticated(state);
            };
            return State_EffModelHelpers.noop(state);
        };
        if (action instanceof State_Types.NotifyUser) {
            return UReview_UserNotifications.notifyUser(action.value0)(state);
        };
        if (action instanceof State_Types.ChangeState) {
            return Data_Function.apply(Pux.noEffects)(action.value0(state));
        };
        if (action instanceof State_Types.RolesChanged) {
            return State_Authentication.subscribeToUsers(action.value0)((function () {
                var $33 = {};
                for (var $34 in state) {
                    if (state.hasOwnProperty($34)) {
                        $33[$34] = state[$34];
                    };
                };
                $33.my = Model_My.setGlobalRole(action.value0)(state.my);
                return $33;
            })());
        };
        if (action instanceof State_Types.PerformEff) {
            return Pux.onlyEffects(state)([ Control_Apply.applySecond(Control_Monad_Aff.applyAff)(Control_Monad_Eff_Class.liftEff(Control_Monad_Aff.monadEffAff)(action.value0))(Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(State_Types.Noop.value)) ]);
        };
        if (action instanceof State_Types.PerformAff) {
            return Pux.onlyEffects(state)([ Control_Bind.bind(Control_Monad_Aff.bindAff)(Data_Function.apply(Control_Monad_Aff.attempt)(action.value0(state.channel)(state)(state.rootRef)))(function (v) {
                if (v instanceof Data_Either.Left) {
                    return Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(UReview_UserNotifications.mkErrorAction(Control_Monad_Eff_Exception.message(v.value0)));
                };
                if (v instanceof Data_Either.Right) {
                    return Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(State_Types.Noop.value);
                };
                throw new Error("Failed pattern match at State line 104, column 37 - line 106, column 64: " + [ v.constructor.name ]);
            }) ]);
        };
        if (action instanceof State_Types.PerformAffEvent) {
            return Pux.onlyEffects(state)([ Control_Apply.applySecond(Control_Monad_Aff.applyAff)(action.value0(state)(action.value1)(state.rootRef))(Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(State_Types.Noop.value)) ]);
        };
        if (action instanceof State_Types.SetPathEvent) {
            return setValueAt(state)(action.value0)(action.value1(action.value2));
        };
        if (action instanceof State_Types.SetPathValue) {
            return setValueAt(state)(action.value0)(new Data_Maybe.Just(action.value1));
        };
        if (action instanceof State_Types.DB) {
            return Components_DB.update(action.value0)(state);
        };
        if (action instanceof State_Types.DoChildAdded) {
            return State_DoChildAdded.doChildAdded(action.value0)(action.value1)(state);
        };
        if (action instanceof State_Types.Subscribed) {
            return Data_Function.apply(Pux.noEffects)((function () {
                var $52 = {};
                for (var $53 in state) {
                    if (state.hasOwnProperty($53)) {
                        $52[$53] = state[$53];
                    };
                };
                $52.firebaseSubscriptions = Data_Firebase_Subscription.subscribed(action.value0)(action.value1)(state.firebaseSubscriptions);
                return $52;
            })());
        };
        if (action instanceof State_Types.ChangedAddEventText) {
            return Data_Function.apply(Pux.noEffects)((function () {
                var $56 = {};
                for (var $57 in state) {
                    if (state.hasOwnProperty($57)) {
                        $56[$57] = state[$57];
                    };
                };
                $56.newEvent = action.value0(action.value1.target.value)(state.newEvent);
                return $56;
            })());
        };
        if (action instanceof State_Types.LoadedSessionDescription) {
            return Data_Function.apply(Pux.noEffects)(State_SessionDescription.setSessionDescription(action.value0)(action.value1)(state));
        };
        if (action instanceof State_Types.DownloadEventCSV) {
            return Pux.onlyEffects(state)([ Control_Apply.applySecond(Control_Monad_Aff.applyAff)(Components_GeneratedFileDownload.update(action.value0))(Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(State_Types.Noop.value)) ]);
        };
        if (action instanceof State_Types.AddEvent) {
            return Data_Function.apply(Components_DB.update(Data_Firebase_Event["saveEvent'"](state.newEvent)))(State_Event.resetNewEvent(state));
        };
        if (action instanceof State_Types.AssignReviewer) {
            return Data_Function.apply(Pux.onlyEffects(state))([ Control_Apply.applySecond(Control_Monad_Aff.applyAff)(Data_Firebase_Review.createReview(action.value0)(state.rootRef))(Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(State_Types.Noop.value)) ]);
        };
        if (action instanceof State_Types.ChooseReviewerToAdd && action.value0 instanceof Components_ManageReviewPanel_Types.Committed) {
            var maybeNewReviewer = Components_ManageReviewPanel_Types.asCertainState((state["new"]).reviewerFor);
            if (maybeNewReviewer instanceof Data_Maybe.Nothing) {
                return State_EffModelHelpers.noop(state);
            };
            if (maybeNewReviewer instanceof Data_Maybe.Just) {
                return {
                    state: (function () {
                        var $65 = {};
                        for (var $66 in state) {
                            if (state.hasOwnProperty($66)) {
                                $65[$66] = state[$66];
                            };
                        };
                        $65.new = {
                            reviewerFor: Components_ManageReviewPanel_Types.init(new Data_Maybe.Just(maybeNewReviewer.value0.eventId))
                        };
                        return $65;
                    })(), 
                    effects: [ Data_Firebase_Reviewer.createReviewerProcedure(maybeNewReviewer.value0.eventId)(maybeNewReviewer.value0.uid)(state.rootRef) ]
                };
            };
            throw new Error("Failed pattern match at State line 121, column 9 - line 126, column 14: " + [ maybeNewReviewer.constructor.name ]);
        };
        if (action instanceof State_Types.ChooseReviewerToAdd) {
            return Data_Function.apply(Pux.noEffects)((function () {
                var $69 = {};
                for (var $70 in state) {
                    if (state.hasOwnProperty($70)) {
                        $69[$70] = state[$70];
                    };
                };
                $69.new = {
                    reviewerFor: Components_ManageReviewPanel_Types.update(state.allUsers)(action.value0)((state["new"]).reviewerFor)
                };
                return $69;
            })());
        };
        if (action instanceof State_Types.ChoosePanelChairToAdd && action.value0 instanceof Components_ManageReviewPanel_Types.Committed) {
            var maybeNewReviewer = Components_ManageReviewPanel_Types.asCertainState((state["new"]).reviewerFor);
            if (maybeNewReviewer instanceof Data_Maybe.Nothing) {
                return State_EffModelHelpers.noop(state);
            };
            if (maybeNewReviewer instanceof Data_Maybe.Just) {
                return {
                    state: (function () {
                        var $73 = {};
                        for (var $74 in state) {
                            if (state.hasOwnProperty($74)) {
                                $73[$74] = state[$74];
                            };
                        };
                        $73.new = {
                            reviewerFor: Components_ManageReviewPanel_Types.init(new Data_Maybe.Just(maybeNewReviewer.value0.eventId))
                        };
                        return $73;
                    })(), 
                    effects: [ Control_Apply.applySecond(Control_Monad_Aff.applyAff)(Data_Firebase_PanelChair.createPanelChairProcedure(maybeNewReviewer.value0.eventId)(Model_UserProfile.mkUserKey(maybeNewReviewer.value0.uid))(state.rootRef))(Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(State_Types.Noop.value)) ]
                };
            };
            throw new Error("Failed pattern match at State line 131, column 9 - line 136, column 14: " + [ maybeNewReviewer.constructor.name ]);
        };
        if (action instanceof State_Types.ChoosePanelChairToAdd) {
            return Data_Function.apply(Pux.noEffects)((function () {
                var $77 = {};
                for (var $78 in state) {
                    if (state.hasOwnProperty($78)) {
                        $77[$78] = state[$78];
                    };
                };
                $77.new = {
                    reviewerFor: Components_ManageReviewPanel_Types.update(state.allUsers)(action.value0)((state["new"]).reviewerFor)
                };
                return $77;
            })());
        };
        if (action instanceof State_Types.TogglePanelChairActivation) {
            return Data_Function.apply(onlyAff(state))(Data_Firebase_PanelChair.togglePanelChair(action.value0)(action.value1)(state.rootRef));
        };
        if (action instanceof State_Types.UpdateMockup) {
            return Data_Function.apply(Pux.noEffects)((function () {
                var $82 = {};
                for (var $83 in state) {
                    if (state.hasOwnProperty($83)) {
                        $82[$83] = state[$83];
                    };
                };
                $82.mockup = State_CurrentMockup.update(action.value0)(state.mockup);
                return $82;
            })());
        };
        throw new Error("Failed pattern match at State line 87, column 3 - line 140, column 93: " + [ action.constructor.name ]);
    };
};
var update = function (action) {
    return function (state) {
        var r = updateWithException(action)(state);
        return {
            state: r.state, 
            effects: Data_Functor.map(Data_Functor.functorArray)(notifyUserOnFailure(state))(r.effects)
        };
    };
};
module.exports = {
    pageViewAfterAuthentication: pageViewAfterAuthentication, 
    update: update, 
    updateWithException: updateWithException
};
