// Generated by psc version 0.9.1
"use strict";
var Data_List = require("../Data.List");
var Control_Monad_Aff = require("../Control.Monad.Aff");
var Control_Monad_Eff_Class = require("../Control.Monad.Eff.Class");
var Control_Monad_Eff_Random = require("../Control.Monad.Eff.Random");
var Data_Array = require("../Data.Array");
var Data_Either = require("../Data.Either");
var Data_Firebase_Foreign = require("../Data.Firebase.Foreign");
var Data_Foreign = require("../Data.Foreign");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_Generators = require("../Data.Generators");
var Data_Loaded = require("../Data.Loaded");
var Data_Map = require("../Data.Map");
var Data_Maybe = require("../Data.Maybe");
var Data_Monoid = require("../Data.Monoid");
var Data_Tuple = require("../Data.Tuple");
var Data_VersionNumber = require("../Data.VersionNumber");
var Model_Event_Key = require("../Model.Event.Key");
var Model_Event_Tokens = require("../Model.Event.Tokens");
var Model_Reviewer = require("../Model.Reviewer");
var Model_Reviewer_Tokens = require("../Model.Reviewer.Tokens");
var PanelChair = require("../PanelChair");
var Prelude = require("../Prelude");
var Test_QuickCheck_Arbitrary = require("../Test.QuickCheck.Arbitrary");
var Test_QuickCheck_Gen = require("../Test.QuickCheck.Gen");
var Data_Show = require("../Data.Show");
var Data_Semigroup = require("../Data.Semigroup");
var Data_Eq = require("../Data.Eq");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra");
var Control_Bind = require("../Control.Bind");
var Data_Function = require("../Data.Function");
var Control_Applicative = require("../Control.Applicative");
var Data_Functor = require("../Data.Functor");
var Data_Foreign_Index = require("../Data.Foreign.Index");
var Data_Foldable = require("../Data.Foldable");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans");
var Data_Identity = require("../Data.Identity");
var Event = function (x) {
    return x;
};
var toForeignEvent = function (v) {
    var fev = {
        modelVersion: v.modelVersion, 
        recordVersion: v.recordVersion, 
        shortCode: v.shortCode, 
        name: v.name, 
        reviewers: Data_Firebase_Foreign.toFire(Data_Firebase_Foreign.toFirebaseMap(Data_ForeignKey.pathToForeignKey)(Data_Firebase_Foreign.toFirebaseForeignBoolean))(v.reviewerKeysWithActivation), 
        sessiondescriptions: Data_Firebase_Foreign.toFire(Data_Firebase_Foreign.toFirebaseArray(Data_ForeignKey.pathToForeignKey))(v.sessionDescriptionKeys)
    };
    return Data_Foreign.toForeign(fev);
};
var toFirebaseForeignEvent = new Data_Firebase_Foreign.ToFirebaseForeign(toForeignEvent);
var showEvent = new Data_Show.Show(function (v) {
    return Data_Show.show(Data_VersionNumber.showVersionNumber)(v.modelVersion) + (Data_Show.show(Data_VersionNumber.showVersionNumber)(v.recordVersion) + (Data_Show.show(Data_Show.showString)(v.shortCode) + (Data_Show.show(Data_Show.showString)(v.name) + (Data_Show.show(Data_Map.showMap(Data_ForeignKey.showForeignKey)(Data_Show.showBoolean))(v.reviewerKeysWithActivation) + Data_Show.show(Data_Show.showArray(Data_ForeignKey.showForeignKey))(v.sessionDescriptionKeys)))));
});
var setShortCode = function (value) {
    return function (v) {
        return Data_Function.apply(Event)((function () {
            var $45 = {};
            for (var $46 in v) {
                if (v.hasOwnProperty($46)) {
                    $45[$46] = v[$46];
                };
            };
            $45.shortCode = value;
            return $45;
        })());
    };
};
var setReviewerActivation = function (rk) {
    return function (isEnabled) {
        return function (v) {
            return Data_Function.apply(Event)((function () {
                var $50 = {};
                for (var $51 in v) {
                    if (v.hasOwnProperty($51)) {
                        $50[$51] = v[$51];
                    };
                };
                $50.reviewerKeysWithActivation = Data_Map.insert(Data_ForeignKey.ordForeignKey)(rk)(isEnabled)(v.reviewerKeysWithActivation);
                return $50;
            })());
        };
    };
};
var setName = function (value) {
    return function (v) {
        return Data_Function.apply(Event)((function () {
            var $54 = {};
            for (var $55 in v) {
                if (v.hasOwnProperty($55)) {
                    $54[$55] = v[$55];
                };
            };
            $54.name = value;
            return $54;
        })());
    };
};
var setEventKey = function (key) {
    return function (v) {
        return Data_Function.apply(Event)((function () {
            var $58 = {};
            for (var $59 in v) {
                if (v.hasOwnProperty($59)) {
                    $58[$59] = v[$59];
                };
            };
            $58.shortCode = Data_ForeignKey.fkToString(key);
            return $58;
        })());
    };
};
var sessionDescriptionCount = function (v) {
    return Data_Array.length(v.sessionDescriptionKeys);
};
var runEvent = function (v) {
    return v;
};
var isForeignEvent = new Data_Foreign_Class.IsForeign(function (value) {
    return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Class.readProp(Data_VersionNumber.isForeignVersionNumber)(Data_Foreign_Index.indexString)("modelVersion")(value))(function (v) {
        return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Class.readProp(Data_VersionNumber.isForeignVersionNumber)(Data_Foreign_Index.indexString)("recordVersion")(value))(function (v1) {
            return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Class.readProp(Data_Foreign_Class.stringIsForeign)(Data_Foreign_Index.indexString)("shortCode")(value))(function (v2) {
                return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Class.readProp(Data_Foreign_Class.stringIsForeign)(Data_Foreign_Index.indexString)("name")(value))(function (v3) {
                    return Control_Bind.bind(Data_Either.bindEither)(PanelChair.readPanelChairLoadersWithDefault(Data_Monoid.mempty(Data_Monoid.monoidArray))(Model_Event_Tokens.panelChairsToken)(value))(function (v4) {
                        return Control_Bind.bind(Data_Either.bindEither)(Data_Functor.map(Data_Either.functorEither)(Data_Map.fromFoldable(Data_ForeignKey.ordForeignKey)(Data_Foldable.foldableArray))(Data_Firebase_Foreign.readBooleanKeysWithDefault(Data_Monoid.mempty(Data_Monoid.monoidArray))(Model_Reviewer_Tokens.reviewersToken)(value)))(function (v5) {
                            return Control_Bind.bind(Data_Either.bindEither)(Data_Firebase_Foreign.readPropKeysWithDefault(Data_Foreign_Index.indexString)([  ])("sessiondescriptions")(value))(function (v6) {
                                return Data_Function.apply(Data_Either.Right.create)(Data_Function.apply(Event)({
                                    modelVersion: v, 
                                    recordVersion: v1, 
                                    shortCode: v2, 
                                    name: v3, 
                                    panelChairs: v4, 
                                    reviewers: Data_Map.empty, 
                                    reviewerKeysWithActivation: v5, 
                                    sessionDescriptionKeys: v6
                                }));
                            });
                        });
                    });
                });
            });
        });
    });
});
var indexOfSessionId = function (k) {
    return function (v) {
        return Data_Array.elemIndex(Data_ForeignKey.eqForeignKey)(k)(v.sessionDescriptionKeys);
    };
};
var getTheReviewerWithKey = function (v) {
    return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Function.apply(Data_List.head)(Data_Map.values(v.reviewers)))(function (v1) {
        return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Function.apply(Data_List.head)(Data_Map.keys(v.reviewers)))(function (v2) {
            return Data_Function.apply(Control_Applicative.pure(Data_Maybe.applicativeMaybe))({
                reviewer: v1, 
                reviewerKey: v2
            });
        });
    });
};
var getShortCode = function (v) {
    return Data_ForeignKey.mkForeignKey(v.shortCode);
};
var getSessionDescriptionKeys = function (v) {
    return v.sessionDescriptionKeys;
};
var getReviewers = function (v) {
    return v.reviewers;
};
var getPanelChairs = function (v) {
    return v.panelChairs;
};
var getEventKey = function (v) {
    return Data_ForeignKey.mkForeignKey(v.shortCode);
};
var findReviewerWithUid = function (uid) {
    return function (v) {
        return Data_Function.apply(Data_List.head)(Data_Function.apply(Data_List.filter(function (v1) {
            return Data_Eq.eq(Data_ForeignKey.eqForeignKey)(uid)(v1.value1.uid);
        }))(Data_Map.toList(v.reviewers)));
    };
};
var findReviewer = function (k) {
    return function (v) {
        return Data_Map.lookup(Data_ForeignKey.ordForeignKey)(k)(v.reviewers);
    };
};
var eqEvent = new Data_Eq.Eq(function (v) {
    return function (v1) {
        return Data_Eq.eq(Data_VersionNumber.eqVersionNumber)(v.modelVersion)(v1.modelVersion) && (Data_Eq.eq(Data_VersionNumber.eqVersionNumber)(v.recordVersion)(v1.recordVersion) && (v.shortCode === v1.shortCode && (v.name === v1.name && (Data_Eq.eq(Data_Map.eqMap(Data_ForeignKey.eqForeignKey)(Data_Eq.eqBoolean))(v.reviewerKeysWithActivation)(v1.reviewerKeysWithActivation) && Data_Eq.eq(Data_Eq.eqArray(Data_ForeignKey.eqForeignKey))(Data_Array.sort(Data_ForeignKey.ordForeignKey)(v.sessionDescriptionKeys))(Data_Array.sort(Data_ForeignKey.ordForeignKey)(v1.sessionDescriptionKeys))))));
    };
});
var deActivatedReviewerKeys = function (v) {
    return Data_Function.apply(Data_Array.fromFoldable(Data_List.foldableList))(Data_Function.apply(Data_Functor.map(Data_List.functorList)(Data_Tuple.fst))(Data_Function.apply(Data_List.filter(function (t) {
        return Data_Function.apply(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Tuple.snd(t));
    }))(Data_Map.toList(v.reviewerKeysWithActivation))));
};
var deActivatedReviewers = function (v) {
    return Data_Loaded.unionKeys(Data_ForeignKey.ordForeignKey)(deActivatedReviewerKeys(v))(v.reviewers);
};
var deActivatedReviewer = function (rk) {
    return function (e) {
        return setReviewerActivation(rk)(false)(e);
    };
};
var currentModelVersion = 0;
var nullEvent = {
    modelVersion: currentModelVersion, 
    recordVersion: Data_VersionNumber.mkVersionNumber, 
    shortCode: "", 
    name: "", 
    panelChairs: Data_Monoid.mempty(Data_Monoid.monoidArray), 
    reviewers: Data_Monoid.mempty(Data_Map.monoidMap(Data_ForeignKey.ordForeignKey)), 
    reviewerKeysWithActivation: Data_Monoid.mempty(Data_Map.monoidMap(Data_ForeignKey.ordForeignKey)), 
    sessionDescriptionKeys: Data_Monoid.mempty(Data_Monoid.monoidArray)
};
var genEventWithoutLeaves = Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Data_ForeignKey.arbForeignKey))(function (v) {
    return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Data_Generators.alphaNumString)(function (v1) {
        return Data_Function.apply(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity)))(Data_Function.apply(setName(v1))(setEventKey(v)(nullEvent)));
    });
});
var sampleEvent = Data_Function.apply(Control_Monad_Eff_Class.liftEff(Control_Monad_Aff.monadEffAff))(Data_Function.apply(Data_Generators.randomSample1)(genEventWithoutLeaves));
var arbEvent = new Test_QuickCheck_Arbitrary.Arbitrary(Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Data_VersionNumber.arbVersionNumber))(function (v) {
    return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Data_Generators.alphaNumString)(function (v1) {
        return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Data_Generators.alphaNumString)(function (v2) {
            return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Data_ForeignKey.uniqueKeys)(function (v3) {
                return Data_Function.apply(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity)))({
                    modelVersion: currentModelVersion, 
                    recordVersion: v, 
                    shortCode: v1, 
                    name: v2, 
                    panelChairs: Data_Monoid.mempty(Data_Monoid.monoidArray), 
                    reviewers: Data_Monoid.mempty(Data_Map.monoidMap(Data_ForeignKey.ordForeignKey)), 
                    reviewerKeysWithActivation: Data_Monoid.mempty(Data_Map.monoidMap(Data_ForeignKey.ordForeignKey)), 
                    sessionDescriptionKeys: v3
                });
            });
        });
    });
}));
var addedReviewer = function (k) {
    return function (reviewer) {
        return function (v) {
            return Data_Function.apply(Event)((function () {
                var $109 = {};
                for (var $110 in v) {
                    if (v.hasOwnProperty($110)) {
                        $109[$110] = v[$110];
                    };
                };
                $109.reviewers = Data_Map.insert(Data_ForeignKey.ordForeignKey)(k)(reviewer)(v.reviewers);
                return $109;
            })());
        };
    };
};
var addSessionDescriptionKey = function (key) {
    return function (v) {
        return Data_Function.apply(Event)((function () {
            var $113 = {};
            for (var $114 in v) {
                if (v.hasOwnProperty($114)) {
                    $113[$114] = v[$114];
                };
            };
            $113.sessionDescriptionKeys = Data_Array.cons(key)(v.sessionDescriptionKeys);
            return $113;
        })());
    };
};
var activatedReviewerKeys = function (v) {
    return Data_Function.apply(Data_Array.fromFoldable(Data_List.foldableList))(Data_Function.apply(Data_Functor.map(Data_List.functorList)(Data_Tuple.fst))(Data_Function.apply(Data_List.filter(Data_Tuple.snd))(Data_Map.toList(v.reviewerKeysWithActivation))));
};
var activatedReviewers = function (v) {
    return Data_Loaded.unionKeys(Data_ForeignKey.ordForeignKey)(activatedReviewerKeys(v))(v.reviewers);
};
var activatedReviewer = function (rk) {
    return function (e) {
        return setReviewerActivation(rk)(true)(e);
    };
};
module.exports = {
    Event: Event, 
    activatedReviewer: activatedReviewer, 
    activatedReviewerKeys: activatedReviewerKeys, 
    activatedReviewers: activatedReviewers, 
    addSessionDescriptionKey: addSessionDescriptionKey, 
    addedReviewer: addedReviewer, 
    deActivatedReviewer: deActivatedReviewer, 
    deActivatedReviewerKeys: deActivatedReviewerKeys, 
    deActivatedReviewers: deActivatedReviewers, 
    findReviewer: findReviewer, 
    findReviewerWithUid: findReviewerWithUid, 
    genEventWithoutLeaves: genEventWithoutLeaves, 
    getEventKey: getEventKey, 
    getPanelChairs: getPanelChairs, 
    getReviewers: getReviewers, 
    getSessionDescriptionKeys: getSessionDescriptionKeys, 
    getShortCode: getShortCode, 
    getTheReviewerWithKey: getTheReviewerWithKey, 
    indexOfSessionId: indexOfSessionId, 
    nullEvent: nullEvent, 
    runEvent: runEvent, 
    sampleEvent: sampleEvent, 
    sessionDescriptionCount: sessionDescriptionCount, 
    setEventKey: setEventKey, 
    setName: setName, 
    setReviewerActivation: setReviewerActivation, 
    setShortCode: setShortCode, 
    showEvent: showEvent, 
    eqEvent: eqEvent, 
    arbEvent: arbEvent, 
    isForeignEvent: isForeignEvent, 
    toFirebaseForeignEvent: toFirebaseForeignEvent
};
