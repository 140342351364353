// Generated by psc version 0.9.1
"use strict";
var Data_Firebase_Foreign = require("../Data.Firebase.Foreign");
var Data_Foreign = require("../Data.Foreign");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var Data_Generic = require("../Data.Generic");
var Test_QuickCheck_Arbitrary = require("../Test.QuickCheck.Arbitrary");
var Test_QuickCheck_Gen = require("../Test.QuickCheck.Gen");
var Prelude = require("../Prelude");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var Data_Functor = require("../Data.Functor");
var Data_Maybe = require("../Data.Maybe");
var Data_Eq = require("../Data.Eq");
var Control_Applicative = require("../Control.Applicative");
var Data_Show = require("../Data.Show");
var Data_Either = require("../Data.Either");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans");
var Data_Identity = require("../Data.Identity");
var Published = (function () {
    function Published() {

    };
    Published.value = new Published();
    return Published;
})();
var Hidden = (function () {
    function Hidden() {

    };
    Hidden.value = new Hidden();
    return Hidden;
})();
var Closed = (function () {
    function Closed() {

    };
    Closed.value = new Closed();
    return Closed;
})();
var verbosePublicationStatus = function (p) {
    if (p instanceof Published) {
        return "Published";
    };
    if (p instanceof Hidden) {
        return "Hidden";
    };
    if (p instanceof Closed) {
        return "Closed";
    };
    throw new Error("Failed pattern match at Model.OpenCallForSessions.PublicationStatus line 26, column 30 - line 31, column 1: " + [ p.constructor.name ]);
};
var toFirebaseForeignPublicationStatus = new Data_Firebase_Foreign.ToFirebaseForeign(function ($16) {
    return Data_Foreign.toForeign(verbosePublicationStatus($16));
});
var showPublicationStatus = new Data_Show.Show(verbosePublicationStatus);
var parsePubStatus = function (s) {
    if (s === "Published") {
        return Published.value;
    };
    if (s === "Hidden") {
        return Hidden.value;
    };
    return Closed.value;
};
var isForeignPublicationStatus = new Data_Foreign_Class.IsForeign(function (value) {
    return Data_Functor.map(Data_Either.functorEither)(parsePubStatus)(Data_Foreign.readString(value));
});
var genericPublicationStatus = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.OpenCallForSessions.PublicationStatus.Published" && v.value1.length === 0)) {
        return new Data_Maybe.Just(Published.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.OpenCallForSessions.PublicationStatus.Hidden" && v.value1.length === 0)) {
        return new Data_Maybe.Just(Hidden.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.OpenCallForSessions.PublicationStatus.Closed" && v.value1.length === 0)) {
        return new Data_Maybe.Just(Closed.value);
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Model.OpenCallForSessions.PublicationStatus.PublicationStatus", [ {
        sigConstructor: "Model.OpenCallForSessions.PublicationStatus.Published", 
        sigValues: [  ]
    }, {
        sigConstructor: "Model.OpenCallForSessions.PublicationStatus.Hidden", 
        sigValues: [  ]
    }, {
        sigConstructor: "Model.OpenCallForSessions.PublicationStatus.Closed", 
        sigValues: [  ]
    } ]);
}, function (v) {
    if (v instanceof Published) {
        return new Data_Generic.SProd("Model.OpenCallForSessions.PublicationStatus.Published", [  ]);
    };
    if (v instanceof Hidden) {
        return new Data_Generic.SProd("Model.OpenCallForSessions.PublicationStatus.Hidden", [  ]);
    };
    if (v instanceof Closed) {
        return new Data_Generic.SProd("Model.OpenCallForSessions.PublicationStatus.Closed", [  ]);
    };
    throw new Error("Failed pattern match at Model.OpenCallForSessions.PublicationStatus line 34, column 1 - line 34, column 70: " + [ v.constructor.name ]);
});
var eqPublicationStatus = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Published && y instanceof Published) {
            return true;
        };
        if (x instanceof Hidden && y instanceof Hidden) {
            return true;
        };
        if (x instanceof Closed && y instanceof Closed) {
            return true;
        };
        return false;
    };
});
var arbitraryPublicationStatus = new Test_QuickCheck_Arbitrary.Arbitrary(Test_QuickCheck_Gen.oneOf(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity))(Published.value))([ Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity))(Hidden.value), Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity))(Closed.value) ]));
var allowedPublicationValues = [ Published.value, Hidden.value, Closed.value ];
module.exports = {
    Published: Published, 
    Hidden: Hidden, 
    Closed: Closed, 
    allowedPublicationValues: allowedPublicationValues, 
    parsePubStatus: parsePubStatus, 
    verbosePublicationStatus: verbosePublicationStatus, 
    toFirebaseForeignPublicationStatus: toFirebaseForeignPublicationStatus, 
    isForeignPublicationStatus: isForeignPublicationStatus, 
    genericPublicationStatus: genericPublicationStatus, 
    eqPublicationStatus: eqPublicationStatus, 
    arbitraryPublicationStatus: arbitraryPublicationStatus, 
    showPublicationStatus: showPublicationStatus
};
