// Generated by psc version 0.9.1
"use strict";
var Components_Elements = require("../Components.Elements");
var Components_ManageReviewPanel_View = require("../Components.ManageReviewPanel.View");
var Components_ViewAllowed = require("../Components.ViewAllowed");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_Maybe = require("../Data.Maybe");
var Model_Event = require("../Model.Event");
var Model_Event_Key = require("../Model.Event.Key");
var Prelude = require("../Prelude");
var Pux_Html = require("../Pux.Html");
var Pux_Html_Attributes = require("../Pux.Html.Attributes");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var Route_HtmlElements = require("../Route.HtmlElements");
var State_Event = require("../State.Event");
var State_My = require("../State.My");
var State_Types = require("../State.Types");
var View_Loading = require("../View.Loading");
var Data_Function = require("../Data.Function");
var UReview_Route_Types = require("../UReview.Route.Types");
var rowColumnH2 = function (heading) {
    return function (innerElement) {
        return Pux_Html_Elements.div([ Pux_Html_Attributes.className("row") ])([ Pux_Html_Elements.div([ Pux_Html_Attributes.className("column") ])([ Pux_Html_Elements.h2([  ])([ Pux_Html_Elements.text(heading) ]), Pux_Html_Elements.div([  ])([ innerElement ]) ]) ]);
    };
};
var pcMemberOnly = function (key) {
    return function (v) {
        return function (v1) {
            return Pux_Html_Elements.div([  ])([ Pux_Html_Elements.h3([  ])([ Pux_Html_Elements.text("Manage reviewers.") ]), Data_Function.apply(Pux_Html_Elements.div([  ]))(Components_ManageReviewPanel_View.manageReviewPanel(v)(v1)) ]);
        };
    };
};
var eventNotFound = View_Loading.loading("Event");
var adminOnly = function (v) {
    return function (state) {
        var addPanelChairs = Pux_Html_Elements.p([  ])([ Pux_Html_Elements.text("Assign panel chair role to "), Route_HtmlElements.routeLink(UReview_Route_Types.UsersRoute.value)("a user"), Pux_Html_Elements.text(".") ]);
        return Pux_Html_Elements.div([  ])([ Pux_Html_Elements.h3([  ])([ Pux_Html_Elements.text("Administrators: ") ]), addPanelChairs ]);
    };
};
var viewFoundEvent = function (v) {
    return function (key) {
        return function (v1) {
            var authorizedView = (function () {
                var $14 = State_My.isPanelChairFor(key)(v);
                if ($14) {
                    return pcMemberOnly(key)(v1)(v);
                };
                if (!$14) {
                    var $15 = Components_ViewAllowed.administrator(v);
                    if ($15) {
                        return adminOnly(v1)(v);
                    };
                    if (!$15) {
                        return Components_Elements.ptext("Checking your permissions, please wait.");
                    };
                    throw new Error("Failed pattern match at View.ManageReviewersView line 40, column 7 - line 45, column 1: " + [ $15.constructor.name ]);
                };
                throw new Error("Failed pattern match at View.ManageReviewersView line 37, column 22 - line 45, column 1: " + [ $14.constructor.name ]);
            })();
            return rowColumnH2(v1.name)(authorizedView);
        };
    };
};
var manageReviewers = function (key) {
    return function (v) {
        var mEvent = State_Event.findEvent(key)(v);
        return Pux_Html_Elements.section([  ])([ Data_Maybe.maybe(eventNotFound)(viewFoundEvent(v)(key))(mEvent) ]);
    };
};
module.exports = {
    manageReviewers: manageReviewers
};
