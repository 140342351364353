// Generated by psc version 0.9.1
"use strict";
var Prelude = require("../Prelude");
var Data_Foreign = require("../Data.Foreign");
var Data_Foreign_Generic = require("../Data.Foreign.Generic");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var Data_Generic = require("../Data.Generic");
var Data_Ord = require("../Data.Ord");
var Data_Boolean = require("../Data.Boolean");
var Data_Ring = require("../Data.Ring");
var jsonOptions = (function () {
    var $3 = {};
    for (var $4 in Data_Foreign_Generic.defaultOptions) {
        if (Data_Foreign_Generic.defaultOptions.hasOwnProperty($4)) {
            $3[$4] = Data_Foreign_Generic.defaultOptions[$4];
        };
    };
    $3.unwrapNewtypes = true;
    return $3;
})();
var readForeign = function (dictIsForeign) {
    return function (dictGeneric) {
        return Data_Foreign_Generic.readGeneric(dictGeneric)(jsonOptions);
    };
};
var writeForeign = function (dictGeneric) {
    return Data_Foreign_Generic.toForeignGeneric(dictGeneric)(jsonOptions);
};
var abs = function (n) {
    if (n >= 0) {
        return n;
    };
    if (Data_Boolean.otherwise) {
        return -n;
    };
    throw new Error("Failed pattern match at UReview.Prelude line 25, column 1 - line 25, column 23: " + [ n.constructor.name ]);
};
module.exports = {
    abs: abs, 
    jsonOptions: jsonOptions, 
    readForeign: readForeign, 
    writeForeign: writeForeign
};
