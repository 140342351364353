// Generated by psc version 0.9.1
"use strict";
var Pux_Html = require("../Pux.Html");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var Pux_Html_Attributes = require("../Pux.Html.Attributes");
var Components_Elements = require("../Components.Elements");
var Components_TextField = require("../Components.TextField");
var Model_SessionDescription_Expenses_Place = require("../Model.SessionDescription.Expenses.Place");
var Prelude = require("../Prelude");
var Data_Function = require("../Data.Function");
var Components_Elements_Basic_Types = require("../Components.Elements.Basic.Types");
var Data_Functor = require("../Data.Functor");
var Data_Semigroup = require("../Data.Semigroup");
var Direction = function (x) {
    return x;
};
var CityChanged = (function () {
    function CityChanged(value0) {
        this.value0 = value0;
    };
    CityChanged.create = function (value0) {
        return new CityChanged(value0);
    };
    return CityChanged;
})();
var CountryChanged = (function () {
    function CountryChanged(value0) {
        this.value0 = value0;
    };
    CountryChanged.create = function (value0) {
        return new CountryChanged(value0);
    };
    return CountryChanged;
})();
var viewReturn = function (v) {
    var countryField = {
        htmlId: Data_Function.apply(Components_Elements_Basic_Types.mkHtmlId)("travelling-return-country"), 
        fieldLabel: "Returning to Country", 
        helpText: "The Country you need to travel back to, after the conference", 
        inputClass: "required-after"
    };
    var cityField = {
        htmlId: Data_Function.apply(Components_Elements_Basic_Types.mkHtmlId)("travelling-return-city"), 
        fieldLabel: "Returning to City", 
        helpText: "The city you need to travel back to, after the conference", 
        inputClass: "required-after"
    };
    return Pux_Html_Elements.fieldset([ Pux_Html_Attributes.className("") ])([ Data_Functor.map(Pux_Html_Elements.functorHtml)(CityChanged.create)(Components_TextField.view(cityField)(v.city)), Data_Functor.map(Pux_Html_Elements.functorHtml)(CountryChanged.create)(Components_TextField.view(countryField)(v.country)) ]);
};
var view = function (v) {
    return function (v1) {
        var countryField = {
            htmlId: Data_Function.apply(Components_Elements_Basic_Types.mkHtmlId)("travelling-" + (v + "-country")), 
            fieldLabel: "Travelling from Country", 
            helpText: "The country you will be travelling from", 
            inputClass: "required-after"
        };
        var cityField = {
            htmlId: Data_Function.apply(Components_Elements_Basic_Types.mkHtmlId)("travelling-" + (v + "-city")), 
            fieldLabel: "Travelling from City", 
            helpText: "The city you will be travelling from", 
            inputClass: "required-after"
        };
        return Pux_Html_Elements.fieldset([ Pux_Html_Attributes.className("") ])([ Data_Functor.map(Pux_Html_Elements.functorHtml)(CityChanged.create)(Components_TextField.view(cityField)(v1.city)), Data_Functor.map(Pux_Html_Elements.functorHtml)(CountryChanged.create)(Components_TextField.view(countryField)(v1.country)) ]);
    };
};
var viewGo = function (place) {
    return view("to")(place);
};
var update = function (v) {
    return function (v1) {
        if (v instanceof CityChanged) {
            return Data_Function.apply(Model_SessionDescription_Expenses_Place.Place)((function () {
                var $10 = {};
                for (var $11 in v1) {
                    if (v1.hasOwnProperty($11)) {
                        $10[$11] = v1[$11];
                    };
                };
                $10.city = Components_TextField.update(v.value0);
                return $10;
            })());
        };
        if (v instanceof CountryChanged) {
            return Data_Function.apply(Model_SessionDescription_Expenses_Place.Place)((function () {
                var $13 = {};
                for (var $14 in v1) {
                    if (v1.hasOwnProperty($14)) {
                        $13[$14] = v1[$14];
                    };
                };
                $13.country = Components_TextField.update(v.value0);
                return $13;
            })());
        };
        throw new Error("Failed pattern match at View.Place line 41, column 1 - line 41, column 81: " + [ v.constructor.name, v1.constructor.name ]);
    };
};
module.exports = {
    Direction: Direction, 
    update: update, 
    viewGo: viewGo, 
    viewReturn: viewReturn
};
