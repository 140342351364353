// Generated by psc version 0.9.1
"use strict";
var Data_Foreign = require("../Data.Foreign");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_ForeignLocation = require("../Data.ForeignLocation");
var Data_List = require("../Data.List");
var Data_Maybe = require("../Data.Maybe");
var Data_SmallPositiveInt = require("../Data.SmallPositiveInt");
var Data_StrMap = require("../Data.StrMap");
var Model_Event = require("../Model.Event");
var Model_Review = require("../Model.Review");
var Model_Reviewer = require("../Model.Reviewer");
var Prelude = require("../Prelude");
var State_Event = require("../State.Event");
var State_Find_Reviewer = require("../State.Find.Reviewer");
var State_Review = require("../State.Review");
var State_Types = require("../State.Types");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var Control_Bind = require("../Control.Bind");
var Data_Function = require("../Data.Function");
var Data_Unfoldable = require("../Data.Unfoldable");
var Data_Functor = require("../Data.Functor");
var Data_Either = require("../Data.Either");
var setReviewer = function (eventId) {
    return function (k) {
        return function (reviewer) {
            return function (v) {
                var $7 = {};
                for (var $8 in v) {
                    if (v.hasOwnProperty($8)) {
                        $7[$8] = v[$8];
                    };
                };
                $7.events = Data_StrMap.update(function ($14) {
                    return Data_Maybe.Just.create(Model_Event.addedReviewer(k)(reviewer)($14));
                })(eventId)(v.events);
                return $7;
            };
        };
    };
};
var requiredNumberOfReviews = 10;
var generateReviewFor = function (reviewerKey) {
    return function (v) {
        return function (state) {
            return Control_Bind.bind(Data_Maybe.bindMaybe)(State_Event.findEvent(v.eventId)(state))(function (v1) {
                var reviews = Data_Function.apply(Data_List.toUnfoldable(Data_Unfoldable.unfoldableArray))(State_Review.reviewsForEventKey(v.eventId)(state));
                return Model_Review.nextReview(v.eventId)(reviewerKey)(State_Event.keysOfVisibleSessionDescriptions(v1)(state))(requiredNumberOfReviews)(reviews);
            });
        };
    };
};
var applyForeignReviewer = function (foreignLocation) {
    return function (state) {
        var setReviewer2 = function (state1) {
            return function (rk) {
                return function (reviewer) {
                    return setReviewer(Data_Function.apply(Data_ForeignKey.fkToString)(Model_Reviewer.eventKey(reviewer)))(rk)(reviewer)(state1);
                };
            };
        };
        var reviewerKey = Data_ForeignLocation.key(foreignLocation);
        var parsedReviewer = Data_ForeignLocation.readForeignPart(Model_Reviewer.isForeignReviewer)(foreignLocation);
        var eventKeyApplied = Data_Functor.map(Data_Either.functorEither)(Model_Reviewer.eventKey)(parsedReviewer);
        return Data_Functor.map(Data_Either.functorEither)(setReviewer2(state)(reviewerKey))(parsedReviewer);
    };
};
module.exports = {
    applyForeignReviewer: applyForeignReviewer, 
    generateReviewFor: generateReviewFor, 
    setReviewer: setReviewer
};
