// Generated by psc version 0.9.1
"use strict";
var $foreign = require("./foreign");
var Control_Monad_Eff = require("../Control.Monad.Eff");
var Control_Monad_Eff_Console = require("../Control.Monad.Eff.Console");
var Data_Function_Uncurried = require("../Data.Function.Uncurried");
var Ports_Firebase_Types = require("../Ports.Firebase.Types");
var Prelude = require("../Prelude");
var Signal_Channel = require("../Signal.Channel");
var State_Types = require("../State.Types");
var Control_Bind = require("../Control.Bind");
var Data_Function = require("../Data.Function");
var Control_Applicative = require("../Control.Applicative");
var ureviewTestRef = Data_Function_Uncurried.runFn0($foreign._ureviewTestRef);
var ureviewProductionRef = Data_Function_Uncurried.runFn0($foreign._ureviewProductionRef);
var ureviewLocalRef = Data_Function_Uncurried.runFn0($foreign._ureviewLocalRef);
var initForHost = function (name) {
    if (name === "ureview.firebaseapp.com") {
        return ureviewProductionRef;
    };
    if (name === "wereview.eu") {
        return ureviewProductionRef;
    };
    if (name === "auth.wereview.eu") {
        return ureviewProductionRef;
    };
    if (name === "auth.wereviewhq.com") {
        return ureviewProductionRef;
    };
    if (name === "wereviewhq.com") {
        return ureviewProductionRef;
    };
    if (name === "auth.wereview.info") {
        return ureviewProductionRef;
    };
    if (name === "testauth.wereviewhq.com") {
        return ureviewTestRef;
    };
    if (name === "test.wereviewhq.com") {
        return ureviewTestRef;
    };
    if (name === "localhost") {
        return ureviewLocalRef;
    };
    return ureviewTestRef;
};
var initHost = function (hostName) {
    var firebaseInit = initForHost(hostName);
    return function __do() {
        var v = firebaseInit();
        var v1 = Signal_Channel.channel(State_Types.Noop.value)();
        return Data_Function.apply(Control_Applicative.pure(Control_Monad_Eff.applicativeEff))(State_Types.initialState(v)(v1))();
    };
};
module.exports = {
    initHost: initHost
};
