// Generated by psc version 0.9.1
"use strict";
var Prelude = require("../Prelude");
var Data_Foldable = require("../Data.Foldable");
var Data_List = require("../Data.List");
var Data_Maybe = require("../Data.Maybe");
var Data_Monoid = require("../Data.Monoid");
var Data_Traversable = require("../Data.Traversable");
var Data_Tuple = require("../Data.Tuple");
var Partial_Unsafe = require("../Partial.Unsafe");
var Data_Eq = require("../Data.Eq");
var Data_Show = require("../Data.Show");
var Data_Semigroup = require("../Data.Semigroup");
var Data_Ord = require("../Data.Ord");
var Data_Functor = require("../Data.Functor");
var Control_Apply = require("../Control.Apply");
var Control_Applicative = require("../Control.Applicative");
var Control_Category = require("../Control.Category");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var Data_Ordering = require("../Data.Ordering");
var Data_Function = require("../Data.Function");
var Data_Semiring = require("../Data.Semiring");
var Leaf = (function () {
    function Leaf() {

    };
    Leaf.value = new Leaf();
    return Leaf;
})();
var Two = (function () {
    function Two(value0, value1, value2, value3) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
        this.value3 = value3;
    };
    Two.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return function (value3) {
                    return new Two(value0, value1, value2, value3);
                };
            };
        };
    };
    return Two;
})();
var Three = (function () {
    function Three(value0, value1, value2, value3, value4, value5, value6) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
        this.value3 = value3;
        this.value4 = value4;
        this.value5 = value5;
        this.value6 = value6;
    };
    Three.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return function (value3) {
                    return function (value4) {
                        return function (value5) {
                            return function (value6) {
                                return new Three(value0, value1, value2, value3, value4, value5, value6);
                            };
                        };
                    };
                };
            };
        };
    };
    return Three;
})();
var TwoLeft = (function () {
    function TwoLeft(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    TwoLeft.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new TwoLeft(value0, value1, value2);
            };
        };
    };
    return TwoLeft;
})();
var TwoRight = (function () {
    function TwoRight(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    TwoRight.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new TwoRight(value0, value1, value2);
            };
        };
    };
    return TwoRight;
})();
var ThreeLeft = (function () {
    function ThreeLeft(value0, value1, value2, value3, value4, value5) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
        this.value3 = value3;
        this.value4 = value4;
        this.value5 = value5;
    };
    ThreeLeft.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return function (value3) {
                    return function (value4) {
                        return function (value5) {
                            return new ThreeLeft(value0, value1, value2, value3, value4, value5);
                        };
                    };
                };
            };
        };
    };
    return ThreeLeft;
})();
var ThreeMiddle = (function () {
    function ThreeMiddle(value0, value1, value2, value3, value4, value5) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
        this.value3 = value3;
        this.value4 = value4;
        this.value5 = value5;
    };
    ThreeMiddle.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return function (value3) {
                    return function (value4) {
                        return function (value5) {
                            return new ThreeMiddle(value0, value1, value2, value3, value4, value5);
                        };
                    };
                };
            };
        };
    };
    return ThreeMiddle;
})();
var ThreeRight = (function () {
    function ThreeRight(value0, value1, value2, value3, value4, value5) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
        this.value3 = value3;
        this.value4 = value4;
        this.value5 = value5;
    };
    ThreeRight.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return function (value3) {
                    return function (value4) {
                        return function (value5) {
                            return new ThreeRight(value0, value1, value2, value3, value4, value5);
                        };
                    };
                };
            };
        };
    };
    return ThreeRight;
})();
var KickUp = (function () {
    function KickUp(value0, value1, value2, value3) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
        this.value3 = value3;
    };
    KickUp.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return function (value3) {
                    return new KickUp(value0, value1, value2, value3);
                };
            };
        };
    };
    return KickUp;
})();
var values = function (v) {
    if (v instanceof Leaf) {
        return Data_List.Nil.value;
    };
    if (v instanceof Two) {
        return Data_Semigroup.append(Data_List.semigroupList)(values(v.value0))(Data_Semigroup.append(Data_List.semigroupList)(Control_Applicative.pure(Data_List.applicativeList)(v.value2))(values(v.value3)));
    };
    if (v instanceof Three) {
        return Data_Semigroup.append(Data_List.semigroupList)(values(v.value0))(Data_Semigroup.append(Data_List.semigroupList)(Control_Applicative.pure(Data_List.applicativeList)(v.value2))(Data_Semigroup.append(Data_List.semigroupList)(values(v.value3))(Data_Semigroup.append(Data_List.semigroupList)(Control_Applicative.pure(Data_List.applicativeList)(v.value5))(values(v.value6)))));
    };
    throw new Error("Failed pattern match at Data.Map line 390, column 1 - line 390, column 18: " + [ v.constructor.name ]);
};
var toList = function (v) {
    if (v instanceof Leaf) {
        return Data_List.Nil.value;
    };
    if (v instanceof Two) {
        return Data_Semigroup.append(Data_List.semigroupList)(toList(v.value0))(Data_Semigroup.append(Data_List.semigroupList)(Control_Applicative.pure(Data_List.applicativeList)(new Data_Tuple.Tuple(v.value1, v.value2)))(toList(v.value3)));
    };
    if (v instanceof Three) {
        return Data_Semigroup.append(Data_List.semigroupList)(toList(v.value0))(Data_Semigroup.append(Data_List.semigroupList)(Control_Applicative.pure(Data_List.applicativeList)(new Data_Tuple.Tuple(v.value1, v.value2)))(Data_Semigroup.append(Data_List.semigroupList)(toList(v.value3))(Data_Semigroup.append(Data_List.semigroupList)(Control_Applicative.pure(Data_List.applicativeList)(new Data_Tuple.Tuple(v.value4, v.value5)))(toList(v.value6)))));
    };
    throw new Error("Failed pattern match at Data.Map line 369, column 1 - line 369, column 18: " + [ v.constructor.name ]);
};
var size = function ($592) {
    return Data_List.length(values($592));
};
var singleton = function (k) {
    return function (v) {
        return new Two(Leaf.value, k, v, Leaf.value);
    };
};
var showTree = function (dictShow) {
    return function (dictShow1) {
        return function (v) {
            if (v instanceof Leaf) {
                return "Leaf";
            };
            if (v instanceof Two) {
                return "Two (" + (showTree(dictShow)(dictShow1)(v.value0) + (") (" + (Data_Show.show(dictShow)(v.value1) + (") (" + (Data_Show.show(dictShow1)(v.value2) + (") (" + (showTree(dictShow)(dictShow1)(v.value3) + ")")))))));
            };
            if (v instanceof Three) {
                return "Three (" + (showTree(dictShow)(dictShow1)(v.value0) + (") (" + (Data_Show.show(dictShow)(v.value1) + (") (" + (Data_Show.show(dictShow1)(v.value2) + (") (" + (showTree(dictShow)(dictShow1)(v.value3) + (") (" + (Data_Show.show(dictShow)(v.value4) + (") (" + (Data_Show.show(dictShow1)(v.value5) + (") (" + (showTree(dictShow)(dictShow1)(v.value6) + ")")))))))))))));
            };
            throw new Error("Failed pattern match at Data.Map line 85, column 1 - line 86, column 1: " + [ v.constructor.name ]);
        };
    };
};
var showMap = function (dictShow) {
    return function (dictShow1) {
        return new Data_Show.Show(function (m) {
            return "(fromList " + (Data_Show.show(Data_List.showList(Data_Tuple.showTuple(dictShow)(dictShow1)))(toList(m)) + ")");
        });
    };
};
var lookupLE = function (dictOrd) {
    return function (v) {
        return function (v1) {
            if (v1 instanceof Leaf) {
                return Data_Maybe.Nothing.value;
            };
            if (v1 instanceof Two) {
                var $117 = Data_Ord.compare(dictOrd)(v)(v1.value1);
                if ($117 instanceof Data_Ordering.EQ) {
                    return new Data_Maybe.Just({
                        key: v1.value1, 
                        value: v1.value2
                    });
                };
                if ($117 instanceof Data_Ordering.GT) {
                    return Data_Function.apply(Data_Maybe.Just.create)(Data_Function.apply(Data_Maybe.fromMaybe({
                        key: v1.value1, 
                        value: v1.value2
                    }))(lookupLE(dictOrd)(v)(v1.value3)));
                };
                if ($117 instanceof Data_Ordering.LT) {
                    return lookupLE(dictOrd)(v)(v1.value0);
                };
                throw new Error("Failed pattern match at Data.Map line 152, column 37 - line 155, column 24: " + [ $117.constructor.name ]);
            };
            if (v1 instanceof Three) {
                var $122 = Data_Ord.compare(dictOrd)(v)(v1.value4);
                if ($122 instanceof Data_Ordering.EQ) {
                    return new Data_Maybe.Just({
                        key: v1.value4, 
                        value: v1.value5
                    });
                };
                if ($122 instanceof Data_Ordering.GT) {
                    return Data_Function.apply(Data_Maybe.Just.create)(Data_Function.apply(Data_Maybe.fromMaybe({
                        key: v1.value4, 
                        value: v1.value5
                    }))(lookupLE(dictOrd)(v)(v1.value6)));
                };
                if ($122 instanceof Data_Ordering.LT) {
                    return Data_Function.apply(lookupLE(dictOrd)(v))(new Two(v1.value0, v1.value1, v1.value2, v1.value3));
                };
                throw new Error("Failed pattern match at Data.Map line 156, column 49 - line 159, column 40: " + [ $122.constructor.name ]);
            };
            throw new Error("Failed pattern match at Data.Map line 151, column 1 - line 151, column 26: " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
};
var lookupGE = function (dictOrd) {
    return function (v) {
        return function (v1) {
            if (v1 instanceof Leaf) {
                return Data_Maybe.Nothing.value;
            };
            if (v1 instanceof Two) {
                var $132 = Data_Ord.compare(dictOrd)(v)(v1.value1);
                if ($132 instanceof Data_Ordering.EQ) {
                    return new Data_Maybe.Just({
                        key: v1.value1, 
                        value: v1.value2
                    });
                };
                if ($132 instanceof Data_Ordering.LT) {
                    return Data_Function.apply(Data_Maybe.Just.create)(Data_Function.apply(Data_Maybe.fromMaybe({
                        key: v1.value1, 
                        value: v1.value2
                    }))(lookupGE(dictOrd)(v)(v1.value0)));
                };
                if ($132 instanceof Data_Ordering.GT) {
                    return lookupGE(dictOrd)(v)(v1.value3);
                };
                throw new Error("Failed pattern match at Data.Map line 176, column 37 - line 179, column 25: " + [ $132.constructor.name ]);
            };
            if (v1 instanceof Three) {
                var $137 = Data_Ord.compare(dictOrd)(v)(v1.value1);
                if ($137 instanceof Data_Ordering.EQ) {
                    return new Data_Maybe.Just({
                        key: v1.value1, 
                        value: v1.value2
                    });
                };
                if ($137 instanceof Data_Ordering.LT) {
                    return Data_Function.apply(Data_Maybe.Just.create)(Data_Function.apply(Data_Maybe.fromMaybe({
                        key: v1.value1, 
                        value: v1.value2
                    }))(lookupGE(dictOrd)(v)(v1.value0)));
                };
                if ($137 instanceof Data_Ordering.GT) {
                    return Data_Function.apply(lookupGE(dictOrd)(v))(new Two(v1.value3, v1.value4, v1.value5, v1.value6));
                };
                throw new Error("Failed pattern match at Data.Map line 180, column 49 - line 183, column 41: " + [ $137.constructor.name ]);
            };
            throw new Error("Failed pattern match at Data.Map line 175, column 1 - line 175, column 26: " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
};
var lookup = function (dictOrd) {
    return Partial_Unsafe.unsafePartial(function (dictPartial) {
        return function (k) {
            return function (tree) {
                if (tree instanceof Leaf) {
                    return Data_Maybe.Nothing.value;
                };
                var comp = Data_Ord.compare(dictOrd);
                var __unused = function (dictPartial1) {
                    return function ($dollar35) {
                        return $dollar35;
                    };
                };
                return __unused(dictPartial)((function () {
                    if (tree instanceof Two) {
                        var $147 = comp(k)(tree.value1);
                        if ($147 instanceof Data_Ordering.EQ) {
                            return new Data_Maybe.Just(tree.value2);
                        };
                        if ($147 instanceof Data_Ordering.LT) {
                            return lookup(dictOrd)(k)(tree.value0);
                        };
                        return lookup(dictOrd)(k)(tree.value3);
                    };
                    if (tree instanceof Three) {
                        var $152 = comp(k)(tree.value1);
                        if ($152 instanceof Data_Ordering.EQ) {
                            return new Data_Maybe.Just(tree.value2);
                        };
                        var $154 = comp(k)(tree.value4);
                        if ($154 instanceof Data_Ordering.EQ) {
                            return new Data_Maybe.Just(tree.value5);
                        };
                        if ($152 instanceof Data_Ordering.LT) {
                            return lookup(dictOrd)(k)(tree.value0);
                        };
                        if ($154 instanceof Data_Ordering.GT) {
                            return lookup(dictOrd)(k)(tree.value6);
                        };
                        return lookup(dictOrd)(k)(tree.value3);
                    };
                    throw new Error("Failed pattern match at Data.Map line 132, column 10 - line 146, column 39: " + [ tree.constructor.name ]);
                })());
            };
        };
    });
};
var member = function (dictOrd) {
    return function (k) {
        return function (m) {
            return Data_Maybe.isJust(lookup(dictOrd)(k)(m));
        };
    };
};
var keys = function (v) {
    if (v instanceof Leaf) {
        return Data_List.Nil.value;
    };
    if (v instanceof Two) {
        return Data_Semigroup.append(Data_List.semigroupList)(keys(v.value0))(Data_Semigroup.append(Data_List.semigroupList)(Control_Applicative.pure(Data_List.applicativeList)(v.value1))(keys(v.value3)));
    };
    if (v instanceof Three) {
        return Data_Semigroup.append(Data_List.semigroupList)(keys(v.value0))(Data_Semigroup.append(Data_List.semigroupList)(Control_Applicative.pure(Data_List.applicativeList)(v.value1))(Data_Semigroup.append(Data_List.semigroupList)(keys(v.value3))(Data_Semigroup.append(Data_List.semigroupList)(Control_Applicative.pure(Data_List.applicativeList)(v.value4))(keys(v.value6)))));
    };
    throw new Error("Failed pattern match at Data.Map line 384, column 1 - line 384, column 16: " + [ v.constructor.name ]);
};
var isEmpty = function (v) {
    if (v instanceof Leaf) {
        return true;
    };
    return false;
};
var functorMap = new Data_Functor.Functor(function (v) {
    return function (v1) {
        if (v1 instanceof Leaf) {
            return Leaf.value;
        };
        if (v1 instanceof Two) {
            return new Two(Data_Functor.map(functorMap)(v)(v1.value0), v1.value1, v(v1.value2), Data_Functor.map(functorMap)(v)(v1.value3));
        };
        if (v1 instanceof Three) {
            return new Three(Data_Functor.map(functorMap)(v)(v1.value0), v1.value1, v(v1.value2), Data_Functor.map(functorMap)(v)(v1.value3), v1.value4, v(v1.value5), Data_Functor.map(functorMap)(v)(v1.value6));
        };
        throw new Error("Failed pattern match at Data.Map line 70, column 3 - line 70, column 20: " + [ v.constructor.name, v1.constructor.name ]);
    };
});
var fromZipper = function (__copy_dictOrd) {
    return function (__copy_v) {
        return function (__copy_tree) {
            var dictOrd = __copy_dictOrd;
            var v = __copy_v;
            var tree = __copy_tree;
            tco: while (true) {
                if (v instanceof Data_List.Nil) {
                    return tree;
                };
                if (v instanceof Data_List.Cons) {
                    if (v.value0 instanceof TwoLeft) {
                        var __tco_dictOrd = dictOrd;
                        var __tco_v = v.value1;
                        var __tco_tree = new Two(tree, v.value0.value0, v.value0.value1, v.value0.value2);
                        dictOrd = __tco_dictOrd;
                        v = __tco_v;
                        tree = __tco_tree;
                        continue tco;
                    };
                    if (v.value0 instanceof TwoRight) {
                        var __tco_dictOrd = dictOrd;
                        var __tco_v = v.value1;
                        var __tco_tree = new Two(v.value0.value0, v.value0.value1, v.value0.value2, tree);
                        dictOrd = __tco_dictOrd;
                        v = __tco_v;
                        tree = __tco_tree;
                        continue tco;
                    };
                    if (v.value0 instanceof ThreeLeft) {
                        var __tco_dictOrd = dictOrd;
                        var __tco_v = v.value1;
                        var __tco_tree = new Three(tree, v.value0.value0, v.value0.value1, v.value0.value2, v.value0.value3, v.value0.value4, v.value0.value5);
                        dictOrd = __tco_dictOrd;
                        v = __tco_v;
                        tree = __tco_tree;
                        continue tco;
                    };
                    if (v.value0 instanceof ThreeMiddle) {
                        var __tco_dictOrd = dictOrd;
                        var __tco_v = v.value1;
                        var __tco_tree = new Three(v.value0.value0, v.value0.value1, v.value0.value2, tree, v.value0.value3, v.value0.value4, v.value0.value5);
                        dictOrd = __tco_dictOrd;
                        v = __tco_v;
                        tree = __tco_tree;
                        continue tco;
                    };
                    if (v.value0 instanceof ThreeRight) {
                        var __tco_dictOrd = dictOrd;
                        var __tco_v = v.value1;
                        var __tco_tree = new Three(v.value0.value0, v.value0.value1, v.value0.value2, v.value0.value3, v.value0.value4, v.value0.value5, tree);
                        dictOrd = __tco_dictOrd;
                        v = __tco_v;
                        tree = __tco_tree;
                        continue tco;
                    };
                    throw new Error("Failed pattern match at Data.Map line 223, column 3 - line 228, column 88: " + [ v.value0.constructor.name ]);
                };
                throw new Error("Failed pattern match at Data.Map line 221, column 1 - line 221, column 27: " + [ v.constructor.name, tree.constructor.name ]);
            };
        };
    };
};
var insert = function (dictOrd) {
    var up = function (__copy_v) {
        return function (__copy_v1) {
            var v = __copy_v;
            var v1 = __copy_v1;
            tco: while (true) {
                if (v instanceof Data_List.Nil) {
                    return new Two(v1.value0, v1.value1, v1.value2, v1.value3);
                };
                if (v instanceof Data_List.Cons) {
                    if (v.value0 instanceof TwoLeft) {
                        return fromZipper(dictOrd)(v.value1)(new Three(v1.value0, v1.value1, v1.value2, v1.value3, v.value0.value0, v.value0.value1, v.value0.value2));
                    };
                    if (v.value0 instanceof TwoRight) {
                        return fromZipper(dictOrd)(v.value1)(new Three(v.value0.value0, v.value0.value1, v.value0.value2, v1.value0, v1.value1, v1.value2, v1.value3));
                    };
                    if (v.value0 instanceof ThreeLeft) {
                        var __tco_v = v.value1;
                        var __tco_v1 = new KickUp(new Two(v1.value0, v1.value1, v1.value2, v1.value3), v.value0.value0, v.value0.value1, new Two(v.value0.value2, v.value0.value3, v.value0.value4, v.value0.value5));
                        v = __tco_v;
                        v1 = __tco_v1;
                        continue tco;
                    };
                    if (v.value0 instanceof ThreeMiddle) {
                        var __tco_v = v.value1;
                        var __tco_v1 = new KickUp(new Two(v.value0.value0, v.value0.value1, v.value0.value2, v1.value0), v1.value1, v1.value2, new Two(v1.value3, v.value0.value3, v.value0.value4, v.value0.value5));
                        v = __tco_v;
                        v1 = __tco_v1;
                        continue tco;
                    };
                    if (v.value0 instanceof ThreeRight) {
                        var __tco_v = v.value1;
                        var __tco_v1 = new KickUp(new Two(v.value0.value0, v.value0.value1, v.value0.value2, v.value0.value3), v.value0.value4, v.value0.value5, new Two(v1.value0, v1.value1, v1.value2, v1.value3));
                        v = __tco_v;
                        v1 = __tco_v1;
                        continue tco;
                    };
                    throw new Error("Failed pattern match at Data.Map line 259, column 5 - line 264, column 104: " + [ v.value0.constructor.name, v1.constructor.name ]);
                };
                throw new Error("Failed pattern match at Data.Map line 257, column 3 - line 257, column 54: " + [ v.constructor.name, v1.constructor.name ]);
            };
        };
    };
    var comp = Data_Ord.compare(dictOrd);
    var down = function (__copy_ctx) {
        return function (__copy_k) {
            return function (__copy_v) {
                return function (__copy_v1) {
                    var ctx = __copy_ctx;
                    var k = __copy_k;
                    var v = __copy_v;
                    var v1 = __copy_v1;
                    tco: while (true) {
                        if (v1 instanceof Leaf) {
                            return up(ctx)(new KickUp(Leaf.value, k, v, Leaf.value));
                        };
                        if (v1 instanceof Two) {
                            var $275 = comp(k)(v1.value1);
                            if ($275 instanceof Data_Ordering.EQ) {
                                return fromZipper(dictOrd)(ctx)(new Two(v1.value0, k, v, v1.value3));
                            };
                            if ($275 instanceof Data_Ordering.LT) {
                                var __tco_ctx = new Data_List.Cons(new TwoLeft(v1.value1, v1.value2, v1.value3), ctx);
                                var __tco_k = k;
                                var __tco_v = v;
                                var __tco_v1 = v1.value0;
                                ctx = __tco_ctx;
                                k = __tco_k;
                                v = __tco_v;
                                v1 = __tco_v1;
                                continue tco;
                            };
                            var __tco_ctx = new Data_List.Cons(new TwoRight(v1.value0, v1.value1, v1.value2), ctx);
                            var __tco_k = k;
                            var __tco_v = v;
                            var __tco_v1 = v1.value3;
                            ctx = __tco_ctx;
                            k = __tco_k;
                            v = __tco_v;
                            v1 = __tco_v1;
                            continue tco;
                        };
                        if (v1 instanceof Three) {
                            var $280 = comp(k)(v1.value1);
                            if ($280 instanceof Data_Ordering.EQ) {
                                return fromZipper(dictOrd)(ctx)(new Three(v1.value0, k, v, v1.value3, v1.value4, v1.value5, v1.value6));
                            };
                            var $282 = comp(k)(v1.value4);
                            if ($282 instanceof Data_Ordering.EQ) {
                                return fromZipper(dictOrd)(ctx)(new Three(v1.value0, v1.value1, v1.value2, v1.value3, k, v, v1.value6));
                            };
                            if ($280 instanceof Data_Ordering.LT) {
                                var __tco_ctx = new Data_List.Cons(new ThreeLeft(v1.value1, v1.value2, v1.value3, v1.value4, v1.value5, v1.value6), ctx);
                                var __tco_k = k;
                                var __tco_v = v;
                                var __tco_v1 = v1.value0;
                                ctx = __tco_ctx;
                                k = __tco_k;
                                v = __tco_v;
                                v1 = __tco_v1;
                                continue tco;
                            };
                            if ($280 instanceof Data_Ordering.GT && $282 instanceof Data_Ordering.LT) {
                                var __tco_ctx = new Data_List.Cons(new ThreeMiddle(v1.value0, v1.value1, v1.value2, v1.value4, v1.value5, v1.value6), ctx);
                                var __tco_k = k;
                                var __tco_v = v;
                                var __tco_v1 = v1.value3;
                                ctx = __tco_ctx;
                                k = __tco_k;
                                v = __tco_v;
                                v1 = __tco_v1;
                                continue tco;
                            };
                            var __tco_ctx = new Data_List.Cons(new ThreeRight(v1.value0, v1.value1, v1.value2, v1.value3, v1.value4, v1.value5), ctx);
                            var __tco_k = k;
                            var __tco_v = v;
                            var __tco_v1 = v1.value6;
                            ctx = __tco_ctx;
                            k = __tco_k;
                            v = __tco_v;
                            v1 = __tco_v1;
                            continue tco;
                        };
                        throw new Error("Failed pattern match at Data.Map line 240, column 3 - line 240, column 52: " + [ ctx.constructor.name, k.constructor.name, v.constructor.name, v1.constructor.name ]);
                    };
                };
            };
        };
    };
    return down(Data_List.Nil.value);
};
var pop = function (dictOrd) {
    var up = Partial_Unsafe.unsafePartial(function (dictPartial) {
        return function (ctxs) {
            return function (tree) {
                if (ctxs instanceof Data_List.Nil) {
                    return tree;
                };
                if (ctxs instanceof Data_List.Cons) {
                    var __unused = function (dictPartial1) {
                        return function ($dollar43) {
                            return $dollar43;
                        };
                    };
                    return __unused(dictPartial)((function () {
                        if (ctxs.value0 instanceof TwoLeft && (ctxs.value0.value2 instanceof Leaf && tree instanceof Leaf)) {
                            return fromZipper(dictOrd)(ctxs.value1)(new Two(Leaf.value, ctxs.value0.value0, ctxs.value0.value1, Leaf.value));
                        };
                        if (ctxs.value0 instanceof TwoRight && (ctxs.value0.value0 instanceof Leaf && tree instanceof Leaf)) {
                            return fromZipper(dictOrd)(ctxs.value1)(new Two(Leaf.value, ctxs.value0.value1, ctxs.value0.value2, Leaf.value));
                        };
                        if (ctxs.value0 instanceof TwoLeft && ctxs.value0.value2 instanceof Two) {
                            return up(ctxs.value1)(new Three(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0, ctxs.value0.value2.value1, ctxs.value0.value2.value2, ctxs.value0.value2.value3));
                        };
                        if (ctxs.value0 instanceof TwoRight && ctxs.value0.value0 instanceof Two) {
                            return up(ctxs.value1)(new Three(ctxs.value0.value0.value0, ctxs.value0.value0.value1, ctxs.value0.value0.value2, ctxs.value0.value0.value3, ctxs.value0.value1, ctxs.value0.value2, tree));
                        };
                        if (ctxs.value0 instanceof TwoLeft && ctxs.value0.value2 instanceof Three) {
                            return fromZipper(dictOrd)(ctxs.value1)(new Two(new Two(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0), ctxs.value0.value2.value1, ctxs.value0.value2.value2, new Two(ctxs.value0.value2.value3, ctxs.value0.value2.value4, ctxs.value0.value2.value5, ctxs.value0.value2.value6)));
                        };
                        if (ctxs.value0 instanceof TwoRight && ctxs.value0.value0 instanceof Three) {
                            return fromZipper(dictOrd)(ctxs.value1)(new Two(new Two(ctxs.value0.value0.value0, ctxs.value0.value0.value1, ctxs.value0.value0.value2, ctxs.value0.value0.value3), ctxs.value0.value0.value4, ctxs.value0.value0.value5, new Two(ctxs.value0.value0.value6, ctxs.value0.value1, ctxs.value0.value2, tree)));
                        };
                        if (ctxs.value0 instanceof ThreeLeft && (ctxs.value0.value2 instanceof Leaf && (ctxs.value0.value5 instanceof Leaf && tree instanceof Leaf))) {
                            return fromZipper(dictOrd)(ctxs.value1)(new Three(Leaf.value, ctxs.value0.value0, ctxs.value0.value1, Leaf.value, ctxs.value0.value3, ctxs.value0.value4, Leaf.value));
                        };
                        if (ctxs.value0 instanceof ThreeMiddle && (ctxs.value0.value0 instanceof Leaf && (ctxs.value0.value5 instanceof Leaf && tree instanceof Leaf))) {
                            return fromZipper(dictOrd)(ctxs.value1)(new Three(Leaf.value, ctxs.value0.value1, ctxs.value0.value2, Leaf.value, ctxs.value0.value3, ctxs.value0.value4, Leaf.value));
                        };
                        if (ctxs.value0 instanceof ThreeRight && (ctxs.value0.value0 instanceof Leaf && (ctxs.value0.value3 instanceof Leaf && tree instanceof Leaf))) {
                            return fromZipper(dictOrd)(ctxs.value1)(new Three(Leaf.value, ctxs.value0.value1, ctxs.value0.value2, Leaf.value, ctxs.value0.value4, ctxs.value0.value5, Leaf.value));
                        };
                        if (ctxs.value0 instanceof ThreeLeft && ctxs.value0.value2 instanceof Two) {
                            return fromZipper(dictOrd)(ctxs.value1)(new Two(new Three(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0, ctxs.value0.value2.value1, ctxs.value0.value2.value2, ctxs.value0.value2.value3), ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5));
                        };
                        if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value0 instanceof Two) {
                            return fromZipper(dictOrd)(ctxs.value1)(new Two(new Three(ctxs.value0.value0.value0, ctxs.value0.value0.value1, ctxs.value0.value0.value2, ctxs.value0.value0.value3, ctxs.value0.value1, ctxs.value0.value2, tree), ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5));
                        };
                        if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value5 instanceof Two) {
                            return fromZipper(dictOrd)(ctxs.value1)(new Two(ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2, new Three(tree, ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5.value0, ctxs.value0.value5.value1, ctxs.value0.value5.value2, ctxs.value0.value5.value3)));
                        };
                        if (ctxs.value0 instanceof ThreeRight && ctxs.value0.value3 instanceof Two) {
                            return fromZipper(dictOrd)(ctxs.value1)(new Two(ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2, new Three(ctxs.value0.value3.value0, ctxs.value0.value3.value1, ctxs.value0.value3.value2, ctxs.value0.value3.value3, ctxs.value0.value4, ctxs.value0.value5, tree)));
                        };
                        if (ctxs.value0 instanceof ThreeLeft && ctxs.value0.value2 instanceof Three) {
                            return fromZipper(dictOrd)(ctxs.value1)(new Three(new Two(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0), ctxs.value0.value2.value1, ctxs.value0.value2.value2, new Two(ctxs.value0.value2.value3, ctxs.value0.value2.value4, ctxs.value0.value2.value5, ctxs.value0.value2.value6), ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5));
                        };
                        if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value0 instanceof Three) {
                            return fromZipper(dictOrd)(ctxs.value1)(new Three(new Two(ctxs.value0.value0.value0, ctxs.value0.value0.value1, ctxs.value0.value0.value2, ctxs.value0.value0.value3), ctxs.value0.value0.value4, ctxs.value0.value0.value5, new Two(ctxs.value0.value0.value6, ctxs.value0.value1, ctxs.value0.value2, tree), ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5));
                        };
                        if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value5 instanceof Three) {
                            return fromZipper(dictOrd)(ctxs.value1)(new Three(ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2, new Two(tree, ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5.value0), ctxs.value0.value5.value1, ctxs.value0.value5.value2, new Two(ctxs.value0.value5.value3, ctxs.value0.value5.value4, ctxs.value0.value5.value5, ctxs.value0.value5.value6)));
                        };
                        if (ctxs.value0 instanceof ThreeRight && ctxs.value0.value3 instanceof Three) {
                            return fromZipper(dictOrd)(ctxs.value1)(new Three(ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2, new Two(ctxs.value0.value3.value0, ctxs.value0.value3.value1, ctxs.value0.value3.value2, ctxs.value0.value3.value3), ctxs.value0.value3.value4, ctxs.value0.value3.value5, new Two(ctxs.value0.value3.value6, ctxs.value0.value4, ctxs.value0.value5, tree)));
                        };
                        throw new Error("Failed pattern match at Data.Map line 309, column 9 - line 326, column 136: " + [ ctxs.value0.constructor.name, tree.constructor.name ]);
                    })());
                };
                throw new Error("Failed pattern match at Data.Map line 306, column 5 - line 326, column 136: " + [ ctxs.constructor.name ]);
            };
        };
    });
    var removeMaxNode = Partial_Unsafe.unsafePartial(function (dictPartial) {
        return function (ctx) {
            return function (m) {
                var __unused = function (dictPartial1) {
                    return function ($dollar45) {
                        return $dollar45;
                    };
                };
                return __unused(dictPartial)((function () {
                    if (m instanceof Two && (m.value0 instanceof Leaf && m.value3 instanceof Leaf)) {
                        return up(ctx)(Leaf.value);
                    };
                    if (m instanceof Two) {
                        return removeMaxNode(new Data_List.Cons(new TwoRight(m.value0, m.value1, m.value2), ctx))(m.value3);
                    };
                    if (m instanceof Three && (m.value0 instanceof Leaf && (m.value3 instanceof Leaf && m.value6 instanceof Leaf))) {
                        return up(new Data_List.Cons(new TwoRight(Leaf.value, m.value1, m.value2), ctx))(Leaf.value);
                    };
                    if (m instanceof Three) {
                        return removeMaxNode(new Data_List.Cons(new ThreeRight(m.value0, m.value1, m.value2, m.value3, m.value4, m.value5), ctx))(m.value6);
                    };
                    throw new Error("Failed pattern match at Data.Map line 338, column 5 - line 342, column 107: " + [ m.constructor.name ]);
                })());
            };
        };
    });
    var maxNode = Partial_Unsafe.unsafePartial(function (dictPartial) {
        return function (m) {
            var __unused = function (dictPartial1) {
                return function ($dollar47) {
                    return $dollar47;
                };
            };
            return __unused(dictPartial)((function () {
                if (m instanceof Two && m.value3 instanceof Leaf) {
                    return {
                        key: m.value1, 
                        value: m.value2
                    };
                };
                if (m instanceof Two) {
                    return maxNode(m.value3);
                };
                if (m instanceof Three && m.value6 instanceof Leaf) {
                    return {
                        key: m.value4, 
                        value: m.value5
                    };
                };
                if (m instanceof Three) {
                    return maxNode(m.value6);
                };
                throw new Error("Failed pattern match at Data.Map line 329, column 33 - line 333, column 45: " + [ m.constructor.name ]);
            })());
        };
    });
    var comp = Data_Ord.compare(dictOrd);
    var down = Partial_Unsafe.unsafePartial(function (dictPartial) {
        return function (ctx) {
            return function (k) {
                return function (m) {
                    if (m instanceof Leaf) {
                        return Data_Maybe.Nothing.value;
                    };
                    if (m instanceof Two) {
                        var $493 = comp(k)(m.value1);
                        if (m.value3 instanceof Leaf && $493 instanceof Data_Ordering.EQ) {
                            return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value2, up(ctx)(Leaf.value)));
                        };
                        if ($493 instanceof Data_Ordering.EQ) {
                            var max = maxNode(m.value0);
                            return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value2, removeMaxNode(new Data_List.Cons(new TwoLeft(max.key, max.value, m.value3), ctx))(m.value0)));
                        };
                        if ($493 instanceof Data_Ordering.LT) {
                            return down(new Data_List.Cons(new TwoLeft(m.value1, m.value2, m.value3), ctx))(k)(m.value0);
                        };
                        return down(new Data_List.Cons(new TwoRight(m.value0, m.value1, m.value2), ctx))(k)(m.value3);
                    };
                    if (m instanceof Three) {
                        var leaves = (function () {
                            if (m.value0 instanceof Leaf && (m.value3 instanceof Leaf && m.value6 instanceof Leaf)) {
                                return true;
                            };
                            return false;
                        })();
                        var $502 = comp(k)(m.value1);
                        var $503 = comp(k)(m.value4);
                        if (leaves && $502 instanceof Data_Ordering.EQ) {
                            return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value2, fromZipper(dictOrd)(ctx)(new Two(Leaf.value, m.value4, m.value5, Leaf.value))));
                        };
                        if (leaves && $503 instanceof Data_Ordering.EQ) {
                            return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value5, fromZipper(dictOrd)(ctx)(new Two(Leaf.value, m.value1, m.value2, Leaf.value))));
                        };
                        if ($502 instanceof Data_Ordering.EQ) {
                            var max = maxNode(m.value0);
                            return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value2, removeMaxNode(new Data_List.Cons(new ThreeLeft(max.key, max.value, m.value3, m.value4, m.value5, m.value6), ctx))(m.value0)));
                        };
                        if ($503 instanceof Data_Ordering.EQ) {
                            var max = maxNode(m.value3);
                            return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value5, removeMaxNode(new Data_List.Cons(new ThreeMiddle(m.value0, m.value1, m.value2, max.key, max.value, m.value6), ctx))(m.value3)));
                        };
                        if ($502 instanceof Data_Ordering.LT) {
                            return down(new Data_List.Cons(new ThreeLeft(m.value1, m.value2, m.value3, m.value4, m.value5, m.value6), ctx))(k)(m.value0);
                        };
                        if ($502 instanceof Data_Ordering.GT && $503 instanceof Data_Ordering.LT) {
                            return down(new Data_List.Cons(new ThreeMiddle(m.value0, m.value1, m.value2, m.value4, m.value5, m.value6), ctx))(k)(m.value3);
                        };
                        return down(new Data_List.Cons(new ThreeRight(m.value0, m.value1, m.value2, m.value3, m.value4, m.value5), ctx))(k)(m.value6);
                    };
                    throw new Error("Failed pattern match at Data.Map line 279, column 36 - line 302, column 82: " + [ m.constructor.name ]);
                };
            };
        };
    });
    return down(Data_List.Nil.value);
};
var foldableMap = new Data_Foldable.Foldable(function (dictMonoid) {
    return function (f) {
        return function (m) {
            return Data_Foldable.foldMap(Data_List.foldableList)(dictMonoid)(f)(values(m));
        };
    };
}, function (f) {
    return function (z) {
        return function (m) {
            return Data_Foldable.foldl(Data_List.foldableList)(f)(z)(values(m));
        };
    };
}, function (f) {
    return function (z) {
        return function (m) {
            return Data_Foldable.foldr(Data_List.foldableList)(f)(z)(values(m));
        };
    };
});
var findMin = function (v) {
    if (v instanceof Leaf) {
        return Data_Maybe.Nothing.value;
    };
    if (v instanceof Two) {
        return Data_Function.apply(Data_Maybe.Just.create)(Data_Function.apply(Data_Maybe.fromMaybe({
            key: v.value1, 
            value: v.value2
        }))(findMin(v.value0)));
    };
    if (v instanceof Three) {
        return Data_Function.apply(Data_Maybe.Just.create)(Data_Function.apply(Data_Maybe.fromMaybe({
            key: v.value1, 
            value: v.value2
        }))(findMin(v.value0)));
    };
    throw new Error("Failed pattern match at Data.Map line 205, column 1 - line 205, column 23: " + [ v.constructor.name ]);
};
var lookupGT = function (dictOrd) {
    return function (v) {
        return function (v1) {
            if (v1 instanceof Leaf) {
                return Data_Maybe.Nothing.value;
            };
            if (v1 instanceof Two) {
                var $525 = Data_Ord.compare(dictOrd)(v)(v1.value1);
                if ($525 instanceof Data_Ordering.EQ) {
                    return findMin(v1.value3);
                };
                if ($525 instanceof Data_Ordering.LT) {
                    return Data_Function.apply(Data_Maybe.Just.create)(Data_Function.apply(Data_Maybe.fromMaybe({
                        key: v1.value1, 
                        value: v1.value2
                    }))(lookupGT(dictOrd)(v)(v1.value0)));
                };
                if ($525 instanceof Data_Ordering.GT) {
                    return lookupGT(dictOrd)(v)(v1.value3);
                };
                throw new Error("Failed pattern match at Data.Map line 188, column 37 - line 191, column 25: " + [ $525.constructor.name ]);
            };
            if (v1 instanceof Three) {
                var $530 = Data_Ord.compare(dictOrd)(v)(v1.value1);
                if ($530 instanceof Data_Ordering.EQ) {
                    return Data_Function.apply(findMin)(new Two(v1.value3, v1.value4, v1.value5, v1.value6));
                };
                if ($530 instanceof Data_Ordering.LT) {
                    return Data_Function.apply(Data_Maybe.Just.create)(Data_Function.apply(Data_Maybe.fromMaybe({
                        key: v1.value1, 
                        value: v1.value2
                    }))(lookupGT(dictOrd)(v)(v1.value0)));
                };
                if ($530 instanceof Data_Ordering.GT) {
                    return Data_Function.apply(lookupGT(dictOrd)(v))(new Two(v1.value3, v1.value4, v1.value5, v1.value6));
                };
                throw new Error("Failed pattern match at Data.Map line 192, column 49 - line 195, column 41: " + [ $530.constructor.name ]);
            };
            throw new Error("Failed pattern match at Data.Map line 187, column 1 - line 187, column 26: " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
};
var findMax = function (v) {
    if (v instanceof Leaf) {
        return Data_Maybe.Nothing.value;
    };
    if (v instanceof Two) {
        return Data_Function.apply(Data_Maybe.Just.create)(Data_Function.apply(Data_Maybe.fromMaybe({
            key: v.value1, 
            value: v.value2
        }))(findMax(v.value3)));
    };
    if (v instanceof Three) {
        return Data_Function.apply(Data_Maybe.Just.create)(Data_Function.apply(Data_Maybe.fromMaybe({
            key: v.value4, 
            value: v.value5
        }))(findMax(v.value6)));
    };
    throw new Error("Failed pattern match at Data.Map line 199, column 1 - line 199, column 23: " + [ v.constructor.name ]);
};
var lookupLT = function (dictOrd) {
    return function (v) {
        return function (v1) {
            if (v1 instanceof Leaf) {
                return Data_Maybe.Nothing.value;
            };
            if (v1 instanceof Two) {
                var $552 = Data_Ord.compare(dictOrd)(v)(v1.value1);
                if ($552 instanceof Data_Ordering.EQ) {
                    return findMax(v1.value0);
                };
                if ($552 instanceof Data_Ordering.GT) {
                    return Data_Function.apply(Data_Maybe.Just.create)(Data_Function.apply(Data_Maybe.fromMaybe({
                        key: v1.value1, 
                        value: v1.value2
                    }))(lookupLT(dictOrd)(v)(v1.value3)));
                };
                if ($552 instanceof Data_Ordering.LT) {
                    return lookupLT(dictOrd)(v)(v1.value0);
                };
                throw new Error("Failed pattern match at Data.Map line 164, column 37 - line 167, column 24: " + [ $552.constructor.name ]);
            };
            if (v1 instanceof Three) {
                var $557 = Data_Ord.compare(dictOrd)(v)(v1.value4);
                if ($557 instanceof Data_Ordering.EQ) {
                    return Data_Function.apply(findMax)(new Two(v1.value0, v1.value1, v1.value2, v1.value3));
                };
                if ($557 instanceof Data_Ordering.GT) {
                    return Data_Function.apply(Data_Maybe.Just.create)(Data_Function.apply(Data_Maybe.fromMaybe({
                        key: v1.value4, 
                        value: v1.value5
                    }))(lookupLT(dictOrd)(v)(v1.value6)));
                };
                if ($557 instanceof Data_Ordering.LT) {
                    return Data_Function.apply(lookupLT(dictOrd)(v))(new Two(v1.value0, v1.value1, v1.value2, v1.value3));
                };
                throw new Error("Failed pattern match at Data.Map line 168, column 49 - line 171, column 40: " + [ $557.constructor.name ]);
            };
            throw new Error("Failed pattern match at Data.Map line 163, column 1 - line 163, column 26: " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
};
var eqMap = function (dictEq) {
    return function (dictEq1) {
        return new Data_Eq.Eq(function (m1) {
            return function (m2) {
                return Data_Eq.eq(Data_List.eqList(Data_Tuple.eqTuple(dictEq)(dictEq1)))(toList(m1))(toList(m2));
            };
        });
    };
};
var ordMap = function (dictOrd) {
    return function (dictOrd1) {
        return new Data_Ord.Ord(function () {
            return eqMap(dictOrd["__superclass_Data.Eq.Eq_0"]())(dictOrd1["__superclass_Data.Eq.Eq_0"]());
        }, function (m1) {
            return function (m2) {
                return Data_Ord.compare(Data_List.ordList(Data_Tuple.ordTuple(dictOrd)(dictOrd1)))(toList(m1))(toList(m2));
            };
        });
    };
};
var empty = Leaf.value;
var fromFoldable = function (dictOrd) {
    return function (dictFoldable) {
        return Data_Foldable.foldl(dictFoldable)(function (m) {
            return function (v) {
                return insert(dictOrd)(v.value0)(v.value1)(m);
            };
        })(empty);
    };
};
var fromList = function (dictOrd) {
    return fromFoldable(dictOrd)(Data_List.foldableList);
};
var $$delete = function (dictOrd) {
    return function (k) {
        return function (m) {
            return Data_Maybe.maybe(m)(Data_Tuple.snd)(pop(dictOrd)(k)(m));
        };
    };
};
var checkValid = function (tree) {
    var allHeights = function (v) {
        if (v instanceof Leaf) {
            return Control_Applicative.pure(Data_List.applicativeList)(0);
        };
        if (v instanceof Two) {
            return Data_Functor.map(Data_List.functorList)(function (n) {
                return n + 1 | 0;
            })(Data_Semigroup.append(Data_List.semigroupList)(allHeights(v.value0))(allHeights(v.value3)));
        };
        if (v instanceof Three) {
            return Data_Functor.map(Data_List.functorList)(function (n) {
                return n + 1 | 0;
            })(Data_Semigroup.append(Data_List.semigroupList)(allHeights(v.value0))(Data_Semigroup.append(Data_List.semigroupList)(allHeights(v.value3))(allHeights(v.value6))));
        };
        throw new Error("Failed pattern match at Data.Map line 120, column 3 - line 120, column 30: " + [ v.constructor.name ]);
    };
    return Data_List.length(Data_List.nub(Data_Eq.eqInt)(allHeights(tree))) === 1;
};
var alter = function (dictOrd) {
    return function (f) {
        return function (k) {
            return function (m) {
                var $580 = f(lookup(dictOrd)(k)(m));
                if ($580 instanceof Data_Maybe.Nothing) {
                    return $$delete(dictOrd)(k)(m);
                };
                if ($580 instanceof Data_Maybe.Just) {
                    return insert(dictOrd)(k)($580.value0)(m);
                };
                throw new Error("Failed pattern match at Data.Map line 347, column 15 - line 349, column 25: " + [ $580.constructor.name ]);
            };
        };
    };
};
var fromFoldableWith = function (dictOrd) {
    return function (dictFoldable) {
        return function (f) {
            var combine = function (v) {
                return function (v1) {
                    if (v1 instanceof Data_Maybe.Just) {
                        return Data_Function.apply(Data_Maybe.Just.create)(f(v)(v1.value0));
                    };
                    if (v1 instanceof Data_Maybe.Nothing) {
                        return new Data_Maybe.Just(v);
                    };
                    throw new Error("Failed pattern match at Data.Map line 364, column 3 - line 364, column 38: " + [ v.constructor.name, v1.constructor.name ]);
                };
            };
            return Data_Foldable.foldl(dictFoldable)(function (m) {
                return function (v) {
                    return alter(dictOrd)(combine(v.value1))(v.value0)(m);
                };
            })(empty);
        };
    };
};
var fromListWith = function (dictOrd) {
    return fromFoldableWith(dictOrd)(Data_List.foldableList);
};
var unionWith = function (dictOrd) {
    return function (f) {
        return function (m1) {
            return function (m2) {
                var go = function (m) {
                    return function (v) {
                        return alter(dictOrd)(function ($593) {
                            return Data_Maybe.Just.create(Data_Maybe.maybe(v.value1)(f(v.value1))($593));
                        })(v.value0)(m);
                    };
                };
                return Data_Foldable.foldl(Data_List.foldableList)(go)(m2)(toList(m1));
            };
        };
    };
};
var union = function (dictOrd) {
    return unionWith(dictOrd)(Data_Function["const"]);
};
var semigroupMap = function (dictOrd) {
    return new Data_Semigroup.Semigroup(union(dictOrd));
};
var monoidMap = function (dictOrd) {
    return new Data_Monoid.Monoid(function () {
        return semigroupMap(dictOrd);
    }, empty);
};
var traversableMap = function (dictOrd) {
    return new Data_Traversable.Traversable(function () {
        return foldableMap;
    }, function () {
        return functorMap;
    }, function (dictApplicative) {
        return Data_Traversable.traverse(traversableMap(dictOrd))(dictApplicative)(Control_Category.id(Control_Category.categoryFn));
    }, function (dictApplicative) {
        return function (f) {
            return function (ms) {
                return Data_Foldable.foldr(Data_List.foldableList)(function (x) {
                    return function (acc) {
                        return Control_Apply.apply(dictApplicative["__superclass_Control.Apply.Apply_0"]())(Data_Functor.map((dictApplicative["__superclass_Control.Apply.Apply_0"]())["__superclass_Data.Functor.Functor_0"]())(union(dictOrd))(x))(acc);
                    };
                })(Control_Applicative.pure(dictApplicative)(empty))(Data_Functor.map(Data_List.functorList)(Data_Functor.map((dictApplicative["__superclass_Control.Apply.Apply_0"]())["__superclass_Data.Functor.Functor_0"]())(Data_Tuple.uncurry(singleton)))(Data_Functor.map(Data_List.functorList)(Data_Traversable.traverse(Data_Tuple.traversableTuple)(dictApplicative)(f))(toList(ms))));
            };
        };
    });
};
var unions = function (dictOrd) {
    return function (dictFoldable) {
        return Data_Foldable.foldl(dictFoldable)(union(dictOrd))(empty);
    };
};
var update = function (dictOrd) {
    return function (f) {
        return function (k) {
            return function (m) {
                return alter(dictOrd)(Data_Maybe.maybe(Data_Maybe.Nothing.value)(f))(k)(m);
            };
        };
    };
};
module.exports = {
    alter: alter, 
    checkValid: checkValid, 
    "delete": $$delete, 
    empty: empty, 
    findMax: findMax, 
    findMin: findMin, 
    fromFoldable: fromFoldable, 
    fromFoldableWith: fromFoldableWith, 
    fromList: fromList, 
    fromListWith: fromListWith, 
    insert: insert, 
    isEmpty: isEmpty, 
    keys: keys, 
    lookup: lookup, 
    lookupGE: lookupGE, 
    lookupGT: lookupGT, 
    lookupLE: lookupLE, 
    lookupLT: lookupLT, 
    member: member, 
    pop: pop, 
    showTree: showTree, 
    singleton: singleton, 
    size: size, 
    toList: toList, 
    union: union, 
    unionWith: unionWith, 
    unions: unions, 
    update: update, 
    values: values, 
    eqMap: eqMap, 
    showMap: showMap, 
    ordMap: ordMap, 
    semigroupMap: semigroupMap, 
    monoidMap: monoidMap, 
    functorMap: functorMap, 
    foldableMap: foldableMap, 
    traversableMap: traversableMap
};
