// Generated by psc version 0.9.1
"use strict";
var Pux_Html = require("../Pux.Html");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var Pux_Html_Attributes = require("../Pux.Html.Attributes");
var Pux_Html_Events = require("../Pux.Html.Events");
var Prelude = require("../Prelude");
var State_CurrentMockup = require("../State.CurrentMockup");
var Model_Expenses = require("../Model.Expenses");
var View_Place = require("../View.Place");
var Data_Functor = require("../Data.Functor");
var Data_Function = require("../Data.Function");
var CurrentValue = function (x) {
    return x;
};
var inH3 = function (html) {
    return Pux_Html_Elements.div([ Pux_Html_Attributes.className("row") ])([ Pux_Html_Elements.fieldset([ Pux_Html_Attributes.className("column") ])(html) ]);
};
var editTravellingTo = function (p) {
    return Data_Functor.map(Pux_Html_Elements.functorHtml)(State_CurrentMockup.TravellingToChanged.create)(View_Place.viewReturn(p));
};
var editTravellingFrom = function (p) {
    return Data_Functor.map(Pux_Html_Elements.functorHtml)(State_CurrentMockup.TravellingFromChanged.create)(View_Place.viewGo(p));
};
var editTextField = function (v) {
    return function (v1) {
        return function (action) {
            return Pux_Html_Elements.div([  ])([ Pux_Html_Elements.label([ Pux_Html_Attributes.htmlFor(v.htmlId), Pux_Html_Attributes.className(v.inputClass) ])([ Pux_Html_Elements.text(v.fieldLabel) ]), Pux_Html_Elements.p([ Pux_Html_Attributes.className("help-text") ])([ Pux_Html_Elements.text(v.helpText) ]), Pux_Html_Elements.input([ Pux_Html_Attributes.id_(v.htmlId), Pux_Html_Attributes.type_("text"), Pux_Html_Attributes.value(v1), Pux_Html_Events.onChange(action) ])([  ]) ]);
        };
    };
};
var editTextArea = function (htmlId) {
    return function (fieldLabel) {
        return function (helpText) {
            return function (inputClass) {
                return function (currentValue) {
                    return function (action) {
                        return function (theRows) {
                            return Pux_Html_Elements.div([  ])([ Pux_Html_Elements.label([ Pux_Html_Attributes.htmlFor(htmlId), Pux_Html_Attributes.className(inputClass) ])([ Pux_Html_Elements.text(fieldLabel) ]), Pux_Html_Elements.p([ Pux_Html_Attributes.className("help-text") ])([ Pux_Html_Elements.text(helpText) ]), Pux_Html_Elements.textarea([ Pux_Html_Attributes.id_(htmlId), Pux_Html_Attributes.cols(60), Pux_Html_Attributes.rows(theRows), Pux_Html_Attributes.value(currentValue), Pux_Html_Events.onChange(action) ])([  ]) ]);
                        };
                    };
                };
            };
        };
    };
};
var editText = function (htmlId) {
    return function (fieldLabel) {
        return function (helpText) {
            return function (inputClass) {
                return function (currentValue) {
                    return function (action) {
                        return Pux_Html_Elements.div([  ])([ Pux_Html_Elements.label([ Pux_Html_Attributes.htmlFor(htmlId), Pux_Html_Attributes.className(inputClass) ])([ Pux_Html_Elements.text(fieldLabel) ]), Pux_Html_Elements.p([ Pux_Html_Attributes.className("help-text") ])([ Pux_Html_Elements.text(helpText) ]), Pux_Html_Elements.input([ Pux_Html_Attributes.id_(htmlId), Pux_Html_Attributes.type_("text"), Pux_Html_Attributes.value(currentValue), Pux_Html_Events.onChange(action) ])([  ]) ]);
                    };
                };
            };
        };
    };
};
var editExpensesRemarks = function (e) {
    return editTextArea("session-expensesremark")("Detailed explanation")("Please explain what you would need in terms of accomodation and anything else (e.g. nights staying, willing to share a room with a co-presenter)")("required-after")(Model_Expenses.getExpenseRemark(e))(State_CurrentMockup.ExpenseRemarksChanged.create)(4);
};
var editExpenseRequest = function (detail) {
    return Pux_Html_Elements.fieldset([  ])([ editTravellingFrom(Model_Expenses.getTravellingFrom(detail)), editTravellingTo(Model_Expenses.getTravellingTo(detail)), editExpensesRemarks(detail) ]);
};
var editElaborateExpenses = function (e) {
    if (e instanceof Model_Expenses.PleaseChoose) {
        return Pux_Html_Elements.text("Choosing whether or not to claim expenses is mandatory.");
    };
    if (e instanceof Model_Expenses.No) {
        return Pux_Html_Elements.text("By selecting No on this form, you acknowledge that you will not be able to claim expenses if your session is accepted. If you, or a fellow speaker on this submission, decides you will need travel or accommodation support, it must be indicated here.");
    };
    if (e instanceof Model_Expenses.Yes) {
        return editExpenseRequest(e.value0);
    };
    throw new Error("Failed pattern match at View.ExpenseRequest line 46, column 27 - line 49, column 44: " + [ e.constructor.name ]);
};
var chooseToFileExpenses = function (current) {
    return Pux_Html_Elements.div([  ])([ Pux_Html_Elements.label([ Pux_Html_Attributes.htmlFor("session-expenses"), Pux_Html_Attributes.className("required-after") ])([ Pux_Html_Elements.text("Do you want to claim expenses?") ]), Pux_Html_Elements.p([ Pux_Html_Attributes.className("help-text") ])([ Pux_Html_Elements.text("") ]), Pux_Html_Elements.select([ Pux_Html_Attributes.id_("session-expenses"), Pux_Html_Attributes.value(current), Pux_Html_Events.onChange(State_CurrentMockup.ChooseExpenseKind.create) ])(Data_Functor.map(Data_Functor.functorArray)(function (v) {
        return Pux_Html_Elements.option([ Pux_Html_Attributes.value(v) ])([ Pux_Html_Elements.text(v) ]);
    })(State_CurrentMockup.mainLabels)) ]);
};
var editExpenses = function (e) {
    return [ Data_Function.apply(chooseToFileExpenses)(State_CurrentMockup.currentChoice(e)), editElaborateExpenses(e) ];
};
var showExpenses = function (s) {
    if (s instanceof Model_Expenses.BasicExpenses) {
        return Pux_Html_Elements.text("You have opened an old submission this can not be edited");
    };
    if (s instanceof Model_Expenses.ElaborateExpenses) {
        return Data_Function.apply(inH3)(editExpenses(s.value0));
    };
    throw new Error("Failed pattern match at View.ExpenseRequest line 26, column 18 - line 28, column 53: " + [ s.constructor.name ]);
};
var expenseRequestView = function (s) {
    return Pux_Html_Elements.section([  ])([ Pux_Html_Elements.div([ Pux_Html_Attributes.className("row") ])([ Pux_Html_Elements.div([ Pux_Html_Attributes.className("column") ])([ Pux_Html_Elements.h2([  ])([ Pux_Html_Elements.text("Expense request") ]), showExpenses(s) ]) ]) ]);
};
module.exports = {
    expenseRequestView: expenseRequestView
};
