// Generated by psc version 0.9.1
"use strict";
var Data_Firebase_Foreign = require("../Data.Firebase.Foreign");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var Data_Generic = require("../Data.Generic");
var Prelude = require("../Prelude");
var Test_QuickCheck_Arbitrary = require("../Test.QuickCheck.Arbitrary");
var UReview_Prelude = require("../UReview.Prelude");
var Data_Maybe = require("../Data.Maybe");
var Data_Show = require("../Data.Show");
var Data_Eq = require("../Data.Eq");
var Data_Ord = require("../Data.Ord");
var Data_Functor = require("../Data.Functor");
var Data_Function = require("../Data.Function");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans");
var Data_Identity = require("../Data.Identity");
var Active = (function () {
    function Active() {

    };
    Active.value = new Active();
    return Active;
})();
var InActive = (function () {
    function InActive() {

    };
    InActive.value = new InActive();
    return InActive;
})();
var genericActivation = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Data.Activation.Active" && v.value1.length === 0)) {
        return new Data_Maybe.Just(Active.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "Data.Activation.InActive" && v.value1.length === 0)) {
        return new Data_Maybe.Just(InActive.value);
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Data.Activation.Activation", [ {
        sigConstructor: "Data.Activation.Active", 
        sigValues: [  ]
    }, {
        sigConstructor: "Data.Activation.InActive", 
        sigValues: [  ]
    } ]);
}, function (v) {
    if (v instanceof Active) {
        return new Data_Generic.SProd("Data.Activation.Active", [  ]);
    };
    if (v instanceof InActive) {
        return new Data_Generic.SProd("Data.Activation.InActive", [  ]);
    };
    throw new Error("Failed pattern match at Data.Activation line 32, column 1 - line 32, column 56: " + [ v.constructor.name ]);
});
var isForeignActivation = new Data_Foreign_Class.IsForeign(function (value) {
    return UReview_Prelude.readForeign(isForeignActivation)(genericActivation)(value);
});
var showActivation = new Data_Show.Show(Data_Generic.gShow(genericActivation));
var fromBoolean = function (v) {
    if (v) {
        return Active.value;
    };
    if (!v) {
        return InActive.value;
    };
    throw new Error("Failed pattern match at Data.Activation line 28, column 1 - line 28, column 26: " + [ v.constructor.name ]);
};
var eqActivation = new Data_Eq.Eq(Data_Generic.gEq(genericActivation));
var deactivate = InActive.value;
var asBoolean = function (v) {
    if (v instanceof Active) {
        return true;
    };
    if (v instanceof InActive) {
        return false;
    };
    throw new Error("Failed pattern match at Data.Activation line 24, column 1 - line 24, column 24: " + [ v.constructor.name ]);
};
var ordActivation = new Data_Ord.Ord(function () {
    return eqActivation;
}, function (lhs) {
    return function (rhs) {
        return Data_Ord.compare(Data_Ord.ordBoolean)(asBoolean(lhs))(asBoolean(rhs));
    };
});
var toFireActivation = new Data_Firebase_Foreign.ToFirebaseForeign(function (a) {
    return Data_Function.apply(Data_Firebase_Foreign.toFire(Data_Firebase_Foreign.toFirebaseForeignBoolean))(asBoolean(a));
});
var arbitraryActivation = new Test_QuickCheck_Arbitrary.Arbitrary(Data_Functor.map(Control_Monad_State_Trans.functorStateT(Data_Identity.functorIdentity))(fromBoolean)(Test_QuickCheck_Arbitrary.arbitrary(Test_QuickCheck_Arbitrary.arbBoolean)));
var activate = Active.value;
module.exports = {
    Active: Active, 
    InActive: InActive, 
    activate: activate, 
    asBoolean: asBoolean, 
    deactivate: deactivate, 
    fromBoolean: fromBoolean, 
    genericActivation: genericActivation, 
    showActivation: showActivation, 
    eqActivation: eqActivation, 
    isForeignActivation: isForeignActivation, 
    ordActivation: ordActivation, 
    arbitraryActivation: arbitraryActivation, 
    toFireActivation: toFireActivation
};
