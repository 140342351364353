// Generated by psc version 0.9.1
"use strict";
var Components_DB_Action = require("../Components.DB.Action");
var Control_Monad_Aff = require("../Control.Monad.Aff");
var Control_Monad_Aff_Exception = require("../Control.Monad.Aff.Exception");
var Control_Monad_Eff_Exception = require("../Control.Monad.Eff.Exception");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class");
var Data_Either = require("../Data.Either");
var Data_Firebase_Foreign = require("../Data.Firebase.Foreign");
var Data_Firebase_WriteParentChild = require("../Data.Firebase.WriteParentChild");
var Data_Foreign = require("../Data.Foreign");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_List = require("../Data.List");
var Data_PathTo = require("../Data.PathTo");
var Data_Show = require("../Data.Show");
var Data_StrMap = require("../Data.StrMap");
var Data_Tuple = require("../Data.Tuple");
var Debug_Trace = require("../Debug.Trace");
var Model_Review = require("../Model.Review");
var Ports_Firebase_Aff_1 = require("../Ports.Firebase.Aff");
var Ports_Firebase_Aff_1 = require("../Ports.Firebase.Aff");
var Ports_Firebase_Eff = require("../Ports.Firebase.Eff");
var Ports_Firebase_Types = require("../Ports.Firebase.Types");
var Prelude = require("../Prelude");
var Pux = require("../Pux");
var State_Types = require("../State.Types");
var UReview_UserNotifications = require("../UReview.UserNotifications");
var WeReview_Data_Schema = require("../WeReview.Data.Schema");
var Data_Function = require("../Data.Function");
var Data_Functor = require("../Data.Functor");
var Control_Bind = require("../Control.Bind");
var Data_Semigroup = require("../Data.Semigroup");
var Web_Firebase_Aff = require("../Web.Firebase.Aff");
var Control_Applicative = require("../Control.Applicative");
var Data_Unit = require("../Data.Unit");
var Control_Apply = require("../Control.Apply");
var Ports_Firebase_Aff_Reference = require("../Ports.Firebase.Aff.Reference");
var sv2Map = function (xs) {
    var convert = function (v) {
        return new Data_Tuple.Tuple(Data_PathTo.pathTo(WeReview_Data_Schema.pathToWeReviewSchema)(v.value0), v.value1);
    };
    return Data_Function.apply(Data_StrMap.fromFoldable(Data_List.foldableList))(Data_Functor.map(Data_List.functorList)(convert)(xs));
};
var slicesOf = function (v) {
    return function (xs) {
        if (v === 0) {
            return new Data_List.Cons(xs, Data_List.Nil.value);
        };
        var tail = Data_List.drop(v)(xs);
        var head = Data_List.take(v)(xs);
        if (tail instanceof Data_List.Nil) {
            return new Data_List.Cons(head, Data_List.Nil.value);
        };
        return Data_Function.apply(Data_List.Cons.create(head))(slicesOf(v)(tail));
    };
};
var setValues = function (state) {
    return function (dataPoints) {
        var dps = slicesOf(50)(dataPoints);
        var effects = function (v) {
            return function (dp) {
                return Control_Bind.bind(Control_Monad_Aff.bindAff)(Data_Function.apply(Control_Monad_Aff["later'"](150))(Data_Function.apply(Control_Monad_Aff["liftEff'"])(Ports_Firebase_Eff.updateOnSuccessOnError(sv2Map(dp))(function (v1) {
                    return UReview_UserNotifications.sendSuccessMessage(state.channel)(Data_Function.apply(Debug_Trace.spy)("Data updated successfully"));
                })(function (err) {
                    return UReview_UserNotifications.sendErrorMessage(state.channel)(Data_Function.apply(Debug_Trace.spy)("Updating data failed, underlying error was: " + Web_Firebase_Aff.firebaseErrToString(err)));
                })(state.rootRef))))(function (v1) {
                    if (v1 instanceof Data_Either.Left) {
                        return Control_Monad_Error_Class.throwError(Control_Monad_Aff.monadErrorAff)(v1.value0);
                    };
                    if (v1 instanceof Data_Either.Right) {
                        return Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(Data_Unit.unit);
                    };
                    throw new Error("Failed pattern match at Components.DB line 82, column 7 - line 84, column 29: " + [ v1.constructor.name ]);
                });
            };
        };
        var effects$prime = Data_List.foldM(Control_Monad_Aff.monadAff)(effects)(Data_Unit.unit)(dps);
        return Pux.onlyEffects(state)([ Control_Apply.applySecond(Control_Monad_Aff.applyAff)(effects$prime)(Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(State_Types.Noop.value)) ]);
    };
};
var setValueAt = function (state) {
    return function (firebasePath) {
        return function (value) {
            var effects = [ Control_Apply.applySecond(Control_Monad_Aff.applyAff)(Data_Firebase_WriteParentChild.setAtPath(Data_PathTo.pathTo(WeReview_Data_Schema.pathToWeReviewSchema)(firebasePath))(value)(state.rootRef))(Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(State_Types.Noop.value)) ];
            return Pux.onlyEffects(state)(effects);
        };
    };
};
var pushValueAt = function (state) {
    return function (firebasePath) {
        return function (value) {
            var effects = [ Control_Bind.bind(Control_Monad_Aff.bindAff)(Web_Firebase_Aff.child(Data_PathTo.pathTo(WeReview_Data_Schema.pathToWeReviewSchema)(firebasePath))(state.rootRef))(function (v) {
                return Control_Bind.bind(Control_Monad_Aff.bindAff)(Web_Firebase_Aff.push(value)(v))(function () {
                    return Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(State_Types.Noop.value);
                });
            }) ];
            return Pux.onlyEffects(state)(effects);
        };
    };
};
var pushChild2$prime = function (dictPathTo) {
    return function (rootRef) {
        return function (childPath) {
            return function (pathInParent) {
                return function (child) {
                    var ff = Control_Bind.bind(Control_Monad_Aff.bindAff)(Web_Firebase_Aff.child(Data_PathTo.pathTo(dictPathTo)(childPath))(rootRef))(function (v) {
                        return Control_Bind.bind(Control_Monad_Aff.bindAff)(Data_Functor.map(Control_Monad_Aff.functorAff)(Data_ForeignKey.mkForeignKey)(Control_Bind.bind(Control_Monad_Aff.bindAff)(Web_Firebase_Aff.push(child)(v))(Ports_Firebase_Aff_Reference.key)))(function (v1) {
                            return Control_Bind.bind(Control_Monad_Aff.bindAff)(Data_Firebase_WriteParentChild.setAtPath(Data_Function.apply(Data_PathTo.pathTo(WeReview_Data_Schema.pathToWeReviewSchema))(pathInParent(v1)))(Data_Firebase_Foreign.toFire(Data_Firebase_Foreign.toFirebaseForeignBoolean)(true))(rootRef))(function () {
                                return Data_Function.apply(Control_Applicative.pure(Control_Monad_Aff.applicativeAff))(v1);
                            });
                        });
                    });
                    return ff;
                };
            };
        };
    };
};
var pushChildForTest = function (action) {
    return function (rootRef) {
        if (action instanceof Components_DB_Action.PushChild) {
            return pushChild2$prime(WeReview_Data_Schema.pathToWeReviewSchema)(rootRef)(action.value0)(action.value1)(action.value2);
        };
        return Data_Function.apply(Control_Monad_Aff_Exception["throw"])("Not testing PushChild, another action was passed: " + Data_Show.show(Components_DB_Action.showAction)(action));
    };
};
var pushChild2 = function (dictPathTo) {
    return function (state) {
        return function (childPath) {
            return function (pathInParent) {
                return function (child) {
                    var ff = Control_Bind.bind(Control_Monad_Aff.bindAff)(Web_Firebase_Aff.child(Data_PathTo.pathTo(dictPathTo)(childPath))(state.rootRef))(function (v) {
                        return Control_Bind.bind(Control_Monad_Aff.bindAff)(Data_Functor.map(Control_Monad_Aff.functorAff)(Data_ForeignKey.mkForeignKey)(Control_Bind.bind(Control_Monad_Aff.bindAff)(Web_Firebase_Aff.push(child)(v))(Ports_Firebase_Aff_Reference.key)))(function (v1) {
                            return Data_Firebase_WriteParentChild.setAtPath(Data_Function.apply(Data_PathTo.pathTo(WeReview_Data_Schema.pathToWeReviewSchema))(pathInParent(v1)))(Data_Firebase_Foreign.toFire(Data_Firebase_Foreign.toFirebaseForeignBoolean)(true))(state.rootRef);
                        });
                    });
                    var effects = [ Control_Apply.applySecond(Control_Monad_Aff.applyAff)(ff)(Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(State_Types.Noop.value)) ];
                    return Pux.onlyEffects(state)(effects);
                };
            };
        };
    };
};
var update = function (action) {
    return function (state) {
        if (action instanceof Components_DB_Action.SetValue) {
            return setValueAt(state)(action.value0)(action.value1);
        };
        if (action instanceof Components_DB_Action.SetValues) {
            return setValues(state)(action.value0);
        };
        if (action instanceof Components_DB_Action.Push) {
            return pushValueAt(state)(action.value0)(action.value1);
        };
        if (action instanceof Components_DB_Action.PushChild) {
            return pushChild2(WeReview_Data_Schema.pathToWeReviewSchema)(state)(action.value0)(action.value1)(action.value2);
        };
        throw new Error("Failed pattern match at Components.DB line 38, column 23 - line 42, column 93: " + [ action.constructor.name ]);
    };
};
module.exports = {
    pushChild2: pushChild2, 
    pushChildForTest: pushChildForTest, 
    pushValueAt: pushValueAt, 
    setValueAt: setValueAt, 
    setValues: setValues, 
    update: update
};
