// Generated by psc version 0.9.1
"use strict";
var $foreign = require("./foreign");
var Prelude = require("../Prelude");
var Control_Monad_Eff = require("../Control.Monad.Eff");
var Data_Int = require("../Data.Int");
var Control_Bind = require("../Control.Bind");
var Control_Applicative = require("../Control.Applicative");
var Data_Semiring = require("../Data.Semiring");
var Data_Ring = require("../Data.Ring");
var Data_Function = require("../Data.Function");
var Data_Functor = require("../Data.Functor");
var Data_Ord = require("../Data.Ord");
var randomRange = function (min) {
    return function (max) {
        return function __do() {
            var v = $foreign.random();
            return v * (max - min) + min;
        };
    };
};
var randomInt = function (low) {
    return function (high) {
        return function __do() {
            var v = $foreign.random();
            var asNumber = ((Data_Int.toNumber(high) - Data_Int.toNumber(low)) + 1) * v + Data_Int.toNumber(low);
            return Data_Function.apply(Control_Applicative.pure(Control_Monad_Eff.applicativeEff))(Data_Int.floor(asNumber))();
        };
    };
};
var randomBool = Data_Functor.map(Control_Monad_Eff.functorEff)(function (v) {
    return v < 0.5;
})($foreign.random);
module.exports = {
    randomBool: randomBool, 
    randomInt: randomInt, 
    randomRange: randomRange, 
    random: $foreign.random
};
