// Generated by psc version 0.9.1
"use strict";
var Data_Map = require("../Data.Map");
var Data_Set = require("../Data.Set");
var Control_Monad_Aff = require("../Control.Monad.Aff");
var Control_Monad_Eff = require("../Control.Monad.Eff");
var Data_Maybe = require("../Data.Maybe");
var Data_Traversable = require("../Data.Traversable");
var Debug_Trace = require("../Debug.Trace");
var Prelude = require("../Prelude");
var Ports_Firebase_Eff = require("../Ports.Firebase.Eff");
var Ports_Firebase_Aff = require("../Ports.Firebase.Aff");
var Ports_Firebase_Types = require("../Ports.Firebase.Types");
var Control_Bind = require("../Control.Bind");
var Web_Firebase = require("../Web.Firebase");
var Data_List = require("../Data.List");
var Control_Applicative = require("../Control.Applicative");
var Data_Unit = require("../Data.Unit");
var Data_Ord = require("../Data.Ord");
var Data_Function = require("../Data.Function");
var Web_Firebase_Aff = require("../Web.Firebase.Aff");
var Control_Category = require("../Control.Category");
var unsubscribeAll = function (root) {
    return function (subscriptions) {
        var unsubscribeEff = function (key) {
            return Control_Bind.bind(Control_Monad_Eff.bindEff)(Web_Firebase.child(key)(root))(Web_Firebase.offSimple);
        };
        return function __do() {
            Data_Traversable.traverse(Data_List.traversableList)(Control_Monad_Eff.applicativeEff)(unsubscribeEff)(Data_Map.keys(subscriptions))();
            return Data_Unit.unit;
        };
    };
};
var subscriptionsAt = function (path) {
    return function (s) {
        var $1 = Data_Map.lookup(Data_Ord.ordString)(path)(s);
        if ($1 instanceof Data_Maybe.Nothing) {
            return Data_Set.empty;
        };
        if ($1 instanceof Data_Maybe.Just) {
            return $1.value0;
        };
        throw new Error("Failed pattern match at Data.Firebase.Subscription line 64, column 26 - line 66, column 32: " + [ $1.constructor.name ]);
    };
};
var subscribed = function (path) {
    return function (event) {
        return function (subscriptions) {
            return Data_Map.alter(Data_Ord.ordString)(Data_Maybe.maybe(new Data_Maybe.Just(Data_Set.singleton(event)))(function (s) {
                return Data_Function.apply(Data_Maybe.Just.create)(Data_Set.insert(Web_Firebase.ordEventType)(event)(s));
            }))(path)(subscriptions);
        };
    };
};
var onceAll = function (root) {
    return function (subscriptions) {
        var readNow = function (key) {
            var unused = Debug_Trace.spy(key);
            return Control_Bind.bind(Control_Monad_Aff.bindAff)(Web_Firebase_Aff.child(key)(root))(Web_Firebase_Aff.onceValue);
        };
        return Control_Bind.bind(Control_Monad_Aff.bindAff)(Data_Traversable.traverse(Data_List.traversableList)(Control_Monad_Aff.applicativeAff)(readNow)(Data_Map.keys(subscriptions)))(function () {
            return Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(Data_Unit.unit);
        });
    };
};
var mkFirebasePath = function (dictCategory) {
    return Control_Category.id(dictCategory);
};
var isSubscribed = function (path) {
    return function (ev) {
        return function (s) {
            var $3 = Data_Map.lookup(Data_Ord.ordString)(path)(s);
            if ($3 instanceof Data_Maybe.Nothing) {
                return false;
            };
            if ($3 instanceof Data_Maybe.Just) {
                return Data_Set.member(Web_Firebase.ordEventType)(ev)($3.value0);
            };
            throw new Error("Failed pattern match at Data.Firebase.Subscription line 70, column 26 - line 72, column 46: " + [ $3.constructor.name ]);
        };
    };
};
var hasSubscriptions = function (path) {
    return function (s) {
        return Data_Map.member(Data_Ord.ordString)(path)(s);
    };
};
var emptySubscription = Data_Map.empty;
module.exports = {
    emptySubscription: emptySubscription, 
    hasSubscriptions: hasSubscriptions, 
    isSubscribed: isSubscribed, 
    mkFirebasePath: mkFirebasePath, 
    onceAll: onceAll, 
    subscribed: subscribed, 
    subscriptionsAt: subscriptionsAt, 
    unsubscribeAll: unsubscribeAll
};
