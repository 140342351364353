// Generated by psc version 0.9.1
"use strict";
var Components_Elements_Types = require("../Components.Elements.Types");
var Components_DomainAttributes = require("../Components.DomainAttributes");
var CssClasses = require("../CssClasses");
var Data_Maybe = require("../Data.Maybe");
var Prelude = require("../Prelude");
var Pux_Html = require("../Pux.Html");
var Pux_Html_Attributes = require("../Pux.Html.Attributes");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var Pux_Html_Events = require("../Pux.Html.Events");
var Components_Elements_Basic_Types = require("../Components.Elements.Basic.Types");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var Data_Function = require("../Data.Function");
var Data_Functor = require("../Data.Functor");
var Data_Semigroup = require("../Data.Semigroup");
var ptext = function (t) {
    return Pux_Html_Elements.p([  ])([ Pux_Html_Elements.text(t) ]);
};
var htmlIdFor = function (hid) {
    return Pux_Html_Attributes.htmlFor(Components_Elements_Basic_Types.fromHtmlId(hid));
};
var htmlId = function ($25) {
    return Pux_Html_Attributes.id_(Components_Elements_Basic_Types.fromHtmlId($25));
};
var renderSmallButton = function (builder) {
    return Pux_Html_Elements.button([ htmlId(builder.htmlId), Pux_Html_Attributes.className("small"), Pux_Html_Events.onClick(Data_Function["const"](builder.action)) ])([ Pux_Html_Elements.text(builder.label) ]);
};
var renderListWithSmallButtons = function (l) {
    return Data_Function.apply(Pux_Html_Elements.ul([ CssClasses.noBullet ]))(Data_Functor.map(Data_Functor.functorArray)(function (v) {
        return Data_Function.apply(Pux_Html_Elements.li([  ]))([ Pux_Html_Elements.text(v.label), renderSmallButton(v.button) ]);
    })(l.items));
};
var createSmallButton = function (action) {
    return function (v) {
        return function (buttonText) {
            return Pux_Html_Elements.button([ Pux_Html_Attributes.id_(v), Pux_Html_Attributes.className("small"), Pux_Html_Events.onClick(Data_Function["const"](action)) ])([ Pux_Html_Elements.text(buttonText) ]);
        };
    };
};
var createButton = function (action) {
    return function (v) {
        return function (buttonText) {
            return Pux_Html_Elements.button([ Pux_Html_Attributes.id_(v), Pux_Html_Attributes.className(""), Pux_Html_Events.onClick(Data_Function["const"](action)) ])([ Pux_Html_Elements.text(buttonText) ]);
        };
    };
};
var column = Pux_Html_Elements.div([ Pux_Html_Attributes.className("column") ]);
var buildSelect = function (attributes) {
    return function (builder) {
        var selected = (function () {
            if (builder.selected instanceof Data_Maybe.Just) {
                return [ Pux_Html_Attributes.value(builder.renderKey(builder.selected.value0)) ];
            };
            return [  ];
        })();
        var renderOption = function (choice) {
            return Pux_Html_Elements.option([ Pux_Html_Attributes.value(builder.renderKey(choice)) ])([ builder.renderValue(choice) ]);
        };
        var attributesForSelect = Data_Semigroup.append(Data_Semigroup.semigroupArray)(selected)([ htmlId(builder.htmlId), Pux_Html_Attributes.multiple(false), Pux_Html_Events.onChange(function ($26) {
            return builder.action(builder.stringToActionKey(Components_DomainAttributes.eventValue($26)));
        }) ]);
        return Pux_Html_Elements.div(attributes)([ Pux_Html_Elements.label([ htmlIdFor(builder.htmlId) ])([ Pux_Html_Elements.text(builder.label) ]), Pux_Html_Elements.select(attributesForSelect)(Data_Functor.map(Data_Functor.functorArray)(renderOption)(builder.items)) ]);
    };
};
var buildMultiSelect = function (attributes) {
    return function (builder) {
        var renderOption = function (choice) {
            return Pux_Html_Elements.option([ Pux_Html_Attributes.value(builder.renderKey(choice)) ])([ builder.renderValue(choice) ]);
        };
        return Pux_Html_Elements.div(attributes)([ Pux_Html_Elements.label([ htmlIdFor(builder.htmlId) ])([ Pux_Html_Elements.text(builder.label) ]), Pux_Html_Elements.select([ htmlId(builder.htmlId), Pux_Html_Attributes.multiple(true), Pux_Html_Attributes.size(builder.length), Pux_Html_Events.onChange(function ($27) {
            return builder.action(builder.stringToActionKey(Components_DomainAttributes.eventValue($27)));
        }) ])(Data_Functor.map(Data_Functor.functorArray)(renderOption)(builder.items)) ]);
    };
};
var buildListWithButtons = function (mkLabel) {
    return function (mkId) {
        return function (mkKey) {
            return function (v) {
                return function (action) {
                    return function (domainObjects) {
                        var domainObject2Item = function (up) {
                            return {
                                label: mkLabel(up), 
                                button: {
                                    htmlId: Data_Semigroup.append(Components_Elements_Basic_Types.semigroupHTMLId)(Components_Elements_Basic_Types.mkHtmlId(v.htmlIdFragment))(mkId(up)), 
                                    label: v.labelFragment, 
                                    action: action(mkKey(up))
                                }
                            };
                        };
                        return {
                            items: Data_Functor.map(Data_Functor.functorArray)(domainObject2Item)(domainObjects)
                        };
                    };
                };
            };
        };
    };
};
var buildConfirmer = function (attributes) {
    return function (build) {
        return function (value) {
            return Pux_Html_Elements.div(attributes)([ build.confirmationMessage(value), createButton(build.action)(build.htmlId)(build.label(value)) ]);
        };
    };
};
var buildMultiSelectConfirmer = function (attributes) {
    return function (chooser) {
        var showConfirmer = function (chosen) {
            return Pux_Html_Elements.div(attributes)([ buildMultiSelect(attributes)(chooser.listBuilder), buildConfirmer([  ])(chooser.confirmBuilder)(chosen) ]);
        };
        if (chooser.listBuilder.selected instanceof Data_Maybe.Nothing) {
            return Pux_Html_Elements.div(attributes)([ buildMultiSelect(attributes)(chooser.listBuilder) ]);
        };
        if (chooser.listBuilder.selected instanceof Data_Maybe.Just) {
            return showConfirmer(chooser.listBuilder.selected.value0);
        };
        throw new Error("Failed pattern match at Components.Elements line 119, column 3 - line 121, column 42: " + [ chooser.listBuilder.selected.constructor.name ]);
    };
};
module.exports = {
    buildConfirmer: buildConfirmer, 
    buildListWithButtons: buildListWithButtons, 
    buildMultiSelect: buildMultiSelect, 
    buildMultiSelectConfirmer: buildMultiSelectConfirmer, 
    buildSelect: buildSelect, 
    column: column, 
    createButton: createButton, 
    createSmallButton: createSmallButton, 
    ptext: ptext, 
    renderListWithSmallButtons: renderListWithSmallButtons, 
    renderSmallButton: renderSmallButton
};
