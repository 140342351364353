// Generated by psc version 0.9.1
"use strict";
var Ports_Firebase_Aff = require("../Ports.Firebase.Aff");
var Control_Monad_Aff = require("../Control.Monad.Aff");
var Data_Firebase_Foreign = require("../Data.Firebase.Foreign");
var Data_Foreign = require("../Data.Foreign");
var Data_ForeignKey = require("../Data.ForeignKey");
var Prelude = require("../Prelude");
var WeReview_Data_Schema = require("../WeReview.Data.Schema");
var Ports_Firebase_Types = require("../Ports.Firebase.Types");
var Control_Bind = require("../Control.Bind");
var Web_Firebase_Aff = require("../Web.Firebase.Aff");
var Data_PathTo = require("../Data.PathTo");
var Ports_Firebase_Aff_Reference = require("../Ports.Firebase.Aff.Reference");
var Control_Applicative = require("../Control.Applicative");
var setAtPath = function (path) {
    return function (value) {
        return function (rootRef) {
            return Control_Bind.bind(Control_Monad_Aff.bindAff)(Web_Firebase_Aff.child(path)(rootRef))(function (v) {
                return Web_Firebase_Aff.set(value)(v);
            });
        };
    };
};
var set = function (dictPathTo) {
    return function (dictToFirebaseForeign) {
        return function (path) {
            return function (value) {
                return function (rootRef) {
                    return setAtPath(Data_PathTo.pathTo(dictPathTo)(path))(Data_Firebase_Foreign.toFire(dictToFirebaseForeign)(value))(rootRef);
                };
            };
        };
    };
};
var addKey = function (k) {
    return function (ref) {
        return Control_Bind.bind(Control_Monad_Aff.bindAff)(Web_Firebase_Aff.child(Data_ForeignKey.fkToString(k))(ref))(function (v) {
            return Web_Firebase_Aff.set(Data_Foreign.toForeign(true))(v);
        });
    };
};
var pushChild = function (dictToFirebaseForeign) {
    return function (child) {
        return function (childrenRef) {
            return function (foreignKeysRef) {
                return Control_Bind.bind(Control_Monad_Aff.bindAff)(Control_Bind.bind(Control_Monad_Aff.bindAff)(Web_Firebase_Aff.push(Data_Firebase_Foreign.toFire(dictToFirebaseForeign)(child))(childrenRef))(Ports_Firebase_Aff_Reference.key))(function (v) {
                    var childKey = Data_ForeignKey.mkForeignKey(v);
                    return Control_Bind.bind(Control_Monad_Aff.bindAff)(addKey(childKey)(foreignKeysRef))(function () {
                        return Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(childKey);
                    });
                });
            };
        };
    };
};
module.exports = {
    addKey: addKey, 
    pushChild: pushChild, 
    set: set, 
    setAtPath: setAtPath
};
