// Generated by psc version 0.9.1
"use strict";
var Prelude = require("../Prelude");
var Data_Generic = require("../Data.Generic");
var Data_String = require("../Data.String");
var CSS_String = require("../CSS.String");
var Data_Eq = require("../Data.Eq");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra");
var Data_Ord = require("../Data.Ord");
var Data_Ordering = require("../Data.Ordering");
var Control_Apply = require("../Control.Apply");
var Data_Maybe = require("../Data.Maybe");
var Data_Unit = require("../Data.Unit");
var Data_Function = require("../Data.Function");
var Data_Semigroup = require("../Data.Semigroup");
var Id = (function () {
    function Id(value0) {
        this.value0 = value0;
    };
    Id.create = function (value0) {
        return new Id(value0);
    };
    return Id;
})();
var Class = (function () {
    function Class(value0) {
        this.value0 = value0;
    };
    Class.create = function (value0) {
        return new Class(value0);
    };
    return Class;
})();
var Attr = (function () {
    function Attr(value0) {
        this.value0 = value0;
    };
    Attr.create = function (value0) {
        return new Attr(value0);
    };
    return Attr;
})();
var AttrVal = (function () {
    function AttrVal(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AttrVal.create = function (value0) {
        return function (value1) {
            return new AttrVal(value0, value1);
        };
    };
    return AttrVal;
})();
var AttrBegins = (function () {
    function AttrBegins(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AttrBegins.create = function (value0) {
        return function (value1) {
            return new AttrBegins(value0, value1);
        };
    };
    return AttrBegins;
})();
var AttrEnds = (function () {
    function AttrEnds(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AttrEnds.create = function (value0) {
        return function (value1) {
            return new AttrEnds(value0, value1);
        };
    };
    return AttrEnds;
})();
var AttrContains = (function () {
    function AttrContains(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AttrContains.create = function (value0) {
        return function (value1) {
            return new AttrContains(value0, value1);
        };
    };
    return AttrContains;
})();
var AttrSpace = (function () {
    function AttrSpace(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AttrSpace.create = function (value0) {
        return function (value1) {
            return new AttrSpace(value0, value1);
        };
    };
    return AttrSpace;
})();
var AttrHyph = (function () {
    function AttrHyph(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AttrHyph.create = function (value0) {
        return function (value1) {
            return new AttrHyph(value0, value1);
        };
    };
    return AttrHyph;
})();
var Pseudo = (function () {
    function Pseudo(value0) {
        this.value0 = value0;
    };
    Pseudo.create = function (value0) {
        return new Pseudo(value0);
    };
    return Pseudo;
})();
var PseudoFunc = (function () {
    function PseudoFunc(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    PseudoFunc.create = function (value0) {
        return function (value1) {
            return new PseudoFunc(value0, value1);
        };
    };
    return PseudoFunc;
})();
var Refinement = function (x) {
    return x;
};
var Star = (function () {
    function Star() {

    };
    Star.value = new Star();
    return Star;
})();
var Elem = (function () {
    function Elem(value0) {
        this.value0 = value0;
    };
    Elem.create = function (value0) {
        return new Elem(value0);
    };
    return Elem;
})();
var PathChild = (function () {
    function PathChild(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    PathChild.create = function (value0) {
        return function (value1) {
            return new PathChild(value0, value1);
        };
    };
    return PathChild;
})();
var Deep = (function () {
    function Deep(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Deep.create = function (value0) {
        return function (value1) {
            return new Deep(value0, value1);
        };
    };
    return Deep;
})();
var Adjacent = (function () {
    function Adjacent(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Adjacent.create = function (value0) {
        return function (value1) {
            return new Adjacent(value0, value1);
        };
    };
    return Adjacent;
})();
var Combined = (function () {
    function Combined(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Combined.create = function (value0) {
        return function (value1) {
            return new Combined(value0, value1);
        };
    };
    return Combined;
})();
var Selector = (function () {
    function Selector(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Selector.create = function (value0) {
        return function (value1) {
            return new Selector(value0, value1);
        };
    };
    return Selector;
})();
var $$with = function (v) {
    return function (v1) {
        return new Selector(Data_Semigroup.append(Data_Semigroup.semigroupArray)(v.value0)(v1), v.value1);
    };
};
var star = new Selector([  ], Star.value);
var isStringSelector = new CSS_String.IsString(function (s) {
    var $213 = Data_String.take(1)(s);
    if ($213 === "#") {
        return new Selector([ Data_Function.apply(Id.create)(Data_String.drop(1)(s)) ], Star.value);
    };
    if ($213 === ".") {
        return new Selector([ Data_Function.apply(Class.create)(Data_String.drop(1)(s)) ], Star.value);
    };
    return new Selector([  ], new Elem(s));
});
var isStringRefinement = new CSS_String.IsString(function (s) {
    return [ (function () {
        var $214 = Data_String.take(1)(s);
        if ($214 === "#") {
            return Data_Function.apply(Id.create)(Data_String.drop(1)(s));
        };
        if ($214 === ".") {
            return Data_Function.apply(Class.create)(Data_String.drop(1)(s));
        };
        if ($214 === ":") {
            return Data_Function.apply(Pseudo.create)(Data_String.drop(1)(s));
        };
        if ($214 === "@") {
            return Data_Function.apply(Attr.create)(Data_String.drop(1)(s));
        };
        return new Attr(s);
    })() ];
});
var genericPredicate = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "CSS.Selector.Id" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Id.create))(Data_Generic.fromSpine(Data_Generic.genericString)(v.value1[0](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "CSS.Selector.Class" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Class.create))(Data_Generic.fromSpine(Data_Generic.genericString)(v.value1[0](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "CSS.Selector.Attr" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Attr.create))(Data_Generic.fromSpine(Data_Generic.genericString)(v.value1[0](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "CSS.Selector.AttrVal" && v.value1.length === 2)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(AttrVal.create))(Data_Generic.fromSpine(Data_Generic.genericString)(v.value1[0](Data_Unit.unit))))(Data_Generic.fromSpine(Data_Generic.genericString)(v.value1[1](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "CSS.Selector.AttrBegins" && v.value1.length === 2)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(AttrBegins.create))(Data_Generic.fromSpine(Data_Generic.genericString)(v.value1[0](Data_Unit.unit))))(Data_Generic.fromSpine(Data_Generic.genericString)(v.value1[1](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "CSS.Selector.AttrEnds" && v.value1.length === 2)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(AttrEnds.create))(Data_Generic.fromSpine(Data_Generic.genericString)(v.value1[0](Data_Unit.unit))))(Data_Generic.fromSpine(Data_Generic.genericString)(v.value1[1](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "CSS.Selector.AttrContains" && v.value1.length === 2)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(AttrContains.create))(Data_Generic.fromSpine(Data_Generic.genericString)(v.value1[0](Data_Unit.unit))))(Data_Generic.fromSpine(Data_Generic.genericString)(v.value1[1](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "CSS.Selector.AttrSpace" && v.value1.length === 2)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(AttrSpace.create))(Data_Generic.fromSpine(Data_Generic.genericString)(v.value1[0](Data_Unit.unit))))(Data_Generic.fromSpine(Data_Generic.genericString)(v.value1[1](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "CSS.Selector.AttrHyph" && v.value1.length === 2)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(AttrHyph.create))(Data_Generic.fromSpine(Data_Generic.genericString)(v.value1[0](Data_Unit.unit))))(Data_Generic.fromSpine(Data_Generic.genericString)(v.value1[1](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "CSS.Selector.Pseudo" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Pseudo.create))(Data_Generic.fromSpine(Data_Generic.genericString)(v.value1[0](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "CSS.Selector.PseudoFunc" && v.value1.length === 2)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(PseudoFunc.create))(Data_Generic.fromSpine(Data_Generic.genericString)(v.value1[0](Data_Unit.unit))))(Data_Generic.fromSpine(Data_Generic.genericArray(Data_Generic.genericString))(v.value1[1](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("CSS.Selector.Predicate", [ {
        sigConstructor: "CSS.Selector.Id", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "CSS.Selector.Class", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "CSS.Selector.Attr", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "CSS.Selector.AttrVal", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
        }, function ($dollarq1) {
            return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "CSS.Selector.AttrBegins", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
        }, function ($dollarq1) {
            return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "CSS.Selector.AttrEnds", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
        }, function ($dollarq1) {
            return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "CSS.Selector.AttrContains", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
        }, function ($dollarq1) {
            return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "CSS.Selector.AttrSpace", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
        }, function ($dollarq1) {
            return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "CSS.Selector.AttrHyph", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
        }, function ($dollarq1) {
            return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "CSS.Selector.Pseudo", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "CSS.Selector.PseudoFunc", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
        }, function ($dollarq1) {
            return Data_Generic.toSignature(Data_Generic.genericArray(Data_Generic.genericString))(Data_Generic.anyProxy);
        } ]
    } ]);
}, function (v) {
    if (v instanceof Id) {
        return new Data_Generic.SProd("CSS.Selector.Id", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_Generic.genericString)(v.value0);
        } ]);
    };
    if (v instanceof Class) {
        return new Data_Generic.SProd("CSS.Selector.Class", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_Generic.genericString)(v.value0);
        } ]);
    };
    if (v instanceof Attr) {
        return new Data_Generic.SProd("CSS.Selector.Attr", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_Generic.genericString)(v.value0);
        } ]);
    };
    if (v instanceof AttrVal) {
        return new Data_Generic.SProd("CSS.Selector.AttrVal", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_Generic.genericString)(v.value0);
        }, function ($dollarq) {
            return Data_Generic.toSpine(Data_Generic.genericString)(v.value1);
        } ]);
    };
    if (v instanceof AttrBegins) {
        return new Data_Generic.SProd("CSS.Selector.AttrBegins", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_Generic.genericString)(v.value0);
        }, function ($dollarq) {
            return Data_Generic.toSpine(Data_Generic.genericString)(v.value1);
        } ]);
    };
    if (v instanceof AttrEnds) {
        return new Data_Generic.SProd("CSS.Selector.AttrEnds", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_Generic.genericString)(v.value0);
        }, function ($dollarq) {
            return Data_Generic.toSpine(Data_Generic.genericString)(v.value1);
        } ]);
    };
    if (v instanceof AttrContains) {
        return new Data_Generic.SProd("CSS.Selector.AttrContains", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_Generic.genericString)(v.value0);
        }, function ($dollarq) {
            return Data_Generic.toSpine(Data_Generic.genericString)(v.value1);
        } ]);
    };
    if (v instanceof AttrSpace) {
        return new Data_Generic.SProd("CSS.Selector.AttrSpace", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_Generic.genericString)(v.value0);
        }, function ($dollarq) {
            return Data_Generic.toSpine(Data_Generic.genericString)(v.value1);
        } ]);
    };
    if (v instanceof AttrHyph) {
        return new Data_Generic.SProd("CSS.Selector.AttrHyph", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_Generic.genericString)(v.value0);
        }, function ($dollarq) {
            return Data_Generic.toSpine(Data_Generic.genericString)(v.value1);
        } ]);
    };
    if (v instanceof Pseudo) {
        return new Data_Generic.SProd("CSS.Selector.Pseudo", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_Generic.genericString)(v.value0);
        } ]);
    };
    if (v instanceof PseudoFunc) {
        return new Data_Generic.SProd("CSS.Selector.PseudoFunc", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_Generic.genericString)(v.value0);
        }, function ($dollarq) {
            return Data_Generic.toSpine(Data_Generic.genericArray(Data_Generic.genericString))(v.value1);
        } ]);
    };
    throw new Error("Failed pattern match at CSS.Selector line 25, column 1 - line 25, column 54: " + [ v.constructor.name ]);
});
var genericRefinement = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "CSS.Selector.Refinement" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Refinement))(Data_Generic.fromSpine(Data_Generic.genericArray(genericPredicate))(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("CSS.Selector.Refinement", [ {
        sigConstructor: "CSS.Selector.Refinement", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_Generic.genericArray(genericPredicate))(Data_Generic.anyProxy);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("CSS.Selector.Refinement", [ function ($dollarq) {
        return Data_Generic.toSpine(Data_Generic.genericArray(genericPredicate))(v);
    } ]);
});
var genericPath = function (dictGeneric) {
    return new Data_Generic.Generic(function (v) {
        if (v instanceof Data_Generic.SProd && (v.value0 === "CSS.Selector.Star" && v.value1.length === 0)) {
            return new Data_Maybe.Just(Star.value);
        };
        if (v instanceof Data_Generic.SProd && (v.value0 === "CSS.Selector.Elem" && v.value1.length === 1)) {
            return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Elem.create))(Data_Generic.fromSpine(Data_Generic.genericString)(v.value1[0](Data_Unit.unit)));
        };
        if (v instanceof Data_Generic.SProd && (v.value0 === "CSS.Selector.PathChild" && v.value1.length === 2)) {
            return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(PathChild.create))(Data_Generic.fromSpine(dictGeneric)(v.value1[0](Data_Unit.unit))))(Data_Generic.fromSpine(dictGeneric)(v.value1[1](Data_Unit.unit)));
        };
        if (v instanceof Data_Generic.SProd && (v.value0 === "CSS.Selector.Deep" && v.value1.length === 2)) {
            return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Deep.create))(Data_Generic.fromSpine(dictGeneric)(v.value1[0](Data_Unit.unit))))(Data_Generic.fromSpine(dictGeneric)(v.value1[1](Data_Unit.unit)));
        };
        if (v instanceof Data_Generic.SProd && (v.value0 === "CSS.Selector.Adjacent" && v.value1.length === 2)) {
            return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Adjacent.create))(Data_Generic.fromSpine(dictGeneric)(v.value1[0](Data_Unit.unit))))(Data_Generic.fromSpine(dictGeneric)(v.value1[1](Data_Unit.unit)));
        };
        if (v instanceof Data_Generic.SProd && (v.value0 === "CSS.Selector.Combined" && v.value1.length === 2)) {
            return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Combined.create))(Data_Generic.fromSpine(dictGeneric)(v.value1[0](Data_Unit.unit))))(Data_Generic.fromSpine(dictGeneric)(v.value1[1](Data_Unit.unit)));
        };
        return Data_Maybe.Nothing.value;
    }, function ($dollarq) {
        return new Data_Generic.SigProd("CSS.Selector.Path", [ {
            sigConstructor: "CSS.Selector.Star", 
            sigValues: [  ]
        }, {
            sigConstructor: "CSS.Selector.Elem", 
            sigValues: [ function ($dollarq1) {
                return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
            } ]
        }, {
            sigConstructor: "CSS.Selector.PathChild", 
            sigValues: [ function ($dollarq1) {
                return Data_Generic.toSignature(dictGeneric)(Data_Generic.anyProxy);
            }, function ($dollarq1) {
                return Data_Generic.toSignature(dictGeneric)(Data_Generic.anyProxy);
            } ]
        }, {
            sigConstructor: "CSS.Selector.Deep", 
            sigValues: [ function ($dollarq1) {
                return Data_Generic.toSignature(dictGeneric)(Data_Generic.anyProxy);
            }, function ($dollarq1) {
                return Data_Generic.toSignature(dictGeneric)(Data_Generic.anyProxy);
            } ]
        }, {
            sigConstructor: "CSS.Selector.Adjacent", 
            sigValues: [ function ($dollarq1) {
                return Data_Generic.toSignature(dictGeneric)(Data_Generic.anyProxy);
            }, function ($dollarq1) {
                return Data_Generic.toSignature(dictGeneric)(Data_Generic.anyProxy);
            } ]
        }, {
            sigConstructor: "CSS.Selector.Combined", 
            sigValues: [ function ($dollarq1) {
                return Data_Generic.toSignature(dictGeneric)(Data_Generic.anyProxy);
            }, function ($dollarq1) {
                return Data_Generic.toSignature(dictGeneric)(Data_Generic.anyProxy);
            } ]
        } ]);
    }, function (v) {
        if (v instanceof Star) {
            return new Data_Generic.SProd("CSS.Selector.Star", [  ]);
        };
        if (v instanceof Elem) {
            return new Data_Generic.SProd("CSS.Selector.Elem", [ function ($dollarq) {
                return Data_Generic.toSpine(Data_Generic.genericString)(v.value0);
            } ]);
        };
        if (v instanceof PathChild) {
            return new Data_Generic.SProd("CSS.Selector.PathChild", [ function ($dollarq) {
                return Data_Generic.toSpine(dictGeneric)(v.value0);
            }, function ($dollarq) {
                return Data_Generic.toSpine(dictGeneric)(v.value1);
            } ]);
        };
        if (v instanceof Deep) {
            return new Data_Generic.SProd("CSS.Selector.Deep", [ function ($dollarq) {
                return Data_Generic.toSpine(dictGeneric)(v.value0);
            }, function ($dollarq) {
                return Data_Generic.toSpine(dictGeneric)(v.value1);
            } ]);
        };
        if (v instanceof Adjacent) {
            return new Data_Generic.SProd("CSS.Selector.Adjacent", [ function ($dollarq) {
                return Data_Generic.toSpine(dictGeneric)(v.value0);
            }, function ($dollarq) {
                return Data_Generic.toSpine(dictGeneric)(v.value1);
            } ]);
        };
        if (v instanceof Combined) {
            return new Data_Generic.SProd("CSS.Selector.Combined", [ function ($dollarq) {
                return Data_Generic.toSpine(dictGeneric)(v.value0);
            }, function ($dollarq) {
                return Data_Generic.toSpine(dictGeneric)(v.value1);
            } ]);
        };
        throw new Error("Failed pattern match at CSS.Selector line 54, column 1 - line 54, column 63: " + [ v.constructor.name ]);
    });
};
var genericSelector = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "CSS.Selector.Selector" && v.value1.length === 2)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Selector.create))(Data_Generic.fromSpine(genericRefinement)(v.value1[0](Data_Unit.unit))))(Data_Generic.fromSpine(genericPath(genericSelector))(v.value1[1](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("CSS.Selector.Selector", [ {
        sigConstructor: "CSS.Selector.Selector", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(genericRefinement)(Data_Generic.anyProxy);
        }, function ($dollarq1) {
            return Data_Generic.toSignature(genericPath(genericSelector))(Data_Generic.anyProxy);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("CSS.Selector.Selector", [ function ($dollarq) {
        return Data_Generic.toSpine(genericRefinement)(v.value0);
    }, function ($dollarq) {
        return Data_Generic.toSpine(genericPath(genericSelector))(v.value1);
    } ]);
});
var eqPredicate = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Id && y instanceof Id) {
            return x.value0 === y.value0;
        };
        if (x instanceof Class && y instanceof Class) {
            return x.value0 === y.value0;
        };
        if (x instanceof Attr && y instanceof Attr) {
            return x.value0 === y.value0;
        };
        if (x instanceof AttrVal && y instanceof AttrVal) {
            return x.value0 === y.value0 && x.value1 === y.value1;
        };
        if (x instanceof AttrBegins && y instanceof AttrBegins) {
            return x.value0 === y.value0 && x.value1 === y.value1;
        };
        if (x instanceof AttrEnds && y instanceof AttrEnds) {
            return x.value0 === y.value0 && x.value1 === y.value1;
        };
        if (x instanceof AttrContains && y instanceof AttrContains) {
            return x.value0 === y.value0 && x.value1 === y.value1;
        };
        if (x instanceof AttrSpace && y instanceof AttrSpace) {
            return x.value0 === y.value0 && x.value1 === y.value1;
        };
        if (x instanceof AttrHyph && y instanceof AttrHyph) {
            return x.value0 === y.value0 && x.value1 === y.value1;
        };
        if (x instanceof Pseudo && y instanceof Pseudo) {
            return x.value0 === y.value0;
        };
        if (x instanceof PseudoFunc && y instanceof PseudoFunc) {
            return x.value0 === y.value0 && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value1)(y.value1);
        };
        return false;
    };
});
var eqRefinement = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(Data_Eq.eqArray(eqPredicate))(x)(y);
    };
});
var ordPredicate = new Data_Ord.Ord(function () {
    return eqPredicate;
}, function (x) {
    return function (y) {
        if (x instanceof Id && y instanceof Id) {
            return Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
        };
        if (x instanceof Id) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Id) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Class && y instanceof Class) {
            return Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
        };
        if (x instanceof Class) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Class) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Attr && y instanceof Attr) {
            return Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
        };
        if (x instanceof Attr) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Attr) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof AttrVal && y instanceof AttrVal) {
            var $374 = Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
            if ($374 instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if ($374 instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return Data_Ord.compare(Data_Ord.ordString)(x.value1)(y.value1);
        };
        if (x instanceof AttrVal) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof AttrVal) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof AttrBegins && y instanceof AttrBegins) {
            var $383 = Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
            if ($383 instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if ($383 instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return Data_Ord.compare(Data_Ord.ordString)(x.value1)(y.value1);
        };
        if (x instanceof AttrBegins) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof AttrBegins) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof AttrEnds && y instanceof AttrEnds) {
            var $392 = Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
            if ($392 instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if ($392 instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return Data_Ord.compare(Data_Ord.ordString)(x.value1)(y.value1);
        };
        if (x instanceof AttrEnds) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof AttrEnds) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof AttrContains && y instanceof AttrContains) {
            var $401 = Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
            if ($401 instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if ($401 instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return Data_Ord.compare(Data_Ord.ordString)(x.value1)(y.value1);
        };
        if (x instanceof AttrContains) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof AttrContains) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof AttrSpace && y instanceof AttrSpace) {
            var $410 = Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
            if ($410 instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if ($410 instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return Data_Ord.compare(Data_Ord.ordString)(x.value1)(y.value1);
        };
        if (x instanceof AttrSpace) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof AttrSpace) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof AttrHyph && y instanceof AttrHyph) {
            var $419 = Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
            if ($419 instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if ($419 instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return Data_Ord.compare(Data_Ord.ordString)(x.value1)(y.value1);
        };
        if (x instanceof AttrHyph) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof AttrHyph) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Pseudo && y instanceof Pseudo) {
            return Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
        };
        if (x instanceof Pseudo) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Pseudo) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof PseudoFunc && y instanceof PseudoFunc) {
            var $432 = Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
            if ($432 instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if ($432 instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return Data_Ord.compare(Data_Ord.ordArray(Data_Ord.ordString))(x.value1)(y.value1);
        };
        throw new Error("Failed pattern match at CSS.Selector line 24, column 1 - line 24, column 46: " + [ x.constructor.name, y.constructor.name ]);
    };
});
var ordRefinement = new Data_Ord.Ord(function () {
    return eqRefinement;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(Data_Ord.ordArray(ordPredicate))(x)(y);
    };
});
var eqPath = function (dictEq) {
    return new Data_Eq.Eq(function (x) {
        return function (y) {
            if (x instanceof Star && y instanceof Star) {
                return true;
            };
            if (x instanceof Elem && y instanceof Elem) {
                return x.value0 === y.value0;
            };
            if (x instanceof PathChild && y instanceof PathChild) {
                return Data_Eq.eq(dictEq)(x.value0)(y.value0) && Data_Eq.eq(dictEq)(x.value1)(y.value1);
            };
            if (x instanceof Deep && y instanceof Deep) {
                return Data_Eq.eq(dictEq)(x.value0)(y.value0) && Data_Eq.eq(dictEq)(x.value1)(y.value1);
            };
            if (x instanceof Adjacent && y instanceof Adjacent) {
                return Data_Eq.eq(dictEq)(x.value0)(y.value0) && Data_Eq.eq(dictEq)(x.value1)(y.value1);
            };
            if (x instanceof Combined && y instanceof Combined) {
                return Data_Eq.eq(dictEq)(x.value0)(y.value0) && Data_Eq.eq(dictEq)(x.value1)(y.value1);
            };
            return false;
        };
    });
};
var eqSelector = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(eqRefinement)(x.value0)(y.value0) && Data_Eq.eq(eqPath(eqSelector))(x.value1)(y.value1);
    };
});
var ordPath = function (dictOrd) {
    return new Data_Ord.Ord(function () {
        return eqPath(dictOrd["__superclass_Data.Eq.Eq_0"]());
    }, function (x) {
        return function (y) {
            if (x instanceof Star && y instanceof Star) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Star) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Star) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Elem && y instanceof Elem) {
                return Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
            };
            if (x instanceof Elem) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Elem) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof PathChild && y instanceof PathChild) {
                var $471 = Data_Ord.compare(dictOrd)(x.value0)(y.value0);
                if ($471 instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if ($471 instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                return Data_Ord.compare(dictOrd)(x.value1)(y.value1);
            };
            if (x instanceof PathChild) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof PathChild) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Deep && y instanceof Deep) {
                var $480 = Data_Ord.compare(dictOrd)(x.value0)(y.value0);
                if ($480 instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if ($480 instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                return Data_Ord.compare(dictOrd)(x.value1)(y.value1);
            };
            if (x instanceof Deep) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Deep) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Adjacent && y instanceof Adjacent) {
                var $489 = Data_Ord.compare(dictOrd)(x.value0)(y.value0);
                if ($489 instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if ($489 instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                return Data_Ord.compare(dictOrd)(x.value1)(y.value1);
            };
            if (x instanceof Adjacent) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Adjacent) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Combined && y instanceof Combined) {
                var $498 = Data_Ord.compare(dictOrd)(x.value0)(y.value0);
                if ($498 instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if ($498 instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                return Data_Ord.compare(dictOrd)(x.value1)(y.value1);
            };
            throw new Error("Failed pattern match at CSS.Selector line 53, column 1 - line 53, column 51: " + [ x.constructor.name, y.constructor.name ]);
        };
    });
};
var ordSelector = new Data_Ord.Ord(function () {
    return eqSelector;
}, function (x) {
    return function (y) {
        var $505 = Data_Ord.compare(ordRefinement)(x.value0)(y.value0);
        if ($505 instanceof Data_Ordering.LT) {
            return Data_Ordering.LT.value;
        };
        if ($505 instanceof Data_Ordering.GT) {
            return Data_Ordering.GT.value;
        };
        return Data_Ord.compare(ordPath(ordSelector))(x.value1)(y.value1);
    };
});
var element = function (e) {
    return new Selector([  ], new Elem(e));
};
var deep = function (a) {
    return function (b) {
        return new Selector([  ], new Deep(a, b));
    };
};
var child = function (a) {
    return function (b) {
        return new Selector([  ], new PathChild(a, b));
    };
};
module.exports = {
    Star: Star, 
    Elem: Elem, 
    PathChild: PathChild, 
    Deep: Deep, 
    Adjacent: Adjacent, 
    Combined: Combined, 
    Id: Id, 
    Class: Class, 
    Attr: Attr, 
    AttrVal: AttrVal, 
    AttrBegins: AttrBegins, 
    AttrEnds: AttrEnds, 
    AttrContains: AttrContains, 
    AttrSpace: AttrSpace, 
    AttrHyph: AttrHyph, 
    Pseudo: Pseudo, 
    PseudoFunc: PseudoFunc, 
    Refinement: Refinement, 
    Selector: Selector, 
    child: child, 
    deep: deep, 
    element: element, 
    star: star, 
    "with": $$with, 
    eqPredicate: eqPredicate, 
    ordPredicate: ordPredicate, 
    genericPredicate: genericPredicate, 
    eqRefinement: eqRefinement, 
    ordRefinement: ordRefinement, 
    genericRefinement: genericRefinement, 
    isStringRefinement: isStringRefinement, 
    eqPath: eqPath, 
    ordPath: ordPath, 
    genericPath: genericPath, 
    eqSelector: eqSelector, 
    ordSelector: ordSelector, 
    genericSelector: genericSelector, 
    isStringSelector: isStringSelector
};
