// Generated by psc version 0.9.1
"use strict";
var Control_Monad_Eff = require("../Control.Monad.Eff");
var Prelude = require("../Prelude");
var Signal_Channel = require("../Signal.Channel");
var State_Types = require("../State.Types");
var sendUnAuthenticated = function (channel) {
    return Signal_Channel.send(channel)(State_Types.UnAuthenticated.value);
};
var sendAction = function (channel) {
    return function (action) {
        return Signal_Channel.send(channel)(action);
    };
};
module.exports = {
    sendAction: sendAction, 
    sendUnAuthenticated: sendUnAuthenticated
};
