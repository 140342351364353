// Generated by psc version 0.9.1
"use strict";
var Data_StrMap = require("../Data.StrMap");
var Components_Loaded = require("../Components.Loaded");
var Data_Array = require("../Data.Array");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_List = require("../Data.List");
var Data_Loaded = require("../Data.Loaded");
var Data_Maybe = require("../Data.Maybe");
var Data_String = require("../Data.String");
var Data_Tuple = require("../Data.Tuple");
var Data_YesNo = require("../Data.YesNo");
var Model_Event = require("../Model.Event");
var Model_Review = require("../Model.Review");
var Model_SessionDescription = require("../Model.SessionDescription");
var Model_SpaLikeReviewVersion = require("../Model.SpaLikeReviewVersion");
var Prelude = require("../Prelude");
var Pux_Html = require("../Pux.Html");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var Route_HtmlElements = require("../Route.HtmlElements");
var State_Review = require("../State.Review");
var State_SessionDescription = require("../State.SessionDescription");
var State_Types = require("../State.Types");
var UReview_Route_Types_1 = require("../UReview.Route.Types");
var UReview_Route_Types_1 = require("../UReview.Route.Types");
var View_Loading = require("../View.Loading");
var View_ReviewSummaryCSV = require("../View.ReviewSummaryCSV");
var Data_Function = require("../Data.Function");
var Control_Category = require("../Control.Category");
var Data_Show = require("../Data.Show");
var Data_Functor = require("../Data.Functor");
var Data_Unfoldable = require("../Data.Unfoldable");
var Data_Semiring = require("../Data.Semiring");
var Data_Semigroup = require("../Data.Semigroup");
var reviewSummaryViewWithEmergencyCSV = function (state) {
    return function (key) {
        var showEvent = function (v) {
            var l = State_SessionDescription.loadedSessionDescriptions(v.sessionDescriptionKeys)(state);
            var r = Data_Loaded.runLoaded(l);
            if (r.allLoaded) {
                return Pux_Html_Elements.div([  ])([ Pux_Html.withChild(Pux_Html_Elements.p)(Route_HtmlElements.routeLink(new UReview_Route_Types_1.EventRoute(key))(v.name)), View_ReviewSummaryCSV.csvOfSessionDescriptionReviews(key)(v)(state) ]);
            };
            if (!r.allLoaded) {
                return Components_Loaded.viewLoadCount("Session descriptions")(l);
            };
            throw new Error("Failed pattern match at View.ReviewSummaryWithEmergencyCSV line 39, column 8 - line 46, column 49: " + [ r.allLoaded.constructor.name ]);
        };
        return Pux_Html.withChild(Pux_Html_Elements.div)((function () {
            var strKey = Data_ForeignKey.fkToString(key);
            var mEvent = Data_StrMap.lookup(strKey)(state.events);
            if (mEvent instanceof Data_Maybe.Nothing) {
                return View_Loading.loading("Event");
            };
            if (mEvent instanceof Data_Maybe.Just) {
                return showEvent(mEvent.value0);
            };
            throw new Error("Failed pattern match at View.ReviewSummaryWithEmergencyCSV line 34, column 3 - line 36, column 37: " + [ mEvent.constructor.name ]);
        })());
    };
};
var linkToReviewerName = function (v) {
    return function (s) {
        var userName = Data_Function.apply(Data_Maybe.maybe("click")(Control_Category.id(Control_Category.categoryFn)))(State_Review.reviewerName(v)(s));
        return Route_HtmlElements.routeToTop(new UReview_Route_Types_1.Reviewer(v.gatheringId, v.reviewerId))(userName);
    };
};
var displayYesNo = function (yn) {
    return Pux_Html_Elements.td([  ])([ Data_Function.apply(Pux_Html_Elements.text)(Data_YesNo.verboseYesNo(yn)) ]);
};
var displayOneToFive = function (v) {
    return Pux_Html.withChild(Pux_Html_Elements.td)(Pux_Html_Elements.text(Data_Show.show(Data_Show.showInt)(v)));
};
var showIndividualReview = function (mkLink) {
    return function (v) {
        var q = Data_Function.apply(Model_SpaLikeReviewVersion.runSpaLikeReviewQuestion)(v.reviewQuestions);
        var yesNoQuestions = Data_Functor.map(Data_Functor.functorArray)(displayYesNo)([ q.subjectClearlyDefined, q.sessionOfInterest, q.technicallySound, q.wellOrganised, q.wouldYouGo ]);
        var oneToFiveQuestions = Data_Functor.map(Data_Functor.functorArray)(displayOneToFive)([ q.overallEvaluation, q.ownConfidence ]);
        var aRow = function (c) {
            return Pux_Html_Elements.td(c);
        };
        return Pux_Html_Elements.tr([  ])(Data_Array.concat([ oneToFiveQuestions, yesNoQuestions, [ Pux_Html.withChild(Pux_Html_Elements.td)(mkLink(v)) ], [ Pux_Html_Elements.td([  ])([ Pux_Html_Elements.text(q.comments) ]) ] ]));
    };
};
var showIndividualReviews = function (linkTo) {
    return function (reviews) {
        var h = function (s) {
            return Pux_Html.withChild(Pux_Html_Elements.th)(Pux_Html_Elements.text(s));
        };
        var columnNames = [ "Rating", "Confidence", "Clear", "Interest", "Sound", "Organized", "Would go", "Reviewer", "Comments" ];
        return Pux_Html_Elements.table([  ])([ Pux_Html_Elements.thead([  ])([ Pux_Html_Elements.tr([  ])(Data_Functor.map(Data_Functor.functorArray)(h)(columnNames)) ]), Pux_Html_Elements.tbody([  ])(Data_Functor.map(Data_Functor.functorArray)(showIndividualReview(linkTo))(Data_List.toUnfoldable(Data_Unfoldable.unfoldableArray)(reviews))) ]);
    };
};
var countOtherSpeakers = function (v) {
    var countNonEmpty = function (name) {
        var $16 = Data_String.trim(name);
        if ($16 === "") {
            return 0;
        };
        return 1;
    };
    return Data_Function.apply(Data_Show.show(Data_Show.showInt))(countNonEmpty(v.speaker2name) + countNonEmpty(v.speaker3name) | 0);
};
var showReview$prime = function (linkTo) {
    return function (v) {
        return Pux_Html_Elements.li([  ])([ Pux_Html.withChild(Pux_Html_Elements.div)(Route_HtmlElements.routeToTop(new UReview_Route_Types_1.FullSessionDescriptionRoute(v.firstReview.sessionDescriptionId))(Data_Show.show(Data_Show.showInt)(v.sessionDescription.sessionid) + (" - " + v.sessionDescription.title))), Pux_Html.withChild(Pux_Html_Elements.div)(Pux_Html_Elements.text(v.sessionDescription.speakername + (" and " + (countOtherSpeakers(v.sessionDescription) + (" others, " + v.sessionDescription.speakerorg))))), Pux_Html.withChild(Pux_Html_Elements.div)(Pux_Html_Elements.text(v.sessionDescription.duration + (" - " + v.sessionDescription.sessiontype))), showIndividualReviews(linkTo)(v.reviews) ]);
    };
};
var reviewSummary$prime = function (linkTo) {
    return function (ls) {
        return Pux_Html_Elements.ul([  ])(Data_Functor.map(Data_Functor.functorArray)(showReview$prime(linkTo))(ls));
    };
};
var reviewSummary = function (eventKey) {
    return function (state) {
        var ls = Data_List.toUnfoldable(Data_Unfoldable.unfoldableArray)(State_Review.reviewsBySession(eventKey)(state));
        var linkTo = Data_Function.flip(linkToReviewerName)(state);
        return reviewSummary$prime(linkTo)(ls);
    };
};
var showReview = function (state) {
    return function (sdWithReviews) {
        var linkTo = Data_Function.flip(linkToReviewerName)(state);
        return showReview$prime(linkTo)(sdWithReviews);
    };
};
module.exports = {
    reviewSummaryViewWithEmergencyCSV: reviewSummaryViewWithEmergencyCSV
};
