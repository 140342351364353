// Generated by psc version 0.9.1
"use strict";
var Components_Elements = require("../Components.Elements");
var Components_Elements_Types = require("../Components.Elements.Types");
var Data_Array = require("../Data.Array");
var Data_Firebase_Review = require("../Data.Firebase.Review");
var Data_Firebase_Reviewer = require("../Data.Firebase.Reviewer");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_Maybe = require("../Data.Maybe");
var Data_Monoid = require("../Data.Monoid");
var Data_Ord = require("../Data.Ord");
var Data_Ordering = require("../Data.Ordering");
var Data_Set = require("../Data.Set");
var Data_Tuple = require("../Data.Tuple");
var Model_Event = require("../Model.Event");
var Model_Event_Key = require("../Model.Event.Key");
var Model_Review = require("../Model.Review");
var Model_ReviewCompletion = require("../Model.ReviewCompletion");
var Model_Reviewer = require("../Model.Reviewer");
var Model_SessionDescription = require("../Model.SessionDescription");
var Model_SpaLikeReviewVersion = require("../Model.SpaLikeReviewVersion");
var Model_UserProfile = require("../Model.UserProfile");
var Prelude = require("../Prelude");
var Pux_Html = require("../Pux.Html");
var Pux_Html_Attributes = require("../Pux.Html.Attributes");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var Route_HtmlElements = require("../Route.HtmlElements");
var State_Event = require("../State.Event");
var State_GlobalRole = require("../State.GlobalRole");
var State_Review = require("../State.Review");
var State_Types = require("../State.Types");
var UReview_Route_Types = require("../UReview.Route.Types");
var View_Loading = require("../View.Loading");
var Data_Semigroup = require("../Data.Semigroup");
var Components_Elements_Basic_Types = require("../Components.Elements.Basic.Types");
var Data_Functor = require("../Data.Functor");
var Data_Eq = require("../Data.Eq");
var Data_Function = require("../Data.Function");
var Data_Show = require("../Data.Show");
var Control_Bind = require("../Control.Bind");
var Data_Unfoldable = require("../Data.Unfoldable");
var trafficLight = function (completion) {
    if (completion instanceof Model_ReviewCompletion.ReviewStarted) {
        return Pux_Html_Elements.span([ Pux_Html_Attributes.className("traffic-before traffic-amber") ])([ Pux_Html_Elements.text("In progress") ]);
    };
    if (completion instanceof Model_ReviewCompletion.ReviewCompleted) {
        return Pux_Html_Elements.span([ Pux_Html_Attributes.className("traffic-before traffic-emerald") ])([ Pux_Html_Elements.text("Completed") ]);
    };
    if (completion instanceof Model_ReviewCompletion.ReviewDeclined) {
        return Pux_Html_Elements.span([ Pux_Html_Attributes.className("traffic-before traffic-ruby") ])([ Pux_Html_Elements.text("Declined") ]);
    };
    if (completion instanceof Model_ReviewCompletion.ReviewNotStarted) {
        return Pux_Html_Elements.span([ Pux_Html_Attributes.className("traffic-before traffic-base") ])([ Pux_Html_Elements.text("Not started") ]);
    };
    throw new Error("Failed pattern match at View.RandomReviewView line 209, column 27 - line 213, column 93: " + [ completion.constructor.name ]);
};
var showUserName = function (v) {
    return function (state) {
        var mU = Model_UserProfile.findUserByUid(v.uid)(state.allUsers);
        if (mU instanceof Data_Maybe.Nothing) {
            return View_Loading.loading("User");
        };
        if (mU instanceof Data_Maybe.Just) {
            return Pux_Html.withChild(Pux_Html_Elements.h3)(Pux_Html_Elements.text("Reviewer " + mU.value0.displayName));
        };
        throw new Error("Failed pattern match at View.RandomReviewView line 174, column 3 - line 176, column 71: " + [ mU.constructor.name ]);
    };
};
var sessionTitleOrdering = function (r1) {
    return function (r2) {
        var getTitle = function (r) {
            return (Model_SessionDescription.runSessionDescription(r.sessionDescription)).title;
        };
        return Data_Ord.compare(Data_Ord.ordString)(getTitle(r1))(getTitle(r2));
    };
};
var sessionIdOrdering = function (r1) {
    return function (r2) {
        var getId = function (r) {
            return (Model_SessionDescription.runSessionDescription(r.sessionDescription)).sessionid;
        };
        return Data_Ord.compare(Data_Ord.ordInt)(getId(r1))(getId(r2));
    };
};
var rowColumnH2 = function (title) {
    return function (innerElement) {
        return Pux_Html_Elements.div([ Pux_Html_Attributes.className("row") ])([ Pux_Html_Elements.div([ Pux_Html_Attributes.className("column") ])([ Pux_Html_Elements.h2([  ])([ Pux_Html_Elements.text(title) ]), Pux_Html_Elements.div([  ])([ innerElement ]) ]) ]);
    };
};
var row = Pux_Html_Elements.div([ Pux_Html_Attributes.className("row") ]);
var reviewASession = function (rkv) {
    return Components_Elements.createButton(new State_Types.DB(Data_Firebase_Reviewer.createReviewCommand(rkv)))(Components_Elements_Basic_Types.mkHtmlId("review-next-session"))("Get next review");
};
var paragraphs = function (strings) {
    return Data_Functor.map(Data_Functor.functorArray)(function (t) {
        return Pux_Html_Elements.p([  ])([ Pux_Html_Elements.text(t) ]);
    })(strings);
};
var welcomeMessage = paragraphs([ "Welcome to reviewing with WeReview. The 'Get next review' button below will let you pull a session to review. There is no Save button on a review, saving happens all the time, just like in e.g. Google Docs.", "WeReview is a work in progress. There will be regular updates, which you can get by opening WeReview in a fresh browser tab. We welcome any feedback you may have on the software via willem@livingsoftware.co.uk .", "If you have questions on the review process, please contact allison@software-acumen.com .", "So go ahead, press that 'Get next review' button, and have fun. Happy reviewing, the WeReview team." ]);
var showNumberOfReviews = function (r) {
    var count = Model_Reviewer.reviewCount(r);
    var zeroReviews = count === 0;
    return Pux_Html_Elements.div([  ])((function () {
        if (zeroReviews) {
            return welcomeMessage;
        };
        if (!zeroReviews) {
            return [ Pux_Html_Elements.p([  ])([ Data_Function.apply(Pux_Html_Elements.text)("You have chosen " + (Data_Show.show(Data_Show.showInt)(count) + " sessions to review.")) ]) ];
        };
        throw new Error("Failed pattern match at View.RandomReviewView line 152, column 25 - line 156, column 81: " + [ zeroReviews.constructor.name ]);
    })());
};
var overallEvaluation = function (v) {
    return Data_Function.apply(Pux_Html_Elements.text)((function () {
        if (v === 1) {
            return "Strong reject";
        };
        if (v === 2) {
            return "Weak reject";
        };
        if (v === 3) {
            return "Neither reject nor accept";
        };
        if (v === 4) {
            return "Weak accept";
        };
        if (v === 5) {
            return "Strong accept";
        };
        return "Not answered";
    })());
};
var isIncomplete = function (v) {
    var $23 = Model_SpaLikeReviewVersion.isComplete((Model_Review.runReview(v.review)).reviewQuestions);
    if ($23 instanceof Model_ReviewCompletion.ReviewCompleted) {
        return false;
    };
    if ($23 instanceof Model_ReviewCompletion.ReviewDeclined) {
        return false;
    };
    return true;
};
var howConfident = function (v) {
    return Data_Function.apply(Pux_Html_Elements.text)((function () {
        if (v === 1) {
            return "Not at all confident";
        };
        if (v === 2) {
            return "Not confident";
        };
        if (v === 3) {
            return "Somewhat confident";
        };
        if (v === 4) {
            return "Confident";
        };
        if (v === 5) {
            return "Extremely confident";
        };
        return "Not answered";
    })());
};
var headingShrink = Pux_Html_Elements.div([ Pux_Html_Attributes.className("column shrink") ]);
var heading = Pux_Html_Elements.div([ Pux_Html_Attributes.className("column") ]);
var reviewHeadings = function (state) {
    var textDiv = function (t) {
        return Pux_Html_Elements.div([  ])([ Pux_Html_Elements.text(t) ]);
    };
    var headings = [ "Actions", "Session Title", "Review status", "Own confidence", "Overall evaluation" ];
    var columnHeading = function (t) {
        return Data_Function.apply(heading)([ textDiv(t) ]);
    };
    return Data_Function.apply(row)(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ headingShrink([ textDiv("Id") ]) ])(Data_Functor.map(Data_Functor.functorArray)(columnHeading)(headings)));
};
var findReviewSessions = function (reviews) {
    return function (state) {
        var mkReviewRow = function (reviewKey) {
            return Control_Bind.bind(Data_Maybe.bindMaybe)(State_Review.findReviewSession(reviewKey)(state))(function (v) {
                return Data_Function.apply(Data_Maybe.Just.create)({
                    reviewKey: reviewKey, 
                    review: v.value0, 
                    sessionDescription: v.value1
                });
            });
        };
        return Data_Array.mapMaybe(mkReviewRow)(reviews);
    };
};
var reviewSessionsById = function (reviews) {
    return function (state) {
        var rs = findReviewSessions(reviews)(state);
        return Data_Array.sortBy(sessionIdOrdering)(rs);
    };
};
var columnShrink = Pux_Html_Elements.div([ Pux_Html_Attributes.className("column shrink") ]);
var column = Pux_Html_Elements.div([ Pux_Html_Attributes.className("column") ]);
var questionaireColumns = function (v) {
    return [ column([ Data_Function.apply(trafficLight)(Model_SpaLikeReviewVersion.isComplete(v)) ]), column([ howConfident(v.ownConfidence) ]), column([ overallEvaluation(v.overallEvaluation) ]) ];
};
var showReviewRow = function (state) {
    return function (v) {
        var reviewerActions = [ Route_HtmlElements.routeButton(new UReview_Route_Types.ReviewRoute(v.reviewKey))("edit-review-" + Data_ForeignKey.fkToString(v.reviewKey))("Edit") ];
        var administratorActions = (function () {
            var $33 = State_GlobalRole.hasAdministratorRole(state);
            if ($33) {
                return [ Components_Elements.createSmallButton(new State_Types.PerformAff(Data_Firebase_Review.deleteReview(v.reviewKey)(v.review)))("delete-review-" + Data_ForeignKey.fkToString(v.reviewKey))("Delete") ];
            };
            if (!$33) {
                return Data_Monoid.mempty(Data_Monoid.monoidArray);
            };
            throw new Error("Failed pattern match at View.RandomReviewView line 190, column 9 - line 193, column 18: " + [ $33.constructor.name ]);
        })();
        var actions = [ Data_Function.apply(column)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(administratorActions)(reviewerActions)) ];
        return Data_Function.apply(row)(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ columnShrink([ Pux_Html_Elements.text(Data_Show.show(Data_Show.showInt)((Model_SessionDescription.runSessionDescription(v.sessionDescription)).sessionid)) ]) ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(actions)(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ column([ Route_HtmlElements.routeToTop(new UReview_Route_Types.ReviewRoute(v.reviewKey))((Model_SessionDescription.runSessionDescription(v.sessionDescription)).title) ]) ])(questionaireColumns((Model_Review.runReview(v.review)).reviewQuestions)))));
    };
};
var showAllReviews = function (v) {
    return function (reviewRows) {
        return function (state) {
            return Pux_Html_Elements.div([  ])([ Pux_Html.withChild(Pux_Html_Elements.h3)(Pux_Html_Elements.text("All reviews")), reviewHeadings(state), Data_Function.apply(Pux_Html_Elements.div([  ]))(Data_Functor.map(Data_Functor.functorArray)(showReviewRow(state))(reviewRows)) ]);
        };
    };
};
var showIncompleteReviews = function (v) {
    return function (reviewRows) {
        return function (state) {
            return Pux_Html_Elements.div([  ])([ Pux_Html.withChild(Pux_Html_Elements.h3)(Pux_Html_Elements.text("Incomplete reviews")), reviewHeadings(state), Data_Function.apply(Pux_Html_Elements.div([  ]))(Data_Functor.map(Data_Functor.functorArray)(showReviewRow(state))(Data_Function.apply(Data_Array.filter(isIncomplete))(reviewRows))) ]);
        };
    };
};
var editReviews = function (v) {
    return function (reviewRows) {
        return function (state) {
            return Pux_Html_Elements.div([  ])([ reviewASession(v), showIncompleteReviews(v.value1)(reviewRows)(state), showAllReviews(v.value1)(reviewRows)(state) ]);
        };
    };
};
var showReviewer = function (v) {
    return function (state) {
        var reviewerHeading = Pux_Html_Elements.div([  ])([ showUserName(v.value1)(state), showNumberOfReviews(v.value1) ]);
        var allSessionsLoaded = State_Event.areAllSessionsLoadedEk(v.value1.eventId)(state);
        var editReviewsWhenLoaded = (function () {
            if (allSessionsLoaded) {
                return editReviews(v)(reviewSessionsById(Data_Set.toUnfoldable(Data_Unfoldable.unfoldableArray)(v.value1.reviews))(state))(state);
            };
            if (!allSessionsLoaded) {
                return Pux_Html_Elements.div([  ])([ Pux_Html_Elements.text("Loading data needed to create and edit reviews, please wait.") ]);
            };
            throw new Error("Failed pattern match at View.RandomReviewView line 80, column 29 - line 86, column 85: " + [ allSessionsLoaded.constructor.name ]);
        })();
        return Pux_Html_Elements.div([  ])([ reviewerHeading, editReviewsWhenLoaded ]);
    };
};
var showLoadedReviewerPage = function (rkv) {
    return function (v) {
        var body = Pux_Html_Elements.div([ Pux_Html_Attributes.className("row") ])([ showReviewer(rkv)(v), Route_HtmlElements.navBox([ Route_HtmlElements.backToTop ]) ]);
        return rowColumnH2("Reviews for Panel Chairs")(body);
    };
};
var showReviewerInline = function (v) {
    return function (state) {
        return Pux_Html.withChild(Pux_Html_Elements.div)(Data_Maybe.maybe(Pux_Html_Elements.text("Loading your reviews."))(function (keyValue) {
            return showReviewer(keyValue)(state);
        })(State_Event.findReviewerKey(v)(state)));
    };
};
var showEventLoading = function (key) {
    return function (v) {
        return Pux_Html.withChild(Pux_Html_Elements.section)((function () {
            var mEvent = State_Event.findEvent(key)(v);
            return Data_Maybe.maybe(View_Loading.loading("Event"))(function (event) {
                return showReviewerInline(event)(v);
            })(mEvent);
        })());
    };
};
var randomReviewView = function (eventKey) {
    return function (s) {
        return Pux_Html_Elements.div([  ])([ Route_HtmlElements.routeToTop(new UReview_Route_Types.EventRoute(eventKey))("See all of the event, session descriptions, reviewers. (may take a while to load)"), showEventLoading(eventKey)(s) ]);
    };
};
module.exports = {
    randomReviewView: randomReviewView
};
