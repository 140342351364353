// Generated by psc version 0.9.1
"use strict";
var $foreign = require("./foreign");
var Control_Monad_Eff = require("../Control.Monad.Eff");
var Data_Foreign = require("../Data.Foreign");
var Data_Function_Uncurried = require("../Data.Function.Uncurried");
var Data_Maybe = require("../Data.Maybe");
var Data_Nullable = require("../Data.Nullable");
var Data_StrMap = require("../Data.StrMap");
var Ports_Firebase_Types = require("../Ports.Firebase.Types");
var Prelude = require("../Prelude");
var Web_Firebase = require("../Web.Firebase");
var Web_Firebase_Authentication_Eff = require("../Web.Firebase.Authentication.Eff");
var Web_Firebase_DataSnapshot = require("../Web.Firebase.DataSnapshot");
var Web_Firebase_UnsafeRef = require("../Web.Firebase.UnsafeRef");
var updateOnSuccessOnError = function (values) {
    return function (onSuccess) {
        return function (onError) {
            return function (ref) {
                return Data_Function_Uncurried.runFn4($foreign._updateOnSuccessOnError)(values)(onSuccess)(onError)(ref);
            };
        };
    };
};
var updateFireAndForget = function (foreigns) {
    return function (ref) {
        return Data_Function_Uncurried.runFn2($foreign._updateFireAndForget)(foreigns)(ref);
    };
};
var logOut = function (thenHandler) {
    return function (elseHandler) {
        return Data_Function_Uncurried.runFn2($foreign._signOut)(thenHandler)(elseHandler);
    };
};
var key = function (ds) {
    return Data_Nullable.toMaybe(Data_Function_Uncurried.runFn1($foreign._key)(ds));
};
var authenticateGoogleWithRedirect = Data_Function_Uncurried.runFn0($foreign._authenticateGoogleWithRedirect);
module.exports = {
    authenticateGoogleWithRedirect: authenticateGoogleWithRedirect, 
    key: key, 
    logOut: logOut, 
    updateFireAndForget: updateFireAndForget, 
    updateOnSuccessOnError: updateOnSuccessOnError
};
