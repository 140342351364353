// Generated by psc version 0.9.1
"use strict";
var $foreign = require("./foreign");
var Prelude = require("../Prelude");
var Web_Firebase_Types = require("../Web.Firebase.Types");
var Control_Monad_Eff = require("../Control.Monad.Eff");
var Data_Foreign = require("../Data.Foreign");
var Data_Function_Uncurried = require("../Data.Function.Uncurried");
var Control_Applicative = require("../Control.Applicative");
var Data_Unit = require("../Data.Unit");
var unAuth = Data_Function_Uncurried.runFn1($foreign._unAuth);
var onAuth = Data_Function_Uncurried.runFn2($foreign._onAuth);
var offAuth = Data_Function_Uncurried.runFn2($foreign._offAuth);
var noOpCallBack = function (v) {
    return Control_Applicative.pure(Control_Monad_Eff.applicativeEff)(Data_Unit.unit);
};
var authWithOAuthRedirect = Data_Function_Uncurried.runFn3($foreign._authWithOAuthRedirect);
var authWithOAuthRedirectSilent = function (provider) {
    return authWithOAuthRedirect(provider)(noOpCallBack);
};
var authWithCustomToken = Data_Function_Uncurried.runFn4($foreign._authWithCustomToken);
module.exports = {
    authWithCustomToken: authWithCustomToken, 
    authWithOAuthRedirect: authWithOAuthRedirect, 
    authWithOAuthRedirectSilent: authWithOAuthRedirectSilent, 
    offAuth: offAuth, 
    onAuth: onAuth, 
    unAuth: unAuth
};
