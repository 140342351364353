// Generated by psc version 0.9.1
"use strict";
var rolesToken = "roles";
var reviewerToken = "reviewer";
var reviewerForToken = "reviewerFor";
var panelChairForToken = "panelChairFor";
var administratorToken = "administrator";
module.exports = {
    administratorToken: administratorToken, 
    panelChairForToken: panelChairForToken, 
    reviewerForToken: reviewerForToken, 
    reviewerToken: reviewerToken, 
    rolesToken: rolesToken
};
