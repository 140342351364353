// Generated by psc version 0.9.1
"use strict";
var Control_Monad_Aff = require("../Control.Monad.Aff");
var Control_Monad_Eff_Class = require("../Control.Monad.Eff.Class");
var DOM = require("../DOM");
var Prelude = require("../Prelude");
var Pux_Router = require("../Pux.Router");
var UReview_Route_AsUrl = require("../UReview.Route.AsUrl");
var Data_Function = require("../Data.Function");
var Data_Semigroup = require("../Data.Semigroup");
var navigateToTop = function (dictAsUrlString) {
    return function (route) {
        return Data_Function.apply(Control_Monad_Eff_Class.liftEff(Control_Monad_Aff.monadEffAff))(Data_Function.apply(Pux_Router.navigateTo)(UReview_Route_AsUrl.asUrlString(dictAsUrlString)(route) + "#top"));
    };
};
var navigateTo = function (dictAsUrlString) {
    return function (route) {
        return Data_Function.apply(Control_Monad_Eff_Class.liftEff(Control_Monad_Aff.monadEffAff))(Pux_Router.navigateTo(UReview_Route_AsUrl.asUrlString(dictAsUrlString)(route)));
    };
};
module.exports = {
    navigateTo: navigateTo, 
    navigateToTop: navigateToTop
};
