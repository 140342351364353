// Generated by psc version 0.9.1
"use strict";
var Prelude = require("../Prelude");
var Control_Category = require("../Control.Category");
var IsString = function (fromString) {
    this.fromString = fromString;
};
var isStringString = new IsString(Control_Category.id(Control_Category.categoryFn));
var fromString = function (dict) {
    return dict.fromString;
};
module.exports = {
    IsString: IsString, 
    fromString: fromString, 
    isStringString: isStringString
};
