firebase = require("firebase/app").default;

// Add additional services that you want to use
require("firebase/auth");
require("firebase/database");

mkTestRef = function() {
  return function() {
    console.log("Configuring firebase remote test environment.");
     var config = {
       apiKey: "AIzaSyBDquoedBnYdCMJfce_piYmaFXDRmdK5Gw",
       authDomain: "ureview-test.firebaseapp.com",
       databaseURL: "https://ureview-test.firebaseio.com",
       projectId: "ureview-test",
       storageBucket: "ureview-test.appspot.com",
       messagingSenderId: "69206488238"
      };
      return initializeFirebaseApp(config);
    };
  };

exports._ureviewTestRef = mkTestRef;

mkLocalRef = function() {
  return function() {
    console.log("Configuring firebase local.");
    var config = {
      apiKey: "AIzaSyBDquoedBnYdCMJfce_piYmaFXDRmdK5Gw",
      authDomain: "ureview-test.firebaseapp.com",
      databaseURL: "http://localhost:9001?ns=ureview-test",
      projectId: "ureview-test",
      storageBucket: "ureview-test.appspot.com",
      messagingSenderId: "69206488238"
    };
    return initializeFirebaseApp(config);
  };
};

exports._ureviewLocalRef = mkLocalRef;

function initializeFirebaseApp(config) {
  console.log("Firebase is before config:");

  console.log(firebase);
  if(!firebase) {
    throw new Error("Firebase not loaded");
  }
  console.log("Firebase loaded. Initializing.");
  firebase.initializeApp(config);
  window.firebaseApp = firebase;

  var rootRef = firebase.database().ref();

 firebase.auth().onAuthStateChanged(manageProfileInGlobals);
  return rootRef;
};

/*
  atrocious name, on purpose. Save and subscribe to save user data in global variables,
  so that we can tie existing purescript code to new code, and check things in tests.
  */
function manageProfileInGlobals(firebaseUser) {
  console.log(firebaseUser);
  window.firebaseUser = firebaseUser;
  if(firebaseUser) {
    window.firebaseUid = firebaseUser.uid; // for testing backdoor, and wiring multiple js apps together in one page
  }
  else {
    if(window.firebaseUid != null ) {
      const path = '/roles/' + window.firebaseUid;
    }
    window.firebaseUid = null;
  }
};

exports._ureviewProductionRef = function() {
  return function() {
    console.log("Configuring firebase production site.");
    var config = {
      apiKey: "AIzaSyB1vUTpxbFIrBhscC5SkVg_J1BV6JIQgEc",
      authDomain: "wereviewhq.com",
      databaseURL: "https://ureview.firebaseio.com",
      projectId: "firebase-ureview",
      storageBucket: "firebase-ureview.appspot.com",
      messagingSenderId: "231611820495"
    };
    return initializeFirebaseApp(config);
  };
};
