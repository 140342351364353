// Generated by psc version 0.9.1
"use strict";
var $foreign = require("./foreign");
var Data_Function_Uncurried = require("../Data.Function.Uncurried");
var Pux_Html = require("../Pux.Html");
var onWheel = Data_Function_Uncurried.runFn2($foreign.handler)("onWheel");
var onWaiting = Data_Function_Uncurried.runFn2($foreign.handler)("onWaiting");
var onVolumeChange = Data_Function_Uncurried.runFn2($foreign.handler)("onVolumeChange");
var onTouchStart = Data_Function_Uncurried.runFn2($foreign.handler)("onTouchStart");
var onTouchMove = Data_Function_Uncurried.runFn2($foreign.handler)("onTouchMove");
var onTouchEnd = Data_Function_Uncurried.runFn2($foreign.handler)("onTouchEnd");
var onTouchCancel = Data_Function_Uncurried.runFn2($foreign.handler)("onTouchCancel");
var onTimeUpdate = Data_Function_Uncurried.runFn2($foreign.handler)("onTimeUpdate");
var onSuspend = Data_Function_Uncurried.runFn2($foreign.handler)("onSuspend");
var onSubmit = Data_Function_Uncurried.runFn2($foreign.handler)("onSubmit");
var onStalled = Data_Function_Uncurried.runFn2($foreign.handler)("onStalled");
var onSelect = Data_Function_Uncurried.runFn2($foreign.handler)("onSelect");
var onSeeking = Data_Function_Uncurried.runFn2($foreign.handler)("onSeeking");
var onSeeked = Data_Function_Uncurried.runFn2($foreign.handler)("onSeeked");
var onScroll = Data_Function_Uncurried.runFn2($foreign.handler)("onScroll");
var onRateChange = Data_Function_Uncurried.runFn2($foreign.handler)("onRateChange");
var onProgress = Data_Function_Uncurried.runFn2($foreign.handler)("onProgress");
var onPlaying = Data_Function_Uncurried.runFn2($foreign.handler)("onPlaying");
var onPlay = Data_Function_Uncurried.runFn2($foreign.handler)("onPlay");
var onPause = Data_Function_Uncurried.runFn2($foreign.handler)("onPause");
var onPaste = Data_Function_Uncurried.runFn2($foreign.handler)("onPaste");
var onMouseUp = Data_Function_Uncurried.runFn2($foreign.handler)("onMouseUp");
var onMouseOver = Data_Function_Uncurried.runFn2($foreign.handler)("onMouseOver");
var onMouseOut = Data_Function_Uncurried.runFn2($foreign.handler)("onMouseOut");
var onMouseMove = Data_Function_Uncurried.runFn2($foreign.handler)("onMouseMove");
var onMouseLeave = Data_Function_Uncurried.runFn2($foreign.handler)("onMouseLeave");
var onMouseEnter = Data_Function_Uncurried.runFn2($foreign.handler)("onMouseEnter");
var onMouseDown = Data_Function_Uncurried.runFn2($foreign.handler)("onMouseDown");
var onLoadedMetadata = Data_Function_Uncurried.runFn2($foreign.handler)("onLoadedMetadata");
var onLoadedData = Data_Function_Uncurried.runFn2($foreign.handler)("onLoadedData");
var onLoadStart = Data_Function_Uncurried.runFn2($foreign.handler)("onLoadStart");
var onLoad = Data_Function_Uncurried.runFn2($foreign.handler)("onLoad");
var onKeyUp = Data_Function_Uncurried.runFn2($foreign.handler)("onKeyUp");
var onKeyPress = Data_Function_Uncurried.runFn2($foreign.handler)("onKeyPress");
var onKeyDown = Data_Function_Uncurried.runFn2($foreign.handler)("onKeyDown");
var onKey = Data_Function_Uncurried.runFn2($foreign.onKeyHandler);
var onInput = Data_Function_Uncurried.runFn2($foreign.handler)("onInput");
var onFocus = Data_Function_Uncurried.runFn2($foreign.handler)("onFocus");
var onError = Data_Function_Uncurried.runFn2($foreign.handler)("onError");
var onEnded = Data_Function_Uncurried.runFn2($foreign.handler)("onEnded");
var onEncrypted = Data_Function_Uncurried.runFn2($foreign.handler)("onEncrypted");
var onEmptied = Data_Function_Uncurried.runFn2($foreign.handler)("onEmptied");
var onDurationChange = Data_Function_Uncurried.runFn2($foreign.handler)("onDurationChange");
var onDrop = Data_Function_Uncurried.runFn2($foreign.handler)("onDrop");
var onDragStart = Data_Function_Uncurried.runFn2($foreign.handler)("onDragStart");
var onDragOver = Data_Function_Uncurried.runFn2($foreign.handler)("onDragOver");
var onDragLeave = Data_Function_Uncurried.runFn2($foreign.handler)("onDragLeave");
var onDragExit = Data_Function_Uncurried.runFn2($foreign.handler)("onDragExit");
var onDragEnter = Data_Function_Uncurried.runFn2($foreign.handler)("onDragEnter");
var onDragEnd = Data_Function_Uncurried.runFn2($foreign.handler)("onDragEnd");
var onDrag = Data_Function_Uncurried.runFn2($foreign.handler)("onDrag");
var onDoubleClick = Data_Function_Uncurried.runFn2($foreign.handler)("onDoubleClick");
var onCut = Data_Function_Uncurried.runFn2($foreign.handler)("onCut");
var onCopy = Data_Function_Uncurried.runFn2($foreign.handler)("onCopy");
var onContextMenu = Data_Function_Uncurried.runFn2($foreign.handler)("onContextMenu");
var onCompositionUpdate = Data_Function_Uncurried.runFn2($foreign.handler)("onCompositionUpdate");
var onCompositionStart = Data_Function_Uncurried.runFn2($foreign.handler)("onCompositionStart");
var onCompositionEnd = Data_Function_Uncurried.runFn2($foreign.handler)("onCompositionEnd");
var onClick = Data_Function_Uncurried.runFn2($foreign.handler)("onClick");
var onChange = Data_Function_Uncurried.runFn2($foreign.handler)("onChange");
var onCanPlayThrough = Data_Function_Uncurried.runFn2($foreign.handler)("onCanPlayThrough");
var onCanPlay = Data_Function_Uncurried.runFn2($foreign.handler)("onCanPlay");
var onBlur = Data_Function_Uncurried.runFn2($foreign.handler)("onBlur");
var onAbort = Data_Function_Uncurried.runFn2($foreign.handler)("onAbort");
module.exports = {
    onAbort: onAbort, 
    onBlur: onBlur, 
    onCanPlay: onCanPlay, 
    onCanPlayThrough: onCanPlayThrough, 
    onChange: onChange, 
    onClick: onClick, 
    onCompositionEnd: onCompositionEnd, 
    onCompositionStart: onCompositionStart, 
    onCompositionUpdate: onCompositionUpdate, 
    onContextMenu: onContextMenu, 
    onCopy: onCopy, 
    onCut: onCut, 
    onDoubleClick: onDoubleClick, 
    onDrag: onDrag, 
    onDragEnd: onDragEnd, 
    onDragEnter: onDragEnter, 
    onDragExit: onDragExit, 
    onDragLeave: onDragLeave, 
    onDragOver: onDragOver, 
    onDragStart: onDragStart, 
    onDrop: onDrop, 
    onDurationChange: onDurationChange, 
    onEmptied: onEmptied, 
    onEncrypted: onEncrypted, 
    onEnded: onEnded, 
    onError: onError, 
    onFocus: onFocus, 
    onInput: onInput, 
    onKey: onKey, 
    onKeyDown: onKeyDown, 
    onKeyPress: onKeyPress, 
    onKeyUp: onKeyUp, 
    onLoad: onLoad, 
    onLoadStart: onLoadStart, 
    onLoadedData: onLoadedData, 
    onLoadedMetadata: onLoadedMetadata, 
    onMouseDown: onMouseDown, 
    onMouseEnter: onMouseEnter, 
    onMouseLeave: onMouseLeave, 
    onMouseMove: onMouseMove, 
    onMouseOut: onMouseOut, 
    onMouseOver: onMouseOver, 
    onMouseUp: onMouseUp, 
    onPaste: onPaste, 
    onPause: onPause, 
    onPlay: onPlay, 
    onPlaying: onPlaying, 
    onProgress: onProgress, 
    onRateChange: onRateChange, 
    onScroll: onScroll, 
    onSeeked: onSeeked, 
    onSeeking: onSeeking, 
    onSelect: onSelect, 
    onStalled: onStalled, 
    onSubmit: onSubmit, 
    onSuspend: onSuspend, 
    onTimeUpdate: onTimeUpdate, 
    onTouchCancel: onTouchCancel, 
    onTouchEnd: onTouchEnd, 
    onTouchMove: onTouchMove, 
    onTouchStart: onTouchStart, 
    onVolumeChange: onVolumeChange, 
    onWaiting: onWaiting, 
    onWheel: onWheel, 
    handler: $foreign.handler, 
    onKeyHandler: $foreign.onKeyHandler
};
