// Generated by psc version 0.9.1
"use strict";
var Data_Map = require("../Data.Map");
var Control_Applicative = require("../Control.Applicative");
var Data_Array = require("../Data.Array");
var Data_Bifunctor = require("../Data.Bifunctor");
var Data_Foreign = require("../Data.Foreign");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_ForeignLocation = require("../Data.ForeignLocation");
var Data_List = require("../Data.List");
var Data_Maybe = require("../Data.Maybe");
var Data_StrMap = require("../Data.StrMap");
var Data_Tuple = require("../Data.Tuple");
var Model_Event = require("../Model.Event");
var Model_Review = require("../Model.Review");
var Model_Reviewer = require("../Model.Reviewer");
var Model_SessionDescription = require("../Model.SessionDescription");
var Model_UserProfile_1 = require("../Model.UserProfile");
var Model_UserProfile_1 = require("../Model.UserProfile");
var Prelude = require("../Prelude");
var State_ApplyFound = require("../State.ApplyFound");
var State_Find_Reviewer = require("../State.Find.Reviewer");
var State_SessionDescription = require("../State.SessionDescription");
var State_Types = require("../State.Types");
var State_UserProfiles = require("../State.UserProfiles");
var Data_Ord = require("../Data.Ord");
var Data_Eq = require("../Data.Eq");
var Control_Bind = require("../Control.Bind");
var Data_Functor = require("../Data.Functor");
var Data_Function = require("../Data.Function");
var Control_Apply = require("../Control.Apply");
var Data_Either = require("../Data.Either");
var sessionIdOrdering = function (r1) {
    return function (r2) {
        var getId = function (r) {
            return (Model_SessionDescription.runSessionDescription(r.sessionDescription)).sessionid;
        };
        return Data_Ord.compare(Data_Ord.ordInt)(getId(r1))(getId(r2));
    };
};
var reviewsForEventKey = function (eventKey) {
    return function (state) {
        return Data_List.filter(function (v) {
            return Data_Eq.eq(Data_ForeignKey.eqForeignKey)(v.gatheringId)(eventKey);
        })(Data_StrMap.values(state.reviews));
    };
};
var reviewerName = function (v) {
    return function (s) {
        return Control_Bind.bind(Data_Maybe.bindMaybe)(State_Find_Reviewer.findReviewer(v.gatheringId)(v.reviewerId)(s))(function (v1) {
            return Data_Functor.map(Data_Maybe.functorMaybe)(Model_UserProfile_1.getDisplayName)(State_UserProfiles.findUser(Model_UserProfile_1.mkUserKey(v1.uid))(s));
        });
    };
};
var mergeSession = function (state) {
    return function (reviews) {
        return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_List.head(reviews))(function (v) {
            return Control_Bind.bind(Data_Maybe.bindMaybe)(State_SessionDescription.findSessionDescription(v.sessionDescriptionId)(state))(function (v1) {
                return Data_Function.apply(Data_Maybe.Just.create)({
                    reviews: reviews, 
                    firstReview: v, 
                    sessionDescription: v1, 
                    sessionId: v.sessionDescriptionId
                });
            });
        });
    };
};
var reviewsBySession = function (eventKey) {
    return function (state) {
        var listsOfReviews = Data_Function.apply(Data_List.groupBy(Model_Review.sameSession))(Data_Function.apply(Data_List.sortBy(Model_Review.bySessionId))(reviewsForEventKey(eventKey)(state)));
        return Data_Function.apply(Data_List.catMaybes)(Data_Functor.map(Data_List.functorList)(mergeSession(state))(listsOfReviews));
    };
};
var reviewsForSession = function (key) {
    return function (v) {
        return function (state) {
            var findSession = function (sdwr) {
                return Data_Eq.eq(Data_ForeignKey.eqForeignKey)(sdwr.sessionId)(key);
            };
            return Data_Function.apply(Data_List.head)(Data_Function.apply(Data_List.filter(findSession))(reviewsBySession(v.eventId)(state)));
        };
    };
};
var findReview = function (key) {
    return function (state) {
        return Data_StrMap.lookup(Data_ForeignKey.fkToString(key))(state.reviews);
    };
};
var findReviewSession = function (key) {
    return function (state) {
        return Control_Bind.bind(Data_Maybe.bindMaybe)(findReview(key)(state))(function (v) {
            return Control_Bind.bind(Data_Maybe.bindMaybe)(State_SessionDescription.findSessionDescription(v.sessionDescriptionId)(state))(function (v1) {
                return Data_Function.apply(Data_Maybe.Just.create)(new Data_Tuple.Tuple(v, v1));
            });
        });
    };
};
var findReviewSessionTitle = function (key) {
    return function (state) {
        return Data_Functor.map(Data_Maybe.functorMaybe)(Data_Bifunctor.rmap(Data_Tuple.bifunctorTuple)(function (v) {
            return v.title;
        }))(findReviewSession(key)(state));
    };
};
var findReviewSessions = function (reviews) {
    return function (state) {
        var mkReviewRow = function (reviewKey) {
            return Control_Bind.bind(Data_Maybe.bindMaybe)(findReviewSession(reviewKey)(state))(function (v) {
                return Data_Function.apply(Data_Maybe.Just.create)({
                    reviewKey: reviewKey, 
                    review: v.value0, 
                    sessionDescription: v.value1
                });
            });
        };
        return Data_Array.mapMaybe(mkReviewRow)(reviews);
    };
};
var reviewSessionsById = function (reviews) {
    return function (state) {
        var rs = findReviewSessions(reviews)(state);
        return Data_Array.sortBy(sessionIdOrdering)(rs);
    };
};
var addReviewKey = function (reviewerKey) {
    return function (reviewKey) {
        return function (v) {
            return Data_Function.apply(Model_Event.Event)((function () {
                var $30 = {};
                for (var $31 in v) {
                    if (v.hasOwnProperty($31)) {
                        $30[$31] = v[$31];
                    };
                };
                $30.reviewers = Data_Map.update(Data_ForeignKey.ordForeignKey)(function (r) {
                    return Data_Function.apply(Data_Maybe.Just.create)(Model_Reviewer.addedReview(reviewKey)(r));
                })(reviewerKey)(v.reviewers);
                return $30;
            })());
        };
    };
};
var setReview = function (eventKey) {
    return function (reviewerKey) {
        return function (reviewKey) {
            return function (review) {
                return function (state) {
                    var $32 = {};
                    for (var $33 in state) {
                        if (state.hasOwnProperty($33)) {
                            $32[$33] = state[$33];
                        };
                    };
                    $32.events = Data_StrMap.update(function (event) {
                        return Data_Function.apply(Data_Maybe.Just.create)(addReviewKey(reviewerKey)(reviewKey)(event));
                    })(Data_ForeignKey.fkToString(eventKey))(state.events);
                    $32.reviews = Data_StrMap.insert(Data_ForeignKey.fkToString(reviewKey))(review)(state.reviews);
                    return $32;
                };
            };
        };
    };
};
var applyForeignReview = function (foreignLocation) {
    return function (state) {
        var parsedReview = Data_ForeignLocation.readForeignPart(Model_Review.isForeignReview)(foreignLocation);
        return Control_Apply.apply(Data_Either.applyEither)(Control_Apply.apply(Data_Either.applyEither)(Control_Apply.apply(Data_Either.applyEither)(Control_Apply.apply(Data_Either.applyEither)(Data_Functor.map(Data_Either.functorEither)(setReview)(Data_Functor.map(Data_Either.functorEither)(Model_Review.eventKey)(parsedReview)))(Data_Functor.map(Data_Either.functorEither)(Model_Review.getReviewerKey)(parsedReview)))(Data_Function.apply(Control_Applicative.pure(Data_Either.applicativeEither))(Data_ForeignLocation.key(foreignLocation))))(parsedReview))(Control_Applicative.pure(Data_Either.applicativeEither)(state));
    };
};
module.exports = {
    applyForeignReview: applyForeignReview, 
    findReview: findReview, 
    findReviewSession: findReviewSession, 
    findReviewSessionTitle: findReviewSessionTitle, 
    findReviewSessions: findReviewSessions, 
    reviewSessionsById: reviewSessionsById, 
    reviewerName: reviewerName, 
    reviewsBySession: reviewsBySession, 
    reviewsForEventKey: reviewsForEventKey, 
    reviewsForSession: reviewsForSession, 
    setReview: setReview
};
