// Generated by psc version 0.9.1
"use strict";
var Control_Monad_State = require("../Control.Monad.State");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var Data_Foreign_Generic = require("../Data.Foreign.Generic");
var Data_Generic = require("../Data.Generic");
var Data_Identity = require("../Data.Identity");
var Prelude = require("../Prelude");
var Test_QuickCheck_Arbitrary = require("../Test.QuickCheck.Arbitrary");
var Test_QuickCheck_LCG = require("../Test.QuickCheck.LCG");
var Control_Apply = require("../Control.Apply");
var Data_Maybe = require("../Data.Maybe");
var Data_Unit = require("../Data.Unit");
var Data_Show = require("../Data.Show");
var Data_Eq = require("../Data.Eq");
var Data_Functor = require("../Data.Functor");
var Data_Ord = require("../Data.Ord");
var Data_Boolean = require("../Data.Boolean");
var Data_Ring = require("../Data.Ring");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans");
var Data_Semiring = require("../Data.Semiring");
var VersionNumber = function (x) {
    return x;
};
var positiveInt = (function () {
    var abs = function (n) {
        if (n >= 0) {
            return n;
        };
        if (Data_Boolean.otherwise) {
            return -n;
        };
        throw new Error("Failed pattern match at Data.VersionNumber line 34, column 9 - line 35, column 31: " + [ n.constructor.name ]);
    };
    return Data_Functor.map(Control_Monad_State_Trans.functorStateT(Data_Identity.functorIdentity))(abs)(Test_QuickCheck_Arbitrary.arbitrary(Test_QuickCheck_Arbitrary.arbInt));
})();
var mkVersionNumber = 0;
var jsonOptions = (function () {
    var $6 = {};
    for (var $7 in Data_Foreign_Generic.defaultOptions) {
        if (Data_Foreign_Generic.defaultOptions.hasOwnProperty($7)) {
            $6[$7] = Data_Foreign_Generic.defaultOptions[$7];
        };
    };
    $6.unwrapNewtypes = true;
    return $6;
})();
var incrementVersionNumber = function (v) {
    return v + 1 | 0;
};
var genericVersionNumber = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Data.VersionNumber.VersionNumber" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(VersionNumber))(Data_Generic.fromSpine(Data_Generic.genericInt)(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Data.VersionNumber.VersionNumber", [ {
        sigConstructor: "Data.VersionNumber.VersionNumber", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_Generic.genericInt)(Data_Generic.anyProxy);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("Data.VersionNumber.VersionNumber", [ function ($dollarq) {
        return Data_Generic.toSpine(Data_Generic.genericInt)(v);
    } ]);
});
var isForeignVersionNumber = new Data_Foreign_Class.IsForeign(Data_Foreign_Generic.readGeneric(genericVersionNumber)(jsonOptions));
var showVersionNumber = new Data_Show.Show(Data_Generic.gShow(genericVersionNumber));
var eqVersionNumber = new Data_Eq.Eq(Data_Generic.gEq(genericVersionNumber));
var arbVersionNumber = new Test_QuickCheck_Arbitrary.Arbitrary(Data_Functor.map(Control_Monad_State_Trans.functorStateT(Data_Identity.functorIdentity))(VersionNumber)(positiveInt));
module.exports = {
    VersionNumber: VersionNumber, 
    incrementVersionNumber: incrementVersionNumber, 
    jsonOptions: jsonOptions, 
    mkVersionNumber: mkVersionNumber, 
    positiveInt: positiveInt, 
    genericVersionNumber: genericVersionNumber, 
    showVersionNumber: showVersionNumber, 
    eqVersionNumber: eqVersionNumber, 
    arbVersionNumber: arbVersionNumber, 
    isForeignVersionNumber: isForeignVersionNumber
};
