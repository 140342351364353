'use strict';

exports._key = function(ds) {
  return ds.key;
};


exports._authenticateGoogleWithRedirect = function() {
  return function() {
    console.log("Redirecting to google for authentication");
    console.log("Firebase is:");
    // var firebase = require("firebase/app");
    // require("firebase/auth");
    var firebase = window.firebaseApp;
    console.log(firebase);
    console.log("Firebase was:");
    // https://firebase.google.com/docs/auth/web/google-signin
    var provider = new firebase.auth.GoogleAuthProvider();
    console.log("got provider");
    console.log(provider);
    provider.setCustomParameters({ prompt: 'select_account'});
    console.log("redirecting");
    // auth() instead of auth in the next line, see below
    // https://stackoverflow.com/questions/39397381/react-firebase-googleauthprovider-is-not-a-constructor
    firebase.auth().signInWithRedirect(provider);
    console.log("we should have redirected already");
  };
};

exports._signOut = function(onSuccess, onError) {
  return function() {
    window.firebaseApp.auth().signOut().then(function() {
      console.log("signed out");
      onSuccess();
    }).catch(function(error) {
      console.log("sign out failed");
      onError();
    });
  };
};

exports._updateFireAndForget = function (foreign, aRef) {
  return function() {
    console.log("Batch updateFireAndForget: " + JSON.stringify(foreign));
    aRef.update(foreign);
  };
};

// similar to _setA in Firebase.js
// but for some reason neither the success nor the error callback or promise get executed.
// so we have commented them out.
exports._updateOnSuccessOnError = function (value, onSuccess, onError, fb) {
  return function () {
    console.log("updating...");
    // console.log("value is:" + JSON.stringify(value));
    const ks = Object.keys(value);
    const lns = ks.length;
    const first = ks[0];
    const last = ks[lns - 1];

    console.log("number of keys:" + lns );
    console.log("first key of this batch: " + first);
    console.log("last key of this batch: " + last);
    fb.update(value).then(function() {
      console.log("---updated");
      onSuccess({})(); // {} is unit
    }).catch(function(error) {
      console.error("update failed: " + JSON.stringify(error));
      onError(error)();
    });

  };
};
