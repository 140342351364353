// Generated by psc version 0.9.1
"use strict";
var Data_List = require("../Data.List");
var Data_Generic = require("../Data.Generic");
var Data_Maybe = require("../Data.Maybe");
var Model_Event_Key = require("../Model.Event.Key");
var Prelude = require("../Prelude");
var Model_CallForSessions = require("../Model.CallForSessions");
var Model_OpenCallForSessions_PublicationStatus = require("../Model.OpenCallForSessions.PublicationStatus");
var Control_Apply = require("../Control.Apply");
var Data_Unit = require("../Data.Unit");
var Data_Eq = require("../Data.Eq");
var Data_Show = require("../Data.Show");
var Data_Semigroup = require("../Data.Semigroup");
var Data_Unfoldable = require("../Data.Unfoldable");
var Data_Function = require("../Data.Function");
var Data_ForeignKey = require("../Data.ForeignKey");
var Control_Category = require("../Control.Category");
var Data_Boolean = require("../Data.Boolean");
var OpenCallsForSessions = function (x) {
    return x;
};
var toArray = function (v) {
    return Data_List.toUnfoldable(Data_Unfoldable.unfoldableArray)(v);
};
var showOpenCallsForSessions = new Data_Show.Show(function (v) {
    return "Open Calls For Sessions  " + Data_Show.show(Data_List.showList(Model_CallForSessions.showOpenCallForSessions))(v);
});
var publishedCallsForSessions = function (v) {
    var isPublished = function (v1) {
        return Data_Eq.eq(Model_OpenCallForSessions_PublicationStatus.eqPublicationStatus)(v1.published)(Model_OpenCallForSessions_PublicationStatus.Published.value);
    };
    return Data_Function.apply(OpenCallsForSessions)(Data_List.filter(isPublished)(v));
};
var notClosed = function (v) {
    var notClosed$prime = function (v1) {
        if (v1.published instanceof Model_OpenCallForSessions_PublicationStatus.Closed) {
            return false;
        };
        return true;
    };
    return Data_Function.apply(OpenCallsForSessions)(Data_List.filter(notClosed$prime)(v));
};
var hasKey = function (k) {
    return function (v) {
        return Data_Eq.eq(Data_ForeignKey.eqForeignKey)(k)(v.eventId);
    };
};
var upsertCfS = function (c) {
    return function (v) {
        var sameKey = function (v1) {
            return hasKey(v1.eventId)(c);
        };
        return Data_Function.apply(OpenCallsForSessions)((function () {
            var $35 = Data_List.findIndex(sameKey)(v);
            if ($35 instanceof Data_Maybe.Nothing) {
                return new Data_List.Cons(c, v);
            };
            if ($35 instanceof Data_Maybe.Just) {
                return Data_Function.apply(Data_Maybe.maybe(v)(Control_Category.id(Control_Category.categoryFn)))(Data_List.updateAt($35.value0)(c)(v));
            };
            throw new Error("Failed pattern match at Model.OpenCallsForSessions line 39, column 47 - line 41, column 54: " + [ $35.constructor.name ]);
        })());
    };
};
var genericOpenCallsForSessions = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.OpenCallsForSessions.OpenCallsForSessions" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(OpenCallsForSessions))(Data_Generic.fromSpine(Data_List.genericList(Model_CallForSessions.genericOpenCallForSessions))(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Model.OpenCallsForSessions.OpenCallsForSessions", [ {
        sigConstructor: "Model.OpenCallsForSessions.OpenCallsForSessions", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_List.genericList(Model_CallForSessions.genericOpenCallForSessions))(Data_Generic.anyProxy);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("Model.OpenCallsForSessions.OpenCallsForSessions", [ function ($dollarq) {
        return Data_Generic.toSpine(Data_List.genericList(Model_CallForSessions.genericOpenCallForSessions))(v);
    } ]);
});
var findElement = function (fn) {
    var go = function (__copy_v) {
        var v = __copy_v;
        tco: while (true) {
            if (v instanceof Data_List.Cons) {
                if (fn(v.value0)) {
                    return new Data_Maybe.Just(v.value0);
                };
                if (Data_Boolean.otherwise) {
                    var __tco_v = v.value1;
                    v = __tco_v;
                    continue tco;
                };
            };
            if (v instanceof Data_List.Nil) {
                return Data_Maybe.Nothing.value;
            };
            throw new Error("Failed pattern match at Model.OpenCallsForSessions line 54, column 3 - line 55, column 37: " + [ v.constructor.name ]);
        };
    };
    return go;
};
var find = function (k) {
    return function (v) {
        return findElement(hasKey(k))(v);
    };
};
var eqOpenCallsForSessions = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(Data_List.eqList(Model_CallForSessions.eqOpenCallForSessions))(x)(y);
    };
});
var empty = Data_List.Nil.value;
module.exports = {
    empty: empty, 
    find: find, 
    notClosed: notClosed, 
    publishedCallsForSessions: publishedCallsForSessions, 
    toArray: toArray, 
    upsertCfS: upsertCfS, 
    genericOpenCallsForSessions: genericOpenCallsForSessions, 
    eqOpenCallsForSessions: eqOpenCallsForSessions, 
    showOpenCallsForSessions: showOpenCallsForSessions
};
