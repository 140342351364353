// Generated by psc version 0.9.1
"use strict";
var Prelude = require("../Prelude");
var Data_Generic = require("../Data.Generic");
var Data_Tuple_Nested = require("../Data.Tuple.Nested");
var CSS_Color = require("../CSS.Color");
var CSS_Property = require("../CSS.Property");
var CSS_Size = require("../CSS.Size");
var CSS_String = require("../CSS.String");
var CSS_Stylesheet = require("../CSS.Stylesheet");
var Data_Eq = require("../Data.Eq");
var Data_Ord = require("../Data.Ord");
var Control_Apply = require("../Control.Apply");
var Data_Maybe = require("../Data.Maybe");
var Data_Unit = require("../Data.Unit");
var Data_Function = require("../Data.Function");
var Stroke = function (x) {
    return x;
};
var wavy = Data_Function.apply(Stroke)(CSS_String.fromString(CSS_Property.isStringValue)("wavy"));
var valStroke = new CSS_Property.Val(function (v) {
    return v;
});
var solid = Data_Function.apply(Stroke)(CSS_String.fromString(CSS_Property.isStringValue)("solid"));
var ridge = Data_Function.apply(Stroke)(CSS_String.fromString(CSS_Property.isStringValue)("ridge"));
var outset = Data_Function.apply(Stroke)(CSS_String.fromString(CSS_Property.isStringValue)("outset"));
var inset = Data_Function.apply(Stroke)(CSS_String.fromString(CSS_Property.isStringValue)("inset"));
var groove = Data_Function.apply(Stroke)(CSS_String.fromString(CSS_Property.isStringValue)("groove"));
var genericStroke = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "CSS.Border.Stroke" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Stroke))(Data_Generic.fromSpine(CSS_Property.genericValue)(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("CSS.Border.Stroke", [ {
        sigConstructor: "CSS.Border.Stroke", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(CSS_Property.genericValue)(Data_Generic.anyProxy);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("CSS.Border.Stroke", [ function ($dollarq) {
        return Data_Generic.toSpine(CSS_Property.genericValue)(v);
    } ]);
});
var eqStroke = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(CSS_Property.eqValue)(x)(y);
    };
});
var ordStroke = new Data_Ord.Ord(function () {
    return eqStroke;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(CSS_Property.ordValue)(x)(y);
    };
});
var $$double = Data_Function.apply(Stroke)(CSS_String.fromString(CSS_Property.isStringValue)("double"));
var dotted = Data_Function.apply(Stroke)(CSS_String.fromString(CSS_Property.isStringValue)("dotted"));
var dashed = Data_Function.apply(Stroke)(CSS_String.fromString(CSS_Property.isStringValue)("dashed"));
var borderRadius = function (a) {
    return function (b) {
        return function (c) {
            return function (d) {
                return CSS_Stylesheet.key(CSS_Property.valTuple(CSS_Property.valTuple(CSS_Property.valTuple(CSS_Size.valSize)(CSS_Size.valSize))(CSS_Size.valSize))(CSS_Size.valSize))(CSS_String.fromString(CSS_Property.isStringKey)("border-radius"))(Data_Tuple_Nested.tuple4(a)(b)(c)(d));
            };
        };
    };
};
var borderColor = Data_Function.apply(CSS_Stylesheet.key(CSS_Property.valColor))(CSS_String.fromString(CSS_Property.isStringKey)("border-color"));
var border = function (a) {
    return function (b) {
        return function (c) {
            return Data_Function.apply(CSS_Stylesheet.key(CSS_Property.valTuple(CSS_Property.valTuple(valStroke)(CSS_Size.valSize))(CSS_Property.valColor))(CSS_String.fromString(CSS_Property.isStringKey)("border")))(Data_Tuple_Nested.tuple3(a)(b)(c));
        };
    };
};
module.exports = {
    Stroke: Stroke, 
    border: border, 
    borderColor: borderColor, 
    borderRadius: borderRadius, 
    dashed: dashed, 
    dotted: dotted, 
    "double": $$double, 
    groove: groove, 
    inset: inset, 
    outset: outset, 
    ridge: ridge, 
    solid: solid, 
    wavy: wavy, 
    eqStroke: eqStroke, 
    ordStroke: ordStroke, 
    genericStroke: genericStroke, 
    valStroke: valStroke
};
