// Generated by psc version 0.9.1
"use strict";
var $foreign = require("./foreign");
var Data_Foreign = require("../Data.Foreign");
var Prelude = require("../Prelude");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var fieldStringValues = function ($0) {
    return $foreign._fieldStringValues(Data_Foreign.toForeign($0));
};
var fieldNames = function ($1) {
    return $foreign._fieldNames(Data_Foreign.toForeign($1));
};
module.exports = {
    fieldNames: fieldNames, 
    fieldStringValues: fieldStringValues
};
