// Generated by psc version 0.9.1
"use strict";
var Control_Alt = require("../Control.Alt");
var Data_Default = require("../Data.Default");
var Data_Firebase_Foreign = require("../Data.Firebase.Foreign");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var Data_Foreign_Generic = require("../Data.Foreign.Generic");
var Data_Generic = require("../Data.Generic");
var Data_String = require("../Data.String");
var Data_Ord = require("../Data.Ord");
var Data_HumanReadable = require("../Data.HumanReadable");
var Data_Valid = require("../Data.Valid");
var Data_VersionNumber = require("../Data.VersionNumber");
var Model_SessionDescription_Expenses_Place = require("../Model.SessionDescription.Expenses.Place");
var Prelude = require("../Prelude");
var Test_QuickCheck_Arbitrary = require("../Test.QuickCheck.Arbitrary");
var Test_QuickCheck_Gen = require("../Test.QuickCheck.Gen");
var UReview_Prelude = require("../UReview.Prelude");
var Control_Apply = require("../Control.Apply");
var Data_Maybe = require("../Data.Maybe");
var Data_Unit = require("../Data.Unit");
var Data_Show = require("../Data.Show");
var Data_Eq = require("../Data.Eq");
var Data_Functor = require("../Data.Functor");
var Control_Applicative = require("../Control.Applicative");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra");
var Control_Bind = require("../Control.Bind");
var Data_Function = require("../Data.Function");
var Data_Semigroup = require("../Data.Semigroup");
var Data_Either = require("../Data.Either");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans");
var Data_Identity = require("../Data.Identity");
var PleaseConfirmConsequences = (function () {
    function PleaseConfirmConsequences() {

    };
    PleaseConfirmConsequences.value = new PleaseConfirmConsequences();
    return PleaseConfirmConsequences;
})();
var IWillNotClaimLater = (function () {
    function IWillNotClaimLater() {

    };
    IWillNotClaimLater.value = new IWillNotClaimLater();
    return IWillNotClaimLater;
})();
var ExpenseRequestDetail = function (x) {
    return x;
};
var PleaseChoose = (function () {
    function PleaseChoose() {

    };
    PleaseChoose.value = new PleaseChoose();
    return PleaseChoose;
})();
var No = (function () {
    function No() {

    };
    No.value = new No();
    return No;
})();
var Yes = (function () {
    function Yes(value0) {
        this.value0 = value0;
    };
    Yes.create = function (value0) {
        return new Yes(value0);
    };
    return Yes;
})();
var BasicExpenses = (function () {
    function BasicExpenses(value0) {
        this.value0 = value0;
    };
    BasicExpenses.create = function (value0) {
        return new BasicExpenses(value0);
    };
    return BasicExpenses;
})();
var ElaborateExpenses = (function () {
    function ElaborateExpenses(value0) {
        this.value0 = value0;
    };
    ElaborateExpenses.create = function (value0) {
        return new ElaborateExpenses(value0);
    };
    return ElaborateExpenses;
})();
var validExpenseRequestDetail = new Data_Valid.Valid(function (v) {
    return Data_Valid.isValid(Model_SessionDescription_Expenses_Place.validPlace)(v.travellingFrom) && (Data_Valid.isValid(Model_SessionDescription_Expenses_Place.validPlace)(v.travellingTo) && Data_Valid.isValid(Data_Valid.validString)(v.pleaseExplain));
});
var validExpenses = new Data_Valid.Valid(function (v) {
    if (v instanceof ElaborateExpenses && v.value0 instanceof PleaseChoose) {
        return false;
    };
    if (v instanceof ElaborateExpenses && v.value0 instanceof No) {
        return true;
    };
    if (v instanceof ElaborateExpenses && v.value0 instanceof Yes) {
        return Data_Valid.isValid(validExpenseRequestDetail)(v.value0.value0);
    };
    if (v instanceof BasicExpenses) {
        return true;
    };
    throw new Error("Failed pattern match at Model.Expenses line 139, column 3 - line 139, column 51: " + [ v.constructor.name ]);
});
var updateTravellingTo = function (v) {
    return function (v1) {
        if (v1 instanceof ElaborateExpenses && v1.value0 instanceof Yes) {
            var updated = (function () {
                var $46 = {};
                for (var $47 in v1.value0.value0) {
                    if (v1.value0.value0.hasOwnProperty($47)) {
                        $46[$47] = v1.value0.value0[$47];
                    };
                };
                $46.travellingTo = v(v1.value0.value0.travellingTo);
                return $46;
            })();
            return new ElaborateExpenses(new Yes(updated));
        };
        return v1;
    };
};
var updateTravellingFrom = function (v) {
    return function (v1) {
        if (v1 instanceof ElaborateExpenses && v1.value0 instanceof Yes) {
            var updated = (function () {
                var $52 = {};
                for (var $53 in v1.value0.value0) {
                    if (v1.value0.value0.hasOwnProperty($53)) {
                        $52[$53] = v1.value0.value0[$53];
                    };
                };
                $52.travellingFrom = v(v1.value0.value0.travellingFrom);
                return $52;
            })();
            return new ElaborateExpenses(new Yes(updated));
        };
        return v1;
    };
};
var setExpenseRemark = function (v) {
    return function (v1) {
        if (v1 instanceof ElaborateExpenses && v1.value0 instanceof Yes) {
            var updated = (function () {
                var $58 = {};
                for (var $59 in v1.value0.value0) {
                    if (v1.value0.value0.hasOwnProperty($59)) {
                        $58[$59] = v1.value0.value0[$59];
                    };
                };
                $58.pleaseExplain = v;
                return $58;
            })();
            return new ElaborateExpenses(new Yes(updated));
        };
        return v1;
    };
};
var pleaseChoose = new ElaborateExpenses(PleaseChoose.value);
var paragraphs = function (s) {
    return Data_String.split("\n")(s);
};
var nullExpensesData = PleaseChoose.value;
var nullExpenses = new ElaborateExpenses(nullExpensesData);
var nullExpenseRequestDetail = {
    travellingFrom: Data_Default["default"](Model_SessionDescription_Expenses_Place.defaultPlace), 
    travellingTo: Data_Default["default"](Model_SessionDescription_Expenses_Place.defaultPlace), 
    pleaseExplain: Data_Default["default"](Data_Default.defaultString)
};
var mkExpenseRequestDetail = function (r) {
    return r;
};
var humanReadableExpensesData = new Data_HumanReadable.HumanReadable(function (v) {
    if (v instanceof PleaseChoose) {
        return [ "Please Choose - No choice made yet" ];
    };
    if (v instanceof No) {
        return [ "No" ];
    };
    if (v instanceof Yes) {
        return [ "Yes" ];
    };
    throw new Error("Failed pattern match at Model.Expenses line 255, column 3 - line 255, column 70: " + [ v.constructor.name ]);
});
var humanReadableExpenses = new Data_HumanReadable.HumanReadable(function (v) {
    if (v instanceof BasicExpenses) {
        return paragraphs(v.value0);
    };
    if (v instanceof ElaborateExpenses) {
        return Data_HumanReadable.humanReadable(humanReadableExpensesData)(v.value0);
    };
    throw new Error("Failed pattern match at Model.Expenses line 251, column 3 - line 251, column 51: " + [ v.constructor.name ]);
});
var humanReadableDetail = new Data_HumanReadable.HumanReadable(function (v) {
    var places = Data_Semigroup.append(Data_Semigroup.semigroupArray)([ "Travelling from: " ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_HumanReadable.humanReadable(Model_SessionDescription_Expenses_Place.humanReadablePlace)(v.travellingFrom))(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ "Travelling to: " ])(Data_HumanReadable.humanReadable(Model_SessionDescription_Expenses_Place.humanReadablePlace)(v.travellingTo))));
    var explanationHeading = [ "Detailed Explanation:" ];
    return Data_Semigroup.append(Data_Semigroup.semigroupArray)(places)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(explanationHeading)(paragraphs(v.pleaseExplain)));
});
var getTravellingTo = function (v) {
    return v.travellingTo;
};
var getTravellingFrom = function (v) {
    return v.travellingFrom;
};
var getExpenseRemark = function (v) {
    return v.pleaseExplain;
};
var genericIUnderstandTheConsequences = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.Expenses.PleaseConfirmConsequences" && v.value1.length === 0)) {
        return new Data_Maybe.Just(PleaseConfirmConsequences.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.Expenses.IWillNotClaimLater" && v.value1.length === 0)) {
        return new Data_Maybe.Just(IWillNotClaimLater.value);
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Model.Expenses.IUnderstandTheConsequences", [ {
        sigConstructor: "Model.Expenses.PleaseConfirmConsequences", 
        sigValues: [  ]
    }, {
        sigConstructor: "Model.Expenses.IWillNotClaimLater", 
        sigValues: [  ]
    } ]);
}, function (v) {
    if (v instanceof PleaseConfirmConsequences) {
        return new Data_Generic.SProd("Model.Expenses.PleaseConfirmConsequences", [  ]);
    };
    if (v instanceof IWillNotClaimLater) {
        return new Data_Generic.SProd("Model.Expenses.IWillNotClaimLater", [  ]);
    };
    throw new Error("Failed pattern match at Model.Expenses line 184, column 1 - line 184, column 88: " + [ v.constructor.name ]);
});
var isForeignIUnderstandTheConsequences = new Data_Foreign_Class.IsForeign(Data_Foreign_Generic.readGeneric(genericIUnderstandTheConsequences)(Data_VersionNumber.jsonOptions));
var showIUnderstandTheConsequences = new Data_Show.Show(Data_Generic.gShow(genericIUnderstandTheConsequences));
var toFirebaseForeignIUnderstandTheConsequences = new Data_Firebase_Foreign.ToFirebaseForeign(UReview_Prelude.writeForeign(genericIUnderstandTheConsequences));
var genericExpenseRequestDetail = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.Expenses.ExpenseRequestDetail" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(ExpenseRequestDetail))((function (r) {
            if (r instanceof Data_Generic.SRecord && r.value0.length === 3) {
                return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(function (pleaseExplain1) {
                    return function (travellingFrom1) {
                        return function (travellingTo1) {
                            return {
                                pleaseExplain: pleaseExplain1, 
                                travellingFrom: travellingFrom1, 
                                travellingTo: travellingTo1
                            };
                        };
                    };
                }))(Data_Generic.fromSpine(Data_Generic.genericString)((r.value0[0]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Model_SessionDescription_Expenses_Place.genericPlace)((r.value0[1]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Model_SessionDescription_Expenses_Place.genericPlace)((r.value0[2]).recValue(Data_Unit.unit)));
            };
            return Data_Maybe.Nothing.value;
        })(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Model.Expenses.ExpenseRequestDetail", [ {
        sigConstructor: "Model.Expenses.ExpenseRequestDetail", 
        sigValues: [ function ($dollarq1) {
            return new Data_Generic.SigRecord([ {
                recLabel: "pleaseExplain", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "travellingFrom", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Model_SessionDescription_Expenses_Place.genericPlace)(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "travellingTo", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Model_SessionDescription_Expenses_Place.genericPlace)(Data_Generic.anyProxy);
                }
            } ]);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("Model.Expenses.ExpenseRequestDetail", [ function ($dollarq) {
        return new Data_Generic.SRecord([ {
            recLabel: "pleaseExplain", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_Generic.genericString)(v.pleaseExplain);
            }
        }, {
            recLabel: "travellingFrom", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Model_SessionDescription_Expenses_Place.genericPlace)(v.travellingFrom);
            }
        }, {
            recLabel: "travellingTo", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Model_SessionDescription_Expenses_Place.genericPlace)(v.travellingTo);
            }
        } ]);
    } ]);
});
var isForeignExpenseRequestDetail = new Data_Foreign_Class.IsForeign(function (fo) {
    return Data_Foreign_Generic.readGeneric(genericExpenseRequestDetail)(Data_VersionNumber.jsonOptions)(fo);
});
var showExpenseRequestDetail = new Data_Show.Show(Data_Generic.gShow(genericExpenseRequestDetail));
var toFirebaseForeignExpenseRequestDetail = new Data_Firebase_Foreign.ToFirebaseForeign(UReview_Prelude.writeForeign(genericExpenseRequestDetail));
var genericElaborateExpensesData = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.Expenses.PleaseChoose" && v.value1.length === 0)) {
        return new Data_Maybe.Just(PleaseChoose.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.Expenses.No" && v.value1.length === 0)) {
        return new Data_Maybe.Just(No.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.Expenses.Yes" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Yes.create))(Data_Generic.fromSpine(genericExpenseRequestDetail)(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Model.Expenses.ElaborateExpensesData", [ {
        sigConstructor: "Model.Expenses.PleaseChoose", 
        sigValues: [  ]
    }, {
        sigConstructor: "Model.Expenses.No", 
        sigValues: [  ]
    }, {
        sigConstructor: "Model.Expenses.Yes", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(genericExpenseRequestDetail)(Data_Generic.anyProxy);
        } ]
    } ]);
}, function (v) {
    if (v instanceof PleaseChoose) {
        return new Data_Generic.SProd("Model.Expenses.PleaseChoose", [  ]);
    };
    if (v instanceof No) {
        return new Data_Generic.SProd("Model.Expenses.No", [  ]);
    };
    if (v instanceof Yes) {
        return new Data_Generic.SProd("Model.Expenses.Yes", [ function ($dollarq) {
            return Data_Generic.toSpine(genericExpenseRequestDetail)(v.value0);
        } ]);
    };
    throw new Error("Failed pattern match at Model.Expenses line 163, column 1 - line 163, column 78: " + [ v.constructor.name ]);
});
var genericExpenses = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.Expenses.BasicExpenses" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(BasicExpenses.create))(Data_Generic.fromSpine(Data_Generic.genericString)(v.value1[0](Data_Unit.unit)));
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.Expenses.ElaborateExpenses" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(ElaborateExpenses.create))(Data_Generic.fromSpine(genericElaborateExpensesData)(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Model.Expenses.Expenses", [ {
        sigConstructor: "Model.Expenses.BasicExpenses", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
        } ]
    }, {
        sigConstructor: "Model.Expenses.ElaborateExpenses", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(genericElaborateExpensesData)(Data_Generic.anyProxy);
        } ]
    } ]);
}, function (v) {
    if (v instanceof BasicExpenses) {
        return new Data_Generic.SProd("Model.Expenses.BasicExpenses", [ function ($dollarq) {
            return Data_Generic.toSpine(Data_Generic.genericString)(v.value0);
        } ]);
    };
    if (v instanceof ElaborateExpenses) {
        return new Data_Generic.SProd("Model.Expenses.ElaborateExpenses", [ function ($dollarq) {
            return Data_Generic.toSpine(genericElaborateExpensesData)(v.value0);
        } ]);
    };
    throw new Error("Failed pattern match at Model.Expenses line 120, column 1 - line 120, column 52: " + [ v.constructor.name ]);
});
var isForeignExpenses = new Data_Foreign_Class.IsForeign(function (fo) {
    var readLegacy = Data_Functor.map(Data_Either.functorEither)(BasicExpenses.create)(Data_Foreign_Generic.readGeneric(Data_Generic.genericString)(Data_VersionNumber.jsonOptions)(fo));
    return Control_Alt.alt(Data_Either.altEither)(Data_Foreign_Generic.readGeneric(genericExpenses)(Data_VersionNumber.jsonOptions)(fo))(readLegacy);
});
var showExpenses = new Data_Show.Show(Data_Generic.gShow(genericExpenses));
var toFirebaseForeignExpenses = new Data_Firebase_Foreign.ToFirebaseForeign(UReview_Prelude.writeForeign(genericExpenses));
var isForeignElaborateExpensesData = new Data_Foreign_Class.IsForeign(Data_Foreign_Generic.readGeneric(genericElaborateExpensesData)(Data_VersionNumber.jsonOptions));
var showElaborateExpensesData = new Data_Show.Show(Data_Generic.gShow(genericElaborateExpensesData));
var toFirebaseForeignElaborateExpensesData = new Data_Firebase_Foreign.ToFirebaseForeign(UReview_Prelude.writeForeign(genericElaborateExpensesData));
var genBasicExpenses = Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Test_QuickCheck_Arbitrary.arbString))(function (v) {
    return Data_Function.apply(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity)))(new BasicExpenses(v));
});
var eqIUnderstandTheConsequences = new Data_Eq.Eq(Data_Generic.gEq(genericIUnderstandTheConsequences));
var ordIUnderstandTheConsequences = new Data_Ord.Ord(function () {
    return eqIUnderstandTheConsequences;
}, Data_Generic.gCompare(genericIUnderstandTheConsequences));
var eqExpenses = new Data_Eq.Eq(Data_Generic.gEq(genericExpenses));
var ordExpenses = new Data_Ord.Ord(function () {
    return eqExpenses;
}, Data_Generic.gCompare(genericExpenses));
var eqExpenseRequestDetail = new Data_Eq.Eq(Data_Generic.gEq(genericExpenseRequestDetail));
var ordExpenseRequestDetail = new Data_Ord.Ord(function () {
    return eqExpenseRequestDetail;
}, Data_Generic.gCompare(genericExpenseRequestDetail));
var eqElaborateExpensesData = new Data_Eq.Eq(Data_Generic.gEq(genericElaborateExpensesData));
var ordElaborateExpensesData = new Data_Ord.Ord(function () {
    return eqElaborateExpensesData;
}, Data_Generic.gCompare(genericElaborateExpensesData));
var defaultExpenses = new Data_Default.Default(new ElaborateExpenses(PleaseChoose.value));
var defaultExpenseRequestDetail = new Data_Default.Default(nullExpenseRequestDetail);
var chooseYes = function (v) {
    return new ElaborateExpenses(new Yes(Data_Default["default"](defaultExpenseRequestDetail)));
};
var chooseNo = function (v) {
    return new ElaborateExpenses(No.value);
};
var arbitraryIUnderstandTheConsequences = new Test_QuickCheck_Arbitrary.Arbitrary(Test_QuickCheck_Gen.elements(PleaseConfirmConsequences.value)([ IWillNotClaimLater.value ]));
var arbitraryElaborateExpensesData = new Test_QuickCheck_Arbitrary.Arbitrary(Test_QuickCheck_Gen.oneOf(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity))(PleaseChoose.value))([ Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity))(No.value) ]));
var genElaborateExpenses = Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(arbitraryElaborateExpensesData))(function (v) {
    return Data_Function.apply(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity)))(new ElaborateExpenses(v));
});
var genExpenses = Test_QuickCheck_Gen.oneOf(genBasicExpenses)([ genElaborateExpenses ]);
var arbitraryExpenses = new Test_QuickCheck_Arbitrary.Arbitrary(genExpenses);
var arbExpenseRequestDetail = new Test_QuickCheck_Arbitrary.Arbitrary(Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Model_SessionDescription_Expenses_Place.arbPlace))(function (v) {
    return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Model_SessionDescription_Expenses_Place.arbPlace))(function (v1) {
        return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Test_QuickCheck_Arbitrary.arbString))(function (v2) {
            return Data_Function.apply(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity)))({
                travellingFrom: v, 
                travellingTo: v1, 
                pleaseExplain: v2
            });
        });
    });
}));
module.exports = {
    PleaseChoose: PleaseChoose, 
    No: No, 
    Yes: Yes, 
    ExpenseRequestDetail: ExpenseRequestDetail, 
    BasicExpenses: BasicExpenses, 
    ElaborateExpenses: ElaborateExpenses, 
    chooseNo: chooseNo, 
    chooseYes: chooseYes, 
    getExpenseRemark: getExpenseRemark, 
    getTravellingFrom: getTravellingFrom, 
    getTravellingTo: getTravellingTo, 
    mkExpenseRequestDetail: mkExpenseRequestDetail, 
    nullExpenses: nullExpenses, 
    pleaseChoose: pleaseChoose, 
    setExpenseRemark: setExpenseRemark, 
    updateTravellingFrom: updateTravellingFrom, 
    updateTravellingTo: updateTravellingTo, 
    genericExpenses: genericExpenses, 
    showExpenses: showExpenses, 
    eqExpenses: eqExpenses, 
    ordExpenses: ordExpenses, 
    isForeignExpenses: isForeignExpenses, 
    toFirebaseForeignExpenses: toFirebaseForeignExpenses, 
    validExpenses: validExpenses, 
    defaultExpenses: defaultExpenses, 
    arbitraryExpenses: arbitraryExpenses, 
    genericElaborateExpensesData: genericElaborateExpensesData, 
    showElaborateExpensesData: showElaborateExpensesData, 
    eqElaborateExpensesData: eqElaborateExpensesData, 
    ordElaborateExpensesData: ordElaborateExpensesData, 
    isForeignElaborateExpensesData: isForeignElaborateExpensesData, 
    arbitraryElaborateExpensesData: arbitraryElaborateExpensesData, 
    toFirebaseForeignElaborateExpensesData: toFirebaseForeignElaborateExpensesData, 
    genericExpenseRequestDetail: genericExpenseRequestDetail, 
    showExpenseRequestDetail: showExpenseRequestDetail, 
    eqExpenseRequestDetail: eqExpenseRequestDetail, 
    ordExpenseRequestDetail: ordExpenseRequestDetail, 
    isForeignExpenseRequestDetail: isForeignExpenseRequestDetail, 
    toFirebaseForeignExpenseRequestDetail: toFirebaseForeignExpenseRequestDetail, 
    validExpenseRequestDetail: validExpenseRequestDetail, 
    defaultExpenseRequestDetail: defaultExpenseRequestDetail, 
    arbExpenseRequestDetail: arbExpenseRequestDetail, 
    humanReadableDetail: humanReadableDetail, 
    humanReadableExpenses: humanReadableExpenses, 
    humanReadableExpensesData: humanReadableExpensesData
};
