// Generated by psc version 0.9.1
"use strict";
var Data_String = require("../Data.String");
var HumanReadable = function (humanReadable) {
    this.humanReadable = humanReadable;
};
var paragraphs = function (s) {
    return Data_String.split("\n")(s);
};
var humanReadableString = new HumanReadable(paragraphs);
var humanReadable = function (dict) {
    return dict.humanReadable;
};
module.exports = {
    HumanReadable: HumanReadable, 
    humanReadable: humanReadable, 
    humanReadableString: humanReadableString
};
