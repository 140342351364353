// Generated by psc version 0.9.1
"use strict";
var Data_PathTo = require("../Data.PathTo");
var Model_UserProfile = require("../Model.UserProfile");
var State_DataDependency = require("../State.DataDependency");
var State_UserProfiles = require("../State.UserProfiles");
var WeReview_Data_Schema = require("../WeReview.Data.Schema");
var State_Types = require("../State.Types");
var dependOnRole = function (userKey) {
    var path = function (v) {
        return function (v1) {
            return Data_PathTo.pathTo(WeReview_Data_Schema.pathToWeReviewSchema)(new WeReview_Data_Schema.Roles(userKey, WeReview_Data_Schema.RoleObject.value));
        };
    };
    return new State_Types.Changed("Role", path, State_UserProfiles.applyForeignRole, [  ]);
};
module.exports = {
    dependOnRole: dependOnRole
};
