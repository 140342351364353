// Generated by psc version 0.9.1
"use strict";
var Components_DB_Action = require("../Components.DB.Action");
var Components_GeneratedFileDownload = require("../Components.GeneratedFileDownload");
var View_ReviewerView = require("../View.ReviewerView");
var Components_Elements = require("../Components.Elements");
var Components_Loaded = require("../Components.Loaded");
var Components_ViewAllowed = require("../Components.ViewAllowed");
var Control_Alternative = require("../Control.Alternative");
var Data_Array = require("../Data.Array");
var Data_CommaSeparated = require("../Data.CommaSeparated");
var Data_Eq = require("../Data.Eq");
var Data_Foreign = require("../Data.Foreign");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_Loaded = require("../Data.Loaded");
var Data_Maybe = require("../Data.Maybe");
var Data_MediaType_Common = require("../Data.MediaType.Common");
var Data_Tuple = require("../Data.Tuple");
var Model_Event = require("../Model.Event");
var Model_Event_Key = require("../Model.Event.Key");
var Model_SessionDescription = require("../Model.SessionDescription");
var Prelude = require("../Prelude");
var Pux_Html = require("../Pux.Html");
var Pux_Html_Attributes = require("../Pux.Html.Attributes");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var Route_HtmlElements = require("../Route.HtmlElements");
var State_Event = require("../State.Event");
var State_My = require("../State.My");
var State_SessionDescription = require("../State.SessionDescription");
var State_Types = require("../State.Types");
var UReview_Route_Types = require("../UReview.Route.Types");
var View_Loading = require("../View.Loading");
var WeReview_Data_Schema = require("../WeReview.Data.Schema");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra");
var Components_Elements_Basic_Types = require("../Components.Elements.Basic.Types");
var Data_Semigroup = require("../Data.Semigroup");
var Data_Function = require("../Data.Function");
var Data_Show = require("../Data.Show");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var Data_Functor = require("../Data.Functor");
var Control_Category = require("../Control.Category");
var showSessionDescriptions = function (state) {
    return function (shortCode) {
        return function (sessionDescriptionKeys) {
            return Pux_Html_Elements.div([  ])([ Pux_Html_Elements.h3([  ])([ Pux_Html_Elements.text("Sessions descriptions") ]), Pux_Html_Elements.div([  ])([ Route_HtmlElements.routeToTop(new UReview_Route_Types.HiddenSessions(Data_ForeignKey.mkForeignKey(shortCode)))("view hidden sessions. ") ]), Pux_Html_Elements.div([  ])([ Route_HtmlElements.routeToTop(new UReview_Route_Types.VisibleSessions(Data_ForeignKey.mkForeignKey(shortCode)))("view visible sessions. ") ]) ]);
        };
    };
};
var showReviewerInline = function (v) {
    return function (state) {
        return Pux_Html.withChild(Pux_Html_Elements.div)(Data_Maybe.maybe(Pux_Html_Elements.text("Loading your reviews."))(function (keyValue) {
            return View_ReviewerView.showReviewer(keyValue)(state);
        })(State_Event.findReviewerKey(v)(state)));
    };
};
var rowColumnH2 = function (heading) {
    return function (innerElement) {
        return Pux_Html_Elements.div([ Pux_Html_Attributes.className("row") ])([ Pux_Html_Elements.div([ Pux_Html_Attributes.className("column") ])([ Pux_Html_Elements.h2([  ])([ Pux_Html_Elements.text(heading) ]), Pux_Html_Elements.div([  ])([ innerElement ]) ]) ]);
    };
};
var linkToSessionDescription = function (sdKey) {
    return function (title) {
        return Route_HtmlElements.routeToTop(new UReview_Route_Types.FullSessionDescriptionRoute(sdKey))(title);
    };
};
var sessionDescriptionSummary = function (fk) {
    return function (sd) {
        var s = Model_SessionDescription.runSessionDescription(sd);
        var hideOrShow = function (visible) {
            if (visible) {
                return "Hide";
            };
            if (!visible) {
                return "Show";
            };
            throw new Error("Failed pattern match at View.EventView line 179, column 26 - line 180, column 5: " + [ visible.constructor.name ]);
        };
        var hideAction = (function () {
            var path = new WeReview_Data_Schema.SessionDescriptions(fk, WeReview_Data_Schema.IsVisible.value);
            return new State_Types.DB(new Components_DB_Action.SetValue(path, Data_Foreign.toForeign(!s.isVisible)));
        })();
        var cell = Pux_Html_Elements.td([  ]);
        return Pux_Html_Elements.tr([  ])([ cell([ Components_Elements.createSmallButton(hideAction)(Components_Elements_Basic_Types.mkHtmlId("hide-" + Data_ForeignKey.fkToString(fk)))(hideOrShow(s.isVisible)) ]), cell([ Data_Function.apply(Pux_Html_Elements.text)(Data_Show.show(Data_Show.showInt)(s.sessionid) + " - "), linkToSessionDescription(fk)(s.title) ]), cell([ Pux_Html_Elements.text(s.speakername) ]) ]);
    };
};
var listOfSessionDescriptions = function (state) {
    return function (sessionDescriptionKeys) {
        var l = State_SessionDescription.loadedSessionDescriptions(sessionDescriptionKeys)(state);
        var r = Data_Loaded.runLoaded(l);
        var descriptions = Data_Array.filter(function ($22) {
            return Model_SessionDescription.isVisibleSessionDescription(Data_Tuple.snd($22));
        })(r.presentOrEmpty);
        if (r.allLoaded) {
            return Pux_Html_Elements.table([  ])([ Data_Function.apply(Pux_Html_Elements.tbody([  ]))(Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.uncurry(sessionDescriptionSummary))(descriptions)) ]);
        };
        if (!r.allLoaded) {
            return Pux_Html_Elements.div([  ])([  ]);
        };
        throw new Error("Failed pattern match at View.EventView line 136, column 3 - line 139, column 14: " + [ r.allLoaded.constructor.name ]);
    };
};
var sessionDescriptionRows = function (d) {
    return Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.uncurry(sessionDescriptionSummary))(d);
};
var linkToAllReviews = function (key) {
    return Pux_Html_Elements.p([  ])([ Route_HtmlElements.routeToTop(new UReview_Route_Types.ReviewSummaryRoute(key))("See everyones' reviews"), Pux_Html_Elements.text(" Warning: shows de-anonymized session descriptions and reviews.") ]);
};
var pcMemberOnly = function (key) {
    return function (v) {
        return function (state) {
            return Pux_Html_Elements.div([  ])([ Pux_Html_Elements.h3([  ])([ Pux_Html_Elements.text("Panel Chairs: ") ]), Pux_Html_Elements.div([  ])([ Route_HtmlElements.routeToTop(new UReview_Route_Types.ManageReviewers(key))("Manage reviewers"), linkToAllReviews(key) ]), showSessionDescriptions(state)(v.shortCode)(v.sessionDescriptionKeys) ]);
        };
    };
};
var eventNotFound = View_Loading.loading("Event");
var csvOfSessionDescriptions = function (state) {
    return function (shortCode) {
        return function (sessionDescriptionKeys) {
            var l = State_SessionDescription.loadedSessionDescriptions(sessionDescriptionKeys)(state);
            var r = Data_Loaded.runLoaded(l);
            var fileName = shortCode + "-sessionDescriptions.csv";
            var descriptions = Data_Function.apply(Data_Array.filter(Model_SessionDescription.isVisibleSessionDescription))(Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.snd)(r.present));
            var downloadSpec = Components_GeneratedFileDownload.mkDownloadSpec(Data_MediaType_Common.textCSV)(fileName)(Control_Category.id(Control_Category.categoryFn))(Data_CommaSeparated.csvFromRecords(descriptions));
            var downloadButton = Components_GeneratedFileDownload.mkDownloadButton("Export CSV")(downloadSpec);
            if (r.allLoaded) {
                return Data_Function.apply(Data_Functor.map(Pux_Html_Elements.functorHtml)(State_Types.DownloadEventCSV.create))(Components_GeneratedFileDownload.view(downloadButton));
            };
            if (!r.allLoaded) {
                return Components_Loaded.viewLoadCount("Session Descriptions")(l);
            };
            throw new Error("Failed pattern match at View.EventView line 153, column 3 - line 156, column 43: " + [ r.allLoaded.constructor.name ]);
        };
    };
};
var adminOnly = function (key) {
    return function (v) {
        return function (state) {
            var addPanelChairs = Pux_Html_Elements.p([  ])([ Pux_Html_Elements.text("Assign panel chair role to "), Route_HtmlElements.routeLink(UReview_Route_Types.UsersRoute.value)("a user"), Pux_Html_Elements.text(".") ]);
            return Pux_Html_Elements.div([  ])([ Pux_Html_Elements.h3([  ])([ Pux_Html_Elements.text("Administrators: ") ]), addPanelChairs, Route_HtmlElements.routeToTop(new UReview_Route_Types.DownloadFullCSVRoute(key))("Download CSV") ]);
        };
    };
};
var viewFoundEvent = function (state) {
    return function (key) {
        return function (v) {
            var pcMemberReviews = (function () {
                var $18 = State_Event.isReviewer(v)(state);
                if ($18) {
                    return showReviewerInline(v)(state);
                };
                if (!$18) {
                    return Components_Elements.ptext("Add yourself as reviewer, so that you can do reviews.");
                };
                throw new Error("Failed pattern match at View.EventView line 73, column 7 - line 79, column 1: " + [ $18.constructor.name ]);
            })();
            var authorizedView = (function () {
                var $19 = Components_ViewAllowed.administrator(state);
                if ($19) {
                    return Pux_Html_Elements.div([  ])([ adminOnly(key)(v)(state), pcMemberReviews, pcMemberOnly(key)(v)(state) ]);
                };
                if (!$19) {
                    var $20 = State_My.isPanelChairFor(key)(state);
                    if ($20) {
                        return Pux_Html_Elements.div([  ])([ pcMemberReviews, pcMemberOnly(key)(v)(state) ]);
                    };
                    if (!$20) {
                        var $21 = State_Event.isReviewer(v)(state);
                        if ($21) {
                            return showReviewerInline(v)(state);
                        };
                        if (!$21) {
                            return Components_Elements.ptext("Checking your permissions, please wait.");
                        };
                        throw new Error("Failed pattern match at View.EventView line 68, column 9 - line 72, column 5: " + [ $21.constructor.name ]);
                    };
                    throw new Error("Failed pattern match at View.EventView line 62, column 9 - line 72, column 5: " + [ $20.constructor.name ]);
                };
                throw new Error("Failed pattern match at View.EventView line 55, column 23 - line 72, column 5: " + [ $19.constructor.name ]);
            })();
            return rowColumnH2(v.name)(authorizedView);
        };
    };
};
var eventView = function (state) {
    return function (key) {
        return Pux_Html.withChild(Pux_Html_Elements.section)((function () {
            var mEvent = State_Event.findEvent(key)(state);
            return Data_Maybe.maybe(eventNotFound)(viewFoundEvent(state)(key))(mEvent);
        })());
    };
};
module.exports = {
    eventView: eventView, 
    sessionDescriptionRows: sessionDescriptionRows
};
