// Generated by psc version 0.9.1
"use strict";
var Components_Elements_Basic_Types = require("../Components.Elements.Basic.Types");
var Data_Maybe = require("../Data.Maybe");
var Pux_Html = require("../Pux.Html");
var IdAndLabelFragment = function (x) {
    return x;
};
var mkIdAndLabelFragment = function (htmlIdFragment) {
    return function (labelFragment) {
        return {
            htmlIdFragment: htmlIdFragment, 
            labelFragment: labelFragment
        };
    };
};
module.exports = {
    IdAndLabelFragment: IdAndLabelFragment, 
    mkIdAndLabelFragment: mkIdAndLabelFragment
};
