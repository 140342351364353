// Generated by psc version 0.9.1
"use strict";
var Components_Elements = require("../Components.Elements");
var Data_Firebase_Foreign = require("../Data.Firebase.Foreign");
var Data_Foreign = require("../Data.Foreign");
var Data_Maybe = require("../Data.Maybe");
var Data_Array = require("../Data.Array");
var Data_String = require("../Data.String");
var Model_CallForSessions = require("../Model.CallForSessions");
var Model_OpenCallForSessions_ProposalForm = require("../Model.OpenCallForSessions.ProposalForm");
var Model_OpenCallForSessions_PublicationStatus = require("../Model.OpenCallForSessions.PublicationStatus");
var Model_OpenCallForSessions_UserDefinedFields = require("../Model.OpenCallForSessions.UserDefinedFields");
var Model_Event_Key = require("../Model.Event.Key");
var Model_OpenCallForSessions_Tokens = require("../Model.OpenCallForSessions.Tokens");
var Network_KeyedRemoteData = require("../Network.KeyedRemoteData");
var Prelude = require("../Prelude");
var Pux_Html = require("../Pux.Html");
var Pux_Html_Attributes = require("../Pux.Html.Attributes");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var Pux_Html_Events = require("../Pux.Html.Events");
var State_CallForSessions = require("../State.CallForSessions");
var State_Types = require("../State.Types");
var WeReview_Data_Schema = require("../WeReview.Data.Schema");
var Components_Elements_Basic_Types = require("../Components.Elements.Basic.Types");
var Data_Function = require("../Data.Function");
var Data_Eq = require("../Data.Eq");
var Label = function (x) {
    return x;
};
var toAdvertiseOrNotToAdvertise = function (v) {
    return function (v1) {
        return function (eventKey) {
            return function (currentValue) {
                var selecter = {
                    htmlId: v, 
                    label: v1, 
                    selected: new Data_Maybe.Just(currentValue), 
                    items: Model_OpenCallForSessions_PublicationStatus.allowedPublicationValues, 
                    action: function (pubStatus) {
                        return State_Types.dbActionSetValue(WeReview_Data_Schema.aPublicationStatusSchema(eventKey))(Data_Firebase_Foreign.toFire(Model_OpenCallForSessions_PublicationStatus.toFirebaseForeignPublicationStatus)(pubStatus));
                    }, 
                    renderKey: Model_OpenCallForSessions_PublicationStatus.verbosePublicationStatus, 
                    renderValue: function (v2) {
                        return Data_Function.apply(Pux_Html_Elements.text)(Model_OpenCallForSessions_PublicationStatus.verbosePublicationStatus(v2));
                    }, 
                    stringToActionKey: Model_OpenCallForSessions_PublicationStatus.parsePubStatus
                };
                return Pux_Html_Elements.div([  ])([ Components_Elements.buildSelect([  ])(selecter) ]);
            };
        };
    };
};
var event2ForeignString = function (ev) {
    return Data_Function.apply(Data_Maybe.Just.create)(Data_Foreign.toForeign(ev.target.value));
};
var event2ForeignArray = function (ev) {
    return Data_Function.apply(Data_Maybe.Just.create)(Data_Function.apply(Data_Foreign.toForeign)(Data_Function.apply(Data_Array.filter(function (s) {
        return s !== "";
    }))(Data_String.split("\n")(ev.target.value))));
};
var editPublicationStatus = function (cfS) {
    return toAdvertiseOrNotToAdvertise(Components_Elements_Basic_Types.mkHtmlId("publicationStatus"))("Publication status")(Model_CallForSessions.cfsKey(cfS))(Model_CallForSessions.getPublicationStatus(cfS));
};
var dbActionMaybeSetValue = function (v) {
    return function (v1) {
        if (v1 instanceof Data_Maybe.Nothing) {
            return State_Types.Noop.value;
        };
        if (v1 instanceof Data_Maybe.Just) {
            return State_Types.dbActionSetValue(v)(v1.value0);
        };
        throw new Error("Failed pattern match at View.EditCfS line 142, column 1 - line 142, column 40: " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var longTextQuestion = function (v) {
    return function (v1) {
        return function (currentValue) {
            return function (schemaPath) {
                var doSetStringValue = function (ev) {
                    return dbActionMaybeSetValue(schemaPath)(event2ForeignString(ev));
                };
                return Pux_Html_Elements.div([  ])([ Pux_Html.withChild(Pux_Html.withAttr(Pux_Html_Elements.label)(Pux_Html_Attributes.htmlFor(v)))(Pux_Html_Elements.text(v1)), Pux_Html_Elements.br([  ])([  ]), Pux_Html_Elements.textarea([ Pux_Html_Attributes.id_(v), Pux_Html_Attributes.cols(80), Pux_Html_Attributes.rows(6), Pux_Html_Events.onInput(doSetStringValue), Pux_Html_Attributes.defaultValue(currentValue) ])([  ]) ]);
            };
        };
    };
};
var editInvitation = function (cfS) {
    var key = Model_CallForSessions.cfsKey(cfS);
    return longTextQuestion(Components_Elements_Basic_Types.mkHtmlId(Model_OpenCallForSessions_Tokens.invitationToken))("Invitation text (to appear on top of the session proposal form)")(Model_CallForSessions.invitationText(cfS))(WeReview_Data_Schema.aCfSInvitationSchema(key));
};
var chooseProposalForm = function (v) {
    return function (v1) {
        return function (eventKey) {
            return function (currentValue) {
                var selecter = {
                    htmlId: v, 
                    label: v1, 
                    selected: new Data_Maybe.Just(currentValue), 
                    items: [ Model_OpenCallForSessions_ProposalForm.InPerson.value, Model_OpenCallForSessions_ProposalForm.Online.value ], 
                    action: function (chosen) {
                        return State_Types.dbActionSetValue(WeReview_Data_Schema.aProposalFormSchema(eventKey))(Data_Firebase_Foreign.toFire(Model_OpenCallForSessions_ProposalForm.toFirebaseForeignProposalForm)(chosen));
                    }, 
                    renderKey: Model_OpenCallForSessions_ProposalForm.verboseProposalForm, 
                    renderValue: function (v2) {
                        return Data_Function.apply(Pux_Html_Elements.text)(Model_OpenCallForSessions_ProposalForm.verboseProposalForm(v2));
                    }, 
                    stringToActionKey: Model_OpenCallForSessions_ProposalForm.parseProposalForm
                };
                return Pux_Html_Elements.div([  ])([ Components_Elements.buildSelect([  ])(selecter) ]);
            };
        };
    };
};
var chooseProposalForm$prime = function (cfS) {
    return chooseProposalForm(Components_Elements_Basic_Types.mkHtmlId("proposalForm"))("Form to use for session proposal")(Model_CallForSessions.cfsKey(cfS))(Model_CallForSessions.getProposalForm(cfS));
};
var arrayTextArea = function (v) {
    return function (v1) {
        return function (currentValue) {
            return function (schemaPath) {
                var doSetArrayValue = function (ev) {
                    return dbActionMaybeSetValue(schemaPath)(event2ForeignArray(ev));
                };
                return Pux_Html_Elements.div([  ])([ Pux_Html.withChild(Pux_Html.withAttr(Pux_Html_Elements.label)(Pux_Html_Attributes.htmlFor(v)))(Pux_Html_Elements.text(v1)), Pux_Html_Elements.br([  ])([  ]), Pux_Html_Elements.textarea([ Pux_Html_Attributes.id_(v), Pux_Html_Attributes.cols(80), Pux_Html_Attributes.rows(6), Pux_Html_Events.onInput(doSetArrayValue), Pux_Html_Attributes.defaultValue(currentValue) ])([  ]) ]);
            };
        };
    };
};
var editThemes = function (v) {
    return arrayTextArea(Components_Elements_Basic_Types.mkHtmlId("example-themes"))("Example themes (to appear near to themes field in proposal form)")(Model_OpenCallForSessions_UserDefinedFields.displayEditExampleThemes(v.adaptedFields))(WeReview_Data_Schema.aCfSThemesSchema(v.eventId));
};
var editFoundCfs = function (cfS) {
    var editCallForSessions = Pux_Html_Elements.form([  ])([ Pux_Html_Elements.fieldset([  ])([ editPublicationStatus(cfS), chooseProposalForm$prime(cfS), editInvitation(cfS), editThemes(cfS) ]) ]);
    var theHtml = Pux_Html_Elements.section([ Pux_Html_Attributes.className("session-submit") ])([ Pux_Html_Elements.div([ Pux_Html_Attributes.className("row") ])([ Pux_Html_Elements.div([ Pux_Html_Attributes.className("small-12 medium-10 large-9 column") ])([ Data_Function.apply(Pux_Html_Elements.text)(Model_CallForSessions.eventNameStr(cfS)) ]) ]), Pux_Html_Elements.div([ Pux_Html_Attributes.className("row") ])([ Pux_Html_Elements.div([ Pux_Html_Attributes.className("small-12 medium-10 large-9 column") ])([ editCallForSessions ]) ]) ]);
    return theHtml;
};
var editCfS = function (s) {
    return function (k) {
        var loadingCall = State_CallForSessions.findOpenCallForSessions(k)(s);
        if (loadingCall instanceof Network_KeyedRemoteData.Success) {
            return editFoundCfs(loadingCall.value0);
        };
        return Data_Function.apply(Components_Elements.ptext)("Loading call for sessions (if this takes long, it may not exist)");
    };
};
module.exports = {
    editCfS: editCfS
};
