// Generated by psc version 0.9.1
"use strict";
var Data_String = require("../Data.String");
var Prelude = require("../Prelude");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra");
var Valid = function (isValid) {
    this.isValid = isValid;
};
var validString = new Valid(function (s) {
    return Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String["null"])(s);
});
var isValid = function (dict) {
    return dict.isValid;
};
module.exports = {
    Valid: Valid, 
    isValid: isValid, 
    validString: validString
};
