// Generated by psc version 0.9.1
"use strict";
var State_Types = require("../State.Types");
var Data_Either = require("../Data.Either");
var noopApply = function (v) {
    return function (state) {
        return new Data_Either.Right(state);
    };
};
module.exports = {
    noopApply: noopApply
};
