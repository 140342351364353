// Generated by psc version 0.9.1
"use strict";
var Components_DB_Action = require("../Components.DB.Action");
var Components_GeneratedFileDownload = require("../Components.GeneratedFileDownload");
var Components_ManageReviewPanel_Types = require("../Components.ManageReviewPanel.Types");
var Components_NewSessionDescription_Action = require("../Components.NewSessionDescription.Action");
var Components_NewSessionDescription_State = require("../Components.NewSessionDescription.State");
var Data_StrMap = require("../Data.StrMap");
var Control_Monad_Aff = require("../Control.Monad.Aff");
var Control_Monad_Eff = require("../Control.Monad.Eff");
var Control_Monad_Eff_Console = require("../Control.Monad.Eff.Console");
var DOM = require("../DOM");
var Data_Either = require("../Data.Either");
var Data_Firebase_Subscription = require("../Data.Firebase.Subscription");
var Data_Foreign = require("../Data.Foreign");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_ForeignLocation = require("../Data.ForeignLocation");
var Data_Generic = require("../Data.Generic");
var Data_Maybe = require("../Data.Maybe");
var Data_Monoid = require("../Data.Monoid");
var Data_Tuple = require("../Data.Tuple");
var Model_OpenCallsForSessions = require("../Model.OpenCallsForSessions");
var Model_Connection = require("../Model.Connection");
var Model_Event = require("../Model.Event");
var Model_Event_Key = require("../Model.Event.Key");
var Model_GlobalRole = require("../Model.GlobalRole");
var Model_My = require("../Model.My");
var Model_Review = require("../Model.Review");
var Model_SessionDescription = require("../Model.SessionDescription");
var Model_SpaLikeReviewVersion = require("../Model.SpaLikeReviewVersion");
var Model_UserProfile = require("../Model.UserProfile");
var Network_RemoteData = require("../Network.RemoteData");
var PanelChair = require("../PanelChair");
var Ports_Firebase_Types = require("../Ports.Firebase.Types");
var Prelude = require("../Prelude");
var Pux = require("../Pux");
var Pux_Html_Events = require("../Pux.Html.Events");
var Signal_Channel = require("../Signal.Channel");
var State_CurrentMockup = require("../State.CurrentMockup");
var UReview_Route_Types = require("../UReview.Route.Types");
var WeReview_Data_Schema = require("../WeReview.Data.Schema");
var Web_Firebase_Channel = require("../Web.Firebase.Channel");
var Data_Show = require("../Data.Show");
var Data_Semigroup = require("../Data.Semigroup");
var Data_Eq = require("../Data.Eq");
var Control_Apply = require("../Control.Apply");
var Data_Unit = require("../Data.Unit");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra");
var Web_Firebase = require("../Web.Firebase");
var Control_Applicative = require("../Control.Applicative");
var SuccessNotification = (function () {
    function SuccessNotification() {

    };
    SuccessNotification.value = new SuccessNotification();
    return SuccessNotification;
})();
var StatusNotification = (function () {
    function StatusNotification() {

    };
    StatusNotification.value = new StatusNotification();
    return StatusNotification;
})();
var WarningNotification = (function () {
    function WarningNotification() {

    };
    WarningNotification.value = new WarningNotification();
    return WarningNotification;
})();
var ErrorNotification = (function () {
    function ErrorNotification() {

    };
    ErrorNotification.value = new ErrorNotification();
    return ErrorNotification;
})();
var UserNotification = function (x) {
    return x;
};
var NavigateTo = (function () {
    function NavigateTo(value0) {
        this.value0 = value0;
    };
    NavigateTo.create = function (value0) {
        return new NavigateTo(value0);
    };
    return NavigateTo;
})();
var PageView = (function () {
    function PageView(value0) {
        this.value0 = value0;
    };
    PageView.create = function (value0) {
        return new PageView(value0);
    };
    return PageView;
})();
var AssignReviewer = (function () {
    function AssignReviewer(value0) {
        this.value0 = value0;
    };
    AssignReviewer.create = function (value0) {
        return new AssignReviewer(value0);
    };
    return AssignReviewer;
})();
var SetPathValue = (function () {
    function SetPathValue(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    SetPathValue.create = function (value0) {
        return function (value1) {
            return new SetPathValue(value0, value1);
        };
    };
    return SetPathValue;
})();
var DB = (function () {
    function DB(value0) {
        this.value0 = value0;
    };
    DB.create = function (value0) {
        return new DB(value0);
    };
    return DB;
})();
var SetPathEvent = (function () {
    function SetPathEvent(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    SetPathEvent.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new SetPathEvent(value0, value1, value2);
            };
        };
    };
    return SetPathEvent;
})();
var DoChildAdded = (function () {
    function DoChildAdded(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    DoChildAdded.create = function (value0) {
        return function (value1) {
            return new DoChildAdded(value0, value1);
        };
    };
    return DoChildAdded;
})();
var LoadedSessionDescription = (function () {
    function LoadedSessionDescription(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    LoadedSessionDescription.create = function (value0) {
        return function (value1) {
            return new LoadedSessionDescription(value0, value1);
        };
    };
    return LoadedSessionDescription;
})();
var ChangedAddEventText = (function () {
    function ChangedAddEventText(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ChangedAddEventText.create = function (value0) {
        return function (value1) {
            return new ChangedAddEventText(value0, value1);
        };
    };
    return ChangedAddEventText;
})();
var ChangeState = (function () {
    function ChangeState(value0) {
        this.value0 = value0;
    };
    ChangeState.create = function (value0) {
        return new ChangeState(value0);
    };
    return ChangeState;
})();
var DownloadEventCSV = (function () {
    function DownloadEventCSV(value0) {
        this.value0 = value0;
    };
    DownloadEventCSV.create = function (value0) {
        return new DownloadEventCSV(value0);
    };
    return DownloadEventCSV;
})();
var ChooseReviewerToAdd = (function () {
    function ChooseReviewerToAdd(value0) {
        this.value0 = value0;
    };
    ChooseReviewerToAdd.create = function (value0) {
        return new ChooseReviewerToAdd(value0);
    };
    return ChooseReviewerToAdd;
})();
var ChoosePanelChairToAdd = (function () {
    function ChoosePanelChairToAdd(value0) {
        this.value0 = value0;
    };
    ChoosePanelChairToAdd.create = function (value0) {
        return new ChoosePanelChairToAdd(value0);
    };
    return ChoosePanelChairToAdd;
})();
var TogglePanelChairActivation = (function () {
    function TogglePanelChairActivation(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    TogglePanelChairActivation.create = function (value0) {
        return function (value1) {
            return new TogglePanelChairActivation(value0, value1);
        };
    };
    return TogglePanelChairActivation;
})();
var PerformEff = (function () {
    function PerformEff(value0) {
        this.value0 = value0;
    };
    PerformEff.create = function (value0) {
        return new PerformEff(value0);
    };
    return PerformEff;
})();
var PerformAff = (function () {
    function PerformAff(value0) {
        this.value0 = value0;
    };
    PerformAff.create = function (value0) {
        return new PerformAff(value0);
    };
    return PerformAff;
})();
var PerformAffEvent = (function () {
    function PerformAffEvent(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    PerformAffEvent.create = function (value0) {
        return function (value1) {
            return new PerformAffEvent(value0, value1);
        };
    };
    return PerformAffEvent;
})();
var Subscribed = (function () {
    function Subscribed(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Subscribed.create = function (value0) {
        return function (value1) {
            return new Subscribed(value0, value1);
        };
    };
    return Subscribed;
})();
var AddEvent = (function () {
    function AddEvent() {

    };
    AddEvent.value = new AddEvent();
    return AddEvent;
})();
var AuthenticateGoogle = (function () {
    function AuthenticateGoogle() {

    };
    AuthenticateGoogle.value = new AuthenticateGoogle();
    return AuthenticateGoogle;
})();
var Authenticated = (function () {
    function Authenticated(value0) {
        this.value0 = value0;
    };
    Authenticated.create = function (value0) {
        return new Authenticated(value0);
    };
    return Authenticated;
})();
var UnAuthenticated = (function () {
    function UnAuthenticated() {

    };
    UnAuthenticated.value = new UnAuthenticated();
    return UnAuthenticated;
})();
var RolesChanged = (function () {
    function RolesChanged(value0) {
        this.value0 = value0;
    };
    RolesChanged.create = function (value0) {
        return new RolesChanged(value0);
    };
    return RolesChanged;
})();
var NotifyUser = (function () {
    function NotifyUser(value0) {
        this.value0 = value0;
    };
    NotifyUser.create = function (value0) {
        return new NotifyUser(value0);
    };
    return NotifyUser;
})();
var Noop = (function () {
    function Noop() {

    };
    Noop.value = new Noop();
    return Noop;
})();
var UpdateMockup = (function () {
    function UpdateMockup(value0) {
        this.value0 = value0;
    };
    UpdateMockup.create = function (value0) {
        return new UpdateMockup(value0);
    };
    return UpdateMockup;
})();
var ChildAddedSuccess = function (x) {
    return x;
};
var Added = (function () {
    function Added(value0, value1, value2, value3) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
        this.value3 = value3;
    };
    Added.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return function (value3) {
                    return new Added(value0, value1, value2, value3);
                };
            };
        };
    };
    return Added;
})();
var Changed = (function () {
    function Changed(value0, value1, value2, value3) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
        this.value3 = value3;
    };
    Changed.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return function (value3) {
                    return new Changed(value0, value1, value2, value3);
                };
            };
        };
    };
    return Changed;
})();
var UIState = function (x) {
    return x;
};
var userNotificationLevel = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "State.Types.SuccessNotification" && v.value1.length === 0)) {
        return new Data_Maybe.Just(SuccessNotification.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "State.Types.StatusNotification" && v.value1.length === 0)) {
        return new Data_Maybe.Just(StatusNotification.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "State.Types.WarningNotification" && v.value1.length === 0)) {
        return new Data_Maybe.Just(WarningNotification.value);
    };
    if (v instanceof Data_Generic.SProd && (v.value0 === "State.Types.ErrorNotification" && v.value1.length === 0)) {
        return new Data_Maybe.Just(ErrorNotification.value);
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("State.Types.UserNotificationLevel", [ {
        sigConstructor: "State.Types.SuccessNotification", 
        sigValues: [  ]
    }, {
        sigConstructor: "State.Types.StatusNotification", 
        sigValues: [  ]
    }, {
        sigConstructor: "State.Types.WarningNotification", 
        sigValues: [  ]
    }, {
        sigConstructor: "State.Types.ErrorNotification", 
        sigValues: [  ]
    } ]);
}, function (v) {
    if (v instanceof SuccessNotification) {
        return new Data_Generic.SProd("State.Types.SuccessNotification", [  ]);
    };
    if (v instanceof StatusNotification) {
        return new Data_Generic.SProd("State.Types.StatusNotification", [  ]);
    };
    if (v instanceof WarningNotification) {
        return new Data_Generic.SProd("State.Types.WarningNotification", [  ]);
    };
    if (v instanceof ErrorNotification) {
        return new Data_Generic.SProd("State.Types.ErrorNotification", [  ]);
    };
    throw new Error("Failed pattern match at State.Types line 187, column 1 - line 187, column 71: " + [ v.constructor.name ]);
});
var userNotification = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "State.Types.UserNotification" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(UserNotification))((function (r) {
            if (r instanceof Data_Generic.SRecord && r.value0.length === 2) {
                return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(function (level1) {
                    return function (message1) {
                        return {
                            level: level1, 
                            message: message1
                        };
                    };
                }))(Data_Generic.fromSpine(userNotificationLevel)((r.value0[0]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Data_Generic.genericString)((r.value0[1]).recValue(Data_Unit.unit)));
            };
            return Data_Maybe.Nothing.value;
        })(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("State.Types.UserNotification", [ {
        sigConstructor: "State.Types.UserNotification", 
        sigValues: [ function ($dollarq1) {
            return new Data_Generic.SigRecord([ {
                recLabel: "level", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(userNotificationLevel)(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "message", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
                }
            } ]);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("State.Types.UserNotification", [ function ($dollarq) {
        return new Data_Generic.SRecord([ {
            recLabel: "level", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(userNotificationLevel)(v.level);
            }
        }, {
            recLabel: "message", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_Generic.genericString)(v.message);
            }
        } ]);
    } ]);
});
var showUserNotificationLevel = new Data_Show.Show(Data_Generic.gShow(userNotificationLevel));
var showUserNotification = new Data_Show.Show(Data_Generic.gShow(userNotification));
var showAction = new Data_Show.Show(function (a) {
    if (a instanceof NavigateTo) {
        return "NavigateTo " + Data_Show.show(UReview_Route_Types.showRoute)(a.value0);
    };
    if (a instanceof PageView) {
        return "PageView " + Data_Show.show(UReview_Route_Types.showRoute)(a.value0);
    };
    if (a instanceof AssignReviewer) {
        return "AssignReviewer" + Data_Show.show(Model_Review.showReview)(a.value0);
    };
    if (a instanceof SetPathValue) {
        return "SetPathValue" + a.value0;
    };
    if (a instanceof DB) {
        return "DB." + Data_Show.show(Components_DB_Action.showAction)(a.value0);
    };
    if (a instanceof SetPathEvent) {
        return "SetPathEvent " + a.value0;
    };
    if (a instanceof DoChildAdded) {
        return "DoChildAdded ";
    };
    if (a instanceof LoadedSessionDescription) {
        return "LoadedSessionDescription";
    };
    if (a instanceof ChangedAddEventText) {
        return "ChangedAddEventText";
    };
    if (a instanceof ChangeState) {
        return "ChangeState";
    };
    if (a instanceof DownloadEventCSV) {
        return "DownloadEventCSV";
    };
    if (a instanceof ChooseReviewerToAdd) {
        return "ChooseReviewerToAdd";
    };
    if (a instanceof ChoosePanelChairToAdd) {
        return "ChooseReviewerToAdd";
    };
    if (a instanceof TogglePanelChairActivation) {
        return "TogglePanelChairActivation" + Data_Show.show(Data_ForeignKey.showForeignKey)(a.value0);
    };
    if (a instanceof PerformEff) {
        return "PerformEff";
    };
    if (a instanceof PerformAff) {
        return "PerformAff";
    };
    if (a instanceof PerformAffEvent) {
        return "PerformAffEvent";
    };
    if (a instanceof Subscribed) {
        return "Subscribed " + (a.value0 + (" " + Data_Show.show(Web_Firebase.show4EventType)(a.value1)));
    };
    if (a instanceof AddEvent) {
        return "AddEvent";
    };
    if (a instanceof AuthenticateGoogle) {
        return "AuthenticateGoogle";
    };
    if (a instanceof Authenticated) {
        return "Authenticated " + Data_Show.show(Model_UserProfile.showUserProfile)(a.value0);
    };
    if (a instanceof UnAuthenticated) {
        return "UnAuthenticated";
    };
    if (a instanceof RolesChanged) {
        return "RolesChanged " + Data_Show.show(Model_GlobalRole.showGlobalRole)(a.value0);
    };
    if (a instanceof NotifyUser) {
        return "NotifyUser " + Data_Show.show(showUserNotification)(a.value0);
    };
    if (a instanceof Noop) {
        return "Noop";
    };
    if (a instanceof UpdateMockup) {
        return "UpdateMockup";
    };
    throw new Error("Failed pattern match at State.Types line 84, column 12 - line 113, column 1: " + [ a.constructor.name ]);
});
var nullStateChanger = function (v) {
    return function (state) {
        return new Data_Either.Right(state);
    };
};
var nullNotification = {
    level: StatusNotification.value, 
    message: ""
};
var nullFirebaseWriter = function (v) {
    return Control_Applicative.pure(Control_Monad_Eff.applicativeEff)(Data_Unit.unit);
};
var noop = function (s) {
    return {
        state: s, 
        effects: [  ]
    };
};
var initialState = function (rootRef) {
    return function (channel) {
        return {
            route: UReview_Route_Types.Home.value, 
            connection: Model_Connection.mkConnected, 
            counter: 0, 
            events: Data_StrMap.empty, 
            openCalls: Model_OpenCallsForSessions.empty, 
            sessionDescriptions: Data_StrMap.empty, 
            reviews: Data_StrMap.empty, 
            newEvent: Model_Event.nullEvent, 
            newSessionDescription: Components_NewSessionDescription_State.init(Model_SessionDescription.nullSessionDescription), 
            current: {
                event: new Data_Tuple.Tuple(Data_Maybe.Nothing.value, Network_RemoteData.NotAsked.value)
            }, 
            "new": {
                reviewerFor: Components_ManageReviewPanel_Types.nothing
            }, 
            notification: nullNotification, 
            userProfile: Network_RemoteData.NotAsked.value, 
            my: Model_My.nullMy, 
            allUsers: Data_Monoid.mempty(Data_Monoid.monoidArray), 
            authHandlerPresent: false, 
            firebaseSubscriptions: Data_Firebase_Subscription.emptySubscription, 
            rootRef: rootRef, 
            channel: channel, 
            mockup: State_CurrentMockup["null"]
        };
    };
};
var eqUserNotificationLevel = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof SuccessNotification && y instanceof SuccessNotification) {
            return true;
        };
        if (x instanceof StatusNotification && y instanceof StatusNotification) {
            return true;
        };
        if (x instanceof WarningNotification && y instanceof WarningNotification) {
            return true;
        };
        if (x instanceof ErrorNotification && y instanceof ErrorNotification) {
            return true;
        };
        return false;
    };
});
var eqUserNotification = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(eqUserNotificationLevel)(x.level)(y.level) && x.message === y.message;
    };
});
var eqAction = new Data_Eq.Eq(function (v) {
    return function (v1) {
        if (v instanceof Noop && v1 instanceof Noop) {
            return true;
        };
        if (v instanceof NotifyUser && v1 instanceof NotifyUser) {
            return Data_Eq.eq(eqUserNotification)(v.value0)(v1.value0);
        };
        return false;
    };
});
var dbActionSetValue = function (s) {
    return function (f) {
        return new DB(new Components_DB_Action.SetValue(s, f));
    };
};
module.exports = {
    NavigateTo: NavigateTo, 
    PageView: PageView, 
    AssignReviewer: AssignReviewer, 
    SetPathValue: SetPathValue, 
    DB: DB, 
    SetPathEvent: SetPathEvent, 
    DoChildAdded: DoChildAdded, 
    LoadedSessionDescription: LoadedSessionDescription, 
    ChangedAddEventText: ChangedAddEventText, 
    ChangeState: ChangeState, 
    DownloadEventCSV: DownloadEventCSV, 
    ChooseReviewerToAdd: ChooseReviewerToAdd, 
    ChoosePanelChairToAdd: ChoosePanelChairToAdd, 
    TogglePanelChairActivation: TogglePanelChairActivation, 
    PerformEff: PerformEff, 
    PerformAff: PerformAff, 
    PerformAffEvent: PerformAffEvent, 
    Subscribed: Subscribed, 
    AddEvent: AddEvent, 
    AuthenticateGoogle: AuthenticateGoogle, 
    Authenticated: Authenticated, 
    UnAuthenticated: UnAuthenticated, 
    RolesChanged: RolesChanged, 
    NotifyUser: NotifyUser, 
    Noop: Noop, 
    UpdateMockup: UpdateMockup, 
    ChildAddedSuccess: ChildAddedSuccess, 
    Added: Added, 
    Changed: Changed, 
    UIState: UIState, 
    UserNotification: UserNotification, 
    SuccessNotification: SuccessNotification, 
    StatusNotification: StatusNotification, 
    WarningNotification: WarningNotification, 
    ErrorNotification: ErrorNotification, 
    dbActionSetValue: dbActionSetValue, 
    initialState: initialState, 
    noop: noop, 
    nullFirebaseWriter: nullFirebaseWriter, 
    nullNotification: nullNotification, 
    nullStateChanger: nullStateChanger, 
    showAction: showAction, 
    eqAction: eqAction, 
    userNotificationLevel: userNotificationLevel, 
    showUserNotificationLevel: showUserNotificationLevel, 
    eqUserNotificationLevel: eqUserNotificationLevel, 
    userNotification: userNotification, 
    showUserNotification: showUserNotification, 
    eqUserNotification: eqUserNotification
};
