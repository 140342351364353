// Generated by psc version 0.9.1
"use strict";
var Data_Array = require("../Data.Array");
var Data_Firebase_Foreign = require("../Data.Firebase.Foreign");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var Data_Foreign_Generic = require("../Data.Foreign.Generic");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_Generic = require("../Data.Generic");
var Data_VersionNumber = require("../Data.VersionNumber");
var Model_Event_Key = require("../Model.Event.Key");
var Model_GlobalRole_1 = require("../Model.GlobalRole");
var Model_GlobalRole_1 = require("../Model.GlobalRole");
var Prelude = require("../Prelude");
var Test_QuickCheck_Arbitrary = require("../Test.QuickCheck.Arbitrary");
var UReview_Prelude = require("../UReview.Prelude");
var Control_Apply = require("../Control.Apply");
var Data_Maybe = require("../Data.Maybe");
var Data_Unit = require("../Data.Unit");
var Data_Show = require("../Data.Show");
var Data_Eq = require("../Data.Eq");
var Control_Bind = require("../Control.Bind");
var Data_Function = require("../Data.Function");
var Control_Applicative = require("../Control.Applicative");
var Data_Semigroup = require("../Data.Semigroup");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans");
var Data_Identity = require("../Data.Identity");
var My = function (x) {
    return x;
};
var unMy = function (v) {
    return v;
};
var setGlobalRole = function (r) {
    return function (v) {
        return {
            globalRole: r
        };
    };
};
var reviewerFor = function (v) {
    return (Model_GlobalRole_1.unGlobalRole(v.globalRole)).reviewerFor;
};
var playsRoleInEvents = function (v) {
    return Data_Function.apply(Data_Array.nub(Data_ForeignKey.eqForeignKey))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(Model_GlobalRole_1.reviewerForEventIds(v.globalRole))(Model_GlobalRole_1.getPanelChairFor(v.globalRole)));
};
var panelChairFor = function (v) {
    return Model_GlobalRole_1.getPanelChairFor(v.globalRole);
};
var nullMy = {
    globalRole: Model_GlobalRole_1.nullGlobalRole
};
var mkMy = function (role) {
    return {
        globalRole: role
    };
};
var isPanelChairFor = function (fk) {
    return function (v) {
        return Model_GlobalRole_1.isPanelChairFor(fk)(v.globalRole);
    };
};
var isAdmin = function (v) {
    return Model_GlobalRole_1.isAdmin(v.globalRole);
};
var genericMy = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.My.My" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(My))((function (r) {
            if (r instanceof Data_Generic.SRecord && r.value0.length === 1) {
                return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(function (globalRole1) {
                    return {
                        globalRole: globalRole1
                    };
                }))(Data_Generic.fromSpine(Model_GlobalRole_1.genericGlobalRole)((r.value0[0]).recValue(Data_Unit.unit)));
            };
            return Data_Maybe.Nothing.value;
        })(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Model.My.My", [ {
        sigConstructor: "Model.My.My", 
        sigValues: [ function ($dollarq1) {
            return new Data_Generic.SigRecord([ {
                recLabel: "globalRole", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Model_GlobalRole_1.genericGlobalRole)(Data_Generic.anyProxy);
                }
            } ]);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("Model.My.My", [ function ($dollarq) {
        return new Data_Generic.SRecord([ {
            recLabel: "globalRole", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Model_GlobalRole_1.genericGlobalRole)(v.globalRole);
            }
        } ]);
    } ]);
});
var isForeignMy = new Data_Foreign_Class.IsForeign(Data_Foreign_Generic.readGeneric(genericMy)(Data_VersionNumber.jsonOptions));
var showMy = new Data_Show.Show(Data_Generic.gShow(genericMy));
var toFirebaseForeignMy = new Data_Firebase_Foreign.ToFirebaseForeign(UReview_Prelude.writeForeign(genericMy));
var eqMy = new Data_Eq.Eq(Data_Generic.gEq(genericMy));
var arbitraryMy = new Test_QuickCheck_Arbitrary.Arbitrary(Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Model_GlobalRole_1.arbitraryGlobalRole))(function (v) {
    return Data_Function.apply(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity)))({
        globalRole: v
    });
}));
module.exports = {
    My: My, 
    isAdmin: isAdmin, 
    isPanelChairFor: isPanelChairFor, 
    mkMy: mkMy, 
    nullMy: nullMy, 
    panelChairFor: panelChairFor, 
    playsRoleInEvents: playsRoleInEvents, 
    reviewerFor: reviewerFor, 
    setGlobalRole: setGlobalRole, 
    unMy: unMy, 
    genericMy: genericMy, 
    showMy: showMy, 
    eqMy: eqMy, 
    isForeignMy: isForeignMy, 
    arbitraryMy: arbitraryMy, 
    toFirebaseForeignMy: toFirebaseForeignMy
};
