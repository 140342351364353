// Generated by psc version 0.9.1
"use strict";
var Data_Foldable = require("../Data.Foldable");
var Data_Foreign = require("../Data.Foreign");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_List = require("../Data.List");
var Data_Show = require("../Data.Show");
var Data_Tuple = require("../Data.Tuple");
var Prelude = require("../Prelude");
var WeReview_Data_Schema = require("../WeReview.Data.Schema");
var Data_Semigroup = require("../Data.Semigroup");
var Data_PathTo = require("../Data.PathTo");
var Data_Function = require("../Data.Function");
var Data_Functor = require("../Data.Functor");
var Data_Monoid = require("../Data.Monoid");
var SetValue = (function () {
    function SetValue(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    SetValue.create = function (value0) {
        return function (value1) {
            return new SetValue(value0, value1);
        };
    };
    return SetValue;
})();
var SetValues = (function () {
    function SetValues(value0) {
        this.value0 = value0;
    };
    SetValues.create = function (value0) {
        return new SetValues(value0);
    };
    return SetValues;
})();
var Push = (function () {
    function Push(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Push.create = function (value0) {
        return function (value1) {
            return new Push(value0, value1);
        };
    };
    return Push;
})();
var PushChild = (function () {
    function PushChild(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    PushChild.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new PushChild(value0, value1, value2);
            };
        };
    };
    return PushChild;
})();
var pathStrings = function (xs) {
    return Data_Functor.map(Data_List.functorList)(function (t) {
        return Data_PathTo.pathTo(WeReview_Data_Schema.pathToWeReviewSchema)(Data_Tuple.fst(t));
    })(xs);
};
var showAction = new Data_Show.Show(function (a) {
    if (a instanceof SetValue) {
        return "SetValue " + Data_PathTo.pathTo(WeReview_Data_Schema.pathToWeReviewSchema)(a.value0);
    };
    if (a instanceof SetValues) {
        return "SetValues " + Data_Function.apply(Data_Foldable.intercalate(Data_List.foldableList)(Data_Monoid.monoidString)(" | "))(pathStrings(a.value0));
    };
    if (a instanceof Push) {
        return "Push " + Data_PathTo.pathTo(WeReview_Data_Schema.pathToWeReviewSchema)(a.value0);
    };
    if (a instanceof PushChild) {
        return "PushChild " + Data_PathTo.pathTo(WeReview_Data_Schema.pathToWeReviewSchema)(a.value0);
    };
    throw new Error("Failed pattern match at Components.DB.Action line 21, column 12 - line 25, column 68: " + [ a.constructor.name ]);
});
module.exports = {
    SetValue: SetValue, 
    SetValues: SetValues, 
    Push: Push, 
    PushChild: PushChild, 
    showAction: showAction
};
