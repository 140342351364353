// Generated by psc version 0.9.1
"use strict";
var Prelude = require("../Prelude");
var Control_Monad_Eff = require("../Control.Monad.Eff");
var Control_Monad_Eff_Random = require("../Control.Monad.Eff.Random");
var Data_Int = require("../Data.Int");
var Data_Maybe = require("../Data.Maybe");
var $$Math = require("../Math");
var Partial_Unsafe = require("../Partial.Unsafe");
var Data_Show = require("../Data.Show");
var Data_Semigroup = require("../Data.Semigroup");
var Data_Eq = require("../Data.Eq");
var Data_Function = require("../Data.Function");
var Data_Semiring = require("../Data.Semiring");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var Data_Ring = require("../Data.Ring");
var Data_EuclideanRing = require("../Data.EuclideanRing");
var Data_Functor = require("../Data.Functor");
var Seed = function (x) {
    return x;
};
var showSeed = new Data_Show.Show(function (v) {
    return "Seed " + Data_Show.show(Data_Show.showInt)(v);
});
var seedMin = 1;
var runSeed = function (v) {
    return v;
};
var lcgN = 2147483647;
var lcgM = 48271;
var lcgPerturb = function (d) {
    var go = function (n) {
        return Data_Function.apply(Partial_Unsafe.unsafePartial)(function (dictPartial) {
            return Data_Function.apply(Data_Maybe.fromJust(dictPartial))(Data_Function.apply(Data_Int.fromNumber)($$Math.remainder(Data_Int.toNumber(lcgM) * Data_Int.toNumber(n) + d)(Data_Int.toNumber(lcgN))));
        });
    };
    return function ($9) {
        return Seed(go(runSeed($9)));
    };
};
var seedMax = lcgM - 1;
var lcgC = 0;
var lcgNext = lcgPerturb(Data_Int.toNumber(lcgC));
var eqSeed = new Data_Eq.Eq(function (v) {
    return function (v1) {
        return v === v1;
    };
});
var ensureBetween = function (min) {
    return function (max) {
        return function (n) {
            var rangeSize = max - min;
            return (n % rangeSize + rangeSize | 0) % rangeSize + min | 0;
        };
    };
};
var mkSeed = function (x) {
    return ensureBetween(seedMin)(seedMax)(x);
};
var randomSeed = Data_Functor.map(Control_Monad_Eff.functorEff)(mkSeed)(Control_Monad_Eff_Random.randomInt(seedMin)(seedMax));
module.exports = {
    lcgC: lcgC, 
    lcgM: lcgM, 
    lcgN: lcgN, 
    lcgNext: lcgNext, 
    lcgPerturb: lcgPerturb, 
    mkSeed: mkSeed, 
    randomSeed: randomSeed, 
    runSeed: runSeed, 
    showSeed: showSeed, 
    eqSeed: eqSeed
};
