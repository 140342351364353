// Generated by psc version 0.9.1
"use strict";
var Prelude = require("../Prelude");
var Pux_Html = require("../Pux.Html");
var State_Types = require("../State.Types");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var Data_Function = require("../Data.Function");
var Data_Semigroup = require("../Data.Semigroup");
var ModelName = function (x) {
    return x;
};
var loading = function (v) {
    return Pux_Html_Elements.p([  ])([ Data_Function.apply(Pux_Html_Elements.text)("Loading " + (v + " please wait.")) ]);
};
module.exports = {
    ModelName: ModelName, 
    loading: loading
};
