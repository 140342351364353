// Generated by psc version 0.9.1
"use strict";
var Data_ForeignKey = require("../Data.ForeignKey");
var Model_GlobalRole_1 = require("../Model.GlobalRole");
var Model_GlobalRole_1 = require("../Model.GlobalRole");
var Model_My = require("../Model.My");
var Prelude = require("../Prelude");
var State_Types = require("../State.Types");
var Data_Function = require("../Data.Function");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra");
var Data_Eq = require("../Data.Eq");
var unwrap = function (s) {
    return (Model_My.unMy(s.my)).globalRole;
};
var setGlobalRole = function (r) {
    return function (state) {
        var $0 = {};
        for (var $1 in state) {
            if (state.hasOwnProperty($1)) {
                $0[$1] = state[$1];
            };
        };
        $0.my = Model_My.setGlobalRole(r)(state.my);
        return $0;
    };
};
var isPanelChairFor = function (k) {
    return function (s) {
        return Data_Function.apply(Model_GlobalRole_1.isPanelChairFor(k))(unwrap(s));
    };
};
var hasReviewerRole = function (s) {
    return !Data_Eq.eq(Data_Eq.eqArray(Data_ForeignKey.eqForeignKey))((Model_GlobalRole_1.unGlobalRole((Model_My.unMy(s.my)).globalRole)).reviewerFor)([  ]);
};
var hasPanelChairRole = function (s) {
    return Model_GlobalRole_1.isPanelChair((Model_My.unMy(s.my)).globalRole);
};
var hasAdministratorRole = function (s) {
    return (Model_GlobalRole_1.unGlobalRole((Model_My.unMy(s.my)).globalRole)).administrator;
};
module.exports = {
    hasAdministratorRole: hasAdministratorRole, 
    hasPanelChairRole: hasPanelChairRole, 
    hasReviewerRole: hasReviewerRole, 
    setGlobalRole: setGlobalRole
};
