// Generated by psc version 0.9.1
"use strict";
var Data_ForeignLocation = require("../Data.ForeignLocation");
var Data_Function = require("../Data.Function");
var Data_Maybe = require("../Data.Maybe");
var Data_PathTo = require("../Data.PathTo");
var Model_CallForSessions = require("../Model.CallForSessions");
var Model_OpenCallsForSessions = require("../Model.OpenCallsForSessions");
var Model_Event_Key = require("../Model.Event.Key");
var Network_KeyedRemoteData = require("../Network.KeyedRemoteData");
var Prelude = require("../Prelude");
var State_DataDependency = require("../State.DataDependency");
var State_Types = require("../State.Types");
var WeReview_Data_Schema = require("../WeReview.Data.Schema");
var Data_Functor = require("../Data.Functor");
var Data_Either = require("../Data.Either");
var publishedCallsForSessions = function (v) {
    return Model_OpenCallsForSessions.publishedCallsForSessions(v.openCalls);
};
var openCfsLog = "Open Call For Sessions";
var fixedPath = function (dictPathTo) {
    return function (p) {
        return function (v) {
            return function (v1) {
                return Data_Function.apply(Data_PathTo.pathTo(dictPathTo))(p);
            };
        };
    };
};
var findOpenCallForSessions = function (k) {
    return function (v) {
        var $9 = Model_OpenCallsForSessions.find(k)(v.openCalls);
        if ($9 instanceof Data_Maybe.Just) {
            return new Network_KeyedRemoteData.Success($9.value0);
        };
        if ($9 instanceof Data_Maybe.Nothing) {
            return new Network_KeyedRemoteData.Loading(k);
        };
        throw new Error("Failed pattern match at State.CallForSessions line 37, column 42 - line 39, column 23: " + [ $9.constructor.name ]);
    };
};
var applyForeignCfS = function (fl) {
    return function (state) {
        var addCfS = function (state1) {
            return function (openCall) {
                var $12 = {};
                for (var $13 in state1) {
                    if (state1.hasOwnProperty($13)) {
                        $12[$13] = state1[$13];
                    };
                };
                $12.openCalls = Model_OpenCallsForSessions.upsertCfS(openCall)(state1.openCalls);
                return $12;
            };
        };
        return Data_Functor.map(Data_Either.functorEither)(addCfS(state))(Data_ForeignLocation.readForeignPart(Model_CallForSessions.isForeignOpenCallForSessions)(fl));
    };
};
var dependOnAllOpenCallsForSessions = new State_Types.Added(openCfsLog, fixedPath(WeReview_Data_Schema.pathToWeReviewSchema)(WeReview_Data_Schema.AllOpenCalls.value), applyForeignCfS, [  ]);
var dependOnAnOpenCallForSessions = function (eventKey) {
    var sc = WeReview_Data_Schema.anOpenCfSSchema(eventKey);
    return new State_Types.Changed(openCfsLog, fixedPath(WeReview_Data_Schema.pathToWeReviewSchema)(sc), applyForeignCfS, [  ]);
};
module.exports = {
    applyForeignCfS: applyForeignCfS, 
    dependOnAllOpenCallsForSessions: dependOnAllOpenCallsForSessions, 
    dependOnAnOpenCallForSessions: dependOnAnOpenCallForSessions, 
    findOpenCallForSessions: findOpenCallForSessions, 
    publishedCallsForSessions: publishedCallsForSessions
};
