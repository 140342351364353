// Generated by psc version 0.9.1
"use strict";
var Prelude = require("../Prelude");
var Data_Generic = require("../Data.Generic");
var Data_Tuple = require("../Data.Tuple");
var CSS_Background = require("../CSS.Background");
var CSS_Color = require("../CSS.Color");
var CSS_Common = require("../CSS.Common");
var CSS_Property = require("../CSS.Property");
var CSS_Size = require("../CSS.Size");
var CSS_String = require("../CSS.String");
var Data_Eq = require("../Data.Eq");
var Data_Ord = require("../Data.Ord");
var Control_Apply = require("../Control.Apply");
var Data_Maybe = require("../Data.Maybe");
var Data_Unit = require("../Data.Unit");
var Data_Functor = require("../Data.Functor");
var Data_Function = require("../Data.Function");
var Data_Semigroup = require("../Data.Semigroup");
var Radial = function (x) {
    return x;
};
var Extend = function (x) {
    return x;
};
var valRadial = new CSS_Property.Val(function (v) {
    return v;
});
var valExtend = new CSS_Property.Val(function (v) {
    return v;
});
var shortcut = function (g) {
    return function (f) {
        return function (t) {
            return g([ new Data_Tuple.Tuple(f, CSS_Size.pct(0.0)), new Data_Tuple.Tuple(t, CSS_Size.pct(100.0)) ]);
        };
    };
};
var ramp = function (xs) {
    return CSS_Property.value(CSS_Property.valList(CSS_Property.valValue))(Data_Functor.map(Data_Functor.functorArray)(function (v) {
        return CSS_Property.value(CSS_Property.valTuple(CSS_Property.valValue)(CSS_Property.valValue))(new Data_Tuple.Tuple(CSS_Property.value(CSS_Property.valColor)(v.value0), CSS_Property.value(CSS_Size.valSize)(v.value1)));
    })(xs));
};
var repeatingLinearGradient = function (d) {
    return function (xs) {
        return Data_Function.apply(CSS_Common.other(CSS_Background.otherBackgroundImage))(Data_Function.apply(CSS_Property.Value)((function () {
            var rlg = Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_String.fromString(CSS_Property.isStringValue)("repeating-linear-gradient("))(Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_Property.value(CSS_Background.valDirection)(d))(Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_String.fromString(CSS_Property.isStringValue)(","))(Data_Semigroup.append(CSS_Property.semigroupValue)(ramp(xs))(CSS_String.fromString(CSS_Property.isStringValue)(")")))));
            return Data_Semigroup.append(CSS_Property.semigroupPrefixed)(CSS_Common.browsers)(rlg);
        })()));
    };
};
var vRepeatingGradient = shortcut(repeatingLinearGradient(CSS_Background.straight(CSS_Background.sideTop)));
var repeatingRadialGradient = function (dictLoc) {
    return function (d) {
        return function (r) {
            return function (xs) {
                return Data_Function.apply(CSS_Common.other(CSS_Background.otherBackgroundImage))(Data_Function.apply(CSS_Property.Value)((function () {
                    var rrg = Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_String.fromString(CSS_Property.isStringValue)("repeating-radial-gradient("))(Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_Property.value(CSS_Property.valList(CSS_Property.valValue))([ CSS_Property.value(dictLoc["__superclass_CSS.Property.Val_0"]())(d), CSS_Property.value(valRadial)(r), ramp(xs) ]))(CSS_String.fromString(CSS_Property.isStringValue)(")")));
                    return Data_Semigroup.append(CSS_Property.semigroupPrefixed)(CSS_Common.browsers)(rrg);
                })()));
            };
        };
    };
};
var radialGradient = function (dictLoc) {
    return function (d) {
        return function (r) {
            return function (xs) {
                return Data_Function.apply(CSS_Common.other(CSS_Background.otherBackgroundImage))(Data_Function.apply(CSS_Property.Value)((function () {
                    var rg = Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_String.fromString(CSS_Property.isStringValue)("radial-gradient("))(Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_Property.value(CSS_Property.valList(CSS_Property.valValue))([ CSS_Property.value(dictLoc["__superclass_CSS.Property.Val_0"]())(d), CSS_Property.value(valRadial)(r), ramp(xs) ]))(CSS_String.fromString(CSS_Property.isStringValue)(")")));
                    return Data_Semigroup.append(CSS_Property.semigroupPrefixed)(CSS_Common.browsers)(rg);
                })()));
            };
        };
    };
};
var otherRadial = new CSS_Common.Other(Radial);
var otherExtend = new CSS_Common.Other(Extend);
var linearGradient = function (d) {
    return function (xs) {
        return Data_Function.apply(CSS_Common.other(CSS_Background.otherBackgroundImage))(Data_Function.apply(CSS_Property.Value)((function () {
            var lg = Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_String.fromString(CSS_Property.isStringValue)("linear-gradient("))(Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_Property.value(CSS_Background.valDirection)(d))(Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_String.fromString(CSS_Property.isStringValue)(","))(Data_Semigroup.append(CSS_Property.semigroupValue)(ramp(xs))(CSS_String.fromString(CSS_Property.isStringValue)(")")))));
            return Data_Semigroup.append(CSS_Property.semigroupPrefixed)(CSS_Common.browsers)(lg);
        })()));
    };
};
var vGradient = shortcut(linearGradient(CSS_Background.straight(CSS_Background.sideTop)));
var hRepeatingGradient = shortcut(repeatingLinearGradient(CSS_Background.straight(CSS_Background.sideLeft)));
var hGradient = shortcut(linearGradient(CSS_Background.straight(CSS_Background.sideLeft)));
var genericRadial = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "CSS.Gradient.Radial" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Radial))(Data_Generic.fromSpine(CSS_Property.genericValue)(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("CSS.Gradient.Radial", [ {
        sigConstructor: "CSS.Gradient.Radial", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(CSS_Property.genericValue)(Data_Generic.anyProxy);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("CSS.Gradient.Radial", [ function ($dollarq) {
        return Data_Generic.toSpine(CSS_Property.genericValue)(v);
    } ]);
});
var genericExtend = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "CSS.Gradient.Extend" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Extend))(Data_Generic.fromSpine(CSS_Property.genericValue)(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("CSS.Gradient.Extend", [ {
        sigConstructor: "CSS.Gradient.Extend", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(CSS_Property.genericValue)(Data_Generic.anyProxy);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("CSS.Gradient.Extend", [ function ($dollarq) {
        return Data_Generic.toSpine(CSS_Property.genericValue)(v);
    } ]);
});
var farthestSide = Data_Function.apply(Extend)(CSS_String.fromString(CSS_Property.isStringValue)("farthest-side"));
var farthestCorner = Data_Function.apply(Extend)(CSS_String.fromString(CSS_Property.isStringValue)("farthest-corner"));
var eqRadial = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(CSS_Property.eqValue)(x)(y);
    };
});
var ordRadial = new Data_Ord.Ord(function () {
    return eqRadial;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(CSS_Property.ordValue)(x)(y);
    };
});
var eqExtend = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(CSS_Property.eqValue)(x)(y);
    };
});
var ordExtend = new Data_Ord.Ord(function () {
    return eqExtend;
}, function (x) {
    return function (y) {
        return Data_Ord.compare(CSS_Property.ordValue)(x)(y);
    };
});
var elliptical = function (radx) {
    return function (rady) {
        return CSS_Property.value(CSS_Property.valTuple(CSS_Size.valSize)(CSS_Size.valSize))(new Data_Tuple.Tuple(radx, rady));
    };
};
var ellipse = function (ext) {
    return Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_String.fromString(CSS_Property.isStringValue)("ellipse "))(CSS_Property.value(valExtend)(ext));
};
var closestSide = Data_Function.apply(Extend)(CSS_String.fromString(CSS_Property.isStringValue)("closest-side"));
var closestCorner = Data_Function.apply(Extend)(CSS_String.fromString(CSS_Property.isStringValue)("closest-corner"));
var circular = function (radius) {
    return CSS_Property.value(CSS_Property.valTuple(CSS_Size.valSize)(CSS_Size.valSize))(new Data_Tuple.Tuple(radius, radius));
};
var circle = function (ext) {
    return Data_Semigroup.append(CSS_Property.semigroupValue)(CSS_String.fromString(CSS_Property.isStringValue)("circle "))(CSS_Property.value(valExtend)(ext));
};
module.exports = {
    circle: circle, 
    circular: circular, 
    closestCorner: closestCorner, 
    closestSide: closestSide, 
    ellipse: ellipse, 
    elliptical: elliptical, 
    farthestCorner: farthestCorner, 
    farthestSide: farthestSide, 
    hGradient: hGradient, 
    hRepeatingGradient: hRepeatingGradient, 
    linearGradient: linearGradient, 
    radialGradient: radialGradient, 
    repeatingLinearGradient: repeatingLinearGradient, 
    repeatingRadialGradient: repeatingRadialGradient, 
    vGradient: vGradient, 
    vRepeatingGradient: vRepeatingGradient, 
    eqRadial: eqRadial, 
    ordRadial: ordRadial, 
    genericRadial: genericRadial, 
    valRadial: valRadial, 
    otherRadial: otherRadial, 
    eqExtend: eqExtend, 
    ordExtend: ordExtend, 
    genericExtend: genericExtend, 
    valExtend: valExtend, 
    otherExtend: otherExtend
};
