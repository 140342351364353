// Generated by psc version 0.9.1
"use strict";
var Components_Elements = require("../Components.Elements");
var Components_Elements_Basic_Types = require("../Components.Elements.Basic.Types");
var Prelude = require("../Prelude");
var Pux_Html = require("../Pux.Html");
var Pux_Html_Attributes = require("../Pux.Html.Attributes");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var Pux_Router = require("../Pux.Router");
var State_Types = require("../State.Types");
var UReview_Route_AsUrl = require("../UReview.Route.AsUrl");
var UReview_Route_Types = require("../UReview.Route.Types");
var Data_Semigroup = require("../Data.Semigroup");
var Anchor = function (x) {
    return x;
};
var routeToTop = function (r) {
    return function (linkText) {
        var target = UReview_Route_AsUrl.asUrlString(UReview_Route_AsUrl.routeAsUrlstring)(r) + "#top";
        return Pux_Router.link(target)([  ])([ Pux_Html_Elements.text(linkText) ]);
    };
};
var routeLinkWithId = function (r) {
    return function (hid) {
        return function (linkText) {
            return Pux_Router.link(UReview_Route_AsUrl.asUrlString(UReview_Route_AsUrl.routeAsUrlstring)(r))([ Pux_Html_Attributes.id_(Components_Elements_Basic_Types.fromHtmlId(hid)) ])([ Pux_Html_Elements.text(linkText) ]);
        };
    };
};
var routeLink = function (r) {
    return function (linkText) {
        return Pux_Router.link(UReview_Route_AsUrl.asUrlString(UReview_Route_AsUrl.routeAsUrlstring)(r))([  ])([ Pux_Html_Elements.text(linkText) ]);
    };
};
var routeButton = function (route) {
    return function (htmlId) {
        return function (linkText) {
            return Components_Elements.createSmallButton(new State_Types.NavigateTo(route))(htmlId)(linkText);
        };
    };
};
var openInTab = function (r) {
    return function (hid) {
        return function (linkText) {
            return Pux_Router.link(UReview_Route_AsUrl.asUrlString(UReview_Route_AsUrl.routeAsUrlstring)(r))([ Pux_Html_Attributes.id_(Components_Elements_Basic_Types.fromHtmlId(hid)), Pux_Html_Attributes.target("_blank") ])([ Pux_Html_Elements.text(linkText) ]);
        };
    };
};
var navBox = function (elements) {
    return Pux_Html_Elements.div([ Pux_Html_Attributes.className("small-12 medium-10 large-9 column mt4") ])(elements);
};
var linkToAnchor = function (v) {
    return function (linkText) {
        return Pux_Html_Elements.a([ Pux_Html_Attributes.href("#" + v) ])([ Pux_Html_Elements.text(linkText) ]);
    };
};
var htmlLink = function (aref) {
    return function (linkText) {
        return Pux_Html_Elements.a([ Pux_Html_Attributes.href(UReview_Route_AsUrl.asUrlString(UReview_Route_AsUrl.routeAsUrlstring)(aref)) ])([ Pux_Html_Elements.text(linkText) ]);
    };
};
var backToTop = linkToAnchor("top")("Back to top");
module.exports = {
    backToTop: backToTop, 
    htmlLink: htmlLink, 
    linkToAnchor: linkToAnchor, 
    navBox: navBox, 
    openInTab: openInTab, 
    routeButton: routeButton, 
    routeLink: routeLink, 
    routeLinkWithId: routeLinkWithId, 
    routeToTop: routeToTop
};
