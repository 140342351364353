// Generated by psc version 0.9.1
"use strict";
var Components_DB_Action = require("../Components.DB.Action");
var Data_Activation = require("../Data.Activation");
var Data_Firebase_Foreign = require("../Data.Firebase.Foreign");
var Model_UserProfile = require("../Model.UserProfile");
var Prelude = require("../Prelude");
var WeReview_Data_Schema = require("../WeReview.Data.Schema");
var Data_Function = require("../Data.Function");
var deactivateAdministratorAction = function (userKey) {
    return Data_Function.apply(Components_DB_Action.SetValue.create(new WeReview_Data_Schema.Roles(userKey, WeReview_Data_Schema.IsAdministrator.value)))(Data_Firebase_Foreign.toFire(Data_Activation.toFireActivation)(Data_Activation.InActive.value));
};
var activateAdministratorAction = function (userKey) {
    return Data_Function.apply(Components_DB_Action.SetValue.create(new WeReview_Data_Schema.Roles(userKey, WeReview_Data_Schema.IsAdministrator.value)))(Data_Firebase_Foreign.toFire(Data_Activation.toFireActivation)(Data_Activation.Active.value));
};
module.exports = {
    activateAdministratorAction: activateAdministratorAction, 
    deactivateAdministratorAction: deactivateAdministratorAction
};
