// Generated by psc version 0.9.1
"use strict";
var Data_Set = require("../Data.Set");
var Data_Firebase_Foreign = require("../Data.Firebase.Foreign");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var Data_Foreign_Generic = require("../Data.Foreign.Generic");
var Data_Generators = require("../Data.Generators");
var Data_Generic = require("../Data.Generic");
var Data_String = require("../Data.String");
var Data_VersionNumber = require("../Data.VersionNumber");
var Data_YesNo = require("../Data.YesNo");
var Model_ReviewCompletion = require("../Model.ReviewCompletion");
var Prelude = require("../Prelude");
var Test_QuickCheck_Arbitrary = require("../Test.QuickCheck.Arbitrary");
var UReview_Prelude = require("../UReview.Prelude");
var Control_Apply = require("../Control.Apply");
var Data_Maybe = require("../Data.Maybe");
var Data_Unit = require("../Data.Unit");
var Data_Show = require("../Data.Show");
var Data_Eq = require("../Data.Eq");
var Control_Bind = require("../Control.Bind");
var Data_Function = require("../Data.Function");
var Control_Applicative = require("../Control.Applicative");
var Data_Functor = require("../Data.Functor");
var Data_Foldable = require("../Data.Foldable");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans");
var Data_Identity = require("../Data.Identity");
var OneToFive = function (x) {
    return x;
};
var SpaLikeReviewVersion = function (x) {
    return x;
};
var runSpaLikeReviewQuestion = function (v) {
    return v;
};
var mkOneToFive = function (n) {
    return n;
};
var one = mkOneToFive(5);
var zero = mkOneToFive(0);
var nullSpaLikeReviewVersion = {
    modelVersion: Data_VersionNumber.mkVersionNumber, 
    recordVersion: Data_VersionNumber.mkVersionNumber, 
    subjectClearlyDefined: Data_YesNo.PleaseChoose.value, 
    sessionOfInterest: Data_YesNo.PleaseChoose.value, 
    technicallySound: Data_YesNo.PleaseChoose.value, 
    wellOrganised: Data_YesNo.PleaseChoose.value, 
    wouldYouGo: Data_YesNo.PleaseChoose.value, 
    overallEvaluation: zero, 
    ownConfidence: zero, 
    comments: ""
};
var genericOneToFive = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.SpaLikeReviewVersion.OneToFive" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(OneToFive))(Data_Generic.fromSpine(Data_Generic.genericInt)(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Model.SpaLikeReviewVersion.OneToFive", [ {
        sigConstructor: "Model.SpaLikeReviewVersion.OneToFive", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_Generic.genericInt)(Data_Generic.anyProxy);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("Model.SpaLikeReviewVersion.OneToFive", [ function ($dollarq) {
        return Data_Generic.toSpine(Data_Generic.genericInt)(v);
    } ]);
});
var genericSpaLikeReviewVersion = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Model.SpaLikeReviewVersion.SpaLikeReviewVersion" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(SpaLikeReviewVersion))((function (r) {
            if (r instanceof Data_Generic.SRecord && r.value0.length === 10) {
                return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(function (comments1) {
                    return function (modelVersion1) {
                        return function (overallEvaluation1) {
                            return function (ownConfidence1) {
                                return function (recordVersion1) {
                                    return function (sessionOfInterest1) {
                                        return function (subjectClearlyDefined1) {
                                            return function (technicallySound1) {
                                                return function (wellOrganised1) {
                                                    return function (wouldYouGo1) {
                                                        return {
                                                            comments: comments1, 
                                                            modelVersion: modelVersion1, 
                                                            overallEvaluation: overallEvaluation1, 
                                                            ownConfidence: ownConfidence1, 
                                                            recordVersion: recordVersion1, 
                                                            sessionOfInterest: sessionOfInterest1, 
                                                            subjectClearlyDefined: subjectClearlyDefined1, 
                                                            technicallySound: technicallySound1, 
                                                            wellOrganised: wellOrganised1, 
                                                            wouldYouGo: wouldYouGo1
                                                        };
                                                    };
                                                };
                                            };
                                        };
                                    };
                                };
                            };
                        };
                    };
                }))(Data_Generic.fromSpine(Data_Generic.genericString)((r.value0[0]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Data_VersionNumber.genericVersionNumber)((r.value0[1]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(genericOneToFive)((r.value0[2]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(genericOneToFive)((r.value0[3]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Data_VersionNumber.genericVersionNumber)((r.value0[4]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Data_YesNo.genericYesNo)((r.value0[5]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Data_YesNo.genericYesNo)((r.value0[6]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Data_YesNo.genericYesNo)((r.value0[7]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Data_YesNo.genericYesNo)((r.value0[8]).recValue(Data_Unit.unit))))(Data_Generic.fromSpine(Data_YesNo.genericYesNo)((r.value0[9]).recValue(Data_Unit.unit)));
            };
            return Data_Maybe.Nothing.value;
        })(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Model.SpaLikeReviewVersion.SpaLikeReviewVersion", [ {
        sigConstructor: "Model.SpaLikeReviewVersion.SpaLikeReviewVersion", 
        sigValues: [ function ($dollarq1) {
            return new Data_Generic.SigRecord([ {
                recLabel: "comments", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "modelVersion", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_VersionNumber.genericVersionNumber)(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "overallEvaluation", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(genericOneToFive)(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "ownConfidence", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(genericOneToFive)(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "recordVersion", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_VersionNumber.genericVersionNumber)(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "sessionOfInterest", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_YesNo.genericYesNo)(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "subjectClearlyDefined", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_YesNo.genericYesNo)(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "technicallySound", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_YesNo.genericYesNo)(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "wellOrganised", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_YesNo.genericYesNo)(Data_Generic.anyProxy);
                }
            }, {
                recLabel: "wouldYouGo", 
                recValue: function ($dollarq2) {
                    return Data_Generic.toSignature(Data_YesNo.genericYesNo)(Data_Generic.anyProxy);
                }
            } ]);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("Model.SpaLikeReviewVersion.SpaLikeReviewVersion", [ function ($dollarq) {
        return new Data_Generic.SRecord([ {
            recLabel: "comments", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_Generic.genericString)(v.comments);
            }
        }, {
            recLabel: "modelVersion", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_VersionNumber.genericVersionNumber)(v.modelVersion);
            }
        }, {
            recLabel: "overallEvaluation", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(genericOneToFive)(v.overallEvaluation);
            }
        }, {
            recLabel: "ownConfidence", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(genericOneToFive)(v.ownConfidence);
            }
        }, {
            recLabel: "recordVersion", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_VersionNumber.genericVersionNumber)(v.recordVersion);
            }
        }, {
            recLabel: "sessionOfInterest", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_YesNo.genericYesNo)(v.sessionOfInterest);
            }
        }, {
            recLabel: "subjectClearlyDefined", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_YesNo.genericYesNo)(v.subjectClearlyDefined);
            }
        }, {
            recLabel: "technicallySound", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_YesNo.genericYesNo)(v.technicallySound);
            }
        }, {
            recLabel: "wellOrganised", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_YesNo.genericYesNo)(v.wellOrganised);
            }
        }, {
            recLabel: "wouldYouGo", 
            recValue: function ($dollarq1) {
                return Data_Generic.toSpine(Data_YesNo.genericYesNo)(v.wouldYouGo);
            }
        } ]);
    } ]);
});
var isForeignSpaLikeReviewVersion = new Data_Foreign_Class.IsForeign(Data_Foreign_Generic.readGeneric(genericSpaLikeReviewVersion)(Data_VersionNumber.jsonOptions));
var showSpaLikeReviewVersion = new Data_Show.Show(Data_Generic.gShow(genericSpaLikeReviewVersion));
var toFirebaseForeignSpalikeReviewVersion = new Data_Firebase_Foreign.ToFirebaseForeign(UReview_Prelude.writeForeign(genericSpaLikeReviewVersion));
var isForeignOneToFive = new Data_Foreign_Class.IsForeign(Data_Foreign_Generic.readGeneric(genericOneToFive)(Data_VersionNumber.jsonOptions));
var showOneToFive = new Data_Show.Show(Data_Generic.gShow(genericOneToFive));
var toFirebaseForeignOneTofive = new Data_Firebase_Foreign.ToFirebaseForeign(UReview_Prelude.writeForeign(genericOneToFive));
var five = mkOneToFive(5);
var eqSpaLikeReviewVersion = new Data_Eq.Eq(Data_Generic.gEq(genericSpaLikeReviewVersion));
var eqOneToFive = new Data_Eq.Eq(Data_Generic.gEq(genericOneToFive));
var isComplete = function (v) {
    var yesNoValues = Data_Set.fromFoldable(Data_Foldable.foldableArray)(Data_YesNo.ordYesNo)([ v.subjectClearlyDefined, v.sessionOfInterest, v.technicallySound, v.wellOrganised, v.wouldYouGo ]);
    var noYesNoChosen = Data_Set.size(yesNoValues) === 1 && Data_Set.member(Data_YesNo.ordYesNo)(Data_YesNo.PleaseChoose.value)(yesNoValues);
    var noQuestionsAnswered = noYesNoChosen && (Data_Eq.eq(eqOneToFive)(v.ownConfidence)(zero) && Data_Eq.eq(eqOneToFive)(v.overallEvaluation)(zero) && Data_String["null"](v.comments));
    var allYesNoChosen = Data_Function.apply(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Set.member(Data_YesNo.ordYesNo)(Data_YesNo.PleaseChoose.value)(yesNoValues));
    var allQuestionsAnswered = allYesNoChosen && (Data_Eq.notEq(eqOneToFive)(v.ownConfidence)(zero) && Data_Eq.notEq(eqOneToFive)(v.overallEvaluation)(zero));
    if (noQuestionsAnswered) {
        return Model_ReviewCompletion.ReviewNotStarted.value;
    };
    if (!noQuestionsAnswered) {
        if (allQuestionsAnswered) {
            return Model_ReviewCompletion.ReviewCompleted.value;
        };
        if (!allQuestionsAnswered) {
            return Model_ReviewCompletion.ReviewStarted.value;
        };
        throw new Error("Failed pattern match at Model.SpaLikeReviewVersion line 69, column 9 - line 73, column 28: " + [ allQuestionsAnswered.constructor.name ]);
    };
    throw new Error("Failed pattern match at Model.SpaLikeReviewVersion line 65, column 3 - line 73, column 28: " + [ noQuestionsAnswered.constructor.name ]);
};
var editOneToFive = function (v) {
    return Data_Show.show(Data_Show.showInt)(v);
};
var arbitraryOneToFive = new Test_QuickCheck_Arbitrary.Arbitrary(Data_Functor.map(Control_Monad_State_Trans.functorStateT(Data_Identity.functorIdentity))(OneToFive)(Data_Generators.integerBetween(1)(5)));
var arbitrarySpaLikeReviewViewVersion = new Test_QuickCheck_Arbitrary.Arbitrary(Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Data_VersionNumber.arbVersionNumber))(function (v) {
    return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Data_VersionNumber.arbVersionNumber))(function (v1) {
        return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Data_YesNo.arbitraryYesNo))(function (v2) {
            return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Data_YesNo.arbitraryYesNo))(function (v3) {
                return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Data_YesNo.arbitraryYesNo))(function (v4) {
                    return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Data_YesNo.arbitraryYesNo))(function (v5) {
                        return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(Data_YesNo.arbitraryYesNo))(function (v6) {
                            return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(arbitraryOneToFive))(function (v7) {
                                return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(arbitraryOneToFive))(function (v8) {
                                    return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Data_Generators.alphaNumString)(function (v9) {
                                        return Data_Function.apply(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity)))({
                                            modelVersion: v, 
                                            recordVersion: v1, 
                                            subjectClearlyDefined: v2, 
                                            sessionOfInterest: v3, 
                                            technicallySound: v4, 
                                            wellOrganised: v5, 
                                            wouldYouGo: v6, 
                                            overallEvaluation: v7, 
                                            ownConfidence: v8, 
                                            comments: v9
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });
    });
}));
module.exports = {
    OneToFive: OneToFive, 
    SpaLikeReviewVersion: SpaLikeReviewVersion, 
    editOneToFive: editOneToFive, 
    five: five, 
    isComplete: isComplete, 
    mkOneToFive: mkOneToFive, 
    nullSpaLikeReviewVersion: nullSpaLikeReviewVersion, 
    one: one, 
    runSpaLikeReviewQuestion: runSpaLikeReviewQuestion, 
    zero: zero, 
    genericSpaLikeReviewVersion: genericSpaLikeReviewVersion, 
    showSpaLikeReviewVersion: showSpaLikeReviewVersion, 
    eqSpaLikeReviewVersion: eqSpaLikeReviewVersion, 
    isForeignSpaLikeReviewVersion: isForeignSpaLikeReviewVersion, 
    arbitrarySpaLikeReviewViewVersion: arbitrarySpaLikeReviewViewVersion, 
    toFirebaseForeignSpalikeReviewVersion: toFirebaseForeignSpalikeReviewVersion, 
    genericOneToFive: genericOneToFive, 
    showOneToFive: showOneToFive, 
    eqOneToFive: eqOneToFive, 
    isForeignOneToFive: isForeignOneToFive, 
    arbitraryOneToFive: arbitraryOneToFive, 
    toFirebaseForeignOneTofive: toFirebaseForeignOneTofive
};
