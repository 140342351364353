// Generated by psc version 0.9.1
"use strict";
var Control_Applicative = require("../Control.Applicative");
var Control_Apply = require("../Control.Apply");
var Control_Bind = require("../Control.Bind");
var Control_Monad = require("../Control.Monad");
var Data_Bifunctor = require("../Data.Bifunctor");
var Data_Either = require("../Data.Either");
var Data_Eq = require("../Data.Eq");
var Data_Function = require("../Data.Function");
var Data_Functor = require("../Data.Functor");
var Data_Generic = require("../Data.Generic");
var Data_Maybe = require("../Data.Maybe");
var Data_Monoid = require("../Data.Monoid");
var Data_Show = require("../Data.Show");
var Test_QuickCheck_Arbitrary = require("../Test.QuickCheck.Arbitrary");
var Test_QuickCheck_Gen = require("../Test.QuickCheck.Gen");
var Data_Unit = require("../Data.Unit");
var Data_Semigroup = require("../Data.Semigroup");
var Control_Category = require("../Control.Category");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans");
var Data_Identity = require("../Data.Identity");
var NotAsked = (function () {
    function NotAsked() {

    };
    NotAsked.value = new NotAsked();
    return NotAsked;
})();
var Loading = (function () {
    function Loading() {

    };
    Loading.value = new Loading();
    return Loading;
})();
var Failure = (function () {
    function Failure(value0) {
        this.value0 = value0;
    };
    Failure.create = function (value0) {
        return new Failure(value0);
    };
    return Failure;
})();
var Success = (function () {
    function Success(value0) {
        this.value0 = value0;
    };
    Success.create = function (value0) {
        return new Success(value0);
    };
    return Success;
})();
var toMaybe = function (v) {
    if (v instanceof Success) {
        return new Data_Maybe.Just(v.value0);
    };
    return Data_Maybe.Nothing.value;
};
var showRemoteData = function (dictShow) {
    return function (dictShow1) {
        return new Data_Show.Show(function (v) {
            if (v instanceof NotAsked) {
                return "RemoteData.NotAsked";
            };
            if (v instanceof Loading) {
                return "RemoteData.Loading";
            };
            if (v instanceof Failure) {
                return "RemoteData.Failure " + Data_Show.show(dictShow)(v.value0);
            };
            if (v instanceof Success) {
                return "RemoteData.Success " + Data_Show.show(dictShow1)(v.value0);
            };
            throw new Error("Failed pattern match at Network.RemoteData line 50, column 3 - line 51, column 3: " + [ v.constructor.name ]);
        });
    };
};
var maybe = function (default$prime) {
    return function (f) {
        return function (v) {
            if (v instanceof Success) {
                return f(v.value0);
            };
            return default$prime;
        };
    };
};
var withDefault = function (default$prime) {
    return maybe(default$prime)(Control_Category.id(Control_Category.categoryFn));
};
var isSuccess = function (v) {
    if (v instanceof Success) {
        return true;
    };
    return false;
};
var isNotAsked = function (v) {
    if (v instanceof NotAsked) {
        return true;
    };
    return false;
};
var isLoading = function (v) {
    if (v instanceof Loading) {
        return true;
    };
    return false;
};
var isFailure = function (v) {
    if (v instanceof Failure) {
        return true;
    };
    return false;
};
var genericRemoteData = function (dictGeneric) {
    return function (dictGeneric1) {
        return new Data_Generic.Generic(function (v) {
            if (v instanceof Data_Generic.SProd && (v.value0 === "Network.RemoteData.NotAsked" && v.value1.length === 0)) {
                return new Data_Maybe.Just(NotAsked.value);
            };
            if (v instanceof Data_Generic.SProd && (v.value0 === "Network.RemoteData.Loading" && v.value1.length === 0)) {
                return new Data_Maybe.Just(Loading.value);
            };
            if (v instanceof Data_Generic.SProd && (v.value0 === "Network.RemoteData.Failure" && v.value1.length === 1)) {
                return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Failure.create))(Data_Generic.fromSpine(dictGeneric)(v.value1[0](Data_Unit.unit)));
            };
            if (v instanceof Data_Generic.SProd && (v.value0 === "Network.RemoteData.Success" && v.value1.length === 1)) {
                return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(Success.create))(Data_Generic.fromSpine(dictGeneric1)(v.value1[0](Data_Unit.unit)));
            };
            return Data_Maybe.Nothing.value;
        }, function ($dollarq) {
            return new Data_Generic.SigProd("Network.RemoteData.RemoteData", [ {
                sigConstructor: "Network.RemoteData.NotAsked", 
                sigValues: [  ]
            }, {
                sigConstructor: "Network.RemoteData.Loading", 
                sigValues: [  ]
            }, {
                sigConstructor: "Network.RemoteData.Failure", 
                sigValues: [ function ($dollarq1) {
                    return Data_Generic.toSignature(dictGeneric)(Data_Generic.anyProxy);
                } ]
            }, {
                sigConstructor: "Network.RemoteData.Success", 
                sigValues: [ function ($dollarq1) {
                    return Data_Generic.toSignature(dictGeneric1)(Data_Generic.anyProxy);
                } ]
            } ]);
        }, function (v) {
            if (v instanceof NotAsked) {
                return new Data_Generic.SProd("Network.RemoteData.NotAsked", [  ]);
            };
            if (v instanceof Loading) {
                return new Data_Generic.SProd("Network.RemoteData.Loading", [  ]);
            };
            if (v instanceof Failure) {
                return new Data_Generic.SProd("Network.RemoteData.Failure", [ function ($dollarq) {
                    return Data_Generic.toSpine(dictGeneric)(v.value0);
                } ]);
            };
            if (v instanceof Success) {
                return new Data_Generic.SProd("Network.RemoteData.Success", [ function ($dollarq) {
                    return Data_Generic.toSpine(dictGeneric1)(v.value0);
                } ]);
            };
            throw new Error("Failed pattern match at Network.RemoteData line 38, column 1 - line 38, column 88: " + [ v.constructor.name ]);
        });
    };
};
var functorRemoteData = new Data_Functor.Functor(function (f) {
    return function (rd) {
        if (rd instanceof Success) {
            return new Success(f(rd.value0));
        };
        if (rd instanceof Failure) {
            return new Failure(rd.value0);
        };
        if (rd instanceof NotAsked) {
            return NotAsked.value;
        };
        if (rd instanceof Loading) {
            return Loading.value;
        };
        throw new Error("Failed pattern match at Network.RemoteData line 43, column 15 - line 47, column 24: " + [ rd.constructor.name ]);
    };
});
var fromMaybe = function (v) {
    if (v instanceof Data_Maybe.Nothing) {
        return NotAsked.value;
    };
    if (v instanceof Data_Maybe.Just) {
        return new Success(v.value0);
    };
    throw new Error("Failed pattern match at Network.RemoteData line 99, column 1 - line 99, column 29: " + [ v.constructor.name ]);
};
var fromEither = function (v) {
    if (v instanceof Data_Either.Left) {
        return new Failure(v.value0);
    };
    if (v instanceof Data_Either.Right) {
        return new Success(v.value0);
    };
    throw new Error("Failed pattern match at Network.RemoteData line 104, column 1 - line 104, column 36: " + [ v.constructor.name ]);
};
var eqRemoteData = function (dictEq) {
    return function (dictEq1) {
        return new Data_Eq.Eq(function (x) {
            return function (y) {
                if (x instanceof NotAsked && y instanceof NotAsked) {
                    return true;
                };
                if (x instanceof Loading && y instanceof Loading) {
                    return true;
                };
                if (x instanceof Failure && y instanceof Failure) {
                    return Data_Eq.eq(dictEq)(x.value0)(y.value0);
                };
                if (x instanceof Success && y instanceof Success) {
                    return Data_Eq.eq(dictEq1)(x.value0)(y.value0);
                };
                return false;
            };
        });
    };
};
var bifunctorRemoteData = new Data_Bifunctor.Bifunctor(function (v) {
    return function (v1) {
        return function (v2) {
            if (v2 instanceof NotAsked) {
                return NotAsked.value;
            };
            if (v2 instanceof Loading) {
                return Loading.value;
            };
            if (v2 instanceof Failure) {
                return new Failure(v(v2.value0));
            };
            if (v2 instanceof Success) {
                return new Success(v1(v2.value0));
            };
            throw new Error("Failed pattern match at Network.RemoteData line 63, column 3 - line 63, column 32: " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
        };
    };
});
var arbRemoteData = function (dictArbitrary) {
    return function (dictArbitrary1) {
        return new Test_QuickCheck_Arbitrary.Arbitrary(Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(dictArbitrary))(function (v) {
            return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Test_QuickCheck_Arbitrary.arbitrary(dictArbitrary1))(function (v1) {
                return Test_QuickCheck_Gen.elements(NotAsked.value)([ Loading.value, new Failure(v), new Success(v1) ]);
            });
        }));
    };
};
var applyRemoteData = new Control_Apply.Apply(function () {
    return functorRemoteData;
}, function (v) {
    return function (v1) {
        if (v instanceof Success && v1 instanceof Success) {
            return new Success(v.value0(v1.value0));
        };
        if (v instanceof Failure) {
            return new Failure(v.value0);
        };
        if (v1 instanceof Failure) {
            return new Failure(v1.value0);
        };
        if (v instanceof NotAsked) {
            return NotAsked.value;
        };
        if (v1 instanceof NotAsked) {
            return NotAsked.value;
        };
        if (v instanceof Loading) {
            return Loading.value;
        };
        if (v1 instanceof Loading) {
            return Loading.value;
        };
        throw new Error("Failed pattern match at Network.RemoteData line 71, column 3 - line 71, column 56: " + [ v.constructor.name, v1.constructor.name ]);
    };
});
var bindRemoteData = new Control_Bind.Bind(function () {
    return applyRemoteData;
}, function (v) {
    return function (v1) {
        if (v instanceof NotAsked) {
            return NotAsked.value;
        };
        if (v instanceof Loading) {
            return Loading.value;
        };
        if (v instanceof Failure) {
            return new Failure(v.value0);
        };
        if (v instanceof Success) {
            return v1(v.value0);
        };
        throw new Error("Failed pattern match at Network.RemoteData line 80, column 3 - line 80, column 29: " + [ v.constructor.name, v1.constructor.name ]);
    };
});
var applicativeRemoteData = new Control_Applicative.Applicative(function () {
    return applyRemoteData;
}, function (value) {
    return new Success(value);
});
var monadRemoteData = new Control_Monad.Monad(function () {
    return applicativeRemoteData;
}, function () {
    return bindRemoteData;
});
module.exports = {
    NotAsked: NotAsked, 
    Loading: Loading, 
    Failure: Failure, 
    Success: Success, 
    fromEither: fromEither, 
    fromMaybe: fromMaybe, 
    isFailure: isFailure, 
    isLoading: isLoading, 
    isNotAsked: isNotAsked, 
    isSuccess: isSuccess, 
    maybe: maybe, 
    toMaybe: toMaybe, 
    withDefault: withDefault, 
    genericRemoteData: genericRemoteData, 
    eqRemoteData: eqRemoteData, 
    functorRemoteData: functorRemoteData, 
    showRemoteData: showRemoteData, 
    arbRemoteData: arbRemoteData, 
    bifunctorRemoteData: bifunctorRemoteData, 
    applyRemoteData: applyRemoteData, 
    bindRemoteData: bindRemoteData, 
    applicativeRemoteData: applicativeRemoteData, 
    monadRemoteData: monadRemoteData
};
