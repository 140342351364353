// Generated by psc version 0.9.1
"use strict";
var Prelude = require("../Prelude");
var Control_Category = require("../Control.Category");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var Control_Monad = require("../Control.Monad");
var Control_Bind = require("../Control.Bind");
var Control_Applicative = require("../Control.Applicative");
var MonadReader = function (__superclass_Control$dotMonad$dotMonad_0, ask, local) {
    this["__superclass_Control.Monad.Monad_0"] = __superclass_Control$dotMonad$dotMonad_0;
    this.ask = ask;
    this.local = local;
};
var monadReaderFun = new MonadReader(function () {
    return Control_Monad.monadFn;
}, Control_Category.id(Control_Category.categoryFn), Control_Semigroupoid.composeFlipped(Control_Semigroupoid.semigroupoidFn));
var local = function (dict) {
    return dict.local;
};
var ask = function (dict) {
    return dict.ask;
};
var reader = function (dictMonadReader) {
    return function (f) {
        return Control_Bind.bindFlipped((dictMonadReader["__superclass_Control.Monad.Monad_0"]())["__superclass_Control.Bind.Bind_1"]())(function ($1) {
            return Control_Applicative.pure((dictMonadReader["__superclass_Control.Monad.Monad_0"]())["__superclass_Control.Applicative.Applicative_0"]())(f($1));
        })(ask(dictMonadReader));
    };
};
module.exports = {
    MonadReader: MonadReader, 
    ask: ask, 
    local: local, 
    reader: reader, 
    monadReaderFun: monadReaderFun
};
