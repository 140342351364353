// Generated by psc version 0.9.1
"use strict";
var Model_UserProfile = require("../Model.UserProfile");
var Prelude = require("../Prelude");
var Pux_Html = require("../Pux.Html");
var Pux_Html_Attributes = require("../Pux.Html.Attributes");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var Route_HtmlElements = require("../Route.HtmlElements");
var State_Types = require("../State.Types");
var UReview_Route_Types = require("../UReview.Route.Types");
var Data_Function = require("../Data.Function");
var Data_Semigroup = require("../Data.Semigroup");
var Data_Functor = require("../Data.Functor");
var showUsers = function (users) {
    var nameHtml = function (v) {
        return Pux_Html_Elements.li([  ])([ Route_HtmlElements.routeLink(new UReview_Route_Types.UserRoute(Model_UserProfile.getUserKey(v)))(v.displayName), Data_Function.apply(Pux_Html_Elements.text)(" " + v.uid) ]);
    };
    return Pux_Html_Elements.div([ Pux_Html_Attributes.className("row") ])([ Pux_Html_Elements.div([ Pux_Html_Attributes.className("column") ])([ Pux_Html_Elements.h3([  ])([ Pux_Html_Elements.text("Your Users") ]), Pux_Html_Elements.ul([ Pux_Html_Attributes.className("no-bullet") ])(Data_Functor.map(Data_Functor.functorArray)(nameHtml)(users)) ]) ]);
};
var usersView = function (state) {
    return Pux_Html_Elements.section([  ])([ Pux_Html_Elements.div([ Pux_Html_Attributes.className("row") ])([ Pux_Html_Elements.div([ Pux_Html_Attributes.className("column") ])([ Pux_Html_Elements.h2([  ])([ Pux_Html_Elements.text("Session Review") ]), showUsers(state.allUsers) ]) ]) ]);
};
module.exports = {
    usersView: usersView
};
