// Generated by psc version 0.9.1
"use strict";
var Data_Loaded = require("../Data.Loaded");
var Prelude = require("../Prelude");
var Pux_Html = require("../Pux.Html");
var Pux_Html_Elements = require("../Pux.Html.Elements");
var Data_Function = require("../Data.Function");
var Data_Semigroup = require("../Data.Semigroup");
var Data_Show = require("../Data.Show");
var viewLoadCount = function (label) {
    return function (l) {
        var loaded = Data_Loaded.runLoaded(l);
        var html = Pux_Html_Elements.p([  ])([ Data_Function.apply(Pux_Html_Elements.text)("Loaded " + (Data_Show.show(Data_Show.showInt)(loaded.presentCount) + (" of " + (Data_Show.show(Data_Show.showInt)(loaded.totalCount) + (" " + (label + ".")))))) ]);
        return html;
    };
};
module.exports = {
    viewLoadCount: viewLoadCount
};
