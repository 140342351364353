// Generated by psc version 0.9.1
"use strict";
var Control_Monad_Aff_Console = require("../Control.Monad.Aff.Console");
var Data_Either = require("../Data.Either");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_ForeignLocation = require("../Data.ForeignLocation");
var Data_Tuple = require("../Data.Tuple");
var Prelude = require("../Prelude");
var Pux = require("../Pux");
var State_DataDependencyInterpreter = require("../State.DataDependencyInterpreter");
var State_EffModelHelpers = require("../State.EffModelHelpers");
var State_SubscribeReadModelForRoute = require("../State.SubscribeReadModelForRoute");
var State_Types = require("../State.Types");
var Data_Semigroup = require("../Data.Semigroup");
var Data_Show = require("../Data.Show");
var Control_Apply = require("../Control.Apply");
var Control_Monad_Aff = require("../Control.Monad.Aff");
var Data_Function = require("../Data.Function");
var Control_Applicative = require("../Control.Applicative");
var Data_Foreign = require("../Data.Foreign");
var onSuccess = function (cas) {
    return function (state) {
        return State_EffModelHelpers.applyAll([ State_SubscribeReadModelForRoute.subscribePartsForRoute, State_DataDependencyInterpreter.subscribeNextLevel(cas) ])(state);
    };
};
var logConversionError = function (dictShow) {
    return function (pathAndKey) {
        return function (err) {
            return function (before) {
                var formatPathKey = function (v) {
                    return v.value0 + Data_ForeignKey.fkToString(v.value1);
                };
                var msg = function (error) {
                    return "Failed to convert object at " + (formatPathKey(pathAndKey) + (" : " + Data_Show.show(dictShow)(error)));
                };
                return Pux.onlyEffects(before)([ Control_Apply.applySecond(Control_Monad_Aff.applyAff)(Data_Function.apply(Control_Monad_Aff_Console.log)(msg(err)))(Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(State_Types.Noop.value)) ]);
            };
        };
    };
};
var doChildAdded = function (applier) {
    return function (v) {
        return function (before) {
            var $9 = applier(v.fdo)(before);
            if ($9 instanceof Data_Either.Right) {
                return onSuccess(v)($9.value0);
            };
            if ($9 instanceof Data_Either.Left) {
                return logConversionError(Data_Foreign.showForeignError)(Data_ForeignLocation.pathKey(v.fdo))($9.value0)(before);
            };
            throw new Error("Failed pattern match at State.DoChildAdded line 20, column 3 - line 22, column 60: " + [ $9.constructor.name ]);
        };
    };
};
module.exports = {
    doChildAdded: doChildAdded
};
