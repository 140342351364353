// Generated by psc version 0.9.1
"use strict";
var Data_Function = require("../Data.Function");
var Data_Tuple_Nested = require("../Data.Tuple.Nested");
var CSS_Size = require("../CSS.Size");
var CSS_String = require("../CSS.String");
var CSS_Stylesheet = require("../CSS.Stylesheet");
var CSS_Property = require("../CSS.Property");
var width = Data_Function.apply(CSS_Stylesheet.key(CSS_Size.valSize))(CSS_String.fromString(CSS_Property.isStringKey)("width"));
var top = Data_Function.apply(CSS_Stylesheet.key(CSS_Size.valSize))(CSS_String.fromString(CSS_Property.isStringKey)("top"));
var right = Data_Function.apply(CSS_Stylesheet.key(CSS_Size.valSize))(CSS_String.fromString(CSS_Property.isStringKey)("right"));
var paddingTop = Data_Function.apply(CSS_Stylesheet.key(CSS_Size.valSize))(CSS_String.fromString(CSS_Property.isStringKey)("padding-top"));
var paddingRight = Data_Function.apply(CSS_Stylesheet.key(CSS_Size.valSize))(CSS_String.fromString(CSS_Property.isStringKey)("padding-right"));
var paddingLeft = Data_Function.apply(CSS_Stylesheet.key(CSS_Size.valSize))(CSS_String.fromString(CSS_Property.isStringKey)("padding-left"));
var paddingBottom = Data_Function.apply(CSS_Stylesheet.key(CSS_Size.valSize))(CSS_String.fromString(CSS_Property.isStringKey)("padding-bottom"));
var padding = function (a) {
    return function (b) {
        return function (c) {
            return function (d) {
                return Data_Function.apply(CSS_Stylesheet.key(CSS_Property.valTuple(CSS_Property.valTuple(CSS_Property.valTuple(CSS_Size.valSize)(CSS_Size.valSize))(CSS_Size.valSize))(CSS_Size.valSize))(CSS_String.fromString(CSS_Property.isStringKey)("padding")))(Data_Tuple_Nested.tuple4(a)(b)(c)(d));
            };
        };
    };
};
var minWidth = Data_Function.apply(CSS_Stylesheet.key(CSS_Size.valSize))(CSS_String.fromString(CSS_Property.isStringKey)("min-width"));
var minHeight = Data_Function.apply(CSS_Stylesheet.key(CSS_Size.valSize))(CSS_String.fromString(CSS_Property.isStringKey)("min-height"));
var maxWidth = Data_Function.apply(CSS_Stylesheet.key(CSS_Size.valSize))(CSS_String.fromString(CSS_Property.isStringKey)("max-width"));
var maxHeight = Data_Function.apply(CSS_Stylesheet.key(CSS_Size.valSize))(CSS_String.fromString(CSS_Property.isStringKey)("max-height"));
var marginTop = Data_Function.apply(CSS_Stylesheet.key(CSS_Size.valSize))(CSS_String.fromString(CSS_Property.isStringKey)("margin-top"));
var marginRight = Data_Function.apply(CSS_Stylesheet.key(CSS_Size.valSize))(CSS_String.fromString(CSS_Property.isStringKey)("margin-right"));
var marginLeft = Data_Function.apply(CSS_Stylesheet.key(CSS_Size.valSize))(CSS_String.fromString(CSS_Property.isStringKey)("margin-left"));
var marginBottom = Data_Function.apply(CSS_Stylesheet.key(CSS_Size.valSize))(CSS_String.fromString(CSS_Property.isStringKey)("margin-bottom"));
var margin = function (a) {
    return function (b) {
        return function (c) {
            return function (d) {
                return Data_Function.apply(CSS_Stylesheet.key(CSS_Property.valTuple(CSS_Property.valTuple(CSS_Property.valTuple(CSS_Size.valSize)(CSS_Size.valSize))(CSS_Size.valSize))(CSS_Size.valSize))(CSS_String.fromString(CSS_Property.isStringKey)("margin")))(Data_Tuple_Nested.tuple4(a)(b)(c)(d));
            };
        };
    };
};
var left = Data_Function.apply(CSS_Stylesheet.key(CSS_Size.valSize))(CSS_String.fromString(CSS_Property.isStringKey)("left"));
var height = Data_Function.apply(CSS_Stylesheet.key(CSS_Size.valSize))(CSS_String.fromString(CSS_Property.isStringKey)("height"));
var bottom = Data_Function.apply(CSS_Stylesheet.key(CSS_Size.valSize))(CSS_String.fromString(CSS_Property.isStringKey)("bottom"));
module.exports = {
    bottom: bottom, 
    height: height, 
    left: left, 
    margin: margin, 
    marginBottom: marginBottom, 
    marginLeft: marginLeft, 
    marginRight: marginRight, 
    marginTop: marginTop, 
    maxHeight: maxHeight, 
    maxWidth: maxWidth, 
    minHeight: minHeight, 
    minWidth: minWidth, 
    padding: padding, 
    paddingBottom: paddingBottom, 
    paddingLeft: paddingLeft, 
    paddingRight: paddingRight, 
    paddingTop: paddingTop, 
    right: right, 
    top: top, 
    width: width
};
