// Generated by psc version 0.9.1
"use strict";
var State_CurrentMockup = require("../State.CurrentMockup");
var Components_NewSessionDescription_Action = require("../Components.NewSessionDescription.Action");
var Data_PleaseChooseYesNo = require("../Data.PleaseChooseYesNo");
var Model_SessionDescription = require("../Model.SessionDescription");
var update = function (action) {
    return function (v) {
        var v1 = function (ev) {
            return ev.target.value;
        };
        var updater = function (x) {
            var $3 = {};
            for (var $4 in x) {
                if (x.hasOwnProperty($4)) {
                    $3[$4] = x[$4];
                };
            };
            $3.techRequirements = "something";
            return $3;
        };
        var p = function (ev) {
            var $5 = v1(ev);
            if ($5 === "Yes") {
                return Data_PleaseChooseYesNo.Yes.value;
            };
            if ($5 === "No") {
                return Data_PleaseChooseYesNo.No.value;
            };
            return Data_PleaseChooseYesNo.No.value;
        };
        if (action instanceof Components_NewSessionDescription_Action.Submit) {
            return v;
        };
        if (action instanceof Components_NewSessionDescription_Action.Cancel) {
            return v;
        };
        if (action instanceof Components_NewSessionDescription_Action.ExpensesChanged) {
            return Model_SessionDescription.SessionDescription((function () {
                var $7 = {};
                for (var $8 in v) {
                    if (v.hasOwnProperty($8)) {
                        $7[$8] = v[$8];
                    };
                };
                $7.expenses = State_CurrentMockup.update(action.value0)(v.expenses);
                return $7;
            })());
        };
        if (action instanceof Components_NewSessionDescription_Action.EditTitle) {
            return Model_SessionDescription.SessionDescription((function () {
                var $10 = {};
                for (var $11 in v) {
                    if (v.hasOwnProperty($11)) {
                        $10[$11] = v[$11];
                    };
                };
                $10.title = v1(action.value0);
                return $10;
            })());
        };
        if (action instanceof Components_NewSessionDescription_Action.AbstractChanged) {
            return Model_SessionDescription.SessionDescription((function () {
                var $13 = {};
                for (var $14 in v) {
                    if (v.hasOwnProperty($14)) {
                        $13[$14] = v[$14];
                    };
                };
                $13.abstract = v1(action.value0);
                return $13;
            })());
        };
        if (action instanceof Components_NewSessionDescription_Action.DescriptionChanged) {
            return Model_SessionDescription.SessionDescription((function () {
                var $16 = {};
                for (var $17 in v) {
                    if (v.hasOwnProperty($17)) {
                        $16[$17] = v[$17];
                    };
                };
                $16.sessiondescription = v1(action.value0);
                return $16;
            })());
        };
        if (action instanceof Components_NewSessionDescription_Action.TypeChanged) {
            return Model_SessionDescription.SessionDescription((function () {
                var $19 = {};
                for (var $20 in v) {
                    if (v.hasOwnProperty($20)) {
                        $19[$20] = v[$20];
                    };
                };
                $19.sessiontype = v1(action.value0);
                return $19;
            })());
        };
        if (action instanceof Components_NewSessionDescription_Action.DurationChanged) {
            return Model_SessionDescription.SessionDescription((function () {
                var $22 = {};
                for (var $23 in v) {
                    if (v.hasOwnProperty($23)) {
                        $22[$23] = v[$23];
                    };
                };
                $22.duration = v1(action.value0);
                return $22;
            })());
        };
        if (action instanceof Components_NewSessionDescription_Action.NameChanged) {
            return Model_SessionDescription.SessionDescription((function () {
                var $25 = {};
                for (var $26 in v) {
                    if (v.hasOwnProperty($26)) {
                        $25[$26] = v[$26];
                    };
                };
                $25.speakername = v1(action.value0);
                return $25;
            })());
        };
        if (action instanceof Components_NewSessionDescription_Action.HowToAddressChanged) {
            return Model_SessionDescription.SessionDescription((function () {
                var $28 = {};
                for (var $29 in v) {
                    if (v.hasOwnProperty($29)) {
                        $28[$29] = v[$29];
                    };
                };
                $28.speakerHowToAdress = v1(action.value0);
                return $28;
            })());
        };
        if (action instanceof Components_NewSessionDescription_Action.OrgChanged) {
            return Model_SessionDescription.SessionDescription((function () {
                var $31 = {};
                for (var $32 in v) {
                    if (v.hasOwnProperty($32)) {
                        $31[$32] = v[$32];
                    };
                };
                $31.speakerorg = v1(action.value0);
                return $31;
            })());
        };
        if (action instanceof Components_NewSessionDescription_Action.EmailChanged) {
            return Model_SessionDescription.SessionDescription((function () {
                var $34 = {};
                for (var $35 in v) {
                    if (v.hasOwnProperty($35)) {
                        $34[$35] = v[$35];
                    };
                };
                $34.speakeremail = v1(action.value0);
                return $34;
            })());
        };
        if (action instanceof Components_NewSessionDescription_Action.TwitterChanged) {
            return Model_SessionDescription.SessionDescription((function () {
                var $37 = {};
                for (var $38 in v) {
                    if (v.hasOwnProperty($38)) {
                        $37[$38] = v[$38];
                    };
                };
                $37.twitter = v1(action.value0);
                return $37;
            })());
        };
        if (action instanceof Components_NewSessionDescription_Action.BioChanged) {
            return Model_SessionDescription.SessionDescription((function () {
                var $40 = {};
                for (var $41 in v) {
                    if (v.hasOwnProperty($41)) {
                        $40[$41] = v[$41];
                    };
                };
                $40.bio = v1(action.value0);
                return $40;
            })());
        };
        if (action instanceof Components_NewSessionDescription_Action.OtherSpeakersChanged) {
            return Model_SessionDescription.SessionDescription((function () {
                var $43 = {};
                for (var $44 in v) {
                    if (v.hasOwnProperty($44)) {
                        $43[$44] = v[$44];
                    };
                };
                $43.otherspeakers = v1(action.value0);
                return $43;
            })());
        };
        if (action instanceof Components_NewSessionDescription_Action.Speaker2NameChanged) {
            return Model_SessionDescription.SessionDescription((function () {
                var $46 = {};
                for (var $47 in v) {
                    if (v.hasOwnProperty($47)) {
                        $46[$47] = v[$47];
                    };
                };
                $46.speaker2name = v1(action.value0);
                return $46;
            })());
        };
        if (action instanceof Components_NewSessionDescription_Action.Speaker2HowToAddressChanged) {
            return Model_SessionDescription.SessionDescription((function () {
                var $49 = {};
                for (var $50 in v) {
                    if (v.hasOwnProperty($50)) {
                        $49[$50] = v[$50];
                    };
                };
                $49.speaker2HowToAdress = v1(action.value0);
                return $49;
            })());
        };
        if (action instanceof Components_NewSessionDescription_Action.Speaker2OrgChanged) {
            return Model_SessionDescription.SessionDescription((function () {
                var $52 = {};
                for (var $53 in v) {
                    if (v.hasOwnProperty($53)) {
                        $52[$53] = v[$53];
                    };
                };
                $52.speaker2org = v1(action.value0);
                return $52;
            })());
        };
        if (action instanceof Components_NewSessionDescription_Action.Speaker2TwitterChanged) {
            return Model_SessionDescription.SessionDescription((function () {
                var $55 = {};
                for (var $56 in v) {
                    if (v.hasOwnProperty($56)) {
                        $55[$56] = v[$56];
                    };
                };
                $55.speaker2twitter = v1(action.value0);
                return $55;
            })());
        };
        if (action instanceof Components_NewSessionDescription_Action.Speaker2BioChanged) {
            return Model_SessionDescription.SessionDescription((function () {
                var $58 = {};
                for (var $59 in v) {
                    if (v.hasOwnProperty($59)) {
                        $58[$59] = v[$59];
                    };
                };
                $58.speaker2bio = v1(action.value0);
                return $58;
            })());
        };
        if (action instanceof Components_NewSessionDescription_Action.Speaker3NameChanged) {
            return Model_SessionDescription.SessionDescription((function () {
                var $61 = {};
                for (var $62 in v) {
                    if (v.hasOwnProperty($62)) {
                        $61[$62] = v[$62];
                    };
                };
                $61.speaker3name = v1(action.value0);
                return $61;
            })());
        };
        if (action instanceof Components_NewSessionDescription_Action.Speaker3HowToAddressChanged) {
            return Model_SessionDescription.SessionDescription((function () {
                var $64 = {};
                for (var $65 in v) {
                    if (v.hasOwnProperty($65)) {
                        $64[$65] = v[$65];
                    };
                };
                $64.speaker3HowToAdress = v1(action.value0);
                return $64;
            })());
        };
        if (action instanceof Components_NewSessionDescription_Action.Speaker3OrgChanged) {
            return Model_SessionDescription.SessionDescription((function () {
                var $67 = {};
                for (var $68 in v) {
                    if (v.hasOwnProperty($68)) {
                        $67[$68] = v[$68];
                    };
                };
                $67.speaker3org = v1(action.value0);
                return $67;
            })());
        };
        if (action instanceof Components_NewSessionDescription_Action.Speaker3TwitterChanged) {
            return Model_SessionDescription.SessionDescription((function () {
                var $70 = {};
                for (var $71 in v) {
                    if (v.hasOwnProperty($71)) {
                        $70[$71] = v[$71];
                    };
                };
                $70.speaker3twitter = v1(action.value0);
                return $70;
            })());
        };
        if (action instanceof Components_NewSessionDescription_Action.Speaker3BioChanged) {
            return Model_SessionDescription.SessionDescription((function () {
                var $73 = {};
                for (var $74 in v) {
                    if (v.hasOwnProperty($74)) {
                        $73[$74] = v[$74];
                    };
                };
                $73.speaker3bio = v1(action.value0);
                return $73;
            })());
        };
        if (action instanceof Components_NewSessionDescription_Action.TechRequirementsChanged) {
            return Model_SessionDescription.SessionDescription((function () {
                var $76 = {};
                for (var $77 in v) {
                    if (v.hasOwnProperty($77)) {
                        $76[$77] = v[$77];
                    };
                };
                $76.techrequirements = v1(action.value0);
                return $76;
            })());
        };
        if (action instanceof Components_NewSessionDescription_Action.AcceptTermsAndConditions) {
            return Model_SessionDescription.SessionDescription((function () {
                var $79 = {};
                for (var $80 in v) {
                    if (v.hasOwnProperty($80)) {
                        $79[$80] = v[$80];
                    };
                };
                $79.termsAndConditions = p(action.value0);
                return $79;
            })());
        };
        if (action instanceof Components_NewSessionDescription_Action.AcceptCodeOfConduct) {
            return Model_SessionDescription.SessionDescription((function () {
                var $82 = {};
                for (var $83 in v) {
                    if (v.hasOwnProperty($83)) {
                        $82[$83] = v[$83];
                    };
                };
                $82.codeOfConduct = p(action.value0);
                return $82;
            })());
        };
        throw new Error("Failed pattern match at Components.NewSessionDescription.State line 16, column 47 - line 46, column 73: " + [ action.constructor.name ]);
    };
};
var init = function (s) {
    return s;
};
module.exports = {
    init: init, 
    update: update
};
