// Generated by psc version 0.9.1
"use strict";
var Control_Monad_Eff_Exception = require("../Control.Monad.Eff.Exception");
var Control_Monad_Eff_Unsafe = require("../Control.Monad.Eff.Unsafe");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var unsafeThrowException = function ($0) {
    return Control_Monad_Eff_Unsafe.unsafePerformEff(Control_Monad_Eff_Exception.throwException($0));
};
var unsafeThrow = function ($1) {
    return unsafeThrowException(Control_Monad_Eff_Exception.error($1));
};
module.exports = {
    unsafeThrow: unsafeThrow, 
    unsafeThrowException: unsafeThrowException
};
