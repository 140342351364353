// Generated by psc version 0.9.1
"use strict";
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_Loaded = require("../Data.Loaded");
var Data_Tuple = require("../Data.Tuple");
var Model_Event = require("../Model.Event");
var Model_My = require("../Model.My");
var State_Types = require("../State.Types");
var myEvents = function (f) {
    return function (state) {
        var ks = f(state.my);
        var mp = Data_Loaded.loadedStrMapAsArray(ks)(state.events);
        var result = Data_Loaded.partiallyLoaded(mp);
        return result;
    };
};
var myEventsWithReviews = function (state) {
    return myEvents(Model_My.reviewerFor)(state);
};
var myPanelChairEvents = function (state) {
    return myEvents(Model_My.panelChairFor)(state);
};
var isPanelChairFor = function (fk) {
    return function (s) {
        return Model_My.isPanelChairFor(fk)(s.my);
    };
};
var isAdmin = function (state) {
    return Model_My.isAdmin(state.my);
};
module.exports = {
    isPanelChairFor: isPanelChairFor, 
    myEvents: myEvents, 
    myEventsWithReviews: myEventsWithReviews, 
    myPanelChairEvents: myPanelChairEvents
};
