// Generated by psc version 0.9.1
"use strict";
var Ports_Firebase_Aff = require("../Ports.Firebase.Aff");
var Control_Monad_Aff = require("../Control.Monad.Aff");
var Control_Monad_Eff_Class = require("../Control.Monad.Eff.Class");
var Data_Firebase_Subscription = require("../Data.Firebase.Subscription");
var Data_ForeignKey = require("../Data.ForeignKey");
var Data_Maybe = require("../Data.Maybe");
var Data_Tuple = require("../Data.Tuple");
var Prelude = require("../Prelude");
var Signal_Channel = require("../Signal.Channel");
var State_Types = require("../State.Types");
var UReview_SendActions = require("../UReview.SendActions");
var Ports_Firebase_Types = require("../Ports.Firebase.Types");
var Control_Bind = require("../Control.Bind");
var Web_Firebase_Aff = require("../Web.Firebase.Aff");
var Web_Firebase = require("../Web.Firebase");
var Data_Function = require("../Data.Function");
var Control_Applicative = require("../Control.Applicative");
var subscribeAff = function (p) {
    return function (et) {
        return function (fn) {
            return function (rootRef) {
                return Control_Bind.bind(Control_Monad_Aff.bindAff)(Web_Firebase_Aff.child(p)(rootRef))(function (v) {
                    return Control_Bind.bind(Control_Monad_Aff.bindAff)(Web_Firebase_Aff.on(et)(v))(function (v1) {
                        return fn(v1);
                    });
                });
            };
        };
    };
};
var subscribeOnValue = function (p) {
    return function (fn) {
        return function (subscriptions) {
            return function (rootRef) {
                return function (channel) {
                    var doSubscribe = Control_Bind.bind(Control_Monad_Aff.bindAff)(Data_Function.apply(Control_Monad_Eff_Class.liftEff(Control_Monad_Aff.monadEffAff))(UReview_SendActions.sendAction(channel)(new State_Types.Subscribed(p, Web_Firebase.Value.value))))(function () {
                        return subscribeAff(p)(Web_Firebase.Value.value)(fn)(rootRef);
                    });
                    var $6 = Data_Firebase_Subscription.isSubscribed(p)(Web_Firebase.Value.value)(subscriptions);
                    if ($6) {
                        return Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(State_Types.Noop.value);
                    };
                    if (!$6) {
                        return doSubscribe;
                    };
                    throw new Error("Failed pattern match at Data.Firebase.Subscribe line 57, column 3 - line 57, column 80: " + [ $6.constructor.name ]);
                };
            };
        };
    };
};
var onChildAddedDo = function (p) {
    return function (et) {
        return function (fn) {
            return function (rootRef) {
                return Control_Bind.bind(Control_Monad_Aff.bindAff)(Web_Firebase_Aff.child(p)(rootRef))(function (v) {
                    return Control_Bind.bind(Control_Monad_Aff.bindAff)(Web_Firebase_Aff.on(et)(v))(function (v1) {
                        return fn(new Data_Tuple.Tuple(Data_Maybe.Nothing.value, v1));
                    });
                });
            };
        };
    };
};
module.exports = {
    subscribeOnValue: subscribeOnValue
};
