// Generated by psc version 0.9.1
"use strict";
var $foreign = require("./foreign");
var Control_Monad_Aff = require("../Control.Monad.Aff");
var Control_Monad_Aff_Exception = require("../Control.Monad.Aff.Exception");
var Data_Maybe = require("../Data.Maybe");
var Data_Nullable = require("../Data.Nullable");
var Ports_Firebase_Types = require("../Ports.Firebase.Types");
var Prelude = require("../Prelude");
var Data_Function = require("../Data.Function");
var Control_Applicative = require("../Control.Applicative");
var key = function (fb) {
    var mKey = Data_Function.apply(Data_Nullable.toMaybe)($foreign._key(fb));
    if (mKey instanceof Data_Maybe.Nothing) {
        return Control_Monad_Aff_Exception["throw"]("Key was null. Did you ask key of root reference?");
    };
    if (mKey instanceof Data_Maybe.Just) {
        return Control_Applicative.pure(Control_Monad_Aff.applicativeAff)(mKey.value0);
    };
    throw new Error("Failed pattern match at Ports.Firebase.Aff.Reference line 17, column 3 - line 19, column 23: " + [ mKey.constructor.name ]);
};
module.exports = {
    key: key
};
