// Generated by psc version 0.9.1
"use strict";
var Data_Array = require("../Data.Array");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var Data_Foreign_Generic = require("../Data.Foreign.Generic");
var Data_Generators = require("../Data.Generators");
var Data_Generic = require("../Data.Generic");
var Data_PathTo = require("../Data.PathTo");
var Data_VersionNumber = require("../Data.VersionNumber");
var Prelude = require("../Prelude");
var Test_QuickCheck_Arbitrary = require("../Test.QuickCheck.Arbitrary");
var Test_QuickCheck_Gen = require("../Test.QuickCheck.Gen");
var Control_Apply = require("../Control.Apply");
var Data_Maybe = require("../Data.Maybe");
var Data_Unit = require("../Data.Unit");
var Data_Show = require("../Data.Show");
var Data_Eq = require("../Data.Eq");
var Data_Ord = require("../Data.Ord");
var Data_Functor = require("../Data.Functor");
var Control_Category = require("../Control.Category");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans");
var Data_Identity = require("../Data.Identity");
var Data_Function = require("../Data.Function");
var ForeignKey = function (x) {
    return x;
};
var runForeignKey = function (f) {
    return function (v) {
        return f(v);
    };
};
var pathToForeignKey = new Data_PathTo.PathTo(function (v) {
    return v;
});
var mkForeignKey = function (s) {
    return s;
};
var genericForeignKey = new Data_Generic.Generic(function (v) {
    if (v instanceof Data_Generic.SProd && (v.value0 === "Data.ForeignKey.ForeignKey" && v.value1.length === 1)) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(new Data_Maybe.Just(ForeignKey))(Data_Generic.fromSpine(Data_Generic.genericString)(v.value1[0](Data_Unit.unit)));
    };
    return Data_Maybe.Nothing.value;
}, function ($dollarq) {
    return new Data_Generic.SigProd("Data.ForeignKey.ForeignKey", [ {
        sigConstructor: "Data.ForeignKey.ForeignKey", 
        sigValues: [ function ($dollarq1) {
            return Data_Generic.toSignature(Data_Generic.genericString)(Data_Generic.anyProxy);
        } ]
    } ]);
}, function (v) {
    return new Data_Generic.SProd("Data.ForeignKey.ForeignKey", [ function ($dollarq) {
        return Data_Generic.toSpine(Data_Generic.genericString)(v);
    } ]);
});
var isForeignForeignKey = new Data_Foreign_Class.IsForeign(Data_Foreign_Generic.readGeneric(genericForeignKey)(Data_VersionNumber.jsonOptions));
var showForeignKey = new Data_Show.Show(Data_Generic.gShow(genericForeignKey));
var fkToString = runForeignKey(Control_Category.id(Control_Category.categoryFn));
var eqForeignKey = new Data_Eq.Eq(Data_Generic.gEq(genericForeignKey));
var ordForeignKey = new Data_Ord.Ord(function () {
    return eqForeignKey;
}, Data_Generic.gCompare(genericForeignKey));
var arbForeignKey = new Test_QuickCheck_Arbitrary.Arbitrary(Data_Functor.map(Control_Monad_State_Trans.functorStateT(Data_Identity.functorIdentity))(ForeignKey)(Data_Generators.bigAlphaNumString));
var nonEmptyUniqueKeys = Data_Function.apply(Data_Functor.map(Control_Monad_State_Trans.functorStateT(Data_Identity.functorIdentity))(Data_Array.nub(eqForeignKey)))(Data_Generators.nonEmptyArrayOf(Test_QuickCheck_Arbitrary.arbitrary(arbForeignKey)));
var uniqueKeys = Data_Functor.map(Control_Monad_State_Trans.functorStateT(Data_Identity.functorIdentity))(Data_Array.nub(eqForeignKey))(Test_QuickCheck_Arbitrary.arbitrary(Test_QuickCheck_Arbitrary.arbArray(arbForeignKey)));
module.exports = {
    ForeignKey: ForeignKey, 
    fkToString: fkToString, 
    mkForeignKey: mkForeignKey, 
    nonEmptyUniqueKeys: nonEmptyUniqueKeys, 
    uniqueKeys: uniqueKeys, 
    genericForeignKey: genericForeignKey, 
    showForeignKey: showForeignKey, 
    eqForeignKey: eqForeignKey, 
    ordForeignKey: ordForeignKey, 
    arbForeignKey: arbForeignKey, 
    pathToForeignKey: pathToForeignKey, 
    isForeignForeignKey: isForeignForeignKey
};
