// Generated by psc version 0.9.1
"use strict";
var $foreign = require("./foreign");
var Prelude = require("../Prelude");
var Control_Alt = require("../Control.Alt");
var Control_Alternative = require("../Control.Alternative");
var Control_Monad_Aff_Internal = require("../Control.Monad.Aff.Internal");
var Control_Monad_Cont_Class = require("../Control.Monad.Cont.Class");
var Control_Monad_Eff = require("../Control.Monad.Eff");
var Control_Monad_Eff_Class = require("../Control.Monad.Eff.Class");
var Control_Monad_Eff_Exception = require("../Control.Monad.Eff.Exception");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class");
var Control_Monad_Rec_Class = require("../Control.Monad.Rec.Class");
var Control_MonadPlus = require("../Control.MonadPlus");
var Control_Parallel_Class = require("../Control.Parallel.Class");
var Control_Plus = require("../Control.Plus");
var Data_Either = require("../Data.Either");
var Data_Foldable = require("../Data.Foldable");
var Data_Function_Uncurried = require("../Data.Function.Uncurried");
var Data_Monoid = require("../Data.Monoid");
var Unsafe_Coerce = require("../Unsafe.Coerce");
var Data_Semigroup = require("../Data.Semigroup");
var Control_Apply = require("../Control.Apply");
var Data_Functor = require("../Data.Functor");
var Control_Applicative = require("../Control.Applicative");
var Control_Bind = require("../Control.Bind");
var Control_Monad = require("../Control.Monad");
var Data_Function = require("../Data.Function");
var Control_MonadZero = require("../Control.MonadZero");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra");
var Data_Eq = require("../Data.Eq");
var Data_Unit = require("../Data.Unit");
var Data_Semiring = require("../Data.Semiring");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var Canceler = function (x) {
    return x;
};
var runAff = function (ex) {
    return function (f) {
        return function (aff) {
            return Data_Function_Uncurried.runFn3($foreign._runAff)(ex)(f)(aff);
        };
    };
};
var makeAff$prime = function (h) {
    return $foreign._makeAff(h);
};
var functorAff = new Data_Functor.Functor(function (f) {
    return function (fa) {
        return Data_Function_Uncurried.runFn2($foreign._fmap)(f)(fa);
    };
});
var fromAVBox = Unsafe_Coerce.unsafeCoerce;
var cancel = function (v) {
    return v;
};
var launchAff = (function () {
    var lowerEx = Data_Functor.map(Control_Monad_Eff.functorEff)(function ($34) {
        return Canceler(Data_Functor.map(Data_Functor.functorFn)($foreign._unsafeInterleaveAff)(cancel($34)));
    });
    return function ($35) {
        return lowerEx(runAff(Control_Monad_Eff_Exception.throwException)(Data_Function["const"](Control_Applicative.pure(Control_Monad_Eff.applicativeEff)(Data_Unit.unit)))($foreign._unsafeInterleaveAff($35)));
    };
})();
var attempt = function (aff) {
    return Data_Function_Uncurried.runFn3($foreign._attempt)(Data_Either.Left.create)(Data_Either.Right.create)(aff);
};
var apathize = function (a) {
    return Data_Functor.map(functorAff)(Data_Function["const"](Data_Unit.unit))(attempt(a));
};
var applyAff = new Control_Apply.Apply(function () {
    return functorAff;
}, function (ff) {
    return function (fa) {
        return Data_Function_Uncurried.runFn3($foreign._bind)(alwaysCanceler)(ff)(function (f) {
            return Data_Functor.map(functorAff)(f)(fa);
        });
    };
});
var applicativeAff = new Control_Applicative.Applicative(function () {
    return applyAff;
}, function (v) {
    return Data_Function_Uncurried.runFn2($foreign._pure)(nonCanceler)(v);
});
var nonCanceler = Data_Function["const"](Control_Applicative.pure(applicativeAff)(false));
var alwaysCanceler = Data_Function["const"](Control_Applicative.pure(applicativeAff)(true));
var cancelWith = function (aff) {
    return function (c) {
        return Data_Function_Uncurried.runFn3($foreign._cancelWith)(nonCanceler)(aff)(c);
    };
};
var forkAff = function (aff) {
    return Data_Function_Uncurried.runFn2($foreign._forkAff)(nonCanceler)(aff);
};
var forkAll = function (dictFoldable) {
    return function (affs) {
        return Data_Function_Uncurried.runFn3($foreign._forkAll)(nonCanceler)(Data_Foldable.foldl(dictFoldable))(affs);
    };
};
var killVar = function (q) {
    return function (e) {
        return Data_Function.apply(fromAVBox)(Data_Function_Uncurried.runFn3(Control_Monad_Aff_Internal._killVar)(nonCanceler)(q)(e));
    };
};
var later$prime = function (n) {
    return function (aff) {
        return Data_Function_Uncurried.runFn3($foreign._setTimeout)(nonCanceler)(n)(aff);
    };
};
var later = later$prime(0);
var liftEff$prime = function (eff) {
    return attempt($foreign._unsafeInterleaveAff(Data_Function_Uncurried.runFn2($foreign._liftEff)(nonCanceler)(eff)));
};
var makeAff = function (h) {
    return makeAff$prime(function (e) {
        return function (a) {
            return Data_Functor.map(Control_Monad_Eff.functorEff)(Data_Function["const"](nonCanceler))(h(e)(a));
        };
    });
};
var makeVar = Data_Function.apply(fromAVBox)(Control_Monad_Aff_Internal._makeVar(nonCanceler));
var putVar = function (q) {
    return function (a) {
        return Data_Function.apply(fromAVBox)(Data_Function_Uncurried.runFn3(Control_Monad_Aff_Internal._putVar)(nonCanceler)(q)(a));
    };
};
var takeVar = function (q) {
    return Data_Function.apply(fromAVBox)(Data_Function_Uncurried.runFn2(Control_Monad_Aff_Internal._takeVar)(nonCanceler)(q));
};
var semigroupAff = function (dictSemigroup) {
    return new Data_Semigroup.Semigroup(function (a) {
        return function (b) {
            return Control_Apply.apply(applyAff)(Data_Functor.map(functorAff)(Data_Semigroup.append(dictSemigroup))(a))(b);
        };
    });
};
var monoidAff = function (dictMonoid) {
    return new Data_Monoid.Monoid(function () {
        return semigroupAff(dictMonoid["__superclass_Data.Semigroup.Semigroup_0"]());
    }, Control_Applicative.pure(applicativeAff)(Data_Monoid.mempty(dictMonoid)));
};
var semigroupCanceler = new Data_Semigroup.Semigroup(function (v) {
    return function (v1) {
        return function (e) {
            return Control_Apply.apply(applyAff)(Data_Functor.map(functorAff)(Data_HeytingAlgebra.disj(Data_HeytingAlgebra.heytingAlgebraBoolean))(v(e)))(v1(e));
        };
    };
});
var monoidCanceler = new Data_Monoid.Monoid(function () {
    return semigroupCanceler;
}, Data_Function["const"](Control_Applicative.pure(applicativeAff)(true)));
var bindAff = new Control_Bind.Bind(function () {
    return applyAff;
}, function (fa) {
    return function (f) {
        return Data_Function_Uncurried.runFn3($foreign._bind)(alwaysCanceler)(fa)(f);
    };
});
var monadAff = new Control_Monad.Monad(function () {
    return applicativeAff;
}, function () {
    return bindAff;
});
var monadContAff = new Control_Monad_Cont_Class.MonadCont(function () {
    return monadAff;
}, function (f) {
    return makeAff(function (eb) {
        return function (cb) {
            return Data_Function.apply(Data_Functor["void"](Control_Monad_Eff.functorEff))(runAff(eb)(cb)(f(function (a) {
                return makeAff(function (v) {
                    return function (v1) {
                        return cb(a);
                    };
                });
            })));
        };
    });
});
var monadEffAff = new Control_Monad_Eff_Class.MonadEff(function () {
    return monadAff;
}, function (eff) {
    return Data_Function_Uncurried.runFn2($foreign._liftEff)(nonCanceler)(eff);
});
var monadRecAff = new Control_Monad_Rec_Class.MonadRec(function () {
    return monadAff;
}, function (f) {
    return function (a) {
        return Data_Function_Uncurried.runFn3($foreign._tailRecM)(Data_Either.isLeft)(f)(a);
    };
});
var monadErrorAff = new Control_Monad_Error_Class.MonadError(function () {
    return monadAff;
}, function (aff) {
    return function (ex) {
        return Control_Bind.bind(bindAff)(attempt(aff))(Data_Either.either(ex)(Control_Applicative.pure(applicativeAff)));
    };
}, function (e) {
    return Data_Function_Uncurried.runFn2($foreign._throwError)(nonCanceler)(e);
});
var $$finally = function (aff1) {
    return function (aff2) {
        return Control_Bind.bind(bindAff)(attempt(aff1))(function (v) {
            return Control_Bind.bind(bindAff)(aff2)(function () {
                return Data_Either.either(Control_Monad_Error_Class.throwError(monadErrorAff))(Control_Applicative.pure(applicativeAff))(v);
            });
        });
    };
};
var monadParAff = new Control_Parallel_Class.MonadPar(function () {
    return monadAff;
}, function (f) {
    return function (ma) {
        return function (mb) {
            var putOrKill = function (v) {
                return Data_Either.either(killVar(v))(putVar(v));
            };
            return Control_Bind.bind(bindAff)(makeVar)(function (v) {
                return Control_Bind.bind(bindAff)(makeVar)(function (v1) {
                    return Control_Bind.bind(bindAff)(forkAff(Control_Bind.bindFlipped(bindAff)(putOrKill(v))(attempt(ma))))(function (v2) {
                        return Control_Bind.bind(bindAff)(forkAff(Control_Bind.bindFlipped(bindAff)(putOrKill(v1))(attempt(mb))))(function (v3) {
                            return Control_Apply.apply(applyAff)(Data_Functor.map(functorAff)(f)(takeVar(v)))(takeVar(v1));
                        });
                    });
                });
            });
        };
    };
});
var monadRaceAff = new Control_Parallel_Class.MonadRace(function () {
    return monadParAff;
}, function (a1) {
    return function (a2) {
        var maybeKill = function (va) {
            return function (ve) {
                return function (err) {
                    return Control_Bind.bind(bindAff)(takeVar(ve))(function (v) {
                        return Control_Bind.bind(bindAff)((function () {
                            var $29 = v === 1;
                            if ($29) {
                                return killVar(va)(err);
                            };
                            if (!$29) {
                                return Control_Applicative.pure(applicativeAff)(Data_Unit.unit);
                            };
                            throw new Error("Failed pattern match at Control.Monad.Aff line 240, column 7 - line 240, column 51: " + [ $29.constructor.name ]);
                        })())(function () {
                            return putVar(ve)(v + 1 | 0);
                        });
                    });
                };
            };
        };
        return Control_Bind.bind(bindAff)(makeVar)(function (v) {
            return Control_Bind.bind(bindAff)(makeVar)(function (v1) {
                return Control_Bind.bind(bindAff)(putVar(v1)(0))(function () {
                    return Control_Bind.bind(bindAff)(Data_Function.apply(forkAff)(Control_Bind.bindFlipped(bindAff)(Data_Either.either(maybeKill(v)(v1))(putVar(v)))(attempt(a1))))(function (v2) {
                        return Control_Bind.bind(bindAff)(Data_Function.apply(forkAff)(Control_Bind.bindFlipped(bindAff)(Data_Either.either(maybeKill(v)(v1))(putVar(v)))(attempt(a2))))(function (v3) {
                            return cancelWith(takeVar(v))(Data_Semigroup.append(semigroupCanceler)(v2)(v3));
                        });
                    });
                });
            });
        });
    };
}, Data_Function.apply(Control_Monad_Error_Class.throwError(monadErrorAff))(Control_Monad_Eff_Exception.error("Stalled")));
var altAff = new Control_Alt.Alt(function () {
    return functorAff;
}, function (a1) {
    return function (a2) {
        return Control_Bind.bind(bindAff)(attempt(a1))(Data_Either.either(Data_Function["const"](a2))(Control_Applicative.pure(applicativeAff)));
    };
});
var plusAff = new Control_Plus.Plus(function () {
    return altAff;
}, Data_Function.apply(Control_Monad_Error_Class.throwError(monadErrorAff))(Control_Monad_Eff_Exception.error("Always fails")));
var alternativeAff = new Control_Alternative.Alternative(function () {
    return applicativeAff;
}, function () {
    return plusAff;
});
var monadZero = new Control_MonadZero.MonadZero(function () {
    return alternativeAff;
}, function () {
    return monadAff;
});
var monadPlusAff = new Control_MonadPlus.MonadPlus(function () {
    return monadZero;
});
module.exports = {
    Canceler: Canceler, 
    apathize: apathize, 
    attempt: attempt, 
    cancel: cancel, 
    cancelWith: cancelWith, 
    "finally": $$finally, 
    forkAff: forkAff, 
    forkAll: forkAll, 
    later: later, 
    "later'": later$prime, 
    launchAff: launchAff, 
    "liftEff'": liftEff$prime, 
    makeAff: makeAff, 
    "makeAff'": makeAff$prime, 
    nonCanceler: nonCanceler, 
    runAff: runAff, 
    semigroupAff: semigroupAff, 
    monoidAff: monoidAff, 
    functorAff: functorAff, 
    applyAff: applyAff, 
    applicativeAff: applicativeAff, 
    bindAff: bindAff, 
    monadAff: monadAff, 
    monadEffAff: monadEffAff, 
    monadErrorAff: monadErrorAff, 
    altAff: altAff, 
    plusAff: plusAff, 
    alternativeAff: alternativeAff, 
    monadZero: monadZero, 
    monadPlusAff: monadPlusAff, 
    monadRecAff: monadRecAff, 
    monadContAff: monadContAff, 
    semigroupCanceler: semigroupCanceler, 
    monoidCanceler: monoidCanceler, 
    monadParAff: monadParAff, 
    monadRaceAff: monadRaceAff
};
