// Generated by psc version 0.9.1
"use strict";
var Data_Either = require("../Data.Either");
var Data_Foreign = require("../Data.Foreign");
var Data_Foreign_Class = require("../Data.Foreign.Class");
var Data_Foreign_Index = require("../Data.Foreign.Index");
var readPropWithDefault = function (dictIsForeign) {
    return function (dictIndex) {
        return function ($$default) {
            return function (key) {
                return function (dictionary) {
                    var $2 = Data_Foreign_Index.hasOwnProperty(dictIndex)(key)(dictionary);
                    if ($2) {
                        return Data_Foreign_Class.readProp(dictIsForeign)(dictIndex)(key)(dictionary);
                    };
                    if (!$2) {
                        return new Data_Either.Right($$default);
                    };
                    throw new Error("Failed pattern match at Data.Foreign.Class.Default line 11, column 3 - line 13, column 14: " + [ $2.constructor.name ]);
                };
            };
        };
    };
};
module.exports = {
    readPropWithDefault: readPropWithDefault
};
