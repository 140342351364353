// Generated by psc version 0.9.1
"use strict";
var $foreign = require("./foreign");
var Prelude = require("../Prelude");
var Control_Monad_Eff = require("../Control.Monad.Eff");
var Control_Monad_Eff_Random = require("../Control.Monad.Eff.Random");
var Control_Monad_Rec_Class = require("../Control.Monad.Rec.Class");
var Control_Monad_State = require("../Control.Monad.State");
var Control_Monad_State_Class = require("../Control.Monad.State.Class");
var Data_Array = require("../Data.Array");
var Data_Either = require("../Data.Either");
var Data_Foldable = require("../Data.Foldable");
var Data_Int = require("../Data.Int");
var Data_List = require("../Data.List");
var Data_Maybe = require("../Data.Maybe");
var Data_Monoid_Additive = require("../Data.Monoid.Additive");
var Data_Tuple = require("../Data.Tuple");
var $$Math = require("../Math");
var Test_QuickCheck_LCG = require("../Test.QuickCheck.LCG");
var Data_Function = require("../Data.Function");
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans");
var Data_Identity = require("../Data.Identity");
var Data_Ord = require("../Data.Ord");
var Control_Applicative = require("../Control.Applicative");
var Data_Functor = require("../Data.Functor");
var Data_Ring = require("../Data.Ring");
var Control_Bind = require("../Control.Bind");
var Data_Unfoldable = require("../Data.Unfoldable");
var Data_EuclideanRing = require("../Data.EuclideanRing");
var Data_Semiring = require("../Data.Semiring");
var Data_Boolean = require("../Data.Boolean");
var Data_Eq = require("../Data.Eq");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var runGen = Control_Monad_State.runState;
var stateful = function (f) {
    return Data_Function.apply(Control_Monad_State_Class.state(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity)))(function (s) {
        return runGen(f(s))(s);
    });
};
var sized = function (f) {
    return stateful(function (s) {
        return f(s.size);
    });
};
var variant = function (n) {
    return function (g) {
        return Data_Function.apply(Control_Monad_State_Class.state(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity)))(function (s) {
            return runGen(g)((function () {
                var $12 = {};
                for (var $13 in s) {
                    if (s.hasOwnProperty($13)) {
                        $12[$13] = s[$13];
                    };
                };
                $12.newSeed = n;
                return $12;
            })());
        });
    };
};
var resize = function (sz) {
    return function (g) {
        return Data_Function.apply(Control_Monad_State_Class.state(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity)))(function (s) {
            return runGen(g)((function () {
                var $14 = {};
                for (var $15 in s) {
                    if (s.hasOwnProperty($15)) {
                        $14[$15] = s[$15];
                    };
                };
                $14.size = sz;
                return $14;
            })());
        });
    };
};
var replicateMRec = function (dictMonadRec) {
    return function (k) {
        return function (v) {
            if (k <= 0) {
                return Control_Applicative.pure((dictMonadRec["__superclass_Control.Monad.Monad_0"]())["__superclass_Control.Applicative.Applicative_0"]())(Data_List.Nil.value);
            };
            var go = function (v1) {
                if (v1.value1 === 0) {
                    return Data_Function.apply(Control_Applicative.pure((dictMonadRec["__superclass_Control.Monad.Monad_0"]())["__superclass_Control.Applicative.Applicative_0"]()))(new Data_Either.Right(v1.value0));
                };
                return Data_Functor.mapFlipped((((dictMonadRec["__superclass_Control.Monad.Monad_0"]())["__superclass_Control.Bind.Bind_1"]())["__superclass_Control.Apply.Apply_0"]())["__superclass_Data.Functor.Functor_0"]())(v)(function (x) {
                    return new Data_Either.Left(new Data_Tuple.Tuple(new Data_List.Cons(x, v1.value0), v1.value1 - 1));
                });
            };
            return Control_Monad_Rec_Class.tailRecM(dictMonadRec)(go)(new Data_Tuple.Tuple(Data_List.Nil.value, k));
        };
    };
};
var repeatable = function (f) {
    return Data_Function.apply(Control_Monad_State_Class.state(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity)))(function (s) {
        return new Data_Tuple.Tuple(function (a) {
            return Data_Tuple.fst(runGen(f(a))(s));
        }, (function () {
            var $23 = {};
            for (var $24 in s) {
                if (s.hasOwnProperty($24)) {
                    $23[$24] = s[$24];
                };
            };
            $23.newSeed = Test_QuickCheck_LCG.lcgNext(s.newSeed);
            return $23;
        })());
    });
};
var perturbGen = function (n) {
    return function (gen) {
        return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(Control_Monad_State_Class.modify(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity))(function (s) {
            var $25 = {};
            for (var $26 in s) {
                if (s.hasOwnProperty($26)) {
                    $25[$26] = s[$26];
                };
            };
            $25.newSeed = Test_QuickCheck_LCG.lcgPerturb(Data_Int.toNumber($foreign.float32ToInt32(n)))(s.newSeed);
            return $25;
        }))(function () {
            return gen;
        });
    };
};
var listOf = replicateMRec(Control_Monad_State_Trans.monadRecStateT(Control_Monad_Rec_Class.monadRecIdentity));
var vectorOf = function (k) {
    return function (g) {
        return Data_Functor.map(Control_Monad_State_Trans.functorStateT(Data_Identity.functorIdentity))(Data_List.toUnfoldable(Data_Unfoldable.unfoldableArray))(listOf(k)(g));
    };
};
var lcgStep = (function () {
    var f = function (s) {
        return new Data_Tuple.Tuple(Test_QuickCheck_LCG.runSeed(s.newSeed), (function () {
            var $27 = {};
            for (var $28 in s) {
                if (s.hasOwnProperty($28)) {
                    $27[$28] = s[$28];
                };
            };
            $27.newSeed = Test_QuickCheck_LCG.lcgNext(s.newSeed);
            return $27;
        })());
    };
    return Control_Monad_State_Class.state(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity))(f);
})();
var uniform = Data_Functor.map(Control_Monad_State_Trans.functorStateT(Data_Identity.functorIdentity))(function (n) {
    return Data_Int.toNumber(n) / Data_Int.toNumber(Test_QuickCheck_LCG.lcgN);
})(lcgStep);
var evalGen = Control_Monad_State.evalState;
var sample = function (seed) {
    return function (sz) {
        return function (g) {
            return evalGen(vectorOf(sz)(g))({
                newSeed: seed, 
                size: sz
            });
        };
    };
};
var randomSample$prime = function (n) {
    return function (g) {
        return function __do() {
            var v = Test_QuickCheck_LCG.randomSeed();
            return Data_Function.apply(Control_Applicative.pure(Control_Monad_Eff.applicativeEff))(sample(v)(n)(g))();
        };
    };
};
var randomSample = randomSample$prime(10);
var chooseInt = function (a) {
    return function (b) {
        var clamp = function (x) {
            var $30 = x % ((b - a) + 1 | 0);
            if ($30 >= 0) {
                return a + $30 | 0;
            };
            if (Data_Boolean.otherwise) {
                return (b + $30 | 0) + 1 | 0;
            };
            throw new Error("Failed pattern match at Test.QuickCheck.Gen line 96, column 13 - line 98, column 43: " + [ $30.constructor.name ]);
        };
        return Data_Functor.map(Control_Monad_State_Trans.functorStateT(Data_Identity.functorIdentity))(clamp)(lcgStep);
    };
};
var elements = function (x) {
    return function (xs) {
        return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(chooseInt(0)(Data_Array.length(xs)))(function (v) {
            return Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity))((function () {
                var $32 = v === 0;
                if ($32) {
                    return x;
                };
                if (!$32) {
                    return Data_Maybe.fromMaybe(x)(Data_Array.index(xs)(v - 1));
                };
                throw new Error("Failed pattern match at Test.QuickCheck.Gen line 154, column 3 - line 154, column 62: " + [ $32.constructor.name ]);
            })());
        });
    };
};
var oneOf = function (x) {
    return function (xs) {
        return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(chooseInt(0)(Data_Array.length(xs)))(function (v) {
            var $34 = v < 1;
            if ($34) {
                return x;
            };
            if (!$34) {
                return Data_Maybe.fromMaybe(x)(Data_Array.index(xs)(v - 1));
            };
            throw new Error("Failed pattern match at Test.QuickCheck.Gen line 105, column 3 - line 105, column 55: " + [ $34.constructor.name ]);
        });
    };
};
var choose = function (a) {
    return function (b) {
        var min = $$Math.min(a)(b);
        var max = $$Math.max(a)(b);
        return Data_Functor.map(Control_Monad_State_Trans.functorStateT(Data_Identity.functorIdentity))(function ($48) {
            return min + (max - min) * $48;
        })(uniform);
    };
};
var frequency = function (x) {
    return function (xs) {
        var xxs = new Data_List.Cons(x, xs);
        var total = Data_Function.apply(Data_Monoid_Additive.runAdditive)(Data_Foldable.fold(Data_List.foldableList)(Data_Monoid_Additive.monoidAdditive(Data_Semiring.semiringNumber))(Data_Functor.map(Data_List.functorList)(function ($49) {
            return Data_Monoid_Additive.Additive(Data_Tuple.fst($49));
        })(xxs)));
        var pick = function (__copy_n) {
            return function (__copy_d) {
                return function (__copy_v) {
                    var n = __copy_n;
                    var d = __copy_d;
                    var v = __copy_v;
                    tco: while (true) {
                        if (v instanceof Data_List.Nil) {
                            return d;
                        };
                        if (v instanceof Data_List.Cons) {
                            var $38 = n <= v.value0.value0;
                            if ($38) {
                                return v.value0.value1;
                            };
                            if (!$38) {
                                var __tco_n = n - v.value0.value0;
                                var __tco_d = d;
                                var __tco_v = v.value1;
                                n = __tco_n;
                                d = __tco_d;
                                v = __tco_v;
                                continue tco;
                            };
                            throw new Error("Failed pattern match at Test.QuickCheck.Gen line 114, column 38 - line 114, column 77: " + [ $38.constructor.name ]);
                        };
                        throw new Error("Failed pattern match at Test.QuickCheck.Gen line 110, column 18 - line 117, column 23: " + [ n.constructor.name, d.constructor.name, v.constructor.name ]);
                    };
                };
            };
        };
        return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(choose(0)(total))(function (v) {
            return pick(v)(Data_Tuple.snd(x))(xxs);
        });
    };
};
var arrayOf1 = function (g) {
    return Data_Function.apply(sized)(function (n) {
        return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(chooseInt(0)(n))(function (v) {
            return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(g)(function (v1) {
                return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(vectorOf(v - 1)(g))(function (v2) {
                    return Data_Function.apply(Control_Applicative.pure(Control_Monad_State_Trans.applicativeStateT(Data_Identity.monadIdentity)))(new Data_Tuple.Tuple(v1, v2));
                });
            });
        });
    });
};
var arrayOf = function (g) {
    return Data_Function.apply(sized)(function (n) {
        return Control_Bind.bind(Control_Monad_State_Trans.bindStateT(Data_Identity.monadIdentity))(chooseInt(0)(n))(function (v) {
            return vectorOf(v)(g);
        });
    });
};
module.exports = {
    arrayOf: arrayOf, 
    arrayOf1: arrayOf1, 
    choose: choose, 
    chooseInt: chooseInt, 
    elements: elements, 
    evalGen: evalGen, 
    frequency: frequency, 
    listOf: listOf, 
    oneOf: oneOf, 
    perturbGen: perturbGen, 
    randomSample: randomSample, 
    "randomSample'": randomSample$prime, 
    repeatable: repeatable, 
    resize: resize, 
    runGen: runGen, 
    sample: sample, 
    sized: sized, 
    stateful: stateful, 
    uniform: uniform, 
    variant: variant, 
    vectorOf: vectorOf
};
