// Generated by psc version 0.9.1
"use strict";
var Prelude = require("../Prelude");
var Control_Bind = require("../Control.Bind");
var Control_Monad_Eff_Exception_Unsafe = require("../Control.Monad.Eff.Exception.Unsafe");
var Data_Array = require("../Data.Array");
var Data_Either = require("../Data.Either");
var Data_Foldable = require("../Data.Foldable");
var Data_Foreign = require("../Data.Foreign");
var Data_Foreign_Index = require("../Data.Foreign.Index");
var Data_Function = require("../Data.Function");
var Data_Generic = require("../Data.Generic");
var Data_List = require("../Data.List");
var Data_Maybe = require("../Data.Maybe");
var Data_Nullable = require("../Data.Nullable");
var Data_StrMap = require("../Data.StrMap");
var Data_Traversable = require("../Data.Traversable");
var Data_Tuple = require("../Data.Tuple");
var Global_Unsafe = require("../Global.Unsafe");
var Type_Proxy = require("../Type.Proxy");
var Data_Functor = require("../Data.Functor");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var Data_Unit = require("../Data.Unit");
var Data_Eq = require("../Data.Eq");
var Data_Boolean = require("../Data.Boolean");
var Data_Ord = require("../Data.Ord");
var Data_Semigroup = require("../Data.Semigroup");
var Control_Applicative = require("../Control.Applicative");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra");
var Data_Show = require("../Data.Show");
var TaggedObject = (function () {
    function TaggedObject(value0) {
        this.value0 = value0;
    };
    TaggedObject.create = function (value0) {
        return new TaggedObject(value0);
    };
    return TaggedObject;
})();
var toForeignGeneric = function (dictGeneric) {
    return function (v) {
        var go = function (v1) {
            return function (v2) {
                if (v2 instanceof Data_Generic.SNumber) {
                    return Data_Foreign.toForeign(v2.value0);
                };
                if (v2 instanceof Data_Generic.SInt) {
                    return Data_Foreign.toForeign(v2.value0);
                };
                if (v2 instanceof Data_Generic.SChar) {
                    return Data_Foreign.toForeign(v2.value0);
                };
                if (v2 instanceof Data_Generic.SString) {
                    return Data_Foreign.toForeign(v2.value0);
                };
                if (v2 instanceof Data_Generic.SBoolean) {
                    return Data_Foreign.toForeign(v2.value0);
                };
                if (v1 instanceof Data_Generic.SigArray && v2 instanceof Data_Generic.SArray) {
                    return Data_Foreign.toForeign(Data_Functor.map(Data_Functor.functorArray)(function ($168) {
                        return go(v1.value0(Data_Unit.unit))((function (v3) {
                            return Data_Function.apply(v3)(Data_Unit.unit);
                        })($168));
                    })(v2.value0));
                };
                if (v1 instanceof Data_Generic.SigRecord && v2 instanceof Data_Generic.SRecord) {
                    var pair = function (sig) {
                        return function (sp) {
                            if (sig.recLabel === sp.recLabel) {
                                return new Data_Tuple.Tuple(sig.recLabel, go(sig.recValue(Data_Unit.unit))(sp.recValue(Data_Unit.unit)));
                            };
                            if (Data_Boolean.otherwise) {
                                return Control_Monad_Eff_Exception_Unsafe.unsafeThrow("Record fields do not match signature");
                            };
                            throw new Error("Failed pattern match at Data.Foreign.Generic line 136, column 5 - line 136, column 42: " + [ sig.constructor.name, sp.constructor.name ]);
                        };
                    };
                    var pairs = Data_Array.zipWith(pair)(Data_Array.sortBy(Data_Function.on(Data_Ord.compare(Data_Ord.ordString))(function (v3) {
                        return v3.recLabel;
                    }))(v1.value0))(Data_Array.sortBy(Data_Function.on(Data_Ord.compare(Data_Ord.ordString))(function (v3) {
                        return v3.recLabel;
                    }))(v2.value0));
                    return Data_Foreign.toForeign(Data_StrMap.fromList(Data_List.fromFoldable(Data_Foldable.foldableArray)(pairs)));
                };
                if (v1 instanceof Data_Generic.SigProd && (v1.value0 === "Data.Maybe.Maybe" && (v2 instanceof Data_Generic.SProd && (v2.value0 === "Data.Maybe.Nothing" && (v2.value1.length === 0 && v.maybeAsNull))))) {
                    return Data_Foreign.toForeign(Data_Nullable.toNullable(Data_Maybe.Nothing.value));
                };
                if (v1 instanceof Data_Generic.SigProd && (v1.value0 === "Data.Maybe.Maybe" && (v1.value1.length === 2 && ((v1.value1[0]).sigValues.length === 1 && (v2 instanceof Data_Generic.SProd && (v2.value0 === "Data.Maybe.Just" && (v2.value1.length === 1 && v.maybeAsNull))))))) {
                    return go((v1.value1[0]).sigValues[0](Data_Unit.unit))(v2.value1[0](Data_Unit.unit));
                };
                if (v1 instanceof Data_Generic.SigProd && (v1.value0 === "Data.Tuple.Tuple" && (v1.value1.length === 1 && ((v1.value1[0]).sigValues.length === 2 && (v2 instanceof Data_Generic.SProd && (v2.value0 === "Data.Tuple.Tuple" && (v2.value1.length === 2 && v.tupleAsArray))))))) {
                    return Data_Foreign.toForeign([ go((v1.value1[0]).sigValues[0](Data_Unit.unit))(v2.value1[0](Data_Unit.unit)), go((v1.value1[0]).sigValues[1](Data_Unit.unit))(v2.value1[1](Data_Unit.unit)) ]);
                };
                if (v1 instanceof Data_Generic.SigProd && (v1.value1.length === 1 && ((v1.value1[0]).sigValues.length === 1 && (v2 instanceof Data_Generic.SProd && (v2.value1.length === 1 && v.unwrapNewtypes))))) {
                    return go((v1.value1[0]).sigValues[0](Data_Unit.unit))(v2.value1[0](Data_Unit.unit));
                };
                if (v1 instanceof Data_Generic.SigProd && v2 instanceof Data_Generic.SProd) {
                    var $82 = Data_Foldable.find(Data_Foldable.foldableArray)(function (alt) {
                        return alt.sigConstructor === v2.value0;
                    })(v1.value1);
                    if ($82 instanceof Data_Maybe.Nothing) {
                        return Control_Monad_Eff_Exception_Unsafe.unsafeThrow("No signature for data constructor " + v2.value0);
                    };
                    if ($82 instanceof Data_Maybe.Just) {
                        var $83 = Data_Array.zipWith(function (sig) {
                            return function (sp) {
                                return go(sig(Data_Unit.unit))(sp(Data_Unit.unit));
                            };
                        })($82.value0.sigValues)(v2.value1);
                        if ($83.length === 0) {
                            return Data_Foreign.toForeign(Data_StrMap.fromList(Data_List.singleton(new Data_Tuple.Tuple(v.sumEncoding.value0.tagFieldName, Data_Foreign.toForeign(v2.value0)))));
                        };
                        if ($83.length === 1 && v.unwrapSingleArgumentConstructors) {
                            return Data_Foreign.toForeign(Data_StrMap.fromList(Data_List.fromFoldable(Data_Foldable.foldableArray)([ new Data_Tuple.Tuple(v.sumEncoding.value0.tagFieldName, Data_Foreign.toForeign(v2.value0)), new Data_Tuple.Tuple(v.sumEncoding.value0.contentsFieldName, $83[0]) ])));
                        };
                        return Data_Foreign.toForeign(Data_StrMap.fromList(Data_List.fromFoldable(Data_Foldable.foldableArray)([ new Data_Tuple.Tuple(v.sumEncoding.value0.tagFieldName, Data_Foreign.toForeign(v2.value0)), new Data_Tuple.Tuple(v.sumEncoding.value0.contentsFieldName, Data_Foreign.toForeign($83)) ])));
                    };
                    throw new Error("Failed pattern match at Data.Foreign.Generic line 150, column 9 - line 161, column 62: " + [ $82.constructor.name ]);
                };
                return Control_Monad_Eff_Exception_Unsafe.unsafeThrow("Invalid spine for signature");
            };
        };
        return function ($169) {
            return go(Data_Generic.toSignature(dictGeneric)((Type_Proxy["Proxy"]).value))(Data_Generic.toSpine(dictGeneric)($169));
        };
    };
};
var toJSONGeneric = function (dictGeneric) {
    return function (opts) {
        return function ($170) {
            return Global_Unsafe.unsafeStringify(toForeignGeneric(dictGeneric)(opts)($170));
        };
    };
};
var readGeneric = function (dictGeneric) {
    return function (v) {
        var go = function (v1) {
            return function (v2) {
                if (v1 instanceof Data_Generic.SigUnit) {
                    return Control_Applicative.pure(Data_Either.applicativeEither)(Data_Generic.SUnit.value);
                };
                if (v1 instanceof Data_Generic.SigNumber) {
                    return Data_Functor.map(Data_Either.functorEither)(Data_Generic.SNumber.create)(Data_Foreign.readNumber(v2));
                };
                if (v1 instanceof Data_Generic.SigInt) {
                    return Data_Functor.map(Data_Either.functorEither)(Data_Generic.SInt.create)(Data_Foreign.readInt(v2));
                };
                if (v1 instanceof Data_Generic.SigChar) {
                    return Data_Functor.map(Data_Either.functorEither)(Data_Generic.SChar.create)(Data_Foreign.readChar(v2));
                };
                if (v1 instanceof Data_Generic.SigString) {
                    return Data_Functor.map(Data_Either.functorEither)(Data_Generic.SString.create)(Data_Foreign.readString(v2));
                };
                if (v1 instanceof Data_Generic.SigBoolean) {
                    return Data_Functor.map(Data_Either.functorEither)(Data_Generic.SBoolean.create)(Data_Foreign.readBoolean(v2));
                };
                if (v1 instanceof Data_Generic.SigArray) {
                    return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign.readArray(v2))(function (v3) {
                        return Control_Bind.bind(Data_Either.bindEither)(Data_Traversable["for"](Data_Either.applicativeEither)(Data_Traversable.traversableArray)(v3)(function (f1) {
                            return Control_Bind.bind(Data_Either.bindEither)(go(v1.value0(Data_Unit.unit))(f1))(function (v4) {
                                return Control_Applicative.pure(Data_Either.applicativeEither)(Data_Function["const"](v4));
                            });
                        }))(function (v4) {
                            return Control_Applicative.pure(Data_Either.applicativeEither)(new Data_Generic.SArray(v4));
                        });
                    });
                };
                if (v1 instanceof Data_Generic.SigRecord) {
                    return Control_Bind.bind(Data_Either.bindEither)(Data_Traversable["for"](Data_Either.applicativeEither)(Data_Traversable.traversableArray)(v1.value0)(function (prop) {
                        return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Index.ix(Data_Foreign_Index.indexString)(v2)(prop.recLabel))(function (v3) {
                            return Control_Bind.bind(Data_Either.bindEither)(go(prop.recValue(Data_Unit.unit))(v3))(function (v4) {
                                return Control_Applicative.pure(Data_Either.applicativeEither)({
                                    recLabel: prop.recLabel, 
                                    recValue: Data_Function["const"](v4)
                                });
                            });
                        });
                    }))(function (v3) {
                        return Control_Applicative.pure(Data_Either.applicativeEither)(new Data_Generic.SRecord(v3));
                    });
                };
                if (v1 instanceof Data_Generic.SigProd && (v1.value1.length === 1 && ((v1.value1[0]).sigValues.length === 1 && v.unwrapNewtypes))) {
                    return Control_Bind.bind(Data_Either.bindEither)(go((v1.value1[0]).sigValues[0](Data_Unit.unit))(v2))(function (v3) {
                        return Control_Applicative.pure(Data_Either.applicativeEither)(new Data_Generic.SProd((v1.value1[0]).sigConstructor, [ function (v4) {
                            return v3;
                        } ]));
                    });
                };
                if (v1 instanceof Data_Generic.SigProd && (v1.value0 === "Data.Maybe.Maybe" && (v1.value1.length === 2 && ((v1.value1[0]).sigValues.length === 1 && v.maybeAsNull)))) {
                    var $118 = Data_Foreign.isNull(v2) || Data_Foreign.isUndefined(v2);
                    if ($118) {
                        return Control_Applicative.pure(Data_Either.applicativeEither)(new Data_Generic.SProd("Data.Maybe.Nothing", [  ]));
                    };
                    if (!$118) {
                        return Control_Bind.bind(Data_Either.bindEither)(go((v1.value1[0]).sigValues[0](Data_Unit.unit))(v2))(function (v3) {
                            return Control_Applicative.pure(Data_Either.applicativeEither)(new Data_Generic.SProd("Data.Maybe.Just", [ function (v4) {
                                return v3;
                            } ]));
                        });
                    };
                    throw new Error("Failed pattern match at Data.Foreign.Generic line 90, column 5 - line 93, column 56: " + [ $118.constructor.name ]);
                };
                if (v1 instanceof Data_Generic.SigProd && (v1.value0 === "Data.Tuple.Tuple" && (v1.value1.length === 1 && ((v1.value1[0]).sigValues.length === 2 && v.tupleAsArray)))) {
                    return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign.readArray(v2))(function (v3) {
                        if (v3.length === 2) {
                            return Control_Bind.bind(Data_Either.bindEither)(go((v1.value1[0]).sigValues[0](Data_Unit.unit))(v3[0]))(function (v4) {
                                return Control_Bind.bind(Data_Either.bindEither)(go((v1.value1[0]).sigValues[1](Data_Unit.unit))(v3[1]))(function (v5) {
                                    return Data_Function.apply(Control_Applicative.pure(Data_Either.applicativeEither))(new Data_Generic.SProd("Data.Tuple.Tuple", [ function (v6) {
                                        return v4;
                                    }, function (v6) {
                                        return v5;
                                    } ]));
                                });
                            });
                        };
                        return new Data_Either.Left(new Data_Foreign.TypeMismatch("array of length 2", "array"));
                    });
                };
                if (v1 instanceof Data_Generic.SigProd) {
                    return Control_Bind.bind(Data_Either.bindEither)(Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Index.prop(v.sumEncoding.value0.tagFieldName)(v2))(Data_Foreign.readString))(function (v3) {
                        var $143 = Data_Foldable.find(Data_Foldable.foldableArray)(function (alt) {
                            return alt.sigConstructor === v3;
                        })(v1.value1);
                        if ($143 instanceof Data_Maybe.Nothing) {
                            return new Data_Either.Left(new Data_Foreign.TypeMismatch("one of " + Data_Show.show(Data_Show.showArray(Data_Show.showString))(Data_Functor.map(Data_Functor.functorArray)(function (v4) {
                                return v4.sigConstructor;
                            })(v1.value1)), v3));
                        };
                        if ($143 instanceof Data_Maybe.Just && $143.value0.sigValues.length === 0) {
                            return Control_Applicative.pure(Data_Either.applicativeEither)(new Data_Generic.SProd(v3, [  ]));
                        };
                        if ($143 instanceof Data_Maybe.Just && ($143.value0.sigValues.length === 1 && v.unwrapSingleArgumentConstructors)) {
                            return Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Index.prop(v.sumEncoding.value0.contentsFieldName)(v2))(function (v4) {
                                return Control_Bind.bind(Data_Either.bindEither)(go($143.value0.sigValues[0](Data_Unit.unit))(v4))(function (v5) {
                                    return Control_Applicative.pure(Data_Either.applicativeEither)(new Data_Generic.SProd(v3, [ function (v6) {
                                        return v5;
                                    } ]));
                                });
                            });
                        };
                        if ($143 instanceof Data_Maybe.Just) {
                            return Control_Bind.bind(Data_Either.bindEither)(Control_Bind.bind(Data_Either.bindEither)(Data_Foreign_Index.prop(v.sumEncoding.value0.contentsFieldName)(v2))(Data_Foreign.readArray))(function (v4) {
                                return Control_Bind.bind(Data_Either.bindEither)(Data_Array.zipWithA(Data_Either.applicativeEither)(function (k) {
                                    return go(k(Data_Unit.unit));
                                })($143.value0.sigValues)(v4))(function (v5) {
                                    return Control_Applicative.pure(Data_Either.applicativeEither)(new Data_Generic.SProd(v3, Data_Functor.map(Data_Functor.functorArray)(Data_Function["const"])(v5)));
                                });
                            });
                        };
                        throw new Error("Failed pattern match at Data.Foreign.Generic line 106, column 9 - line 116, column 45: " + [ $143.constructor.name ]);
                    });
                };
                throw new Error("Failed pattern match at Data.Foreign.Generic line 68, column 3 - line 68, column 28: " + [ v1.constructor.name, v2.constructor.name ]);
            };
        };
        var fromSpineUnsafe = function (sp) {
            var $161 = Data_Generic.fromSpine(dictGeneric)(sp);
            if ($161 instanceof Data_Maybe.Nothing) {
                return Control_Monad_Eff_Exception_Unsafe.unsafeThrow("Invalid spine for signature");
            };
            if ($161 instanceof Data_Maybe.Just) {
                return $161.value0;
            };
            throw new Error("Failed pattern match at Data.Foreign.Generic line 63, column 5 - line 65, column 18: " + [ $161.constructor.name ]);
        };
        return function ($171) {
            return Data_Functor.map(Data_Either.functorEither)(fromSpineUnsafe)(go(Data_Generic.toSignature(dictGeneric)((Type_Proxy["Proxy"]).value))($171));
        };
    };
};
var readJSONGeneric = function (dictGeneric) {
    return function (opts) {
        return Control_Bind.composeKleisli(Data_Either.bindEither)(Data_Foreign.parseJSON)(readGeneric(dictGeneric)(opts));
    };
};
var defaultOptions = {
    sumEncoding: new TaggedObject({
        tagFieldName: "tag", 
        contentsFieldName: "contents"
    }), 
    unwrapNewtypes: false, 
    unwrapSingleArgumentConstructors: true, 
    maybeAsNull: true, 
    tupleAsArray: false
};
module.exports = {
    TaggedObject: TaggedObject, 
    defaultOptions: defaultOptions, 
    readGeneric: readGeneric, 
    readJSONGeneric: readJSONGeneric, 
    toForeignGeneric: toForeignGeneric, 
    toJSONGeneric: toJSONGeneric
};
