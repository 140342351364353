// Generated by psc version 0.9.1
"use strict";
var $foreign = require("./foreign");
var Prelude = require("../Prelude");
var Control_Monad_Eff = require("../Control.Monad.Eff");
var Data_Foreign = require("../Data.Foreign");
var Data_Function_Uncurried = require("../Data.Function.Uncurried");
var Data_Maybe = require("../Data.Maybe");
var Data_Nullable = require("../Data.Nullable");
var Web_Firebase_Types = require("../Web.Firebase.Types");
var Web_Firebase_Unsafe = require("../Web.Firebase.Unsafe");
var Data_Eq = require("../Data.Eq");
var Data_Ord = require("../Data.Ord");
var Data_Show = require("../Data.Show");
var Data_Functor = require("../Data.Functor");
var Control_Semigroupoid = require("../Control.Semigroupoid");
var Value = (function () {
    function Value() {

    };
    Value.value = new Value();
    return Value;
})();
var ChildAdded = (function () {
    function ChildAdded() {

    };
    ChildAdded.value = new ChildAdded();
    return ChildAdded;
})();
var ChildChanged = (function () {
    function ChildChanged() {

    };
    ChildChanged.value = new ChildChanged();
    return ChildChanged;
})();
var ChildRemoved = (function () {
    function ChildRemoved() {

    };
    ChildRemoved.value = new ChildRemoved();
    return ChildRemoved;
})();
var ChildMoved = (function () {
    function ChildMoved() {

    };
    ChildMoved.value = new ChildMoved();
    return ChildMoved;
})();
var toString = function (ds) {
    return Data_Function_Uncurried.runFn1($foreign._toString)(ds);
};
var showEventType = function (t) {
    if (t instanceof Value) {
        return "value";
    };
    if (t instanceof ChildAdded) {
        return "child_added";
    };
    if (t instanceof ChildChanged) {
        return "child_changed";
    };
    if (t instanceof ChildRemoved) {
        return "child_removed";
    };
    if (t instanceof ChildMoved) {
        return "child_moved";
    };
    throw new Error("Failed pattern match at Web.Firebase line 90, column 19 - line 97, column 1: " + [ t.constructor.name ]);
};
var show4EventType = new Data_Show.Show(showEventType);
var setA = Data_Function_Uncurried.runFn4($foreign._setA);
var set = function (value) {
    return function (cb) {
        return function (fb) {
            return Data_Function_Uncurried.runFn3($foreign.setImpl)(value)(Data_Nullable.toNullable(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Nullable.toNullable)(cb)))(fb);
        };
    };
};
var pushA = Data_Function_Uncurried.runFn4($foreign._pushA);
var push = function (value) {
    return function (cb) {
        return function (fb) {
            return Data_Function_Uncurried.runFn3($foreign.pushImpl)(value)(Data_Nullable.toNullable(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Nullable.toNullable)(cb)))(fb);
        };
    };
};
var once = function (etype) {
    return function (ds) {
        return function (cb) {
            return function (fb) {
                return Data_Function_Uncurried.runFn4($foreign.onceImpl)(showEventType(etype))(function ($7) {
                    return Web_Firebase_Unsafe.unsafeEvalEff(ds($7));
                })(cb)(fb);
            };
        };
    };
};
var on = function (etype) {
    return function (ds) {
        return function (canceler) {
            return function (fb) {
                return Data_Function_Uncurried.runFn4($foreign.onImpl)(showEventType(etype))(ds)(canceler)(fb);
            };
        };
    };
};
var offSimple = function (ref) {
    return Data_Function_Uncurried.runFn1($foreign._offSimple)(ref);
};
var newFirebase = function (u) {
    return Data_Function_Uncurried.runFn1($foreign.newFirebaseImpl)(u);
};
var key = function (ds) {
    return Data_Functor.map(Control_Monad_Eff.functorEff)(Data_Nullable.toMaybe)(Data_Function_Uncurried.runFn1($foreign._key)(ds));
};
var eqEventType = new Data_Eq.Eq(function (v) {
    return function (v1) {
        if (v instanceof Value && v1 instanceof Value) {
            return true;
        };
        if (v instanceof ChildAdded && v1 instanceof ChildAdded) {
            return true;
        };
        if (v instanceof ChildChanged && v1 instanceof ChildChanged) {
            return true;
        };
        if (v instanceof ChildRemoved && v1 instanceof ChildRemoved) {
            return true;
        };
        if (v instanceof ChildMoved && v1 instanceof ChildMoved) {
            return true;
        };
        return false;
    };
});
var ordEventType = new Data_Ord.Ord(function () {
    return eqEventType;
}, function (ev1) {
    return function (ev2) {
        var numValue = function (v) {
            if (v instanceof Value) {
                return 0;
            };
            if (v instanceof ChildAdded) {
                return 1;
            };
            if (v instanceof ChildChanged) {
                return 2;
            };
            if (v instanceof ChildRemoved) {
                return 3;
            };
            if (v instanceof ChildMoved) {
                return 4;
            };
            throw new Error("Failed pattern match at Web.Firebase line 81, column 25 - line 82, column 25: " + [ v.constructor.name ]);
        };
        return Data_Ord.compare(Data_Ord.ordInt)(numValue(ev1))(numValue(ev2));
    };
});
var child = Data_Function_Uncurried.runFn2($foreign.childImpl);
var callBackReceivesNull = function (cb) {
    var nab = function (nullValue) {
        return cb(Data_Nullable.toMaybe(nullValue));
    };
    return nab;
};
var pushE = function (value) {
    return function (cb) {
        return function (fb) {
            return Data_Function_Uncurried.runFn3($foreign.pushEImpl)(value)(callBackReceivesNull(cb))(fb);
        };
    };
};
var setE = function (value) {
    return function (cb) {
        return function (fb) {
            return Data_Function_Uncurried.runFn3($foreign.setEImpl)(value)(callBackReceivesNull(cb))(fb);
        };
    };
};
module.exports = {
    Value: Value, 
    ChildAdded: ChildAdded, 
    ChildChanged: ChildChanged, 
    ChildRemoved: ChildRemoved, 
    ChildMoved: ChildMoved, 
    child: child, 
    key: key, 
    newFirebase: newFirebase, 
    offSimple: offSimple, 
    on: on, 
    once: once, 
    push: push, 
    pushA: pushA, 
    pushE: pushE, 
    set: set, 
    setA: setA, 
    setE: setE, 
    toString: toString, 
    eqEventType: eqEventType, 
    ordEventType: ordEventType, 
    show4EventType: show4EventType
};
