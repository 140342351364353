// Generated by psc version 0.9.1
"use strict";
var Data_MediaType = require("../Data.MediaType");
var textXML = "text/xml";
var textPlain = "text/plain";
var textHTML = "text/html";
var textCSV = "text/csv";
var multipartFormData = "multipart/form-data";
var imagePNG = "image/png";
var imageJPEG = "image/jpeg";
var imageGIF = "image/gif";
var applicationXML = "application/xml";
var applicationOctetStream = "application/octet-stream";
var applicationJavascript = "application/javascript";
var applicationJSON = "application/json";
var applicationFormURLEncoded = "application/x-www-form-urlencoded";
module.exports = {
    applicationFormURLEncoded: applicationFormURLEncoded, 
    applicationJSON: applicationJSON, 
    applicationJavascript: applicationJavascript, 
    applicationOctetStream: applicationOctetStream, 
    applicationXML: applicationXML, 
    imageGIF: imageGIF, 
    imageJPEG: imageJPEG, 
    imagePNG: imagePNG, 
    multipartFormData: multipartFormData, 
    textCSV: textCSV, 
    textHTML: textHTML, 
    textPlain: textPlain, 
    textXML: textXML
};
